import React, {useEffect, useState} from 'react'
import classnames from 'classnames'

import {ReactComponent as IconSidebar} from 'ipmp-react-ui/icons/menu/sidebar.svg'

import Logo from 'ui/Logo'
import UserInfo from 'components/UserInfo'
import RecentWidget from 'components/Nav/RecentWidget'
import SearchTopNav from 'components/Search/SearchTopNav'
import {DEFAULT_THEME, getOSTheme, isDarkTheme} from 'constants/themes'

const NavbarTop = ({theme, handleClick, sidebarShowed, showAboutModal}) => {
    const [darkMode, setDarkMode] = useState(getOSTheme().matches)

    const sideBarClassname = classnames({
        'sidebar-opener': true,
        active: sidebarShowed,
    })

    const isDarkMode = (theme) => {
        if (theme === DEFAULT_THEME) {
            return darkMode
        }

        return isDarkTheme(theme)
    }

    useEffect(() => {
        const mediaQuery = getOSTheme()
        const handleOSThemeChange = () => setDarkMode(mediaQuery.matches)

        mediaQuery.addEventListener('change', handleOSThemeChange)

        return () => mediaQuery.removeEventListener('change', handleOSThemeChange)
    }, [])

    return (
        <nav className="navbar-top">
            <Logo dark={isDarkMode(theme)} onClick={showAboutModal} />
            <SearchTopNav />
            <div className="right-menu">
                <RecentWidget />
                <div className={sideBarClassname} onClick={handleClick}>
                    <IconSidebar />
                </div>
                <UserInfo />
            </div>
        </nav>
    )
}

export default NavbarTop
