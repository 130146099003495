import React from 'react'
import {useSelector} from 'react-redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import {LOGIN_SCREEN} from 'constants/loginScreen'
import {changeOTPPassword} from 'modules/forms/handlers'
import {useActions} from 'modules/higherOrder/useActions'
import {selectIsLoading} from 'modules/auth/sign/selectors'
import {setLoginScreenForm as setLoginScreenFormAction} from 'modules/auth/sign/actions'

const rules = {
    newPassword: {
        presence: true,
        password: true,
    },
    newPasswordConfirmation: {
        presence: true,
        equality: {
            attribute: 'newPassword',
            message: () => __('Passwords do not match'),
            comparator: (v1, v2) => v1 === v2,
        },
    },
}

export default function OTPChangePassword() {
    const {
        form: {errors},
        isLoading: isFormLoading,
        handle,
    } = useForm(changeOTPPassword)

    const isAuthLoading = useSelector(selectIsLoading)
    const isLoading = isFormLoading || isAuthLoading

    const {setLoginScreenFormAction: setLoginScreenForm} = useActions({
        setLoginScreenFormAction,
    })

    const handleSubmit = ({newPassword}) => {
        handle({newPassword})
    }

    const handleBack = () => {
        setLoginScreenForm(LOGIN_SCREEN)
    }

    return (
        <Form
            rules={rules}
            errors={errors}
            isLoading={isLoading}
            onSubmit={handleSubmit}
            className="card login-form login-form-forgot-form"
        >
            <CardHeader className="login-form-header">{__('Change Password')}</CardHeader>

            <CardContent>
                <span className="login-form-content-label">
                    {__('Enter your new password')}
                </span>

                <Input
                    label={__('New Password')}
                    name="newPassword"
                    type="password"
                    dataTestId="otp-new-password"
                />

                <Input
                    label={__('Confirm Password')}
                    name="newPasswordConfirmation"
                    type="password"
                    dataTestId="otp-confirm-password"
                />
            </CardContent>

            <CardActions>
                <Button
                    primary
                    className="login-form-button"
                    disabled={isLoading}
                    type="submit"
                >
                    {__('Change password')}
                </Button>
                <Button borderless className="login-form-back" onClick={handleBack}>
                    <IconPrev />
                    {__('Back to Sign In')}
                </Button>
            </CardActions>
        </Form>
    )
}
