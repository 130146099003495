import {handleActions} from 'redux-actions'

import {fetch, receive} from './actions'
import {merge, set} from 'immutable-modify'

const defaultState = {
    errors: {},
}

export default handleActions(
    {
        [fetch](state, {payload: panelId}) {
            const old = state[panelId] || defaultState

            return set(state, panelId, {
                ...old,
                isLoading: true,
            })
        },

        [receive](state, {error, payload, meta: {panelId}}) {
            if (error) {
                return merge(state, panelId, {
                    isLoading: false,
                    error: payload,
                })
            }

            return merge(state, panelId, {
                error: null,
                isLoading: false,
                ...payload,
            })
        },
    },
    {}
)
