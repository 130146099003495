import {handleActions} from 'redux-actions'

import {updateSupportedTestsList} from './actions'

const defaultState = {}

export default handleActions(
    {
        [updateSupportedTestsList](state, {payload: {panelId, supportedTests}}) {
            return {
                ...state,
                [panelId]: {
                    supportedTests: [...supportedTests],
                },
            }
        },
    },
    defaultState
)
