import {createAction, handleActions} from 'redux-actions'
import uniqId from 'uniqid'

export const snackShow = createAction(
    'SNACK/SHOW',
    (text, action, actionLabel, isPermanent) => ({
        id: uniqId(),
        text,
        action,
        actionLabel,
        isPermanent,
    })
)

export const hide = createAction('SNACK/HIDE', (id) => id)

export default handleActions(
    {
        [snackShow](state, {payload}) {
            // maybe we should stack snacks
            return [payload]
        },

        [hide](state, {payload}) {
            return state.filter(({id}) => id !== payload)
        },
    },
    []
)
