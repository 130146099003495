import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {showAddGroup} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch, init, remove, reset, setStart} from 'modules/groups/list/actions'
import {selectGroupsList} from 'modules/groups/list/selectors'
import {selectByGroupsIds} from 'modules/groups/store/selectors'

export default function useGroups() {
    const {onPerPageChange, perPage} = usePerPage('groups')
    const byIds = useSelector(selectByGroupsIds)
    const {isLoading, page, error, total, start} = useSelector(selectGroupsList)

    return {
        perPage,
        isLoading,
        page,
        error,
        total,
        start,
        rows: page.map((id) => byIds[id]),
        onPerPageChange,
        ...useActions({
            init,
            fetch,
            reset,
            remove,
            showAddGroup,
            onPageChange: setStart,
        }),
    }
}
