import dataMapper from 'utils/dataMapper'
import {isNeoSerial} from 'utils/panelType'
import parseDate from 'api/base/parseDate'
import {ROLE_INSTALLER} from 'constants/accountRoles'

export const keysMap = {
    id: 'id',
    name: 'name',
    email: 'email',
    status: 'status',
    createdAt: 'created_at',
    lastLogin: 'last_login',
}

export const mapInstaller = dataMapper((installer) => ({
    installerId: installer.id,
    id: installer.id || installer.email,
    email: installer.email,
    name: installer.name,
    status: installer.status,
    telephone: installer.telephone,
    createdAt: parseDate(installer.created_at),
    lastLogin: parseDate(installer.last_login),
    panels: installer.panels,
}))

export const mapInstallerPanels = dataMapper((installerPanel) => ({
    id: Number(installerPanel.unt_id),
    groupId: Number(installerPanel.utg_id),
    serial: installerPanel.unt_serial,
    isNeo: isNeoSerial(installerPanel.unt_serial),
    account: installerPanel.unt_account,
    group: installerPanel._utg_name,
    role: ROLE_INSTALLER,
}))

export const assignInstallerKeyMap = {
    installerId: 'installer_id',
    panelId: 'unt_id',
    pin: 'pin',
}
