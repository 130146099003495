import React, {useCallback, useEffect, useMemo, useState} from 'react'
import PropTypes from 'prop-types'

import DropDownButton from 'ipmp-react-ui/DropDownButton'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import {IdSetSubstractive} from 'modules/selection/store'

const SelectionDropDown = ({
    rows,
    selection,
    select,
    deselect,
    deselectAll,
    selectAll,
    total,
    isSelectEverythingAvailable = false,
    disabledRowsIds = [],
    reverse,
    ...props
}) => {
    const [allSelected, setAllSelected] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)

    const availableRows = useMemo(() => {
        if (disabledRowsIds && disabledRowsIds.length) {
            return rows.filter(
                (row) => row && row.id && !disabledRowsIds.includes(row.id)
            )
        }

        return rows
    }, [disabledRowsIds, rows])

    const toggleSelection = useCallback(
        (e) => {
            e.stopPropagation()
            e.preventDefault()
            if (selection && availableRows && selection.hasAny(availableRows)) {
                deselect(availableRows)
            } else {
                select(availableRows)
            }
        },
        [availableRows, deselectAll, rows, select, selection]
    )

    const handleSelectAll = useCallback(() => {
        if (selection instanceof IdSetSubstractive) {
            reverse({idSet: selection})
        }

        select(availableRows)
    }, [availableRows, reverse, select, selection])

    const handleDeselectAll = useCallback(() => {
        deselect(rows)
    }, [deselect, rows])

    const handleClearSelection = useCallback(() => {
        deselectAll()
    }, [deselectAll])

    const handleSelectEverything = useCallback(() => {
        if (selectAll && total) {
            selectAll(total)
        }
        if (disabledRowsIds) {
            deselect(disabledRowsIds)
        }
    }, [disabledRowsIds, deselect, selectAll, total])

    useEffect(() => {
        if (
            (rows?.length > 0 &&
                !selection.isEmpty() &&
                selection.set.size === rows?.length &&
                selection.has(availableRows)) ||
            selection?.total === rows?.length ||
            selection?.set.size === total
        ) {
            setAllSelected(true)
            setIndeterminate(false)
        }

        if (
            rows?.length > 0 &&
            selection.set.size > 0 &&
            selection.set.size < rows?.length
        ) {
            setAllSelected(false)
            setIndeterminate(true)
        }

        if (total && selection && selection.set.size < total) {
            setAllSelected(false)
            setIndeterminate(true)
        }

        if (rows?.length === 0 || (selection.set.size === 0 && !selection.total)) {
            setAllSelected(false)
            setIndeterminate(false)
        }
    }, [selection.set, rows, toggleSelection, allSelected, indeterminate])

    if (rows === undefined || rows === null || rows.length === 0) {
        return null
    }

    return (
        <DropDownButton
            {...props}
            checkbox
            indeterminate={indeterminate && !allSelected}
            checked={allSelected && !indeterminate}
            shortcut="a"
            onChange={toggleSelection}
            dataTestId="selection-drop-down"
        >
            <Menu>
                {isSelectEverythingAvailable && (
                    <MenuItem onClick={handleSelectEverything}>
                        {__('Everything')}
                    </MenuItem>
                )}
                <MenuItem onClick={handleSelectAll} disabled={allSelected}>
                    {__('All')}
                </MenuItem>
                <MenuItem onClick={handleDeselectAll}>{__('None')}</MenuItem>
                <MenuItem onClick={handleClearSelection} disabled={!!selection.isEmpty()}>
                    {__('Clear')}
                </MenuItem>
            </Menu>
        </DropDownButton>
    )
}

SelectionDropDown.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    selection: PropTypes.shape({
        has: PropTypes.func.isRequired,
        hasAny: PropTypes.func.isRequired,
        isEmpty: PropTypes.func.isRequired,
    }).isRequired,
    select: PropTypes.func,
    deselect: PropTypes.func,
    deselectAll: PropTypes.func,
    selectAll: PropTypes.func,
    total: PropTypes.number,
    isSelectEverythingAvailable: PropTypes.bool,
    disabledRowsIds: PropTypes.array,
    reverse: PropTypes.func,
}

export default SelectionDropDown
