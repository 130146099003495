import React from 'react'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/upload.svg'
import Row from 'components/InteractiveUser/Blind/FirstBlindContent/PanelUserTable/Rows/Row'

import __ from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const PatchTagButton = () => (
    <Button flat type="submit" Icon={IconEdit}>
        {__('Patch tag ')}
    </Button>
)

const PatchTag = ({handler, info}) => {
    return (
        <Form onSubmit={handler} className="patchTagTable">
            <div className="panelUserInfoTable table">
                {info.map(({name, value, withButton}) => (
                    <Row
                        value={convertConfigValue(value)}
                        title={name}
                        EditButton={withButton ? <PatchTagButton /> : null}
                        key={`info-row-${name}`}
                    />
                ))}
            </div>
        </Form>
    )
}

PatchTag.propType = {
    info: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            withButton: PropTypes.bool.isRequired,
        })
    ),
    handler: PropTypes.func,
}

export default PatchTag
