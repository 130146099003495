import React from 'react'

import {stop} from 'permissions/processes/actions'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import Duration from 'ui/Duration'

import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import ProcessStatus from 'components/Processes/ProcessStatus'
import ProcessStopButton from 'components/Processes/ProcessStopButton.js'
import User from 'components/Entities/User'

import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'
import processType from 'constants/processType'

import {__} from 'utils/i18n'

export const allColumns = [
    {
        maxWidth: 170,
        name: () => __('Timing'),
        render: ({started, finished}) => <Duration start={started} end={finished} />,
    },
    {
        width: 50,
        fixed: true,
        align: 'center',
        name: () => __('User'),
        render: ({user, email, userId}) => (
            <User name={user} email={email} id={userId} withTooltip />
        ),
    },
    {
        width: 200,
        name: () => __('Process'),
        render: ({type, description}) => (
            <Definition title={processType(type)} detail={description} multiLine />
        ),
    },
    {
        width: 100,
        maxWidth: 250,
        name: () => __('Status'),
        render: (props) => <ProcessStatus row={props} />,
    },
    {
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, id}, {stop}) => (
            <ProcessStopButton onClick={() => stop(id)} disabled={!isStoppable} />
        ),
    },
]

export default function ProcessesTable(props) {
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <Table
            {...props}
            className="process-table"
            columns={columns}
            emptyMessage={__('No processes')}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
