import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {on} from 'modules/panels/had/actions'
import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'

const SetHadOnButton = ({id, deviceType, disabled, label}) => {
    const {onClick} = useActions({
        onClick: on,
    })

    const onClickHandler = (event) => {
        event.stopPropagation()
        onClick({id, deviceType})
    }

    return (
        <Button
            onClick={onClickHandler}
            flat
            label={label ? label : __('Turn On')}
            disabled={disabled}
        />
    )
}

SetHadOnButton.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
}

export default SetHadOnButton
