import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Autolearn from 'components/Devices/Autolearn'
import {selectPanel} from 'modules/panels/store/selectors'

export default function Auto({panelId, onClose, onDismiss}) {
    const panel = useSelector((state) => selectPanel(state, {panelId}))

    return (
        <Autolearn
            serial={panel?.serial}
            onClose={onClose}
            onDismiss={onDismiss}
            isForSecurityDevices={true}
        />
    )
}

Auto.propTypes = {
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
}
