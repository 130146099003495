// There is no any reason to keep all possible extended info values
// C++ team is managing their translations
// Please put there information when you need to reference some info from the panel

import __ from 'utils/i18n'
import {warn} from 'utils/log'

export const EXTENDED_INFO_SOFTWARE = 'SOFTWARE'
export const EXTENDED_INFO_SOFTWARE_VERSION = 'VERSION'

export default function extendedInfo(entry) {
    switch (entry) {
        case EXTENDED_INFO_SOFTWARE:
            return __('Software')
        case EXTENDED_INFO_SOFTWARE_VERSION:
            return __('Version')
        default:
            warn(`Unknown extended info entry ${entry}`)
            return entry
    }
}
