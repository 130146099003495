import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import withRunnerLoader from 'containers/withRunnerLoader'
import __ from 'utils/i18n'
import withLoader from 'containers/withLoader'

import combine from '../Devices/Sidebar/Tabs/PatchTag/extendedInfo'
import FirmwarePatchTagCard from './FirmwarePatchTagCard'

const ConnectedPatchTag = compose(
    withLoader(({fetch, panelId}) => {
        fetch(panelId)
    }),
    withRunnerLoader(
        () => __('Upgrading panel software…'),
        ({fetch, panelId}) => fetch(panelId)
    )
)(FirmwarePatchTagCard)

const FirmwarePatchTag = ({panelId, info, actions, runner, isLoading}) => {
    useEffect(() => {
        actions.startPoll(panelId)

        return () => actions.stopPoll(panelId)
    }, [])

    return (
        <ConnectedPatchTag
            panelId={panelId}
            runner={runner}
            isLoading={isLoading}
            fetch={actions.fetch}
            handler={actions.handler}
            info={info && combine(info)}
        />
    )
}

FirmwarePatchTag.propTypes = {
    panelId: PropTypes.number.isRequired,
    info: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string,
                    name: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
        })
    ),
    version: PropTypes.string,
}

export default FirmwarePatchTag
