export default function message(data) {
    const result = {...data}

    if ('requestID' in result) {
        result.requestId = result.requestID
        delete result.requestID
    }

    if ('sensorSN' in result) {
        result.enrollmentId = result.sensorSN
        delete result.sensorSN
    }

    if ('STATUS' in result) {
        result.status = result.STATUS
        delete result.STATUS
    }

    if ('SETTING_VALUE' in result) {
        result.settingValue = result.SETTING_VALUE
        delete result.SETTING_VALUE
    }

    if ('RESPONSE_CODE' in result) {
        result.responseCode = result.RESPONSE_CODE
        delete result.RESPONSE_CODE
    }

    if ('GENERIC_REQUEST_RESPONSE' in result) {
        result.genericRequestResponse = result.GENERIC_REQUEST_RESPONSE
        delete result.GENERIC_REQUEST_RESPONSE
    }

    if ('VERBOSE_MESSAGE' in result) {
        result.verboseMessage = result.VERBOSE_MESSAGE
        delete result.VERBOSE_MESSAGE
    }

    if ('PANEL_EVENT' in result && !('event' in result)) {
        result.event = 'PANEL_EVENT'
        result.panelEvent = result.PANEL_EVENT
        delete result.PANEL_EVENT
    }

    if ('NEW_SECURITY_DEVICE_ADDED' in result && !('event' in result)) {
        result.event = 'NEW_SECURITY_DEVICE_ADDED'
        result.newSecurityDeviceAdded = result.NEW_SECURITY_DEVICE_ADDED
        delete result.NEW_SECURITY_DEVICE_ADDED
    }

    return result
}
