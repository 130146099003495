import {put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'
import {EVENT_NOTIFICATION_MESSAGE} from 'constants/autolearn'

export default function* (session, message) {
    if (EVENT_NOTIFICATION_MESSAGE !== message.event || !message.verboseMessage) {
        return null
    }

    yield put(snackShow(message.verboseMessage))
}
