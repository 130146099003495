import {put} from 'redux-saga/effects'

import {
    EVENT_ZWAVE_DSK_DEVICE_ENROLMENT,
    MESSAGE_ZWAVE_DSK_REPORT,
    MESSAGE_ZWAVE_KEYS_REPORT,
} from 'constants/autolearn'

import * as actions from '../actions'

export default function* (session, message) {
    if (EVENT_ZWAVE_DSK_DEVICE_ENROLMENT !== message.event) {
        return null
    }

    switch (message.message) {
        case MESSAGE_ZWAVE_KEYS_REPORT:
            yield put(
                actions.requestZWaveKeysReport(session, {
                    nodeId: message.nodeId,
                    zWaveKeys: message.zwave_keys,
                    zWaveCsa: message.zwave_csa,
                })
            )
            break
        case MESSAGE_ZWAVE_DSK_REPORT:
            yield put(
                actions.requestZWaveDskReport(session, {
                    nodeId: message.nodeId,
                    zWaveDsk: message.zwave_dsk,
                })
            )
            break
    }
}
