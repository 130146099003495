import React, {useEffect} from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import Table from 'ipmp-react-ui/Table'

import RowActions from 'ui/Row/Actions'

import User from 'components/Entities/User'
import Role from 'components/Entities/Role'

import list from 'permissions/roles/list'
import selectionPermission from 'permissions/roles/selection'

import useRoles from 'hooks/pages/useRoles'
import useSelection from 'hooks/useSelection'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import usePermission from 'hooks/usePermission'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import {selectIsDefaultRoleType} from 'modules/auth/sign/selectors'

import RoleGroupCell from './TableCell/RoleGroupCell'
import EditAction from './Actions/EditRoleAction'
import EditPermissionsAction from './Actions/EditPermissionsAction'

import {__} from 'utils/i18n'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

const ROLE_TYPE_COLUMN_KEY = 'role_type'

const allColumns = [
    {
        name: () => __('Role & Type'),
        render: ({name, type}) => <Role name={name} type={type} />,
    },
    {
        name: () => __('Parent Role'),
        render: ({parentName}) => parentName,
    },
    {
        name: () => __('Groups'),
        tooltip: false,
        width: 200,
        component: RoleGroupCell,
    },
    {
        tooltip: false,
        name: () => __('Creator'),
        width: 65,
        align: 'center',
        fixed: true,
        render: ({userName, userId}) => <User name={userName} id={userId} withTooltip />,
    },
    {
        width: 60,
        fixed: true,
        render: ({id, isEditable}) => {
            return (
                <RowActions>
                    <EditAction id={id} disabled={!isEditable}>
                        {__('Edit role')}
                    </EditAction>
                    <EditPermissionsAction id={id}>
                        {__('Edit permissions')}
                    </EditPermissionsAction>
                </RowActions>
            )
        },
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function () {
    const roles = useRoles()

    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    useEffect(() => {
        roles.init()
        roles.startPoll()

        return roles.stopPoll
    }, [])

    let {columns} = useTableColumnsPermission(allColumns)
    const {selection, select, deselect} = useSelection()

    const isDefaultRoleType = useSelector(selectIsDefaultRoleType)
    if (!isDefaultRoleType) {
        columns = columns.filter((column) => column.key !== ROLE_TYPE_COLUMN_KEY)
    }

    return (
        <ConnectedTable
            {...roles}
            hasSelection={hasSelection}
            columns={columns}
            select={select}
            selection={selection}
            deselect={deselect}
            fullHeight
            emptyMessage={__('No roles found')}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
