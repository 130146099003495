import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import EventRuleForm from './EventRuleForm'
import {editEventRule} from 'modules/forms/handlers'

export default function EditEventRule({hide, eventRuleId}) {
    const {handle, isLoading, form} = useForm(editEventRule)
    const handler = (data) => handle({id: eventRuleId, ...data})
    const data = useSelector(({eventRules}) => eventRules.store.byIds[eventRuleId])

    return (
        <EventRuleForm
            {...form}
            data={data}
            hide={hide}
            handle={handler}
            isLoading={isLoading}
            header={__('Edit Event Rules')}
        />
    )
}
