import React, {useContext, useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import isEmpty from 'lodash-es/isEmpty'

import Tabs from 'ipmp-react-ui/Tabs'

import BlindModalWithPagination from 'ui/BlindModalWithPagination'
import {selectHadBlindModal, selectHadIsSwitchable} from 'modules/panels/had/selector'
import HadContext from 'pages/Panel/Had/HadContext'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import __ from 'utils/i18n'
import HadDeviceGeneric from 'components/Had/HadDeviceGeneric'
import {hadDeviceBlindModalControlResolve} from './List/HadDeviceControl'
import DeviceControlTab from './BlindModal/DeviceControlTab'
import ConfigurationTab from './BlindModal/ConfigurationTab'
import SaveButton from 'pages/Panel/Had/BlindModal/SaveButton'
import {useActions} from 'modules/higherOrder/useActions'
import {clear} from 'modules/forms/actions'
import usePermission from 'hooks/usePermission'
import {
    dimmerLevel,
    doorlockControl,
    genericDisable,
    genericEnable,
    genericToggle,
    thermostatMode,
    thermostatTarget,
    thermostatFanMode,
    waterValveControl,
} from 'permissions/panel/output/actions'
import {
    isThermostatFanModeSupported,
    isThermostatModeChangeSupported,
    isThermostatTargetChangeSupported,
} from 'constants/had/command'
import {
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_PG_PLUS_WATER_VALVE,
    HAD_TYPE_ZWAVE_WATER_VALVE,
    isHadConfigurable,
    isHadDisabled,
} from 'constants/had'
import {HAD_TYPE_PGM, HAD_TYPE_DIMMER, HAD_TYPE_THERMOSTAT} from 'constants/had'
import {isOff, isOn, isStateless, isStatelessToggle} from 'constants/had/hadState'

const genericPermissionsResolve = (had, toggleEnable, genericEnable, genericDisable) => {
    switch (true) {
        case isOn(had?.state?.pgm):
            return !isEmpty(had.supportedCommands) && genericDisable
        case isOff(had?.state?.pgm):
            return !isEmpty(had.supportedCommands) && genericEnable
        case isStateless(had?.state?.pgm):
            return !isEmpty(had.supportedCommands) && (genericEnable || genericDisable)
        case isStatelessToggle(had?.state?.pgm):
            return toggleEnable
    }
}

const isAllowDeviceControl = (
    had,
    {
        isAllowedMode,
        isAllowedTarget,
        isAllowedDimmer,
        isAllowedDoorLock,
        isAllowedWaterValve,
        toggleEnable,
        genericEnable,
        genericDisable,
        isAllowedFanMode,
    }
) => {
    const type = had?.subtype

    switch (type) {
        case HAD_TYPE_THERMOSTAT:
            return isAllowedMode || isAllowedTarget || isAllowedFanMode
        case HAD_TYPE_DIMMER:
            return isAllowedDimmer
        case HAD_TYPE_DOORLOCK:
            return isAllowedDoorLock
        case HAD_TYPE_ZWAVE_WATER_VALVE:
        case HAD_TYPE_PG_PLUS_WATER_VALVE:
            return isAllowedWaterValve
        case HAD_TYPE_PGM:
            return genericPermissionsResolve(
                had,
                toggleEnable,
                genericEnable,
                genericDisable
            )
        default:
            return true
    }
}

const isAllowButtonSave = (
    had,
    {
        isAllowedMode,
        isAllowedTarget,
        isAllowedFanMode,
        isAllowedDimmer,
        isAllowedDoorLock,
        isAllowedWaterValve,
    }
) => {
    if (isStateless(had?.state?.pgm) || isStatelessToggle(had?.state?.pgm)) {
        return false
    }

    const type = had?.subtype
    const options = had?.state?.thermostat?.modes || []

    const isThermostatModeChoose =
        isThermostatModeChangeSupported(had?.supportedCommands) && Boolean(options.length)
    const isThermostatTarget = isThermostatTargetChangeSupported(had?.supportedCommands)
    const isThermostatFanMode = isThermostatFanModeSupported(had?.supportedCommands)

    switch (type) {
        case HAD_TYPE_THERMOSTAT:
            return (
                (isAllowedMode && isThermostatModeChoose) ||
                (isAllowedTarget && isThermostatTarget) ||
                (isAllowedFanMode && isThermostatFanMode)
            )
        case HAD_TYPE_DIMMER:
            return isAllowedDimmer
        case HAD_TYPE_DOORLOCK:
            return isAllowedDoorLock
        case HAD_TYPE_ZWAVE_WATER_VALVE:
        case HAD_TYPE_PG_PLUS_WATER_VALVE:
            return isAllowedWaterValve
        case HAD_TYPE_PGM:
            return (had?.supportedCommands || []).length !== 0
        default:
            return true
    }
}

const HadBlindModal = ({selectedHadId}) => {
    const permissions = usePermission({
        isAllowedDimmer: dimmerLevel,
        isAllowedMode: thermostatMode,
        isAllowedFanMode: thermostatFanMode,
        isAllowedTarget: thermostatTarget,
        isAllowedDoorLock: doorlockControl,
        isAllowedWaterValve: waterValveControl,
        toggleEnable: genericToggle,
        genericEnable: genericEnable,
        genericDisable: genericDisable,
    })
    const {
        hadBlindModalPrevNext: [prevId, nextId],
        had,
    } = useSelector((state) => selectHadBlindModal(state, selectedHadId))
    const {isSwitchable} = useSelector(selectHadIsSwitchable)
    const controlFormSuccess = useSelector(
        (store) => store.forms?.submitHadDeviceControlForm?.success
    )
    const {clear: clearForm} = useActions({clear})

    const isOpened = Boolean(selectedHadId)
    const isDisabled = isHadDisabled({isSwitchable, had})

    const {handleHadSelect} = useContext(HadContext)
    const isLast = nextId === null
    const isFirst = prevId === null

    const HadDeviceControl = hadDeviceBlindModalControlResolve(had?.subtype)

    const [activeTabIndex, setActiveTabIndex] = useState(0)

    const isConfigurable = isHadConfigurable(had)

    const [isActiveSaveButton, setIsActiveSaveButton] = useState(true)
    const cardActions = [
        !isDisabled && isAllowButtonSave(had, permissions) ? (
            <SaveButton isDisabled={!isActiveSaveButton} />
        ) : null,
    ]

    useEffect(() => {
        setActiveTabIndex(0)
    }, [isOpened])

    useEffect(() => {
        if (controlFormSuccess) {
            handleHadSelect(null)
            clearForm()
        }
    }, [controlFormSuccess])

    return (
        <BlindModalWithPagination
            prev={prevId}
            next={nextId}
            onNextClick={(next) => handleHadSelect(next)}
            onClose={() => handleHadSelect(null)}
            opened={isOpened}
            onPrevClick={(prev) => handleHadSelect(prev)}
            isNextDisabled={isLast}
            isPrevDisabled={isFirst}
            className="hadBlindModal"
            cardActions={cardActions[activeTabIndex]}
        >
            {had && (
                <>
                    <HadDeviceGeneric
                        name={had?.name}
                        type={had?.deviceType}
                        subtype={had?.subtype}
                        userType={had?.userType}
                        id={had?.id}
                        location={had?.location}
                        partitions={had?.partitions}
                        state={had?.state}
                        RightCornerComponent={
                            <HadDeviceControl had={had} disabled={isDisabled} />
                        }
                    />

                    <hr />

                    <Tabs
                        Layout={SidebarTabsLayout}
                        onChangeTab={(i) => setActiveTabIndex(i)}
                    >
                        {isAllowDeviceControl(had, permissions) && (
                            <DeviceControlTab
                                name={__('Device Control')}
                                had={had}
                                disabled={isDisabled}
                                setIsActiveSaveButton={setIsActiveSaveButton}
                            />
                        )}

                        {isConfigurable && (
                            <ConfigurationTab name={__('Configuration')} had={had} />
                        )}
                    </Tabs>
                </>
            )}
        </BlindModalWithPagination>
    )
}

export default HadBlindModal
