import React from 'react'
import {compose} from 'redux'

import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'
import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useInstallers from 'hooks/pages/useInstallers'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {withSelectionHandler} from 'containers/withSelection'
import {remove as removePermission} from 'permissions/installers/actions'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function InstallerRemoveButton() {
    const {remove} = useInstallers()
    const {isPermitted} = usePermission(removePermission)

    return (
        <ConnectedButton
            isVisible={isPermitted}
            onClick={remove}
            Icon={IconRemove}
            label={__('Remove')}
            title={__('Remove Installers')}
            message={__('Do you want to remove installers?')}
        />
    )
}
