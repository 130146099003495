import React from 'react'

import RemoteInspectionPage from './RemoteInspectionPage'
import path from 'utils/path'

export default class RemoteInspection {
    path = path('remoteInspections')

    element = (<RemoteInspectionPage />)
}
