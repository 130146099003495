import React from 'react'

import Pager from 'ipmp-react-ui/Pager'
import useInstallers from 'hooks/pages/useInstallers'

export default function InstallersPager() {
    const {total, start, perPage, onPageChange, onPerPageChange} = useInstallers()

    return (
        <Pager
            total={total}
            start={start}
            perPage={perPage}
            onPerPageChange={onPerPageChange}
            onPageChange={onPageChange}
        />
    )
}
