import React, {Fragment} from 'react'
import {useNavigate} from 'react-router'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'

import path from 'utils/path'
import IconBack from 'ui/IconBack'
import useSelection from 'hooks/useSelection'
import PatchTagUpdatePager from './GridBar/PatchTagUpdatePager'
import PatchTagUpdateDropDown from './GridBar/PatchTagUpdateDropDown'
import PatchTagUpdate from './GridBar/Buttons/PatchTagUpdate'

export default function FirmwarePatchTagUpdateGridBar() {
    const navigate = useNavigate()
    const {selection} = useSelection()

    const handleGoBack = () => navigate(path('firmware'))

    return (
        <Fragment>
            <Bar>
                <Button onClick={handleGoBack} className="btn--goBack">
                    <IconBack />
                </Button>

                <BarSpace />

                <PatchTagUpdatePager />
            </Bar>

            <Bar>
                <PatchTagUpdateDropDown />
                <Buttons>
                    <PatchTagUpdate selection={selection} />
                </Buttons>
            </Bar>
        </Fragment>
    )
}
