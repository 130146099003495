import React, {useEffect} from 'react'

import Pager from 'ipmp-react-ui/Pager'
import {BarSpace} from 'ipmp-react-ui/Bar'

import list from 'permissions/centralStations/list'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/group/useCentralStations'
import {
    CENTRAL_STATION_COMMUNICATION_PER_PAGE_DEFAULT,
    CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS,
} from 'constants/centralStation'

const PagerVisibility = withVisibility()(Pager)

export default function StationsPager() {
    const {isPermitted} = usePermission(list)
    const {perPage, start, total, onPageChange, onPerPageChange} = useCentralStations()

    useEffect(() => {
        if (!perPage) {
            onPerPageChange(CENTRAL_STATION_COMMUNICATION_PER_PAGE_DEFAULT)
        }
    }, [])

    return (
        <>
            <BarSpace />
            <PagerVisibility
                isVisible={isPermitted && total > 1}
                perPage={perPage}
                start={start}
                total={total}
                onPageChange={onPageChange}
                className="group-communicating-pager"
                onPerPageChange={onPerPageChange}
                customPerPageRows={CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS}
            />
        </>
    )
}
