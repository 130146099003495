import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Error from 'ipmp-react-ui/Error'

import ThermostatModeChoose from 'ui/ThermostatModeChoose'

import {selectTemperatureScale} from 'modules/settings/selectors'
import HadThermostatControlFormTargetTemperature from 'components/Had/HadThermostatControlFormTargetTemperature'
import {
    isThermostatModeChangeSupported,
    isThermostatTargetChangeSupported,
    resolveThermostatCommands,
} from 'constants/had/command'
import __ from 'utils/i18n'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
} from 'constants/had/thermostat'
import {
    thermostatControl,
    thermostatMode,
    thermostatTarget,
} from 'permissions/panel/output/actions'
import useForm from 'hooks/useForm'
import {withPermissionRejection} from 'containers/withPermission'
import usePermission from 'hooks/usePermission'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'
import HadThermostatFanMode from 'components/Had/HadThermostatFanMode'
import {convertFahrenheitToCelsius, TEMP_UNIT_F} from 'utils/temperature'

const getConvertedTemperatures = (heat, cool, currentTemperatureScale) => {
    const convertedHeat =
        currentTemperatureScale === TEMP_UNIT_F
            ? convertFahrenheitToCelsius(Number(heat))
            : Number(heat)
    const convertedCool =
        currentTemperatureScale === TEMP_UNIT_F
            ? convertFahrenheitToCelsius(Number(cool))
            : Number(cool)

    return {
        heat: Math.round(convertedHeat),
        cool: Math.round(convertedCool),
    }
}

const rules = (targets) => {
    const rulesObject = {}
    Object.values(targets).map((target) => {
        rulesObject[target.name] = {
            presence: (value, attributes) => {
                const attributesKeys = Object.keys(attributes)
                return attributesKeys.includes(target.name)
            },
            format: {
                pattern: /^-?\d+$/,
                message: () => __('Invalid value. Must be digits only'),
            },
            numericality: {
                greaterThanOrEqualTo: target.min,
                lessThanOrEqualTo: target.max,
            },
        }
    })

    return rulesObject
}

const ThermostatControlFormBase = ({
    errors,
    isLoading,
    handle,
    had,
    panelId,
    isAllowedMode,
    isAllowedTarget,
}) => {
    const [currentMode, setCurrentMode] = useState(had?.state?.thermostat?.mode)
    const [targets, setTargets] = useState([])

    const userTemperatureScale = useSelector(selectTemperatureScale)
    const onSubmitHandler = (formData) => {
        const targetTemperatures = getConvertedTemperatures(
            formData[`target_${HAD_DEVICE_THERMOSTAT_MODE_HEAT}`],
            formData[`target_${HAD_DEVICE_THERMOSTAT_MODE_COOL}`],
            userTemperatureScale
        )

        handle &&
            formData &&
            handle(
                new HadControlForm({
                    commands: resolveThermostatCommands({
                        thermostatMode:
                            had?.state?.thermostat?.mode === currentMode
                                ? undefined
                                : currentMode,
                        thermostatFanMode: formData['fan'],
                        supportedCommands: had?.supportedCommands,
                        targetHeat: targetTemperatures.heat,
                        targetCool: targetTemperatures.cool,
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }

    const onChangeModeHandler = (mode) => {
        setCurrentMode(Number(mode))
    }

    const options = had?.state?.thermostat?.modes || []
    const isThermostatModeChoose =
        isThermostatModeChangeSupported(had?.supportedCommands) && Boolean(options.length)
    const isThermostatTarget = isThermostatTargetChangeSupported(had?.supportedCommands)
    const isControlSupported = isThermostatModeChoose && isThermostatTarget

    return (
        <Form
            className="thermostatControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
            rules={rules(targets)}
        >
            {!isControlSupported && <Error message={__('Not supported')} />}
            {isAllowedMode && isThermostatModeChoose && (
                <ThermostatModeChoose
                    name="mode"
                    defaultValue={currentMode}
                    onChange={onChangeModeHandler}
                    options={Object.keys(options).map(Number)}
                />
            )}
            {isAllowedTarget && isThermostatTarget && (
                <HadThermostatControlFormTargetTemperature
                    name="target"
                    state={had.state}
                    mode={currentMode}
                    userTemperatureScale={userTemperatureScale}
                    setTargets={setTargets}
                />
            )}
            {<HadThermostatFanMode had={had} />}
        </Form>
    )
}

const ThermostatControlFormRejection = withPermissionRejection(thermostatControl)(
    ThermostatControlFormBase
)

export default function ThermostatControlForm({had, panelId}) {
    const {handle, isLoading, form} = useForm(submitHadDeviceControlForm)

    const {isAllowedMode, isAllowedTarget} = usePermission({
        isAllowedMode: thermostatMode,
        isAllowedTarget: thermostatTarget,
    })

    return (
        <ThermostatControlFormRejection
            {...form}
            had={had}
            handle={handle}
            panelId={panelId}
            isLoading={isLoading}
            isAllowedMode={isAllowedMode}
            isAllowedTarget={isAllowedTarget}
        />
    )
}

ThermostatControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
    panelId: PropTypes.number,
}
