import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import {withVisibility} from 'containers/withVisibility'
import {services} from 'permissions/panels/bar'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {unitContent} from 'permissions/panel/page'
import {selectFaults} from 'modules/features/store/selectors'

import MarkForServiceItem from './FaultsItems/MarkForServiceItem'
import ReassignItem from './FaultsItems/ReassignItem'
import ResolveFaultsItem from './FaultsItems/ResolveFaultsItem'
import SuspendFaultsItem from './FaultsItems/SuspendFaultsItem'
import ResumeFaultsItem from './FaultsItems/ResumeFaultsItem'

const FaultsDropDownBase = () => (
    <DropDownButton label={__('Faults')} dataTestId="faults">
        <Menu>
            <MarkForServiceItem />
            <ReassignItem />
            <MenuDelimiter />
            <ResolveFaultsItem />
            <SuspendFaultsItem />
            <ResumeFaultsItem />
        </Menu>
    </DropDownButton>
)

const FaultsDropDownVisibility = compose(withVisibility())(FaultsDropDownBase)

export default function FaultsDropDown() {
    const panelId = useRouterPanelId()
    const {services: isPermitted, isUnitPermitted} = usePermission({
        services,
        isUnitPermitted: unitContent,
    })
    const {isAvailable: isFeatureEnabled} = useSelector((state) =>
        selectFaults(state, {panelId})
    )
    const isAllowed = isPermitted && (isFeatureEnabled || !isUnitPermitted)

    return <FaultsDropDownVisibility panelId={panelId} isVisible={isAllowed} />
}
