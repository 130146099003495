import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import __ from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import usePermission from 'hooks/usePermission'
import {getUserRoleTitle} from 'constants/accountRoles'
import {PanelUserSelect} from './PanelUserSelect'
import {InteractiveUsersAutocomplete} from './InteractiveUsersAutocomplete'
import {assignedUnitUsers} from 'permissions/interactiveUsers/recentHistoryAssignedUnitUsersList'

const rules = {
    panelUserId: {
        presence: true,
    },
    email: {
        presence: true,
        emailRFC: true,
    },
}

function InteractiveUserEmail({interactiveUser, isRegisterNew}) {
    const {isPermitted} = usePermission(assignedUnitUsers)

    if (!isPermitted) {
        return (
            <Input
                name="email"
                label={__('Email')}
                readOnly={!!interactiveUser}
                defaultValue={interactiveUser?.email}
            />
        )
    }

    return (
        <InteractiveUsersAutocomplete
            isRegisterNew={isRegisterNew}
            interactiveUser={interactiveUser}
        />
    )
}

export const AssignUserToPanelForm = ({
    panel,
    panelId,
    handle,
    interactiveUser,
    interactiveUsers,
    isRegisterNew,
    panelUserId,
    panelUser,
    ...props
}) => {
    const getEmail = (emailFromForm) => {
        if (interactiveUser) {
            return interactiveUser.email
        }

        return emailFromForm
    }

    const handleForm = ({panelUserId, email}) => {
        handle({
            panelId,
            email: getEmail(email),
            panelUserId: Number(panelUserId),
        })
    }

    return (
        <ModalCardForm
            onSubmit={handleForm}
            header={__('Assign customer to panel %s', panel.serial)}
            rules={rules}
            confirmOnSubmit
            {...props}
        >
            <InteractiveUserEmail
                interactiveUser={interactiveUser}
                isRegisterNew={isRegisterNew}
            />
            <PanelUserSelect
                label={__('Panel User')}
                name="panelUserId"
                defaultValue={panelUserId ?? undefined}
                defaultLabel={
                    panelUser?.role ? getUserRoleTitle(panelUser.role, panelUser.id) : ''
                }
                disabled={Number.isInteger(panelUserId)}
                panelId={panel.id}
            />
        </ModalCardForm>
    )
}

AssignUserToPanelForm.propTypes = {
    handle: PropTypes.func.isRequired,
    panel: PropTypes.shape({
        id: PropTypes.number.isRequired,
        serial: PropTypes.string.isRequired,
    }).isRequired,
    interactiveUser: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }),

    interactiveUsers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        })
    ),
}
