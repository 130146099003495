import React from 'react'
import __ from 'utils/i18n'

import RunGroupSystemTestsButton from './RunGroupSystemTestsButton'
import {SYSTEM_TEST_GROUP_ALL} from 'constants/panelTests'

export default function RunAllSystemTestsButton({
    tests,
    panelSerial,
    startedGroup,
    setStartedGroup,
}) {
    const label =
        startedGroup === SYSTEM_TEST_GROUP_ALL
            ? __('All Tests Started')
            : __('Start All Tests')

    return (
        <div className="panel-system-tests-all-button-wrapper">
            <RunGroupSystemTestsButton
                tests={tests}
                panelSerial={panelSerial}
                groupName={SYSTEM_TEST_GROUP_ALL}
                startedGroup={startedGroup}
                setStartedGroup={setStartedGroup}
                externalLabel={label}
                primary
            />
        </div>
    )
}
