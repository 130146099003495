import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import list from 'permissions/centralStations/list'
import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/centralStations/list/actions'
import Search from 'components/Search/Search'
import {withVisibility} from 'containers/withVisibility'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import {
    CS_CONNECTION_TYPE_FIBRO,
    CS_CONNECTION_TYPE_HTTP,
    CS_CONNECTION_TYPE_SERIAL,
    CS_CONNECTION_TYPE_TCP,
} from 'constants/centralStation'
import csProfileType, {
    CS_PROFILE_ALARM,
    CS_PROFILE_ALERT,
    CS_PROFILE_CAMERABEINGVIEWED,
    CS_PROFILE_CAMERATROUBLE,
    CS_PROFILE_DURESS,
    CS_PROFILE_HDONOFF,
    CS_PROFILE_HDTROUBLE,
    CS_PROFILE_ILLUMINANCE,
    CS_PROFILE_NOTICE,
    CS_PROFILE_OFFLINE,
    CS_PROFILE_ONLINE,
    CS_PROFILE_OPENCLOSE,
    CS_PROFILE_RESTORE,
    CS_PROFILE_RRI,
    CS_PROFILE_SECURITYOPENCLOSE,
    CS_PROFILE_SMART_SENSORS,
    CS_PROFILE_TEMPERATURE,
} from 'constants/csProfileType'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {selectCentralStationsList} from 'modules/centralStations/list/selectors'

const SearchVisibility = withVisibility()(Search)

export default function CentralStationsSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectCentralStationsList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('cls_name', __('Name')),
        new SuggestFilter('protocol', __('Protocol')),
        new SuggestFilter('hpa_host', __('Host')),
        new SuggestFilter('hpa_port', __('Port')),
        new SuggestFilter('spa_device', __('Device')),
        new TranslateSuggestFilter('etp_name', __('Enabled profile'), {
            [CS_PROFILE_ALERT]: csProfileType(CS_PROFILE_ALERT),
            [CS_PROFILE_ALARM]: csProfileType(CS_PROFILE_ALARM),
            [CS_PROFILE_RESTORE]: csProfileType(CS_PROFILE_RESTORE),
            [CS_PROFILE_SECURITYOPENCLOSE]: csProfileType(CS_PROFILE_SECURITYOPENCLOSE),
            [CS_PROFILE_CAMERABEINGVIEWED]: csProfileType(CS_PROFILE_CAMERABEINGVIEWED),
            [CS_PROFILE_CAMERATROUBLE]: csProfileType(CS_PROFILE_CAMERATROUBLE),
            [CS_PROFILE_HDONOFF]: csProfileType(CS_PROFILE_HDONOFF),
            [CS_PROFILE_HDTROUBLE]: csProfileType(CS_PROFILE_HDTROUBLE),
            [CS_PROFILE_ONLINE]: csProfileType(CS_PROFILE_ONLINE),
            [CS_PROFILE_OFFLINE]: csProfileType(CS_PROFILE_OFFLINE),
            [CS_PROFILE_NOTICE]: csProfileType(CS_PROFILE_NOTICE),
            [CS_PROFILE_RRI]: csProfileType(CS_PROFILE_RRI),
            [CS_PROFILE_SMART_SENSORS]: csProfileType(CS_PROFILE_SMART_SENSORS),
            [CS_PROFILE_OPENCLOSE]: csProfileType(CS_PROFILE_OPENCLOSE),
            [CS_PROFILE_TEMPERATURE]: csProfileType(CS_PROFILE_TEMPERATURE),
            [CS_PROFILE_ILLUMINANCE]: csProfileType(CS_PROFILE_ILLUMINANCE),
            [CS_PROFILE_DURESS]: csProfileType(CS_PROFILE_DURESS),
        }),
        new SuggestFilter('utg_name', __('Group')),
        new ValuesFilter('cls_transport', __('Connection type'), {
            [CS_CONNECTION_TYPE_HTTP]: __('HTTP'),
            [CS_CONNECTION_TYPE_TCP]: __('TCP/IP'),
            [CS_CONNECTION_TYPE_SERIAL]: __('Serial'),
            [CS_CONNECTION_TYPE_FIBRO]: __('Fibro'),
        }),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Central stations')}
        />
    )
}
