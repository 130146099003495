import React from 'react'

import {ReactComponent as IconPrivacy} from 'icons/shield.svg'

import __ from 'utils/i18n'
import {privacyPolicyLink} from 'constants/externalLinks'

export default function PrivacyPolicyLink() {
    return (
        <a
            className="privacyPolicy-link"
            href={privacyPolicyLink}
            target="_blank"
            rel="noreferrer"
        >
            <IconPrivacy />
            {__('Privacy Policy')}
        </a>
    )
}
