import React from 'react'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    setQuery,
} from 'modules/runners/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectRunnersListSearch} from 'modules/runners/list/selectors'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import getRunnerStatusTitle, {list} from 'constants/runnerStatuses'

export default function RunnersSearch({className}) {
    const {filters: selected, query, suggests} = useSelector(selectRunnersListSearch)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new ValuesFilter(
            'status',
            __('Status'),
            list.reduce((acc, value) => {
                acc[value] = getRunnerStatusTitle(value)
                return acc
            }, {})
        ),
        new DateFilter('started', __('Process Started')),
        new SuggestFilter('serial', __('Panel`s Serial')),
        new SuggestFilter('group', __('Panel`s Group')),
        new DateFilter('changed', __('State Changed')),
    ]

    return (
        <Search
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            className={className}
            placeholder={__('Search Job runners')}
        />
    )
}
