import {takeEvery, all, call, put} from 'redux-saga/effects'

import {update} from 'modules/centralStations/store/actions'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'
import {getCentralStation} from 'api/centralStations'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import watch from 'modules/higherOrder/watch'
import {POLL_CS_LIST} from 'configs/pollers'

import {fetch, receive, startPoll, stopPoll} from './actions'

export default function* () {
    yield all([
        takeEvery(fetch, watch(_fetch)),
        createListPollerSaga({startPoll, stopPoll}, POLL_CS_LIST, watchPoll),
    ])
}

function* _fetch({payload: centralStationId}) {
    const data = yield call(getCentralStation, centralStationId)
    yield put(fetchProtocols())

    yield put(update([data]))
    yield put(receive())
}

_fetch.onError = function* (error) {
    yield put(receive(error))
}

function* watchPoll({payload: centralStationId}) {
    const data = yield call(getCentralStation, centralStationId)

    yield put(update([data]))
    yield put(receive())
}

watchPoll.onError = function* (error) {
    yield put(receive(error))
}
