import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import __ from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {selectMfa} from 'modules/system/settings/selectors'

const MFAParameters = () => {
    const {
        otpTtl,
        overallBlockTimeout,
        resendTimeout,
        otpLength,
        resendCount,
        verificationSessionTimes,
        maxAttempts,
        blockTimeout,
    } = useSelector(selectMfa)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('OTP TTL (Seconds)')}</InfoCardTileTitle>
                <InfoCardTileContent>{convertConfigValue(otpTtl)}</InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('OTP Length (Symbols)')}</InfoCardTileTitle>
                <InfoCardTileContent>{convertConfigValue(otpLength)}</InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Resend Timeout (Seconds)')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(resendTimeout)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Resend Count (Times)')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(resendCount)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Verification Sessions Times (Times)')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(verificationSessionTimes)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Failed Attempts Count Blocker (Times)')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxAttempts)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Blocking timeout (Seconds)')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(blockTimeout)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Overall Blocks Timeout (Seconds)')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(overallBlockTimeout)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default MFAParameters
