import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useInstallers from 'hooks/pages/useInstallers'
import {withVisibility} from 'containers/withVisibility'
import {accept as acceptPermission} from 'permissions/installers/actions'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedButton = compose(withVisibility(), withSelectionHandler())(Button)

export default function InstallersAcceptButton() {
    const {accept} = useInstallers()
    const {isPermitted} = usePermission(acceptPermission)

    const onClick = (selection) => accept(selection)

    return (
        <ConnectedButton isVisible={isPermitted} onClick={onClick} label={__('Accept')} />
    )
}
