import React from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import ConfigurationLink from './ConfigurationLink'

export default function ConfigurationTableOfContents({
    onSelect,
    className,
    highlightKey,
    nodes,
}) {
    return (
        <div className={classes('configuration-links', className)}>
            {nodes
                .filter((node) => node.name)
                .map((node) => (
                    <ConfigurationLink
                        key={node.key}
                        node={node}
                        highlightKey={highlightKey}
                        onSelect={onSelect}
                    />
                ))}
        </div>
    )
}

ConfigurationTableOfContents.propTypes = {
    className: PropTypes.any,
    onSelect: PropTypes.func.isRequired,
    nodes: PropTypes.array,
    highlightKey: PropTypes.string,
}
