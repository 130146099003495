import React from 'react'

import useUsers from 'hooks/pages/useUsers'
import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import selectionPermission from 'permissions/users/selection'
import SelectionDropDown from 'components/SelectionDropDown'

const DropDownVisibility = withVisibility()(SelectionDropDown)

export default function UserSelectionDropDown() {
    const {rows, total} = useUsers()
    const {isPermitted} = usePermission(selectionPermission)
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <DropDownVisibility
            rows={rows}
            total={total}
            select={select}
            deselect={deselect}
            selection={selection}
            selectAll={selectAll}
            isVisible={isPermitted}
            deselectAll={deselectAll}
        />
    )
}
