import React, {Fragment} from 'react'

import EventRulesTable from './EventRulesTable'
import EventRulesGridBar from './EventRulesGridBar'

export default function EventRulesPageContent() {
    return (
        <Fragment>
            <EventRulesGridBar />
            <EventRulesTable />
        </Fragment>
    )
}
