import {createSelector} from 'reselect'

export const selectUnitListForPushBasicConfigurationRows = createSelector(
    (state) => state.basicConfiguration.push.page,
    (state) => state.panels.store.byIds,
    (page, store) => page.map((id) => store[id])
)

export const selectBasicConfigurationPushList = createSelector(
    (state) => state.basicConfiguration.push,
    (list) => list
)
