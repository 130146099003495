import React from 'react'
import get from 'lodash-es/get'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {canSuspend} from 'constants/interactiveUserStatus'
import {suspend as suspendPermission} from 'permissions/interactiveUsers/actions'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import * as actions from 'modules/interactiveUsers/store/actions'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler((id, {interactiveUsers}) =>
        canSuspend(get(interactiveUsers, ['store', 'byIds', id, 'status']))
    )
)(Button)

export default function InteractiveSuspendButton() {
    const {isPermitted} = usePermission(suspendPermission)
    const {suspend} = useActions({suspend: actions.suspend})

    return (
        <ConnectedButton
            isVisible={isPermitted}
            label={__('Suspend')}
            onClick={(selection) => suspend(selection)}
        />
    )
}
