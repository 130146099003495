import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Empty from 'ui/Empty'

const HadDeviceDimmerValues = ({had, disabled}) => {
    const value = had?.state?.dimmer?.level

    return (
        <span
            className={classnames('device-had-controls-dimmer', {
                'device-had-controls-dimmer--disabled': Boolean(disabled),
            })}
        >
            {(!!value || value === 0) && (
                <>
                    <strong className="device-had-controls-dimmer-value">{value}</strong>%
                </>
            )}
            {!value && value !== 0 && <Empty />}
        </span>
    )
}

HadDeviceDimmerValues.propTypes = {
    had: PropTypes.object,
    disabled: PropTypes.bool,
}

export default HadDeviceDimmerValues
