import React from 'react'

import Input from 'ipmp-react-ui/Input'
import Select, {Option} from 'ipmp-react-ui/Select'

import __ from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import SelectPgmType from 'modals/Had/SelectPgmType'

export default function AddPgm(props) {
    function onSubmit(data) {
        props.handle(data)
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={onSubmit}
            header={__('Add Had')}
            {...props}
        >
            <SelectPgmType />
            <Select name="parentDevice" label={__('Parent Device')}>
                <Option label="Mainboard" value={0} />
            </Select>
            <Select name="port" label={__('Port')}>
                <Option label="Port 1" value={0} />
            </Select>
            <Select name="location" label={__('Location')}>
                <Option label="Attic" value={0} />
            </Select>
            <Input name="description" label={__('Description')} />
        </ModalCardForm>
    )
}
