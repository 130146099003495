import React from 'react'
import {compose} from 'redux'
import {useParams} from 'react-router'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import withLoader from 'containers/withLoader'
import {pushBasic} from 'permissions/panels/selection'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import {withPermissionRejection} from 'containers/withPermission'
import usePushBasicConfigurations from 'hooks/pages/usePushBasicConfigurations'

const columns = [
    {
        maxWidth: 180,
        name: () => __('Panel'),
        render: ({id, serial, account}) => <PanelCell {...{id, serial, account}} />,
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        width: 250,
        render: ({isSupported}) =>
            isSupported ? null : __('Push configuration is not supported'),
    },
    {
        name: () => __('Model'),
        render: ({model}) => model || <span className="empty">&mdash;</span>,
    },
    {
        name: () => __('Configuration type'),
        render: ({family}) => family || <span className="empty">&mdash;</span>,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(pushBasic),
    withLoader(
        ({init}) => {
            init()
        },
        ({reset}) => {
            reset()
        }
    )
)(Table)

export default function PushBasicConfigurationTable() {
    const {id} = useParams()
    const {selection, select, deselect} = useSelection()
    const pushBasicConfigurations = usePushBasicConfigurations(parseInt(id))

    return (
        <ConnectedTable
            {...pushBasicConfigurations}
            fullHeight
            select={select}
            columns={columns}
            deselect={deselect}
            selection={selection}
            emptyMessage={__('No applicable units found')}
        />
    )
}
