import React from 'react'

import ProcessesGridBar from './ProcessesGridBar'
import ProcessesTable from './ProcessesTable'

import Page from 'ipmp-react-ui/Page'

export default class ProcessesPage extends Page {
    renderBar() {
        return <ProcessesGridBar />
    }

    renderContent() {
        return <ProcessesTable />
    }
}
