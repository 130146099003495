import React from 'react'

import path from 'utils/path'
import RunnersPage from './RunnersPage'

export default class BatchesRunnersRoute {
    path = path('batches.runners')

    element = (<RunnersPage />)
}
