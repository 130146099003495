import React, {useState} from 'react'

import {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'
import {ReactComponent as IconPanel} from 'ipmp-react-ui/icons/panel.svg'
import EmptyPageCard from 'ui/EmptyPageCard'

import EditPanelInfoButton, {
    EditPanelInfoButtonWithText,
} from './Buttons/EditPanelInfoButton'

import {__} from 'utils/i18n'
import withLoader from 'containers/withLoader'
import {isEmptyPanelInfo} from 'utils/panel/IsEmptyPanelInfo'
import {preparePanelInfoGridItems} from './PreparePanelInfoGridItems'
import {isActivationOk} from 'constants/panelActivationStatus'
import {fetch} from 'modules/panels/one/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {convertConfigValue} from 'utils/gettext'

const PanelInfo = ({fotaAotaVersions, panel}) => {
    const [activationStatus, setActivationStatus] = useState(panel.activationStatus)
    if (isEmptyPanelInfo(panel, fotaAotaVersions)) {
        return (
            <EmptyPageCard
                title={__('Panel Info')}
                message={__('No Panel Info')}
                Icon={<IconPanel />}
                Button={<EditPanelInfoButtonWithText />}
            />
        )
    }
    const discoveryPassedRecently =
        panel.activationStatus !== activationStatus &&
        isActivationOk(panel.activationStatus)

    const {fetchPanelInfo} = useActions({fetchPanelInfo: fetch})

    const gridItems = preparePanelInfoGridItems(panel, fotaAotaVersions)

    if (discoveryPassedRecently) {
        fetchPanelInfo(panel.id)
        setActivationStatus(panel.activationStatus)
    }

    const extInfo = [...(panel.extendedInfo || [])]
    extInfo.sort((a, b) => a.metric - b.metric)

    return (
        <>
            <InfoCard
                cols={2}
                title={__('Panel Info')}
                headerButton={<EditPanelInfoButton />}
            >
                {gridItems.map(({title, text}) => (
                    <InfoCardTile key={title}>
                        <InfoCardTileTitle>{title}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(text)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                ))}
            </InfoCard>
            {extInfo.map((item) => (
                <InfoCard cols={2} title={item.name} key={item.key}>
                    {item.values.map(({name, value}) => (
                        <InfoCardTile key={name}>
                            <InfoCardTileTitle>{name}</InfoCardTileTitle>
                            <InfoCardTileContent>
                                {convertConfigValue(value)}
                            </InfoCardTileContent>
                        </InfoCardTile>
                    ))}
                </InfoCard>
            ))}
        </>
    )
}

export default withLoader()(PanelInfo)
