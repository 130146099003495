import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'

import path from 'utils/path'
import usePermission from 'hooks/usePermission'
import permissions from 'permissions/panel/navigation'

const pages = Object.keys(permissions)

export default function PanelLink({id, to, className, children, onClick}) {
    const {permissionsPages} = usePermission({permissionsPages: permissions})

    const getPermittedRoute = (route) => {
        if (permissionsPages[route]) {
            return route
        }

        return pages.find((key) => permissionsPages[key])
    }

    const getPath = () => {
        const route = getPermittedRoute(to)

        if (route && id) {
            return path('panel.' + route, {id})
        }

        return false
    }

    const toPath = getPath()

    if (toPath) {
        return (
            <Link className={className} to={toPath} onClick={onClick}>
                {children}
            </Link>
        )
    }

    return <span className={className}>{children}</span>
}

PanelLink.propTypes = {
    id: PropTypes.number,
    to: PropTypes.oneOf(pages),
}
