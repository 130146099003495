import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {showCentralStationEditModal} from 'modules/modals/actions'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import Button from 'ipmp-react-ui/Button'
import {edit} from 'permissions/centralStations/actions'
import {withPermissionVisibility} from 'containers/withPermission'

export default compose(
    withPermissionVisibility(edit),
    connect(
        () => ({
            borderless: true,
            Icon: IconEdit,
        }),
        (dispatch, {centralStationId}) =>
            bindActionCreators(
                {
                    onClick: () => showCentralStationEditModal(centralStationId),
                },
                dispatch
            )
    )
)(Button)
