import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconKey} from 'ipmp-react-ui/icons/key.svg'

import {RowAction} from 'ui/Row/Actions'

import {useActions} from 'modules/higherOrder/useActions'
import {showEditRolePermission} from 'modules/modals/actions'
import {withPermissionVisibility} from 'containers/withPermission'
import {showPermissions} from 'permissions/roles/actions'

function EditPermissionsAction({id, children}) {
    const onClick = useActions(() => showEditRolePermission(id))

    return (
        <RowAction Icon={IconKey} onClick={onClick}>
            {children}
        </RowAction>
    )
}

EditPermissionsAction.propTypes = {
    id: PropTypes.string.isRequired,
}

export default withPermissionVisibility(showPermissions)(EditPermissionsAction)
