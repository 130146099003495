import axios from 'axios'
import {err} from 'utils/log'
import getAudioContext from 'utils/getAudioContext'

const audioContext = getAudioContext()

export default function getSoundBuffer(path) {
    return axios
        .get(path, {responseType: 'arraybuffer'})
        .then((response) => audioContext.decodeAudioData(response.data))
        .catch((error) => err(`Error load and decode audio data: ${error.message}`))
}
