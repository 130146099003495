import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {selectCountryNameByAlphaCode} from 'modules/countries/selectors'
import * as actions from 'modules/countries/actions'

const useCountries = () => {
    const {fetch} = useActions(
        {
            fetch: actions.fetch,
        },
        []
    )

    return {
        selectByAlpha3Code: (countryCode) =>
            useSelector((state) => selectCountryNameByAlphaCode(state, countryCode)),
        fetch,
    }
}

export default useCountries
