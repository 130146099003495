import React, {useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {selectZWaveDskReportRequest} from 'modules/devices/autolearn/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    completeZWaveDskReport,
    sendZWaveDskReport,
} from 'modules/devices/autolearn/actions'

import DskReportButtons from './DskReportButtons'
import DskReportForm from './DskReportForm'

export default function useDskReport(session) {
    const request = useSelector((state) => selectZWaveDskReportRequest(state, {session}))
    const [handle, dismiss] = useActions([sendZWaveDskReport, completeZWaveDskReport])
    const [code, setCode] = useState([])
    const codeRef = useRef(code)
    codeRef.current = code

    const buildDsk = () =>
        JSON.parse(request?.zWaveDsk || '[]').map((v, i) =>
            i < 2 ? codeRef.current[i] : v
        )

    const onClick = () => handle({session, data: {is_available: true, dsk: buildDsk()}})
    const onDismiss = () => dismiss(session, {dsk: buildDsk()})

    return useMemo(
        () =>
            !session.token || !request
                ? null
                : {
                      Content: () => (
                          <DskReportForm
                              dsk={parseDsk(request?.zWaveDsk)}
                              setCode={setCode}
                          />
                      ),
                      Actions: () => (
                          <DskReportButtons onClick={onClick} onDismiss={onDismiss} />
                      ),
                  },
        [session, request]
    )
}

function parseDsk(dsk) {
    return JSON.parse(dsk || '[]')
        .map((it) => it & 0xff)
        .reduce((acc, curr, index, array) => {
            if (index % 2 === 0) {
                acc.push((curr << 8) | array[index + 1])
            }
            return acc
        }, [])
}
