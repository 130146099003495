import React from 'react'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {convertConfigValue} from 'utils/gettext'

export default function EventProfileList({profiles, availableProfiles}) {
    return (
        <>
            {Object.keys(availableProfiles).map((profile, index) => {
                const tileContentClass = profiles[profile] ? 'enabled' : 'disabled'
                return (
                    <InfoCardTile key={profile}>
                        <InfoCardTileTitle>
                            {availableProfiles[profile]}
                        </InfoCardTileTitle>
                        <InfoCardTileContent className={tileContentClass}>
                            {convertConfigValue(profiles[profile])}
                        </InfoCardTileContent>
                    </InfoCardTile>
                )
            })}
        </>
    )
}
