import React from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import {Route, Routes} from 'react-router-dom'

import {GroupTitle} from 'components/TableCells/GroupCell'

import {customizations, general, notificationsList} from 'permissions/group/navigation'
import centralStationsList from 'permissions/centralStations/list'
import {withVisibility} from 'containers/withVisibility'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import path, {pathLastChild, pathTrimEndSlash} from 'utils/path'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'

import StationsPager from '../Stations/Bar/StationsPager'

function GroupBarNavBase(props) {
    const {
        group: {id, linkedToDealer, name},
        isStationsTabPermitted,
        isCustomizationsTabPermitted,
        isNotificationsTabPermitted,
    } = props

    const navigate = useNavigate()
    const goBack = () => navigate(path('groups'))

    const Title = <GroupTitle name={name} linkedToDealer={linkedToDealer} />

    return (
        <NavTabs className="group-nav" title={Title} goBack={goBack}>
            <NavTabLink end to={pathTrimEndSlash('group.general', {id})}>
                {__('General')}
            </NavTabLink>
            {isStationsTabPermitted && (
                <NavTabLink to={pathTrimEndSlash('group.stations', {id})}>
                    {__('Communication')}
                </NavTabLink>
            )}
            {isNotificationsTabPermitted && (
                <NavTabLink to={pathTrimEndSlash('group.notifications', {id})}>
                    {__('Notifications Settings')}
                </NavTabLink>
            )}
            {isCustomizationsTabPermitted && (
                <NavTabLink to={pathTrimEndSlash('group.customizations', {id})}>
                    {__('App customization')}
                </NavTabLink>
            )}
            <Routes>
                <Route
                    path={pathLastChild('group.stations')}
                    element={<StationsPager />}
                />
            </Routes>
        </NavTabs>
    )
}

GroupBarNav.propTypes = {
    group: PropTypes.shape({
        id: PropTypes.number,
    }),
}

const GroupBarNavWithVisibility = withVisibility()(GroupBarNavBase)

export default function GroupBarNav(props) {
    const permissions = usePermission({
        isVisible: general,
        isStationsTabPermitted: centralStationsList,
        isCustomizationsTabPermitted: customizations,
        isNotificationsTabPermitted: notificationsList,
    })

    const nabBarProps = {...props, ...permissions}

    return <GroupBarNavWithVisibility {...nabBarProps} />
}
