import React from 'react'
import {Route, Routes} from 'react-router-dom'
import tabs, {
    CENTRAL_STATION_TAB_COMMUNICATION,
    CENTRAL_STATION_TAB_GENERAL,
} from './CentralStationRouting'
import General from './General/General'
import Communication from './Communication/Communication'
import {pathLastChild} from 'utils/path'

const componentsMap = new Map([
    [CENTRAL_STATION_TAB_GENERAL, <General />],
    [CENTRAL_STATION_TAB_COMMUNICATION, <Communication />],
])

const CentralStationContent = () => {
    return (
        <Routes>
            {[...tabs.entries()].map(([key, {path: pathName}]) => (
                <Route
                    path={pathLastChild(pathName)}
                    exact
                    element={componentsMap.get(key)}
                    key={key}
                />
            ))}
        </Routes>
    )
}

export default CentralStationContent
