import React from 'react'
import PropTypes from 'prop-types'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import SelectBroker from './SelectBroker'
import {setSystemSettings} from 'modules/forms/handlers'

const rules = {
    messageBroker: {},
    retriesMax: {
        presence: {
            message: () => __('You should define Number of Retries'),
        },
        numericality: {
            onlyInteger: true,
        },
    },
    retryInterval: {
        presence: {
            message: () => __('You should define Retries Interval'),
        },
        numericality: {
            onlyInteger: true,
        },
    },
}

export default function CellularConnectedSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.CellularConnected)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Edit Cellular Connected Settings')}
        >
            <Checkbox
                label={__('Enable Auto-Enroll')}
                name="isAutoEnrollmentEnabled"
                defaultChecked={data.isAutoEnrollmentEnabled}
            />

            <Disclaimer
                className="settings-card-disclaimer"
                children={__(
                    'Before enabling auto-enroll, ensure that cellular supervision is enabled for PowerMaster/PowerMax panels in the Main Group. Enabling this option can lead to irreversible consequences.'
                )}
            />
            <SelectBroker
                label={__('Message Broker for WakeUp')}
                name="messageBroker"
                defaultValue={data.messageBroker}
            />

            <Input
                label={__('Number of Retries')}
                name="retriesMax"
                defaultValue={data.retriesMax}
            />

            <Input
                label={__('Retries Interval [seconds]')}
                name="retryInterval"
                defaultValue={data.retryInterval}
            />

            <Checkbox
                label={__('WakeUp panel on user initiated discovery')}
                name="wakeupOnUserDiscovery"
                defaultChecked={data.wakeupOnUserDiscovery}
            />
        </ModalCardForm>
    )
}

CellularConnectedSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        isAutoEnrollmentEnabled: PropTypes.bool,
        messageBroker: PropTypes.number,
        retriesMax: PropTypes.number,
        retryInterval: PropTypes.number,
        wakeupOnUserDiscovery: PropTypes.bool,
    }),
}
