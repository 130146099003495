import React from 'react'

import {ReactComponent as IconFire} from 'ipmp-react-ui/icons/events/IconFire.svg'
import {ReactComponent as IconAlarm} from 'ipmp-react-ui/icons/alarm.svg'

import __ from 'utils/i18n'
import {PARTITION_ALL, PARTITION_LABEL_ALL} from 'constants/partitions'
import PartitionsSelect from 'components/Panel/Emergencies/PartitionsSelect'

export default function FireContent({panelId, setSelected}) {
    return (
        <div className="init-emergency-chosen-block">
            <div className="init-emergency-block-content-disclaimer">
                <div className="init-emergency-block-content-image">
                    <IconFire />
                </div>
                <h1>{__('Your are going to initiate fire panic')}</h1>
                <h3>
                    {__(
                        'Please choose the partitions for which you need to apply fire panic'
                    )}
                </h3>
                <div className="disclaimer">
                    <IconAlarm />
                    <div>
                        <h2>{__('AUDIBLE ALARM')}</h2>
                        <h3>
                            {__(
                                'Fire alarm will be transferred to central monitoring stations and activate siren,' +
                                    ' that may lead to hearing damage or other unwanted consequences, if facility is currently attended'
                            )}
                        </h3>
                    </div>
                </div>
            </div>
            <hr />
            <PartitionsSelect
                setSelected={setSelected}
                panelId={panelId}
                defaultValues={[{value: PARTITION_ALL, label: PARTITION_LABEL_ALL}]}
            />
        </div>
    )
}
