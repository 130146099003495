import React from 'react'
import PropTypes from 'prop-types'

import Table from 'ipmp-react-ui/Table'

import __ from 'utils/i18n'

const columns = [
    {
        name: () => __('ID'),
        width: 40,
        render: ({id}) => id,
    },
    {
        name: () => __('Type'),
        width: 100,
        render: ({type}) => type,
    },
    {
        name: () => __('Name'),
        width: 122,
        render: ({name}) => name,
    },
    {
        name: () => __('State'),
        width: 100,
        render: ({state}) => state,
    },
    {
        name: () => __('Status'),
        width: 100,
        render: ({status}) => status,
    },
]

export default function AddedDevicesTable({devices}) {
    return <Table columns={columns} rows={devices} />
}

AddedDevicesTable.propTypes = {
    devices: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
            name: PropTypes.string,
            state: PropTypes.string,
            status: PropTypes.string,
        })
    ).isRequired,
}
