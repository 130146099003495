import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import SelectBroker from './SelectBroker'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

export default function UserNotificationsSettings({data, hide}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.UserNotification)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            onSubmit={handle}
            isLoading={isLoading}
            confirmOnDismiss
            header={__('Edit Customer Notifications Settings')}
        >
            <SelectBroker
                label={__('Message Broker for SMS by server')}
                name="messageBroker"
                defaultValue={data.messageBroker}
            />

            <Checkbox
                label={__('Enable Emails with Attached Video')}
                name="isEmailWithVideoEnabled"
                defaultChecked={data.isEmailWithVideoEnabled}
            />

            <Checkbox
                label={__('Enable Emails without Attached Video')}
                name="isEmailWithoutVideoEnabled"
                defaultChecked={data.isEmailWithoutVideoEnabled}
            />
        </ModalCardForm>
    )
}

UserNotificationsSettings.propTypes = {
    data: PropTypes.shape({
        isEmailWithVideoEnabled: PropTypes.bool,
        isEmailWithoutVideoEnabled: PropTypes.bool,
        messageBroker: PropTypes.number,
    }),
    hide: PropTypes.func.isRequired,
}
