import React, {useState} from 'react'

import {compose} from 'redux'
import {connect} from 'react-redux'

import get from 'lodash-es/get'
import classnames from 'classnames'

import Error from 'ipmp-react-ui/Error'
import {ScrollView} from 'ipmp-react-ui/Layout'
import RadioBox from 'ipmp-react-ui/Radiobox'
import {InteractiveBar} from 'ipmp-react-ui'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as DarkModeIcon} from 'ipmp-react-ui/icons/night.svg'
import {ReactComponent as LightModeIcon} from 'ipmp-react-ui/icons/sunny.svg'
import {Matrix} from 'pages/Group/Customizations/Matrix'
import Tooltip from 'ipmp-react-ui/Tooltip'

import {customizations as customizationsPermission} from 'permissions/group/navigation'
import useCustomizations from 'hooks/pages/useCustomizations'
import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import {THEME_LIGHT, THEME_DARK} from 'constants/groups'

import {__} from 'utils/i18n'

const Theme = ({
    customization: {
        id: customizationId,
        name,
        data: {customization: customizationData},
    },
    isSelected,
    isChosen,
    handle,
    index,
}) => {
    const [brightness, setBrightness] = useState(THEME_LIGHT)

    const toggleBrightness = (e) => {
        e.stopPropagation()
        setBrightness(brightness === THEME_LIGHT ? THEME_DARK : THEME_LIGHT)
    }

    const themes = get(customizationData, 'themes', [])

    const themeLight =
        themes.find(({brightness: themeBrightness}) => themeBrightness === THEME_LIGHT) ||
        null
    const themeDark =
        themes.find(({brightness: themeBrightness}) => themeBrightness === THEME_DARK) ||
        null

    const currentTheme =
        themes.length === 1
            ? themes[0]
            : themes.find(
                  ({brightness: themeBrightness}) => themeBrightness === brightness
              ) || null

    const logo = get(customizationData, 'logo', null)

    const modeSwitcher = () => {
        if (themeLight === null || themeDark === null) {
            return null
        }

        const modeSwitcherData = {
            onClick: toggleBrightness,
            className: 'brightness-switcher',
        }

        if (currentTheme.brightness === THEME_LIGHT) {
            return <DarkModeIcon {...modeSwitcherData} width="24" />
        }

        return <LightModeIcon {...modeSwitcherData} width="24" />
    }

    return (
        <div
            onClick={() => handle(customizationId)}
            className={classnames('customization-theme', {
                'customization-theme-chosen': isChosen,
                'customization-theme-selected': isSelected,
            })}
        >
            {currentTheme && <Matrix theme={currentTheme} logo={logo} index={index} />}
            <div className="customization-bottom">
                <div className="customization-theme-info">
                    <Tooltip tooltip={name}>
                        <RadioBox
                            name="customization"
                            title={name}
                            label={name}
                            checked={isSelected}
                            onChange={() => {}}
                            noInk
                        />
                    </Tooltip>
                </div>
                {modeSwitcher()}
            </div>
        </div>
    )
}

function BaseCustomizations({
    available: availableCustomizations,
    isUncommittedCustomization,
    selectedCustomizationId,
    uncommittedCustomization,
    save,
    dismiss,
    handle,
}) {
    const isUseDefault = selectedCustomizationId === null

    if (availableCustomizations?.length === 0) {
        return <Error title={__('No customizations')} />
    }

    return (
        <>
            <InteractiveBar
                className="group-customizations-interactiveBar"
                active={isUncommittedCustomization}
            >
                {__(
                    'Selected theme will be applied to all mobile apps related to the panels in group.'
                )}

                <Button
                    className="save-customization"
                    small
                    primary
                    label={__('Save')}
                    onClick={() => save(uncommittedCustomization.id)}
                />
                <Button className="dismiss-customization" borderless onClick={dismiss}>
                    {__('Dismiss')}
                </Button>
            </InteractiveBar>

            <ScrollView className="group-customization">
                {availableCustomizations.map((customization, key) => {
                    const isSelected =
                        selectedCustomizationId === customization.id ||
                        (isUseDefault && customization.isDefault)

                    const isChosen =
                        uncommittedCustomization &&
                        uncommittedCustomization.id === customization.id &&
                        !isSelected

                    return (
                        <Theme
                            key={key}
                            handle={handle}
                            isSelected={isSelected}
                            isChosen={isChosen}
                            customization={customization}
                            index={key}
                        />
                    )
                })}
            </ScrollView>
        </>
    )
}

const checkUncommittedCustomization = (
    uncommittedCustomization,
    selectedCustomizationId
) =>
    uncommittedCustomization &&
    uncommittedCustomization.id !== selectedCustomizationId &&
    !(uncommittedCustomization.isDefault && selectedCustomizationId === null)

const ConnectCustomizations = compose(
    withPermissionRejection(customizationsPermission),
    withLoader(({fetchOne, fetchAll, groupId}) => {
        fetchAll()
        fetchOne(groupId)
    }),
    connect(
        (
            {
                groups: {
                    customizations: {selected, uncommitted, available},
                },
            },
            {groupId}
        ) => {
            const selectedCustomizationId = get(selected.byIds, [groupId], null)
            const uncommittedCustomizationId = get(uncommitted.byIds, [groupId], null)
            const uncommittedCustomization = available.find(
                (customization) => customization.id === uncommittedCustomizationId
            )
            const isUncommittedCustomization = checkUncommittedCustomization(
                uncommittedCustomization,
                selectedCustomizationId
            )

            return {
                isUncommittedCustomization,
                selectedCustomizationId,
                uncommittedCustomization,
                groupId,
            }
        }
    )
)(BaseCustomizations)

export default function Customizations(props) {
    const {
        group: {id: groupId},
    } = props
    const customizations = useCustomizations(groupId)

    return (
        <ConnectCustomizations
            {...customizations}
            isLoading={customizations.isLoading}
            groupId={groupId}
        />
    )
}
