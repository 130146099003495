import React from 'react'
import {useNavigate, useParams} from 'react-router'
import {Route, Routes} from 'react-router-dom'

import NavTabs, {NavTabLink} from 'ipmp-react-ui/NavTabs'

import tabs from './CentralStationRouting'
import path, {pathLastChild, pathTrimEndSlash} from 'utils/path'
import {useSelector} from 'react-redux'
import {oneCentralStationDataSelect} from 'modules/centralStations/one/selectors'
import CommunicationBar from 'pages/CentralStation/Communication/CommunicationBar'
import usePermission from 'hooks/usePermission'

const filtrateProhibition = (key) => {
    const {isVisiblePermission} = tabs.get(key)
    const {isPermitted} = usePermission(isVisiblePermission)

    return isPermitted
}

const CentralStationTop = () => {
    const {id} = useParams()
    const oneCentralStationData = useSelector((state) =>
        oneCentralStationDataSelect(state, {id})
    )

    const links = [...tabs.keys()]

    const navigate = useNavigate()
    const goBack = () => navigate(path('centralStations'))

    return (
        <NavTabs
            className="central-station-tabs"
            title={oneCentralStationData?.name}
            goBack={goBack}
        >
            {links.filter(filtrateProhibition).map((key) => {
                const {label, path: pathName, end} = tabs.get(key)

                return (
                    <NavTabLink key={key} to={pathTrimEndSlash(pathName, {id})} end={end}>
                        {label()}
                    </NavTabLink>
                )
            })}
            <Routes>
                <Route
                    path={pathLastChild('centralStation.communication')}
                    element={<CommunicationBar />}
                />
            </Routes>
        </NavTabs>
    )
}

export default CentralStationTop
