import React from 'react'
import {useParams} from 'react-router'

import Buttons from 'ipmp-react-ui/Buttons'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import usePermission from 'hooks/usePermission'
import {pushBasic} from 'permissions/panels/selection'
import {withVisibility} from 'containers/withVisibility'
import PushBasicConfigurationPager from './GridBar/PushBasicConfigurationPager'
import PushBasicConfigurationButton from './GridBar/PushBasicConfigurationButton'
import PushBasicConfigurationDropDown from './GridBar/PushBasicConfigurationDropDown'

function FirmwareUpgradeGridBar() {
    const {id} = useParams()

    return (
        <Bar>
            <PushBasicConfigurationDropDown />

            <Buttons>
                <PushBasicConfigurationButton basicConfigId={parseInt(id)} />
            </Buttons>

            <BarSpace />

            <PushBasicConfigurationPager />
        </Bar>
    )
}

const FirmwareUpgradeGridBarVisibility = withVisibility()(FirmwareUpgradeGridBar)

export default function () {
    const {isPermitted} = usePermission(pushBasic)

    return <FirmwareUpgradeGridBarVisibility isVisible={isPermitted} />
}
