import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ProcessCounters from 'components/Processes/ProcessCounters'
import {__} from 'utils/i18n'
import parseDate from 'api/base/parseDate'
import momentPropType from 'utils/momentPropType'
import processType from 'constants/processType'

import {ReactComponent as DropDownIcon} from 'ipmp-react-ui/icons/drop-down.svg'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/cross.svg'
import Duration from 'ipmp-react-ui/Duration'

import useWindowDimensions from 'hooks/useWindowDimensions'
import {useActions} from 'modules/higherOrder/useActions'
import {scroll} from 'modules/navBlind/actions'

import {scrollingElementClass} from './ProcessBatches'

export const activeElementClass = 'main-sidebar-process--active'
export const screenOffsetTop = 150
export const screenOffsetBottom = 70

const ProcessBatch = ({
    batch,
    untrack,
    openedBatchId,
    selectedBatchId,
    showInfo,
    setOpenedBatch,
    closeNavBlind,
    scrollTop,
    isNew = false,
}) => {
    const elementRef = React.createRef()
    const accentuatedElementClass = 'process--accent'
    const {height} = useWindowDimensions()
    const setScroll = useActions(scroll)

    useEffect(() => {
        const activeBatchItemPosition = document
            .querySelector('.' + activeElementClass)
            ?.getBoundingClientRect()

        const isActiveElementAbroad =
            activeBatchItemPosition?.bottom < screenOffsetTop ||
            activeBatchItemPosition?.top > height - screenOffsetBottom

        if (isActiveElementAbroad) {
            closeNavBlind()
            setOpenedBatch(null)
        }
        setScroll(scrollTop)
    }, [scrollTop])

    useEffect(() => {
        if (selectedBatchId.value === batch.id) {
            const isElementInView = (scrollingElement, elem) => {
                const listViewTop = scrollingElement.scrollTop
                const listViewBottom = listViewTop + scrollingElement.offsetHeight

                const elemTop = elem.offsetTop
                const elemBottom = elemTop + elem.offsetHeight

                return elemBottom <= listViewBottom && elemTop >= listViewTop
            }

            setTimeout(() => {
                const current = elementRef.current
                if (current === null) {
                    selectedBatchId.clear()
                    return
                }

                const tableRow = current.closest('.table-row')
                const scrollingElement = current.closest('.' + scrollingElementClass)

                if (!isElementInView(scrollingElement, tableRow)) {
                    const start = scrollingElement.scrollTop,
                        change = tableRow.offsetTop - start,
                        increment = 20,
                        duration = 1000
                    let currentTime = 0

                    const easeInOutQuad = function (
                        currentTime,
                        start,
                        change,
                        duration
                    ) {
                        currentTime /= duration / 2
                        if (currentTime < 1) {
                            return (change / 2) * currentTime * currentTime + start
                        }
                        currentTime--
                        return (
                            (-change / 2) * (currentTime * (currentTime - 2) - 1) + start
                        )
                    }

                    const animateScroll = function () {
                        currentTime += increment
                        scrollingElement.scrollTop = easeInOutQuad(
                            currentTime,
                            start,
                            change,
                            duration
                        )
                        if (currentTime < duration) {
                            setTimeout(animateScroll, increment)
                        } else {
                            setTimeout(() => {
                                current.classList.remove(accentuatedElementClass)
                                selectedBatchId.clear()
                            }, 1000)
                        }
                    }

                    animateScroll()
                } else {
                    setTimeout(() => {
                        current.classList.remove(accentuatedElementClass)
                        selectedBatchId.clear()
                    }, 1000)
                }
            }, 1000)
        }
    }, [selectedBatchId])

    const handleShowInfo = () => {
        setOpenedBatch(batch.id)
        showInfo(batch.id)
    }

    const handleUntrack = (e) => {
        e.stopPropagation()
        untrack(batch.id)
    }

    const renderDuration = () => {
        let {started, finished} = batch

        started = parseDate(started)
        finished = parseDate(finished)

        if (finished) {
            return (
                <div className="process-time">
                    {__('Finished in:')} <Duration from={started} to={finished} />
                </div>
            )
        }

        return (
            <div className="process-time">
                <Duration from={started} />
            </div>
        )
    }

    const {type, stats, panels, panelSerial} = batch
    const className = classnames('process main-sidebar-process', {
        [accentuatedElementClass]: selectedBatchId.value === batch.id,
        [activeElementClass]: openedBatchId === batch.id,
        isNew,
    })

    return (
        <div className={className} onClick={handleShowInfo} ref={elementRef}>
            <div className="process-title">
                <div className="hint">
                    <span className="hint-panel">
                        {panels > 1 ? __('%d panels', panels) : panelSerial}
                    </span>
                    {untrack &&
                        !stats.handled &&
                        !stats.started &&
                        !stats.notStarted &&
                        !stats.pending && (
                            <span className="process-untrack">
                                <IconClose
                                    className="process-title-icon"
                                    onClick={handleUntrack}
                                />
                            </span>
                        )}
                    {renderDuration()}
                </div>

                <div className="process-type">{processType(type)}</div>
            </div>

            <ProcessCounters stats={stats} />
            <DropDownIcon className="process-dropdown" />
        </div>
    )
}

ProcessBatch.propTypes = {
    batch: PropTypes.shape({
        type: PropTypes.string.isRequired,
        started: PropTypes.oneOfType([PropTypes.string, momentPropType]).isRequired,
        finished: PropTypes.oneOfType([PropTypes.string, momentPropType]),
        stats: PropTypes.shape({
            succeeded: PropTypes.number.isRequired,
            handled: PropTypes.number.isRequired,
            failed: PropTypes.number.isRequired,
            start: PropTypes.number.isRequired,
        }).isRequired,
        panels: PropTypes.number,
        panelSerial: PropTypes.string,
    }),
    untrack: PropTypes.func.isRequired,
    showInfo: PropTypes.func.isRequired,
    openedBatchId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedBatchId: PropTypes.shape({
        value: PropTypes.string,
        clear: PropTypes.func,
    }),
}

export default ProcessBatch
