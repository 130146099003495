import React from 'react'

import Page from 'ipmp-react-ui/Page'

import ActionLogTable from './ActionLogTable'
import ActionLogGridBar from './ActionLogGridBar'

export default function ActionLogPage() {
    return <Page Bar={ActionLogGridBar} Content={ActionLogTable} />
}
