import React from 'react'

import BatchesPage from './BatchesPage'
import path from 'utils/path'

export default class BatchesRoute {
    path = path('batches')

    element = (<BatchesPage />)
}
