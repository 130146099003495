import {cancel, fork, take, call, race, delay, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

export default function* createListPollerSaga(listActions, interval, iteratee) {
    while (true) {
        const action = yield take(listActions.startPoll)

        const pollTask = yield fork(
            poller,
            listActions.stopPoll,
            interval,
            iteratee,
            [
                listActions.setStart,
                listActions.setQuery,
                listActions.setFilters,
                listActions.clearFilters,
                listActions.addFilters,
                listActions.removeFilters,
            ].filter((action) => action !== undefined),
            action
        )

        yield take(listActions.stopPoll)
        yield cancel(pollTask)
    }
}

export function* poller(stopAction, interval, iteratee, cancelActions, ...args) {
    while (true) {
        if (Number.isInteger(interval)) {
            yield delay(interval)
        } else {
            yield call(interval, ...args)
        }

        try {
            yield race([call(iteratee, ...args), take(cancelActions)])
        } catch (e) {
            if (e.isServerError && [401, 403].includes(e.status)) {
                yield put(stopAction())
            } else if ('message' in e) {
                yield put(snackShow(e.message))
            }

            if (iteratee.onError instanceof Function) {
                yield iteratee.onError(e, ...args)
            }
        }
    }
}
