import React from 'react'

import Page from 'ipmp-react-ui/Page'

import list from 'permissions/firmware/list'
import FirmwarePatchTagUpdateTable from './FirmwarePatchTagUpdateTable'
import FirmwarePatchTagUpdateGridBar from './FirmwarePatchTagUpdateGridBar'
import {withPermissionRejection} from 'containers/withPermission'

function FirmwarePatchTagPageBase() {
    return (
        <Page Bar={FirmwarePatchTagUpdateGridBar} Content={FirmwarePatchTagUpdateTable} />
    )
}

export default withPermissionRejection(list)(FirmwarePatchTagPageBase)
