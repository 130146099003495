import React from 'react'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {__, __n} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {changeGroup} from 'modules/forms/handlers'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchGroups} from 'modules/groups/select/actions'

const GroupSelect = withSelectLoader(
    fetchGroups,
    (state) => state.groups.select.isLoading,
    (state) =>
        Object.values(state.groups.store.byIds).map(({id, name}) => ({
            label: name,
            value: id,
        })),
    10
)(Autocomplete)

const rules = {
    groupId: {
        presence: true,
    },
}

export default function ChangePanelGroup({panelIds, hide}) {
    const {form, isLoading, handle} = useForm(changeGroup)

    const handler = ({groupId}) => {
        handle(panelIds, groupId)
    }

    return (
        <ModalCardForm
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            header={__n(
                'Assign %d panel to group',
                'Assign %s panels to group',
                panelIds.length,
                [panelIds.length]
            )}
            {...form}
        >
            <GroupSelect showTypeForMore label={__('Group')} name="groupId" />
        </ModalCardForm>
    )
}
