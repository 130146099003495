import {call, put} from 'redux-saga/effects'

import * as api from 'api/panels'

import generateBatch from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_PMAXCONFIGUPLOAD} from 'constants/processTypes'
import {snackShow} from 'modules/snacks'

export default function* watchPushBasicConfiguration({
    payload: {basicConfigId, panelIds},
}) {
    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_PMAXCONFIGUPLOAD,
        panelIds
    )

    try {
        yield call(api.pushBasicConfiguration, panelIds, basicConfigId, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
