export const PARTITION_ALL = -1
export const PARTITION_LABEL_ALL = 'All'
export const PARTITION_LABEL_SYSTEM = 'SYSTEM'

export const DEFAULT_PARTITION_VALUE = 1

export const preparePartitionName = (id, name) => {
    const label = name && name === PARTITION_LABEL_SYSTEM ? name : 'Partition ' + id
    return {value: id, label: label}
}
