import React from 'react'

import useSelection from 'hooks/useSelection'
import useRunners from 'hooks/pages/useRunners'
import SelectionDropDown from 'components/SelectionDropDown'

export default function RunnersDropDown() {
    const {rows, total} = useRunners()
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <SelectionDropDown
            rows={rows}
            total={total}
            selection={selection}
            select={select}
            deselect={deselect}
            deselectAll={deselectAll}
            selectAll={selectAll}
        />
    )
}
