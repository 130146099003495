import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

import {selectZWaveKeysReportRequest} from 'modules/devices/autolearn/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    completeZWaveKeysReport,
    sendZWaveKeysReport,
} from 'modules/devices/autolearn/actions'

import KeysReportButtons from './KeysReportButtons'
import KeysReportForm from './KeysReportForm'

export default function useKeysReport(session) {
    const request = useSelector((state) => selectZWaveKeysReportRequest(state, {session}))
    const [handle, dismiss] = useActions([sendZWaveKeysReport, completeZWaveKeysReport])

    const [keys, setKeys] = useState(request?.zWaveKeys)
    useEffect(() => setKeys(request?.zWaveKeys), [request?.zWaveKeys])
    const keysRef = useRef(keys)
    keysRef.current = keys

    const onClick = () =>
        handle({
            session,
            data: {
                node_id: request.nodeId,
                keys: keysRef.current,
                csa: request.zWaveCsa,
            },
        })

    const onDismiss = () => dismiss(session, request?.nodeId)

    return useMemo(
        () =>
            !session.token || !request
                ? null
                : {
                      Content: () => (
                          <KeysReportForm keys={keysRef.current} setKeys={setKeys} />
                      ),
                      Actions: () => (
                          <KeysReportButtons onClick={onClick} onDismiss={onDismiss} />
                      ),
                  },
        [session, request]
    )
}
