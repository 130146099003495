import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {unlock} from 'modules/panels/had/actions'
import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'

const SetHadUnlockButton = ({id, deviceType, label, disabled}) => {
    const {onClick} = useActions({
        onClick: unlock,
    })

    const onClickHandler = (event) => {
        event.stopPropagation()
        onClick({id, deviceType})
    }

    return (
        <Button
            disabled={disabled}
            onClick={onClickHandler}
            flat
            label={label ? label : __('Turn Off')}
        />
    )
}

SetHadUnlockButton.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
    deviceType: PropTypes.string,
}

export default SetHadUnlockButton
