import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {showCentralStationCreateModal} from 'modules/modals/actions'
import {selectCentralStationsList} from 'modules/centralStations/store/selectors'
import {init, remove, reset, setStart} from 'modules/centralStations/list/actions'

export default function useCentralStations() {
    const {isLoading, total, start, rows} = useSelector(selectCentralStationsList)
    const {onPerPageChange, perPage} = usePerPage('centralStations')

    return {
        perPage,
        onPerPageChange,
        isLoading,
        total,
        start,
        rows,
        ...useActions({
            init,
            reset,
            remove,
            onPageChange: setStart,
            showCentralStationCreateModal,
        }),
    }
}
