import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/check.svg'
import Definition from 'ipmp-react-ui/Definition'
import ProgressBar from 'ipmp-react-ui/ProgressBar'
import Spinner from 'ipmp-react-ui/Spinner'

import TooltipList, {TooltipListItem} from 'ui/TooltipList'

import processErrorMessage from 'constants/processError'
import getRunnerStatusTitle from 'constants/runnerStatuses'

import {__} from 'utils/i18n'

export default function ProcessStatus({
    row: {
        isNotStarted,
        isStarted,
        isHandled,
        isFailed,
        isSuccessful,
        isFailedToStart,
        details,
        percentage,
        error,
        errorMessage,
        status,
    },
    runnerTable = false,
    isStopping,
}) {
    const renderNotStarted = () => {
        if (isFailedToStart) {
            return (
                <Definition
                    className="danger process-table-definition-cell"
                    Icon={IconAlert}
                    title={errorMessage || __('Failed to start')}
                />
            )
        }

        return <Definition Icon={Spinner} title={__('Starting…')} />
    }

    const renderStarted = () => {
        return (
            <>
                <ProgressBar
                    inline={!!details}
                    className="progress-bar--indeterminate-started"
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                />
                {details}
            </>
        )
    }

    const renderFailed = () => {
        const tooltipContent = (error, errorMessage) => (
            <TooltipList title={processErrorMessage(error, errorMessage)} error>
                <TooltipListItem monospace>{errorMessage}</TooltipListItem>
            </TooltipList>
        )

        return (
            <Definition
                className="danger"
                Icon={IconAlert}
                title={processErrorMessage(error, errorMessage)}
                detail={errorMessage}
                detailTooltip={tooltipContent(error, errorMessage)}
                multiLine
            />
        )
    }

    const renderHandled = () => {
        if (!details) {
            return (
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                    className="progress-bar--indeterminate-started"
                />
            )
        }

        return (
            <>
                <ProgressBar
                    max={100}
                    className="progress-bar--indeterminate-started"
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                    inline
                />
                {details}
            </>
        )
    }

    const renderSuccessful = () => {
        return (
            <Definition
                className="success process-table-definition-cell"
                Icon={IconCompleted}
                title={__('Succeeded')}
            />
        )
    }

    const renderStopping = () => {
        return (
            <Definition
                className="danger process-table-definition-cell"
                Icon={IconAlert}
                title={__('Stopping...')}
            />
        )
    }

    const renderStatus = () => {
        if (runnerTable) {
            return getRunnerStatusTitle(status)
        }
        return status
    }

    if (isStopping) {
        return renderStopping()
    }

    if (!!isNotStarted || !!isFailedToStart) {
        return renderNotStarted()
    }

    if (isFailed) {
        return renderFailed()
    }

    if (isStarted) {
        return renderStarted()
    }

    if (isHandled) {
        return renderHandled()
    }

    if (isSuccessful) {
        return renderSuccessful()
    }

    return renderStatus()
}

ProcessStatus.propTypes = {
    row: PropTypes.shape({
        isFailedToStart: PropTypes.bool,
        errorMessage: PropTypes.string,
        error: PropTypes.string,
        details: PropTypes.string,
        percentage: PropTypes.number,
        id: PropTypes.number,
        status: PropTypes.string,
        isRunning: PropTypes.bool,
        isFailed: PropTypes.bool,
        isSuccessful: PropTypes.bool,
    }),
    isStopping: PropTypes.bool,
}
