import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'
import get from 'lodash-es/get'

import MultiSelect from 'ipmp-react-ui/MultiSelect'
import {ReactComponent as IconPartition} from 'ipmp-react-ui/icons/icons-partition.svg'

import {fetch as fetchPartitions} from 'modules/panels/state/actions'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import withSelectLoader from 'containers/withSelectLoader'
import {
    DEFAULT_PARTITION_VALUE,
    PARTITION_ALL,
    preparePartitionName,
} from 'constants/partitions'
import __, {__n} from 'utils/i18n'

const PartitionsMultiSelect = withSelectLoader(
    (prefix, maxOptionsToShow, {panelId}) => fetchPartitions(panelId),
    ({panels: {state}}, {panelId}) => !get(state, ['byIds', panelId, 'partitions'], true),
    ({panels: {state}}, {isLoading, panelId}) => {
        const partitionsList = Object.values(state?.byIds[panelId]?.partitionStates)
            .filter(({id}) => id > 0)
            .map(({id, name}) => {
                return preparePartitionName(id, name)
            })
        const partitions =
            partitionsList.length !== 0
                ? partitionsList
                : [preparePartitionName(DEFAULT_PARTITION_VALUE)]

        return !isLoading && partitions ? partitions : []
    }
)(MultiSelect)

const PartitionsSelect = ({panelId, defaultValues, setSelected, iconLeft}) => {
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    useEffect(() => {
        if (!features?.partitions?.isEnabled) {
            setSelected([
                {
                    value: PARTITION_ALL,
                },
            ])
        }
    }, [features?.partitions?.isEnabled])
    const partitions = useSelector(
        ({panels}) => panels.state.byIds[panelId]?.partitionStates
    )
    const maxPartitions = (partitions && Object.keys(partitions).length) || 1

    if (!features?.partitions?.isEnabled) {
        return null
    }

    return (
        <PartitionsMultiSelect
            setSelected={setSelected}
            label={__('Partitions')}
            name="partitions"
            panelId={panelId}
            hasSelectAll={maxPartitions > 1}
            maxSelectOptions={maxPartitions}
            maxSelectOptionsLabel={__n(
                'Only %d partition allowed to select for this device',
                'Only %d partitions allowed for this device',
                maxPartitions
            )}
            defaultValues={defaultValues}
            Icon={IconPartition}
            className="full-width"
            iconLeft
        />
    )
}

export default PartitionsSelect
