import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {editPanelCustomerInfo} from 'modules/forms/handlers'
import CustomerFieldSet, {validation} from './Fieldset/Customer'

export default function EditPanelCustomerInfo({panelId, googleApiError, hide}) {
    const {form, handle, isLoading} = useForm(editPanelCustomerInfo)
    const {panel, googleToken} = useSelector(({panels, system}) => ({
        panel: panels.store.byIds[panelId] || {},
        googleToken: system.settings.googleSettings.data.token,
    }))

    const handleForm = (data) => {
        handle(panelId, data)
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            rules={validation}
            isLoading={isLoading}
            onSubmit={handleForm}
            header={__('Edit Panel Owner Info')}
        >
            <CustomerFieldSet
                data={panel ? panel.contact : {}}
                googleToken={googleToken}
                googleApiError={googleApiError}
            />
        </ModalCardForm>
    )
}
