import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconRepeat} from 'ipmp-react-ui/icons/repeat.svg'

import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'

function InitRRIBase({children, onClick}) {
    return (
        <a onClick={onClick} className="rri-action">
            <IconRepeat className="rri-action-icon" />
            {children}
        </a>
    )
}

const InitRRIWithConfirmation = withConfirmation(({initiateNow, panelId}) =>
    initiateNow([panelId])
)(InitRRIBase)

export default function InitRRI({panelId, initiateNow}) {
    return (
        <InitRRIWithConfirmation
            panelId={panelId}
            initiateNow={initiateNow}
            title={__('Initiate Inspection')}
            message={__('Do you want to initiate remote inspection now?')}
            positive={__('Begin now')}
        />
    )
}

InitRRI.propTypes = {
    initiateNow: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
