import React from 'react'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
} from 'modules/interactiveUsers/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import {selectInteractiveUsersListSearch} from 'modules/interactiveUsers/list/selectors'

export default function InteractiveUsersSearch({className}) {
    const {filters: selected, query} = useSelector(selectInteractiveUsersListSearch)

    const {apply, onSelect, onDeselect, onClear, onQuery} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
    })

    const filters = [
        new ValuesFilter('status', __('Status'), {
            verified: __('Active'),
            suspended: __('Suspended'),
            registered: __('Not verified'),
        }),

        new DateFilter('createdAt', __('Created')),
        new DateFilter('lastLoginAt', __('Last Login')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <Search
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            query={query}
            filters={filters}
            selected={selected}
            className={className}
            placeholder={__('Search Customers')}
        />
    )
}
