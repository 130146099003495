import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'

import Layout from 'ipmp-react-ui/Layout'
import VerticalDelimiter from 'ipmp-react-ui/VerticalDelimiter'
import InfoCards, {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import CentralStationStatus from 'components/CentralStations/CentralStationStatus'
import {oneCentralStationDataSelect} from 'modules/centralStations/one/selectors'
import __ from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {selectProtocols} from 'modules/centralStations/protocols/selectors'
import {
    connectionTypeTitle,
    CS_CONNECTION_TYPE_SERIAL,
    CS_STATUS_NOT_APPLICABLE,
    getSslLabel,
    isHttpConnectionType,
} from 'constants/centralStation'
import CentralStationEditButton from 'pages/CentralStation/General/CentralStationEditButton'
import list from 'permissions/centralStations/list'
import {withPermissionRejection} from 'containers/withPermission'
import {useActions} from 'modules/higherOrder/useActions'
import {startPoll, stopPoll} from 'modules/centralStations/one/actions'

const WithRejectionLayout = withPermissionRejection(list)(Layout)

const General = () => {
    const {startPoll: startPollAction, stopPoll: stopPollAction} = useActions({
        startPoll,
        stopPoll,
    })

    const {id: centralStationId} = useParams()
    const oneCentralStationData = useSelector((state) =>
        oneCentralStationDataSelect(state, {id: centralStationId})
    )
    const protocols = useSelector(selectProtocols)
    const protocol = protocols?.data?.find(
        ({id}) => Number(id) === Number(oneCentralStationData?.protocolId)
    )
    const security =
        oneCentralStationData?.ssl === undefined
            ? null
            : getSslLabel(oneCentralStationData.ssl)
    const isHttp = isHttpConnectionType(oneCentralStationData?.connectionType)
    const status = oneCentralStationData?.status
    const applicableStatus = status && status !== CS_STATUS_NOT_APPLICABLE
    const csEditBtn = <CentralStationEditButton centralStationId={centralStationId} />
    const csTitle = (
        <>
            {oneCentralStationData?.name}
            {applicableStatus && (
                <>
                    <VerticalDelimiter />
                    <CentralStationStatus status={status} />
                </>
            )}
        </>
    )

    useEffect(() => {
        startPollAction(Number(centralStationId))

        return stopPollAction
    }, [])

    return (
        <WithRejectionLayout className="centralStation-general">
            <InfoCards cols={1}>
                <InfoCard cols={2} headerButton={csEditBtn} title={csTitle}>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Name')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(oneCentralStationData?.name)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Protocol')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(protocol?.name)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Heart Beat')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(
                                oneCentralStationData?.heartBeat || false
                            )}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Retry Time')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(oneCentralStationData?.retryTime)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Retry Count')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(oneCentralStationData?.retryCount)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    <InfoCardTile>
                        <InfoCardTileTitle>{__('Connection Type')}</InfoCardTileTitle>
                        <InfoCardTileContent>
                            {convertConfigValue(
                                connectionTypeTitle(oneCentralStationData?.connectionType)
                            )}
                        </InfoCardTileContent>
                    </InfoCardTile>
                    {oneCentralStationData?.connectionType !==
                        CS_CONNECTION_TYPE_SERIAL && (
                        <>
                            <InfoCardTile>
                                <InfoCardTileTitle>{__('Host')}</InfoCardTileTitle>
                                <InfoCardTileContent>
                                    {convertConfigValue(oneCentralStationData?.host)}
                                </InfoCardTileContent>
                            </InfoCardTile>
                            <InfoCardTile>
                                <InfoCardTileTitle>{__('Port')}</InfoCardTileTitle>
                                <InfoCardTileContent>
                                    {convertConfigValue(oneCentralStationData?.port)}
                                </InfoCardTileContent>
                            </InfoCardTile>
                            {security && (
                                <InfoCardTile>
                                    <InfoCardTileTitle>
                                        {__('Security')}
                                    </InfoCardTileTitle>
                                    <InfoCardTileContent>
                                        {convertConfigValue(security)}
                                    </InfoCardTileContent>
                                </InfoCardTile>
                            )}
                        </>
                    )}
                    {oneCentralStationData?.connectionType ===
                        CS_CONNECTION_TYPE_SERIAL && (
                        <InfoCardTile>
                            <InfoCardTileTitle>{__('Serial Port')}</InfoCardTileTitle>
                            <InfoCardTileContent>
                                {convertConfigValue(
                                    oneCentralStationData?.serialPortName ||
                                        oneCentralStationData?.device
                                )}
                            </InfoCardTileContent>
                        </InfoCardTile>
                    )}
                    {isHttp && (
                        <>
                            <InfoCardTile>
                                <InfoCardTileTitle>{__('Path')}</InfoCardTileTitle>
                                <InfoCardTileContent>
                                    {convertConfigValue(oneCentralStationData?.path)}
                                </InfoCardTileContent>
                            </InfoCardTile>
                            <InfoCardTile>
                                <InfoCardTileTitle>{__('User')}</InfoCardTileTitle>
                                <InfoCardTileContent>
                                    {convertConfigValue(oneCentralStationData?.user)}
                                </InfoCardTileContent>
                            </InfoCardTile>
                        </>
                    )}
                </InfoCard>
            </InfoCards>
        </WithRejectionLayout>
    )
}

General.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
}

export default General
