import React from 'react'

import Page from 'ipmp-react-ui/Page'

import EventsGridBar from './List/EventsGridBar'
import EventsTable from './List/EventsTable'

export default function EventsListPage(props) {
    return <Page Bar={EventsGridBar} Content={EventsTable} {...props} />
}
