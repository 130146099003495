import {useSelector} from 'react-redux'

import {selectDashboard} from 'modules/dashboard/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch as fetchConnectedPanels,
    startPoll as startPollConnectedPanels,
    stopPoll as stopPollConnectedPanels,
} from 'modules/dashboard/connectedPanels/actions'
import {
    fetch as fetchEventsRate,
    startPoll as startPollEventsRate,
    stopPoll as stopPollEventsRate,
} from 'modules/dashboard/eventsRate/actions'
import {
    fetch as fetchOnline,
    startPoll as startPollOnline,
    stopPoll as stopPollOnline,
} from 'modules/dashboard/online/actions'
import {
    fetch as fetchAllProcesses,
    startPoll as startPollAllProcesses,
    stopPoll as stopPollAllProcesses,
} from 'modules/dashboard/allProcesses/actions'
import {selectConnectedPanels} from 'modules/dashboard/connectedPanels/selectors'
import {selectOnline} from 'modules/dashboard/online/selectors'
import {selectAllProcesses} from 'modules/dashboard/allProcesses/selectors'
import {selectEventsRate} from 'modules/dashboard/eventsRate/selectors'

export function useDashboard() {
    const {connectedPanels, eventsRate, online, allProcesses} =
        useSelector(selectDashboard)

    return {
        connectedPanels,
        eventsRate,
        online,
        allProcesses,
        ...useActions({
            fetchConnectedPanels,
            startPollConnectedPanels,
            stopPollConnectedPanels,
            fetchEventsRate,
            startPollEventsRate,
            stopPollEventsRate,
            fetchOnline,
            startPollOnline,
            stopPollOnline,
            fetchAllProcesses,
            startPollAllProcesses,
            stopPollAllProcesses,
        }),
    }
}

export function useConnectedPanels() {
    const {isLoading, data, from, to, error} = useSelector(selectConnectedPanels)

    return {
        isLoading,
        data,
        from,
        to,
        error,
        ...useActions({
            fetchConnectedPanels,
            startPollConnectedPanels,
            stopPollConnectedPanels,
        }),
    }
}

export function useEventsRate() {
    const {isLoading, alarms, alerts, other, from, to, error} =
        useSelector(selectEventsRate)

    return {
        isLoading: isLoading,
        alarms,
        alerts,
        other,
        from,
        to,
        error,
        ...useActions({
            fetchEventsRate,
            startPollEventsRate,
            stopPollEventsRate,
        }),
    }
}

export function useOnline() {
    const {isLoading, from, to, faultyData, offlaneData, onlineData, totalData, error} =
        useSelector(selectOnline)

    return {
        isLoading,
        from,
        to,
        faultyData,
        offlaneData,
        onlineData,
        totalData,
        error,
        ...useActions({
            fetchOnline,
            startPollOnline,
            stopPollOnline,
        }),
    }
}

export function useAllProcesses() {
    const {isLoading, dataset, labels, error} = useSelector(selectAllProcesses)

    return {
        isLoading,
        dataset,
        labels,
        error,
        ...useActions({
            fetchAllProcesses,
            startPollAllProcesses,
            stopPollAllProcesses,
        }),
    }
}
