import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import {validation} from './NotificationTypeFieldset'
import NotificationTypeForm from './NotificationTypeForm'
import {editNotificationType} from 'modules/forms/handlers'

export default function EditNotificationType({hide, notificationTypeId}) {
    const {handle, form, isLoading} = useForm(editNotificationType)
    const data = useSelector(
        ({notificationTypes}) => notificationTypes.store.byIds[notificationTypeId]
    )

    const handler = (data) => handle({id: notificationTypeId, ...data})

    return (
        <NotificationTypeForm
            {...form}
            hide={hide}
            data={data}
            handle={handler}
            rules={validation}
            isLoading={isLoading}
            header={__('Edit Notification Type')}
        />
    )
}
