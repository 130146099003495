import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

const BroadbandConnected = () => {
    const {isAutoEnrollmentEnabled} = useSelector(
        (state) => state.system.settings.broadbandConnected.data
    )

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Enable Auto-Enroll')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(isAutoEnrollmentEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default BroadbandConnected
