import React, {useMemo, useRef, useState} from 'react'

import {AUTOLEARN_POWERG_CONFIRMATION_PIN_LENGTH} from 'constants/autolearn'

import ConfirmWithPinCodeForm from './ConfirmWithPinCodeForm'
import ConfirmWithPinCodeButtons from './ConfirmWithPinCodeButtons'

export default function useConfirmWithPinCode(hasPin, setHasPin, session, request) {
    const {pattern} = request?.information?.SETTING_VALUE?.pin_code || {}
    const pinLength =
        +pattern?.match(/\{(?<length>\d+)}/i).groups?.length ||
        AUTOLEARN_POWERG_CONFIRMATION_PIN_LENGTH
    const isValid = (value) => (value ? new RegExp(pattern, 'i').test(value) : false)

    const [pin, setPin] = useState('')
    const pinRef = useRef()
    pinRef.current = pin

    return useMemo(
        () =>
            !hasPin || !request
                ? null
                : {
                      Content: () => (
                          <ConfirmWithPinCodeForm
                              serial={session?.serial}
                              sensor={request?.sensor}
                              pinLength={pinLength}
                              setPin={setPin}
                          />
                      ),
                      Actions: () => (
                          <ConfirmWithPinCodeButtons
                              session={session}
                              sensor={request.sensor}
                              pin={pinRef.current}
                              isValid={isValid}
                              setHasPin={setHasPin}
                          />
                      ),
                  },
        [hasPin, setHasPin, session, request]
    )
}
