import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {useParams} from 'react-router'

import Page from 'ipmp-react-ui/Page'
import Layout from 'ipmp-react-ui/Layout'

import GroupTop from './GroupTop'
import GroupContent from './GroupContent'
import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'
import page from 'permissions/group/page'
import useGroup from 'hooks/pages/useGroup'

class GroupPageBase extends Page {
    static propTypes = {
        group: PropTypes.object,
    }

    renderTop() {
        const {group} = this.props

        return <GroupTop group={group} />
    }

    renderContent() {
        const {group} = this.props

        return (
            <Layout>
                <GroupContent group={group} />
            </Layout>
        )
    }
}

const GroupPageConnected = compose(
    withPermissionRejection(page),
    withLoader(({fetch}) => fetch())
)(GroupPageBase)

export default function GroupPage() {
    const params = useParams()

    const {fetch, group, isLoading} = useGroup(params.id)

    return <GroupPageConnected fetch={fetch} group={group} isLoading={isLoading} />
}
