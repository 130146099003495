import React from 'react'

import {__} from 'utils/i18n'
import {useSelector} from 'react-redux'
import {ReactComponent as CLearIcon} from 'ipmp-react-ui/icons/clear-icon-large.svg'
import {ReactComponent as SpinnerIcon} from 'ipmp-react-ui/icons/loader.svg'

import StartClearDeviceButton from 'pages/Panel/Devices/Bars/Buttons/StartClearDeviceButton'
import StopClearDeviceButton from 'pages/Panel/Devices/Bars/Buttons/StopClearDeviceButton'
import {
    PROCESS_TYPE_CLEAR_DEVICE_START,
    PROCESS_TYPE_CLEAR_DEVICE_STOP,
} from 'constants/processTypes'
import useTrackProcess from 'hooks/useTrackProcess'
import {selectFreshBatchByTypeAndPanelSerial} from 'modules/batches/store/selectors'
import usePanelInfo from 'hooks/pages/usePanelInfo'

export default function ClearDeviceWidget({subtype}) {
    const {panel} = usePanelInfo()

    const {process: runningStartClearProcess} = useTrackProcess({
        process: panel.startClearDeviceRunner,
    })

    const {process: runningStopClearProcess} = useTrackProcess({
        process: panel.stopClearDeviceRunner,
    })

    const startBatch = useSelector((state) =>
        selectFreshBatchByTypeAndPanelSerial(
            state,
            PROCESS_TYPE_CLEAR_DEVICE_START,
            panel.id
        )
    )
    const stopBatch = useSelector((state) =>
        selectFreshBatchByTypeAndPanelSerial(
            state,
            PROCESS_TYPE_CLEAR_DEVICE_STOP,
            panel.id
        )
    )

    let currentBatch = stopBatch ?? startBatch
    if (
        startBatch &&
        stopBatch &&
        new Date(startBatch.started) > new Date(stopBatch.started)
    ) {
        currentBatch = startBatch
    }

    const isProcessing = !!runningStartClearProcess || isRunning(currentBatch)

    const startBatchRunning = isRunning(startBatch)
    const stopBatchRunning = isRunning(stopBatch)

    return (
        <>
            <div className="block clear-widget">
                <CLearIcon />
                <WidgetText
                    batch={startBatch}
                    processErrorMessage={runningStartClearProcess?.errorMessage}
                />
                {isProcessing && <SpinnerIcon className="clear-widget-spinner" />}
            </div>
            <div className="block clear-button-line">
                {runningStartClearProcess || startBatchRunning ? (
                    <StopClearDeviceButton
                        disabled={!!runningStopClearProcess || stopBatchRunning}
                        flat
                        panelId={panel.id}
                        batch={currentBatch}
                        subtype={subtype}
                    />
                ) : (
                    <StartClearDeviceButton
                        flat
                        panelId={panel.id}
                        batch={currentBatch}
                        subtype={subtype}
                        disabled={!!runningStartClearProcess || startBatchRunning}
                    />
                )}
            </div>
        </>
    )
}

const WidgetText = ({batch, processErrorMessage}) => {
    const getSubtitle = () => {
        let errorMessage = ''

        if (processErrorMessage) {
            errorMessage = processErrorMessage + ' '
        }

        return getSubtitleText(batch, errorMessage)
    }

    return (
        <div className="clear-widget-text">
            <div className="clear-widget-title">{getTitleText(batch)}</div>
            <div className="clear-widget-sub-title">{getSubtitle()}</div>
        </div>
    )
}

const getTitleText = (batch) => {
    switch (true) {
        case batch?.stats?.succeeded > 0:
            return __('Clear successful')
        case batch?.stats?.failed > 0:
        case batch?.stats?.start > 0:
        case batch?.stats?.handled > 0:
        default:
            return __('Clear Z-Wave device')
    }
}

const getSubtitleText = (batch, errorMessage) => {
    switch (true) {
        case batch?.stats?.start > 0:
        case batch?.stats?.handled > 0:
            return __('Press learn button on the device to clear it')
        case batch?.stats?.succeeded > 0:
        case batch?.stats?.failed > 0:
        case batch?.stats?.failed_to_start > 0:
            return (
                errorMessage + __('Press “Start Clear” button below to initiate process')
            )
        default:
            return __('Press “Start Clear” button below to initiate process')
    }
}

const isRunning = (batch) => {
    return (
        batch?.stats?.notStarted > 0 ||
        batch?.stats?.start > 0 ||
        batch?.stats?.handled > 0 ||
        batch?.stats?.pending > 0
    )
}
