import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {
    selectUpgradeStatusList,
    selectUpgradeStatusListRows,
} from 'modules/upgradeStatus/list/selectors'
import {retry} from 'modules/upgradeStatus/store/actions'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/upgradeStatus/list/actions'

export default function useUpgradeStatus() {
    const {isLoading, page, error, total, start} = useSelector(selectUpgradeStatusList)

    const {onPerPageChange, perPage} = usePerPage('upgrades')

    return {
        perPage,
        start,
        total,
        page,
        error,
        isLoading,
        onPerPageChange,
        rows: useSelector(selectUpgradeStatusListRows),
        ...useActions({
            init,
            fetch,
            reset,
            retry,
            startPoll,
            stopPoll,
            onPageChange: setStart,
        }),
    }
}
