import React from 'react'
import {compose} from 'redux'

import {service} from 'permissions/panels/selection'

import {withPermissionVisibility} from 'containers/withPermission'
import {ifSelection} from 'containers/withSelection'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import ChangeGroupItem from './Items/ChangeGroupItem'
import RefreshStateItem from './Items/RefreshStateItem'
import DownloadConfigurationItem from './Items/DownloadConfigurationItem'
import PushBasicConfigurationItem from './Items/PushBasicConfigurationItem'
import TriggerDiscoveryItem from './Items/TriggerDiscoveryItem'
import MarkForServiceItem from './Items/MarkForServiceItem'

import {__} from 'utils/i18n'

function ServiceDropDown() {
    return (
        <DropDownButton label={__('Service')}>
            <Menu>
                <ChangeGroupItem />

                <MenuDelimiter />

                <RefreshStateItem />
                <DownloadConfigurationItem />
                <PushBasicConfigurationItem />

                <MenuDelimiter />

                <TriggerDiscoveryItem />

                <MenuDelimiter />

                <MarkForServiceItem />
            </Menu>
        </DropDownButton>
    )
}

export default compose(withPermissionVisibility(service), ifSelection())(ServiceDropDown)
