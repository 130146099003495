import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {
    selectFirmwarePatchTagUpdateList,
    selectFirmwarePatchTagUpdateListRows,
} from 'modules/firmware/patchTagUpdate/selectors'
import {fetch, init, reset, setStart} from 'modules/firmware/patchTagUpdate/actions'

export default function usePatchTagUpdatePanels() {
    const {start, total, error, isLoading} = useSelector(selectFirmwarePatchTagUpdateList)

    const perPage = usePerPage('firmwarePatchTagUpdate')

    return {
        ...perPage,
        start,
        total,
        error,
        isLoading,
        rows: useSelector(selectFirmwarePatchTagUpdateListRows),
        ...useActions({
            setStart,
            reset,
            init,
            fetch: () => fetch(),
        }),
    }
}
