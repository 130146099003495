import React from 'react'

import {Tab} from 'ipmp-react-ui/Tabs'
import PatchTag from 'pages/Panel/Devices/Sidebar/Tabs/PatchTag/PatchTag'

import {__} from 'utils/i18n'

const PatchTagTab = ({panelId, info, version}) => (
    <Tab name={__('Patch Tag')}>
        <PatchTag panelId={panelId} info={info} version={version} />
    </Tab>
)

export default PatchTagTab
