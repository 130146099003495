import React from 'react'
import classnames from 'classnames'

import {ReactComponent as IconStar} from 'ipmp-react-ui/icons/star.svg'

import {NO_PLAN_ID} from 'constants/billingPlan'

export default function PanelBillingPlanBadge({
    onClick = null,
    id = NO_PLAN_ID,
    name = null,
    isShort = false,
    isPending = false,
    className = null,
}) {
    return (
        <div
            onClick={onClick}
            className={classnames(
                'panelBillingPlanBadge',
                className,
                `panelBillingPlanBadge--${id}`,
                {
                    'panelBillingPlanBadge--short': isShort,
                    'panelBillingPlanBadge--pending': isPending,
                    'panelBillingPlanBadge--callable': !!onClick,
                }
            )}
        >
            <IconStar />
            {!isShort && <span>{name}</span>}
        </div>
    )
}
