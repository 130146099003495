import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {fetch} from 'modules/groups/notifications/endUser/actions'
import {getByGroupId, getIsLoading} from 'modules/groups/notifications/endUser/selectors'

export default function useGroupNotificationsEndUser(groupId) {
    const dispatch = useDispatch()
    const settings = useSelector((state) => getByGroupId(state, groupId))
    const isLoading = useSelector(getIsLoading)

    return {
        settings,
        isLoading,
        ...bindActionCreators(
            {
                fetch: () => fetch(groupId),
            },
            dispatch
        ),
    }
}
