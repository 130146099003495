import React, {useEffect} from 'react'

import Table from 'ipmp-react-ui/Table'
import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'

import withLoader from 'containers/withLoader'
import {__} from 'utils/i18n'
import RemoveStateType from './TableCell/RemoveStateType'
import EditStateType from './TableCell/EditStateType'
import useStateTypes from 'hooks/pages/useStateTypes'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import {edit, remove} from 'permissions/settings/virtualPanelRules'

const allColumns = [
    {
        name: () => __('State Type'),
        render: ({name}) => name,
    },
    {
        name: () => __('Is Disarm'),
        render: ({isDisarmState}) => isDisarmState && <IconCheck className="success" />,
    },
    {
        name: () => __('Has entry delay'),
        render: ({hasEntryDelay}) => hasEntryDelay && <IconCheck className="success" />,
    },
    {
        name: () => __('Triggers exit delay'),
        render: ({triggersExitDelay}) =>
            triggersExitDelay && <IconCheck className="success" />,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditStateType,
        permission: edit,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveStateType,
        permission: remove,
    },
]

const TableWithLoader = withLoader()(Table)

export default function StateTypesTable() {
    const stateTypesProps = useStateTypes()

    useEffect(() => {
        stateTypesProps.init()
        stateTypesProps.startPoll()

        return () => stateTypesProps.stopPoll()
    }, [])

    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <TableWithLoader
            {...stateTypesProps}
            columns={columns}
            fullHeight
            emptyMessage={() => __('No state types found')}
        />
    )
}
