import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const Supervision = () => {
    const {
        doSendEmailOnOnlineOffline,
        doSendSmsOnOnlineOffline,
        doSendOneChannelPanelOnlineOffline,
        doSendTwoChannelPanelOnlineOffline,
    } = useSelector((state) => state.system.settings.supervision.data)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Send Notification Email on Online/Offline Event')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doSendEmailOnOnlineOffline)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Send Notification SMS on Online/Offline Event')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doSendSmsOnOnlineOffline)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Generate `SYSTEM OFFLINE/ONLINE` Events for One-Channel Panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doSendOneChannelPanelOnlineOffline)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Generate `SYSTEM OFFLINE/ONLINE` Events for Two-Channel Panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doSendTwoChannelPanelOnlineOffline)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default Supervision
