import {createSelector} from 'reselect'

export const selectStatus = createSelector(
    (state, panelId) => state.panels.billingPlan[panelId],
    (status) => status || null
)

export const selectPlan = createSelector(
    (state, panelId, planId) =>
        state.panels.billingPlan[panelId].availablePlans.find(({id}) => id === planId),
    (plan) => plan
)
