import React from 'react'
import {compose} from 'redux'

import {withPermissionVisibility} from 'containers/withPermission'
import {ifSelection} from 'containers/withSelection'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import MarkForServiceItem from './Items/MarkForServiceItem'
import ReassignItem from './Items/ReassignItem'
import ResolveFaultsItem from './Items/ResolveFaultsItem'
import SuspendFaultsItem from './Items/SuspendFaultsItem'
import ResumeFaultsItem from './Items/ResumeFaultsItem'

import {faultsMonitoring} from 'permissions/panels/selection'

import {__} from 'utils/i18n'

function FaultsMonitoringDropDown() {
    return (
        <DropDownButton label={__('Faults')} dataTestId="panels-faults-drop-down">
            <Menu>
                <MarkForServiceItem />
                <ReassignItem />
                <MenuDelimiter />
                <ResolveFaultsItem />
                <SuspendFaultsItem />
                <ResumeFaultsItem />
            </Menu>
        </DropDownButton>
    )
}

export default compose(
    withPermissionVisibility(faultsMonitoring),
    ifSelection()
)(FaultsMonitoringDropDown)
