import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'

import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import {runPanelTest, fetchTestsHistory} from 'modules/panels/one/actions'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {list, start} from 'permissions/panel/systemTest/page'
import {selectBatchByName, selectNewBatchInProgress} from 'modules/panels/one/selectors'

const ButtonVisibility = withVisibility()(Button)

export default function RunPanelTestButton({panelId, test, panelSerial, startedGroup}) {
    const {testName, groupName, groupOnly} = test
    const {isAllowedRunTests, isAllowedListTests} = usePermission({
        isAllowedRunTests: start,
        isAllowedListTests: list,
    })

    const {runTest, fetchTest} = useActions({
        runTest: runPanelTest,
        fetchTest: fetchTestsHistory,
    })

    const currentBatch = useSelector((state) =>
        selectBatchByName(state, {testName, panelSerial})
    )

    const batchInProgress = useSelector(
        (state) => currentBatch && selectNewBatchInProgress(state, currentBatch?.id)
    )

    const [startedTest, setStartedTest] = useState(currentBatch && batchInProgress)
    const testStarted =
        startedTest ||
        (currentBatch && batchInProgress) ||
        (groupName && startedGroup === groupName)

    const label = testStarted ? __('Test Started') : __('Start Test')

    const onButtonClick = () => {
        runTest({
            panelId,
            tests: [test],
        })
        setStartedTest(true)
    }

    useEffect(() => {
        if (startedTest && !batchInProgress) {
            isAllowedListTests && fetchTest(panelId)
            setStartedTest(false)
        }
    }, [currentBatch, batchInProgress])

    return (
        <ButtonVisibility
            isVisible={isAllowedRunTests && !groupOnly}
            onClick={onButtonClick}
            Icon={IconRun}
            label={label}
            disabled={testStarted}
        />
    )
}
