import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/stateTypes'
import toIds from 'utils/toIds'
import * as actions from './actions'

import {POLL_STATE_TYPES} from 'configs/pollers'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {update, purge} from 'modules/stateTypes/store/actions'
import {snackShow} from 'modules/snacks'

import {__} from 'utils/i18n'
import createListWithoutPaginationSaga from 'modules/higherOrder/createListWithoutPaginationSaga'
import {addStateType} from 'modules/forms/handlers'

const selector = (state) => ({
    ...state.stateTypes.list,
    listRoute: 'stateTypes',
})

export default function* () {
    yield all([
        createListWithoutPaginationSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_STATE_TYPES, watchPoll),
        takeEvery(addStateType.SUCCESS, watchAddZoneType),
    ])
}

function* watchAddZoneType() {
    yield put(actions.fetch())
    yield put(snackShow(__('State type was created successfully')))
}

function* watchPoll() {
    const {rows, count} = yield call(api.fetch)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}
