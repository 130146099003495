import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import {
    MODULE_STATES_HASH,
    MODULE_TYPE_BBA,
    MODULE_TYPE_GPRS,
} from 'constants/moduleTypes'
import {
    addFilters,
    clearFilters,
    fetch,
    fetchSuggests,
    removeFilters,
    setQuery,
} from 'modules/panels/list/actions'
import deviceType, {
    DEVICE_TYPE_AUDIO_VERIFICATION,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_POWER_LINK,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
} from 'constants/deviceType'
import __ from 'utils/i18n'
import list from 'permissions/panels/list'
import {status} from 'permissions/panel/billing/actions'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {troubleTitle} from 'constants/troubleType'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showSaveSearchModal} from 'modules/modals/actions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectPanelsList} from 'modules/panels/list/selectors'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

const SearchVisibility = withVisibility()(Search)

export default function PanelsSearch({className}) {
    const {scope} = useParams()
    const {isPermitted} = usePermission(list)
    const {isBillingPermitted} = usePermission(status, 'isBillingPermitted')
    const {filters: selected, query, suggests} = useSelector(selectPanelsList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest, onSaveSearch} =
        useActions({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: (filters, onClose) =>
                showSaveSearchModal('panels', filters, onClose),
        })

    const filters = [
        new SuggestFilter('serial', __('Panel Id')),
        new SuggestFilter('utz_enroll_id', __('Device Id')),
        new ValuesFilter('faults', __('Faulty'), {
            active: __('Active'),
            suspended: __('Suspended'),
            any: __('Any'),
        }),
        new SuggestFilter('account', __('Account')),
        new SuggestFilter('group', __('Group')),
        new SuggestFilter('model', __('Model')),
        new SuggestFilter('user', __('Operator')),
        new SuggestFilter(
            DEVICE_TYPE_CONTROL_PANEL,
            deviceType(DEVICE_TYPE_CONTROL_PANEL)
        ),
        new SuggestFilter(DEVICE_TYPE_POWER_LINK, deviceType(DEVICE_TYPE_POWER_LINK)),
        new SuggestFilter(DEVICE_TYPE_WIRED_KEYPAD, deviceType(DEVICE_TYPE_WIRED_KEYPAD)),
        new SuggestFilter(
            DEVICE_TYPE_ZONE_EXPANDER,
            deviceType(DEVICE_TYPE_ZONE_EXPANDER)
        ),
        new SuggestFilter(
            DEVICE_TYPE_OUTPUT_EXPANDER,
            deviceType(DEVICE_TYPE_OUTPUT_EXPANDER)
        ),
        new SuggestFilter(DEVICE_TYPE_POWER_SUPPLY, deviceType(DEVICE_TYPE_POWER_SUPPLY)),
        new SuggestFilter(
            DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
            deviceType(DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS)
        ),
        new SuggestFilter(DEVICE_TYPE_PGH, deviceType(DEVICE_TYPE_PGH)),
        new SuggestFilter(DEVICE_TYPE_COMMUNICATOR, deviceType(DEVICE_TYPE_COMMUNICATOR)),
        new SuggestFilter(
            DEVICE_TYPE_AUDIO_VERIFICATION,
            deviceType(DEVICE_TYPE_AUDIO_VERIFICATION)
        ),
        new TranslateSuggestFilter('fault', __('Fault'), troubleTitle),
        new ValuesFilter('isActivated', __('Activated'), {
            '{=1}': __('Yes'),
            '{!=1}': __('No'),
        }),
        new ValuesFilter('userApp', __('Customer App'), {
            allow: __('Allow'),
            disallow: __('Disallow'),
        }),
        new ValuesFilter('configuratorApp', __('Installer App'), {
            allow: __('Allow'),
            disallow: __('Disallow'),
        }),
        new ValuesFilter(MODULE_TYPE_GPRS, __('Cellular'), MODULE_STATES_HASH),
        new ValuesFilter(MODULE_TYPE_BBA, __('Broadband'), MODULE_STATES_HASH),
        new SuggestFilter('configurationName', __('Configuration')),
        new DateFilter('unt_first_discovery_timestamp', __('First connection')),
    ]

    if (isBillingPermitted) {
        filters.push(new SuggestFilter('plan_name', __('Billing plan')))
        filters.push(new DateFilter('plan_till', __('Billing plan till'), 'future'))
        filters.push(new DateFilter('plan_since', __('Billing plan since')))
    }

    filters.sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            onSaveSearch={onSaveSearch}
            key={scope}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Equipment')}
        />
    )
}
