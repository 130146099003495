import {createAction} from 'redux-actions'
import uniqid from 'uniqid'
import toArray from 'utils/toArray'

export const update = createAction('PANELS/REMARKS/UPDATE', toArray, (_, panelId) => ({
    panelId,
}))
export const fetch = createAction('PANELS/REMARKS/FETCH')
export const startPoll = createAction('PANELS/REMARKS/START_POLL')
export const stopPoll = createAction('PANELS/REMARKS/STOP_POLL')
export const add = createAction('PANELS/REMARKS/ADD', null, (_, panelId) => ({panelId}))
export const remove = createAction('PANELS/REMARKS/REMOVE', null, (_, panelId) => ({
    panelId,
}))

export function create(text, panelId) {
    return (dispatch, getState) => {
        const user = getState().auth.sign.user

        dispatch(
            add(
                {
                    key: uniqid(),
                    isNew: true,
                    time: new Date(),
                    text,
                    user,
                },
                panelId
            )
        )
    }
}
