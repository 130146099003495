import React from 'react'
import PropTypes from 'prop-types'

import {showExportConfigurationModal} from 'modules/modals/actions'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconDownload} from 'ipmp-react-ui/icons/download.svg'

import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'
import {makeExport} from 'permissions/panel/configuration/actions'
import {withVisibility} from 'containers/withVisibility'

const ButtonVisibility = withVisibility()(Button)

const ExportButton = ({configurationId, configuration, panelId}) => {
    const {showModal} = useActions({
        showModal: () =>
            showExportConfigurationModal(configurationId, panelId, configuration),
    })

    const {isPermitted} = usePermission(makeExport)

    return (
        <ButtonVisibility Icon={IconDownload} onClick={showModal} isVisible={isPermitted}>
            {__('Export')}
        </ButtonVisibility>
    )
}

ExportButton.propTypes = {
    configuration: PropTypes.object,
    configurationId: PropTypes.number,
}

export default ExportButton
