import {createSelector} from 'reselect'

export const selectPanelsListRows = createSelector(
    (state) => state.panels.list.page,
    (state) => state.panels.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectPanelsList = createSelector(
    (state) => state.panels.list,
    (list) => list
)
