import {cancel, fork, take, call, delay, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

export default function* createPollerSaga(
    startAction,
    stopAction,
    interval,
    iteratee,
    startBeforeDelay = false
) {
    while (true) {
        const action = yield take(startAction)
        const pollTask = yield fork(
            poller,
            stopAction,
            interval,
            iteratee,
            startBeforeDelay,
            action
        )
        yield take(stopAction)
        yield cancel(pollTask)
    }
}

export function* poller(stopAction, interval, iteratee, startBeforeDelay, ...args) {
    if (startBeforeDelay) {
        yield execute(stopAction, iteratee, ...args)
    }

    while (true) {
        if (Number.isInteger(interval)) {
            yield delay(interval)
        } else {
            yield call(interval, ...args)
        }

        yield execute(stopAction, iteratee, ...args)
    }
}

function* execute(stopAction, iteratee, ...args) {
    try {
        yield call(iteratee, ...args)
    } catch (e) {
        if (e.isServerError && [401, 403].includes(e.status)) {
            yield put(stopAction())
        } else if ('message' in e) {
            yield put(snackShow(e.message))
        }

        if (iteratee.onError instanceof Function) {
            yield iteratee.onError(e, ...args)
        }
    }
}
