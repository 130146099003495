import extendedInfo, {
    EXTENDED_INFO_SOFTWARE,
    EXTENDED_INFO_SOFTWARE_VERSION,
} from 'constants/panelExtendedInfo'
import __ from 'utils/i18n'

const select = (prop, value, info) => info.find((entry) => entry[prop] === value)

const template = (version) => ({
    key: EXTENDED_INFO_SOFTWARE_VERSION,
    name: extendedInfo(EXTENDED_INFO_SOFTWARE_VERSION),
    value: version || __('Unknown'), // I give up
})

// This is weird but now there are no keys for additional info, so I have to compare
// values by name literals. Yes, I do understand that it does not work on different languages
// I opened bug for C++ team, so I hope they will be able to extend and information about keys.
// If you know for sure, that keys are implemented you can remove this code and compare keys only
const equals = ({key, name}, {key: _key, name: _name}) => {
    if (key == null || _key == null) {
        return name === _name
    }

    return key === _key
}

const section = (info, name) => {
    const byKey = select('key', name, info)

    if (byKey) {
        return byKey
    }

    return select('name', extendedInfo(name), info)
}

const combine = (info, version) => {
    let {values} = section(info, EXTENDED_INFO_SOFTWARE)

    const fallback = template(version)

    // fallback in case there is no extended information to use current version information
    if (!values) {
        values = []
    }

    // fallback in case there is no version information in extended info, I don't know why
    if (!values.find((entry) => equals(fallback, entry))) {
        //Immutable array is not a joke
        values = [fallback, ...values]
    }

    return values.map((entry) => ({
        ...entry,
        withButton: equals(fallback, entry),
    }))
}

export default combine
