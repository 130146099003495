import React from 'react'
import {useSelector} from 'react-redux'

import nl2br from 'react-nl2br'

import Button from 'ipmp-react-ui/Button'

import ActionCards, {ActionCard, ActionCardsGroup} from 'ui/ActionCards'

import {downloadIQPanelLog, rebootPanel} from 'permissions/panel/actions'

import {useActions} from 'modules/higherOrder/useActions'
import {selectLogTypes, selectPanelActions} from 'modules/features/store/selectors'
import {fetchLogOutsidePm, triggerReboot} from 'modules/devices/list/actions'

import withConfirmation from 'containers/withConfirmation'

import useTrackProcess from 'hooks/useTrackProcess'
import usePermission from 'hooks/usePermission'
import usePanelInfo from 'hooks/pages/usePanelInfo'

import {REBOOT_ACTION} from 'constants/panelAction'
import {SYSTEM_OUTSIDE_PM_LOG_TYPE} from 'constants/logTypes'

import __ from 'utils/i18n'

const ConfirmationButton = withConfirmation()(Button)

function TriggerRebootButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => triggerReboot(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.panelRebootProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={nl2br(
                __(
                    'Panel will reboot even if it is in arm state. \n\r' +
                        'Do you want to send reboot command?'
                )
            )}
            title={__('Sending Reboot command')}
        >
            {__('Send')}
        </ConfirmationButton>
    )
}

function FetchLogOutsidePmButton({panel}) {
    const {onClick} = useActions(
        {
            onClick: () => fetchLogOutsidePm(panel.id),
        },
        [panel]
    )

    const {process} = useTrackProcess({
        process: panel.sysLogFetchOutsidePmProcess,
    })

    return (
        <ConfirmationButton
            onClick={onClick}
            disabled={!!process}
            message={__('Do you really want to download log?')}
            title={__('Download log')}
        >
            {__('Download log')}
        </ConfirmationButton>
    )
}

export default function GeneralInfoTab({panelId}) {
    const {isDownloadLogAllowed, isTriggerActionRebootAllowed} = usePermission({
        isDownloadLogAllowed: downloadIQPanelLog,
        isTriggerActionRebootAllowed: rebootPanel,
    })

    const allowedPanelActions = useSelector((state) =>
        selectPanelActions(state, {panelId})
    )

    const allowedLogTypes = useSelector((state) => selectLogTypes(state, {panelId}))
    const {panel} = usePanelInfo()

    return (
        <ActionCardsGroup label={__('General Commands')}>
            {isDownloadLogAllowed &&
                allowedLogTypes.includes(SYSTEM_OUTSIDE_PM_LOG_TYPE) && (
                    <ActionCards label={__('Log options')}>
                        <ActionCard label={__('Download log')}>
                            <FetchLogOutsidePmButton panel={panel} />
                        </ActionCard>
                    </ActionCards>
                )}

            {isTriggerActionRebootAllowed &&
                allowedPanelActions.includes(REBOOT_ACTION) && (
                    <ActionCards label={__('General management')}>
                        <ActionCard label={__('Sending reboot command')}>
                            <TriggerRebootButton panel={panel} />
                        </ActionCard>
                    </ActionCards>
                )}
        </ActionCardsGroup>
    )
}
