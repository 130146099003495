import React from 'react'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {changePassword} from 'modules/forms/handlers'

const rules = {
    currentPassword: {
        presence: true,
        length: {minimum: 8},
    },
    newPassword: {
        presence: true,
        password: true,
    },
    newPasswordConfirmation: {
        presence: true,
        equality: {
            attribute: 'newPassword',
            message: () => __('Passwords do not match'),
            comparator: (v1, v2) => v1 === v2,
        },
    },
}

export default function ChangePassword({hide}) {
    const {form, handle, isLoading} = useForm(changePassword)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handle}
            isLoading={isLoading}
            confirmOnDismiss
            header={__('Change Password')}
        >
            <Input
                label={__('Current Password')}
                name="currentPassword"
                type="password"
                dataTestId="current-password"
            />

            <Input
                label={__('New Password')}
                name="newPassword"
                type="password"
                dataTestId="new-password"
            />

            <Input
                label={__('Confirm Password')}
                name="newPasswordConfirmation"
                type="password"
                dataTestId="confirm-password"
            />
        </ModalCardForm>
    )
}
