import React from 'react'
import * as PropTypes from 'prop-types'

import {
    TYPE_EMERGENCY_EMERGENCY,
    TYPE_EMERGENCY_FIRE,
    TYPE_EMERGENCY_SECURITY,
    TYPE_EMERGENCY_SILENT_EMERGENCY,
    TYPE_EMERGENCY_SILENT_SECURITY,
} from 'constants/events'

import SecurityContent from 'components/Panel/Emergencies/Screens/Security/SecurityContent'
import EmergencyContent from 'components/Panel/Emergencies/Screens/Emergency/EmergencyContent'
import FireContent from 'components/Panel/Emergencies/Screens/Fire/FireContent'
import InitEmergencyContent from 'components/Panel/Emergencies/Screens/Init/InitContent'

export default function Content({data, panelId, emergencyActions, setData, setSelected}) {
    switch (data?.chosen) {
        case TYPE_EMERGENCY_SECURITY:
        case TYPE_EMERGENCY_SILENT_SECURITY:
            return (
                <SecurityContent
                    panelId={panelId}
                    data={data}
                    setSelected={setSelected}
                />
            )
        case TYPE_EMERGENCY_EMERGENCY:
        case TYPE_EMERGENCY_SILENT_EMERGENCY:
            return (
                <EmergencyContent
                    panelId={panelId}
                    data={data}
                    setSelected={setSelected}
                />
            )
        case TYPE_EMERGENCY_FIRE:
            return <FireContent panelId={panelId} setSelected={setSelected} />
        default:
            return (
                <InitEmergencyContent
                    data={data}
                    setData={setData}
                    emergencyActions={emergencyActions}
                />
            )
    }
}

Content.propTypes = {
    panelId: PropTypes.number.isRequired,
    setData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    emergencyActions: PropTypes.array,
}
