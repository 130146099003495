import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {changeGroup} from 'permissions/panels/selection'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {withSelectionHandler} from 'containers/withSelection'
import {showChangePanelGroupModal} from 'modules/modals/actions'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export function ChangeGroupBase({onClick}) {
    const {isPermitted} = usePermission(changeGroup)

    return (
        <ConnectedMenuItem isVisible={isPermitted} onClick={onClick}>
            {__('Change Group')}
        </ConnectedMenuItem>
    )
}

export default function ChangeGroupItem() {
    const onClick = useActions(showChangePanelGroupModal)

    return <ChangeGroupBase onClick={onClick} />
}
