import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {selectNotificationTypesListRows} from 'modules/notificationTypes/list/selectors'
import {init, reset, startPoll, stopPoll} from 'modules/notificationTypes/list/actions'

export default function useNotificationTypes() {
    const {rows, isLoading, error} = useSelector(selectNotificationTypesListRows)

    return {
        rows,
        isLoading,
        error,
        ...useActions({
            init,
            reset,
            startPoll,
            stopPoll,
        }),
    }
}
