import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {setBypass} from 'modules/devices/list/actions'
import {withVisibility} from 'containers/withVisibility'
import {bypass} from 'permissions/panel/devices/actions'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'

const ConnectedButton = compose(withVisibility(), withConfirmation())(Button)

export default function SetBypassButton({
    primary,
    className,
    panelId,
    deviceBypassStates,
    resetSelection,
    children,
}) {
    const {isPermitted} = usePermission(bypass)

    const {onClick: onClickBypass} = useActions(
        {
            onClick: () => setBypass(panelId, deviceBypassStates),
        },
        [panelId, deviceBypassStates]
    )

    const onClick = () => {
        onClickBypass()
        resetSelection()
    }

    return (
        <ConnectedButton
            primary={primary}
            onClick={onClick}
            className={className}
            isVisible={isPermitted}
            label={__('Set Bypass')}
            title={__('Set Bypass for Devices')}
            message={__('Do you really want to set bypass to selected panels?')}
        >
            {children}
        </ConnectedButton>
    )
}
