import React from 'react'

import {__} from 'utils/i18n'
import UserForm from './UserForm'
import useForm from 'hooks/useForm'
import {AddValidation} from './Fieldset/User'
import {addUser} from 'modules/forms/handlers'

export default function AddUser({hide}) {
    const {handle, isLoading, form} = useForm(addUser)

    return (
        <UserForm
            isNew
            {...form}
            hide={hide}
            handle={handle}
            isLoading={isLoading}
            rules={AddValidation}
            header={__('Add User')}
        />
    )
}
