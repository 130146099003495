import React from 'react'

import {__} from 'utils/i18n'
import list from 'permissions/groups/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {withVisibility} from 'containers/withVisibility'
import {
    clearFilters as groupListClearFilters,
    fetch as groupListFetch,
    setQuery as groupListSetQuery,
} from 'modules/centralStations/groupsList/actions'

const SearchVisibility = withVisibility()(Search)

export default function CommunicationSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {fetch, setQuery, clearFilters} = useActions({
        fetch: groupListFetch,
        setQuery: groupListSetQuery,
        clearFilters: groupListClearFilters,
    })

    return (
        <SearchVisibility
            apply={fetch}
            isVisible={isPermitted}
            onQuery={setQuery}
            filters={[]}
            selected={[]}
            onClear={clearFilters}
            placeholder={__('Search Groups')}
            className={className}
            hideAutoCompleteAfterQuery
        />
    )
}
