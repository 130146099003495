import React from 'react'

import Modal from 'ipmp-react-ui/Modal'
import CardMessage from 'ipmp-react-ui/CardMessage'
import Portal from 'ipmp-react-ui/Portal'

import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'

export const DismissConfirmation = ({
    cancelDismiss,
    agreeDismiss,
    dismiss,
    message,
    title,
}) => {
    const defaultMessage = __('All data will be lost. Do you want to proceed?')
    const defaultTitle = __('Closing form')

    return (
        <Portal onClick={stopPropagation} key="portal">
            <Modal onClose={dismiss}>
                <CardMessage
                    title={title ?? defaultTitle}
                    message={message ?? defaultMessage}
                    positive={__('Confirm')}
                    negative={__('Cancel')}
                    onPositive={agreeDismiss}
                    onNegative={cancelDismiss}
                    onClose={cancelDismiss}
                />
            </Modal>
        </Portal>
    )
}
