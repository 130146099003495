import React, {useMemo} from 'react'

import ConfirmWithoutPinCodeForm from './ConfirmWithoutPinCodeForm'
import ConfirmWithoutPinCodeButtons from './ConfirmWithoutPinCodeButtons'

export default function useConfirmWithoutPinCode(hasPin, setHasPin, session, request) {
    return useMemo(
        () =>
            hasPin || !request
                ? null
                : {
                      Content: () => (
                          <ConfirmWithoutPinCodeForm
                              serial={session?.serial}
                              sensor={request?.sensor}
                          />
                      ),
                      Actions: () => (
                          <ConfirmWithoutPinCodeButtons
                              session={session}
                              request={request}
                              setHasPin={setHasPin}
                          />
                      ),
                  },
        [hasPin, session, request]
    )
}
