import React from 'react'
import {Bar} from 'react-chartjs-2'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import list from 'permissions/centralStations/list'

import DeliveranceTable from '../DeliveranceTable/DeliveranceTable'

const options = {
    animation: false,
    indexAxis: 'y',
    scales: {
        x: {
            display: true,
            grid: {
                color: 'rgba(176, 173, 169, 0.08)',
            },
            ticks: {
                stepSize: 1,
                beginAtZero: true,
            },
        },
        y: {
            display: true,
            grid: {
                color: 'rgba(176, 173, 169, 0.08)',
            },
        },
    },
    elements: {
        bar: {
            borderWidth: 1,
        },
    },
    responsive: true,
    plugins: {
        legend: {display: false},
        title: {
            display: false,
        },
        tooltip: {
            displayColors: false,
            callbacks: {
                label(tooltipItem) {
                    return tooltipItem.label + ' :' + tooltipItem.formattedValue
                },
                title() {
                    return ''
                },
            },
        },
    },
}

export default function DeliveryStatus({data, deliveranceByCS, centralStations}) {
    const {isPermitted} = usePermission(list)
    return (
        <div>
            <h3 className="deliverance-heading">{__('Overall status')}</h3>
            <div className="deliverance-line-chart">
                <Bar options={options} data={data} />
            </div>
            <div className="centralstations-events">
                {isPermitted &&
                    Object.keys(deliveranceByCS).map((key) => (
                        <DeliveranceTable
                            key={key}
                            deliverance={deliveranceByCS[key]}
                            centralStation={centralStations[key]}
                        />
                    ))}
            </div>
        </div>
    )
}

DeliveryStatus.propTypes = {
    options: PropTypes.object,
    data: PropTypes.object,
    deliveranceByCS: PropTypes.object,
    centralStations: PropTypes.object,
}
