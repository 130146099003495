import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'
import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/icons_user.svg'
import {ReactComponent as IconPassword} from 'ipmp-react-ui/icons/cameras-password.svg'
import {ReactComponent as IconCode} from 'ipmp-react-ui/icons/qr-code.svg'
import {ReactComponent as IconLabel} from 'ipmp-react-ui/icons/icons-comment.svg'
import {ReactComponent as IconNumber} from 'ipmp-react-ui/icons/icons_number.svg'
import Button from 'ipmp-react-ui/Button'
import Form from 'ipmp-react-ui/Form'

import {__} from 'utils/i18n'
import {WIFI_CAMERA_ENROLMENT_PREFIX} from 'constants/device'
import {DEVICE_TYPE_CAMERA} from 'constants/deviceType'
import {PartitionSelect} from 'components/Panel'
import {DismissConfirmation} from 'components/Modal'
import useForm from 'hooks/useForm'
import {addProvisionCamera} from 'modules/forms/handlers'

const getRules = (panelId, hasPartitions) => {
    const featureRules = useSelector(({features}) => features.store.byIds[panelId])
    const cameraLabelValidationRules = featureRules?.validation_rules[DEVICE_TYPE_CAMERA]
    return {
        user: {
            presence: true,
            length: {
                maximum: 255,
            },
        },
        password: {
            presence: true,
        },
        device_number: {
            numericality: {
                greaterThanOrEqualTo: 0,
            },
        },
        serial_number: {
            presence: true,
            length: {
                maximum: 28,
            },
        },
        label: {
            presence: true,
            labelByFeatureset: {
                maxLength: 28,
                validationRules: cameraLabelValidationRules,
            },
        },
        partitions: {
            presence: !!hasPartitions,
        },
    }
}

const ProvisionISR = ({panelId, setProvisionData, data, onClose, panelSerial}) => {
    const {handle, isLoading, form} = useForm(addProvisionCamera)
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
    const [changed, setChanged] = useState(false)

    const {hasPartitions} = useSelector(({panels}) => panels.state.byIds[panelId]) || {}

    const handleChangeField = (e) => {
        setProvisionData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleAddPartition = (e, value) => {
        if (value) {
            const newPartition = {label: 'Partition ' + value, value: value}
            setProvisionData((prevData) => ({
                ...prevData,
                partitions: [...prevData.partitions.concat(value)],
                partitionValues: [...prevData.partitionValues.concat(newPartition)],
            }))
        }
    }

    const onHandleClose = () => {
        changed ? setShowDismissConfirmation(true) : onClose()
    }

    const agreeDismiss = () => {
        onClose()
    }

    const cancelDismiss = () => setShowDismissConfirmation(false)

    const onChange = () => {
        setChanged(true)
    }

    const dismiss = (e) => {
        e && e.preventDefault()

        if (changed) {
            setShowDismissConfirmation(true)
        } else {
            onClose()
        }
    }

    const handleRemovePartition = (e, value) => {
        if (value) {
            setProvisionData((prevData) => ({
                ...prevData,
                partitions: [
                    ...prevData.partitions.filter((val) => {
                        return val !== value
                    }),
                ],
                partitionValues: [
                    ...prevData.partitionValues.filter((val) => {
                        return val.value !== value
                    }),
                ],
            }))
        }
    }

    const onSubmit = () => {
        handle({...data, panelId, panelSerial})
    }

    return (
        <Form
            onSubmit={onSubmit}
            onClose={dismiss}
            className="add-camera-tab"
            rules={getRules(panelId, hasPartitions)}
            isLoading={isLoading}
            onChange={onChange}
            {...form}
        >
            <CardClose onClick={onClose} />
            <CardHeader>{__('Add Provision ISR')}</CardHeader>
            <CardContent>
                <div className="add-camera-form--section">
                    <h3>{__('User info')}</h3>
                    <div className="device-form-content">
                        <Input
                            label={__('User')}
                            name="user"
                            dataTestId="user"
                            Icon={IconUser}
                            value={data.user}
                            onChange={handleChangeField}
                            iconLeft
                        />
                        <Input
                            label={__('Password')}
                            name="password"
                            dataTestId="password"
                            Icon={IconPassword}
                            value={data.password}
                            onChange={handleChangeField}
                            secure
                            revealable
                            iconLeft
                        />
                    </div>
                </div>
                <div className="add-camera-form--section">
                    <h3>{__('Camera info')}</h3>
                    <div className="device-form-content">
                        <Input
                            label={__('Serial Number')}
                            name="serial_number"
                            dataTestId="serial_number"
                            Icon={IconCode}
                            value={data.serial_number}
                            onChange={handleChangeField}
                            iconLeft
                        />
                    </div>
                </div>
                <div className="add-camera-form--section">
                    <h3>{__('General info')}</h3>
                    <div className="device-form-content">
                        <Input
                            label={__('Device Number')}
                            name="device_number"
                            dataTestId="device_number"
                            Icon={IconNumber}
                            onChange={handleChangeField}
                            value={data.device_number}
                            iconLeft
                        />
                        <Input
                            label={__('Label')}
                            name="label"
                            dataTestId="label"
                            Icon={IconLabel}
                            onChange={handleChangeField}
                            value={data.label}
                            iconLeft
                        />
                        {hasPartitions && (
                            <PartitionSelect
                                enrollmentPrefix={WIFI_CAMERA_ENROLMENT_PREFIX}
                                panelId={panelId}
                                handleAddPartition={handleAddPartition}
                                handleRemovePartition={handleRemovePartition}
                                defaultValues={data.partitionValues}
                                icon
                            />
                        )}
                    </div>
                </div>
                {showDismissConfirmation && (
                    <DismissConfirmation
                        dismiss={dismiss}
                        cancelDismiss={cancelDismiss}
                        agreeDismiss={agreeDismiss}
                    />
                )}
            </CardContent>
            <CardActions>
                <Button onClick={onHandleClose} borderless>
                    {__('Dismiss')}
                </Button>
                <Button primary type="submit" className="add-camera-submit">
                    {__('Save')}
                </Button>
            </CardActions>
        </Form>
    )
}

export default ProvisionISR
