import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {ErrorWithIcon} from 'ipmp-react-ui/Error'
import {ReactComponent as IconInteractiveUsers} from 'ipmp-react-ui/icons/interactive-users.svg'

import __ from 'utils/i18n'

import InteractiveUserAssignButton from './InteractiveUserAssignButton'

export default function InteractiveUserNoAssigned({
    user: {id, isPinSet},
    setFooterContent,
}) {
    useEffect(() => {
        setFooterContent(null)
    }, [id])

    return (
        <ErrorWithIcon
            errorTitle={__('No assigned customers')}
            Icon={IconInteractiveUsers}
        >
            <InteractiveUserAssignButton panelUserId={id} isPinSet={isPinSet} />
        </ErrorWithIcon>
    )
}

InteractiveUserNoAssigned.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
    }).isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
