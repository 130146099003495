import {useEffect, useRef} from 'react'

export default function useRunnerPoll(
    {runner, startPollRunnerData, stopPollRunnerData, refreshRunner, ...rest},
    mapToRefreshRunner = (args) => ({...args})
) {
    const runnerRef = useRef(runner)

    useEffect(() => {
        if (runner) {
            startPollRunnerData(runner.id, (payload) =>
                refreshRunner(mapToRefreshRunner({...payload, ...rest}))
            )
        }

        return () => {
            if (runner) {
                stopPollRunnerData(runner.id)
            }
        }
    }, [])

    useEffect(() => {
        const prevRunner = runnerRef.current

        if (runner && !prevRunner) {
            startPollRunnerData(runner.id, (payload) =>
                refreshRunner(mapToRefreshRunner({...payload, ...rest}))
            )
        }

        if (!runner && prevRunner) {
            stopPollRunnerData(prevRunner.id)
        }

        runnerRef.current = runner
    }, [runner])
}
