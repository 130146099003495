import React from 'react'

import ProcessesPage from './ProcessesPage'
import path from 'utils/path'

export default class ProcessesRoute {
    path = path('processes')

    element = (<ProcessesPage />)
}
