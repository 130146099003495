import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconStop} from 'ipmp-react-ui/icons/stop.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {stopSession} from 'modules/devices/autolearn/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import __ from 'utils/i18n'

export default function SessionButtons({session}) {
    const [onClick] = useActions([() => stopSession(session)])

    return !session.token ? null : (
        <>
            <WithConfirmationButton
                Icon={IconStop}
                flat
                onClick={onClick}
                message={__('Do you really want to interrupt autolearn session?')}
                title={__('Stop autolearn session')}
                label={__('Stop')}
            />
        </>
    )
}

SessionButtons.propTypes = {
    session: PropTypes.object.isRequired,
}
