import {changeLocale} from 'modules/locales/actions'
import {
    take,
    call,
    put,
    select,
    all,
    takeLatest,
    delay,
    takeEvery,
} from 'redux-saga/effects'

import * as api from 'api/auth'
import {getLastKnownTheme} from 'storage/lastKnownTheme'
import * as actions from './actions'
import applyTheme from 'utils/applyTheme'
import _ from 'lodash'

import {snackShow} from 'modules/snacks'
import {autologoutContinue} from 'modules/auth/sign/actions'
import {DARK_CLASSES, DEFAULT_THEME, getOSTheme, LIGHT_CLASSES} from 'constants/themes'
import {MENU_ORDER_UPDATE_DELAY} from 'constants/mainMenu'
import {enableSounds, resetMenuOrderUpdateFlag, showSoundPermissionError} from './actions'
import __ from 'utils/i18n'
import confirmationSound from 'assets/sounds/confirmation.mp3'

export default function* () {
    applyTheme(getLastKnownTheme())

    yield all([
        persistSettings(),
        applySettings(),
        detectOSThemeChange(),
        updateMenuOrder(),
        takeEvery(showSoundPermissionError, showSoundPermissionsErrorSnack),
        takeEvery(enableSounds, enableBrowserSounds),
    ])
}

function* persistSettings() {
    while (true) {
        const action = yield take(Object.values(actions))

        if (
            action.type === actions.update.toString() ||
            action.type === actions.revert.toString() ||
            action.type === actions.updateMenuOrder.toString() ||
            action.type === actions.updateFromServiceWorkerWithoutSend.toString() ||
            action.type === actions.resetMenuOrderUpdateFlag().toString()
        ) {
            continue
        }

        const settingsToPersist = yield select((state) => state.settings)

        try {
            yield call(api.setSettings, settingsToPersist)
        } catch (error) {
            yield put(snackShow(error.message))
        }
    }
}

function* detectOSThemeChange() {
    const mediaQuery = getOSTheme()
    const handleOSThemeChange = () => {
        if (mediaQuery.matches && getLastKnownTheme() === DEFAULT_THEME) {
            applyTheme(getLastKnownTheme(), DARK_CLASSES)
        }
        if (!mediaQuery.matches && getLastKnownTheme() === DEFAULT_THEME) {
            applyTheme(getLastKnownTheme(), LIGHT_CLASSES)
        }
    }

    mediaQuery.addEventListener('change', handleOSThemeChange)

    yield takeLatest(actions.updateOSTheme, () => {
        mediaQuery.removeEventListener('change', handleOSThemeChange)
    })
}

function* updateMenuOrder() {
    let prevState = yield select((state) => state.settings.menuOrder)

    while (true) {
        yield delay(MENU_ORDER_UPDATE_DELAY)

        const settings = yield select((state) => state.settings)

        if (_.isEqual(prevState, settings.menuOrder) || !settings.shouldUpdateMenuOrder) {
            continue
        }

        try {
            yield call(api.setSettings, settings)
            prevState = settings.menuOrder
            yield put(resetMenuOrderUpdateFlag)
        } catch (error) {
            yield put(snackShow(error.message))
        }
    }
}
function* applyLanguage(locale) {
    yield put(changeLocale(locale))
}

function* applyAutologout() {
    yield put(autologoutContinue())
}

function* applySettings() {
    let old = yield select((state) => state.settings)

    const applyers = {
        theme: applyTheme,
        language: applyLanguage,
        autologout: applyAutologout,
    }

    while (true) {
        yield take(Object.values(actions))
        let settings = yield select((state) => state.settings)

        for (const key in applyers) {
            if (key in settings) {
                const value = settings[key]

                if (old[key] !== value) {
                    yield call(applyers[key], value)
                }
            }
        }

        old = settings
    }
}

function* showSoundPermissionsErrorSnack() {
    yield put(
        snackShow(
            __(
                'Notification sounds in your browser are off by default, click button to enable'
            ),
            enableSounds(),
            __('Enable'),
            true
        )
    )
}

function enableBrowserSounds() {
    const audio = new Audio(confirmationSound)
    audio.play()
}
