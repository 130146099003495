import React from 'react'
import {useSelector} from 'react-redux'

import {
    STATUS_BLOCKED,
    STATUS_OFFLINE,
    STATUS_ONLINE,
    STATUS_SUSPENDED,
} from 'constants/userStatuses'
import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
    fetchSuggests,
} from 'modules/users/list/actions'
import __ from 'utils/i18n'
import list from 'permissions/users/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {selectUsersList} from 'modules/users/list/selectors'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import {DEALER_TYPE, DEFAULT_TYPE, INSTALLER_TYPE} from 'constants/role'

const SearchVisibility = withVisibility()(Search)

export default function UsersSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectUsersList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('name', __('Name')),
        new SuggestFilter('phone', __('Phone')),
        new SuggestFilter('roleName', __('Role Name')),
        new ValuesFilter('roleType', __('Role Type'), {
            [DEFAULT_TYPE]: __('Default'),
            [DEALER_TYPE]: __('Dealer'),
            [INSTALLER_TYPE]: __('Installer'),
        }),
        new SuggestFilter('roleCreatorName', __('Role Creator Name')),
        new DateFilter('lastLogin', __('Last Login')),
        new ValuesFilter('status', __('Status'), {
            [STATUS_ONLINE]: __('Online'),
            [STATUS_OFFLINE]: __('Offline'),
            [STATUS_SUSPENDED]: __('Suspended'),
            [STATUS_BLOCKED]: __('Blocked'),
        }),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Users')}
        />
    )
}
