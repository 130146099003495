import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPassword} from 'ipmp-react-ui/icons/password.svg'

import __ from 'utils/i18n'
import {withPermissionVisibility} from 'containers/withPermission'
import {reset} from 'permissions/installers/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {resetPassword} from 'modules/installers/store/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'

const ResetPasswordButton = ({installer: {id, email}}) => {
    const {onClick} = useActions({onClick: resetPassword})

    return (
        <WithConfirmationButton
            Icon={IconPassword}
            flat
            onClick={() => onClick(id)}
            message={__('Do you really want to reset password for %s?', email)}
            title={__('Reset Password')}
            label={__('Reset Password')}
        />
    )
}

ResetPasswordButton.propTypes = {
    installer: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
    }).isRequired,
}

export default withPermissionVisibility(reset)(ResetPasswordButton)
