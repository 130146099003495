import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import {isAllowDisableUser, isAllowManipulateUser} from 'constants/accountRoles'
import DisablePanelUserButton from 'components/Panel/PanelUser/DisablePanelUserButton'

import {Code, ExpirationDate, Label, LastName, Partitions} from './Rows'
import NotAllowRoleDisclaimer from './NotAllowRoleDisclaimer'

const FooterContent = ({user}) => {
    return (
        <div className="blindModal-card-actions-buttons">
            <DisablePanelUserButton row={user} hasLabel />
        </div>
    )
}

export default function PanelUserTable({user, setFooterContent}) {
    const {isPinSet, role} = user
    useEffect(() => {
        if (isPinSet) {
            setFooterContent(() => <FooterContent user={user} />)
        }
        if (!isPinSet || !isAllowDisableUser(role)) {
            setFooterContent(null)
        }
    }, [user, isPinSet])

    return (
        <div className="panelUserInfoTable table">
            <Code user={user} />
            <Label user={user} />
            <Partitions user={user} />
            <LastName user={user} />
            <ExpirationDate user={user} />
            {!isAllowManipulateUser(role) && <NotAllowRoleDisclaimer role={role} />}
        </div>
    )
}

PanelUserTable.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string.isRequired,
    }).isRequired,
    setFooterContent: PropTypes.func.isRequired,
}
