import React from 'react'
import PropTypes from 'prop-types'

import Togglebox from 'ipmp-react-ui/ToggleBox'
import {ReactComponent as IconKey} from 'ipmp-react-ui/icons/autolearn/Key.svg'

import __ from 'utils/i18n'

const keysMap = {
    s2UnsecuredKey: 0x01,
    s2SecureKey: 0x02,
    s2Access: 0x04,
    s0NetworkKey: 0x08,
}

export default function KeysReportForm({keys, setKeys}) {
    const onChange = (key, value) =>
        setKeys(value ? keys | keysMap[key] : keys & ~keysMap[key])

    return (
        <>
            <div className="autolearn-in-process">
                <IconKey />
                <div className="autolearn-description-content-wrapper">
                    <div className="autolearn-description-content-title">
                        {__('Grant keys')}
                    </div>
                    <div className="autolearn-description-content-message">
                        {__('Select the required secure keys.')}
                    </div>
                </div>
            </div>
            <div className="autolearn-zwave-toggle-group">
                <div>{__('Authenticated, S2')}</div>
                <Togglebox
                    onChange={(e) => onChange('s2SecureKey', e.target.checked)}
                    label={__(
                        'Highest level S2 security, not accessible from less secure devices'
                    )}
                    checked={(keys & keysMap.s2SecureKey) > 0}
                />
                <div>{__('Unaunthenticated, S2')}</div>
                <Togglebox
                    onChange={(e) => onChange('s2Access', e.target.checked)}
                    label={__(
                        'A lower from of S2 security, also tick Authenticated if available'
                    )}
                    checked={(keys & keysMap.s2Access) > 0}
                />
                <div>{__('Legacy security network key, S0')}</div>
                <Togglebox
                    onChange={(e) => onChange('s0NetworkKey', e.target.checked)}
                    label={__('S0, a previous security framework, superseded by S2')}
                    checked={(keys & keysMap.s0NetworkKey) > 0}
                />
            </div>
        </>
    )
}

KeysReportForm.propTypes = {
    keys: PropTypes.number.isRequired,
    setKeys: PropTypes.func.isRequired,
}
