import React from 'react'

import PushBasicConfigurationPage from './PushBasicConfigurationPage'
import path from 'utils/path'

export default class PushBasicConfigurationRoute {
    path = path('basicConfiguration.push')

    element = (<PushBasicConfigurationPage />)
}
