import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

export default function RemoteInspectionSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.RemoteInspection)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Edit Remote Inspection Settings')}
        >
            <Checkbox
                label={__('Send Email to the Customer of Succeed RI')}
                name="doSendEmailOnSuccess"
                defaultChecked={data.doSendEmailOnSuccess}
            />

            <Checkbox
                label={__("Generate 'Succeed/Failed RI' Event")}
                name="doGenerateResultEvent"
                defaultChecked={data.doGenerateResultEvent}
            />
        </ModalCardForm>
    )
}

RemoteInspectionSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        doSendEmailOnSuccess: PropTypes.bool,
        doGenerateResultEvent: PropTypes.bool,
    }),
}
