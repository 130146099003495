import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import InfoCards, {InfoCard} from 'ipmp-react-ui/InfoCards'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import Supervision from './Supervision'

import __ from 'utils/i18n'
import withLoader from 'containers/withLoader'
import * as actions from 'modules/system/settings/actions'
import {showSystemSettingsSupervisionModal} from 'modules/modals/actions'

export function Receiver({supervisionModal}) {
    const supervisionEditBtn = (
        <Button onClick={supervisionModal} borderless Icon={IconEdit} />
    )

    return (
        <InfoCards cols={1}>
            <InfoCard
                cols={2}
                title={__('Supervision')}
                headerButton={supervisionEditBtn}
            >
                <Supervision />
            </InfoCard>
        </InfoCards>
    )
}

export default compose(
    connect(
        ({
            system: {
                settings: {supervision},
            },
        }) => ({
            supervision,
            isLoading: supervision.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    supervision: actions.fetchSupervision,
                    showSystemSettingsSupervisionModal,
                },
                dispatch
            ),
        ({supervision}, dispatchProps) => {
            return {
                ...dispatchProps,
                supervisionModal: () => {
                    dispatchProps.showSystemSettingsSupervisionModal(supervision)
                },
            }
        }
    ),
    withLoader(({supervision}) => {
        supervision()
    })
)(Receiver)
