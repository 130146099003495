import {createSelector} from 'reselect'
import {selectFotaAotaVersions} from 'modules/devices/list/selectors'
import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'

export const selectPanelCustomerInfo = createSelector(
    (state, panelId) => state?.panels?.store?.byIds[panelId],
    (panel) => panel
)

export const selectPanelInfo = createSelector(
    (state, panelId) => selectFotaAotaVersions(state, panelId),
    (state, panelId) => state?.panels?.store?.byIds[panelId],
    (fotaAotaVersions, panel) => ({fotaAotaVersions, panel})
)

export const selectGroupTestsBatches = createSelector(
    (state) => state.batches.store.byIds,
    (_, {tests}) => tests,
    (_, {panelSerial}) => panelSerial,
    (byIds, tests, panelSerial) => {
        return tests.map((test) => {
            return Object.values(byIds)
                .filter(
                    (batch) =>
                        batch.type === test.testName && batch.panelSerial === panelSerial
                )
                .sort((a, b) => new Date(b.started) - new Date(a.started))
                .shift()
        })
    }
)

export const selectBatchesInProgress = createSelector(
    (_, groupTestsBatches) => groupTestsBatches,
    (groupTestsBatches) => {
        const inProgress =
            groupTestsBatches &&
            groupTestsBatches.map((batch) => {
                const {stats} = batch || {}

                if (!stats) {
                    return false
                }
                const {start, handled, pending} = stats
                return start > 0 || handled > 0 || pending > 0
            })

        return inProgress.includes(true)
    }
)

export const selectSystemTestsInProgress = createSelector(
    ({state, panelId}) => state.panels.systemTests[panelId],
    ({state, panelId}) => state.panels.store.byIds[panelId]?.testsData,
    ({state}) => state.batches.store.byIds,
    (supportedTests, panelTests, batches) => {
        const batchesInProgress = Object.values(batches).filter((batch) => {
            const {stats} = batch || {}

            if (!stats) {
                return true
            }

            const {start, handled, pending} = stats

            return start > 0 || handled > 0 || pending > 0
        })

        return (
            (supportedTests &&
                Object.values(supportedTests?.supportedTests).filter((test) => {
                    return batchesInProgress.some(({type}) => test.testName === type)
                })) ||
            []
        )
    }
)

export const selectNewBatchInProgress = createSelector(
    selectBatchIsTemporary,
    (state) => state.batches.store.byIds,
    (_, batchId) => batchId,
    (isTemporary, byIds, batchId) => {
        const {stats} = byIds[batchId] || {}

        if (!stats) {
            return true
        }

        const {start, handled, pending} = stats

        return isTemporary || start > 0 || handled > 0 || pending > 0
    }
)

export const selectBatchByName = createSelector(
    (state) => state.batches.store.byIds,
    (_, {testName}) => testName,
    (_, {panelSerial}) => panelSerial,
    (byIds, testName, panelSerial) => {
        const panelBatches = Object.values(byIds).filter((batch) => {
            return batch.panelSerial === panelSerial
        })
        const testBatches = panelBatches.filter((batch) => {
            return batch.type === testName
        })

        return testBatches
            .sort((a, b) => new Date(b.started) - new Date(a.started))
            .shift()
    }
)
