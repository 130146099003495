import React from 'react'

import Layout from 'ipmp-react-ui/Layout'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import TableWithBlind from 'components/Table/TableWithBlind'

import AssignInstallerButton from './GridBar/AssignInstallerButton'
import ChangeCodeButton from './GridBar/ChangeCodeButton'
import PanelInstallersBlind from './PanelInstallersBlind'
import PanelInstallersTable from './PanelInstallersTable'
import useRouterPanelId from 'hooks/useRouterPanelId'

export default function PanelInstallers() {
    const panelId = useRouterPanelId()

    return (
        <Layout vertical className="panelPage-content">
            <Bar className="panel-page-installers-tab">
                <BarSpace />
                <ChangeCodeButton panelId={panelId} />
                <AssignInstallerButton />
            </Bar>
            <TableWithBlind>
                {(props) => <PanelInstallersBlind {...props} />}
                {(props) => <PanelInstallersTable {...props} />}
            </TableWithBlind>
        </Layout>
    )
}
