import React from 'react'

import Autocomplete from 'ipmp-react-ui/Autocomplete'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {panelBillingActivate} from 'modules/forms/handlers'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchCountries} from 'modules/countries/actions'

const rules = {
    countryId: {
        presence: true,
    },
}

const CountrySelect = withSelectLoader(
    fetchCountries,
    (state) => state.countries.isLoading,
    ({countries}) =>
        Object.values(countries.byIds).map(({id, name}) => ({value: id, label: name}))
)(Autocomplete)

export default function ActivatePlan({hide, panelId, planId}) {
    const {handle, isLoading, form} = useForm(panelBillingActivate)

    const onSubmit = ({countryId}) => {
        handle({countryId, panelId, planId})
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnSubmit
            confirmOnDismiss
            onSubmit={onSubmit}
            isLoading={isLoading}
            submitLabel={__('Confirm')}
            header={__('You are about to change your billing plan panel')}
        >
            <div className="panelBillingActivateModal__description">
                <p>{__('Choose a country to continue your plan.')}</p>
                <p>{__('Changing the billing plan may take several hours.')}</p>
            </div>
            <CountrySelect name="countryId" label={__('Country')} />
        </ModalCardForm>
    )
}
