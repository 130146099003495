import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {resolveFaults} from 'permissions/panels/selection'
import {showResolveFaultsModal} from 'modules/modals/actions'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export default function ResolveFaultsItem() {
    const {isPermitted} = usePermission(resolveFaults)
    const onClick = useActions(showResolveFaultsModal)

    return (
        <ConnectedMenuItem isVisible={isPermitted} onClick={onClick}>
            {__('Resolve faults')}
        </ConnectedMenuItem>
    )
}
