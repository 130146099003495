import {createSelector} from 'reselect'

export const selectBatches = createSelector(
    (state) => state.batches.store.byIds,
    (byIds) => ({...byIds})
)

export const selectFreshBatchByTypeAndPanelSerial = createSelector(
    (state) => selectBatches(state),
    (_, processType, panelId) => ({processType, panelId}),
    (batches, {processType, panelId}) =>
        Object.values(batches)
            .filter((item) => item.type === processType && item.panelId === panelId)
            .sort((a, b) => new Date(b.started) - new Date(a.started))[0]
)
