import React from 'react'
import {compose} from 'redux'
import {useNavigate} from 'react-router'

import Table from 'ipmp-react-ui/Table'

import {edit} from 'permissions/centralStations/actions'
import list from 'permissions/centralStations/list'
import selectionPermission from 'permissions/centralStations/selection'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import EditCell from './TableCell/EditCell'
import CommunicationCell from './TableCell/CommunicationCell'

import {__} from 'utils/i18n'
import path from 'utils/path'
import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import useCentralStations from 'hooks/pages/useCentralStations'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import CentralStationStatus from 'components/CentralStations/CentralStationStatus'

const allColumns = [
    {
        name: () => __('Name'),
        render: ({name}) => {
            return name
        },
    },
    {
        name: () => __('Status'),
        render: ({status}) => <CentralStationStatus status={status} />,
    },
    {
        name: () => __('Protocol'),
        width: 100,
        maxWidth: 220,
        render: ({protocol}) => protocol,
    },
    {
        name: () => __('Communication'),
        width: 220,
        component: CommunicationCell,
    },
    {
        tooltip: false,
        permission: edit,
        width: 50,
        fixed: true,
        component: EditCell,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({init}) => init())
)(Table)

export default function CentralStationsTable() {
    const navigate = useNavigate()
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })
    const centralStations = useCentralStations()
    const {selection, select, deselect} = useSelection()
    const {columns} = useTableColumnsPermission(allColumns)

    const onRowClick = ({id}) => navigate(path('centralStation', {id}))

    return (
        <ConnectedTable
            {...centralStations}
            fullHeight
            columns={columns}
            hasSelection={hasSelection}
            select={select}
            deselect={deselect}
            selection={selection}
            emptyMessage={__('No stations found')}
            onRowClick={onRowClick}
            className="central-stations-table"
        />
    )
}
