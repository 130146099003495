import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'

import usePermission from 'hooks/usePermission'
import {stations} from 'permissions/group/navigation'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showEditCentralStationLinksModal} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function EditCSCommunicatingProfilesButton({groupId, stationId}) {
    const {isPermitted} = usePermission(stations)
    const {onClick} = useActions({
        onClick: () => showEditCentralStationLinksModal(groupId, stationId),
    })

    return (
        <ButtonVisibility
            borderless
            Icon={EditIcon}
            onClick={onClick}
            isVisible={isPermitted}
        />
    )
}
