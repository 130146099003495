import mapper from 'utils/dataMapper'
import set from 'lodash-es/set'
import {
    DEFAULT_MENU_ORDER,
    DEFAULT_MENU_ORDER_MANUAL,
    logToCount,
} from 'constants/mainMenu'

export const map = mapper((data) => {
    if (data.user) {
        const {user, permissions, settings, serverSettings} = data
        let menuOrderLogToCount = {}
        let menuOrderManualLogToCount = {}

        if (settings?.menuOrder) {
            Object.keys(settings.menuOrder).forEach((orderItem) => {
                menuOrderLogToCount[orderItem] = logToCount(settings.menuOrder[orderItem])
            })
        } else {
            menuOrderLogToCount = DEFAULT_MENU_ORDER
        }

        if (settings?.menuOrderManual) {
            Object.keys(settings.menuOrderManual).forEach((orderItem) => {
                menuOrderManualLogToCount[orderItem] = logToCount(
                    settings.menuOrderManual[orderItem]
                )
            })
        } else {
            menuOrderManualLogToCount = DEFAULT_MENU_ORDER_MANUAL
        }

        return {
            user: {
                id: parseInt(user.usr_id),
                email: user.usr_email,
                name: user.usr_name,
                perPage: user.usr_per_page,
                phone: user.usr_phone,
                status: user.usr_status,
                countryId: user.coy_id,
                countryName: user.coy_name,
                roleName: user.roe_name,
                roleType: user.roe_type,
            },
            permissions: Object.keys(permissions).reduce(
                (mapped, resource) => set(mapped, resource, permissions[resource]),
                {}
            ),
            settings: {
                ...settings,
                ...{
                    menuOrder: menuOrderLogToCount,
                    menuOrderManual: menuOrderManualLogToCount,
                },
            },
            serverSettings: serverSettings,
        }
    }

    return {
        isOTP: data.is_otp,
        phone: data.phone,
        userConfirmed: data.user_confirmed,
        otpLength: data.otpLength,
    }
})

export const keysMap = {
    email: 'usr_email',
    password: 'usr_password',
}
