import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {remove} from 'modules/eventRules/list/actions'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {add} from 'permissions/installers/actions'
import withConfirmation from 'containers/withConfirmation'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonConfirmation = withConfirmation()(Button)

export default function RemoveEventRulesButton({row: {id}}) {
    const {isPermitted} = usePermission(add)
    const {onClick} = useActions({
        onClick: () => remove([id]),
    })

    return (
        <ButtonConfirmation
            flat
            small
            Icon={IconRemove}
            onClick={onClick}
            isVisible={isPermitted}
            title={__('Remove Event Rule')}
            message={__('Do you really want to remove event rule?')}
        />
    )
}
