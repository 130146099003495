import React from 'react'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import withConfirmation from 'containers/withConfirmation'
import {panelBillingDeactivate} from 'modules/forms/handlers'

const DeactivateButton = withConfirmation()(Button)

export default function PanelBillingDeactivateButton({panelId}) {
    const {handle, isLoading} = useForm(panelBillingDeactivate)

    const onClick = () => {
        handle({panelId})
    }

    return (
        <DeactivateButton
            danger
            onClick={onClick}
            disabled={isLoading}
            positive={__('Deactivate')}
            cardClassName="panelBillingDeactivateConfirmation"
            message={__('Do you really want to deactivate billing plan for the panel?')}
            title={__('Deactivating plan')}
        >
            {__('Deactivate plan')}
        </DeactivateButton>
    )
}
