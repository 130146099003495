import Gettext from 'node-gettext'
import moment from 'moment'
import momentTimezone from 'moment-timezone'
import {sprintf} from 'sprintf-js'
import {getLangDir} from 'rtl-detect'
import mapValues from 'lodash-es/mapValues'

import {warn} from 'utils/log'
import __ from './i18n'

const gt = new Gettext({debug: false})
const domain = 'messages'
let currentLocale = null
const loaded = []

export const addLocale = (locale, data) => {
    const translations = mapValues(data.translations, (context) =>
        mapValues(context, (msgstr) => ({msgstr}))
    )

    gt.addTranslations(locale, domain, {
        ...data,
        translations,
    })

    loaded.push(locale)
}

export const isLoaded = (locale) => loaded.includes(locale)

export const setLocale = (locale) => {
    const momentLocale = [locale, locale.substr(0, 2)]
    moment.locale(momentLocale)
    momentTimezone.locale(momentLocale)

    gt.setLocale(locale)
    currentLocale = locale

    setLangDirection(locale)
}

export function getCurrentLocale() {
    return currentLocale
}

export function gettext(key, ...vars) {
    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    if (vars.length) {
        return sprintf(gt.gettext(key), ...vars)
    } else {
        return gt.gettext(key)
    }
}

export function ngettext(key, pluralKey, count, vars) {
    if (!vars) {
        vars = [count]
    }

    if (!currentLocale) {
        warn(`Trying to get translation '${key}' while locale is not inited`)
    }

    return sprintf(gt.ngettext(key, pluralKey, count), ...vars)
}

export function convertConfigValue(value) {
    switch (typeof value) {
        case 'undefined':
            return __('not set')
        case 'boolean':
            return value ? __('Enabled') : __('Disabled')
        default:
            return value
    }
}

const setLangDirection = (locale) => {
    document.documentElement.setAttribute('dir', getLangDir(locale))
}
