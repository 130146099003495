import React from 'react'
import * as PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import EditIconList from './EditIconList'
import ModalCardForm from 'ui/ModalCardForm'
import {editHadIcon} from 'modules/forms/handlers'
import {validConfigurableIconValue} from 'constants/had'

export default function EditIcon({hide, panelId, hadId, deviceType, defaultValue}) {
    const {handle, form, isLoading} = useForm(editHadIcon)

    defaultValue = validConfigurableIconValue(defaultValue)

    function onSubmit({icon}) {
        if (icon !== defaultValue) {
            handle({panelId, hadId, deviceType, icon})
        } else {
            hide()
        }
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            hide={hide}
            onSubmit={onSubmit}
            isLoading={isLoading}
            header={__('Edit icon')}
            className="modalFormEditHadIcon"
            {...form}
        >
            <EditIconList defaultValue={defaultValue} />
        </ModalCardForm>
    )
}

EditIcon.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
    hadId: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
}
