import React from 'react'

import {useActions} from 'modules/higherOrder/useActions'
import {showTriggerDiscoveryModal} from 'modules/runners/store/actions'
import {TriggerDiscoveryBase} from 'pages/Panels/GridBar/DropDowns/Items/TriggerDiscoveryItem'

export default function TriggerDiscoveryItem() {
    const onClick = useActions(showTriggerDiscoveryModal)

    return <TriggerDiscoveryBase onClick={onClick} />
}
