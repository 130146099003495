import React from 'react'

import Definition from 'ipmp-react-ui/Definition'

import {getRoleTypeLabel} from 'constants/role'
import PropTypes from 'prop-types'

export default function Role({name, type}) {
    return <Definition title={name} detail={getRoleTypeLabel(type)} multiLine />
}

Role.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
}
