import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import Logo from 'ui/Logo'
import LoginForm from 'components/Auth/LoginForm'
import ForgotPasswordForm from 'components/Auth/ForgotPasswordForm'
import {
    CONFIRMATION_CODE_SCREEN,
    FORGOT_PASSWORD_SCREEN,
    LOGIN_SCREEN,
    OTP_CHANGE_PASSWORD_SCREEN,
    PHONE_NUMBER_CONFIRMATION_SCREEN,
} from 'constants/loginScreen'
import PhoneNumberConfirmationForm from 'components/Auth/PhoneNumberConfirmationForm'
import ConfirmationCodeForm from 'components/Auth/ConfirmationCodeForm'
import OTPChangePassword from 'components/Auth/OTPChangePassword'
import PrivacyPolicyLink from '../../ui/PrivacyPolicyLink'

const screen = {
    [FORGOT_PASSWORD_SCREEN]: <ForgotPasswordForm />,
    [LOGIN_SCREEN]: <LoginForm />,
    [PHONE_NUMBER_CONFIRMATION_SCREEN]: <PhoneNumberConfirmationForm />,
    [CONFIRMATION_CODE_SCREEN]: <ConfirmationCodeForm />,
    [OTP_CHANGE_PASSWORD_SCREEN]: <OTPChangePassword />,
}

class Login extends Component {
    static propTypes = {
        loginScreenName: PropTypes.string,
    }

    render() {
        const {loginScreenName, children} = this.props

        return (
            <div className="login">
                <div className="login-logo">
                    <Logo dark />
                </div>

                {screen[loginScreenName]}

                {children}

                <PrivacyPolicyLink />
            </div>
        )
    }
}

export default connect(({auth: {sign}}) => ({
    ...sign,
}))(Login)
