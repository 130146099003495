import React from 'react'
import {compose} from 'redux'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import list from 'permissions/firmware/list'
import useSelection from 'hooks/useSelection'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import GroupCell from 'components/TableCells/GroupCell'
import PanelCell from 'components/TableCells/PanelCell'
import {withPermissionRejection} from 'containers/withPermission'
import selectionPermission from 'permissions/firmware/selection'
import usePatchTagUpdatePanels from 'hooks/pages/usePatchTagUpdatePanels'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account}) => (
            <PanelCell id={id} serial={serial} account={account} />
        ),
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell row={{id, name}} />,
    },
    {
        name: () => __('Model'),
        render: ({model}) => model,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({init, deselectAll}) => {
        init()
        deselectAll()
    })
)(Table)

export default function FirmwarePatchTagUpdateTable() {
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    const {rows, init, error, isLoading} = usePatchTagUpdatePanels()
    const {selection, select, deselect, deselectAll} = useSelection()

    return (
        <ConnectedTable
            rows={rows}
            fullHeight
            init={init}
            error={error}
            isLoading={isLoading}
            hasSelection={hasSelection}
            columns={columns}
            select={select}
            deselect={deselect}
            selection={selection}
            deselectAll={deselectAll}
            emptyMessage={__('No equipment')}
        />
    )
}
