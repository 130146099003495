import React from 'react'
import PropTypes from 'prop-types'

import Duration from 'ipmp-react-ui/Duration'

export default function BatchRunnerItem({
    id,
    panelId,
    onRowClick,
    serial,
    started,
    finished,
    children,
}) {
    const onClick = () => {
        onRowClick(panelId)
    }

    return (
        <div className="batchInfo-list-item" onClick={onClick}>
            <header className="batchInfo-list-item-details">
                <div className="batchInfo-list-item-details-panel">{serial}</div>
                <div className="batchInfo-list-item-details-duration">
                    <Duration from={started} to={finished} />
                </div>
            </header>
            <div className="batchInfo-list-item-status">{children}</div>
        </div>
    )
}

BatchRunnerItem.propTypes = {
    id: PropTypes.number,
    panelId: PropTypes.number,
    onRowClick: PropTypes.func,
    serial: PropTypes.string,
    started: PropTypes.instanceOf(Date),
    finished: PropTypes.instanceOf(Date),
}
