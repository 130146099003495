import React from 'react'
import * as PropTypes from 'prop-types'

import {
    TYPE_EMERGENCY_EMERGENCY,
    TYPE_EMERGENCY_FIRE,
    TYPE_EMERGENCY_SECURITY,
    TYPE_EMERGENCY_SILENT_EMERGENCY,
    TYPE_EMERGENCY_SILENT_SECURITY,
} from 'constants/events'
import SecurityButtons from 'components/Panel/Emergencies/Screens/Security/SecurityButtons'
import EmergencyButtons from 'components/Panel/Emergencies/Screens/Emergency/EmergencyButtons'
import FireButtons from 'components/Panel/Emergencies/Screens/Fire/FireButtons'
import InitButtons from 'components/Panel/Emergencies/Screens/Init/InitButtons'

export default function Buttons({data, hide, setData, selectedPartitions}) {
    switch (data?.chosen) {
        case TYPE_EMERGENCY_SECURITY:
        case TYPE_EMERGENCY_SILENT_SECURITY:
            return (
                <SecurityButtons
                    onClose={hide}
                    setData={setData}
                    selectedPartitions={selectedPartitions}
                />
            )
        case TYPE_EMERGENCY_EMERGENCY:
        case TYPE_EMERGENCY_SILENT_EMERGENCY:
            return (
                <EmergencyButtons
                    onClose={hide}
                    setData={setData}
                    selectedPartitions={selectedPartitions}
                />
            )
        case TYPE_EMERGENCY_FIRE:
            return (
                <FireButtons
                    onClose={hide}
                    setData={setData}
                    selectedPartitions={selectedPartitions}
                />
            )
        default:
            return <InitButtons onClose={hide} />
    }
}

Buttons.propTypes = {
    hide: PropTypes.func.isRequired,
    setData: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
}
