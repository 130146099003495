import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {TIMEOUT_DISABLED, TIMEOUTS_AUTOLOGOUT} from 'configs/timeouts'
import {getCountryName} from 'constants/ISO3166-1'
import themes, {DEFAULT_THEME} from 'constants/themes'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {getNativeLanguageName} from 'constants/ISO639-1'

import {
    setLanguage,
    setAutologout,
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
    setTheme,
    setTemperature,
    setEDSView,
    setBillingStatusView,
} from 'modules/settings/actions'
import {fetch} from 'modules/locales/actions'

import withLoader from 'containers/withLoader'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Select from 'ipmp-react-ui/Select'
import Button from 'ipmp-react-ui/Button'
import {Option} from 'ipmp-react-ui/Select'
import {humanDuration} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'
import {getTemperatureUnits} from 'utils/temperature'
import {EDS_VIEW_LONG, EDS_VIEW_SHORT} from 'constants/eventDelivery'
import {withPermissionVisibility} from 'containers/withPermission'
import {status as billingStatusPermission} from 'permissions/panel/billing/actions'
import {BILLING_STATUS_VIEW_LONG, BILLING_STATUS_VIEW_SHORT} from 'constants/billingPlan'

function BillingStatusViewSelect({onChange, defaultValue}) {
    return (
        <Select
            label={__('Billing status view')}
            onChange={onChange}
            defaultValue={defaultValue || BILLING_STATUS_VIEW_SHORT}
        >
            <Option
                key={BILLING_STATUS_VIEW_SHORT}
                value={BILLING_STATUS_VIEW_SHORT}
                label={__('Short view')}
            />
            <Option
                key={BILLING_STATUS_VIEW_LONG}
                value={BILLING_STATUS_VIEW_LONG}
                label={__('Full view')}
            />
        </Select>
    )
}

const BillingStatusViewSelectVisibility = withPermissionVisibility(
    billingStatusPermission
)(BillingStatusViewSelect)

class CommonSettings extends Component {
    static propTypes = {
        setLanguage: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        timeout: PropTypes.number,
        locale: PropTypes.string,
        setTheme: PropTypes.func.isRequired,
        setEDSView: PropTypes.func.isRequired,
        setBillingStatusView: PropTypes.func.isRequired,
        setTemperature: PropTypes.func.isRequired,
        setAutologout: PropTypes.func,
    }

    handleChangeLocale = (e, value) => {
        const {setLanguage} = this.props
        setLanguage(value)
    }

    handleChangeAutologout = (e, value) => {
        const {setAutologout} = this.props
        setAutologout(value)
    }

    handleChangeTheme = (e, value) => {
        const {setTheme} = this.props
        setTheme(value)
    }

    handleChangeTemperature = (e, value) => {
        const {setTemperature} = this.props
        setTemperature(value)
    }
    handleChangeEDSView = (e, value) => {
        const {setEDSView} = this.props
        setEDSView(value)
    }

    handleChangeBillingStatusView = (e, value) => {
        this.props.setBillingStatusView(value)
    }

    handleClose = () => {
        const {onClose} = this.props

        onClose()
    }

    renderLocaleOptions() {
        const {locales} = this.props

        const languages = locales
            .map((locale) => locale.substr(0, 2))
            .filter((locale, index, array) => array.indexOf(locale) !== index)

        return locales.map((locale) => {
            const [langCode, countryCode] = locale.split('-')
            const showCountry = languages.includes(langCode)

            let label = getNativeLanguageName(langCode)

            if (showCountry && countryCode) {
                const country = getCountryName(countryCode, langCode)
                label += ' - ' + (country || countryCode)
            }

            return <Option key={locale} label={label} value={locale} />
        })
    }

    renderTemperatureOptions() {
        return getTemperatureUnits().map(({key, name}) => {
            return <Option key={key} label={name()} value={key} />
        })
    }

    render() {
        const {locale, autologout, theme, temperature, edsView, billingStatusView} =
            this.props

        return (
            <div className="editSettings-layout commonSetting">
                <CardClose onClick={this.handleClose} />
                <CardHeader>{__('Edit Settings')}</CardHeader>
                <CardContent>
                    <Select
                        label={__('Language')}
                        onChange={this.handleChangeLocale}
                        defaultValue={locale}
                    >
                        {this.renderLocaleOptions()}
                    </Select>

                    <Select
                        label={__('Theme')}
                        onChange={this.handleChangeTheme}
                        defaultValue={theme || DEFAULT_THEME}
                    >
                        {Object.keys(themes).map((theme) => (
                            <Option
                                label={themes[theme].title}
                                key={theme}
                                value={theme}
                            />
                        ))}
                    </Select>

                    <Select
                        label={__('Auto logout timeout')}
                        onChange={this.handleChangeAutologout}
                        defaultValue={autologout || TIMEOUTS_AUTOLOGOUT[0]}
                    >
                        {TIMEOUTS_AUTOLOGOUT.map((value) =>
                            value > TIMEOUT_DISABLED ? (
                                <Option
                                    key={value}
                                    value={value}
                                    label={humanDuration(value, 'minutes')}
                                />
                            ) : (
                                <Option
                                    key={value}
                                    value={value}
                                    label={__('Disabled')}
                                />
                            )
                        )}
                    </Select>

                    <Select
                        label={__('Temperature units')}
                        onChange={this.handleChangeTemperature}
                        defaultValue={temperature}
                    >
                        {this.renderTemperatureOptions()}
                    </Select>

                    <Select
                        label={__('Events delivery status view')}
                        onChange={this.handleChangeEDSView}
                        defaultValue={edsView || EDS_VIEW_SHORT}
                    >
                        <Option
                            key={EDS_VIEW_SHORT}
                            value={EDS_VIEW_SHORT}
                            label={__('Short')}
                        />
                        <Option
                            key={EDS_VIEW_LONG}
                            value={EDS_VIEW_LONG}
                            label={__('Long')}
                        />
                    </Select>

                    <BillingStatusViewSelectVisibility
                        defaultValue={billingStatusView}
                        onChange={this.handleChangeBillingStatusView}
                    />
                </CardContent>
                <CardActions>
                    <Button primary onClick={this.handleClose}>
                        {__('Close')}
                    </Button>
                </CardActions>
            </div>
        )
    }
}

export default compose(
    connect(
        ({locales, settings}) => {
            const {isLoading, list, error, locale} = locales
            const {autologout, theme, temperature, edsView, menuType, billingStatusView} =
                settings

            return {
                autologout,
                isLoading,
                error,
                locales: list,
                locale,
                theme,
                temperature,
                edsView,
                menuType,
                billingStatusView,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    setLanguage,
                    setAutologout,
                    fetchLocalesList: fetch,
                    setAlarmsEnabled,
                    setAlarmsSoundEnabled,
                    setAlarmsNotificationsEnabled,
                    setTemperature,
                    setTheme,
                    setEDSView,
                    setBillingStatusView,
                },
                dispatch
            )
    ),
    withLoader(({fetchLocalesList}) => fetchLocalesList())
)(CommonSettings)
