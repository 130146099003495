import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import __ from 'utils/i18n'

import {AutolearnDeviceCard} from '../AutolearnDeviceCard'

export default function ConfirmWithoutPinCodeForm({serial, sensor}) {
    return (
        <div className="autolearn-in-process">
            <IconAutolearn />
            <div className="autolearn-description-content-wrapper">
                <div className="autolearn-description-content-title">
                    {__('New device')}
                </div>
                <div className="autolearn-description-content-message ">
                    <div>{__('Device is requesting to be added to your list.')}</div>
                    <div>{__('Do you want to continue?')}</div>
                </div>
                <div className="autolearn-device-card-wrapper">
                    <AutolearnDeviceCard serial={serial} sensor={sensor} />
                </div>
            </div>
        </div>
    )
}

ConfirmWithoutPinCodeForm.propTypes = {
    serial: PropTypes.string.isRequired,
    sensor: PropTypes.string.isRequired,
}
