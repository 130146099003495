export default function resolveTabsPermissions(allowedTabsByPermissions, tabs) {
    const resultTabs = new Map(tabs)
    for (const key in allowedTabsByPermissions) {
        const isAllowed = allowedTabsByPermissions[key]

        if (resultTabs.has(key) && !isAllowed) {
            resultTabs.delete(key)
        }
    }

    return resultTabs
}
