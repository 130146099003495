import {DTP_ZWAVE} from 'constants/protocols'

import useKeysReport from './KeysReport'
import useDskReport from './DskReport'

export default function useZWave(session) {
    const keysReport = useKeysReport(session)
    const dskReport = useDskReport(session)

    return session.protocol === DTP_ZWAVE ? keysReport || dskReport : null
}
