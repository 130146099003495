import React, {useEffect} from 'react'
import {useActions} from 'modules/higherOrder/useActions'

import {__} from 'utils/i18n'

import ModalCardForm from 'ui/ModalCardForm'
import {clear} from 'modules/forms/actions'
import {dlsConnectDsc} from 'modules/forms/handlers'
import ConnectDscFormFields from './ConnectDlsFormFields'
import useForm from 'hooks/useForm'

const rules = {
    host: {
        presence: true,
        host: true,
    },
    port: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 80,
            lessThanOrEqualTo: 65535,
        },
    },
    applicationProtocol: {
        presence: true,
    },
    networkProtocol: {
        presence: true,
    },
}

export default function DlsConnectDsc({panelId, hide}) {
    const clearForm = useActions(clear)
    const {
        handle,
        form: {errors},
    } = useForm(dlsConnectDsc)

    useEffect(() => {
        return clearForm
    }, [])

    const onConnect = ({host, port, networkProtocol, applicationProtocol}) => {
        handle(panelId, host, port, networkProtocol, applicationProtocol)
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={onConnect}
            header={__('Dsc connect')}
            rules={rules}
            hide={hide}
            className="dls-dsc-main-form"
        >
            <ConnectDscFormFields errors={errors} />
        </ModalCardForm>
    )
}
