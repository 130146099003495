import React, {useState} from 'react'

const TableWithBlind = ({children}) => {
    const [blindComponent, tableComponent] = children

    const [row, setRow] = useState(null)

    const onOpenBlind = (row) => setRow(row)
    const onCloseBlind = () => setRow(null)

    return (
        <>
            {blindComponent({row, setRow, onClose: onCloseBlind})}
            {tableComponent({
                onRowClick: onOpenBlind,
                active: {id: row && row?.id},
            })}
        </>
    )
}
export default TableWithBlind
