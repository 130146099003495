import React from 'react'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'
import {selectMfa} from 'modules/system/settings/selectors'
import FormFieldWithDisclaimer from 'ui/FormFieldWithDisclaimer'

const rules = {
    otpTtl: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 60,
            lessThanOrEqualTo: 1200,
        },
    },
    otpLength: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 4,
            lessThanOrEqualTo: 12,
        },
    },
    resendTimeout: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 30,
            lessThanOrEqualTo: 600,
        },
    },
    resendCount: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 10,
        },
    },
    verificationSessionTimes: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 10,
        },
    },
    maxAttempts: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 10,
        },
    },
    blockTimeout: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 60,
            lessThanOrEqualTo: 3600,
        },
    },
    overallBlockTimeout: {
        presence: true,
        numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 3600,
            lessThanOrEqualTo: 2147483647,
        },
    },
}

export default function MfaParameters({hide}) {
    const {
        otpTtl,
        overallBlockTimeout,
        resendTimeout,
        otpLength,
        resendCount,
        verificationSessionTimes,
        maxAttempts,
        blockTimeout,
        ...rest
    } = useSelector(selectMfa)

    const {form, handle, isLoading} = useForm(setSystemSettings.MFAGeneral)

    const onSubmit = (data) => {
        handle({
            ...rest,
            otpTtl: parseInt(data.otpTtl),
            otpLength: parseInt(data.otpLength),
            resendTimeout: parseInt(data.resendTimeout),
            resendCount: parseInt(data.resendCount),
            verificationSessionTimes: parseInt(data.verificationSessionTimes),
            maxAttempts: parseInt(data.maxAttempts),
            blockTimeout: parseInt(data.blockTimeout),
            overallBlockTimeout: parseInt(data.overallBlockTimeout),
        })
    }

    return (
        <ModalCardForm
            wide={4}
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={onSubmit}
            isLoading={isLoading}
            header={__('Parameters')}
            className="mfaGeneral-form formGrid formGrid--modalCardForm formGrid--twoCols"
        >
            <FormFieldWithDisclaimer disclaimer={__('Code TTL')}>
                <Input
                    defaultValue={otpTtl}
                    label={__('OTP TTL (Seconds)')}
                    name="otpTtl"
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer disclaimer={__('Code length')}>
                <Input
                    name="otpLength"
                    defaultValue={otpLength}
                    label={__('OTP LENGTH (Symbols)')}
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__('Time before code resend could be requested')}
            >
                <Input
                    name="resendTimeout"
                    defaultValue={resendTimeout}
                    label={__('Resend Timeout (Seconds)')}
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'Max amount of resends before blocking opportunity to resend code'
                )}
            >
                <Input
                    name="resendCount"
                    defaultValue={resendCount}
                    label={__('Resend Count (Times)')}
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'Amount of failed verifications before admin will be notified about it'
                )}
            >
                <Input
                    name="verificationSessionTimes"
                    defaultValue={verificationSessionTimes}
                    label={__('Verification Sessions Times (Times)')}
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'Amount of failed verifications before operator will be blocked'
                )}
            >
                <Input
                    name="maxAttempts"
                    defaultValue={maxAttempts}
                    label={__('Failed Attempts Count Blocker (Times)')}
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'Timeout between sessions of authentication attempts, when the attempt failed. Also this value used for blocking after max resend attempts reached'
                )}
            >
                <Input
                    defaultValue={blockTimeout}
                    label={__('Blocking timeout (Seconds)')}
                    name="blockTimeout"
                />
            </FormFieldWithDisclaimer>
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'Operator blocking time after all verification attempts are failed'
                )}
            >
                <Input
                    defaultValue={overallBlockTimeout}
                    label={__('Overall Blocking timeout (Seconds)')}
                    name="overallBlockTimeout"
                />
            </FormFieldWithDisclaimer>
        </ModalCardForm>
    )
}
