import React from 'react'

import {DTP_POWERG} from 'constants/protocols'

import PowerGBase from './PowerGBase'
import PropTypes from 'prop-types'

export default function PowerG({
    panelId,
    onClose,
    protocol,
    setProtocol,
    startAuto,
    isSRFEnabled,
    isAutoLearnAvailable,
}) {
    return protocol !== DTP_POWERG ? null : (
        <PowerGBase
            panelId={panelId}
            onClose={onClose}
            setProtocol={setProtocol}
            startAuto={startAuto}
            isSRFEnabled={isSRFEnabled}
            isAutoLearnAvailable={isAutoLearnAvailable}
        />
    )
}

PowerG.propTypes = {
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    protocol: PropTypes.number.isRequired,
    setProtocol: PropTypes.func.isRequired,
    startAuto: PropTypes.func.isRequired,
    isSRFEnabled: PropTypes.bool.isRequired,
    isAutoLearnAvailable: PropTypes.bool.isRequired,
}
