import React, {useEffect, useState, useCallback} from 'react'
import {useLocation} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'

import {ReactComponent as IconMore} from 'ipmp-react-ui/icons/menu/more.svg'

import useWindowDimensions from 'hooks/useWindowDimensions'
import {updateMenuOrder} from 'modules/settings/actions'
import menu, {
    AVERAGE_MENU_HEIGHT_WITH_LABEL,
    AVERAGE_MENU_HEIGHT_WITHOUT_LABEL,
    DEFAULT_MENU_ORDER,
    setMenuOrdered,
} from 'constants/mainMenu'
import __ from 'utils/i18n'

import {NavLinkGroup} from './NavLinkGroup'

function NavItemsResizable({permissions, handleSearchScopeRemove, scopes}) {
    const {height} = useWindowDimensions()
    const location = useLocation()
    const dispatch = useDispatch()
    const {menuOrderType, menuOrder, hideMenuLabels, menuOrderManual} = useSelector(
        (state) => state.settings
    )

    const handleClick = useCallback(
        (key) => () => {
            dispatch(updateMenuOrder(key))
        },
        [dispatch]
    )

    const generateItem = (key) => {
        const {to, toStartWith, icon, name, isPermitted, scope, Component} = menu[key]
        return (
            <Component
                key={key}
                to={to}
                toStartWith={toStartWith}
                Icon={icon}
                name={name()}
                order={DEFAULT_MENU_ORDER[key]}
                isPermitted={isPermitted(permissions)}
                {...(scope ? {scopes: scope(scopes)} : {})}
                {...(scope ? {onRemove: handleSearchScopeRemove} : {})}
                handleClick={handleClick(key)}
            />
        )
    }

    const generateItems = useCallback(() => {
        return Object.keys(menu)
            .map((key) => {
                const {to, toStartWith, icon, name, isPermitted, scope, Component} =
                    menu[key]

                return (
                    <Component
                        key={key}
                        to={to}
                        toStartWith={toStartWith}
                        Icon={icon}
                        name={name()}
                        order={DEFAULT_MENU_ORDER[key]}
                        isPermitted={isPermitted(permissions)}
                        {...(scope ? {scopes: scope(scopes)} : {})}
                        {...(scope ? {onRemove: handleSearchScopeRemove} : {})}
                        handleClick={handleClick(key)}
                    />
                )
            })
            .filter((link) => link.props.isPermitted)
    }, [permissions, scopes, handleSearchScopeRemove, handleClick])

    const [showedItems, setShowedItems] = useState([])
    const [hidedItems, setHidedItems] = useState([])

    useEffect(() => {
        const items = generateItems()
        const averageMenuItemHeight = hideMenuLabels
            ? AVERAGE_MENU_HEIGHT_WITHOUT_LABEL
            : AVERAGE_MENU_HEIGHT_WITH_LABEL
        const maxShownItems = Math.max(1, Math.trunc(height / averageMenuItemHeight) - 1)

        const menuItems = setMenuOrdered(menuOrderType, items, menuOrder, menuOrderManual)

        setHidedItems(menuItems.slice(maxShownItems))

        if (hideMenuLabels) {
            setShowedItems(
                menuItems.slice(0, maxShownItems).map((item) =>
                    React.cloneElement(item, {
                        name: () => '',
                        hidedName: item.name,
                        hideMenuLabels,
                    })
                )
            )
        } else {
            setShowedItems(menuItems.slice(0, maxShownItems))
        }
    }, [
        scopes,
        permissions,
        height,
        menuOrderType,
        menuOrder,
        menuOrderManual,
        hideMenuLabels,
        generateItems,
    ])

    useEffect(() => {
        const currentLink = showedItems
            .concat(hidedItems)
            .find((item) =>
                item.props.toStartWith
                    ? location.pathname.startsWith(item.props.toStartWith)
                    : location.pathname.startsWith(item.props.to)
            )

        if (currentLink) {
            const currentLinkIndex = hidedItems.findIndex(
                (obj) => obj.key === currentLink.key
            )

            if (currentLinkIndex !== -1) {
                const newHiddenItems = [...hidedItems]
                newHiddenItems.splice(currentLinkIndex, 1)

                newHiddenItems.splice(
                    0,
                    0,
                    generateItem(showedItems[showedItems.length - 1].key)
                )

                setHidedItems(newHiddenItems)
                setShowedItems((prevState) => [
                    ...prevState.slice(0, prevState.length - 1),
                    React.cloneElement(currentLink, {
                        name: hideMenuLabels ? () => '' : currentLink.props.name,
                        hideMenuLabels,
                    }),
                ])
            }
        }
    }, [location.pathname, showedItems, hidedItems])

    const MoreMenu = (
        <NavLinkGroup
            Icon={IconMore}
            path="#"
            name={!hideMenuLabels ? __('More') : ''}
            isPermitted
            key="More"
            location={location}
        >
            {hidedItems.map((hidedItem) => hidedItem)}
        </NavLinkGroup>
    )

    return [...showedItems, ...(hidedItems.length > 0 ? [MoreMenu] : [])]
}

export default NavItemsResizable
