import React, {Component} from 'react'
import PropTypes from 'prop-types'
import throttle from 'lodash-es/throttle'

import Checkbox from 'ipmp-react-ui/Checkbox'

import getInteractiveUserStatusTitle, {
    STATUS_SUSPENDED,
    STATUS_VERIFIED,
    STATUS_REGISTERED,
} from 'constants/interactiveUserStatus'
import usePermission from 'hooks/usePermission'
import stopPropagation from 'utils/stopPropagation'
import {useActions} from 'modules/higherOrder/useActions'
import {enable, suspend} from 'modules/interactiveUsers/store/actions'
import {suspend as suspendPermission} from 'permissions/interactiveUsers/actions'

export class InteractiveUserStatusChanger extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
        enable: PropTypes.func.isRequired,
        suspend: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isPermitted: false,
    }

    constructor(props) {
        super(props)

        this.onChange = throttle(this.onChange, 500)
        this.state = {
            status: props.row.status,
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.row.status !== state.status) {
            return {
                status: props.row.status,
            }
        }
        return null
    }

    getLabel() {
        return getInteractiveUserStatusTitle(this.state.status)
    }

    getClassName() {
        switch (this.state.status) {
            case STATUS_REGISTERED:
                return 'warn'
            case STATUS_SUSPENDED:
                return 'danger'
            case STATUS_VERIFIED:
                return 'primary'
        }
    }

    isIndeterminated() {
        return this.state.status === STATUS_REGISTERED
    }

    isChecked() {
        return this.state.status === STATUS_VERIFIED
    }

    onChange = () => {
        const {
            enable,
            suspend,
            row: {id},
        } = this.props

        switch (this.state.status) {
            case STATUS_SUSPENDED:
                return enable && enable(id)
            case STATUS_VERIFIED:
                return suspend && suspend(id)
        }
    }

    isDisabled() {
        const {isPermitted, disabled} = this.props
        return disabled || !isPermitted || this.state.status === STATUS_REGISTERED
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={stopPropagation}>
                {this.isChecked() && (
                    <Checkbox
                        checked={true}
                        disabled={false}
                        indeterminate={this.isIndeterminated()}
                        label={this.getLabel()}
                        onChange={this.onChange}
                    />
                )}
                {!this.isChecked() && (
                    <Checkbox
                        checked={false}
                        disabled={this.isDisabled()}
                        indeterminate={this.isIndeterminated()}
                        label={this.getLabel()}
                        onChange={this.onChange}
                    />
                )}
            </div>
        )
    }
}

export default function InteractiveUserStatusChangerMain({row}) {
    const {isPermitted} = usePermission(suspendPermission)
    const {enableAction, suspendAction} = useActions({
        enableAction: enable,
        suspendAction: suspend,
    })

    return (
        <InteractiveUserStatusChanger
            row={row}
            enable={enableAction}
            suspend={suspendAction}
            isPermitted={isPermitted}
        />
    )
}
