import {all} from 'redux-saga/effects'

import list from 'modules/events/list/saga'
import one from 'modules/events/one/saga'
import alarms from 'modules/events/alarms/saga'
import bySerialList from 'modules/events/bySerialList/saga'

export default function* () {
    yield all([list(), alarms(), bySerialList(), one()])
}
