import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

const rules = {
    systemIdRegex: {
        presence: {
            message: () => __("You should define System ID's Mask"),
        },
    },
}

export default function CommonConnectedSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.CommonConnected)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Edit Common Connected Settings')}
        >
            <Input
                label={__("System ID's Mask")}
                name="systemIdRegex"
                defaultValue={data.systemIdRegex}
            />

            <Checkbox
                label={__('Remove Panel by Resetting the Receiver IP')}
                name="isPanelRemoveForever"
                defaultChecked={data.isPanelRemoveForever}
            />
        </ModalCardForm>
    )
}

CommonConnectedSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        systemIdRegex: PropTypes.string,
        isPanelRemoveForever: PropTypes.bool,
    }),
}
