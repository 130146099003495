import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import {compose} from 'redux'
import getHocName from 'utils/getHocName'
import {Option} from 'ipmp-react-ui/Select'

export default function withOptions(map) {
    return compose(
        connect((state, props) => ({
            options: map(state, props),
        })),
        (WrappedComponent) => {
            return class extends PureComponent {
                static displayName = getHocName('WithOptionsMapper', WrappedComponent)

                render() {
                    const {options, ...props} = this.props
                    return (
                        <WrappedComponent {...props}>
                            {options.map((option, key) => (
                                <Option key={option.value || key} {...option} />
                            ))}
                        </WrappedComponent>
                    )
                }
            }
        }
    )
}
