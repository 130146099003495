import axios from 'axios'
import queryString from 'query-string'

const instance = axios.create({
    baseURL: '/api/',
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
    },
    timeout: 30000,
    validateStatus: function (status) {
        return status >= 200 && status < 400 // for 304 Not Modified status
    },
})

export function get(url, data, options = {}) {
    return instance.get(url + (data ? '?' + queryString.stringify(data) : ''), options)
}

export function post(url, data, options = {}) {
    return instance.post(url, data, options)
}

export function put(url, data, options = {}) {
    return instance.put(url, data, options)
}

export function del(url, data, options = {}) {
    return instance.delete(url, {params: data}, options)
}

export default instance
