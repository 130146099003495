import React from 'react'
import PropTypes from 'prop-types'

import {useActions} from 'modules/higherOrder/useActions'
import {off, on} from 'modules/panels/had/actions'
import usePermission from 'hooks/usePermission'
import {genericDisable, genericEnable} from 'permissions/panel/output/actions'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'
import __ from 'utils/i18n'

const SetHadPGMStatelessButton = ({id, deviceType, disabled}) => {
    const permissions = usePermission({
        genericEnable: genericEnable,
        genericDisable: genericDisable,
    })

    const {onClickOn, onClickOff} = useActions({
        onClickOn: on,
        onClickOff: off,
    })

    if (!permissions.genericEnable && !permissions.genericDisable) {
        return null
    }

    return (
        <DropDownButton flat label={__('Submit status')} disabled={disabled}>
            <Menu>
                {permissions.genericEnable && (
                    <MenuItem onClick={() => onClickOn({id, deviceType})}>
                        {__('Turn On')}
                    </MenuItem>
                )}
                {permissions.genericDisable && (
                    <MenuItem onClick={() => onClickOff({id, deviceType})}>
                        {__('Turn Off')}
                    </MenuItem>
                )}
            </Menu>
        </DropDownButton>
    )
}

SetHadPGMStatelessButton.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
}

export default SetHadPGMStatelessButton
