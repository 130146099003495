import __ from 'utils/i18n'

import getEnrollmentDevice from './getEnrollmentDevice'

export default function getRules(showUser, showPartitions, devices, isZoneApplicable) {
    const rules = {
        deviceId: {
            presence: {
                message: __('You should define enroll code of device'),
            },

            deviceId: {
                invalid: __('This is not valid enrollment ID'),
                notExists: __('This enrollment ID does not exist'),
                existence: (code) =>
                    code && getEnrollmentDevice(code.slice(0, 3), devices) !== false,
            },
        },

        zoneId: isZoneApplicable && {
            presence: {
                message: __('You should define zone to enroll device'),
            },
            numericality: {
                strict: true,
                greaterThan: 0,
                message: __('Zone number must be a integer.'),
            },
        },
    }

    const neoRules = !showPartitions
        ? {}
        : {
              partitions: {
                  presence: {
                      message: __('You should define partitions to enroll device'),
                  },
              },
          }

    const user = {
        userId: {
            presence: {
                message: __('You should define user number to enroll device'),
            },
            numericality: {
                strict: true,
                greaterThan: 0,
                message: __('User number must be a integer.'),
            },
        },
    }
    const userRules = !showUser ? {} : user

    return {
        ...rules,
        ...neoRules,
        ...userRules,
    }
}
