import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'

import {selectActiveSession} from 'modules/devices/autolearn/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {stopSession} from 'modules/devices/autolearn/actions'
import {DTP_UNKNOWN} from 'constants/protocols'
import __ from 'utils/i18n'
import {DismissConfirmation} from 'components/Modal'

import useScreens from './Screens'

export default function Autolearn({
    serial,
    onClose,
    onDismiss,
    isForSecurityDevices = false,
    automationProtocols,
}) {
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
    const activeSession = useSelector((state) => selectActiveSession(state, serial)) || {}
    if (!isForSecurityDevices && DTP_UNKNOWN === activeSession?.protocol) {
        activeSession.protocol = undefined
    }
    const session = useMemo(
        () => ({
            ...activeSession,
            ...(isForSecurityDevices ? {protocol: DTP_UNKNOWN} : {}),
            serial,
        }),
        [serial, isForSecurityDevices]
    )
    const [stop] = useActions([() => stopSession(session)])

    const cancelDismiss = () => setShowDismissConfirmation(false)

    const agreeDismiss = () => {
        onClose()
    }

    const dismiss = (e) => {
        e && e.preventDefault()

        if (activeSession?.token) {
            setShowDismissConfirmation(true)
        } else {
            onClose()
        }
    }

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            e.preventDefault()
            e.returnValue = ''
            session.token && stop()
        }

        window.addEventListener('beforeunload', handleBeforeUnload)

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
            session.token && stop()
        }
    }, [session])

    const {Content, Actions} = useScreens(session, onDismiss, automationProtocols)

    return (
        <Form className="add-device-tab add-device-autolearn-tab">
            <CardClose onClick={dismiss} />
            <CardHeader>
                {isForSecurityDevices
                    ? __('Autolearn security devices')
                    : __('Autolearn automation devices')}
            </CardHeader>
            <CardContent>
                <Content />
            </CardContent>
            {showDismissConfirmation && (
                <DismissConfirmation
                    dismiss={dismiss}
                    cancelDismiss={cancelDismiss}
                    agreeDismiss={agreeDismiss}
                    message={__(
                        'Current autolearn process will be stopped. Do you want to proceed?'
                    )}
                />
            )}
            <CardActions>
                <Actions />
            </CardActions>
        </Form>
    )
}

Autolearn.propTypes = {
    serial: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
    automationProtocols: PropTypes.array.isRequired,
    isForSecurityDevices: PropTypes.bool,
}
