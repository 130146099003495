export function resolve(dependency, values) {
    return dependency.some(({key, val}) => values[key] === val)
}

export function lookup(dependency) {
    const acc = {}

    dependency.forEach(({key}) => (acc[key] = true))

    return acc
}
