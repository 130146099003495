import {prepareListReducer} from './createListReducer'

export const generalDefaultState = {
    error: null,
    page: [],
    query: null,
    total: null,
    isLoading: true,
    oldPage: null,
    oldTotal: null,
}

const createReducers = (defaultState) => ({
    reset() {
        return defaultState
    },

    setLoading(state, {payload}) {
        return {
            ...state,
            isLoading: payload === undefined ? true : !!payload,
        }
    },

    remove(state, {payload}) {
        return {
            ...state,
            oldPage: state.page,
            oldTotal: state.total,
            total: state.total && state.total - payload.length,
            page: state.page.filter((id) => !payload.includes(id)),
        }
    },

    setQuery(state, {payload}) {
        return {
            ...state,
            query: payload,
        }
    },

    revertRemove(state) {
        if (!state.oldPage) {
            // page already changed
            return state
        }

        return {
            ...state,
            total: state.oldTotal,
            oldPage: null,
            oldTotal: null,
            page: state.oldPage,
        }
    },

    receive(state, {payload, error}) {
        if (error) {
            return {
                ...state,
                error: payload,
                oldPage: null,
                isLoading: false,
            }
        }

        return {
            ...state,
            ...payload,
            isLoading: false,
            oldPage: null,
            error: null,
        }
    },

    fetch(state) {
        return {
            ...state,
            isLoading: true,
        }
    },
})

export default function createListWithoutPaginationReducer(
    actions,
    actionHandlers = {},
    defaultState = {}
) {
    defaultState = {
        ...generalDefaultState,
        ...defaultState,
    }

    const reducers = createReducers(defaultState)
    return prepareListReducer({reducers, actions, actionHandlers, defaultState})
}
