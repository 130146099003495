import React from 'react'

import {REFRESH_STATE} from 'constants/features'
import {useActions} from 'modules/higherOrder/useActions'
import {refreshState} from 'modules/runners/store/actions'
import {withSelectionFeature} from 'containers/withFeature'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'
import {RefreshStateBase} from 'pages/Panels/GridBar/DropDowns/Items/RefreshStateItem'

const RefreshStateSelectionFeature = withSelectionFeature(
    REFRESH_STATE,
    selectSelectedRunnersFeature
)(RefreshStateBase)

export default function RefreshStateItem() {
    const onClick = useActions(refreshState)

    return <RefreshStateSelectionFeature onClick={onClick} />
}
