import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import __ from 'utils/i18n'
import {remove} from 'modules/stateTypes/list/actions'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'

const ButtonConfirmation = withConfirmation()(Button)

export default function RemoveStateType({row: {id, name}}) {
    const {onRemove} = useActions(
        {
            onRemove: () => remove([id]),
        },
        [id]
    )

    return (
        <ButtonConfirmation
            small
            flat
            Icon={IconRemove}
            onClick={onRemove}
            title={__('Remove State Type')}
            message={__(
                'If you delete this state type, then the event rule in which it is used will also be deleted, if it exists. Do you really want to remove %s state type?',
                name
            )}
        />
    )
}
