import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/groups/bar'
import GroupsPager from './GridBar/GroupsPager'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import CreateGroupButton from './GridBar/CreateGroupButton'
import RemoveGroupsButton from './GridBar/RemoveGroupsButton'
import GroupsSelectionDropDown from './GridBar/GroupsSelectionDropDown'

function GroupsBarBase() {
    return (
        <Bar>
            <GroupsSelectionDropDown />
            <RemoveGroupsButton />

            <BarSpace />

            <GroupsPager />
            <CreateGroupButton />
        </Bar>
    )
}

const GroupsBarVisibility = withVisibility()(GroupsBarBase)

export default function GroupsGridBar() {
    const {isPermitted} = usePermission(bar)

    return <GroupsBarVisibility isVisible={isPermitted} />
}
