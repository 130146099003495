import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import startsWith from 'lodash-es/startsWith'

import {showSoundPermissionError} from 'modules/settings/actions'
import {eventSeverityTitle} from 'constants/eventSeverity'
import {NOT_ALLOWED_ERROR} from 'constants/settings'
import player from 'audio/player'
import SoundPlaySelect from 'components/Alarms/SoundPlaySelect'
import SoundPlayOption from 'components/Alarms/SoundPlayOption'
import {useActions} from 'modules/higherOrder/useActions'

const AlarmsSounds = ({alarmTypes, sounds, setAlarmSound, alarmTypeToSound}) => {
    const firstHalfAlarmTypes = () => {
        return alarmTypes.slice(0, Math.round(alarmTypes.length / 2))
    }

    const secondHalfAlarmTypes = () => {
        return alarmTypes.slice(Math.round(alarmTypes.length / 2))
    }

    const handleSoundChange = ({alarmType, soundKey}) =>
        setAlarmSound({alarmType, soundKey})

    const {showSoundPermissionError: showSoundPermissionErrorAction} = useActions({
        showSoundPermissionError,
    })

    const handleOnPlaySound = async (soundKey) => {
        try {
            await player.play(soundKey)
        } catch (e) {
            if (startsWith(e.message, NOT_ALLOWED_ERROR)) {
                showSoundPermissionErrorAction()
            }
        }
    }

    const isPlaySound = (soundKey) => {
        const sound = sounds.get(soundKey)

        return sound ? sound.isPlay : false
    }

    const renderSoundsOptions = (alarmType) => {
        return Array.from(sounds.keys()).map((soundKey) => {
            const sound = sounds.get(soundKey)
            return (
                <SoundPlayOption
                    playSound={() => handleOnPlaySound(soundKey)}
                    label={sound.title}
                    key={`${soundKey}_${alarmType}`}
                    value={soundKey}
                    isPlaySound={isPlaySound(soundKey)}
                />
            )
        })
    }

    const renderSoundsSelections = (alarmTypes) => {
        return alarmTypes.map((alarmType) => {
            const sound = sounds.get(alarmTypeToSound[alarmType])

            return (
                <SoundPlaySelect
                    key={alarmType}
                    defaultLabel={sound?.title}
                    onChange={(event, value) =>
                        handleSoundChange({alarmType, soundKey: value})
                    }
                    label={get(eventSeverityTitle, alarmType, () => alarmType)()}
                    defaultValue={alarmTypeToSound[alarmType]}
                    playSound={() => handleOnPlaySound(alarmTypeToSound[alarmType])}
                    isPlaySound={isPlaySound(alarmTypeToSound[alarmType])}
                >
                    {renderSoundsOptions(alarmType)}
                </SoundPlaySelect>
            )
        })
    }

    return (
        <div className="cols">
            <div className="col">{renderSoundsSelections(firstHalfAlarmTypes())}</div>
            <div className="col">{renderSoundsSelections(secondHalfAlarmTypes())}</div>
        </div>
    )
}

AlarmsSounds.propTypes = {
    sounds: PropTypes.instanceOf(Map).isRequired,
    alarmTypes: PropTypes.array,
    alarmTypeToSound: PropTypes.object,
    setAlarmSound: PropTypes.func,
}

export default AlarmsSounds
