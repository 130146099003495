import React from 'react'
import {useSelector} from 'react-redux'
import has from 'lodash-es/has'
import get from 'lodash-es/get'

import Tabs from 'ipmp-react-ui/Tabs'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import FirmwareTab from 'pages/Panel/Devices/Sidebar/Tabs/FirmwareTab'
import ConnectionStatusTab from './Tabs/ConnectionStatusTab'
import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import PatchTagTab from 'pages/Panel/Devices/Sidebar/Tabs/PatchTagTab'
import ConfigurationTab from './Tabs/ConfigurationTab'

import usePermission from 'hooks/usePermission'
import {useSectionName} from 'hooks/panel/configuration'
import {VENDOR_DUAL_PATH, VENDOR_QOLSYS} from 'constants/panelVendorType'
import diagnostic from 'permissions/panel/devices/diagnostic'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import configuration from 'permissions/panel/configuration/page'
import {listUpgrade} from 'permissions/panel/firmware/actions'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'

import {__} from 'utils/i18n'
import GeneralInfoTab from './PanelTabs/GeneralInfoTab'
import {downloadIQPanelLog, triggerAction} from 'permissions/panel/actions'
import {selectLogTypes, selectPanelActions} from 'modules/features/store/selectors'
import {SYSTEM_OUTSIDE_PM_LOG_TYPE} from 'constants/logTypes'

export default function PanelSidebar({panel}) {
    const {id: panelId} = panel

    const {
        isConfigurationAllowed,
        isDiagnosticAllowed,
        isFirmwareAllowed,
        isDownloadLogAllowed,
        isTriggerActionAllowed,
    } = usePermission({
        isDiagnosticAllowed: diagnostic,
        isConfigurationAllowed: configuration,
        isFirmwareAllowed: listUpgrade,
        isDownloadLogAllowed: downloadIQPanelLog,
        isTriggerActionAllowed: triggerAction,
    })
    const {firmware} = useSelector((state) =>
        selectPanelFirmwareByDeviceType(state, {
            panelId: panel.id,
            device: {deviceType: DEVICE_TYPE_CONTROL_PANEL},
        })
    )
    const allowedPanelActions = useSelector((state) =>
        selectPanelActions(state, {panelId})
    )

    const allowedLogTypes = useSelector((state) => selectLogTypes(state, {panelId}))

    const hasGeneral = () => {
        return (
            (isDownloadLogAllowed &&
                allowedLogTypes.includes(SYSTEM_OUTSIDE_PM_LOG_TYPE)) ||
            (isTriggerActionAllowed && allowedPanelActions.length > 0)
        )
    }

    const hasConfiguration = () => {
        return isConfigurationAllowed && panel.vendor !== VENDOR_DUAL_PATH
    }

    const hasDiagnostic = () => {
        return isDiagnosticAllowed && panel && panel.traits && panel.traits.diagnostic
    }

    const hasPatchTag = () => isFirmwareAllowed && panel.vendor === VENDOR_QOLSYS

    const hasFirmware = () => {
        return (
            isFirmwareAllowed && firmware && has(firmware, 'packages') && !hasPatchTag()
        )
    }

    const hasConnectionStatus = () => {
        return has(panel, 'connectionStatus.status')
    }

    const configurationSectionName = useSectionName(panel)

    return (
        <Tabs Layout={SidebarTabsLayout}>
            {hasGeneral() && <GeneralInfoTab name={__('General')} panelId={panelId} />}

            {hasConnectionStatus() && (
                <ConnectionStatusTab
                    panelId={panelId}
                    name={__('Connection Status')}
                    connectionStatus={panel.connectionStatus}
                />
            )}

            {hasDiagnostic() && (
                <DiagnosticInfo name={__('Diagnostic')} {...panel.traits.diagnostic} />
            )}

            {hasConfiguration() && configurationSectionName && (
                <ConfigurationTab
                    name={__('Configuration')}
                    sectionName={configurationSectionName}
                    panelId={panel.id}
                />
            )}

            {hasFirmware() && (
                <FirmwareTab name={__('Firmware')} panelId={panel.id} {...firmware} />
            )}

            {hasPatchTag() && (
                <PatchTagTab
                    name={__('Firmware')}
                    panelId={panel.id}
                    info={panel.extendedInfo}
                    version={get(panel, 'traits.firmware.currentVersion', null)}
                />
            )}
        </Tabs>
    )
}
