import {all, put, call, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/pgm'

import {POLL_PGMS} from 'configs/pollers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import watch from 'modules/higherOrder/watch'

export default function* () {
    yield all([
        yield takeEvery(actions.fetch, watch(fetch)),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_PGMS, fetch, true),
    ])
}

export function* fetch({payload: panelId}) {
    const pgms = yield call(api.getPGMListLEGACY, panelId)

    yield put(actions.receive(pgms, panelId))
}

fetch.onError = function* (error) {
    yield put(actions.receive(error))
}
