import {put, select} from 'redux-saga/effects'

import {EVENT_NEW_SECURITY_DEVICE_ADDED} from 'constants/autolearn'
import {deviceSubtype} from 'constants/deviceSubtype'
import {selectPanelIdBySerial} from 'modules/panels/store/selectors'
import {getActiveBitsFromBitmask} from 'utils/bitmask'
import __ from 'utils/i18n'

import {addDeviceComplete} from '../actions'

export default function* (session, message) {
    if (EVENT_NEW_SECURITY_DEVICE_ADDED !== message.event) {
        return null
    }

    const panelId = yield select((state) => selectPanelIdBySerial(state, session))
    const {devices} = yield select(({features}) => features.store.byIds[panelId].devices)

    const {
        ZONE_ID: id,
        2: name,
        sensor_status: status,
        38: enrollmentId,
    } = message.newSecurityDeviceAdded

    const feature = Object.values(devices).find(
        ({enrollmentPrefix}) => enrollmentPrefix === enrollmentId?.slice(0, 3)
    )

    const [statusActiveBit] = getActiveBitsFromBitmask(status, 21) || [0]
    const getStatusName = statusMap[statusActiveBit in statusMap ? statusActiveBit : 0]

    yield put(
        addDeviceComplete(session, {
            id: id,
            type: deviceSubtype(feature?.name),
            name,
            status: getStatusName(),
            state: '-',
        })
    )
}

const statusMap = {
    0: () => __('Unknown'),
    1: () => __('In Alarm'),
    2: () => __('Low battery'),
    3: () => __('AC detached'),
    4: () => __('Supervisory trouble'),
    5: () => __('Tamper'),
    6: () => __('Bypass'),
    7: () => __('Trip'),
    8: () => __('Not network'),
    9: () => __('Low voltage'),
    10: () => __('Auxiliary'),
    11: () => __('Output1 fault'),
    12: () => __('Bell circuit'),
    13: () => __('Bus fault'),
    14: () => __('Battery absent'),
    15: () => __('CO trouble'),
    16: () => __('Day zone trouble'),
    17: () => __('RF jam'),
    18: () => __('Fire trouble'),
    19: () => __('Probe trouble'),
    20: () => __('Masking trouble'),
    21: () => __('Panel overheat shutdown restore'),
}
