import Input from 'ipmp-react-ui/Input'
import PropTypes from 'prop-types'
import React from 'react'
import {__} from 'utils/i18n'

export const SimNumber = ({simNumber, disabled}) => {
    return (
        <Input
            disabled={disabled}
            label={__('Sim Number')}
            name="simNumber"
            defaultValue={simNumber}
            dataTestId="sim-number"
        />
    )
}

SimNumber.propTypes = {
    simNumber: PropTypes.string,
    disabled: PropTypes.bool,
}
