import React, {useEffect, useState} from 'react'
import {__} from 'utils/i18n'
import {useSelector} from 'react-redux'
import Select, {Option} from 'ipmp-react-ui/Select'

import panelVendorType, {
    VENDOR_NEO,
    VENDOR_POWER_MASTER,
    VENDOR_DUAL_PATH,
    VENDOR_QOLSYS,
    VENDOR_SMART_COMMUNICATOR,
} from 'constants/panelVendorType'

const panelVendors = {
    panelsNeo: VENDOR_NEO,
    panels: VENDOR_POWER_MASTER,
    panelsDualPath: VENDOR_DUAL_PATH,
    panelsQolsys: VENDOR_QOLSYS,
    panelsSC: VENDOR_SMART_COMMUNICATOR,
}

const PanelVendorsListSelect = ({changePanelParent}) => {
    const {license} = useSelector(
        ({
            system: {
                settings: {license},
            },
        }) => ({
            license,
        })
    )

    const panels = Object.keys(panelVendors).filter((key) => license?.limits[key] > 0)

    const [vendor, setVendor] = useState(panelVendors[panels[0]])

    const changePanelType = (e, value) => {
        setVendor(() => value)
        changePanelParent(e, value)
    }

    useEffect(() => changePanelType({}, vendor), [vendor])

    return (
        <Select
            label={__('Panel type')}
            value={vendor}
            name="vendor"
            onChange={changePanelType}
            dataTestId="panel-type-select"
            isLoading={!license}
        >
            {panels.map((panelVendor) => {
                return (
                    <Option
                        key={panelVendors[panelVendor]}
                        value={panelVendors[panelVendor]}
                        label={panelVendorType(panelVendors[panelVendor])}
                    />
                )
            })}
        </Select>
    )
}

export default PanelVendorsListSelect
