import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import DisableLogButton from './PlinkDebugButtons/DisableLogButton'
import DisableSSHButton from './PlinkDebugButtons/DisableSSHButton'
import EnableLogButton from './PlinkDebugButtons/EnableLogButton'
import EnableSSHButton from './PlinkDebugButtons/EnableSSHButton'
import SendLogButton from './PlinkDebugButtons/SendLogButton'
import RebootButton from './PlinkDebugButtons/RebootButton'

import ActionCards, {ActionCard, ActionCardsGroup} from 'ui/ActionCards'

export default class PlinkDebug extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
    }

    render() {
        const {panelId} = this.props

        return (
            <ActionCardsGroup label={__('Debug Commands')}>
                <ActionCards label={__('Log options')}>
                    <ActionCard label={__('Remote log service')}>
                        <DisableLogButton panelId={panelId}>
                            {__('Disable')}
                        </DisableLogButton>
                        <EnableLogButton panelId={panelId}>
                            {__('Enable')}
                        </EnableLogButton>
                    </ActionCard>

                    <ActionCard label={__('Log dump')}>
                        <SendLogButton panelId={panelId}>{__('Send')}</SendLogButton>
                    </ActionCard>
                </ActionCards>

                <ActionCards label={__('Connection options')}>
                    <ActionCard label={__('SSH connection')}>
                        <DisableSSHButton panelId={panelId}>
                            {__('Disable')}
                        </DisableSSHButton>
                        <EnableSSHButton panelId={panelId}>
                            {__('Enable')}
                        </EnableSSHButton>
                    </ActionCard>
                </ActionCards>

                <ActionCards label={__('General management')}>
                    <ActionCard label={__('Sending reboot command')}>
                        <RebootButton panelId={panelId}>{__('Send')}</RebootButton>
                    </ActionCard>
                </ActionCards>
            </ActionCardsGroup>
        )
    }
}
