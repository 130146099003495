import React from 'react'
import get from 'lodash-es/get'
import {compose} from 'redux'

import {STATUS_SUSPENDED} from 'constants/interactiveUserStatus'
import {suspend} from 'permissions/interactiveUsers/actions'
import {withPermissionVisibility} from 'containers/withPermission'
import {withSelectionHandler} from 'containers/withSelection'
import * as actions from 'modules/interactiveUsers/store/actions'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'

const ConnectedButton = compose(
    withPermissionVisibility(suspend),
    withSelectionHandler(
        (id, {interactiveUsers}) =>
            get(interactiveUsers, ['store', 'byIds', id, 'status']) === STATUS_SUSPENDED
    )
)(Button)

export default function InteractiveEnableButton() {
    const enable = useActions(actions.enable)

    return (
        <ConnectedButton
            label={__('Enable')}
            onClick={(selection) => enable(selection)}
        />
    )
}
