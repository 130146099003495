import {warn} from 'utils/log'
import __ from 'utils/i18n'
import {
    HAD_DOORLOCK_JAMMED,
    HAD_DOORLOCK_LOCKED,
    HAD_DOORLOCK_UNLOCKED,
} from 'constants/had/hadState'

const doorlockTypeStateTitleMap = new Map([
    [HAD_DOORLOCK_LOCKED, () => __('Locked')],
    [HAD_DOORLOCK_UNLOCKED, () => __('Unlocked')],
    [HAD_DOORLOCK_JAMMED, () => __('Jammed')],
])

const doorlockTypeStateTitle = (state) => {
    let result = doorlockTypeStateTitleMap.get(state)

    if (!result) {
        warn(`No home automation device (doorlock) state title for ${state}`)
        result = () => __('Undefined')
    }

    return result()
}

export default doorlockTypeStateTitle
