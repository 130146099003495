import usePermission from '../usePermission'
import {markAsViewed, sendEmail} from 'permissions/remoteInspections/actions'
import useRouterPanelId from '../useRouterPanelId'
import {selectPanelRemoteInspection} from 'modules/panels/remoteInspections/selectors'
import {bindActionCreators} from 'redux'
import {fetch, reset, startPoll, stopPoll} from 'modules/panels/remoteInspections/actions'
import moment from 'moment'
import {YEAR} from 'ipmp-react-ui/Calendar'
import {initiateNow} from 'modules/remoteInspections/store/actions'
import {showScheduleRri} from 'modules/modals/actions'
import {
    markAsViewed as markAsViewedAction,
    sendEmail as sendEmailAction,
} from 'modules/remoteInspections/results/actions'
import {useDispatch, useSelector} from 'react-redux'

export default function usePanelRemoteInspection() {
    const dispatch = useDispatch()
    const panelId = useRouterPanelId()
    const permissions = usePermission({
        markAsViewed,
        sendEmail,
    })

    const panelRemoteInspection = useSelector((state) =>
        selectPanelRemoteInspection(state, {panelId})
    )

    return {
        permissions,
        ...panelRemoteInspection,
        ...bindActionCreators(
            {
                reset: () => reset({date: moment(), view: YEAR}, parseInt(panelId)),
                fetch: (...args) => fetch(...args, parseInt(panelId)),
                startPoll: () =>
                    startPoll({date: moment(), view: YEAR}, parseInt(panelId)),
                stopPoll: () => stopPoll(),
                initiateNow: () => initiateNow([panelId]),
                schedule: () => showScheduleRri([panelId]),
                markAsViewed: markAsViewedAction,
                sendEmail: sendEmailAction,
            },
            dispatch
        ),
    }
}
