import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import useGroups from 'hooks/pages/useGroups'
import usePermission from 'hooks/usePermission'
import {create} from 'permissions/groups/actions'
import {withVisibility} from 'containers/withVisibility'

const ButtonVisibility = withVisibility()(Button)

export default function CreateGroupButton() {
    const {showAddGroup} = useGroups()
    const {isPermitted} = usePermission(create)

    return (
        <ButtonVisibility
            Icon={PlusIcon}
            isVisible={isPermitted}
            onClick={showAddGroup}
            children={__('Add Group')}
        />
    )
}
