import React from 'react'
import classNames from 'classnames'

import isRtl from 'ipmp-react-ui/_utils/isRtl'
import {ReactComponent as AppIcon} from 'ipmp-react-ui/icons/app.svg'
import {ALIGN_BOTTOM, ALIGN_RIGHT, ALIGN_LEFT} from 'ipmp-react-ui/DropDown'

import apps, {configurator, user} from 'permissions/panels/apps'
import usePermission from 'hooks/usePermission'
import {withFeatureRejection} from 'containers/withFeature'
import {selectApplicationFeature} from 'modules/panels/store/selectors'
import ApplicationInteractiveDropdown from 'components/DropDown/ApplicationInteractiveDropdown'

function Cell({row, isPermittedUser, isPermittedConfigurator}) {
    return (
        <span className="applications">
            <AppIcon className="applications-icon" />

            {isPermittedUser && (
                <span
                    className={classNames('application', {
                        'application--enabled': row.userApp,
                    })}
                >
                    {' '}
                    C{' '}
                </span>
            )}
            {isPermittedConfigurator && (
                <span
                    className={classNames('application', {
                        'application--enabled': row.configuratorApp,
                    })}
                >
                    {' '}
                    I{' '}
                </span>
            )}
        </span>
    )
}

function ApplicationCellBase({
    row,
    isPermittedApps,
    isPermittedUser,
    isPermittedConfigurator,
}) {
    if (!isPermittedApps) {
        return (
            <Cell
                row={row}
                isPermittedUser={isPermittedUser}
                isPermittedConfigurator={isPermittedConfigurator}
            />
        )
    }

    return (
        <ApplicationInteractiveDropdown
            panel={row}
            trigger={
                <Cell
                    row={row}
                    isPermittedUser={isPermittedUser}
                    isPermittedConfigurator={isPermittedConfigurator}
                />
            }
            align={ALIGN_BOTTOM | (isRtl() ? ALIGN_LEFT : ALIGN_RIGHT)}
        />
    )
}

const ConnectedApplicationCell = withFeatureRejection(selectApplicationFeature)(
    ApplicationCellBase
)

export default function ApplicationCell({row}) {
    const {isPermittedUser, isPermittedApps, isPermittedConfigurator} = usePermission({
        isPermittedUser: user,
        isPermittedApps: apps,
        isPermittedConfigurator: configurator,
    })

    return (
        <ConnectedApplicationCell
            row={row}
            panelId={row.id}
            isPermittedUser={isPermittedUser}
            isPermittedApps={isPermittedApps}
            isPermittedConfigurator={isPermittedConfigurator}
        />
    )
}
