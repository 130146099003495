import React from 'react'
import {useSelector} from 'react-redux'

import Account from './Field/Account'
import Group from './Field/Group'
import Serial from './Field/Serial'
import {SimNumber} from './Field/SimNumber'

export function Fieldset({isNew, data}) {
    const {setSimNumber} = useSelector(
        ({features}) => features.store.byIds[data?.id] || {}
    )

    return (
        <div className="form-field">
            <Serial isNew={isNew} serial={data.serial} />
            <Account isNew={isNew} account={data.account} />
            <Group groupName={data.group} groupId={data.groupId} />
            {(isNew || (!isNew && setSimNumber?.isEnabled)) && (
                <SimNumber simNumber={data.simNumber} />
            )}
        </div>
    )
}

export const rules = {
    groupId: {
        presence: true,
    },
    simNumber: {
        phone: true,
    },
}
