import React from 'react'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import Duration from 'ui/Duration'

import {stop} from 'permissions/processes/actions'
import selectionPermission from 'permissions/processes/selection'

import withLoader from 'containers/withLoader'
import processType from 'constants/processType'

import usePermission from 'hooks/usePermission'
import useSelection from 'hooks/useSelection'
import useProcesses from 'hooks/pages/useProcesses'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import ProcessStatus from 'components/Processes/ProcessStatus'
import PanelCell from 'components/TableCells/PanelCell'
import ProcessStopButton from 'components/Processes/ProcessStopButton.js'
import User from 'components/Entities/User'

import {__} from 'utils/i18n'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

const empty = <span className="empty">—</span>

export const allColumns = [
    {
        maxWidth: 170,
        name: () => __('Timing'),
        render: ({started, finished}) => <Duration start={started} end={finished} />,
    },
    {
        width: 50,
        align: 'center',
        fixed: true,
        name: () => __('User'),
        render: ({user, email, userId}) => (
            <User name={user} email={email} id={userId} withTooltip />
        ),
    },
    {
        maxWidth: 150,
        name: () => __('Panel'),
        render: ({panelId, serial}) =>
            panelId ? <PanelCell id={panelId} serial={serial} /> : empty,
    },
    {
        width: 150,
        name: () => __('Process'),
        render: ({type, description}) => (
            <Definition title={processType(type)} detail={description} multiLine />
        ),
    },
    {
        width: 100,
        maxWidth: 250,
        name: () => __('Status'),
        render: (props) => {
            return <ProcessStatus row={props} />
        },
    },
    {
        tooltip: false,
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, id}, {stop}) => (
            <ProcessStopButton onClick={() => stop(id)} disabled={!isStoppable} />
        ),
    },
]

const ConnectedTable = withLoader(
    ({init, startPoll}) => {
        init()
        startPoll()
    },
    ({stopPoll}) => stopPoll()
)(Table)

export default function ProcessesTable() {
    const {isPermitted} = usePermission(selectionPermission)
    const {selection, select, deselect} = useSelection()
    const {columns} = useTableColumnsPermission(allColumns)
    const {rows, isLoading, init, startPoll, stopPoll, stop} = useProcesses()

    return (
        <ConnectedTable
            className="process-table"
            rows={rows}
            init={init}
            stop={stop}
            fullHeight
            select={select}
            columns={columns}
            deselect={deselect}
            selection={selection}
            stopPoll={stopPoll}
            startPoll={startPoll}
            isLoading={isLoading}
            hasSelection={isPermitted}
            emptyMessage={__('No processes')}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
