import React from 'react'

import Page from 'ipmp-react-ui/Page'

import GroupsTable from './GroupsTable'
import GroupsGridBar from './GroupsGridBar'

export default function GroupsPage() {
    return <Page Bar={GroupsGridBar} Content={GroupsTable} />
}
