import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {compose} from 'redux'

import Togglebox from 'ipmp-react-ui/ToggleBox'

import {encryption} from 'permissions/panel/actions'
import {setEncryptionState} from 'modules/panels/store/actions'
import {MODULE_TYPE_ANY, moduleTypeTitle} from 'constants/moduleTypes'
import {showConfirmationModal} from 'modules/modals/actions'
import {
    selectModuleEncrypted,
    selectModuleEncryptionIsEnabled,
} from 'modules/features/store/selectors'
import __ from 'utils/i18n'
import usePanel from 'hooks/pages/usePanel'
import {useActions} from 'modules/higherOrder/useActions'
import {withPermissionVisibility} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

const ToggleEncryptionItemBase = (props) => {
    const {
        handle,
        panelId,
        type,
        showConfirmationModal,
        setPreventHideOnOuterClick,
        encrypted,
    } = props

    const [changeValue, setChangeValue] = useState(encrypted)
    const {canEnable, canDisable} = useSelector(
        (state) => state.features.store.byIds[panelId].encryption[type]
    )

    const canChangeValue = (changeValue && !canDisable) || (!changeValue && !canEnable)

    const isDisabled = () => {
        if (type === MODULE_TYPE_ANY) {
            return canChangeValue
        }

        return props.connected || canChangeValue
    }

    const handleEncryptionChange = () => {
        const modalHandle = () => {
            handle(panelId, type, !changeValue)
        }

        showConfirmationModal(
            [modalHandle, () => setChangeValue(!changeValue)],
            {
                message: __('Do you really want to change encryption settings?'),
                title: __('Toggle encryption'),
            },
            setPreventHideOnOuterClick
        )
    }

    return (
        <div className="menu-item menu-item--toggle">
            <Togglebox
                label={props.label}
                onChange={handleEncryptionChange}
                checked={changeValue}
                disabled={isDisabled()}
            />
        </div>
    )
}

const ToggleEncryptionItemVisibility = compose(
    withPermissionVisibility(encryption),
    withVisibility()
)(ToggleEncryptionItemBase)

export default function ToggleEncryptionItem(props) {
    const panelProps = usePanel()
    const actions = useActions({
        handle: setEncryptionState,
        showConfirmationModal,
    })
    const toggleProps = {...props, ...panelProps, ...actions}
    const {panel, type} = toggleProps
    const {modules} = panel || {}

    const isVisible = useSelector((state) =>
        selectModuleEncryptionIsEnabled(state, {type, panelId: panel.id})
    )

    const encrypted = useSelector(() => selectModuleEncrypted({type, modules}))

    return (
        <ToggleEncryptionItemVisibility
            {...toggleProps}
            label={moduleTypeTitle(type)}
            encrypted={encrypted}
            isVisible={isVisible}
        />
    )
}
