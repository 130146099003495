import get from 'lodash-es/get'
import {err} from 'utils/log'

const FUNCTION_EQUALS = '=='
const FUNCTION_NOT_EQUALS = '!='
const FUNCTION_OR = '||'
const FUNCTION_AND = '&&'
const FUNCTION_VALUE = 'VALUE'
const FUNCTION_ID = 'ID'

export function lookup({function: operation, operands}, acc = {}) {
    switch (operation) {
        case FUNCTION_EQUALS:
        case FUNCTION_NOT_EQUALS:
        case FUNCTION_OR:
        case FUNCTION_AND:
            return {
                ...lookup(operands.left, acc),
                ...lookup(operands.right, acc),
                ...acc,
            }
        case FUNCTION_VALUE:
            return {[operands.key]: true}
        case FUNCTION_ID:
            return {}
        default:
            err('Unable to recognise function:', operation)
            return {}
    }
}

export function resolve({function: operation, operands}, values) {
    switch (operation) {
        case FUNCTION_EQUALS:
            return resolve(operands.left, values) === resolve(operands.right, values)
        case FUNCTION_NOT_EQUALS:
            return resolve(operands.left, values) !== resolve(operands.right, values)
        case FUNCTION_AND:
            return resolve(operands.left, values) && resolve(operands.right, values)
        case FUNCTION_OR:
            return resolve(operands.left, values) || resolve(operands.right, values)
        case FUNCTION_VALUE:
            return get(values, operands.key, null)
        case FUNCTION_ID:
            return operands.val
        default:
            err('Unable to recognise function:', operation)
            return false
    }
}
