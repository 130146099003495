import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

import reduce from 'lodash-es/reduce'
import filter from 'lodash-es/filter'

import Each from 'ui/Each'

import {
    getChannelTitle,
    getConnectionTypeTitle,
    getEncryptionTitle,
    getStatusIcon,
    getStatusTitle,
    STATUS_NOT_CONFIGURED,
} from 'constants/connections'

import {__} from 'utils/i18n'

const ChannelInfo = ({connection}) => {
    return (
        <div>
            <div className="channel-encryption-col">
                <div className="connection-channel">
                    {getChannelTitle(connection.channel_type)}
                </div>
                <div className="connection-encryption">
                    {getEncryptionTitle(connection.encryption)}
                </div>
            </div>
        </div>
    )
}

const getHostPort = (connection) => {
    const hostname = connection?.additional_info?.hostname
    const port = connection?.additional_info?.port

    if (!hostname) {
        return null
    }

    if (hostname && port) {
        return hostname + ':' + port
    }

    return hostname
}

const ConnectionInfo = ({connection}) => {
    const hostPort = getHostPort(connection)

    return (
        <div>
            {getStatusIcon(connection.value)}
            <div>
                <div>{getStatusTitle(connection.value)}</div>
                {hostPort && <div>{hostPort}</div>}
            </div>
        </div>
    )
}

const filterStatus = (status) =>
    reduce(
        status,
        (acc, protocol, name) => {
            const rows = filter(protocol, ({value}) => value !== STATUS_NOT_CONFIGURED)
            if (rows.length > 0) {
                acc[name] = rows
            }
            return acc
        },
        {}
    )

//There is a requirement to remove button to connect DLS since USC was obsoleted
const ConnectionStatusTab = ({connectionStatus: {status}}) => {
    // There is a requirement to hide all not configured channels
    // As a result we also have to hide all the protocols that does not have any configured channels on them
    const visibleStatus = useMemo(() => filterStatus(status), [status])

    return (
        <div className="connection-status">
            <table>
                <thead>
                    <tr>
                        <th className="protocol-header">{__('Protocol')}</th>
                        <th className="channel-header">{__('Channel & Encryption')}</th>
                        <th className="encryption-header">{__('Connection')}</th>
                    </tr>
                </thead>
                <tbody>
                    <Each
                        of={visibleStatus}
                        render={(connections, protocol) => (
                            <Each
                                of={connections}
                                render={(connection, idx) => (
                                    <tr key={protocol + idx + 'title'}>
                                        {idx === 0 && (
                                            <td
                                                rowSpan={connections.length}
                                                className="connection-name-short"
                                            >
                                                <span>
                                                    {getConnectionTypeTitle(protocol)}
                                                </span>
                                            </td>
                                        )}
                                        <td className="channel-cell">
                                            <ChannelInfo connection={connection} />
                                        </td>
                                        <td className="host-port-cell">
                                            <ConnectionInfo connection={connection} />
                                        </td>
                                    </tr>
                                )}
                            />
                        )}
                    />
                </tbody>
            </table>
        </div>
    )
}

ConnectionStatusTab.propTypes = {
    connectionStatus: PropTypes.shape({
        status: PropTypes.object.isRequired,
    }).isRequired,
}

export default ConnectionStatusTab
