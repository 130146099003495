import React from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import Input from 'ipmp-react-ui/Input'
import Textarea from 'ipmp-react-ui/Textarea'
import {AddressAutocomplete} from './CustomerInfo/AddressAutocomplete'

export const validation = {
    email: {
        emailRFC: true,
    },
    name: {
        format: {
            pattern: /^[a-zA-Z 0-9_\-'.]+$/,
            message: () =>
                __(
                    'Allowed only digits, letters, spaces, dots, dashes, and single-quotes.'
                ),
        },
    },
    phone: {
        phone: true,
    },
    address: {
        length: {maximum: 200},
    },
    remark: {
        length: {
            maximum: 1000,
        },
    },
}

const CustomerFieldSet = ({data, googleToken, googleApiError}) => {
    return (
        <div>
            <Input
                label={__('Name')}
                name="name"
                maxLength="32"
                defaultValue={data.name}
                dataTestId="customer-name"
            />

            <Input
                label={__('Email')}
                name="email"
                maxLength="72"
                defaultValue={data.email}
                dataTestId="customer-email"
            />

            <Input
                label={__('Phone')}
                name="phone"
                defaultValue={data.phone}
                dataTestId="customer-phone"
            />

            <AddressAutocomplete
                googleToken={googleToken}
                defaultValue={data.address}
                googleApiError={googleApiError}
            />

            <Textarea
                label={__('Remark')}
                name="remark"
                maxLength="1000"
                defaultValue={data.remark}
                dataTestId="customer-remark-first"
            />

            <Textarea
                label={__('Remark 2')}
                name="remark2"
                maxLength="1000"
                defaultValue={data.remark2}
                dataTestId="customer-remark-second"
            />

            <Textarea
                label={__('Remark 3')}
                name="remark3"
                maxLength="1000"
                defaultValue={data.remark3}
                dataTestId="customer-remark-third"
            />
        </div>
    )
}

CustomerFieldSet.propTypes = {
    data: PropTypes.object,
}

CustomerFieldSet.defaultProps = {
    data: {},
}

export default CustomerFieldSet
