import React from 'react'

import {TableGrouped} from 'ipmp-react-ui/Table'
import {humanDate} from 'ipmp-react-ui/humanTime'

import {__} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import ActionsCell from './Cells/ActionsCell'
import usePermission from 'hooks/usePermission'
import PanelCell from 'components/TableCells/PanelCell'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'
import NextRICell from 'components/Cell/RemoteInspections/NextRICell'
import ResultsCell from 'components/Cell/RemoteInspections/ResultsCell'
import selectionPermission from 'permissions/remoteInspections/selection'
import useRemoteInspectionsResult from 'hooks/pages/useRemoteInspectionsResult'

const columns = [
    {
        tooltip: false,
        render: ({
            id,
            serial,
            account,
            latest,
            result,
            progress,
            next,
            repetition,
            isSupported,
        }) => {
            const results = latest ? latest.result : []

            return (
                <div>
                    <PanelCell {...{id, serial, account}} to="remoteInspections" />
                    {isSupported && <ResultsCell {...{results, progress, result}} />}
                    {isSupported && <NextRICell {...{next, repetition, isSupported}} />}
                    {!isSupported && (
                        <div>{__('Remote inspection is not supported')}</div>
                    )}
                </div>
            )
        },
    },
    {
        tooltip: false,
        width: 150,
        fixed: true,
        render: ActionsCell,
    },
]

const separatorCallback = (row) => {
    let group = humanDate(row.last)

    return group || __('Remote Inspections never initiated')
}

export default function RemoteInspectionsTable() {
    const {isPermitted} = usePermission(selectionPermission)
    const remoteInspections = useRemoteInspections()
    const {selection, select, deselect} = useSelection()
    const remoteInspectionsResult = useRemoteInspectionsResult()

    const componentProps = {...remoteInspections, ...remoteInspectionsResult}

    const handleSelected = (remoteInspections, index) => {
        return remoteInspectionsResult.select(index)
    }
    return (
        <TableGrouped
            {...componentProps}
            select={select}
            columns={columns}
            deselect={deselect}
            selection={selection}
            selectionBar={false}
            hasSelection={isPermitted}
            onRowClick={handleSelected}
            emptyMessage={__('No Panels')}
            separatorCallback={separatorCallback}
            active={{id: remoteInspectionsResult.selectedId}}
        />
    )
}
