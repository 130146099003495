import React from 'react'

import useSelection from 'hooks/useSelection'
import useGroups from 'hooks/pages/useGroups'
import {MAIN_GROUP_ID} from 'constants/groups'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import SelectionDropDown from 'components/SelectionDropDown'
import selectionPermission from 'permissions/groups/selection'

const DropDownVisibility = withVisibility()(SelectionDropDown)

export default function GroupsSelectionDropDown() {
    const {rows, total} = useGroups()
    const {isPermitted} = usePermission(selectionPermission)
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <DropDownVisibility
            rows={rows}
            total={total}
            selection={selection}
            select={select}
            deselect={deselect}
            deselectAll={deselectAll}
            selectAll={selectAll}
            isVisible={isPermitted}
            disabledRowsIds={[MAIN_GROUP_ID]}
        />
    )
}
