import {post, get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {map, keysMap} from './auth.map'
import {countToLog} from 'constants/mainMenu'

export function login(email, password) {
    return post('login/sign', {
        usr_email: email,
        usr_password: password,
    })
        .then(map)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export async function setPhone(phone) {
    return post('login/phone', {phone}).catch((e) => mapValidationErrors(e, keysMap))
}

export async function sendCode() {
    return post('login/send').catch((e) => mapValidationErrors(e, keysMap))
}

export function confirmCode(code) {
    return post('login/send/confirm', {code})
        .then(map)
        .catch((e) => mapValidationErrors(e, keysMap))
}

export function remindPassword(email) {
    return post('login/forgot', {usr_email: email}).catch((e) =>
        mapValidationErrors(e, keysMap)
    )
}

export function logout() {
    return post('logout/out')
}

export function whoami(options) {
    return get('login/whoami', null, options).then(map)
}

export function setSettings(settings) {
    let menuOrderLog = {}
    let menuOrderManualLog = {}
    Object.keys(settings.menuOrder).forEach((orderItem) => {
        menuOrderLog[orderItem] = countToLog(settings.menuOrder[orderItem])
    })

    if (settings.menuOrderManual) {
        Object.keys(settings.menuOrderManual).forEach((orderItem) => {
            menuOrderManualLog[orderItem] = countToLog(
                settings.menuOrderManual[orderItem]
            )
        })
    }

    return post('settings/local', {
        ...settings,
        menuOrder: menuOrderLog,
        menuOrderManual: menuOrderManualLog,
    })
}
