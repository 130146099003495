import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRunners from 'hooks/pages/useRunners'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import {stop as stopPermission} from 'permissions/processes/actions'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(
        (id, {runners}) => runners.store.byIds[id] && runners.store.byIds[id].isStoppable
    )
)(Button)

export default function RunnerStopButton() {
    const {isPermitted} = usePermission(stopPermission)
    const {stop} = useRunners()

    return <ConnectedButton isVisible={isPermitted} onClick={stop} label={__('Stop')} />
}
