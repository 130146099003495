import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import GroupForm from './GroupForm'
import useForm from 'hooks/useForm'
import {editGroup} from 'modules/forms/handlers'

export default function EditGroup({groupId, activeTab, hide}) {
    const {handle, form, isLoading} = useForm(editGroup)
    const group = useSelector(({groups}) => groups.store.byIds[groupId])
    const handler = (data) => handle(groupId, data)

    return (
        <GroupForm
            {...form}
            data={group}
            hide={hide}
            activeTab={activeTab}
            isLoading={isLoading}
            header={__('Edit Group')}
            handle={handler}
        />
    )
}
