export const provisionState = {
    user: '',
    password: '',
    number: '',
    serial_number: '',
    partitions: [],
    label: '',
    partitionValues: [],
}

export const RtspState = {
    stream: '',
    number: '',
    partitions: [],
    label: '',
    partitionValues: [],
}
