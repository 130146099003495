import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import usePermission from 'hooks/usePermission'
import bar from 'permissions/basicConfiguration/bar'
import {withVisibility} from 'containers/withVisibility'
import BasicConfigurationsPager from './GridBar/BasicConfigurationsPager'
import BasicConfigurationsRemoveButton from './GridBar/BasicConfigurationsRemoveButton'
import BasicConfigurationsSelectionDropDown from './GridBar/BasicConfigurationsSelectionDropDown'

const BasicConfigurationsGridBarBase = () => (
    <Bar>
        <BasicConfigurationsSelectionDropDown />
        <BasicConfigurationsRemoveButton />

        <BarSpace />

        <BasicConfigurationsPager />
    </Bar>
)

const BasicConfigurationsGridBarVisibility = withVisibility()(
    BasicConfigurationsGridBarBase
)

export default function BasicConfigurationsGridBar() {
    const {isPermitted} = usePermission(bar)

    return <BasicConfigurationsGridBarVisibility isVisible={isPermitted} />
}
