import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
} from 'constants/eventDelivery'
import {
    addFilters,
    clearFilters,
    fetch,
    removeFilters,
    fetchSuggests,
    setQuery,
} from 'modules/events/list/actions'
import __ from 'utils/i18n'
import list from 'permissions/events/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import delivery from 'permissions/events/delivery'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showSaveSearchModal} from 'modules/modals/actions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectEventsList} from 'modules/events/list/selectors'
import centralStations from 'permissions/centralStations/list'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

const SearchVisibility = withVisibility()(Search)

const getFilters = (isDeliveryPermitted, isCentralStationsPermitted) => {
    let filters = [
        new TranslateSuggestFilter('severity', __('Severity'), {
            alarm: __('Alarm'),
            alert: __('Alert'),
            offline: __('Offline'),
            online: __('Online'),
            notice: __('Notice'),
            restore: __('Restore'),
            duress: __('Duress'),
            securityopenclose: __('Security open/close'),
        }),

        new SuggestFilter('serial', __('Panel Id')),

        new SuggestFilter('account', __('Account')),

        new ValuesFilter('camera', __('Has Video'), {
            '{>=1}': __('Yes'),
            0: __('No'),
        }),

        new DateFilter('datetime', __('Date')),

        new SuggestFilter('description', __('Event Description')),

        new ValuesFilter('sourceProtocol', __('Source Protocol'), {
            sia_over_ip: __('SIA over IP'),
            lwm2m: __('LWM2M'),
            fibro: __('Fibro'),
            itv2: __('ITv2'),
            PowerManage: __('PowerManage'),
        }),
    ]

    if (isDeliveryPermitted) {
        filters = [
            ...filters,
            new ValuesFilter('eds_status', __('Delivery status'), {
                [EDS_QUEUED]: __('Queued'),
                [EDS_PROCESSING]: __('Processing'),
                [EDS_SUCCESS]: __('Success'),
                [EDS_RETRY]: __('Retry'),
                [EDS_FAILED]: __('Failed'),
            }),

            new DateFilter('eds_changed_timestamp', __('Delivery status changed')),
        ]
    }

    if (isCentralStationsPermitted) {
        filters.push(new SuggestFilter('cls_name', __('Central Station')))
    }

    return filters.sort((item, next) => item.title.localeCompare(next.title))
}
export default function EventsSearch({className}) {
    const {scope} = useParams()
    const {filters: selected, query, suggests} = useSelector(selectEventsList)
    const {isVisible, isDeliveryPermitted, isCentralStationsPermitted} = usePermission({
        isVisible: list,
        isDeliveryPermitted: delivery,
        isCentralStationsPermitted: centralStations,
    })

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest, onSaveSearch} =
        useActions({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: (filters, onClose) =>
                showSaveSearchModal('events', filters, onClose),
        })

    const filters = getFilters(isDeliveryPermitted, isCentralStationsPermitted)

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            onSaveSearch={onSaveSearch}
            key={scope}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isVisible}
            className={className}
            placeholder={__('Search Events')}
        />
    )
}
