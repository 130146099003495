import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import {connect, useSelector} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import Error from 'ipmp-react-ui/Error'
import Spinner from 'ipmp-react-ui/Spinner'

import __ from 'utils/i18n'
import path from 'utils/path'
import usePermission from 'hooks/usePermission'
import {stop} from 'modules/runners/store/actions'
import {getProcessLinkByType} from 'constants/processType'
import BatchRunnerItem from 'components/Processes/BatchRunnerItem'
import {stop as stopPermission} from 'permissions/processes/actions'
import {activeElementClass} from 'components/Processes/ProcessBatch'
import BatchRunnerStatus from 'components/Processes/BatchRunnerStatus'
import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'
import {reset, startPoll, stopPoll} from 'modules/batches/batchRunners/actions'
import NavBlind, {NavBlindList, NavBlindListMore} from 'components/Nav/NavBlind'
import {selectRunnersRowsByBatchId} from 'modules/batches/batchRunners/selectors'
import withLifeCycle from 'containers/withLifeCycle'

const BatchInfo = ({
    batchId,
    hide,
    stop,
    reset,
    batch,
    runners,
    isLoading,
    error,
    stoppingIds,
    hasMore,
    navBlindRefHandler,
    hideSidebar,
}) => {
    const navigate = useNavigate()
    const {top} = useSelector(({navBlind}) => navBlind)
    const {isPermitted: isStopPermitted} = usePermission(stopPermission)
    const byIdsSystemTests = useSelector((state) => state.panels.systemTests)

    useEffect(() => {}, [top])

    useEffect(() => {
        reset()
    }, [batchId])

    useEffect(() => {
        if (!batch) {
            hide()
        }
    }, [batch])

    const handleClick = (panelId) => {
        const link = getProcessLinkByType(batch.type)

        if (!link) {
            const panelTest = byIdsSystemTests[panelId] || {}
            if (panelTest && panelTest.supportedTests) {
                const panelTestsProcesses = panelTest.supportedTests.map(
                    ({testName}) => testName
                )
                if (panelTestsProcesses.includes(batch.type)) {
                    navigate(path('panel.panel-test', {id: panelId}))
                    hide()
                }
            }
            return
        }

        navigate(path(link, {id: panelId}))
        hide()
    }

    const onMoreClick = () => {
        navigate(path('batches.runners', {id: batch.id}))
        hide()
        hideSidebar()
    }

    const renderBatchItem = (runner, isStopping) => (
        <BatchRunnerItem {...runner} key={runner.id} onRowClick={handleClick}>
            <BatchRunnerStatus
                row={{
                    ...runner,
                }}
                isStopping={isStopping}
                isStopPermitted={isStopPermitted}
                onStop={stop}
            />
        </BatchRunnerItem>
    )

    const renderList = () => {
        if (isLoading) {
            return <Spinner />
        }

        if (error) {
            return <Error error={error} />
        }

        if (!runners.length) {
            return <Error message={__('No processes')} />
        }

        return runners.map((runner) => {
            return runner && renderBatchItem(runner, stoppingIds.includes(runner.id))
        })
    }

    const elementTopPosition = () => {
        const activeBatchItemPosition = document
            .querySelector('.' + activeElementClass)
            ?.getBoundingClientRect()
        const navBatchItem = parseInt(activeBatchItemPosition?.top - 68)
        const navBlindHeight = (batch?.processCount || 0) * 88 + navBatchItem + 68
        const calculatedPadding = navBatchItem - (navBlindHeight - window.innerHeight)

        if (window.innerHeight < navBlindHeight && calculatedPadding > 0) {
            return {
                marginTop: calculatedPadding + 'px',
                height: 'initial',
            }
        }
        if (window.innerHeight < navBlindHeight && calculatedPadding < 0) {
            return {
                marginTop: 0,
                height: '100%',
            }
        }
        return {
            marginTop: navBatchItem + 'px',
            height: 'initial',
        }
    }

    return (
        <NavBlind style={elementTopPosition()} refHandler={navBlindRefHandler}>
            <NavBlindList>
                {renderList()}

                {hasMore && <NavBlindListMore onClick={onMoreClick} />}
            </NavBlindList>
        </NavBlind>
    )
}

BatchInfo.propTypes = {
    batch: PropTypes.shape({
        type: PropTypes.string,
    }),
    stoppingIds: PropTypes.array,
    runners: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            panelId: PropTypes.number.isRequired,
        })
    ),
    stop: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    navBlindRefHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    hasMore: PropTypes.bool,
}

export default compose(
    connect(selectRunnersRowsByBatchId, (dispatch, {batchId}) =>
        bindActionCreators(
            {
                stop,
                reset: () => reset(batchId),
                startPoll,
                stopPoll,
            },
            dispatch
        )
    ),
    withLifeCycle({
        onMount({startPoll, reset}) {
            reset()
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    connect((state, {isLoading, batchId}) => ({
        isLoading: isLoading || selectBatchIsTemporary(state, batchId),
    }))
)(BatchInfo)
