import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useProcesses from 'hooks/pages/useProcesses'
import {withVisibility} from 'containers/withVisibility'
import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import {stop as stopPermission} from 'permissions/processes/actions'

const ButtonVisibility = compose(
    withVisibility(),
    withSelectionHandler(),
    ifSelection(
        (id, {processes}) =>
            processes.store.byIds[id] && processes.store.byIds[id].isStoppable
    )
)(Button)

export default function ProcessesStopButton() {
    const {isPermitted} = usePermission(stopPermission)
    const {stop} = useProcesses()

    const onClick = (ids) => stop(ids)

    return (
        <ButtonVisibility isVisible={isPermitted} label={__('Stop')} onClick={onClick} />
    )
}
