import React from 'react'

import BasicConfigurationPage from './BasicConfigurationPage'
import path from 'utils/path'

export default class BasicConfigurationsRoute {
    exact = true

    path = path('basicConfiguration.edit')

    element = (<BasicConfigurationPage />)
}
