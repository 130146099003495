import createListActions from 'modules/higherOrder/createListActions'
import {createAction} from 'redux-actions'

export const {
    init,
    reset,
    fetch,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('EVENTS/LIST')

export const fetchNewer = createAction('EVENTS/LIST/FETCH_NEWER')
export const fetchOverbound = createAction('EVENTS/LIST/FETCH_OVERBOUND')
export const receiveOverbound = createAction('EVENTS/LIST/RECEIVE_OVERBOUND')
export const nextOverbound = createAction('EVENTS/LIST/NEXT_OVERBOUND')
export const prevOverbound = createAction('EVENTS/LIST/PREV_OVERBOUND')

export const setMaxId = createAction('EVENTS/LIST/SET_MAX_ID', (maxId) => ({maxId}))
export const setNewerCount = createAction('EVENTS/LIST/SET_NEWER_COUNT', (count) => ({
    count,
}))
export const setUnhandledFirst = createAction(
    'EVENTS/LIST/SET_UNHANDLED_FIRST',
    (isUnhandledFirst) => ({isUnhandledFirst})
)
