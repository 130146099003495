import React from 'react'
import {useSelector} from 'react-redux'

import {humanDuration} from 'ipmp-react-ui/humanTime'

import __ from 'utils/i18n'
import {selectHadIsSwitchable} from 'modules/panels/had/selector'

export function IsCanActivatedDisclaimerMessage({isTimeSlotAutomationDevicesEnabled}) {
    if (isTimeSlotAutomationDevicesEnabled) {
        return <>{__('Automation devices and Outputs can be activated')}</>
    }

    return <>{__('Outputs can be activated')}</>
}

export function IsOnlyAlarmActivatedDisclaimerMessage({
    isTimeSlotAutomationDevicesEnabled,
}) {
    const {timeSlot} = useSelector(selectHadIsSwitchable)
    const time = humanDuration(timeSlot, 'seconds', true)

    if (isTimeSlotAutomationDevicesEnabled) {
        return (
            <>
                {__(
                    'Automation devices and Outputs can be only activated during %s since alarm received',
                    time
                )}
            </>
        )
    }

    return <>{__('Outputs can be only activated during %s since alarm received', time)}</>
}

export function IsDisabledDisclaimerMessage({isTimeSlotAutomationDevicesEnabled}) {
    if (isTimeSlotAutomationDevicesEnabled) {
        return (
            <>{__('Automation devices and outputs activation is disabled in settings')}</>
        )
    }

    return <>{__('Outputs activation is disabled in settings')}</>
}
