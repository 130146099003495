import isFunction from 'lodash-es/isFunction'
import {warn} from 'utils/log'

export default function resolveMapTranslation(map, warningMessageCreator) {
    return (key) => {
        if (map.has(key)) {
            const result = map.get(key)

            if (isFunction(result)) {
                return result()
            }

            return result
        }

        warn(warningMessageCreator(key))

        return key
    }
}
