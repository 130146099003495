import React, {useEffect} from 'react'
import {useNavigate} from 'react-router'

import Table from 'ipmp-react-ui/Table'

import path from 'utils/path'
import Empty from 'ui/Empty'
import Duration from 'ui/Duration'
import {__, __n} from 'utils/i18n'
import useBatches from 'hooks/pages/useBatches'
import withLoader from 'containers/withLoader'
import processType from 'constants/processType'
import {
    PROCESS_TYPE_FIRMWARE_UPGRADE,
    PROCESS_TYPE_NEO_UPGRADE,
} from 'constants/processTypes'
import ProcessCounters from 'components/Processes/ProcessCounters'
import BatchStatusCell from 'pages/Batches/BatchesCells/BatchStatusCell'
import BatchPackages, {
    ConnectedTrigger,
} from 'pages/UpgradeStatus/TableCell/BatchPackages'

/**
 * PMN-5919
 * widht values were set, according to minimal table viewport
 * 794px = 1000px - 16px(pzdding) - 190px(asside)
 */
export const columns = [
    {
        name: () => __('Timing'),
        maxWidth: 170,
        render: ({started, finished}) => <Duration start={started} end={finished} />,
    },
    {
        name: () => __('Process'),
        width: 150,
        render: ({type, id}) => {
            if (
                [PROCESS_TYPE_NEO_UPGRADE, PROCESS_TYPE_FIRMWARE_UPGRADE].includes(type)
            ) {
                return (
                    <BatchPackages batchId={id}>
                        <ConnectedTrigger batchId={id} title={processType(type)} />
                    </BatchPackages>
                )
            }
            return processType(type)
        },
    },
    {
        name: () => __('Panel'),
        width: 100,
        render: ({panels, panelSerial}) => {
            if (panels > 1) {
                return __n('%d panel', '%d panels', panels)
            }

            return panelSerial || <Empty />
        },
    },
    {
        name: () => __('Status'),
        width: 100,
        render: BatchStatusCell,
    },
    {
        name: () => __('Stats'),
        width: 156,
        render: ({stats}) => <ProcessCounters withLabels stats={stats} />,
    },
]

const ConnectedTable = withLoader(({init}) => init())(Table)

export default function BatchesTable() {
    const navigate = useNavigate()
    const batchesProps = useBatches()

    useEffect(() => {
        batchesProps.startPoll()

        return batchesProps.stopPoll
    }, [])

    const onRowClick = ({id}) => {
        navigate(path('batches.runners', {id}))
    }

    return (
        <ConnectedTable
            columns={columns}
            emptyMessage={__('No batches')}
            onRowClick={onRowClick}
            selectionBar={false}
            {...batchesProps}
        />
    )
}
