import {PARTITION_ALL} from 'constants/partitions'
import {handleActions} from 'redux-actions'

import * as actions from './actions'
import keyBy from 'lodash-es/keyBy'
import {set} from 'immutable-modify'

const defaultState = {
    byIds: {},
}

export default handleActions(
    {
        [actions.receive](state, {error, payload, meta: {panelId}}) {
            if (error) {
                return set(state, ['byIds', panelId], (data) => ({
                    ...data,
                    error: payload,
                }))
            }

            const {partitionStates, partitions, stateSets} = payload

            return set(state, ['byIds', panelId], (data) => {
                return {
                    ...data,
                    error: null,
                    hasPartitions: partitions.find(
                        (partition) => partition.id !== PARTITION_ALL
                    ),
                    partitionStates: keyBy(partitionStates, 'id'),
                    partitions: keyBy(partitions, 'id'),
                    stateSets: Object.keys(stateSets).reduce((acc, key) => {
                        acc[key] = keyBy(stateSets[key], 'name')

                        return acc
                    }, {}),
                }
            })
        },

        [actions.renamePartition](state, {payload: {panelId, partitionId, name}}) {
            return set(state, ['byIds', panelId, 'partitions', partitionId, 'name'], name)
        },
        [actions.setStateInProgress](
            state,
            {payload: {panelId, partitionId, partitionState, inProgress}}
        ) {
            return set(
                state,
                ['byIds', panelId, 'statesInProgress', partitionId, partitionState],
                inProgress
            )
        },
    },
    defaultState
)
