import React, {useEffect, useState} from 'react'

import {ReactComponent as UnhandledIcon} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as NewEventsIcon} from 'ipmp-react-ui/icons/alarm.svg'
import {ReactComponent as AllEventsIcon} from 'ipmp-react-ui/icons/list-all.svg'
import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import __ from 'utils/i18n'
import classes from 'classnames'
import useEvents from 'hooks/pages/useEvents'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import {markAsViewed as markAsViewedPermission} from 'permissions/events/actions'
import {fetch} from 'modules/events/list/actions'

export default function StreamEventsButtons() {
    const {
        isFirstPageStreamable,
        setFirstPageStreamable,
        setUnhandledFirst,
        unhandledShowedFirst,
    } = useEvents()

    const {isPermitted} = usePermission(markAsViewedPermission)

    const handleChange = () => {
        setFirstPageStreamable(!isFirstPageStreamable.state)
    }

    const [unhandledEventsFirst, setUnhandledEventsFirst] = useState(false)

    const {callSetUnhandledFirst, callFetch} = useActions({
        callSetUnhandledFirst: setUnhandledFirst,
        callFetch: fetch,
    })
    const handleSortEventsFetch = () => {
        setUnhandledEventsFirst(!unhandledEventsFirst)
    }

    useEffect(() => {
        setUnhandledEventsFirst(unhandledShowedFirst)
    }, [])

    useEffect(() => {
        callSetUnhandledFirst(unhandledEventsFirst)
        callFetch()
    }, [unhandledEventsFirst])

    return (
        <div className="bar-item streamNewEvents">
            <Buttons>
                {isPermitted && (
                    <DropDownButton
                        label={
                            !unhandledEventsFirst
                                ? __('All Events')
                                : __('Unhandled Events First')
                        }
                        Icon={!unhandledEventsFirst ? AllEventsIcon : UnhandledIcon}
                    >
                        <Menu>
                            <MenuItem
                                Icon={
                                    unhandledEventsFirst ? AllEventsIcon : UnhandledIcon
                                }
                                onClick={handleSortEventsFetch}
                            >
                                {unhandledEventsFirst
                                    ? __('All Events')
                                    : __('Unhandled Events First')}
                            </MenuItem>
                        </Menu>
                    </DropDownButton>
                )}
                <Button
                    flat
                    Icon={NewEventsIcon}
                    onClick={handleChange}
                    className={classes('stream-events-btn', {
                        'active-stream': isFirstPageStreamable?.state,
                    })}
                >
                    {__('Stream Events')}
                </Button>
            </Buttons>
        </div>
    )
}
