import React, {useEffect} from 'react'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import Duration from 'ui/Duration'

import useSelection from 'hooks/useSelection'
import useRunners from 'hooks/pages/useRunners'
import useAutoFilters from 'hooks/useAutoFilters'

import withLoader from 'containers/withLoader'

import {stop} from 'permissions/processes/actions'

import PanelCell from 'components/TableCells/PanelCell'
import ProcessStatus from 'components/Processes/ProcessStatus'
import ProcessStopButton from 'components/Processes/ProcessStopButton'
import Packages from 'components/UpgradeStatus/Packages'
import UpgradeStatusRetryButton from 'components/UpgradeStatus/UpgradeStatusRetryButton'

import processType from 'constants/processType'
import {
    PROCESS_TYPE_EVENT_INITIATE,
    PROCESS_TYPE_FIRMWARE_UPGRADE,
    PROCESS_TYPE_NEO_UPGRADE,
} from 'constants/processTypes'
import {HISTORY_PUSH} from 'constants/reactRouterHistoryActions'

import {__} from 'utils/i18n'

const empty = <span className="empty">—</span>

export const columns = [
    {
        maxWidth: 170,
        name: () => __('Timing'),
        render: ({started, finished}) => <Duration start={started} end={finished} />,
    },
    {
        maxWidth: 150,
        name: () => __('Panel'),
        render: ({panelId, serial}) =>
            panelId ? <PanelCell id={panelId} serial={serial} /> : empty,
    },
    {
        width: 150,
        name: () => __('Process'),
        render: ({type, description, processType: processName, params}) => {
            if (type === PROCESS_TYPE_EVENT_INITIATE) {
                return (
                    <Definition title={processType(processName)} detail={description} />
                )
            }
            if (
                [PROCESS_TYPE_NEO_UPGRADE, PROCESS_TYPE_FIRMWARE_UPGRADE].includes(type)
            ) {
                return (
                    <Packages packages={JSON.parse(params).packages}>
                        <Definition title={processType(type)} detail={description} />
                    </Packages>
                )
            }
            return <Definition title={processType(type)} detail={description} />
        },
    },
    {
        width: 110,
        name: () => __('Status'),
        render: (row, {stoppingIds}) => {
            return (
                <ProcessStatus
                    row={row}
                    isStopping={stoppingIds.includes(row.id)}
                    runnerTable={true}
                />
            )
        },
    },
    {
        tooltip: false,
        align: 'right',
        permission: stop,
        width: 50,
        fixed: true,
        render: ({isStoppable, isFailed, id, type}, {stop}) => {
            if (isFailed) {
                return <UpgradeStatusRetryButton type={type} id={id} />
            }
            return <ProcessStopButton onClick={() => stop(id)} disabled={!isStoppable} />
        },
    },
]

const ConnectedTable = withLoader(({init, filters}) => {
    return init(null, HISTORY_PUSH, filters)
})(Table)

export default function RunnersTable() {
    const {selection, select, deselect} = useSelection()
    const {
        rows,
        init,
        error,
        isLoading,
        startPoll,
        stopPoll,
        stoppingIds,
        stop,
        disabledRowsIds,
    } = useRunners()

    const {filters} = useAutoFilters()

    useEffect(() => {
        startPoll()

        return stopPoll
    }, [])

    return (
        <ConnectedTable
            fullHeight
            rows={rows}
            init={init}
            error={error}
            stop={stop}
            filters={filters}
            select={select}
            deselect={deselect}
            selection={selection}
            isLoading={isLoading}
            stoppingIds={stoppingIds}
            disabledRowsIds={disabledRowsIds}
            columns={columns}
            emptyMessage={__('No batch runners')}
        />
    )
}
