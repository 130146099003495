import React from 'react'
import * as PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setUserCode} from 'modules/forms/handlers'

const getRules = (panelId) => {
    const allowedCodeLength = useSelector(
        ({features}) => features.store.byIds[panelId].user.codeLengths
    )

    return {
        code: {
            presence: true,
            format: {
                pattern: `[0-9]{${allowedCodeLength}}`,
                message: () => __('Must be %d digit', allowedCodeLength),
            },
        },
        confirmCode: {
            presence: true,
            format: {
                pattern: `[0-9]{${allowedCodeLength}}`,
                message: () => __('Must be %d digit', allowedCodeLength),
            },
            equality: {
                attribute: 'code',
                message: () => __('Codes do not match'),
                comparator: (v1, v2) => v1 === v2,
            },
        },
    }
}

export default function SetUserCode({panelId, panelUserId, hide}) {
    const {form, handle, isLoading} = useForm(setUserCode)

    const onHandle = ({code}) => {
        const id = parseInt(panelUserId)

        handle({id, code, panelId})
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={onHandle}
            isLoading={isLoading}
            rules={getRules(panelId)}
            header={__('Change code')}
        >
            <Input type="password" label={__('Code')} autoComplete="off" name="code" />
            <Input
                type="password"
                label={__('Confirm Code')}
                autoComplete="off"
                name="confirmCode"
            />
        </ModalCardForm>
    )
}

SetUserCode.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
