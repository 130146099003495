import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'

import {notificationsEdit} from 'permissions/group/navigation'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showEditGroupsEvents} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function EditEventsButton({groupId}) {
    const {isPermitted} = usePermission(notificationsEdit)
    const {onClick} = useActions({
        onClick: () => showEditGroupsEvents(groupId),
    })

    return (
        <ButtonVisibility
            Icon={EditIcon}
            onClick={onClick}
            isVisible={isPermitted}
            borderless
        />
    )
}
