import React from 'react'

import path from 'utils/path'
import FirmwareUpgradePage from './FirmwareUpgradePage'

export default class {
    path = path('firmware.upgrade')

    element = (<FirmwareUpgradePage />)
}
