import {__} from 'utils/i18n'
import {warn} from 'utils/log'
import deviceType, * as deviceTypes from './deviceType'

import iconUnknown from 'assets/devices/unknown.svg'
import iconAcOutdoor from 'assets/devices/SR-730-PG2.svg'
import iconBasicKeyfob from 'assets/devices/KF-234-PG2.svg'
import iconBasicRepeater from 'assets/devices/RP-600-PG2.svg'
import iconBuiltInGlassBreak from 'assets/devices/Panel_Glassbreak_detector.svg'
import iconBuiltInMotion from 'assets/devices/Panel_Motioin_Sensor.svg'
import iconCo from 'assets/devices/GSD-442-PG2.svg'
import iconContact from 'assets/devices/MC-302.svg'
import iconContact4In from 'assets/devices/FLD-550-PG2.svg'
import iconContactAux from 'assets/devices/MC-302e.svg'
import iconContactIos from 'assets/devices/FLD-550-PG2.svg'
import iconContactV from 'assets/devices/ContactVanish.svg'
import iconComboNbiot from 'assets/devices/Smart-CO-Sensor.svg'
import iconPspContact from 'assets/devices/MC-302e.svg'
import iconCurtain from 'assets/devices/Clip-PG2.svg'
import iconDscKeyfob1 from 'assets/devices/KF-234-PG2.svg'
import iconDscKeyfob2 from 'assets/devices/KF-235-PG2.svg'
import iconDscSmartcommPanel from 'assets/panels/SmartCommunicator-connected-to-DSC-Panel.svg'
import iconFlatPirSmart from 'assets/devices/FlatPIR.svg'
import iconFlatPirPGPlus from 'assets/devices/MP-802.svg'
import iconFlood from 'assets/devices/FLD-550-PG2.svg'
import iconGas from 'assets/devices/GSD-441-PG2.svg'
import iconGenericCommunicator from 'assets/devices/GEN_POWER_SUPPLY.svg'
import iconGenericExpander33 from 'assets/devices/Expander33.svg'
import iconGenericPgm from 'assets/devices/PGM.svg'
import iconGenericZone from 'assets/devices/Generic-Zone-Icon.svg'
import iconGenericProxyTag from 'assets/devices/Proxy-tag.svg'
import iconGlassBreak from 'assets/devices/GB-501.svg'
import iconGlassBreakSmart from 'assets/devices/GB-502-PG2.svg'
import iconWiredZone from 'assets/devices/WiredZone.svg'
import iconIndoor from 'assets/devices/RP-600-PG2-white.svg'
import iconKeyfobArmLed from 'assets/devices/KF-235-PG2.svg'
import iconKeyfob from 'assets/devices/MCM-140.svg'
import iconLcdKeypad from 'assets/devices/MKP-160.svg'
import iconLcdPrgKeypad from 'assets/devices/KP-250.svg'
import iconMc303Vanish from 'assets/devices/MC-303.svg'
import iconMotion from 'assets/devices/Next-PG2.svg'
import iconMotionCamera from 'assets/devices/Next-CAM-PG2.svg'
import iconMotionDual from 'assets/devices/TOWER-30-PG2.svg'
import iconMotionOutdoor from 'assets/devices/TOWER-20AM-PG2.svg'
import iconMotionOutdoorCamera from 'assets/devices/TOWER-CAM-PG2.svg'
import iconNeo16LedKeypad from 'assets/devices/LED_KEYPAD.svg'
import iconNeo1APowerSupplyModule from 'assets/devices/GEN_POWER_SUPPLY.svg'
import iconWireKeypad from 'assets/devices/WIRE_KEYPAD.svg'
import iconGenZoneExpander from 'assets/devices/GEN_ZONE_EXPANDER.svg'
import iconNeo from 'assets/panels/neo-1.svg'
import iconTouchKp from 'assets/devices/TOUCH_KP.svg'
import iconOutdoor from 'assets/devices/SR-730-PG2.svg'
import iconOutdoorContactNbiot from 'assets/devices/Smart-Out-MC.svg'
import iconPowerG from 'assets/devices/PowerG.svg'
import iconRecessed from 'assets/devices/Recessed.svg'
import iconLongRange from 'assets/devices/Long_Range.svg'
import iconSmartCeilingSr from 'assets/devices/Smart-Ceiling-SR.svg'
import iconSmartCoSensor from 'assets/devices/Smart-CO-Sensor.svg'
import iconSmartOurCurtain from 'assets/devices/Smart-Out-Curtain.svg'
import iconSmartSmokeHeat from 'assets/devices/Smart-Smoke-Heat.svg'
import iconShockAux from 'assets/devices/SD-304-PG2.svg'
import iconSignalButton from 'assets/devices/PB-101-PG2.svg'
import iconSmoke from 'assets/devices/SMD-426-PG2.svg'
import iconSmokeHeat from 'assets/devices/SMD-427-PG2.svg'
import iconSrfRadio from 'assets/devices/SRF.svg'
import iconTemperature from 'assets/devices/TMD-560-PG2.svg'
import iconTwoButton from 'assets/devices/PM-102-PG2.svg'
import iconVisonicPanel from 'assets/panels/powermaster-360R.svg'
import iconWirelessValve from 'assets/devices/Wireless-water-valve.svg'
import iconZwaveThermostat from 'assets/devices/z-wave/thermostat.svg'
import iconZwaveDoorLock from 'assets/devices/z-wave/doorlock.svg'
import iconZwaveLight from 'assets/devices/z-wave/light.svg'
import iconZwaveLightActive from 'assets/devices/z-wave/light-active.svg'
import iconZwaveWaterValve from 'assets/devices/z-wave/water-valve.svg'
import iconZwaveThermometer from 'assets/devices/z-wave/thermometer.svg'
import iconZwaveEnergyClamp from 'assets/devices/z-wave/energy-clamp.svg'
import iconZwaveRepeater from 'assets/devices/z-wave/repeater.svg'
import iconZwaveGarageDoor from 'assets/devices/z-wave/garage-door.svg'
import iconZwaveExternalSiren from 'assets/devices/z-wave/external-siren.svg'
import iconZwaveSmartSocket from 'assets/devices/z-wave/smart-socket.svg'
import iconZwaveDimmer from 'assets/devices/z-wave/dimmer.svg'
import iconBtPhone from 'assets/devices/BT_PHONE.svg'
import iconBtSpeaker from 'assets/devices/BT_SPEAKER.svg'
import iconContactAuxPgPlus from 'assets/devices/CONTACT_AUX_PG_PLUS.svg'
import iconShortRangeIrReceiver from 'assets/devices/SHORT_RANGE_IR_RECEIVER.svg'
import iconShortRangeIrTransmitter from 'assets/devices/SHORT_RANGE_IR_TRANSMITTER.svg'
import iconLongRangeIrReceiver from 'assets/devices/LONG_RANGE_IR_RECEIVER.svg'
import iconLongRangeIrTransmitter from 'assets/devices/LONG_RANGE_IR_TRANSMITTER.svg'
import iconContactPspPgPlus from 'assets/devices/CONTACT_PSP_PG_PLUS.svg'
import iconSmartGlassBreakPgPlus from 'assets/devices/GB-502-PG2.svg'
import iconWiredToWireless from 'assets/devices/WIRED_TO_WIRELESS.svg'
import iconEthernetCard from 'assets/devices/Ethernet-duaghter-card.svg'
import iconSmartCommunicator from 'assets/devices/SMART_COMMUNICATOR.svg'
import iconVirtualKeypad from 'assets/devices/VIRTUAL_KEYPAD.svg'
import iconGsm from 'assets/devices/4g/GSM-Modem.svg'
import iconDtPgBoxDevice from 'assets/devices/MC-302.svg'
import iconIQRemotePG from 'assets/devices/IQ_Remote_PG.svg'
import iconIQRemote from 'assets/devices/IQ_Remote.svg'
import iconIpCamera from 'assets/devices/wifi-camera.svg'
import iconCameraProvisioning from 'assets/devices/cameras-Provisioned.svg'
import iconCameraRtsp from 'assets/devices/cameras-Generic.svg'
import iconIndoorPirPgPlus from 'assets/devices/IndoorPirPgPlus.svg'
import iconAppolloPgPlus from 'assets/devices/ComboDetectorApolloPgPlus.svg'
import iconRepeaterPgPlus from 'assets/devices/RepeaterPgPlus.svg'
import iconYaleDoorlockL2PgPlus from 'assets/devices/YaleDoorlockL2PgPlus.svg'
import iconYaleDoorlockL3PgPlus from 'assets/devices/YaleDoorlockL3PgPlus.svg'
import iconIqSmartDoorlockPgPlus from 'assets/devices/IQSmartDoorLockPgPlus.svg'
import iconLeedarsonLightSwitchPgPlus from 'assets/devices/LeedarsonLightSwitchPgPlus.svg'
import iconLeedarsonDimmerSwitchPgPlus from 'assets/devices/LeedarsonDimmerSwitchPgPlus.svg'
import iconLeedarsonIndoorAcPlusPgPlus from 'assets/devices/LeedarsonIndoorACPlugPgLus.svg'
import iconLeedarsonOutdoorAcPlusPgPlus from 'assets/devices/LeedarsonOutdoorACPlugPgPlus.svg'
import iconLeedarsonSocketPlusPgPlus from 'assets/devices/LeedarsOnSocketPgPlus.svg'
import iconSrfTakeoverModule from 'assets/devices/srf/SRF_TAKEOVER_MODULE.svg'
import iconSrfAuxiliaryPendant from 'assets/devices/srf/SRF_AUXILIARY_PENDANT.svg'
import iconSrfKeyfob from 'assets/devices/srf/IQ_KEYFOB.svg'
import iconSrfMotion from 'assets/devices/srf/IQ_MOTION.svg'
import iconSrfKeypad from 'assets/devices/srf/SRF_IQ_KEYPAD.svg'
import iconSrfCarbone from 'assets/devices/srf/IQ_carbon.svg'
import iconSrfSmoke from 'assets/devices/srf/IQ_smoke.svg'
import iconSrfGlass from 'assets/devices/srf/IQ_GLASS_S.svg'
import iconSrfDoorWindow from 'assets/devices/srf/IQ_door_window_S.svg'
import iconSrfTranslator from 'assets/devices/srf/IQ_TRANSLATOR.svg'
import iconSrfTilt from 'assets/devices/srf/IQ_TILT.svg'
import iconSrfShock from 'assets/devices/srf/IQ_SHOCK.svg'
import iconSrfHeat from 'assets/devices/srf/IQ_HEAT.svg'
import iconSrfTemperature from 'assets/devices/srf/IQ_TEMPERATURE.svg'
import iconSrfFreeze from 'assets/devices/srf/IQ_FREEZE.svg'
import iconSrfOccupancySensor from 'assets/devices/srf/IQ_OCCUPANCY_SENSOR.svg'
import iconSrfSmokeM from 'assets/devices/srf/IQSmokeM.svg'
import iconSrfFlood from 'assets/devices/srf/IQ_FLOOD.svg'
import iconSrfDoorbell from 'assets/devices/srf/IQ_DOORBELL.svg'
import iconSrfContactM from 'assets/devices/srf/IQ_CONTACT_M.svg'
import iconIqKeypadPg from 'assets/devices/IQ_KEYPAD_PG_372.svg'
import iconWireFreeLcdKeypad from 'assets/devices/WIREFREE_LCD_KEYPAD_379.svg'

// that's out type
export const DEVICE_SUBTYPE_UNDEFINED = 'UNDEFINED'

// Please carry alphabet order of this list!
// Sorting is very helpful when maintaining this list
export const DEVICE_SUBTYPE_AC_OUTDOOR = 'AC_OUTDOOR'
export const DEVICE_SUBTYPE_BASIC_KEYFOB = 'BASIC_KEYFOB'
export const DEVICE_SUBTYPE_BASIC_REPEATER = 'BASIC_REPEATER'
export const DEVICE_SUBTYPE_BIG = 'BIG'
export const DEVICE_SUBTYPE_BUILT_IN_GLASS_BREAK = 'BUILT_IN_GLASS_BREAK'
export const DEVICE_SUBTYPE_BUILT_BUILT_IN_MOTION = 'BUILT_IN_MOTION'
export const DEVICE_SUBTYPE_CO = 'CO'
export const DEVICE_SUBTYPE_CONTACT = 'CONTACT'
export const DEVICE_SUBTYPE_CONTACT_4_IN = 'CONTACT_4_IN'
export const DEVICE_SUBTYPE_CONTACT_AUX = 'CONTACT_AUX'
export const DEVICE_SUBTYPE_CONTACT_IOS = 'CONTACT_IOS'
export const DEVICE_SUBTYPE_CONTACT_V = 'CONTACT_V'
export const DEVICE_SUBTYPE_COMBO_NBIOT = 'COMBO_NBIOT'
export const DEVICE_SUBTYPE_CURTAIN = 'CURTAIN'
export const DEVICE_SUBTYPE_DSC_KEYFOB_1 = 'DSC_KEYFOB_1'
export const DEVICE_SUBTYPE_DSC_KEYFOB_2 = 'DSC_KEYFOB_2'
export const DEVICE_SUBTYPE_DSC_SMARTCOMM_PANEL = 'DSC_SMARTCOMM_PANEL'
export const DEVICE_SUBTYPE_FLAT_PIR_SMART = 'FLAT_PIR_SMART'
export const DEVICE_SUBTYPE_FLAT_PIR_PG_PLUS = 'FLAT_PIR_PG_PLUS'
export const DEVICE_SUBTYPE_FLOOD = 'FLOOD'
export const DEVICE_SUBTYPE_FLOOD_PROBE = 'FLOOD_PROBE'
export const DEVICE_SUBTYPE_GAS = 'GAS'
export const DEVICE_SUBTYPE_GENERIC_COMMUNICATOR = 'GENERIC_COMMUNICATOR'
export const DEVICE_SUBTYPE_GENERIC_EXPANDER33 = 'GENERIC_EXPANDER33'
export const DEVICE_SUBTYPE_GENERIC_FIREBELL = 'GENERIC_FIREBELL'
export const DEVICE_SUBTYPE_GENERIC_HSMX56K = 'GENERIC_HSMX56K'
export const DEVICE_SUBTYPE_GENERIC_HW_IOV = 'GENERIC_HW_IOV'
export const DEVICE_SUBTYPE_GENERIC_HW_ZONE = 'GENERIC_HW_ZONE'
export const DEVICE_SUBTYPE_GENERIC_PGM = 'GENERIC_PGM'
export const DEVICE_SUBTYPE_PGM_ON_SMART_COMM = 'PGM_ON_SMART_COMM'
export const DEVICE_SUBTYPE_GENERIC_ZONE = 'GENERIC_ZONE'
export const DEVICE_SUBTYPE_GENERIC_PRINTER = 'GENERIC_PRINTER'
export const DEVICE_SUBTYPE_GENERIC_PROXY_TAG = 'GENERIC_PROXY_TAG'
export const DEVICE_SUBTYPE_GLASS_BREAK = 'GLASS_BREAK'
export const DEVICE_SUBTYPE_GLASS_BREAK_SMART = 'GLASS_BREAK_SMART'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL =
    'HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33 =
    'HW_ZONE_CONNECTED_TO_EXPANDER33'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV =
    'HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV'
export const DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV =
    'HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV'
export const DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER = 'HW_ZONE_ON_8_IO_EXPANDER'
export const DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER = 'HW_ZONE_ON_8_ZONE_EXPANDER'
export const DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD = 'HW_ZONE_ON_KEYPAD'
export const DEVICE_SUBTYPE_INDOOR = 'INDOOR'
export const DEVICE_SUBTYPE_KEYFOB_ARM_LED = 'KEYFOB_ARM_LED'
export const DEVICE_SUBTYPE_KEYPAD = 'KEYPAD'
export const DEVICE_SUBTYPE_LCD_KEYPAD = 'LCD_KEYPAD'
export const DEVICE_SUBTYPE_LCD_PRG_KEYPAD = 'LCD_PRG_KEYPAD'
export const DEVICE_SUBTYPE_LOCAL_PGM_ON_KEYPAD = 'LOCAL_PGM_ON_KEYPAD'
export const DEVICE_SUBTYPE_MC303_VANISH = 'MC303_VANISH'
export const DEVICE_SUBTYPE_MOTION = 'MOTION'
export const DEVICE_SUBTYPE_MOTION_CAMERA = 'MOTION_CAMERA'
export const DEVICE_SUBTYPE_MOTION_DUAL = 'MOTION_DUAL'
export const DEVICE_SUBTYPE_MOTION_ENERGY = 'MOTION_ENERGY'
export const DEVICE_SUBTYPE_MOTION_OUTDOOR = 'MOTION_OUTDOOR'
export const DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA = 'MOTION_OUTDOOR_CAMERA'
export const DEVICE_SUBTYPE_MOTION_V_ANTIMASK = 'MOTION_V_ANTIMASK'
export const DEVICE_SUBTYPE_NEO_16_LED_KEYPAD = 'NEO_16_LED_KEYPAD'
export const DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE = 'NEO_1A_POWER_SUPPLY_MODULE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD = 'NEO_2X16_LCD_KEYPAD'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE =
    'NEO_2X16_LCD_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX =
    'NEO_2X16_LCD_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD = 'NEO_2X16_LCD_RFK_KEYPAD'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE =
    'NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX =
    'NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE = 'NEO_3A_POWER_SUPPLY_MODULE'
export const DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE =
    'NEO_4_HIGH_CURRENT_OUTPUT_MODULE'
export const DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE = 'NEO_8_I/O_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE = 'NEO_8_OUTPUT_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE = 'NEO_8_ZONE_EXPANDER_MODULE'
export const DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE =
    'NEO_AUDIO_ALARM_VERIFICATION_MODULE'
export const DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING =
    'NEO_AUDIO_MODULE_WITH_RECORDING'
export const DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE = 'NEO_CORBUS_REPEATER_MODULE'
export const DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE = 'NEO_DOOR_CONTROL_MODULE'
export const DEVICE_SUBTYPE_NEO_ICON_KEYPAD = 'NEO_ICON_KEYPAD'
export const DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX = 'NEO_ICON_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD = 'NEO_ICON_RFK_KEYPAD'
export const DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX =
    'NEO_ICON_RFK_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE = 'NEO_MX_EXPANSION_MODULE'
export const DEVICE_SUBTYPE_NEO_PANEL = 'NEO_PANEL'
export const DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE =
    'NEO_POWERG_WIRELESS_TRANCEIVER_MODULE'
export const DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE =
    'NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE'
export const DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX =
    'NEO_TOUCHSCREEN_KEYPAD_WITH_PROX'
export const DEVICE_SUBTYPE_OUTDOOR = 'OUTDOOR'
export const DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT = 'OUTDOOR_CONTACT_NBIOT'
export const DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE =
    'PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE'
export const DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER = 'PGM_ON_8_OUTPUT_EXPANDER'
export const DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER = 'PGM_ON_CORBUS_REPEATER'
export const DEVICE_SUBTYPE_PGM_ON_EXPANDER33 = 'PGM_ON_EXPANDER33'
export const DEVICE_SUBTYPE_PGM_ON_HW_IOV = 'PGM_ON_HW_IOV'
export const DEVICE_SUBTYPE_PGM_ON_KEYPAD = 'PGM_ON_KEYPAD'
export const DEVICE_SUBTYPE_PGM_ON_PANEL = 'PGM_ON_PANEL'
export const DEVICE_SUBTYPE_PGM_ON_ZONE = 'PGM_ON_ZONE'
export const DEVICE_SUBTYPE_POWER_LINK = 'POWER_LINK'
export const DEVICE_SUBTYPE_POWERG_RADIO = 'POWERG_RADIO'
export const DEVICE_SUBTYPE_PROXIMITY_KEYPAD = 'PROXIMITY_KEYPAD'
export const DEVICE_SUBTYPE_PSP_CONTACT = 'PSP_CONTACT'
export const DEVICE_SUBTYPE_RECESSED = 'RECESSED'
export const DEVICE_SUBTYPE_SHOCK = 'SHOCK'
export const DEVICE_SUBTYPE_SHOCK_AUX = 'SHOCK_AUX'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK = 'SHOCK_CONTACT_AUX_ANTIMASK'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_G2 = 'SHOCK_CONTACT_G2'
export const DEVICE_SUBTYPE_SHOCK_CONTACT_G3 = 'SHOCK_CONTACT_G3'
export const DEVICE_SUBTYPE_SINGLE_BUTTON = 'SINGLE_BUTTON'
export const DEVICE_SUBTYPE_SMALL = 'SMALL'
export const DEVICE_SUBTYPE_SMOKE = 'SMOKE'
export const DEVICE_SUBTYPE_SMOKE_HEAT = 'SMOKE_HEAT'
export const DEVICE_SUBTYPE_SMOKE_HEAT_APOLLO = 'HEAT_APOLLO'
export const DEVICE_SUBTYPE_SMOKE_OR_HEAT = 'SMOKE_OR_HEAT'
export const DEVICE_SUBTYPE_SRF_RADIO = 'SRF_RADIO'
export const DEVICE_SUBTYPE_SOUNDER = 'SOUNDER'
export const DEVICE_SUBTYPE_S_CEILING_LR = 'S_CEILING_LR'
export const DEVICE_SUBTYPE_S_CEILING_SR = 'S_CEILING_SR'
export const DEVICE_SUBTYPE_S_CO_SENSOR = 'S_CO_SENSOR'
export const DEVICE_SUBTYPE_S_OUT_CURTAIN = 'S_OUT_CURTAIN'
export const DEVICE_SUBTYPE_S_OUT_MC_1IN = 'S_OUT_MC_1IN'
export const DEVICE_SUBTYPE_S_SMOKE_AND_HEAT = 'S_SMOKE_AND_HEAT'
export const DEVICE_SUBTYPE_TEMPERATURE = 'TEMPERATURE'
export const DEVICE_SUBTYPE_TEMPERATURE_PG_PLUS = 'TEMPERATURE_PG_PLUS'
export const DEVICE_SUBTYPE_TWO_BUTTON = 'TWO_BUTTON'
export const DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS =
    'UNIVERSAL_TRANSMITTER_2_INPUTS'
export const DEVICE_SUBTYPE_VISONIC_PANEL = 'VISONIC_PANEL'
export const DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD =
    'WIRELESS_FULL_MESSAGE_LCD_KEYPAD'
export const DEVICE_SUBTYPE_WIRELESS_VALVE = 'WIRELESS_VALVE'
export const DEVICE_SUBTYPE_ZIGBEE_RADIO = 'ZIGBEE_RADIO'

export const DEVICE_SUBTYPE_ARM_DISARM_STATUS = 'ARM_DISARM_STATUS'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_1 = 'PARTIAL_ARM_DISARM_STATUS_1'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_2 = 'PARTIAL_ARM_DISARM_STATUS_2'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_3 = 'PARTIAL_ARM_DISARM_STATUS_3'
export const DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_4 = 'PARTIAL_ARM_DISARM_STATUS_4'
export const DEVICE_SUBTYPE_ALARM_STATUS = 'ALARM_STATUS'
export const DEVICE_SUBTYPE_TAMPER_STATUS = 'TAMPER_STATUS'
export const DEVICE_SUBTYPE_FAULT_STATUS = 'FAULT_STATUS'
export const DEVICE_SUBTYPE_AUTOMATION_INPUT = 'AUTOMATION_INPUT'

export const DEVICE_SUBTYPE_DUALPATH_ARM_DISARM = 'DUALPATH_ARM_DISARM'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_1 = 'DUALPATH_PARTIAL_ARM_1'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_2 = 'DUALPATH_PARTIAL_ARM_2'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_3 = 'DUALPATH_PARTIAL_ARM_3'
export const DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_4 = 'DUALPATH_PARTIAL_ARM_4'
export const DEVICE_SUBTYPE_DUALPATH_ARM_ONLY = 'DUALPATH_ARM_ONLY'
export const DEVICE_SUBTYPE_DUALPATH_DISARM_ONLY = 'DUALPATH_DISARM_ONLY'
export const DEVICE_SUBTYPE_DUALPATH_AUTOMATION_BUTTON = 'DUALPATH_AUTOMATION_BUTTON'

export const DEVICE_SUBTIPE_GATEWAY_PANEL = 'GATEWAY_PANEL'

export const DEVICE_SUBTYPE_ZWAVE_THERMOSTAT = 'ZWAVE_THERMOSTAT'
export const DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK = 'ZWAVE_DOOR_LOCK'
export const DEVICE_SUBTYPE_ZWAVE_LIGHT = 'ZWAVE_LIGHT'
export const DEVICE_SUBTYPE_ZWAVE_WATER_VALVE = 'ZWAVE_WATER_VALVE'
export const DEVICE_SUBTYPE_ZWAVE_THERMOMETER = 'ZWAVE_THERMOMETER'
export const DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP = 'ZWAVE_ENERGY_CLAMP'
export const DEVICE_SUBTYPE_ZWAVE_RADIO = 'ZWAVE_RADIO'
export const DEVICE_SUBTYPE_ZWAVE_REPEATER = 'ZWAVE_REPEATER'
export const DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR = 'ZWAVE_GARAGE_DOOR'
export const DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN = 'ZWAVE_EXTERNAL_SIREN'
export const DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET = 'ZWAVE_SMART_SOCKET'
export const DEVICE_SUBTYPE_ZWAVE_DIMMER = 'ZWAVE_DIMMER'
export const DEVICE_SUBTYPE_BT_PHONE = 'BT_PHONE'
export const DEVICE_SUBTYPE_BT_SPEAKER = 'BT_SPEAKER'
export const DEVICE_SUBTYPE_CONTACT_AUX_PG_PLUS = 'CONTACT_AUX_PG_PLUS'
export const DEVICE_SUBTYPE_VANISHING_V2_PG_PLUS = 'VANISHING_V2_PG_PLUS'
export const DEVICE_SUBTYPE_SHORT_RANGE_IR_RECEIVER = 'SHORT_RANGE_IR_RECEIVER'
export const DEVICE_SUBTYPE_SHORT_RANGE_IR_TRANSMITTER = 'SHORT_RANGE_IR_TRANSMITTER'
export const DEVICE_SUBTYPE_LONG_RANGE_IR_RECEIVER = 'LONG_RANGE_IR_RECEIVER'
export const DEVICE_SUBTYPE_LONG_RANGE_IR_TRANSMITTER = 'LONG_RANGE_IR_TRANSMITTER'
export const DEVICE_SUBTYPE_CONTACT_PSP_PG_PLUS = 'CONTACT_PSP_PG_PLUS'
export const DEVICE_SUBTYPE_SMART_GLASS_BREAK_PG_PLUS = 'SMART_GLASS_BREAK_PG_PLUS'
export const DEVICE_SUBTYPE_WIRED_TO_WIRELESS = 'WIRED_TO_WIRELESS'
export const DEVICE_SUBTYPE_COMMON_KEYFOB = 'KEYFOB'
export const DEVICE_SUBTYPE_ETHERNET_CARD = 'ETHERNET_CARD'
export const DEVICE_SUBTYPE_SMART_COMMUNICATOR = 'SMART_COMMUNICATOR'
export const DEVICE_SUBTYPE_VIRTUAL_KEYPAD = 'VIRTUAL_KEYPAD'
export const DEVICE_SUBTYPE_SMC_HAD_ON_SMART_COMM = 'SMC_HAD_ON_SMART_COMM'
export const DEVICE_SUBTYPE_SMC_GENERIC_IO_TERMINAL = 'SMC_GENERIC_IO_TERMINAL'
export const DEVICE_SUBTYPE_IQ_REMOTE_PG = 'IQ_REMOTE_PG'
export const DEVICE_SUBTYPE_IQ_REMOTE = 'IQ_REMOTE'
export const DEVICE_SUBTYPE_IP_CAMERA_PROVISIONING = 'IP_CAMERA_PROVISIONING'
export const DEVICE_SUBTYPE_IP_CAMERA_RTSP = 'IP_CAMERA_RTSP'
export const DEVICE_SUBTYPE_PG_PLUS_DOOR_LOCK = 'PG_PLUS_DOOR_LOCK'
export const DEVICE_SUBTYPE_INDOOR_PIR_PG_PLUS = 'INDOOR_PIR_PG_PLUS'
export const DEVICE_SUBTYPE_REPEATER_PG_PLUS = 'REPEATER_PG_PLUS'
export const DEVICE_SUBTYPE_COMBO_DETECTOR_APOLLO_PG_PLUS =
    'COMBO_DETECTOR_APPOLLO_PG_PLUS'
export const DEVICE_SUBTYPE_YALE_DOORLOCK_L2_PG_PLUS = 'YALE_DOORLOCK_L2_PG_PLUS'
export const DEVICE_SUBTYPE_YALE_DOORLOCK_L3_PG_PLUS = 'YALE_DOORLOCK_L3_PG_PLUS'
export const DEVICE_SUBTYPE_IQ_SMART_DOORLOCK_PG_PLUS = 'IQ_SMART_DOORLOCK_PG_PLUS'
export const DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L2 = 'ZONE_ON_YALE_DOORLOCK_L2'
export const DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L2 = 'KEYPAD_ON_YALE_DOORLOCK_L2'
export const DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L3 = 'ZONE_ON_YALE_DOORLOCK_L3'
export const DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L3 = 'KEYPAD_ON_YALE_DOORLOCK_L3'
export const DEVICE_SUBTYPE_LEEDARSON_LIGHT_SWITCH_PG_PLUS =
    'LEEDARSON_LIGHT_SWITCH_PG_PLUS'
export const DEVICE_SUBTYPE_LEEDARSON_DIMMER_SWITCH_PG_PLUS =
    'LEEDARSON_DIMMER_SWITCH_PG_PLUS'
export const DEVICE_SUBTYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS =
    'LEEDARSON_INDOOR_AC_PLUG_PG_PLUS'
export const DEVICE_SUBTYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS =
    'LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS'
export const DEVICE_SUBTYPE_LEEDARSON_SOCKET_PG_PLUS = 'LEEDARSON_SOCKET_PG_PLUS'
export const DEVICE_SUBTYPE_IQ_GLASS_BREAK = 'IQ_GLASS_BREAK'
export const DEVICE_SUBTYPE_IQ_CONTACT = 'IQ_CONTACT'
export const DEVICE_SUBTYPE_IQ_MOTION = 'IQ_MOTION'
export const DEVICE_SUBTYPE_IQ_PENDANT = 'IQ_PENDANT'
export const DEVICE_SUBTYPE_IQ_SMOKE = 'IQ_SMOKE'
export const DEVICE_SUBTYPE_IQ_CARBON = 'IQ_CARBON'
export const DEVICE_SUBTYPE_IQ_HEAT = 'IQ_HEAT'
export const DEVICE_SUBTYPE_IQ_HIGH_TEMPERATURE = 'IQ_HIGH_TEMPERATURE'
export const DEVICE_SUBTYPE_IQ_FLOOD = 'IQ_FLOOD'
export const DEVICE_SUBTYPE_IQ_SHOCK = 'IQ_SHOCK'
export const DEVICE_SUBTYPE_IQ_FREEZE = 'IQ_FREEZE'
export const DEVICE_SUBTYPE_IQ_TILT = 'IQ_TILT'
export const DEVICE_SUBTYPE_IQ_DOORBELL = 'IQ_DOORBELL'
export const DEVICE_SUBTYPE_IQ_SMOKE_M = 'IQ_SMOKE_M'
export const DEVICE_SUBTYPE_IQ_CONTACT_M = 'IQ_CONTACT_M'
export const DEVICE_SUBTYPE_IQ_OCCUPANCY_SENSOR = 'IQ_OCCUPANCY_SENSOR'
export const DEVICE_SUBTYPE_IQ_KEYFOB = 'IQ_KEYFOB'
export const DEVICE_SUBTYPE_IQ_KEYPAD = 'IQ_KEYPAD'
export const DEVICE_SUBTYPE_IQ_SIREN = 'IQ_SIREN'
export const DEVICE_SUBTYPE_IQ_TAKEOVER_MODULE = 'IQ_TAKEOVER_MODULE'
export const DEVICE_SUBTYPE_IQ_REPEATER = 'IQ_REPEATER'
export const DEVICE_SUBTYPE_IQ_KEYPAD_PG = 'IQ_KEYPAD_PG'
export const DEVICE_SUBTYPE_WIREFREE_LCD_KEYPAD = 'WIREFREE_LCD_KEYPAD'
export const DEVICE_SUBTYPE_IQ_TAMPER_SENSOR = 'IQ_TAMPER_SENSOR'

export const srfDevices = [
    DEVICE_SUBTYPE_IQ_GLASS_BREAK,
    DEVICE_SUBTYPE_IQ_CONTACT,
    DEVICE_SUBTYPE_IQ_MOTION,
    DEVICE_SUBTYPE_IQ_PENDANT,
    DEVICE_SUBTYPE_IQ_SMOKE,
    DEVICE_SUBTYPE_IQ_CARBON,
    DEVICE_SUBTYPE_IQ_HEAT,
    DEVICE_SUBTYPE_IQ_HIGH_TEMPERATURE,
    DEVICE_SUBTYPE_IQ_FLOOD,
    DEVICE_SUBTYPE_IQ_SHOCK,
    DEVICE_SUBTYPE_IQ_FREEZE,
    DEVICE_SUBTYPE_IQ_TILT,
    DEVICE_SUBTYPE_IQ_DOORBELL,
    DEVICE_SUBTYPE_IQ_SMOKE_M,
    DEVICE_SUBTYPE_IQ_CONTACT_M,
    DEVICE_SUBTYPE_IQ_OCCUPANCY_SENSOR,
    DEVICE_SUBTYPE_IQ_KEYFOB,
    DEVICE_SUBTYPE_IQ_KEYPAD,
    DEVICE_SUBTYPE_IQ_SIREN,
    DEVICE_SUBTYPE_IQ_TAKEOVER_MODULE,
    DEVICE_SUBTYPE_IQ_REPEATER,
    DEVICE_SUBTYPE_IQ_TAMPER_SENSOR,
]

export const srfSensorsSubtypesMap = [
    DEVICE_SUBTYPE_IQ_GLASS_BREAK,
    DEVICE_SUBTYPE_IQ_CONTACT,
    DEVICE_SUBTYPE_IQ_MOTION,
    DEVICE_SUBTYPE_IQ_SMOKE,
    DEVICE_SUBTYPE_IQ_CARBON,
    DEVICE_SUBTYPE_IQ_FLOOD,
    DEVICE_SUBTYPE_IQ_SHOCK,
    DEVICE_SUBTYPE_IQ_HEAT,
    DEVICE_SUBTYPE_IQ_HIGH_TEMPERATURE,
    DEVICE_SUBTYPE_IQ_FREEZE,
    DEVICE_SUBTYPE_IQ_TILT,
    DEVICE_SUBTYPE_IQ_DOORBELL,
    DEVICE_SUBTYPE_IQ_SMOKE_M,
    DEVICE_SUBTYPE_IQ_CONTACT_M,
    DEVICE_SUBTYPE_IQ_OCCUPANCY_SENSOR,
    DEVICE_SUBTYPE_IQ_TAMPER_SENSOR,
]

export const zWaveDevices = [
    DEVICE_SUBTYPE_ZWAVE_THERMOSTAT,
    DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK,
    DEVICE_SUBTYPE_ZWAVE_LIGHT,
    DEVICE_SUBTYPE_ZWAVE_WATER_VALVE,
    DEVICE_SUBTYPE_ZWAVE_THERMOMETER,
    DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP,
    DEVICE_SUBTYPE_ZWAVE_RADIO,
    DEVICE_SUBTYPE_ZWAVE_REPEATER,
    DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR,
    DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN,
    DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET,
    DEVICE_SUBTYPE_ZWAVE_DIMMER,
]

export const deviceSubtypeMap = new Map([
    [DEVICE_SUBTYPE_UNDEFINED, () => __('Undefined')],
    [DEVICE_SUBTYPE_AC_OUTDOOR, () => __('AC outdoor')],
    [DEVICE_SUBTYPE_BASIC_KEYFOB, () => __('Basic keyfob')],
    [DEVICE_SUBTYPE_BASIC_REPEATER, () => __('Basic repeater')],
    [DEVICE_SUBTYPE_BIG, () => __('Big')],
    [DEVICE_SUBTYPE_BUILT_IN_GLASS_BREAK, () => __('Built in glass break')],
    [DEVICE_SUBTYPE_BUILT_BUILT_IN_MOTION, () => __('Built in motion')],
    [DEVICE_SUBTYPE_CO, () => __('CO')],
    [DEVICE_SUBTYPE_CONTACT, () => __('Contact')],
    [DEVICE_SUBTYPE_CONTACT_4_IN, () => __('Contact 4 in')],
    [DEVICE_SUBTYPE_CONTACT_AUX, () => __('Contact aux')],
    [DEVICE_SUBTYPE_CONTACT_IOS, () => __('Contact ios')],
    [DEVICE_SUBTYPE_CONTACT_V, () => __('Vanish contact')],
    [DEVICE_SUBTYPE_COMBO_NBIOT, () => __('Combo')],
    [DEVICE_SUBTYPE_CURTAIN, () => __('Curtain')],
    [DEVICE_SUBTYPE_DSC_KEYFOB_1, () => __('DSC keyfob 1')],
    [DEVICE_SUBTYPE_DSC_KEYFOB_2, () => __('DSC keyfob 2')],
    [DEVICE_SUBTYPE_DSC_SMARTCOMM_PANEL, () => __('Smart Communicator + DSC')],
    [DEVICE_SUBTYPE_FLAT_PIR_SMART, () => __('Flat pir smart')],
    [DEVICE_SUBTYPE_FLAT_PIR_PG_PLUS, () => __('Flat pir, PowerG +')],
    [DEVICE_SUBTYPE_FLOOD, () => __('Flood')],
    [DEVICE_SUBTYPE_FLOOD_PROBE, () => __('Flood probe')],
    [DEVICE_SUBTYPE_GAS, () => __('Gas')],
    [DEVICE_SUBTYPE_GENERIC_COMMUNICATOR, () => __('Generic Communicator')],
    [DEVICE_SUBTYPE_GENERIC_EXPANDER33, () => __('Expander 33')],
    [DEVICE_SUBTYPE_GENERIC_FIREBELL, () => __('Generic firebell')],
    [DEVICE_SUBTYPE_GENERIC_HSMX56K, () => __('Generic HSMX56K')],
    [DEVICE_SUBTYPE_GENERIC_HW_IOV, () => __('Generic HW IOV')],
    [DEVICE_SUBTYPE_GENERIC_HW_ZONE, () => __('Generic HW Zone')],
    [DEVICE_SUBTYPE_GENERIC_ZONE, () => __('Generic Zone')],
    [DEVICE_SUBTYPE_GENERIC_PGM, () => __('Generic PGM')],
    [DEVICE_SUBTYPE_PGM_ON_SMART_COMM, () => __('PGM on communicator')],
    [DEVICE_SUBTYPE_GENERIC_PRINTER, () => __('Generic printer')],
    [DEVICE_SUBTYPE_GENERIC_PROXY_TAG, () => __('Proximity Tag')],
    [DEVICE_SUBTYPE_GLASS_BREAK, () => __('Glass break')],
    [DEVICE_SUBTYPE_GLASS_BREAK_SMART, () => __('Glass break smart')],
    [
        DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL,
        () => __('HW zone connected directly to the panel'),
    ],
    [
        DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33,
        () => __('HW zone connected to expander33'),
    ],
    [
        DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV,
        () => __('HW zone connected to expander HW IOV'),
    ],
    [
        DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV,
        () => __('HW zone connected to WL expander IOV'),
    ],
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER, () => __('HW Zone on 8 I/O expander')],
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER, () => __('HW Zone on 8 zone expander')],
    [DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD, () => __('HW Zone on Keypad')],
    [DEVICE_SUBTYPE_INDOOR, () => __('Indoor')],
    [DEVICE_SUBTYPE_KEYFOB_ARM_LED, () => __('Keyfob arm LED')],
    [DEVICE_SUBTYPE_KEYPAD, () => __('Keypad')],
    [DEVICE_SUBTYPE_LCD_KEYPAD, () => __('LCD keypad')],
    [DEVICE_SUBTYPE_LCD_PRG_KEYPAD, () => __('LCD prg keypad')],
    [DEVICE_SUBTYPE_LOCAL_PGM_ON_KEYPAD, () => __('Local PGM on Keypad')],
    [DEVICE_SUBTYPE_MC303_VANISH, () => __('New vanish contact')],
    [DEVICE_SUBTYPE_MOTION, () => __('Motion')],
    [DEVICE_SUBTYPE_MOTION_CAMERA, () => __('Motion camera')],
    [DEVICE_SUBTYPE_MOTION_DUAL, () => __('Motion dual')],
    [DEVICE_SUBTYPE_MOTION_ENERGY, () => __('Motion energy')],
    [DEVICE_SUBTYPE_MOTION_OUTDOOR, () => __('Motion outdoor')],
    [DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA, () => __('Motion outdoor camera')],
    [DEVICE_SUBTYPE_MOTION_V_ANTIMASK, () => __('Motion v antimask')],
    [DEVICE_SUBTYPE_NEO_16_LED_KEYPAD, () => __('16 LED keypad')],
    [DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE, () => __('1A Power Supply module')],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD, () => __('2x16 LCD Keypad')],
    [
        DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE,
        () => __('Neo 2x16 LCD keypad with mifare'),
    ],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX, () => __('2x16 LCD keypad with prox')],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD, () => __('2x16 LCD RFK keypad')],
    [
        DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE,
        () => __('2x16 LCD RFK keypad with mifare'),
    ],
    [
        DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX,
        () => __('2x16 LCD RFK keypad with prox'),
    ],
    [DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE, () => __('3A Power Supply module')],
    [
        DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE,
        () => __('4 high current output module'),
    ],
    [DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE, () => __('8 I/O Expansion')],
    [DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE, () => __('8 Output Expander')],
    [DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE, () => __('8 Zone Expander')],
    [
        DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE,
        () => __('Audio Alarm Verifcation Module'),
    ],
    [
        DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING,
        () => __('Audio module with recording'),
    ],
    [DEVICE_SUBTYPE_NEO_CORBUS_REPEATER_MODULE, () => __('Corbus repeater module')],
    [DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE, () => __('Door control module')],
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD, () => __('Icon keypad')],
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX, () => __('Icon keypad with prox')],
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD, () => __('Icon RFK keypad')],
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX, () => __('Icon RFK keypad with prox')],
    [DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE, () => __('MX expansion module')],
    [DEVICE_SUBTYPE_NEO_PANEL, () => __('Neo Panel')],
    [
        DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE,
        () => __('Power-G transceiver module'),
    ],
    [
        DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE,
        () => __('Touchscreen keypad with mifare'),
    ],
    [
        DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX,
        () => __('Touchscreen keypad with prox'),
    ],
    [DEVICE_SUBTYPE_OUTDOOR, () => __('Outdoor')],
    [DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT, () => __('Outdoor contact NbIoT')],
    [
        DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE,
        () => __('PGM on 4 high current output module'),
    ],
    [DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER, () => __('PGM on 8 output expander')],
    [DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER, () => __('PGM on Corbus Repeater')],
    [DEVICE_SUBTYPE_PGM_ON_EXPANDER33, () => __('PGM on expander33')],
    [DEVICE_SUBTYPE_PGM_ON_HW_IOV, () => __('PGM on HW IOV')],
    [DEVICE_SUBTYPE_PGM_ON_KEYPAD, () => __('PGM on Keypad')],
    [DEVICE_SUBTYPE_PGM_ON_PANEL, () => __('PGM on panel')],
    [DEVICE_SUBTYPE_PGM_ON_ZONE, () => __('PGM on zone')],
    [DEVICE_SUBTYPE_POWER_LINK, () => __('Power link')],
    [DEVICE_SUBTYPE_POWERG_RADIO, () => __('PowerG radio')],
    [DEVICE_SUBTYPE_PROXIMITY_KEYPAD, () => __('Proximity keypad')],
    [DEVICE_SUBTYPE_PSP_CONTACT, () => __('PSP contact')],
    [DEVICE_SUBTYPE_RECESSED, () => __('Recessed contact')],
    [DEVICE_SUBTYPE_SHOCK, () => __('Shock')],
    [DEVICE_SUBTYPE_SHOCK_AUX, () => __('Shock aux')],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK, () => __('Shock contact aux antimask')],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G2, () => __('Shock contact g2')],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G3, () => __('Shock contact g3')],
    [DEVICE_SUBTYPE_SINGLE_BUTTON, () => __('Single button')],
    [DEVICE_SUBTYPE_SMALL, () => __('Small')],
    [DEVICE_SUBTYPE_SMOKE, () => __('Smoke')],
    [DEVICE_SUBTYPE_SMOKE_HEAT, () => __('Smoke heat')],
    [DEVICE_SUBTYPE_SMOKE_HEAT_APOLLO, () => __('Smoke heat Apollo')],
    [DEVICE_SUBTYPE_SMOKE_OR_HEAT, () => __('Smoke or heat')],
    [DEVICE_SUBTYPE_SRF_RADIO, () => __('SRF Radio')],
    [DEVICE_SUBTYPE_SOUNDER, () => __('Sounder')],
    [DEVICE_SUBTYPE_S_CEILING_LR, () => __('Smart Presence Detector (Long Range)')],
    [DEVICE_SUBTYPE_S_CEILING_SR, () => __('Smart Presence Detector (Short Range)')],
    [DEVICE_SUBTYPE_S_CO_SENSOR, () => __('Smart CO sensor')],
    [DEVICE_SUBTYPE_S_OUT_CURTAIN, () => __('Smart Outdoor PIR (Curtain) Detector')],
    [DEVICE_SUBTYPE_S_OUT_MC_1IN, () => __('Outdoor Contact Detector with 1 Aux input')],
    [DEVICE_SUBTYPE_S_SMOKE_AND_HEAT, () => __('Apollo Smoke and Heat Detector')],
    [DEVICE_SUBTYPE_TEMPERATURE, () => __('Temperature')],
    [DEVICE_SUBTYPE_TEMPERATURE_PG_PLUS, () => __('Temperature, PowerG +')],
    [DEVICE_SUBTYPE_TWO_BUTTON, () => __('Two button')],
    [DEVICE_SUBTYPE_ETHERNET_CARD, () => __('Ethernet')],
    [
        DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS,
        () => __('Universal transmitter 2 inputs'),
    ],
    [DEVICE_SUBTYPE_VISONIC_PANEL, () => __('Power Master Panel')],
    [
        DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD,
        () => __('Wireless full message LCD keypad'),
    ],
    [DEVICE_SUBTYPE_WIRELESS_VALVE, () => __('Wireless water valve')],
    [DEVICE_SUBTYPE_ZIGBEE_RADIO, () => __('ZigBee radio')],
    [DEVICE_SUBTYPE_ARM_DISARM_STATUS, () => __('Arm disarm status')],
    [DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_1, () => __('Partial arm disarm status 1')],
    [DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_2, () => __('Partial arm disarm status 2')],
    [DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_3, () => __('Partial arm disarm status 3')],
    [DEVICE_SUBTYPE_PARTIAL_ARM_DISARM_STATUS_4, () => __('Partial arm disarm status 4')],
    [DEVICE_SUBTYPE_ALARM_STATUS, () => __('Alarm status')],
    [DEVICE_SUBTYPE_TAMPER_STATUS, () => __('Tamper status')],
    [DEVICE_SUBTYPE_FAULT_STATUS, () => __('Fault status')],
    [DEVICE_SUBTYPE_AUTOMATION_INPUT, () => __('Automation input')],
    [DEVICE_SUBTYPE_DUALPATH_ARM_DISARM, () => __('Arm disarm')],
    [DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_1, () => __('Partial arm 1')],
    [DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_2, () => __('Partial arm 2')],
    [DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_3, () => __('Partial arm 3')],
    [DEVICE_SUBTYPE_DUALPATH_PARTIAL_ARM_4, () => __('Partial arm 4')],
    [DEVICE_SUBTYPE_DUALPATH_ARM_ONLY, () => __('Arm only')],
    [DEVICE_SUBTYPE_DUALPATH_DISARM_ONLY, () => __('Disarm only')],
    [DEVICE_SUBTYPE_DUALPATH_AUTOMATION_BUTTON, () => __('Automation button')],
    [DEVICE_SUBTYPE_ZWAVE_THERMOSTAT, () => __('Z-Wave Thermostat')],
    [DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK, () => __('Z-Wave Door Lock')],
    [DEVICE_SUBTYPE_ZWAVE_LIGHT, () => __('Z-Wave Light')],
    [DEVICE_SUBTYPE_ZWAVE_WATER_VALVE, () => __('Z-Wave Water Valve')],
    [DEVICE_SUBTYPE_ZWAVE_THERMOMETER, () => __('Z-Wave Thermometer')],
    [DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP, () => __('Z-Wave Energy Clamp')],
    [DEVICE_SUBTYPE_ZWAVE_RADIO, () => __('Z-Wave Radio')],
    [DEVICE_SUBTYPE_ZWAVE_REPEATER, () => __('Z-Wave Repeater')],
    [DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR, () => __('Z-Wave Garage Door')],
    [DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN, () => __('Z-Wave External Siren')],
    [DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET, () => __('Z-Wave Smart Socket')],
    [DEVICE_SUBTYPE_ZWAVE_DIMMER, () => __('Z-Wave Dimmer')],
    [DEVICE_SUBTYPE_BT_PHONE, () => __('Bluetooth Phone')],
    [DEVICE_SUBTYPE_BT_SPEAKER, () => __('Bluetooth Speaker')],
    [DEVICE_SUBTYPE_CONTACT_AUX_PG_PLUS, () => __('Contact aux, PowerG +')],
    [DEVICE_SUBTYPE_VANISHING_V2_PG_PLUS, () => __('Vanish contact, PowerG +')],
    [DEVICE_SUBTYPE_SHORT_RANGE_IR_RECEIVER, () => __('Short range IR receiver')],
    [DEVICE_SUBTYPE_SHORT_RANGE_IR_TRANSMITTER, () => __('Short range IR transmitter')],
    [DEVICE_SUBTYPE_LONG_RANGE_IR_RECEIVER, () => __('Long range IR receiver')],
    [DEVICE_SUBTYPE_LONG_RANGE_IR_TRANSMITTER, () => __('Long range IR transmitter')],
    [DEVICE_SUBTYPE_CONTACT_PSP_PG_PLUS, () => __('PSP contact, PowerG +')],
    [DEVICE_SUBTYPE_SMART_GLASS_BREAK_PG_PLUS, () => __('Glass break smart, PowerG +')],
    [DEVICE_SUBTYPE_WIRED_TO_WIRELESS, () => __('Wired to wireless')],
    [DEVICE_SUBTYPE_SMART_COMMUNICATOR, () => __('Smart Communicator')],
    [DEVICE_SUBTYPE_VIRTUAL_KEYPAD, () => __('Virtual Keypad')],
    [DEVICE_SUBTYPE_SMC_HAD_ON_SMART_COMM, () => __('PGM on USC')],
    [DEVICE_SUBTYPE_SMC_GENERIC_IO_TERMINAL, () => __('IO Terminal')],
    [DEVICE_SUBTYPE_IQ_REMOTE_PG, () => __('IQ remote PG')],
    [DEVICE_SUBTYPE_IQ_REMOTE, () => __('IQ remote')],
    [DEVICE_SUBTYPE_IP_CAMERA_PROVISIONING, () => __('Provisioning Camera')],
    [DEVICE_SUBTYPE_IP_CAMERA_RTSP, () => __('RTSP Camera')],
    [DEVICE_SUBTYPE_PG_PLUS_DOOR_LOCK, () => __('PowerG Automation Door Lock')],
    [DEVICE_SUBTYPE_REPEATER_PG_PLUS, () => __('Repeater, PowerG +')],
    [DEVICE_SUBTYPE_INDOOR_PIR_PG_PLUS, () => __('Indoor pir, PowerG +')],
    [
        DEVICE_SUBTYPE_COMBO_DETECTOR_APOLLO_PG_PLUS,
        () => __('Combo detector APOLLO, PowerG +'),
    ],
    [
        DEVICE_SUBTYPE_YALE_DOORLOCK_L2_PG_PLUS,
        () => __('Yale Doorlock (L2), PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_YALE_DOORLOCK_L3_PG_PLUS,
        () => __('Yale Doorlock (L3), PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_IQ_SMART_DOORLOCK_PG_PLUS,
        () => __('IQ Smart Door Lock, PowerG Automation'),
    ],
    [DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L2, () => __('Zone on Yale Doorlock L2')],
    [DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L2, () => __('Keypad on Yale Doorlock L2')],
    [DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L3, () => __('Zone on Yale Doorlock L3')],
    [DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L3, () => __('Keypad on Yale Doorlock L3')],
    [
        DEVICE_SUBTYPE_LEEDARSON_LIGHT_SWITCH_PG_PLUS,
        () => __('Leedarson Light Switch, PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_LEEDARSON_DIMMER_SWITCH_PG_PLUS,
        () => __('Leedarson Dimmer Switch, PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS,
        () => __('Leedarson Indoor AC Plug, PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS,
        () => __('Leedarson Outdoor AC Plug, PowerG Automation'),
    ],
    [
        DEVICE_SUBTYPE_LEEDARSON_SOCKET_PG_PLUS,
        () => __('Leedarson Socket, PowerG Automation'),
    ],
    [DEVICE_SUBTYPE_IQ_GLASS_BREAK, () => __('IQ Glass Break')],
    [DEVICE_SUBTYPE_IQ_CONTACT, () => __('IQ Door Window Contact')],
    [DEVICE_SUBTYPE_IQ_MOTION, () => __('IQ Motion')],
    [DEVICE_SUBTYPE_IQ_PENDANT, () => __('IQ Auxiliary Pendant')],
    [DEVICE_SUBTYPE_IQ_SMOKE, () => __('IQ Smoke Detector')],
    [DEVICE_SUBTYPE_IQ_CARBON, () => __('IQ CO Detector')],
    [DEVICE_SUBTYPE_IQ_HEAT, () => __('IQ Heat')],
    [DEVICE_SUBTYPE_IQ_HIGH_TEMPERATURE, () => __('IQ High Temperature')],
    [DEVICE_SUBTYPE_IQ_FLOOD, () => __('IQ Flood')],
    [DEVICE_SUBTYPE_IQ_SHOCK, () => __('IQ Shock')],
    [DEVICE_SUBTYPE_IQ_FREEZE, () => __('IQ Freeze')],
    [DEVICE_SUBTYPE_IQ_TILT, () => __('IQ Tilt')],
    [DEVICE_SUBTYPE_IQ_DOORBELL, () => __('IQ Doorbell')],
    [DEVICE_SUBTYPE_IQ_SMOKE_M, () => __('IQ Smoke M')],
    [DEVICE_SUBTYPE_IQ_CONTACT_M, () => __('IQ Contact M')],
    [DEVICE_SUBTYPE_IQ_OCCUPANCY_SENSOR, () => __('IQ Occupancy Sensor')],
    [DEVICE_SUBTYPE_IQ_KEYFOB, () => __('IQ Keyfob')],
    [DEVICE_SUBTYPE_IQ_KEYPAD, () => __('IQ Keypad')],
    [DEVICE_SUBTYPE_IQ_SIREN, () => __('IQ Siren')],
    [DEVICE_SUBTYPE_IQ_TAKEOVER_MODULE, () => __('IQ Takeover Module')],
    [DEVICE_SUBTYPE_IQ_REPEATER, () => __('IQ Repeater')],
    [DEVICE_SUBTYPE_IQ_KEYPAD_PG, () => __('IQ Keypad PG')],
    [DEVICE_SUBTYPE_WIREFREE_LCD_KEYPAD, () => __('Wirefree LCD Keypad')],
    [DEVICE_SUBTYPE_IQ_TAMPER_SENSOR, () => __('IQ Tamper Sensor')],
])

export function deviceSubtype(subtype, type = null) {
    if (deviceSubtypeMap.has(subtype)) {
        return deviceSubtypeMap.get(subtype)()
    }

    if (subtype === null && type) {
        return deviceType(type)
    }

    warn(`Unknown device subtype ${subtype}, type ${type}`)

    return __('Unknown device')
}

export const images = new Map([
    [DEVICE_SUBTYPE_UNDEFINED, iconUnknown],
    [DEVICE_SUBTYPE_AC_OUTDOOR, iconAcOutdoor],
    [DEVICE_SUBTYPE_BASIC_KEYFOB, iconBasicKeyfob],
    [DEVICE_SUBTYPE_BASIC_REPEATER, iconBasicRepeater],
    [DEVICE_SUBTYPE_BIG, null],
    [DEVICE_SUBTYPE_BUILT_IN_GLASS_BREAK, iconBuiltInGlassBreak],
    [DEVICE_SUBTYPE_BUILT_BUILT_IN_MOTION, iconBuiltInMotion],
    [DEVICE_SUBTYPE_CO, iconCo],
    [DEVICE_SUBTYPE_CONTACT, iconContact],
    [DEVICE_SUBTYPE_CONTACT_4_IN, iconContact4In],
    [DEVICE_SUBTYPE_CONTACT_AUX, iconContactAux],
    [DEVICE_SUBTYPE_CONTACT_IOS, iconContactIos],
    [DEVICE_SUBTYPE_CONTACT_V, iconContactV],
    [DEVICE_SUBTYPE_COMBO_NBIOT, iconComboNbiot],
    [DEVICE_SUBTYPE_PSP_CONTACT, iconPspContact],
    [DEVICE_SUBTYPE_CURTAIN, iconCurtain],
    [DEVICE_SUBTYPE_DSC_KEYFOB_1, iconDscKeyfob1],
    [DEVICE_SUBTYPE_DSC_KEYFOB_2, iconDscKeyfob2],
    [DEVICE_SUBTYPE_DSC_SMARTCOMM_PANEL, iconDscSmartcommPanel],
    [DEVICE_SUBTYPE_FLAT_PIR_SMART, iconFlatPirSmart],
    [DEVICE_SUBTYPE_FLAT_PIR_PG_PLUS, iconFlatPirPGPlus],
    [DEVICE_SUBTYPE_FLOOD, iconFlood],
    [DEVICE_SUBTYPE_FLOOD_PROBE, iconFlood],
    [DEVICE_SUBTYPE_GAS, iconGas],
    [DEVICE_SUBTYPE_GENERIC_COMMUNICATOR, iconGenericCommunicator],
    [DEVICE_SUBTYPE_GENERIC_EXPANDER33, iconGenericExpander33],
    [DEVICE_SUBTYPE_GENERIC_FIREBELL, null],
    [DEVICE_SUBTYPE_GENERIC_HSMX56K, null],
    [DEVICE_SUBTYPE_GENERIC_HW_IOV, iconGenericExpander33],
    [DEVICE_SUBTYPE_GENERIC_PGM, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_SMART_COMM, iconGenericPgm],
    [DEVICE_SUBTYPE_SMC_HAD_ON_SMART_COMM, iconGenericPgm],
    [DEVICE_SUBTYPE_SMC_GENERIC_IO_TERMINAL, iconGenericPgm],
    [DEVICE_SUBTYPE_GENERIC_ZONE, iconGenericZone],
    [DEVICE_SUBTYPE_GENERIC_PRINTER, null],
    [DEVICE_SUBTYPE_GENERIC_PROXY_TAG, iconGenericProxyTag],
    [DEVICE_SUBTYPE_GLASS_BREAK, iconGlassBreak],
    [DEVICE_SUBTYPE_GLASS_BREAK_SMART, iconGlassBreakSmart],
    [DEVICE_SUBTYPE_GLASS_BREAK_SMART, iconGlassBreakSmart],
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_DIRECTLY_TO_THE_PANEL, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_ON_KEYPAD, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER33, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_EXPANDER_HW_IOV, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_CONNECTED_TO_WL_EXPANDER_IOV, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_IO_EXPANDER, iconWiredZone],
    [DEVICE_SUBTYPE_HW_ZONE_ON_8_ZONE_EXPANDER, iconWiredZone],
    [DEVICE_SUBTYPE_INDOOR, iconIndoor],
    [DEVICE_SUBTYPE_INDOOR_PIR_PG_PLUS, iconIndoorPirPgPlus],
    [DEVICE_SUBTYPE_KEYFOB_ARM_LED, iconKeyfobArmLed],
    [DEVICE_SUBTYPE_KEYPAD, iconKeyfob],
    [DEVICE_SUBTYPE_LCD_KEYPAD, iconLcdKeypad],
    [DEVICE_SUBTYPE_LCD_PRG_KEYPAD, iconLcdPrgKeypad],
    [DEVICE_SUBTYPE_MC303_VANISH, iconMc303Vanish],
    [DEVICE_SUBTYPE_MOTION, iconMotion],
    [DEVICE_SUBTYPE_MOTION_CAMERA, iconMotionCamera],
    [DEVICE_SUBTYPE_MOTION_DUAL, iconMotionDual],
    [DEVICE_SUBTYPE_MOTION_ENERGY, null],
    [DEVICE_SUBTYPE_MOTION_OUTDOOR, iconMotionOutdoor],
    [DEVICE_SUBTYPE_MOTION_OUTDOOR_CAMERA, iconMotionOutdoorCamera],
    [DEVICE_SUBTYPE_MOTION_V_ANTIMASK, iconMotionDual],
    [DEVICE_SUBTYPE_NEO_16_LED_KEYPAD, iconNeo16LedKeypad],
    [DEVICE_SUBTYPE_NEO_1A_POWER_SUPPLY_MODULE, iconNeo1APowerSupplyModule],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_MIFARE, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_KEYPAD_WITH_PROX, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_MIFARE, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_2X16_LCD_RFK_KEYPAD_WITH_PROX, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_3A_POWER_SUPPLY_MODULE, iconNeo1APowerSupplyModule],
    [DEVICE_SUBTYPE_NEO_4_HIGH_CURRENT_OUTPUT_MODULE, iconNeo1APowerSupplyModule],
    [DEVICE_SUBTYPE_NEO_8_IO_EXPANDER_MODULE, iconNeo1APowerSupplyModule],
    [DEVICE_SUBTYPE_NEO_8_OUTPUT_EXPANDER_MODULE, iconGenZoneExpander],
    [DEVICE_SUBTYPE_NEO_8_ZONE_EXPANDER_MODULE, iconGenZoneExpander],
    [DEVICE_SUBTYPE_NEO_AUDIO_ALARM_VERIFICATION_MODULE, iconGenZoneExpander],
    [DEVICE_SUBTYPE_NEO_AUDIO_MODULE_WITH_RECORDING, iconGenZoneExpander],
    [DEVICE_SUBTYPE_NEO_DOOR_CONTROL_MODULE, null],
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_ICON_KEYPAD_WITH_PROX, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_ICON_RFK_KEYPAD_WITH_PROX, iconWireKeypad],
    [DEVICE_SUBTYPE_NEO_MX_EXPANSION_MODULE, null],
    [DEVICE_SUBTYPE_NEO_PANEL, iconNeo],
    [DEVICE_SUBTYPE_NEO_POWERG_WIRELESS_TRANCEIVER_MODULE, iconIndoor],
    [DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_MIFARE, iconTouchKp],
    [DEVICE_SUBTYPE_NEO_TOUCHSCREEN_KEYPAD_WITH_PROX, iconTouchKp],
    [DEVICE_SUBTYPE_OUTDOOR, iconOutdoor],
    [DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT, iconOutdoorContactNbiot],
    [DEVICE_SUBTYPE_PGM_ON_EXPANDER33, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_4_HIGH_CURRENT_OUTPUT_MODULE, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_8_OUTPUT_EXPANDER, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_CORBUS_REPEATER, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_HW_IOV, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_PANEL, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_ZONE, iconGenericPgm],
    [DEVICE_SUBTYPE_PGM_ON_KEYPAD, iconGenericPgm],
    [DEVICE_SUBTYPE_POWER_LINK, iconNeo1APowerSupplyModule],
    [DEVICE_SUBTYPE_POWERG_RADIO, iconPowerG],
    [DEVICE_SUBTYPE_PROXIMITY_KEYPAD, iconKeyfob],
    [DEVICE_SUBTYPE_RECESSED, iconRecessed],
    [DEVICE_SUBTYPE_S_CEILING_LR, iconLongRange],
    [DEVICE_SUBTYPE_S_CEILING_SR, iconSmartCeilingSr],
    [DEVICE_SUBTYPE_S_CO_SENSOR, iconSmartCoSensor],
    [DEVICE_SUBTYPE_S_OUT_CURTAIN, iconSmartOurCurtain],
    [DEVICE_SUBTYPE_S_OUT_MC_1IN, iconOutdoorContactNbiot],
    [DEVICE_SUBTYPE_S_SMOKE_AND_HEAT, iconSmartSmokeHeat],
    [DEVICE_SUBTYPE_SHOCK, null],
    [DEVICE_SUBTYPE_SHOCK_AUX, iconShockAux],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_AUX_ANTIMASK, iconShockAux],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G2, iconShockAux],
    [DEVICE_SUBTYPE_SHOCK_CONTACT_G3, iconShockAux],
    [DEVICE_SUBTYPE_SINGLE_BUTTON, iconSignalButton],
    [DEVICE_SUBTYPE_SMALL, null],
    [DEVICE_SUBTYPE_SMOKE, iconSmoke],
    [DEVICE_SUBTYPE_SMOKE_HEAT, iconSmokeHeat],
    [DEVICE_SUBTYPE_SMOKE_HEAT_APOLLO, iconSmokeHeat],
    [DEVICE_SUBTYPE_SMOKE_OR_HEAT, iconSmokeHeat],
    [DEVICE_SUBTYPE_SRF_RADIO, iconSrfRadio],
    [DEVICE_SUBTYPE_SOUNDER, iconIndoor],
    [DEVICE_SUBTYPE_TEMPERATURE, iconTemperature],
    [DEVICE_SUBTYPE_TEMPERATURE_PG_PLUS, iconTemperature],
    [DEVICE_SUBTYPE_TWO_BUTTON, iconTwoButton],
    [DEVICE_SUBTYPE_UNIVERSAL_TRANSMITTER_2_INPUTS, null],
    [DEVICE_SUBTYPE_VISONIC_PANEL, iconVisonicPanel],
    [DEVICE_SUBTYPE_WIRELESS_FULL_MESSAGE_LCD_KEYPAD, iconWireKeypad],
    [DEVICE_SUBTYPE_WIRELESS_VALVE, iconWirelessValve],
    [DEVICE_SUBTYPE_ZIGBEE_RADIO, iconPowerG],
    [DEVICE_SUBTYPE_ZWAVE_THERMOSTAT, iconZwaveThermostat],
    [DEVICE_SUBTYPE_ZWAVE_DOOR_LOCK, iconZwaveDoorLock],
    [DEVICE_SUBTYPE_ZWAVE_LIGHT, iconZwaveLight],
    [DEVICE_SUBTYPE_ZWAVE_WATER_VALVE, iconZwaveWaterValve],
    [DEVICE_SUBTYPE_ZWAVE_THERMOMETER, iconZwaveThermometer],
    [DEVICE_SUBTYPE_ZWAVE_ENERGY_CLAMP, iconZwaveEnergyClamp],
    [DEVICE_SUBTYPE_ZWAVE_RADIO, iconPowerG],
    [DEVICE_SUBTYPE_ZWAVE_REPEATER, iconZwaveRepeater],
    [DEVICE_SUBTYPE_ZWAVE_GARAGE_DOOR, iconZwaveGarageDoor],
    [DEVICE_SUBTYPE_ZWAVE_EXTERNAL_SIREN, iconZwaveExternalSiren],
    [DEVICE_SUBTYPE_ZWAVE_SMART_SOCKET, iconZwaveSmartSocket],
    [DEVICE_SUBTYPE_ZWAVE_DIMMER, iconZwaveDimmer],
    [DEVICE_SUBTYPE_BT_PHONE, iconBtPhone],
    [DEVICE_SUBTYPE_BT_SPEAKER, iconBtSpeaker],
    [DEVICE_SUBTYPE_CONTACT_AUX_PG_PLUS, iconContactAuxPgPlus],
    [DEVICE_SUBTYPE_VANISHING_V2_PG_PLUS, iconContactV],
    [DEVICE_SUBTYPE_SHORT_RANGE_IR_RECEIVER, iconShortRangeIrReceiver],
    [DEVICE_SUBTYPE_SHORT_RANGE_IR_TRANSMITTER, iconShortRangeIrTransmitter],
    [DEVICE_SUBTYPE_LONG_RANGE_IR_RECEIVER, iconLongRangeIrReceiver],
    [DEVICE_SUBTYPE_LONG_RANGE_IR_TRANSMITTER, iconLongRangeIrTransmitter],
    [DEVICE_SUBTYPE_CONTACT_PSP_PG_PLUS, iconContactPspPgPlus],
    [DEVICE_SUBTYPE_SMART_GLASS_BREAK_PG_PLUS, iconSmartGlassBreakPgPlus],
    [DEVICE_SUBTYPE_WIRED_TO_WIRELESS, iconWiredToWireless],
    [DEVICE_SUBTYPE_ETHERNET_CARD, iconEthernetCard],
    [DEVICE_SUBTYPE_SMART_COMMUNICATOR, iconSmartCommunicator],
    [DEVICE_SUBTYPE_VIRTUAL_KEYPAD, iconVirtualKeypad],
    [DEVICE_SUBTYPE_IQ_REMOTE_PG, iconIQRemotePG],
    [DEVICE_SUBTYPE_IQ_REMOTE, iconIQRemote],
    [DEVICE_SUBTYPE_IP_CAMERA_PROVISIONING, iconCameraProvisioning],
    [DEVICE_SUBTYPE_IP_CAMERA_RTSP, iconCameraRtsp],
    [DEVICE_SUBTYPE_PG_PLUS_DOOR_LOCK, iconZwaveDoorLock],
    [DEVICE_SUBTYPE_COMBO_DETECTOR_APOLLO_PG_PLUS, iconAppolloPgPlus],
    [DEVICE_SUBTYPE_REPEATER_PG_PLUS, iconRepeaterPgPlus],
    [DEVICE_SUBTYPE_YALE_DOORLOCK_L2_PG_PLUS, iconYaleDoorlockL2PgPlus],
    [DEVICE_SUBTYPE_YALE_DOORLOCK_L3_PG_PLUS, iconYaleDoorlockL3PgPlus],
    [DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L2, iconYaleDoorlockL2PgPlus],
    [DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L2, iconYaleDoorlockL2PgPlus],
    [DEVICE_SUBTYPE_ZONE_ON_YALE_DOORLOCK_L3, iconYaleDoorlockL3PgPlus],
    [DEVICE_SUBTYPE_KEYPAD_ON_YALE_DOORLOCK_L3, iconYaleDoorlockL3PgPlus],
    [DEVICE_SUBTYPE_IQ_SMART_DOORLOCK_PG_PLUS, iconIqSmartDoorlockPgPlus],
    [DEVICE_SUBTYPE_LEEDARSON_LIGHT_SWITCH_PG_PLUS, iconLeedarsonLightSwitchPgPlus],
    [DEVICE_SUBTYPE_LEEDARSON_DIMMER_SWITCH_PG_PLUS, iconLeedarsonDimmerSwitchPgPlus],
    [DEVICE_SUBTYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS, iconLeedarsonIndoorAcPlusPgPlus],
    [DEVICE_SUBTYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS, iconLeedarsonOutdoorAcPlusPgPlus],
    [DEVICE_SUBTYPE_LEEDARSON_SOCKET_PG_PLUS, iconLeedarsonSocketPlusPgPlus],
    [DEVICE_SUBTYPE_IQ_TAKEOVER_MODULE, iconSrfTakeoverModule],
    [DEVICE_SUBTYPE_IQ_PENDANT, iconSrfAuxiliaryPendant],
    [DEVICE_SUBTYPE_IQ_KEYFOB, iconSrfKeyfob],
    [DEVICE_SUBTYPE_IQ_MOTION, iconSrfMotion],
    [DEVICE_SUBTYPE_IQ_KEYPAD, iconSrfKeypad],
    [DEVICE_SUBTYPE_IQ_CARBON, iconSrfCarbone],
    [DEVICE_SUBTYPE_IQ_SMOKE, iconSrfSmoke],
    [DEVICE_SUBTYPE_IQ_GLASS_BREAK, iconSrfGlass],
    [DEVICE_SUBTYPE_IQ_CONTACT, iconSrfDoorWindow],
    [DEVICE_SUBTYPE_IQ_REPEATER, iconSrfTranslator],
    [DEVICE_SUBTYPE_IQ_TILT, iconSrfTilt],
    [DEVICE_SUBTYPE_IQ_SHOCK, iconSrfShock],
    [DEVICE_SUBTYPE_IQ_HEAT, iconSrfHeat],
    [DEVICE_SUBTYPE_IQ_HIGH_TEMPERATURE, iconSrfTemperature],
    [DEVICE_SUBTYPE_IQ_FREEZE, iconSrfFreeze],
    [DEVICE_SUBTYPE_IQ_OCCUPANCY_SENSOR, iconSrfOccupancySensor],
    [DEVICE_SUBTYPE_IQ_SMOKE_M, iconSrfSmokeM],
    [DEVICE_SUBTYPE_IQ_FLOOD, iconSrfFlood],
    [DEVICE_SUBTYPE_IQ_DOORBELL, iconSrfDoorbell],
    [DEVICE_SUBTYPE_IQ_CONTACT_M, iconSrfContactM],
    [DEVICE_SUBTYPE_IQ_SIREN, iconIndoor],
    [DEVICE_SUBTYPE_IQ_KEYPAD_PG, iconIqKeypadPg],
    [DEVICE_SUBTYPE_WIREFREE_LCD_KEYPAD, iconWireFreeLcdKeypad],
    [DEVICE_SUBTYPE_IQ_TAMPER_SENSOR, iconSrfDoorWindow],
])

const imagesByDeviceType = new Map([
    [deviceTypes.DEVICE_TYPE_PROXY_TAG, iconGenericProxyTag],
    [deviceTypes.DEVICE_TYPE_POWER_LINK, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_POWER_SUPPLY, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_AUDIO_VERIFICATION, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_OUTPUT_EXPANDER, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_ZONE_EXPANDER, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_BUS_REPEATER_HSM3204CX, iconNeo1APowerSupplyModule],
    [deviceTypes.DEVICE_TYPE_DUALPATH_INPUT, iconGenericPgm],
    [deviceTypes.DEVICE_TYPE_DUALPATH_OUTPUT, iconGenericPgm],
    [deviceTypes.DEVICE_TYPE_GSM, iconGsm],
    [deviceTypes.DEVICE_TYPE_LTE_MODULE, iconGsm],
    [deviceTypes.DEVICE_TYPE_DT_PG_BOX_DEVICE, iconDtPgBoxDevice],
    [deviceTypes.DEVICE_TYPE_CAMERA, iconIpCamera],
])

export const activeImages = new Map([[DEVICE_SUBTYPE_ZWAVE_LIGHT, iconZwaveLightActive]])

export function deviceImage(subtype, type = null) {
    if (images.has(subtype)) {
        return images.get(subtype)
    }

    if (type && imagesByDeviceType.has(type)) {
        return imagesByDeviceType.get(type)
    }

    warn(`Image for device subtype ${subtype} not set`)

    return iconUnknown
}

// see bug PMN-11729 for Virtual panels
export const devicesToFilterNames = [
    DEVICE_SUBTIPE_GATEWAY_PANEL,
    DEVICE_SUBTYPE_OUTDOOR_CONTACT_NBIOT,
    DEVICE_SUBTYPE_COMBO_NBIOT,
]

export function devicesToEnrollExists(devices) {
    return devices.filter(({name}) => !devicesToFilterNames.includes(name)).length > 0
}
