import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import * as PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Modal from 'ipmp-react-ui/Modal'

import {__} from 'utils/i18n'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {eventInitiate} from 'modules/panels/one/actions'

import Buttons from './Buttons'
import Content from './Content'

const rules = {}

export default function InitEmergency({hide, panelId}) {
    const [data, setData] = useState({})
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    const emergencyActions = features.emergency?.emergencyActions
    const handle = useActions(eventInitiate)

    useEffect(() => {
        rules.partitions = {
            presence: !!features?.partitions?.isEnabled,
        }
    }, [features?.partitions?.isEnabled])

    const submitForm = () => {
        handle(
            panelId,
            data.chosen,
            selectedPartitions?.map(({value}) => value)
        )
        hide()
    }

    const [selectedPartitions, setSelected] = useState()

    return (
        <Modal className="init-emergency-modal" onClose={hide}>
            <Form className="init-emergency-form" rules={rules} onSubmit={submitForm}>
                <Card className="init-emergency-card">
                    <CardClose onClick={hide} />
                    <CardHeader>{__('Emergencies')}</CardHeader>
                    <CardContent>
                        <Content
                            data={data}
                            panelId={panelId}
                            emergencyActions={emergencyActions}
                            setData={setData}
                            setSelected={setSelected}
                        />
                    </CardContent>
                    <CardActions>
                        <Buttons
                            data={data}
                            setData={setData}
                            hide={hide}
                            selectedPartitions={selectedPartitions}
                        />
                    </CardActions>
                </Card>
            </Form>
        </Modal>
    )
}

InitEmergency.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
