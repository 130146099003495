import {loggedIn, logout} from 'modules/auth/sign/actions'
import {all, call, put, takeEvery, select} from 'redux-saga/effects'
import isEqual from 'lodash-es/isEqual'

import * as api from 'api/batches'
import * as actions from './actions'
import {update as storeUpdate} from 'modules/batches/store/actions'

import {snackShow} from 'modules/snacks'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {POLL_BATCHES} from 'configs/pollers'

export default function* () {
    yield all([
        createPollerSaga(loggedIn, logout, POLL_BATCHES, pollBatches, true),
        takeEvery(actions.untrack, watchUntrack),
        takeEvery(actions.untrackFinished, watchUntrackFinished),
    ])
}

function* pollBatches() {
    const {rows} = yield call(api.status)
    const actual = yield select((store) => store.batches.store.byIds)
    const changed = rows.filter((batch) => !isEqual(actual[batch.id], batch))

    if (changed.length) {
        yield put(storeUpdate(changed))
    }

    const mineIds = yield select((store) => store.batches.widget.ids)
    const ids = rows.map((batch) => batch.id)

    if (!isEqual(ids, mineIds)) {
        yield put(actions.receive(ids))
    }
}

pollBatches.onError = function (error) {
    console.error(error)
}

function* watchUntrack({payload: batchId}) {
    try {
        yield call(api.untrack, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchUntrackFinished() {
    try {
        const ids = yield select((state) => state.batches.widget.ids)
        const byIds = yield select((state) => state.batches.store.byIds)

        const batchesIds = ids
            .map((id) => byIds[id])
            .filter((batch) => {
                const {handled, start} = batch.stats
                return handled > 0 || start > 0
            })
            .map((batch) => batch.id)

        yield put(actions.receive(batchesIds))

        yield call(api.untrackFinished)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
