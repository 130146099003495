export default class MasterUserToRemember {
    panelId
    interactiveUserId
    panelUserId
    role
    email
    status
    createdAt

    constructor({
        panelId,
        interactiveUserId,
        panelUserId,
        role,
        email,
        status,
        createdAt,
    }) {
        this.panelId = panelId
        this.interactiveUserId = interactiveUserId
        this.panelUserId = panelUserId
        this.role = role
        this.email = email
        this.status = status
        this.createdAt = createdAt
    }
}
