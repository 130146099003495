import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {selectGoogleSettings} from 'modules/system/settings/selectors'

const GoogleSettings = () => {
    const {token} = useSelector(selectGoogleSettings)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Google API key')}</InfoCardTileTitle>
                <InfoCardTileContent>{convertConfigValue(!!token)}</InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default GoogleSettings
