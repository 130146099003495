import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {RowAction} from 'ui/Row/Actions'

import {showEditUserModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {withPermissionVisibility} from 'containers/withPermission'
import {edit} from 'permissions/users/actions'

const PermittedRowAction = withPermissionVisibility(edit)(RowAction)

export default function EditAction({id, children}) {
    const onClick = useActions(() => showEditUserModal(id))

    return (
        <PermittedRowAction onClick={onClick} Icon={IconEdit}>
            {children}
        </PermittedRowAction>
    )
}

EditAction.propTypes = {
    id: PropTypes.number.isRequired,
}
