import React from 'react'

import {useEventsRate} from 'hooks/pages/useDashboard'
import {withPermissionRejection} from 'containers/withPermission'
import {eventsRate} from 'permissions/dashboard/charts'
import EventsRateChartBase from 'components/Dashboard/EventsRateChart'

const EventsRateChartRejection = withPermissionRejection(eventsRate)(EventsRateChartBase)

export default function EventsRateChart() {
    const {alarms, alerts, other, from, to} = useEventsRate()

    return (
        <EventsRateChartRejection
            to={to}
            from={from}
            other={other}
            alarms={alarms}
            alerts={alerts}
        />
    )
}
