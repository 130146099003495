import React from 'react'
import PropTypes from 'prop-types'

import {Tab} from 'ipmp-react-ui/Tabs'

import RemoteInspectionResults from 'components/RemoteInspection/Results'

export default function RemoteInspectionsTab({
    name,
    activeResult: activeResultProps,
    state,
}) {
    const activeResult = {
        ...activeResultProps,
        result: (activeResultProps?.result || []).filter(
            (foundResult) => foundResult.state === state
        ),
    }

    return (
        <Tab name={name}>
            <RemoteInspectionResults remoteInspectionResult={activeResult} />
        </Tab>
    )
}

RemoteInspectionsTab.propTypes = {
    name: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    activeResult: PropTypes.object.isRequired,
}
