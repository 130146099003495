import React from 'react'

import PanelBillingPlanBadge from 'components/Panel/Billing/PanelBillingPlanBadge'
import {
    BILLING_PLAN_STATUS_DEACTIVATED,
    BILLING_STATUS_VIEW_SHORT,
} from 'constants/billingPlan'

export default function BillingCell({row, openBillingBlind, billingStatusView}) {
    if (row.billing === null) {
        return null
    }

    const onClick = () => {
        openBillingBlind(row)
    }

    return (
        <PanelBillingPlanBadge
            onClick={onClick}
            id={row.billing.planId}
            name={row.billing.planName}
            isPending={row.billing.planStatus === BILLING_PLAN_STATUS_DEACTIVATED}
            isShort={billingStatusView === BILLING_STATUS_VIEW_SHORT}
        />
    )
}
