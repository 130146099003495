import React from 'react'

import Page from 'ipmp-react-ui/Page'

import RolesTable from './RolesTable'
import RolesGridBar from './RolesGridBar'

export default class RolesPage extends Page {
    renderBar() {
        return <RolesGridBar />
    }

    renderContent() {
        return <RolesTable />
    }
}
