import React, {useEffect} from 'react'

import Spinner from 'ipmp-react-ui/Spinner'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {messageTitleByErrorType} from 'constants/errorType'

export default function withLoader(onMount, onUnmount) {
    return (WrappedComponent) =>
        function WithLoader(loaderProps) {
            useEffect(() => {
                onMount && onMount(loaderProps)

                return () => {
                    onUnmount && onUnmount(loaderProps)
                }
            }, [])

            const handleClick = () => {
                onMount && onMount(loaderProps)
            }

            const {isLoading, error, ...props} = loaderProps

            if (isLoading) {
                return <Spinner dataTestId="loading-spinner" />
            }

            if (error) {
                return (
                    <Error error={error} title={messageTitleByErrorType(error?.type)}>
                        {onMount && <Button onClick={handleClick}>{__('Retry')}</Button>}
                    </Error>
                )
            }

            return <WrappedComponent {...props} />
        }
}
