import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import find from 'lodash-es/find'
import {connect} from 'react-redux'

import Select, {Option} from 'ipmp-react-ui/Select'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {EventRuleFormContext} from 'modals/EventRule/EventRuleFormContext'
import {
    EVENT_TYPE_NAME,
    EVENT_TYPE_NAME_RESTORE,
    NOTIFICATION_TYPE_NAME,
    STATE_TYPE_NAME,
    ZONE_TYPE_NAME,
} from 'constants/eventRules'
import __ from 'utils/i18n'
import {selectAllZoneTypesForEventRule} from 'modules/zoneTypes/store/selectors'
import {selectAllStateTypes} from 'modules/stateTypes/store/selectors'
import {selectAllEventTypes} from 'modules/eventTypes/store/selectors'
import {selectAllNotificationTypes} from 'modules/notificationTypes/store/selectors'

const commonCollectionType = PropTypes.arrayOf(
    PropTypes.shape({id: PropTypes.number, name: PropTypes.string})
)

class Save extends Component {
    static contextType = EventRuleFormContext

    static propTypes = {
        zoneTypesData: commonCollectionType,
        stateTypesData: commonCollectionType,
        eventTypesData: commonCollectionType,
        notificationTypes: commonCollectionType,
    }

    getSelectLabel = (collection, value, key = 'name') => {
        return get(find(collection, {id: Number(value)}), key, '')
    }

    render() {
        const {zoneTypesData, stateTypesData, eventTypesData, notificationTypes} =
            this.props
        const {allData} = this.context

        return (
            <div className="eventRules-master-content eventRules-master-content__save cols cols--stretch">
                <div className="col">
                    <Select
                        name={ZONE_TYPE_NAME}
                        defaultValue={allData[ZONE_TYPE_NAME]}
                        defaultLabel={this.getSelectLabel(
                            zoneTypesData,
                            allData[ZONE_TYPE_NAME]
                        )}
                        label={__('Zone Type')}
                    >
                        {zoneTypesData &&
                            zoneTypesData.length &&
                            zoneTypesData.map(({id, name}) => (
                                <Option key={id} label={name} value={id} />
                            ))}
                    </Select>
                    <Select
                        name={STATE_TYPE_NAME}
                        defaultValue={allData[STATE_TYPE_NAME]}
                        defaultLabel={this.getSelectLabel(
                            stateTypesData,
                            allData[STATE_TYPE_NAME]
                        )}
                        label={__('State')}
                    >
                        {stateTypesData &&
                            stateTypesData.length &&
                            stateTypesData.map(({id, name}) => (
                                <Option key={id} label={name} value={id} />
                            ))}
                    </Select>
                    <Select
                        name={EVENT_TYPE_NAME}
                        defaultValue={allData[EVENT_TYPE_NAME]}
                        defaultLabel={this.getSelectLabel(
                            eventTypesData,
                            allData[EVENT_TYPE_NAME],
                            'description'
                        )}
                        label={__('Event')}
                    >
                        {eventTypesData &&
                            eventTypesData.length &&
                            eventTypesData.map(({id, description}) => (
                                <Option key={id} label={description} value={id} />
                            ))}
                    </Select>
                </div>

                <div className="col">
                    <Select
                        name={NOTIFICATION_TYPE_NAME}
                        defaultValue={allData[NOTIFICATION_TYPE_NAME]}
                        defaultLabel={this.getSelectLabel(
                            notificationTypes,
                            allData[NOTIFICATION_TYPE_NAME]
                        )}
                        label={__('Notification')}
                    >
                        {notificationTypes &&
                            notificationTypes.length &&
                            notificationTypes.map(({id, name}) => (
                                <Option key={id} label={name} value={id} />
                            ))}
                    </Select>
                    <Checkbox
                        className="eventRules-master-content-checkbox"
                        name={EVENT_TYPE_NAME_RESTORE}
                        label={__('Event Restore')}
                        defaultChecked={allData[EVENT_TYPE_NAME_RESTORE] === 'on'}
                    />
                </div>
            </div>
        )
    }
}

export default connect((store) => {
    return {
        zoneTypesData: get(selectAllZoneTypesForEventRule(store), 'data', []),
        stateTypesData: get(selectAllStateTypes(store), 'data', []),
        eventTypesData: get(selectAllEventTypes(store), 'data', []),
        notificationTypes: get(selectAllNotificationTypes(store), 'data', []),
    }
})(Save)
