import {handleActions} from 'redux-actions'

import * as actions from './actions'

const defaultState = {}

export default handleActions(
    {
        [actions.addMessage](state, {payload: {serial, protocol, message}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.messages = [
                ...new Set([...(session.messages || []), message.requestId]),
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.setToken](state, {payload: {serial, protocol, token}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            return {
                ...state,
                [serial]: {...state[serial], [protocol]: {...session, token}},
            }
        },
        [actions.clearSession](state, {payload: {serial, protocol}}) {
            let data = Object.keys(state[serial] || {}).reduce(
                (acc, key) => ({
                    ...acc,
                    ...(key !== protocol ? {[key]: state[serial][key]} : {}),
                }),
                {}
            )

            return Object.fromEntries(
                Object.entries(data).filter(([_, v]) => Object.keys(v).length !== 0)
            )
        },
        [actions.addDeviceComplete](state, {payload: {serial, protocol, device}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}
            session.devices = [...(session.devices || []), {...device}]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.requestAddSensorConfirmation](
            state,
            {payload: {serial, protocol, message}}
        ) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.addSensorConfirmationRequests = [
                ...(session.addSensorConfirmationRequests || []),
                {...message},
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.completeAddSensorConfirmation](
            state,
            {payload: {serial, protocol, sensor}}
        ) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.addSensorConfirmationRequests = [
                ...(session.addSensorConfirmationRequests || []).filter(
                    (request) => request.sensor !== sensor
                ),
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.requestZWaveKeysReport](state, {payload: {serial, protocol, message}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.zWaveKeysReportRequests = [
                ...(session.zWaveKeysReportRequests || []),
                {...message},
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.completeZWaveKeysReport](state, {payload: {serial, protocol, nodeId}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.zWaveKeysReportRequests = [
                ...(session.zWaveKeysReportRequests || []).filter(
                    (request) => request.nodeId !== nodeId
                ),
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.requestZWaveDskReport](state, {payload: {serial, protocol, message}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            session.zWaveDskReportRequests = [
                ...(session.zWaveDskReportRequests || []),
                {...message},
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
        [actions.completeZWaveDskReport](state, {payload: {serial, protocol, data}}) {
            const session = (state[serial] && {...state[serial][protocol]}) || {}

            const dsk = `[${data.dsk.map((v, i) => (i < 2 ? 0 : v)).join(', ')}]`

            session.zWaveDskReportRequests = [
                ...(session.zWaveDskReportRequests || []).filter(
                    (request) => request.zWaveDsk !== dsk
                ),
            ]

            return {...state, [serial]: {...state[serial], [protocol]: session}}
        },
    },
    defaultState
)
