import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import ModalCardForm from 'ui/ModalCardForm'

import {__} from 'utils/i18n'
import {
    FIRMWARE_UPGRADE_DEFAULT_TIMEOUT,
    FIRMWARE_UPGRADE_MAXIMUM_TIMEOUT,
    FIRMWARE_UPGRADE_MINIMAL_TIMEOUT,
} from 'constants/firmware'
import {useActions} from 'modules/higherOrder/useActions'
import {upgrade as upgradeBatch} from 'modules/firmware/patchTagUpdate/actions'
import {patchTagUpgrade as upgradePanel} from 'modules/panels/firmware/actions'

const rules = {
    timeout: {
        numericality: {
            greaterThanOrEqualTo: FIRMWARE_UPGRADE_MINIMAL_TIMEOUT,
            lessThanOrEqualTo: FIRMWARE_UPGRADE_MAXIMUM_TIMEOUT,
            onlyInteger: true,
        },
        presence: true,
    },
    packageName: {
        length: {
            maximum: 255,
        },
        format: {
            pattern: /^[a-zA-Z0-9\-_.]+$/,
            flags: 'i',
            message: () => __('Allowed only digits, letters, dots, dashes.'),
        },
    },
}

export default function PatchTagUpdate({hide, panelIds, panelId}) {
    const {handle} = useActions({
        handle: ({timeout, packageName}) => {
            if (panelId) {
                return upgradePanel({timeout, packageName, panelId})
            }

            return upgradeBatch({timeout, packageName, panelIds})
        },
    })

    const onSubmit = ({timeout, packageName}) => {
        hide()

        return handle({timeout, packageName})
    }

    return (
        <ModalCardForm
            hide={hide}
            rules={rules}
            onSubmit={onSubmit}
            submitLabel={__('Update')}
            header={__('Patch tag update')}
        >
            <Input label={__('Patch tag')} name="packageName" />
            <Input
                label={__('Timeout, seconds')}
                name="timeout"
                type="number"
                maxLength="10"
                defaultValue={FIRMWARE_UPGRADE_DEFAULT_TIMEOUT}
            />
        </ModalCardForm>
    )
}

PatchTagUpdate.propTypes = {
    hide: PropTypes.func,
}
