import React from 'react'
import classes from 'classnames'

import Button from 'ipmp-react-ui/Button'

import {ReactComponent as EditIcon} from 'ipmp-react-ui/icons/edit.svg'

import __ from 'utils/i18n'

export default function PanelBillingCurrentPlanItem({
    name,
    value,
    Icon,
    className,
    isAnimated,
    onEditClick,
}) {
    return (
        <div
            className={classes('panelBillingCurrentPlan__item', className, {
                'panelBillingCurrentPlan__item--animated': isAnimated,
            })}
        >
            <Icon />
            <div className="panelBillingCurrentPlan__item-info">
                <h5 className="panelBillingCurrentPlan__item-name">{name}</h5>
                <span className="panelBillingCurrentPlan__item-value">
                    {value}
                    {onEditClick && (
                        <Button borderless small Icon={EditIcon} onClick={onEditClick} />
                    )}
                </span>
            </div>
        </div>
    )
}

export function PanelBillingCurrentPlanItemsContainer({children}) {
    return (
        <div className="panelBillingCurrentPlan">
            <h4 className="panelBillingCurrentPlan__title">{__('Billing status')}</h4>
            <div className="panelBillingCurrentPlan__items">{children}</div>
        </div>
    )
}
