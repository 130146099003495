import React from 'react'
import {connect} from 'react-redux'
import {hide} from 'modules/modals/actions'
import {bindActionCreators} from 'redux'
import * as modals from 'modules/modals/constants'

import BlockedNotificationsHelp from './General/BlockedNotificationsHelp'
import About from './General/About'
import AboutLicenseFeatures from './General/AboutLicenseFeatures'
import AboutReleaseNotes from './General/AboutReleaseNotes'
import Confirmation from './General/Confirmation'
import Alert from './General/Alert'
import AddPanel from './Panel/AddPanel'
import AddDevice from './Panel/AddDevice'
import AddCamera from './Panel/AddCamera'
import EditPanelInfo from './Panel/EditPanelInfo'
import EditPanelCustomerInfo from './Panel/EditPanelCustomerInfo'
import ChangePanelGroup from './Panel/ChangePanelGroup'
import MakeBasicConfiguration from './Panel/MakeBasicConfiguration'
import PushBasicConfiguration from './Panel/PushBasicConfiguration'
import ExportConfiguration from './Panel/Configuration/Export'
import ActivatePanel from './Panel/ActivatePanel'
import ActivateMasterPanelGUI from './Panel/ActivateMasterPanelGUI'
import MarkForService from './Panel/Service/MarkForService'
import ReassignService from './Panel/Service/ReassignService'
import RebootPlink from './Panel/RebootPlink'
import EnablePlinkLogging from './Panel/EnablePlinkLogging'
import DisablePlinkLogging from './Panel/DisablePlinkLogging'
import SetUserPin from './Panel/SetUserCode'
import AddGroup from './Group/AddGroup'
import EditGroup from './Group/EditGroup'
import EditGroupNotifications from './Group/Profiles/EditNotificationsProfile'
import EditGroupEvents from './Group/Profiles/EditEventsProfile'
import FaultsResolve from './Panel/Faults/Resolve'
import FaultsResume from './Panel/Faults/Resume'
import FaultsSuspend from './Panel/Faults/Suspend'
import RemoteInspectionValues from './RemoteInspection/RemoteInspectionValues'
import Schedule from './RemoteInspection/Schedule'
import ScheduleByCsv from './RemoteInspection/ScheduleByCsv'
import CreateReport from './Report/Create'
import EditLinks from './CentralStations/EditLinks'
import AddCentralStation from './CentralStations/AddCentralStation'
import EditCentralStation from './CentralStations/EditCentralStation'
import AddUser from './User/AddUser'
import EditUser from './User/EditUser'
import RemoveUser from './User/RemoveUser'
import EditProfileInfo from './Profile/EditProfileInfo'
import EditSettings from './Profile/EditSettings'
import ChangePassword from './Profile/ChangePassword'
import AddRole from './Roles/AddRole'
import EditRole from './Roles/EditRole'
import RemoveRole from './Roles/RemoveRole'
import EditRolePermissions from './Roles/EditRolePermissions'
import SaveSearchScope from './Profile/SaveSearchScope'
import ShowDeviceMeteoChart from './Panel/ShowDeviceMeteoChart'
import {RenameDevice, RenamePartition} from './Panel/RenameEntity'
import FirmwareUpgrade from './Firmware/Upgrade'
import PatchTagUpdate from './Firmware/PatchTagUpdate'
import UnavailableFeature from './Panel/UnavailableFeature'
import TriggerDiscovery from './Panel/TriggerDiscovery'

import MessageBrokerSettings from './System/Settings/MessageBrokerSettings'
import CellularConnectedSettings from './System/Settings/CellularConnectedSettings'
import BroadbandConnectedSettings from './System/Settings/BroadbandConnectedSettings'
import CommonConnectedSettings from './System/Settings/CommonConnectedSettings'
import SupervisionSettings from './System/Settings/SupervisionSettings'
import RemoteInspectionSettings from './System/Settings/RemoteInspectionSettings'
import AdvertisementSettings from './System/Settings/AdvertisementSettings'
import InteractiveSessionSettings from './System/Settings/InteractiveSessionSettings'
import UserNotificationsSettings from './System/Settings/UserNotificationsSettings'
import AuthorizationSettings from './System/Settings/AuthorizationSettings'
import RotationSettings from './System/Settings/RotationSettings'
import MFAGeneral from './System/Settings/MFAGeneral'
import MfaParameters from 'modals/System/Settings/MFAParameters'
import GoogleSettings from 'modals/System/Settings/GoogleSettings'
import PgmActivationTimeSlot from 'modals/System/Settings/PgmActivationTimeSlot'

import AssignUserToPanel from './InteractiveUsers/AssigneUserToPanel'
import RegisterInteractiveUser from './InteractiveUsers/RegisterInteractiveUser'
import AssignInstallerToPanel from 'modals/Installer/AssignInstallerToPanel'
import RegisterInstaller from './Installer/RegisterInstaller'
import ChangeInstallerCode from './Installer/ChangeInstallerCode'

import AddZoneType from './ZoneType/AddZoneType'
import EditZoneType from './ZoneType/EditZoneType'
import AddNotificationType from 'modals/NotificationType/AddNotificationType'
import EditNotificationType from 'modals/NotificationType/EditNotificationType'
import AddStateType from 'modals/StateType/AddStateType'
import EditStateType from 'modals/StateType/EditStateType'
import AddEventType from 'modals/EventType/AddEventType'
import EditEventType from 'modals/EventType/EditEventType'
import AddEventRule from 'modals/EventRule/AddEventRule'
import EditEventRule from 'modals/EventRule/EditEventRule'

import ChangePgmStateParameters from 'modals/Had/ChangePgmStateParameters'
import AddPgm from 'modals/Had/AddPgm'
import SetPanelUserLabel from 'modals/Panel/SetPanelUserLabel'
import SetPanelUserPartitions from 'modals/Panel/SetPanelUserPartitions'
import SetPanelUserLastName from 'modals/Panel/SetPanelUserLastName'
import SetPanelUserExpirationDate from 'modals/Panel/SetPanelUserExpirationDate'
import InitEmergency from 'modals/Panel/InitiateEmergency/InitEmergency'

import EditLabel from 'modals/Had/EditLabel'
import EditIcon from 'modals/Had/EditIcon'

import DlsConnectDsc from 'modals/Panel/DlsConnection/DlsConnectDsc'
import ConnectSmartCommunicator from 'modals/Panel/DlsConnection/ConnectSmartCommunicator'
import ActivatePlan from './Panel/Billing/ActivatePlan'

const components = {
    [modals.MODAL_ABOUT]: About,
    [modals.MODAL_ABOUT_LICENSE_FEATURES]: AboutLicenseFeatures,
    [modals.MODAL_ABOUT_RELEASE_NOTES]: AboutReleaseNotes,
    [modals.MODAL_BLOCKED_NOTIFICATIONS_HELP]: BlockedNotificationsHelp,
    [modals.MODAL_CONFIRMATION]: Confirmation,
    [modals.MODAL_ALERT]: Alert,
    [modals.MODAL_MARK_FOR_SERVICE]: MarkForService,
    [modals.MODAL_REASSIGN_SERVICE]: ReassignService,
    [modals.MODAL_CHANGE_PANEL_GROUP]: ChangePanelGroup,
    [modals.MODAL_ADD_PANEL]: AddPanel,
    [modals.MODAL_EDIT_PANEL_INFO]: EditPanelInfo,
    [modals.MODAL_EDIT_PANEL_CUSTOMER_INFO]: EditPanelCustomerInfo,
    [modals.MODAL_MAKE_BASIC_CONFIGURATION]: MakeBasicConfiguration,
    [modals.EXPORT_CONFIGURATION]: ExportConfiguration,
    [modals.MODAL_PUSH_BASIC_CONFIGURATION]: PushBasicConfiguration,
    [modals.MODAL_ACTIVATE_PANEL]: ActivatePanel,
    [modals.MODAL_ACTIVATE_MASTER_PANEL_GUI]: ActivateMasterPanelGUI,
    [modals.MODAL_ADD_GROUP]: AddGroup,
    [modals.MODAL_EDIT_GROUP]: EditGroup,
    [modals.MODAL_EDIT_GROUP_NOTIFICATIONS]: EditGroupNotifications,
    [modals.MODAL_EDIT_GROUP_EVENTS]: EditGroupEvents,
    [modals.MODAL_CREATE_REPORT]: CreateReport,
    [modals.MODAL_FAULTS_RESOLVE]: FaultsResolve,
    [modals.MODAL_FAULTS_SUSPEND]: FaultsSuspend,
    [modals.MODAL_FAULTS_RESUME]: FaultsResume,
    [modals.MODAL_SCHEDULE_RRI]: Schedule,
    [modals.MODAL_SCHEDULE_RRI_CSV]: ScheduleByCsv,
    [modals.MODAL_REMOTE_INSPECTION_VALUES]: RemoteInspectionValues,
    [modals.MODAL_EDIT_CENTRAL_STATION_LINKS]: EditLinks,
    [modals.MODAL_ADD_CENTRAL_STATION]: AddCentralStation,
    [modals.MODAL_EDIT_CENTRAL_STATION]: EditCentralStation,
    [modals.MODAL_ADD_USER]: AddUser,
    [modals.MODAL_EDIT_USER]: EditUser,
    [modals.MODAL_REMOVE_USER]: RemoveUser,
    [modals.MODAL_ADD_DEVICE]: AddDevice,
    [modals.MODAL_ADD_CAMERA]: AddCamera,
    [modals.MODAL_EDIT_PROFILE_INFO]: EditProfileInfo,
    [modals.MODAL_EDIT_SETTINGS]: EditSettings,
    [modals.MODAL_CHANGE_PASSWORD]: ChangePassword,
    [modals.MODAL_REBOOT_PLINK]: RebootPlink,
    [modals.MODAL_ENABLE_PLINK_LOGGING]: EnablePlinkLogging,
    [modals.MODAL_DISABLE_PLINK_LOGGING]: DisablePlinkLogging,
    [modals.MODAL_ADD_ROLE]: AddRole,
    [modals.MODAL_EDIT_ROLE]: EditRole,
    [modals.MODAL_REMOVE_ROLE]: RemoveRole,
    [modals.MODAL_EDIT_ROLE_PERMISSIONS]: EditRolePermissions,
    [modals.MODAL_SAVE_SEARCH_SCOPE]: SaveSearchScope,
    [modals.MODAL_SHOW_DEVICE_METEO_CHART]: ShowDeviceMeteoChart,
    [modals.MODAL_RENAME_DEVICE]: RenameDevice,
    [modals.MODAL_RENAME_PARTITION]: RenamePartition,
    [modals.MODAL_FIRMWARE_UPGRADE]: FirmwareUpgrade,
    [modals.MODAL_FIRMWARE_PATCH_TAG_UPDATE]: PatchTagUpdate,
    [modals.MODAL_TRIGGER_DISCOVERY]: TriggerDiscovery,
    [modals.MODAL_UNAVAILABLE_FEATURE]: UnavailableFeature,

    [modals.MODAL_SYSTEM_SETTINGS_CELLULAR_CONNECTED]: CellularConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_BROADBAND_CONNECTED]: BroadbandConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_COMMON_CONNECTED]: CommonConnectedSettings,
    [modals.MODAL_SYSTEM_SETTINGS_SUPERVISION]: SupervisionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_REMOTE_INSPECTION]: RemoteInspectionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_INTERACTIVE_SESSION]: InteractiveSessionSettings,
    [modals.MODAL_SYSTEM_SETTINGS_USER_NOTIFICATIONS]: UserNotificationsSettings,
    [modals.MODAL_SYSTEM_SETTINGS_ADVERTISEMENT]: AdvertisementSettings,
    [modals.MODAL_SYSTEM_SETTINGS_MESSAGE_BROKER]: MessageBrokerSettings,
    [modals.MODAL_SYSTEM_SETTINGS_AUTHORIZATION_SETTINGS]: AuthorizationSettings,
    [modals.MODAL_SYSTEM_SETTINGS_ROTATION]: RotationSettings,

    [modals.MODAL_ASSIGN_USER_TO_PANEL]: AssignUserToPanel,
    [modals.MODAL_REGISTER_INTERACTIVE_USER]: RegisterInteractiveUser,
    [modals.MODAL_SET_USER_PIN]: SetUserPin,
    [modals.MODAL_SET_PANEL_USER_LABEL]: SetPanelUserLabel,
    [modals.MODAL_SET_PANEL_USER_PARTITIONS]: SetPanelUserPartitions,
    [modals.MODAL_SET_PANEL_USER_LAST_NAME]: SetPanelUserLastName,
    [modals.MODAL_SET_PANEL_USER_EXPIRATION_DATE]: SetPanelUserExpirationDate,

    [modals.MODAL_ASSIGN_INSTALLER_TO_PANEL]: AssignInstallerToPanel,
    [modals.MODAL_REGISTER_INSTALLER]: RegisterInstaller,
    [modals.MODAL_CHANGE_INSTALLER_CODE]: ChangeInstallerCode,

    [modals.MODAL_ZONE_TYPE_ADD]: AddZoneType,
    [modals.MODAL_ZONE_TYPE_EDIT]: EditZoneType,

    [modals.MODAL_NOTIFICATION_TYPE_ADD]: AddNotificationType,
    [modals.MODAL_NOTIFICATION_TYPE_EDIT]: EditNotificationType,

    [modals.MODAL_STATE_TYPE_ADD]: AddStateType,
    [modals.MODAL_STATE_TYPE_EDIT]: EditStateType,

    [modals.MODAL_EVENT_TYPE_ADD]: AddEventType,
    [modals.MODAL_EVENT_TYPE_EDIT]: EditEventType,

    [modals.MODAL_EVENT_RULE_ADD]: AddEventRule,
    [modals.MODAL_EVENT_RULE_EDIT]: EditEventRule,

    [modals.MODAL_CHANGE_PGM_STATE_PARAMS]: ChangePgmStateParameters,

    [modals.MODAL_ADD_PGM]: AddPgm,

    [modals.MODAL_SYSTEM_SETTINGS_MFA_GENERAL]: MFAGeneral,
    [modals.MODAL_SYSTEM_SETTINGS_MFA_PARAMETERS]: MfaParameters,
    [modals.MODAL_SYSTEM_SETTINGS_GOOGLE]: GoogleSettings,

    [modals.MODAL_EVENT_INITIATE]: InitEmergency,
    [modals.MODAL_SYSTEM_SETTINGS_PGM_ACTIVATION_TIME_SLOT]: PgmActivationTimeSlot,

    [modals.MODAL_HAD_EDIT_LABEL]: EditLabel,
    [modals.MODAL_HAD_EDIT_ICON]: EditIcon,

    [modals.MODAL_DLS_CONNECT_DSC]: DlsConnectDsc,
    [modals.MODAL_DLS_CONNECT_SMART_COMMUNICATOR]: ConnectSmartCommunicator,

    [modals.MODAL_PANEL_BILLING_ACTIVATE]: ActivatePlan,
}

export const ModalsContainer = ({type, hide, args}) => {
    if (!type) {
        return null
    }

    if (!components[type]) {
        throw new Error(`Unknown modal type ${type}`)
    }

    const Component = components[type]

    return <Component {...args} hide={hide} />
}

export default connect(
    ({modals}) => ({...modals}),
    (dispatch) => bindActionCreators({hide}, dispatch)
)(ModalsContainer)
