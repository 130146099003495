import React from 'react'

import {ReactComponent as IconTime} from 'ipmp-react-ui/icons/time.svg'

import Time from 'ipmp-react-ui/Time'
import DateTime from 'ipmp-react-ui/DateTime'
import {default as DurationWidget} from 'ipmp-react-ui/Duration'
import Definition from 'ipmp-react-ui/Definition'

export default function Duration({start, end, timeOnly, withSeconds}) {
    return (
        <>
            {timeOnly && <Time datetime={start} withSeconds={withSeconds} />}
            {!timeOnly && <DateTime datetime={start} timeFirst />}

            <Definition
                className="duration"
                Icon={IconTime}
                detail={<DurationWidget from={start} to={end} />}
            />
        </>
    )
}
