import React from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRetry} from 'ipmp-react-ui/icons/retry.svg'

import {__} from 'utils/i18n'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'
import usePermission from 'hooks/usePermission'
import useUpgradeStatusRunners from 'hooks/pages/useUpgradeStatusRunners'
import {retry as retryPermission} from 'permissions/firmware/upgradeStatus'
import {isAllowRetry} from 'constants/processTypes'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler((runnerId, {runners}) => {
        const runner = get(runners, ['store', 'byIds', runnerId], {})

        return runner.isFailed && isAllowRetry(runner.type)
    })
)(Button)

export default function UpgradeStatusRetryButton() {
    const {retry} = useUpgradeStatusRunners()
    const {isPermitted} = usePermission(retryPermission)

    return (
        <ConnectedButton
            onClick={retry}
            isVisible={isPermitted}
            label={__('Retry')}
            Icon={IconRetry}
        />
    )
}
