import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {editPanelInfo} from 'modules/forms/handlers'
import PanelFieldSet, {validation} from './Fieldset/Panel'

export default function EditPanelInfo({panelId, hide}) {
    const {form, handle, isLoading} = useForm(editPanelInfo)
    const panel = useSelector(({panels}) => panels.store.byIds[panelId] || {})

    const handler = (data) => {
        const info = {...data, groupId: parseInt(data.groupId)}

        if ('serial' in info) {
            delete info.serial
        }

        if ('account' in info) {
            delete info.account
        }

        handle(panelId, info)
    }

    const rules = validation(panel.vendor)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            confirmOnDismiss
            isLoading={isLoading}
            header={__('Edit Panel')}
        >
            <PanelFieldSet
                data={panel}
                vendor={panel.vendor}
                activationStatus={panel.activationStatus}
            />
        </ModalCardForm>
    )
}
