import React from 'react'
import ProptTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import {ReactComponent as IconArrow} from 'icons/triangle-right.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {autolearn} from 'permissions/panel/devices/autolearn/actions'
import {useSelector} from 'react-redux'
import {selectAvailableAutolearn} from 'modules/features/store/selectors'
import {SECURITY_SENSORS} from 'constants/autolearn'

export default function SecurityContent({
    children,
    startAuto,
    isAutoLearnAvailable,
    panelId,
}) {
    const {isPermitted: isAutolearnPermitted} = usePermission(autolearn)
    const featuresAvailableAutolearn = useSelector((state) =>
        selectAvailableAutolearn(state, {panelId})
    )

    const isAutolearnButtonShown =
        isAutoLearnAvailable &&
        isAutolearnPermitted &&
        featuresAvailableAutolearn.includes(SECURITY_SENSORS)

    return (
        <div className="security-device-enrollment">
            <div className="security-device-enrollment-description">
                <IconAutolearn />
                <div className="security-device-enrollment-description-title">
                    {__('Enroll security device')}
                </div>
                <div className="security-device-enrollment-description-message">
                    {__('Enter data and press "Add" to enroll device')}
                    {isAutolearnButtonShown && (
                        <div className="security-device-enrollment-description-message-button">
                            <Button flat onClick={startAuto} Icon={IconArrow}>
                                {__('or start autolearn')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            <div className="security-device-enrollment-form">{children}</div>
        </div>
    )
}

SecurityContent.propTypes = {
    startAuto: ProptTypes.func.isRequired,
    isAutoLearnAvailable: ProptTypes.bool.isRequired,
    panelId: ProptTypes.number.isRequired,
}
