import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {unassign} from 'permissions/installers/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'
import {unassignInstaller} from 'modules/installerPanels/list/actions'

const ButtonVisibility = compose(withVisibility(), withConfirmation())(Button)

export default function UnassignInstallerButton({row: {id, email, installerId}}) {
    const {isPermitted} = usePermission(unassign)
    const {onUnassign} = useActions(
        {
            onUnassign: () => unassignInstaller(id),
        },
        [id]
    )

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconMinus}
            onClick={onUnassign}
            isVisible={isPermitted && installerId}
            title={__('Unassign Installer')}
            message={__('Do you really want to unassign installer with email %s?', email)}
        />
    )
}
