import React, {useState, useRef, useCallback} from 'react'
import PropTypes from 'prop-types'
import {dropRight} from 'lodash'

import ConfigurationList from 'components/Configuration/ConfigurationList'
import ConfigurationTableOfContents from 'components/Configuration/ConfigurationTableOfContents'
import Layout, {Content, ScrollView, SideBar} from 'ipmp-react-ui/Layout'

import {__} from 'utils/i18n'
import {CONFIGURATION_SEPARATOR} from 'constants/panelConfiguration'

const ConfigurationLayout = ({sections, header, filters, ...props}) => {
    const [key, setKey] = useState('')
    const moveToRef = useRef(null)

    const handleBindMoveTo = useCallback(
        (handler) => {
            moveToRef.current = handler
        },
        [key]
    )

    const handleSelect = useCallback(
        (selectedKey) => {
            let newKey = selectedKey
            if (newKey === key) {
                const selectedPath = selectedKey.split(CONFIGURATION_SEPARATOR)
                newKey = dropRight(selectedPath).join(CONFIGURATION_SEPARATOR)
            }
            setKey(newKey)
            if (moveToRef.current) {
                moveToRef.current(newKey)
            }
        },
        [key]
    )

    const handleMove = useCallback(
        (newKey) => {
            if (key !== newKey) {
                setKey(newKey)
            }
        },
        [key]
    )

    const renderEmptyMessage = () => {
        const {isCompareWithBackup, showChanged, showExportable} = props

        switch (true) {
            case isCompareWithBackup:
                return __(
                    'There are no configuration elements that are different in comparison with backup'
                )
            case showChanged:
                return __('There are no changed configuration elements')
            case showExportable:
                return __('There are no exportable configuration elements')
            default:
                return __('Configuration elements not found')
        }
    }

    return (
        <Layout className="configuration">
            <Layout vertical>
                {header && <div className="configuration-header">{header}</div>}

                {sections.length ? (
                    <ConfigurationList
                        onMove={handleMove}
                        bindMoveTo={handleBindMoveTo}
                        sections={sections}
                        {...props}
                    />
                ) : (
                    <div className="empty-message">{renderEmptyMessage()}</div>
                )}
            </Layout>

            <SideBar>
                <Content className="configuration-sidebar-search">{filters}</Content>

                {sections.length > 0 && (
                    <div className="hint">{__('Table of contents')}</div>
                )}

                {sections.length > 0 && (
                    <ScrollView>
                        <ConfigurationTableOfContents
                            onSelect={handleSelect}
                            nodes={sections}
                            highlightKey={key}
                        />
                    </ScrollView>
                )}
            </SideBar>
        </Layout>
    )
}

ConfigurationLayout.propTypes = {
    sections: PropTypes.array.isRequired,
    header: PropTypes.element,
    filters: PropTypes.element,
}

export default ConfigurationLayout
