import themes, {DEFAULT_THEME} from 'constants/themes'
import {setLastKnownTheme} from 'storage/lastKnownTheme'

export default function applyTheme(theme, classes = []) {
    theme = theme && themes[theme] ? theme : DEFAULT_THEME
    setLastKnownTheme(theme)
    Object.values(themes).forEach(({classList}) =>
        document.body.classList.remove(...classList)
    )
    if (classes.length) {
        document.body.classList.add(...classes)
    } else {
        document.body.classList.add(...themes[theme].classList)
    }
}
