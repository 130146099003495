import React from 'react'

import PanelsPage from './PanelsPage'
import path from 'utils/path'

export default class Panels {
    path = path('panels')

    element = (<PanelsPage />)
}
