import React from 'react'

export default function ConfigurationSection({node, offset, height, from, children, to}) {
    const sectionVisible = offset + height > from && offset < to

    return (
        <section className="configuration-section">
            {node.name && <div className="configuration-section-title">{node.name}</div>}

            <div className="card" style={{height}}>
                {sectionVisible && children}
            </div>
        </section>
    )
}
