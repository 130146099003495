import React from 'react'

import {featureName, sectionName} from 'constants/license'

import {TableGrouped} from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import {ReactComponent as IconCheck} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

import Void from 'components/Void'

const columns = [
    {
        render: ({feature}) => <Definition title={featureName(feature)} />,
    },
    {
        align: 'center',
        render: ({feature, value}) => {
            if (!feature) {
                return <Void />
            }
            return value ? (
                <IconCheck className="success" />
            ) : (
                <IconCross className="danger" />
            )
        },
    },
]

export default function LicenseFeaturesTable({rows, className}) {
    const separatorCallback = (row) => sectionName(row.section)

    return (
        <TableGrouped
            rows={rows}
            columns={columns}
            className={className}
            separatorCallback={separatorCallback}
        />
    )
}
