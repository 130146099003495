import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {ReactComponent as IconOpen} from 'ipmp-react-ui/icons/disarm.svg'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/away.svg'

import {
    HAD_DOORLOCK_JAMMED,
    HAD_DOORLOCK_LOCKED,
    HAD_DOORLOCK_UNLOCKED,
} from 'constants/had/hadState'
import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'

import SetHadUnlockButton from './SetHadUnlockButton'
import SetHadLockButton from './SetHadLockButton'
import {doorlockControl} from 'permissions/panel/output/actions'
import {selectHadIsSwitchable} from 'modules/panels/had/selector'

const HadDeviceDoorlockControl = ({had: {id, state, deviceType}, disabled}) => {
    const mode = state?.lock?.mode
    const {isPermitted} = usePermission(doorlockControl)
    const {isSwitchable} = useSelector(selectHadIsSwitchable)

    return (
        <div
            className={classnames(
                'device-had-controls-stateButtons',
                'device-had-controls-stateButtons--doorlock',
                {
                    'device-had-controls-stateButtons--disabled': Boolean(disabled),
                }
            )}
        >
            {[HAD_DOORLOCK_LOCKED, HAD_DOORLOCK_JAMMED].includes(mode) && isPermitted && (
                <SetHadUnlockButton
                    Icon={IconClose}
                    id={id}
                    label={__('Unlock')}
                    deviceType={deviceType}
                    disabled={!isSwitchable}
                />
            )}
            {mode === HAD_DOORLOCK_UNLOCKED && isPermitted && (
                <SetHadLockButton
                    Icon={IconOpen}
                    id={id}
                    label={__('Lock')}
                    deviceType={deviceType}
                    disabled={!isSwitchable}
                />
            )}
        </div>
    )
}

HadDeviceDoorlockControl.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.shape({pgm: PropTypes.number}),
    }).isRequired,
    disabled: PropTypes.bool,
}

export default HadDeviceDoorlockControl
