import React from 'react'

import {useActions} from 'modules/higherOrder/useActions'
import {showMarkForServiceModal} from 'modules/runners/store/actions'
import {MarkForServiceItem} from 'pages/Panels/GridBar/DropDowns/Items/MarkForServiceItem'

export default function MarkForService() {
    const onClick = useActions(showMarkForServiceModal)

    return <MarkForServiceItem onClick={onClick} />
}
