import React from 'react'
import {warn} from 'utils/log'
import __ from 'utils/i18n'

import {ReactComponent as IconOff} from 'ipmp-react-ui/icons/power.svg'
import {ReactComponent as IconHeat} from 'ipmp-react-ui/icons/sunny.svg'
import {ReactComponent as IconCool} from 'ipmp-react-ui/icons/cool.svg'
import {ReactComponent as IconAuto} from 'ipmp-react-ui/icons/auto.svg'
import {ReactComponent as IconAux} from 'ipmp-react-ui/icons/worries/fire.svg'
import {convertTemperatureMap, TEMP_UNIT_F} from 'utils/temperature'

export const HAD_DEVICE_THERMOSTAT_MODE_OFF = 0
export const HAD_DEVICE_THERMOSTAT_MODE_HEAT = 1
export const HAD_DEVICE_THERMOSTAT_MODE_COOL = 2
export const HAD_DEVICE_THERMOSTAT_MODE_AUTO = 3
export const HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT = 4

export const HAD_DEVICE_THERMOSTAT_FAN_MODE_AUTO = 0
export const HAD_DEVICE_THERMOSTAT_FAN_MODE_ON = 1

export const HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_CURRENT = 'current_temp'
export const HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_TARGET_HEAT = 'target_heat_temp'
export const HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_TARGET_COOL = 'target_cool_temp'

export const getThermostatFanToggleLabel = (mode) => {
    switch (mode) {
        case true:
            return () => __('Auto')
        default:
            return () => __('On')
    }
}

const thermostatTypeStateTitleMap = new Map([
    [HAD_DEVICE_THERMOSTAT_MODE_OFF, () => __('Off')],
    [HAD_DEVICE_THERMOSTAT_MODE_HEAT, () => __('Heat')],
    [HAD_DEVICE_THERMOSTAT_MODE_COOL, () => __('Cool')],
    [HAD_DEVICE_THERMOSTAT_MODE_AUTO, () => __('Auto')],
    [HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT, () => __('Aux')],
])

const thermostatTypeStateTitle = (mode) => {
    let result = thermostatTypeStateTitleMap.get(mode)

    if (!result) {
        warn(`No home automation device (thermostat) state title for ${mode}`)
        result = () => __('Undefined')
    }

    return result()
}

const thermostatModeIconMap = new Map([
    [HAD_DEVICE_THERMOSTAT_MODE_OFF, <IconOff />],
    [HAD_DEVICE_THERMOSTAT_MODE_HEAT, <IconHeat />],
    [HAD_DEVICE_THERMOSTAT_MODE_COOL, <IconCool />],
    [HAD_DEVICE_THERMOSTAT_MODE_AUTO, <IconAuto />],
    [HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT, <IconAux />],
])

export function getThermostatModeIcon(mode) {
    let result = thermostatModeIconMap.get(mode)

    if (!result) {
        warn(`No (thermostat) icon title for ${mode}`)
        result = thermostatModeIconMap.get(HAD_DEVICE_THERMOSTAT_MODE_OFF)
    }

    return result
}

export const getThermostatTemperature = ({state, userTemperatureScale, tempKey}) => {
    const isNeedToConvert = userTemperatureScale === TEMP_UNIT_F

    if (isNeedToConvert) {
        return convertTemperatureMap.get(userTemperatureScale)(
            state?.thermostat?.[tempKey]
        )
    }

    return state?.thermostat?.[tempKey]
}

const temperatureKeyAccordingToMode = new Map([
    [HAD_DEVICE_THERMOSTAT_MODE_HEAT, HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_TARGET_HEAT],
    [HAD_DEVICE_THERMOSTAT_MODE_COOL, HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_TARGET_COOL],
    [
        HAD_DEVICE_THERMOSTAT_MODE_AUX_HEAT,
        HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_TARGET_HEAT,
    ],
])

export const resolveTemperatureKeyAccordingToMode = (mode) => {
    const tempKey = temperatureKeyAccordingToMode.get(mode)

    if (!tempKey) {
        if (mode === HAD_DEVICE_THERMOSTAT_MODE_OFF) {
            return null
        } else if (mode === HAD_DEVICE_THERMOSTAT_MODE_AUTO) {
            return null
        } else {
            warn(`No (thermostat) temperature key for ${mode}`)
        }
        return temperatureKeyAccordingToMode.get(HAD_DEVICE_THERMOSTAT_MODE_HEAT)
    }

    return tempKey
}

export default thermostatTypeStateTitle
