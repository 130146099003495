// libraries
import React from 'react'
import {compose} from 'redux'

import {withPermissionRejection} from 'containers/withPermission'

// application
import Table from 'ipmp-react-ui/Table'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

// utils
import {__} from 'utils/i18n'

import list from 'permissions/firmware/list'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import useSelection from 'hooks/useSelection'
import selectionPermission from 'permissions/firmware/selection'
import useUpgradablePanels from 'hooks/pages/useUpgradablePanels'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch} from 'modules/firmware/applianceSelection/actions'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account}) => <PanelCell {...{id, serial, account}} />,
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => <GroupCell {...{row: {id, name}}} />,
    },
    {
        name: () => __('Model'),
        render: ({model}) => model,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({fetch, init, deselectAll}) => {
        init()
        deselectAll()
        fetch()
    })
)(Table)

export default function FirmwareUpgradeTable() {
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    const fetchAction = useActions(fetch)
    const {rows, init, error, isLoading} = useUpgradablePanels()
    const {selection, select, deselect, deselectAll} = useSelection()

    return (
        <ConnectedTable
            rows={rows}
            fullHeight
            init={init}
            fetch={fetchAction}
            error={error}
            isLoading={isLoading}
            hasSelection={hasSelection}
            columns={columns}
            select={select}
            deselect={deselect}
            selection={selection}
            deselectAll={deselectAll}
            emptyMessage={__('No equipment')}
        />
    )
}
