// libraries
import {panelName} from 'constants/panelType'
import React from 'react'
import {compose} from 'redux'

// application
import Table from 'ipmp-react-ui/Table'
import {ReactComponent as FirstDiscoveryIcon} from 'ipmp-react-ui/icons/first-discovery.svg'
import {humanTime} from 'ipmp-react-ui/humanTime'
import Ava, {UnassignedAva} from 'ipmp-react-ui/Ava'
import Definition from 'ipmp-react-ui/Definition'

import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import list from 'permissions/panels/list'
import {ownerInfo} from 'permissions/panel/info/page'
import apps from 'permissions/panels/apps'
import selectionPermission from 'permissions/panels/selection'
import events from 'permissions/events/page'
import remoteInspections from 'permissions/remoteInspections/page'
import {status as billingStatusPermission} from 'permissions/panel/billing/actions'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import {useSearchScope} from 'hooks/useAutoFilters'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import User, {USER_TYPE_OWNER} from 'components/Entities/User'

import RRICell from './TableCell/RRICell'
import ApplicationCell from './TableCell/ApplicationCell'
import CommunicationCell from './TableCell/CommunicationCell'
import UnhandledEventsCell from './TableCell/UnhandledEventsCell'
import FaultsCell from './TableCell/FaultsCell'
import BillingCell from './TableCell/BillingCell'

// utils
import {__} from 'utils/i18n'

const allColumns = [
    {
        name: () => __('Panel'),
        width: 135,
        maxWidth: 180,
        render: ({id, serial, account}) => (
            <PanelCell id={id} serial={serial} account={account} multiLine />
        ),
    },
    {
        name: () => __('Owner'),
        tooltip: false,
        fixed: true,
        width: 55,
        render: ({
            customerName,
            customerEmail,
            customerPhone,
            customerAddress,
            customerRemark,
            customerRemark2,
            customerRemark3,
        }) => (
            <User
                name={customerName}
                email={customerEmail}
                phone={customerPhone}
                location={customerAddress}
                remark={customerRemark}
                remark2={customerRemark2}
                remark3={customerRemark3}
                type={USER_TYPE_OWNER}
                withTooltip
            />
        ),
        permission: ownerInfo,
    },
    {
        name: () => __('Group'),
        width: 120,
        maxWidth: 150,
        render: ({groupId: id, group: name, groupIsDealer: linkedToDealer, model}) => {
            const Group = id && <GroupCell row={{id, name, linkedToDealer}} />
            const Model = panelName(model) || <span className="empty">&mdash;</span>

            return <Definition title={Group} detail={Model} multiLine />
        },
    },
    {
        tooltip: false,
        width: 85,
        fixed: true,
        render: ({modules, serial}) => (
            <CommunicationCell serial={serial} modules={modules} />
        ),
    },
    {
        name: () => __('Events'),
        tooltip: false,
        permission: events,
        maxWidth: 95,
        render: ({serial, alerts, alarms, id}) => (
            <UnhandledEventsCell {...{alerts, alarms, serial, panelId: id}} />
        ),
    },
    {
        width: 44,
        fixed: true,
        tooltip: ({firstDiscoveryTimestamp}) =>
            __('First connection: ') +
            (firstDiscoveryTimestamp
                ? humanTime(firstDiscoveryTimestamp)
                : __('No data')),
        render: ({firstDiscoveryTimestamp}) => {
            if (firstDiscoveryTimestamp) {
                return <FirstDiscoveryIcon />
            }

            return <FirstDiscoveryIcon className={'icon--disabled'} />
        },
    },
    {
        width: 44,
        fixed: true,
        tooltip: ({user}) => user || __('Unassigned'),
        render: ({user, userId}) => {
            if (userId) {
                return <Ava small id={userId} name={user} />
            }

            return <UnassignedAva small />
        },
    },
    {
        name: () => __('Faults'),
        tooltip: false,
        render: ({faultsSince, faults}) => <FaultsCell {...{faultsSince, faults}} />,
    },
    {
        tooltip: false,
        width: 50,
        maxWidth: 120,
        align: 'center',
        permission: billingStatusPermission,
        component: BillingCell,
    },
    {
        name: () => __('Apps'),
        tooltip: false,
        permission: apps,
        width: 70,
        fixed: true,
        component: ApplicationCell,
    },
    {
        name: () => __('RI'),
        permission: remoteInspections,
        width: 40,
        fixed: true,
        tooltip: ({rriDate, features: {remoteInspection}}) => {
            if (remoteInspection) {
                return rriDate ? humanTime(rriDate) : __('RRI never initiated')
            }
        },
        render: ({id, rri, rriDate}) => <RRICell {...{panelId: id, rri, rriDate}} />,
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function PanelsTable(props) {
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    const {selection, select, deselect} = useSelection()
    const {columns} = useTableColumnsPermission(allColumns)
    useSearchScope(props.init)

    return (
        <ConnectedTable
            {...props}
            fullHeight
            select={select}
            columns={columns}
            deselect={deselect}
            selection={selection}
            hasSelection={hasSelection}
            emptyMessage={__('No equipment')}
            className="panels-page-panels-table"
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
