import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import InfoCards, {InfoCard} from 'ipmp-react-ui/InfoCards'

import BroadbandConnected from './BroadbandConnected'
import CellularConnected from './CellularConnected'
import CommonConnected from './CommonConnected'

import * as actions from 'modules/system/settings/actions'
import withLoader from 'containers/withLoader'
import __ from 'utils/i18n'
import {
    showSystemSettingsCellularConnectedModal,
    showSystemSettingsBroadbandConnectedModal,
    showSystemSettingsCommonConnectedModal,
    showSystemSettingsAddMessageBrokerModal,
} from 'modules/modals/actions'
import PropTypes from 'prop-types'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'
import MessageBrokers from './MessageBrokers'

const General = ({
    cellularConnectedModal,
    broadbandConnectedModal,
    commonConnectedModal,
    messageBrokersModal,
}) => {
    const cellularEditBtn = (
        <Button onClick={cellularConnectedModal} borderless Icon={IconEdit} />
    )
    const broadbandEditBtn = (
        <Button onClick={broadbandConnectedModal} borderless Icon={IconEdit} />
    )
    const commonConnectedEditBtn = (
        <Button onClick={commonConnectedModal} borderless Icon={IconEdit} />
    )
    const brokerButton = (
        <Button
            flat
            primary
            Icon={PlusIcon}
            onClick={messageBrokersModal}
            className="brokers-button"
            label={__('Add message broker')}
        />
    )
    return (
        <InfoCards cols={2} className="has-three-rows-element">
            <InfoCard
                title={__('Cellular Connected')}
                cols={2}
                headerButton={cellularEditBtn}
            >
                <CellularConnected />
            </InfoCard>
            <InfoCard
                title={__('Broadband Connected')}
                cols={2}
                headerButton={broadbandEditBtn}
            >
                <BroadbandConnected />
            </InfoCard>
            <InfoCard
                title={__('Common Connected')}
                cols={2}
                headerButton={commonConnectedEditBtn}
            >
                <CommonConnected />
            </InfoCard>
            <InfoCard
                title={__('Message Brokers')}
                cols={1}
                headerButton={brokerButton}
                clickable
                threeRows
            >
                <MessageBrokers />
            </InfoCard>
        </InfoCards>
    )
}

General.propTypes = {
    cellularConnectedModal: PropTypes.func.isRequired,
    broadbandConnectedModal: PropTypes.func.isRequired,
    commonConnectedModal: PropTypes.func.isRequired,
    messageBrokersModal: PropTypes.func.isRequired,
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    cellularConnected,
                    broadbandConnected,
                    commonConnected,
                    messageBrokers,
                },
            },
        }) => ({
            cellularConnected,
            broadbandConnected,
            commonConnected,
            messageBrokers,
            isLoading:
                cellularConnected.isLoading ||
                broadbandConnected.isLoading ||
                commonConnected.isLoading ||
                messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    fetchCellular: actions.fetchCellularConnected,
                    fetchBroadband: actions.fetchBroadbandConnected,
                    fetchCommon: actions.fetchCommonConnected,
                    fetchMessageBrokers: actions.fetchMessageBrokers,
                    showSystemSettingsCellularConnectedModal,
                    showSystemSettingsBroadbandConnectedModal,
                    showSystemSettingsCommonConnectedModal,
                    showSystemSettingsAddMessageBrokerModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                cellularConnectedModal: () =>
                    dispatchProps.showSystemSettingsCellularConnectedModal({
                        ...stateProps.cellularConnected,
                    }),
                broadbandConnectedModal: () =>
                    dispatchProps.showSystemSettingsBroadbandConnectedModal({
                        ...stateProps.broadbandConnected,
                    }),
                commonConnectedModal: () =>
                    dispatchProps.showSystemSettingsCommonConnectedModal(
                        stateProps.commonConnected
                    ),
                messageBrokersModal: () =>
                    dispatchProps.showSystemSettingsAddMessageBrokerModal(
                        stateProps.messageBrokers
                    ),
            }
        }
    ),
    withLoader(({fetchCellular, fetchBroadband, fetchCommon, fetchMessageBrokers}) => {
        fetchMessageBrokers()
        fetchCellular()
        fetchBroadband()
        fetchCommon()
    })
)(General)
