import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {createBasicConfiguration} from 'modules/forms/handlers'

const rules = {
    name: {
        presence: true,
        length: {
            maximum: 200,
        },
    },
}

export default function MakeBasicConfiguration({hide, panelId}) {
    const {form, handle, isLoading} = useForm(createBasicConfiguration)
    const {values, version} = useSelector(({panels}) => {
        const {configuration, exporting} = panels.configuration[panelId] || {}

        return {
            values: exporting,
            version: configuration && configuration.version,
        }
    })
    const {currentConfigId} = useSelector(
        ({panels}) => panels.configuration[panelId] || {}
    )

    const handler = ({name}) => {
        handle(panelId, name, version, values, currentConfigId)
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            header={__('Basic Configuration')}
        >
            <Input name="name" label={__('Basic configuration name')} />
        </ModalCardForm>
    )
}

MakeBasicConfiguration.propTypes = {
    hide: PropTypes.func,
    panelId: PropTypes.number,
}
