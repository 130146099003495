import React from 'react'

import useSelection from 'hooks/useSelection'
import useInstallers from 'hooks/pages/useInstallers'
import SelectionDropDown from 'components/SelectionDropDown'
import {selector} from 'permissions/installers/actions'
import {withPermissionVisibility} from 'containers/withPermission'

const ConnectedSelectionDropDown = withPermissionVisibility(selector)(SelectionDropDown)

export default function InstallersSelectionDropDown() {
    const {rows, total} = useInstallers()
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <ConnectedSelectionDropDown
            rows={rows}
            total={total}
            selection={selection}
            select={select}
            deselect={deselect}
            deselectAll={deselectAll}
            selectAll={selectAll}
        />
    )
}
