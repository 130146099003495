import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPin} from 'ipmp-react-ui/icons/autolearn/Pin.svg'

import __ from 'utils/i18n'

import {AutolearnDeviceCard} from '../AutolearnDeviceCard'
import ConfirmWithPinCodeInputFields from './ConfirmWithPinCodeInputFields'

export default function ConfirmWithPinCodeForm({serial, sensor, pinLength, setPin}) {
    const [compound, setCompound] = useState([])

    useEffect(() => {
        setPin(compound.join(''))
    }, [compound, setPin])

    const onChange = (index, value) => {
        setCompound(
            Array.from({length: pinLength}, (v, i) => (i === index ? value : compound[i]))
        )
    }

    return (
        <>
            <div className="autolearn-in-process">
                <IconPin />
                <div className="autolearn-description-content-wrapper">
                    <div className="autolearn-description-content-title">
                        {__('Pin Code')}
                    </div>
                    <div className="autolearn-description-content-message">
                        {__('Enter device PIN then press "Send PIN" button')}
                    </div>
                </div>
            </div>
            <div className="autolearn-device-card-wrapper">
                <AutolearnDeviceCard serial={serial} sensor={sensor} />
            </div>
            <div className="autolearn-pin-input-fields">
                <ConfirmWithPinCodeInputFields
                    pin={compound}
                    pinLength={pinLength}
                    onChange={onChange}
                />
            </div>
        </>
    )
}

ConfirmWithPinCodeForm.propTypes = {
    serial: PropTypes.string.isRequired,
    sensor: PropTypes.string.isRequired,
    pinLength: PropTypes.number.isRequired,
    setPin: PropTypes.func.isRequired,
}
