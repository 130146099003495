import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {RowAction} from 'ui/Row/Actions'

import {edit} from 'permissions/roles/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {showEditRole} from 'modules/modals/actions'
import {withPermissionVisibility} from 'containers/withPermission'

const EditRoleAction = ({id, disabled, children}) => {
    const onClick = useActions(() => showEditRole(id))

    return (
        <RowAction Icon={IconEdit} disabled={disabled} onClick={onClick}>
            {children}
        </RowAction>
    )
}

EditRoleAction.propTypes = {
    id: PropTypes.string.isRequired,
    isEditable: PropTypes.bool,
}
export default withPermissionVisibility(edit)(EditRoleAction)
