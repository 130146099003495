import {call, put, select, all} from 'redux-saga/effects'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {update} from 'modules/panelInteractiveUsers/store/actions'
import * as api from 'api/interactiveUsers'
import {getUsers} from 'api/panel/info'
import {getPanelUsersList} from 'constants/panelUsers'
import * as actions from '../actions'
import toIds from 'utils/toIds'

const selector = (state) => {
    const panelId = getPanelId(state)
    return {
        ...state.panelInteractiveUsers.list,
        panelId,
    }
}

export function* fetch() {
    const panelState = yield select(selector)
    const {panelId} = panelState

    const [{rows}, {maxUsersCount, reservedIds}] = yield all([
        call(api.panelInteractiveUsers, panelState),
        call(getUsers, panelId),
    ])

    const {users, count} = getPanelUsersList(rows, maxUsersCount, reservedIds)

    yield put(update(users, panelId))
    yield put(actions.receive(toIds(users), count))
}

fetch.onError = function* (error) {
    yield put(actions.receive(error))
}
