import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import Account from './Field/Account'
import Group from './Field/Group'
import Serial from './Field/Serial'
import {SimNumber} from './Field/SimNumber'

import {ACTIVATION_STATUS_NOT_DISCOVERED} from 'constants/panelActivationStatus'
import {__} from 'utils/i18n'

export function Fieldset({isNew, data, activationStatus}) {
    const {setSimNumber} = useSelector(
        ({features}) => features.store.byIds[data?.id] || {}
    )
    const isSimNumberVisible =
        activationStatus !== ACTIVATION_STATUS_NOT_DISCOVERED && !isNew

    return (
        <div className="form-field">
            <Serial isNew={isNew} serial={data.serial} />
            <Account isNew={isNew} account={data.account} />
            <Group groupName={data.group} groupId={data.groupId} />
            {isSimNumberVisible && (isNew || (!isNew && setSimNumber?.isEnabled)) && (
                <SimNumber disabled simNumber={data.simNumber} />
            )}
        </div>
    )
}

Fieldset.propTypes = {
    isNew: PropTypes.bool,
    data: PropTypes.shape({
        serial: PropTypes.string,
        account: PropTypes.string,
        group: PropTypes.string,
        groupId: PropTypes.number,
    }),
}

export const rules = {
    groupId: {
        presence: true,
    },
    serial: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{10}',
            flags: 'i',
            message: () => __('Panel ID must be a 10 digit hexadecimal'),
        },
    },
    account: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{4}',
            flags: 'i',
            message: () => __('Must be a 4 digit hexadecimal'),
        },
    },
}
