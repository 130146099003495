import React from 'react'
import {compose} from 'redux'

import disable from 'permissions/panel/devices/plink/ssh/disable'

import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import {usePlinkDebugCommands} from 'hooks/pages/useDevices'
import usePermission from 'hooks/usePermission'

const ButtonVisibleConfirmation = compose(withVisibility(), withConfirmation())(Button)

export default function DisableSSHButton({panelId, ...props}) {
    const {isPermitted} = usePermission(disable)
    const {disableSSH} = usePlinkDebugCommands(panelId)

    return (
        <ButtonVisibleConfirmation
            isVisible={isPermitted}
            onClick={disableSSH}
            title={__('Disable SSH')}
            message={__('Do you want to disable SSH?')}
            positive={__('Disable')}
            {...props}
        />
    )
}
