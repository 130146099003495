import {takeEvery, call, fork, select, put, all, take} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'

import {
    AUTHENTICATION_BLOCKED,
    AUTHENTICATION_REQUIRED,
    BAD_REQUEST_PARAMS,
} from 'constants/errorType'
import {TRUE} from 'constants/boolean'
import * as commands from 'modules/auth/commands'
import * as actions from 'modules/auth/sign/actions'
import * as serverTimeActions from 'modules/system/serverTime/actions'
import * as api from 'api/auth'
import {fetchServerTime} from 'api/about'

import {
    CONFIRMATION_CODE_SCREEN,
    LOGIN_SCREEN,
    OTP_CHANGE_PASSWORD_SCREEN,
    PHONE_NUMBER_CONFIRMATION_SCREEN,
} from 'constants/loginScreen'

import {snackShow} from 'modules/snacks'
import * as settingsActions from 'modules/settings/actions'
import * as serverSettingsActions from 'modules/serverSettings/actions'
import * as googleActions from 'modules/system/settings/actions'

import {restoreLanguage, setLanguage} from 'modules/locales/saga'
import {changeOTPPassword, editProfile, remindPassword} from 'modules/forms/handlers'
import {update as updatePermissions} from 'modules/auth/permissions/actions'

import {__} from 'utils/i18n'
import {
    addServiceWorkerErrorMessageListener,
    addServiceWorkerMessageListener,
    removeServiceWorkerErrorMessageListener,
    removeServiceWorkerMessageListener,
    sendSWMessage,
} from 'utils/serviceWorker/serviceWorkerUtils'
import isDevMode from 'utils/isDevMode'

import initWithDependency from './authUtils/initWithDependency'

const {authService, autologout} = initWithDependency()

export default function* () {
    yield all([
        authWorkerSaga(),
        takeEvery(actions.authEmailPassword, watchAuthEmailPassword),
        takeEvery(actions.setPhone, watchSetPhone),
        takeEvery(actions.sendCode, watchSendCode),
        takeEvery(actions.confirmCode, watchConfirmCode),
        takeEvery(actions.resendCode, watchResendCode),
        takeEvery(remindPassword.FAILURE, watchRemindPassword),
        takeEvery(remindPassword.SUCCESS, watchRemindPasswordSuccess),
        takeEvery(changeOTPPassword.SUCCESS, watchOTPPasswordSuccess),
        takeEvery(changeOTPPassword.FAILURE, watchOTPPasswordFail),
        takeEvery(actions.loggedOut, clearAuthCookie),
        takeEvery(actions.autologoutContinue, watchSetAutologout),
    ])
}

function clearAuthCookie() {
    document.cookie = document.cookie
        .split(';')
        .filter((s) => !s.match(/^ipmp2=/))
        .join(';')
}

function* watchAuthEmailPassword({payload}) {
    const {email, password} = payload
    try {
        yield put(actions.setLoading(true))
        const data = yield call(api.login, email, password)
        yield put(actions.setLoading(false))
        const {phone, isOTP, userConfirmed} = data

        if (isOTP) {
            yield put(actions.setEmail(email))
            yield put(actions.setLoginScreenForm(OTP_CHANGE_PASSWORD_SCREEN))
            return
        }

        if (phone) {
            yield put(
                actions.setLoginScreenForm(
                    userConfirmed
                        ? CONFIRMATION_CODE_SCREEN
                        : PHONE_NUMBER_CONFIRMATION_SCREEN
                )
            )
            yield put(actions.authEmailPasswordComplete(data))
            return
        }

        authService.doLogin(data)
        sendSWMessage({
            command: commands.COMMAND_LOGGED_IN,
            data,
        })

        yield setUserData(data)
    } catch (error) {
        if (error.type === BAD_REQUEST_PARAMS || error.type === AUTHENTICATION_BLOCKED) {
            yield put(actions.receive(error))
        }
        if (error.type !== BAD_REQUEST_PARAMS) {
            yield put(snackShow(error.message))
        }

        yield put(actions.setLoading(false))
    }
}

function* watchSetPhone({payload}) {
    const {phone} = payload
    try {
        yield call(api.setPhone, phone)
        yield put(actions.sendCode())
    } catch (error) {
        yield handleAuthError(error)
    } finally {
        yield put(actions.setLoading(false))
    }
}

function* watchSendCode() {
    try {
        yield call(api.sendCode)
    } catch (error) {
        yield handleAuthError(error)
    } finally {
        yield put(actions.setLoading(false))
    }
}

function* watchResendCode() {
    try {
        yield call(api.sendCode)
        yield put(snackShow(__('Code is resent')))
    } catch (error) {
        yield handleAuthError(error)
    }
}

function* handleAuthError(error) {
    if (error.type === BAD_REQUEST_PARAMS) {
        yield put(actions.receive(error))
    } else if (error.type === AUTHENTICATION_REQUIRED) {
        yield put(actions.receive(error))
        yield put(snackShow(error.message))
    } else {
        yield put(snackShow(error.message))
        yield put(actions.setLoading(false))
    }
}

function* watchConfirmCode({payload}) {
    const {code} = payload
    try {
        const data = yield call(api.confirmCode, code)
        authService.doLogin(data)
        sendSWMessage({
            command: commands.COMMAND_LOGGED_IN,
            data,
        })
        yield setUserData(data)
    } catch (error) {
        yield handleAuthError(error)
    }
}

function* authWorkerSaga() {
    let authData = undefined

    if (localStorage.getItem('isLoggedIn') === TRUE) {
        authData = yield authService.check()
    }

    if (!authData) {
        // NO AUTH
        yield restoreLanguage()
        yield put(actions.checked())
    } else {
        // LOGGED_IN
        yield setUserData(authData)
    }

    yield fork(sendMessages)

    const channel = yield call(createChannel)

    while (true) {
        const {command, data} = yield take(channel)

        switch (command) {
            case commands.COMMAND_LOGGED_IN:
                yield setUserData(data)
                break

            case commands.COMMAND_LOGGED_OUT:
                yield put(actions.loggedOut())
                break

            case commands.COMMAND_NO_AUTH:
                yield restoreLanguage()
                yield put(actions.checked())
                break

            case commands.COMMAND_UPDATED:
                if (data.user) {
                    yield put(actions.update(data.user))
                }

                if (data.isNotSend) {
                    yield put(
                        settingsActions.updateFromServiceWorkerWithoutSend(data.settings)
                    )
                }

                if (data.settings && !data.isNotSend) {
                    yield put(settingsActions.updateFromServiceWorker(data.settings))
                }

                if (data.permissions) {
                    yield put(snackShow(__('Your privileges was changed')))
                    yield put(updatePermissions(data.permissions))
                }

                if (data.serverSettings) {
                    yield put(
                        googleActions.updateGoogleSettings(data.serverSettings.google)
                    )
                }

                break
        }
    }
}

function createChannel() {
    return eventChannel((emit) => {
        const errorMessageListener = (e) => {
            console.error('auth received error', e)
        }

        addServiceWorkerErrorMessageListener(errorMessageListener)

        const messageListener = ({data}) => {
            if (isDevMode()) {
                console.log('data', data)
            }
            authService.receiveMessage(data)
            emit(data)
        }

        addServiceWorkerMessageListener(messageListener)

        return () => {
            removeServiceWorkerErrorMessageListener(errorMessageListener)
            removeServiceWorkerMessageListener(messageListener)
        }
    })
}

function* sendMessages() {
    if (!window.addEventListener) {
        // running in tests
        return
    }

    const interact = () => {
        const commandInteract = {
            command: commands.COMMAND_INTERACT,
        }
        sendSWMessage(commandInteract)
        authService.receiveMessage({data: commandInteract})
    }

    window.addEventListener('mousedown', interact, true)
    window.addEventListener('keydown', interact, true)

    yield all([
        takeEvery(actions.logout, watchLogout),
        takeEvery(editProfile.SUCCESS, watchEditProfile),
        takeEvery(Object.values(settingsActions), watchChangeSettings),
        takeEvery(serverSettingsActions.updateFromServiceWorker, watchChangeSettings),
    ])
}

function* watchLogout() {
    const user = yield select((state) => state.auth.sign.user)
    if (!user) {
        return
    }

    authService.doLogout()
    sendSWMessage({
        command: commands.COMMAND_LOGGED_OUT,
    })

    yield put(actions.loggedOut())
}

function* watchChangeSettings(action) {
    if (!navigator?.serviceWorker?.controller) {
        return
    }

    if (
        ![
            settingsActions.updateFromServiceWorker.toString(),
            settingsActions.resetMenuOrderUpdateFlag.toString(),
            settingsActions.updateMenuOrder.toString(),
            serverSettingsActions.updateFromServiceWorker.toString(),
        ].includes(action.type)
    ) {
        const settings = yield select((state) => state.settings)
        const systemSettings = yield select(
            (state) => state.system.settings.systemSettings
        )
        sendSWMessage({
            command: commands.COMMAND_UPDATED,
            data: {
                settings,
                isNotSend: action.type === settingsActions.updateMenuOrder.toString(),
                serverSettings: systemSettings,
            },
        })
    }
}

function* setUserData({settings, permissions, user, serverSettings}) {
    yield put(settingsActions.update(settings))
    yield put(updatePermissions(permissions))
    yield put(googleActions.updateGoogleSettings(serverSettings.google))
    const info = yield fetchServerTime()
    yield put(serverTimeActions.receive(info))

    const {language} = yield select(({settings}) => settings)
    yield setLanguage(language)

    yield put(actions.receive(user))
    yield put(actions.loggedIn())
}

function* watchEditProfile({meta: {email, phone, countryId}}) {
    let user = yield select((state) => state.auth.sign.user)
    if (user.email !== email) {
        authService.doLogout()
        sendSWMessage({
            command: commands.COMMAND_LOGGED_OUT,
        })

        yield put(actions.loggedOut())
        return
    }

    const countryList = yield select((state) => state.countries.byIds)
    const countryName = countryId !== null ? countryList[countryId].name : null

    user = {
        email,
        phone,
        countryId,
        countryName,
    }

    yield put(actions.update(user))

    sendSWMessage({
        command: commands.COMMAND_UPDATED,
        data: {user},
    })
}

function* watchRemindPassword({payload: {error}}) {
    if (error) {
        yield put(snackShow(error))
    }
}

function* watchRemindPasswordSuccess() {
    yield put(snackShow(__('Recovery email is sent if it’s registered')))
    yield put(actions.setLoginScreenForm(LOGIN_SCREEN))
}

function* watchOTPPasswordFail() {
    yield put(snackShow(__('Authentication required')))
    yield put(actions.setLoginScreenForm(LOGIN_SCREEN))
}

function* watchOTPPasswordSuccess({meta: {newPassword}}) {
    const email = yield select((state) => state.auth.sign.email)
    yield put(actions.authEmailPassword(email, newPassword))
}

function* watchSetAutologout() {
    authService.receiveMessage({
        data: {
            command: commands.COMMAND_UPDATED,
            data: {
                settings: {
                    autologout: yield select((state) => state.settings.autologout),
                },
            },
        },
    })

    yield call(autologout.continue)
}
