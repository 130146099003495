import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRetry} from 'ipmp-react-ui/icons/retry.svg'

import {isAllowRetry} from 'constants/processTypes'
import useUpgradeStatus from 'hooks/pages/useUpgradeStatus'
import usePermission from 'hooks/usePermission'
import {retry as retryPermission} from 'permissions/firmware/upgradeStatus'

export default function UpgradeStatusRetryButton({id, type}) {
    if (!isAllowRetry(type)) {
        return null
    }

    const {retry} = useUpgradeStatus()

    const {isPermitted} = usePermission(retryPermission)
    if (!isPermitted) {
        return null
    }

    const onClick = () => retry(id)

    return <Button flat small primary Icon={IconRetry} onClick={onClick} />
}

export function RunnerUpgradeStatusRetryButton({id, type}) {
    if (!isAllowRetry(type)) {
        return null
    }

    const {retry} = useUpgradeStatus()

    const {isPermitted} = usePermission(retryPermission)
    if (!isPermitted) {
        return null
    }

    const onClick = (e) => {
        e.stopPropagation()
        retry(id)
    }

    return (
        <Button
            small
            borderless
            Icon={IconRetry}
            onClick={onClick}
            className="batchInfo-list-item-retryProcess"
        />
    )
}
