import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Radiobox from 'ipmp-react-ui/Radiobox'
import {ReactComponent as IconToolTip} from 'ipmp-react-ui/icons/question.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'

import __ from 'utils/i18n'
import {
    MENU_ORDER_TYPE_AUTO,
    MENU_ORDER_TYPE_DEFAULT,
    MENU_ORDER_TYPE_MANUAL,
} from 'constants/mainMenu'

export default function OrderType({onChange, savedType}) {
    const [orderType, setOrderType] = useState(savedType ?? MENU_ORDER_TYPE_DEFAULT)

    const onChangeOrderType = (e) => {
        if (!e.target.checked) {
            return null
        }

        setOrderType(e.target.name)
        onChange(e.target.name)
    }

    return (
        <>
            <h3 className="form-section-header">{__('Order')}</h3>
            <Radiobox
                label={
                    <span>
                        {__('Default')}
                        <Tooltip
                            tooltip={__(
                                'When Default order is selected, menu has default order'
                            )}
                            inline
                            orientation="top"
                        >
                            <IconToolTip />
                        </Tooltip>
                    </span>
                }
                name={MENU_ORDER_TYPE_DEFAULT}
                checked={orderType === MENU_ORDER_TYPE_DEFAULT}
                onChange={onChangeOrderType}
            />

            <Radiobox
                label={
                    <span>
                        {__('Manual')}
                        <Tooltip
                            tooltip={__(
                                'When manual order is selected, menu has order on user choice. Please use "Preview" widget to order menu items'
                            )}
                            inline
                            orientation="top"
                        >
                            <IconToolTip />
                        </Tooltip>
                    </span>
                }
                name={MENU_ORDER_TYPE_MANUAL}
                checked={orderType === MENU_ORDER_TYPE_MANUAL}
                onChange={onChangeOrderType}
            />

            <Radiobox
                label={
                    <span>
                        {__('Auto')}
                        <Tooltip
                            tooltip={__(
                                'When auto is selected, the most frequently visited items go to the top'
                            )}
                            inline
                            orientation="top"
                        >
                            <IconToolTip />
                        </Tooltip>
                    </span>
                }
                name={MENU_ORDER_TYPE_AUTO}
                checked={orderType === MENU_ORDER_TYPE_AUTO}
                onChange={onChangeOrderType}
            />
        </>
    )
}

OrderType.propTypes = {
    onChange: PropTypes.func.isRequired,
}
