import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {rebootPlink} from 'modules/forms/handlers'

const rules = {
    seconds: {
        presence: true,
        numericality: true,
    },
}

export default function RebootPlink({hide, panelId}) {
    const {form, handle, isLoading} = useForm(rebootPlink)

    const handler = (data) => {
        handle(panelId, data)
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            header={__('Reboot Plink')}
            submitLabel={__('Reboot')}
        >
            <Input label={__('Seconds')} name="seconds" defaultValue={3} />
        </ModalCardForm>
    )
}

RebootPlink.propTypes = {
    panelId: PropTypes.number,
    hide: PropTypes.func,
}
