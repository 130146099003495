import React from 'react'

import {withPermissionRejection} from 'containers/withPermission'
import {connectedPanels} from 'permissions/dashboard/charts'
import ConnectedPanelsBase from 'components/Dashboard/ConnectedPanels'
import {useConnectedPanels, useOnline} from 'hooks/pages/useDashboard'

const ConnectedPanelsRejection =
    withPermissionRejection(connectedPanels)(ConnectedPanelsBase)

export default function ConnectedPanels() {
    const {onlineData} = useOnline()

    const {
        data,
        from,
        to,
        fetchConnectedPanels,
        startPollConnectedPanels,
        stopPollConnectedPanels,
    } = useConnectedPanels()

    return (
        <ConnectedPanelsRejection
            data={data}
            onlineData={onlineData}
            from={from}
            to={to}
            fetchConnectedPanels={fetchConnectedPanels}
            startPollConnectedPanels={startPollConnectedPanels}
            stopPollConnectedPanels={stopPollConnectedPanels}
        />
    )
}
