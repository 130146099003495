import {mapReceiveGroup, mapCustomization} from './groups.map'
import {mapRole} from 'api/roles'
import {get, post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

export function receiveGroup(id) {
    return get('/group/info', {id}).then((data) => mapReceiveGroup(data))
}

export function receiveGroupNotificationsEventProfiles(id) {
    return get(`/group/${id}/notifications/event_profiles`)
}

export function receiveGroupNotificationsEndUser(id) {
    return get(`/group/${id}/notifications/end_user`)
}

export function updateGroupNotificationsEventProfiles(id, {eventProfile}) {
    return post(`/group/${id}/notifications/event_profiles`, {eventProfile}).catch(
        mapValidationErrors
    )
}

export function updateGroupNotificationsEndUser(id, {notificationProfile}) {
    return post(`/group/${id}/notifications/end_user`, {notificationProfile}).catch(
        mapValidationErrors
    )
}

export function persistCentralStationLinks(groupId, centralStationId, profiles) {
    return post('/group/savecslinks', {
        form: {
            groupId,
            centralStationId,
            profiles,
        },
    }).catch(mapValidationErrors)
}

export function receiveGroupPermissions(groupIds) {
    return get('/group/getpermissions', {utg_id: groupIds}).then((data) =>
        Object.values(data).map(mapRole)
    )
}

export function receiveAllGroupCustomizations() {
    return get('/group/app/customization').then((data) =>
        Object.values(data).map(mapCustomization)
    )
}

export function receiveGroupCustomization(groupId) {
    return get(`/group/${groupId}/app/customization`).then((data) =>
        data ? mapCustomization(data) : null
    )
}

export function persistGroupCustomization(groupId, customizationId) {
    return post(`/group/${groupId}/app/customization/${customizationId}`).catch(
        mapValidationErrors
    )
}
