import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {fetch} from 'modules/groups/one/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {selectGroupById} from 'modules/groups/store/selectors'

export default function useGroup(id) {
    const dispatch = useDispatch()
    const group = useSelector((state) => selectGroupById(state, id))

    useEffect(() => {
        dispatch(fetch(parseInt(id)))
    }, [id, dispatch])

    return {
        group,
        isLoading: !group,
        ...useActions({
            fetch: () => fetch(parseInt(id)),
        }),
    }
}
