import React, {useEffect} from 'react'

import Page from 'ipmp-react-ui/Page'

import {withPermissionRejection} from 'containers/withPermission'
import page from 'permissions/dashboard/page'

import DashboardCharts from './DashboardCharts'
import {useDashboard} from 'hooks/pages/useDashboard'

const ConnectedPage = withPermissionRejection(page)(Page)

export default function DashboardPage() {
    const {
        startPollConnectedPanels,
        stopPollConnectedPanels,
        startPollEventsRate,
        stopPollEventsRate,
        startPollOnline,
        stopPollOnline,
        startPollAllProcesses,
        stopPollAllProcesses,
    } = useDashboard()

    useEffect(() => {
        startPollConnectedPanels()
        startPollEventsRate()
        startPollOnline()
        startPollAllProcesses()

        return () => {
            stopPollConnectedPanels()
            stopPollEventsRate()
            stopPollOnline()
            stopPollAllProcesses()
        }
    }, [])

    return <ConnectedPage Content={DashboardCharts} />
}
