import React from 'react'

import path from 'utils/path'
import CentralStationsPage from './CentralStationsPage'

export default class {
    path = path('centralStations')

    element = (<CentralStationsPage />)
}
