import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

const rules = {
    globalServerApptype: {},
    maxInteractiveConnections: {
        presence: {
            message: () =>
                __(
                    'You should define Limit of Concurrent Interactive Connections per Server'
                ),
        },
        numericality: {
            onlyInteger: true,
        },
    },
    maxInteractiveConnectionsPerPanel: {
        presence: {
            message: () =>
                __(
                    'You should define Limit of Concurrent Interactive Sessions per Panel'
                ),
        },
        numericality: {
            onlyInteger: true,
        },
    },

    requireUserInstallerApprovalTimeout: {
        presence: (value, attributes) =>
            attributes.doRequireUserInstallerApproval !== null,
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            lessThanOrEqualTo: 360,
        },
    },
}

export default function InteractiveSessionSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.InteractiveSession)

    const [doRequireUserInstallerApproval, setDoRequireUserInstallerApproval] = useState(
        data.doRequireUserInstallerApproval
    )

    const handleRequireUserInstallerApproval = (e) =>
        setDoRequireUserInstallerApproval(!!e.target.checked)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Edit Iteractive Session Settings')}
        >
            <Input
                label={__('Application Type')}
                name="globalServerApptype"
                defaultValue={data.globalServerApptype}
            />

            <Input
                label={__('Limit of Concurrent Interactive Connections per Server')}
                name="maxInteractiveConnections"
                defaultValue={data.maxInteractiveConnections}
            />

            <Input
                label={__('Limit of Concurrent Interactive Sessions per Panel')}
                name="maxInteractiveConnectionsPerPanel"
                defaultValue={data.maxInteractiveConnectionsPerPanel}
            />

            <Checkbox
                label={__('Enable Customer App for New Incoming Panels')}
                name="doAutoEnableUserApp"
                defaultChecked={data.doAutoEnableUserApp}
            />

            <Checkbox
                label={__('Enable Installer App for New Incoming Panels')}
                name="doAutoEnableConfiguratorApp"
                defaultChecked={data.doAutoEnableConfiguratorApp}
            />

            <Checkbox
                label={__('Enable DLS service')}
                name="isDlsServiceEnabled"
                defaultChecked={data.isDlsServiceEnabled}
            />

            <Checkbox
                label={__('Approve Installer Access by Customer')}
                name="doRequireUserInstallerApproval"
                defaultChecked={data.doRequireUserInstallerApproval}
                onChange={handleRequireUserInstallerApproval}
            />

            {doRequireUserInstallerApproval && (
                <Input
                    label={__('Installer Access Timeout [Minutes]')}
                    name="requireUserInstallerApprovalTimeout"
                    defaultValue={data.requireUserInstallerApprovalTimeout}
                />
            )}
        </ModalCardForm>
    )
}

InteractiveSessionSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        doAutoEnableUserApp: PropTypes.bool,
        doAutoEnableConfiguratorApp: PropTypes.bool,
        isDlsServiceEnabled: PropTypes.bool,
        globalServerApptype: PropTypes.string,
        maxInteractiveConnections: PropTypes.number,
        maxInteractiveConnectionsPerPanel: PropTypes.number,
        doRequireUserInstallerApproval: PropTypes.bool,
    }),
}
