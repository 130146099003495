import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {selectPgmActivationTimeSlot} from 'modules/system/settings/selectors'

const PgmActivationTimeSlot = () => {
    const {enabled, timeWindow, automationDevicesEnabled} = useSelector(
        selectPgmActivationTimeSlot
    )

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Activation')}</InfoCardTileTitle>
                <InfoCardTileContent>{convertConfigValue(!!enabled)}</InfoCardTileContent>
            </InfoCardTile>
            {enabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>
                        {__('Time Window after Alarm [SEC]')}
                    </InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(timeWindow)}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Display Z-wave and PowerG+ devices')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(!!automationDevicesEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default PgmActivationTimeSlot
