import {get} from 'api/http'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import {keysMap} from 'api/events.map'

export function suggest(fields, start = 0, count = 5, panelId) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('event/suggestevents', {suggest, start, count, panelId}).then(
        mapSuggests(fields, keysMap)
    )
}
