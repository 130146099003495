import {selectBatchIsTemporary} from 'modules/batches/widget/selectors'
import {all, call, put, select, race, take, delay} from 'redux-saga/effects'
import {POLL_BATCH_RUNNERS} from 'configs/pollers'
import toIds from 'utils/toIds'

import * as actions from './actions'
import * as api from 'api/runners'

import {update} from 'modules/runners/store/actions'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'

export default function* () {
    yield all([
        createPollerSaga(actions.startPoll, actions.stopPoll, interval, poll, true),
    ])
}

function* interval() {
    yield race({
        a: take(actions.reset),
        d: delay(POLL_BATCH_RUNNERS),
    })
}

function* poll() {
    const {perPage, batchId} = yield select((store) => store.batches.batchRunners)
    const isTemporary = yield select((store) => selectBatchIsTemporary(store, batchId))

    if (!isTemporary) {
        const {rows, count} = yield call(api.fetch, {batchId, perPage})

        yield put(update(rows))
        yield put(actions.receiveByBatchId(toIds(rows), count))
    }
}

poll.onError = function* (error) {
    yield put(actions.receiveByBatchId(error))
}
