import React from 'react'
import {useSelector} from 'react-redux'

import ToggleBox from 'ipmp-react-ui/ToggleBox'
import Select, {Option} from 'ipmp-react-ui/Select'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {useActions} from 'modules/higherOrder/useActions'
import {setSystemSettings} from 'modules/forms/handlers'
import {selectMfa} from 'modules/system/settings/selectors'
import {showConfirmationModal} from 'modules/modals/actions'

const rules = {
    smsBroker: {
        presence(value, attributes) {
            return !!attributes.mfaEnabled
        },
    },
}

const confirmOnEnableMfaText = () => (
    <>
        <p>
            <b>{__('Warning:')}</b>&nbsp;
            {__(
                'By continuing with enabling MFA, all logged-in users will be logged out.'
            )}
        </p>
        <br />
        <p>
            {__(
                'Note: for using MFA phone number should be relevant for getting access code. User will be blocked if incorrect code is entered few times. Do you want to proceed?'
            )}
        </p>
    </>
)

const toggleLabel = (status) => (
    <span className="mfaGeneral-form-enable-toggle">
        {__('MFA Status')}
        <em>({status})</em>
    </span>
)

export default function MfaGeneral({hide}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.MFAGeneral)
    const {mfaEnabled, smsBroker, messageBrokers, ...rest} = useSelector(selectMfa)
    const showConfirmation = useActions(showConfirmationModal)

    const onSubmit = (data) => {
        const handleData = {
            ...rest,
            smsBroker: parseInt(data.smsBroker),
            mfaEnabled: data.mfaEnabled === 'on',
        }

        if (!handleData.mfaEnabled) {
            handle(handleData)
            return
        }

        showConfirmation(() => handle(handleData), {
            message: confirmOnEnableMfaText(),
            title: __('Submit form'),
        })
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={onSubmit}
            isLoading={isLoading}
            className="mfaGeneral-form"
            header={__('General')}
        >
            <ToggleBox
                class
                name="mfaEnabled"
                label={toggleLabel(__('Enabled'))}
                labelOff={toggleLabel(__('Disabled'))}
                defaultChecked={mfaEnabled}
            />
            <Select
                name="smsBroker"
                optional={__('Without message broker')}
                defaultValue={smsBroker}
                label={__('SMS-broker')}
            >
                {messageBrokers.map(({id, name}) => (
                    <Option key={id} label={name} value={id} />
                ))}
            </Select>
        </ModalCardForm>
    )
}
