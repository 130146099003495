import React from 'react'

import {REPORTS} from 'constants/features'
import {useActions} from 'modules/higherOrder/useActions'
import {withSelectionFeature} from 'containers/withFeature'
import {showCreateReportModal} from 'modules/runners/store/actions'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'
import {CreateReportBaseButton} from 'pages/Panels/GridBar/Buttons/CreateReportButton'

const CreateReportSelectionFeature = withSelectionFeature(
    REPORTS,
    selectSelectedRunnersFeature
)(CreateReportBaseButton)

export default function CreateReportButton() {
    const onClick = useActions(showCreateReportModal)

    return <CreateReportSelectionFeature onClick={onClick} />
}
