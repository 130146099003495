import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/roles/bar'
import RolesPager from './GridBar/RolesPager'
import AddRoleButton from './GridBar/AddRoleButton'
import RemoveRolesButton from './GridBar/RemoveRolesButton'
import {withPermissionVisibility} from 'containers/withPermission'
import RolesSelectionDropDown from './GridBar/RolesSelectionDropDown'

export const RolesGridBar = () => (
    <Bar>
        <RolesSelectionDropDown />
        <RemoveRolesButton />

        <BarSpace />

        <RolesPager />

        <AddRoleButton />
    </Bar>
)

export default withPermissionVisibility(bar)(RolesGridBar)
