import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import {assign} from 'permissions/interactiveUsers/actions'
import {showAssignUserToPanelModal} from 'modules/modals/actions'

export default function InteractiveUserAssignButton({panelUserId, isPinSet}) {
    const {showAssignUserToPanelModal: showAssignUserToPanelModalAction} = useActions({
        showAssignUserToPanelModal,
    })
    const panelId = useRouterPanelId()
    const panelsByIds = useSelector((state) => state.panelInteractiveUsers.store)
    const {isAllowedAssignInteractiveUsers} = usePermission({
        isAllowedAssignInteractiveUsers: assign,
    })

    if (!isAllowedAssignInteractiveUsers) {
        return null
    }

    if (!isPinSet) {
        return (
            <Tooltip tooltip={__('Unavailable for inactive panel user')}>
                <Button
                    flat
                    primary
                    disabled
                    Icon={IconPlus}
                    label={__('Assign Customer')}
                />
            </Tooltip>
        )
    }

    const onClick = () =>
        showAssignUserToPanelModalAction({
            panelId,
            hasPanelUser: Object.values(panelsByIds.byIds[panelId]).some(
                ({isAssign, isPinSet}) => !isAssign && isPinSet
            ),
            panelUserId,
        })

    return (
        <Button
            flat
            primary
            Icon={IconPlus}
            onClick={onClick}
            label={__('Assign Customer')}
        />
    )
}

InteractiveUserAssignButton.propTypes = {
    panelUserId: PropTypes.number,
}
