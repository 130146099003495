import configurationValueFactory from 'components/Configuration/Row/configurationValueFactory'
import React from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import classes from 'classnames'
import Button from 'ipmp-react-ui/Button'

import {ReactComponent as IconDropDown} from 'ipmp-react-ui/icons/drop-down.svg'
import {ReactComponent as IconUndo} from 'ipmp-react-ui/icons/undo.svg'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'
import {__} from 'utils/i18n'

import {
    BITSET_TYPE,
    CODE_HEX_TYPE,
    CODE_TYPE,
    LIST_TYPE,
} from 'constants/panelConfiguration'

import BitsetViewer from 'components/Configuration/RowViewer/BitsetViewer'

export default function ConfigurationRow({
    isEditable = true,
    hasUndo = true,
    onChange,
    item,
    onUndo,
    onSetExport,
    backup,
    isShowExportCheckbox,
    isChanged,
    isExported,
    changed,
}) {
    const handleRestoreFromBackup = () => {
        onChange(item.key, backup)
    }

    const handleUndo = () => {
        if (onUndo) {
            onUndo(item.key)
        } else {
            onChange(item.key, item.val)
        }
    }

    const handleOnCheck = (e) => {
        onSetExport(item.key, e.target.checked, item.val)
    }

    function isRfId() {
        return /RFID\+/.test(item.key)
    }

    const handleChange = (value, isValid = true) => {
        onChange(item.key, value, isValid)
    }

    const renderElement = () => {
        const isChanged = changed !== undefined

        if (isRfId()) {
            // can't change RFID
            return item.val
        }
        const ValueComponent = configurationValueFactory(item.type)

        return (
            <ValueComponent
                item={item}
                value={isChanged ? changed : item.val}
                onChange={handleChange}
                isChanged={isChanged}
            />
        )
    }

    const getValue = () => {
        const {type, val, items} = item

        let i

        switch (type) {
            case CODE_HEX_TYPE:
            case CODE_TYPE:
                return '****'
            case LIST_TYPE:
                i = items.find(([v]) => v === val)

                if (i) {
                    return i[1]
                }

                return val || <div className="empty">{__('Not set')}</div>
            case BITSET_TYPE:
                return <BitsetViewer item={item} />
            default:
                return val || <div className="empty">{__('Not set')}</div>
        }
    }

    const renderBackup = () => {
        const {type, items} = item

        let i

        switch (type) {
            case CODE_HEX_TYPE:
            case CODE_TYPE:
                return '****'
            case BITSET_TYPE:
                return <BitsetViewer item={{...item, val: backup}} />
            case LIST_TYPE:
                i = items.find((r) => r[0] === backup)
                return i && i[1]
            default:
                return nl2br(backup)
        }
    }

    const isCheckable = () => {
        return isShowExportCheckbox && item.export
    }

    const getRowContent = () => {
        return isEditable ? renderElement() : getValue()
    }

    const renderUndo = () => {
        if (!hasUndo || !isChanged) {
            return null
        }

        return (
            <Button
                Icon={IconUndo}
                small
                borderless
                className="configuration-undo"
                onClick={handleUndo}
            />
        )
    }

    return (
        <div
            className={classes('form-row', {
                'form-row--changed': isChanged,
                'form-row--highlight': backup !== undefined,
            })}
        >
            <OverflowTextTooltip className="form-row-label">
                {isCheckable() ? (
                    <Checkbox
                        label={item.name}
                        onChange={handleOnCheck}
                        checked={isExported || false}
                    />
                ) : (
                    item.name
                )}
            </OverflowTextTooltip>

            <div className="form-row-content" key={item.key}>
                {getRowContent()}
                {renderUndo()}
            </div>

            {backup !== undefined && (
                <div className="form-row-content configuration-backup">
                    {!isRfId() && isEditable && (
                        <Button
                            Icon={IconDropDown}
                            small
                            borderless
                            className="configuration-backup-restore"
                            onClick={handleRestoreFromBackup}
                        />
                    )}
                    <OverflowTextTooltip className="form-row-value">
                        {renderBackup()}
                    </OverflowTextTooltip>
                </div>
            )}
        </div>
    )
}

ConfigurationRow.propTypes = {
    item: PropTypes.object.isRequired,
    changed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    backup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isShowExportCheckbox: PropTypes.bool,
    isExported: PropTypes.bool,
    isEditable: PropTypes.bool,
    isChanged: PropTypes.bool,
    hasUndo: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSetExport: PropTypes.func,
    onUndo: PropTypes.func,
}
