import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const fetch = createAction('GROUPS/NOTIFICATION_SETTINGS/END_USER/FETCH')
export const setLoading = createAction(
    'GROUPS/NOTIFICATION_SETTINGS/END_USER/SET_LOADING',
    (isLoading = true) => isLoading
)

export const receive = createAction('GROUPS/NOTIFICATION_SETTINGS/END_USER/RECEIVE')
export const update = createAction(
    'GROUPS/NOTIFICATION_SETTINGS/END_USER/UPDATE',
    toArray
)
