import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

const CellularConnected = ({data, messageBroker}) => {
    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Enable Auto-Enroll')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.isAutoEnrollmentEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Message Broker for WakeUp')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(messageBroker && messageBroker.name)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Number of Retries')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.retriesMax)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Retries Interval [seconds]')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.retryInterval)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('WakeUp panel on user initiated discovery')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.wakeupOnUserDiscovery)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

CellularConnected.propTypes = {
    data: PropTypes.shape({
        isAutoEnrollmentEnabled: PropTypes.bool,
        messageBroker: PropTypes.number,
        retriesMax: PropTypes.number,
        retryInterval: PropTypes.number,
        wakeupOnUserDiscovery: PropTypes.bool,
    }),
    messageBroker: PropTypes.object,
}

export default compose(
    connect(
        ({
            system: {
                settings: {cellularConnected, messageBrokers},
            },
        }) => {
            return {
                messageBroker: messageBrokers.data.find(
                    (broker) => broker.id == cellularConnected.data.messageBroker
                ),
                ...cellularConnected,
            }
        }
    )
)(CellularConnected)
