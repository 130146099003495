import React from 'react'

import Page from 'ipmp-react-ui/Page'

import BasicConfigurationsTable from './BasicConfigurationsTable'
import BasicConfigurationsGridBar from './BasicConfigurationsGridBar'

export default function BasicConfigurationsPage() {
    return <Page Bar={BasicConfigurationsGridBar} Content={BasicConfigurationsTable} />
}
