import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import RoleForm from './RoleForm'
import useForm from 'hooks/useForm'
import {editRole} from 'modules/forms/handlers'

export default function EditRole({hide, roleId}) {
    const {form, handle, isLoading} = useForm(editRole)
    const data = useSelector(({roles}) => roles.store.byIds[roleId])

    const handler = (data) => handle(roleId, data)

    return (
        <RoleForm
            {...form}
            hide={hide}
            data={data}
            handle={handler}
            isLoading={isLoading}
            header={__('Edit role')}
        />
    )
}
