import {put, call} from 'redux-saga/effects'
import * as actions from '../actions'
import * as api from 'api/panels'
import {snackShow} from 'modules/snacks'

export default function* watchRefresh({payload: panelIds}) {
    try {
        yield put(actions.setRefreshing())
        const data = yield call(api.refresh, {filters: {id: panelIds}})
        yield put(actions.update(data.rows))
        yield put(actions.setRefreshing(false))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
