import React from 'react'

import {ReactComponent as CheckIcon} from 'ipmp-react-ui/icons/circle-check.svg'
import {ReactComponent as LockIcon} from 'ipmp-react-ui/icons/locker.svg'
import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/circle-pause.svg'
import {ReactComponent as MinusIcon} from 'ipmp-react-ui/icons/circle-minus.svg'
import {ReactComponent as QuestionIcon} from 'ipmp-react-ui/icons/question.svg'

import {__} from 'utils/i18n'

export const STATUS_ONLINE = 'online'
export const STATUS_OFFLINE = 'offline'
export const STATUS_SUSPENDED = 'suspended'
export const STATUS_BLOCKED = 'blocked'

export const getUserStatus = (isEnabled, isBlocked, isBlockedMfa, isOnline) => {
    switch (true) {
        case isBlockedMfa:
            return {title: __('Blocked'), icon: <LockIcon />}
        case isEnabled && isOnline:
            return {title: __('Online'), icon: <CheckIcon />}
        case isEnabled && !isOnline:
            return {title: __('Offline'), icon: <MinusIcon />}
        case !isEnabled:
            return {title: __('Suspended'), icon: <PauseIcon />}
        default:
            return {title: __('Unknown status'), icon: <QuestionIcon />}
    }
}
