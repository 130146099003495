import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import {selectStateTypeNameById} from 'modules/stateTypes/store/selectors'

export default function StateTypeCell({row: {stateId}}) {
    const {name} = useSelector((state) => selectStateTypeNameById(state, {id: stateId}))

    return (
        <OverflowTextTooltip className="evenRules-elements-name">
            {name}
        </OverflowTextTooltip>
    )
}

StateTypeCell.propTypes = {
    row: PropTypes.shape({
        isStateActive: PropTypes.bool,
    }),
}
