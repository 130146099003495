import React, {PureComponent, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {compose} from 'redux'
import has from 'lodash-es/has'

import Error from 'ipmp-react-ui/Error'
import Layout from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import {ScrollView} from 'ipmp-react-ui/Layout'

import {__} from 'utils/i18n'
import LocationRow from './LocationRow'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import page from 'permissions/panel/locations/page'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {save} from 'permissions/panel/locations/actions'
import {useActions} from 'modules/higherOrder/useActions'
import * as actions from 'modules/panels/locations/actions'
import withProcessLoader from 'containers/withProcessLoader'
import {withPermissionRejection} from 'containers/withPermission'

export class Locations extends PureComponent {
    static propTypes = {
        values: PropTypes.object,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired,
                custom: PropTypes.string.isRequired,
                editable: PropTypes.bool,
            })
        ).isRequired,
        change: PropTypes.func.isRequired,
        persist: PropTypes.func.isRequired,
        characters: PropTypes.string,
        maxLength: PropTypes.number,
        process: PropTypes.object,
        isNeo: PropTypes.bool,
    }

    static defaultProps = {
        values: {},
    }

    state = {filter: true}

    handleFilterChange = (e) => {
        this.setState({filter: e.target.checked})
    }

    getLocations() {
        const {locations, isEditable} = this.props

        if (!this.state.filter || !isEditable) {
            return locations
        }

        return locations.filter(({editable}) => !!editable)
    }

    render() {
        const {
            isEditable,
            locations,
            values,
            maxLength,
            persist,
            characters,
            change,
            isNeo,
        } = this.props

        if (isNeo) {
            return <Error title={__('Neo panels has no locations support')} />
        }

        if (!locations || locations.length === 0) {
            return (
                <Error
                    title={__('No editable locations')}
                    message={__('Editable locations were not found for current unit.')}
                />
            )
        }

        return (
            <Layout vertical className="locations-list-wrapper">
                {isEditable && (
                    <Bar>
                        <Checkbox
                            label={__('Show only editable locations')}
                            defaultChecked
                            onChange={this.handleFilterChange}
                        />

                        <BarSpace />

                        <Button
                            onClick={persist}
                            disabled={Object.keys(values).length === 0}
                        >
                            {__('Save locations')}
                        </Button>
                    </Bar>
                )}

                <ScrollView>
                    <div className="locations-list card">
                        {this.getLocations().map(({id, name, custom, editable}) => (
                            <LocationRow
                                {...{
                                    key: id,
                                    id,
                                    onChange: change,
                                    name,
                                    isChanged: has(values, id),
                                    value: has(values, id) ? values[id] : custom,
                                    isDisabled: !editable,
                                    isReadOnly: !isEditable,
                                    maxLength,
                                    characters,
                                }}
                            />
                        ))}
                    </div>
                </ScrollView>
            </Layout>
        )
    }
}

const ConnectedLocations = compose(
    withPermissionRejection(page),
    withLoader(),
    withProcessLoader(
        () => __('Saving…'),
        ({fetch}) => fetch()
    )
)(Locations)

export default function LocationsMain() {
    const panelId = useRouterPanelId()
    const {isPermitted: isEditable} = usePermission(save)
    const {isNeo, isLoading, ...locations} = useSelector(({panels}) => {
        const locations = panels.locations[panelId]
        const {isNeo} = panels.store.byIds[panelId]

        return {
            isNeo,
            isLoading: !isNeo && !locations,
            ...locations,
        }
    })

    const {fetch, change, persist, startPolling, stopPolling} = useActions({
        fetch: () => actions.fetch(panelId),
        persist: () => actions.persist(panelId),
        stopPolling: () => actions.stopPolling(panelId),
        startPolling: () => actions.startPolling(panelId),
        change: (name, value) => actions.change(name, value, panelId),
    })

    useEffect(() => {
        startPolling()

        return stopPolling
    }, [])

    return (
        <ConnectedLocations
            {...locations}
            fetch={fetch}
            isNeo={isNeo}
            change={change}
            isLoading={isLoading}
            persist={persist}
            panelId={panelId}
            isEditable={isEditable}
        />
    )
}
