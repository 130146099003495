import {bindActionCreators, compose} from 'redux'
import RecentHistoryPanelList from 'components/RecentHistory/RecentHistoryPanelList'
import withLoader from 'containers/withLoader'
import {connect} from 'react-redux'
import {selectRecentInteractiveUserPanels} from 'modules/panels/recent/selectors'
import {fetchPanelsInteractiveUser} from 'modules/panels/recent/actions'
import get from 'lodash-es/get'

export default compose(
    connect(
        (state, {interactiveUserId, panel}) => {
            if (!interactiveUserId) {
                return {
                    isLoading: false,
                    panels: [panel],
                }
            }

            const {isLoading, error, panels} = selectRecentInteractiveUserPanels(state, {
                interactiveUserId,
            })
            const allowedFetchUsers = get(
                state,
                'auth.permissions.system.customers.assignedunitusers',
                true
            )

            return {
                isLoading,
                error,
                panels,
                allowedFetchUsers,
            }
        },
        (dispatch) =>
            bindActionCreators(
                {
                    fetchPanelsInteractiveUser,
                },
                dispatch
            )
    ),
    withLoader(({fetchPanelsInteractiveUser, allowedFetchUsers}) => {
        allowedFetchUsers && fetchPanelsInteractiveUser()
    })
)(RecentHistoryPanelList)
