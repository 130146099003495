import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import InfoCards, {InfoCard} from 'ipmp-react-ui/InfoCards'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import InteractiveSession from './InteractiveSession'
import Advertisement from './Advertisement'
import UserNotifications from './UserNotifications'
import AuthorizationSettings from './AuthorizationSettings'

import __ from 'utils/i18n'
import withLoader from 'containers/withLoader'
import * as actions from 'modules/system/settings/actions'
import {
    showSystemSettingsInteractiveSessionModal,
    showSystemSettingsUserNotificationsModal,
    showSystemSettingsAdvertisementModal,
    showSystemSettingsAuthorizationSettingsModal,
} from 'modules/modals/actions'

const Interactive = ({
    interactiveSessionModal,
    userNotificationsModal,
    advertisementModal,
    authorizationSettingsModal,
}) => {
    const authorizationBtn = (
        <Button onClick={authorizationSettingsModal} borderless Icon={IconEdit} />
    )
    const interactiveSessionBtn = (
        <Button onClick={interactiveSessionModal} borderless Icon={IconEdit} />
    )
    const advertisementBtn = (
        <Button onClick={advertisementModal} borderless Icon={IconEdit} />
    )
    const userNotificationsBtn = (
        <Button onClick={userNotificationsModal} borderless Icon={IconEdit} />
    )

    return (
        <InfoCards cols={2} className="settingsInteractive">
            <InfoCard
                title={__('Authorization Settings')}
                headerButton={authorizationBtn}
                cols={2}
                twoRows
            >
                <AuthorizationSettings />
            </InfoCard>
            <InfoCard
                title={__('Interactive Session')}
                headerButton={interactiveSessionBtn}
                cols={2}
            >
                <InteractiveSession />
            </InfoCard>
            <InfoCard
                title={__('Advertisement')}
                headerButton={advertisementBtn}
                cols={2}
            >
                <Advertisement />
            </InfoCard>
            <InfoCard
                title={__('Customer Notifications')}
                headerButton={userNotificationsBtn}
                cols={2}
            >
                <UserNotifications />
            </InfoCard>
        </InfoCards>
    )
}

export default compose(
    connect(
        ({
            system: {
                settings: {
                    interactiveSession,
                    userNotifications,
                    advertisement,
                    authorizationSettings,
                    messageBrokers,
                },
            },
        }) => ({
            interactiveSession,
            userNotifications,
            advertisement,
            authorizationSettings,
            messageBrokers,
            isLoading:
                interactiveSession.isLoading ||
                userNotifications.isLoading ||
                advertisement.isLoading ||
                messageBrokers.isLoading,
        }),
        (dispatch) =>
            bindActionCreators(
                {
                    interactiveSession: actions.fetchInteractiveSession,
                    userNotification: actions.fetchUserNotifications,
                    advertisement: actions.fetchAdvertisement,
                    messageBrokers: actions.fetchMessageBrokers,
                    authorizationSettings: actions.fetchAuthorizationSettings,
                    showSystemSettingsInteractiveSessionModal,
                    showSystemSettingsUserNotificationsModal,
                    showSystemSettingsAdvertisementModal,
                    showSystemSettingsAuthorizationSettingsModal,
                },
                dispatch
            ),
        (stateProps, dispatchProps) => {
            return {
                ...stateProps,
                ...dispatchProps,
                interactiveSessionModal: () =>
                    dispatchProps.showSystemSettingsInteractiveSessionModal({
                        ...stateProps.interactiveSession,
                    }),
                userNotificationsModal: () =>
                    dispatchProps.showSystemSettingsUserNotificationsModal({
                        ...stateProps.userNotifications,
                    }),
                advertisementModal: () =>
                    dispatchProps.showSystemSettingsAdvertisementModal(
                        stateProps.advertisement
                    ),
                authorizationSettingsModal: () =>
                    dispatchProps.showSystemSettingsAuthorizationSettingsModal(
                        stateProps.authorizationSettings
                    ),
            }
        }
    ),
    withLoader(
        ({
            interactiveSession,
            userNotification,
            advertisement,
            messageBrokers,
            authorizationSettings,
        }) => {
            messageBrokers()
            interactiveSession()
            userNotification()
            advertisement()
            authorizationSettings()
        }
    )
)(Interactive)
