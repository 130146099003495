import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {CardActions, CardClose, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'

import OrderType from './OrderType'
import ShowLabels from './ShowLabels'
import OrderPreview from './OrderPreview'
import {MENU_ORDER_TYPE_DEFAULT} from 'constants/mainMenu'
import {setMainMenuSettings} from 'modules/settings/actions'

export default function MenuSettings({onClose}) {
    const settings = useSelector(({settings}) => settings)
    const menuOrderType = settings?.menuOrderType || MENU_ORDER_TYPE_DEFAULT
    const savedMenuLabels = settings?.hideMenuLabels

    const [orderType, setType] = useState(menuOrderType)
    const [isLabelDisabled, disableLabel] = useState(savedMenuLabels)
    const [menuOrder, setMenu] = useState(null)

    const {setSettings} = useActions({setSettings: setMainMenuSettings})

    const handleClose = () => onClose && onClose()

    const handleSave = () => {
        const items = menuOrder.reduce((orderedItems, item, index) => {
            orderedItems[item] = menuOrder.length - index
            return orderedItems
        }, {})

        setSettings({
            hideMenuLabels: isLabelDisabled,
            menuOrderType: orderType,
            menuOrderManual: items,
            shouldUpdateMenuOrder: true,
        })

        onClose && onClose()
    }

    return (
        <div className="editSettings-layout menuSetting">
            <CardClose onClick={handleClose} />
            <CardHeader>{__('Menu settings')}</CardHeader>
            <CardContent>
                <div className="cols cols--stretch">
                    <div className="col">
                        <OrderType onChange={setType} savedType={orderType} />
                        <ShowLabels
                            onChange={disableLabel}
                            savedLabels={isLabelDisabled}
                        />
                    </div>
                    <div className="col">
                        <OrderPreview
                            orderType={orderType}
                            setMenu={setMenu}
                            isLabelDisabled={isLabelDisabled}
                        />
                    </div>
                </div>
            </CardContent>
            <CardActions>
                <Button borderless onClick={handleClose}>
                    {__('Close')}
                </Button>
                <Button primary onClick={handleSave}>
                    {__('Save')}
                </Button>
            </CardActions>
        </div>
    )
}

MenuSettings.propTypes = {
    onClose: PropTypes.func,
}
