import React, {Component, Fragment, useEffect} from 'react'

import CardBlindModal from 'ipmp-react-ui/CardBlindModal'

import page from 'permissions/panel/remoteInspections/page'
import {withPermissionRejection} from 'containers/withPermission'
import ReviewButton from './BlindButtons/ReviewButton'
import SendEmailButton from './BlindButtons/SendEmailButton'
import InitiateButton from './BlindButtons/InitiateButton'
import {ScrollView} from 'ipmp-react-ui/Layout'
import Tabs from 'ipmp-react-ui/Tabs'

import RemoteInspectionsCalendar from './RemoteInspectionsCalendar'
import RemoteInspectionsTab from './RemoteInspectionsTab'
import RemoteInspectionsNextRI from './RemoteInspectionsNextRI'

import {__} from 'utils/i18n'
import {
    RESULT_RRI_FAIL,
    RESULT_RRI_NOT_AVAILABLE,
    RESULT_RRI_PASS,
} from 'constants/remoteInspection'
import usePermission from 'hooks/usePermission'
import usePanelRemoteInspection from 'hooks/pages/usePanelRemoteInspection'

const TabsLayout = ({nav, tab}) => {
    return (
        <Fragment>
            {nav}

            <ScrollView>{tab}</ScrollView>
        </Fragment>
    )
}

class RemoteInspectionsPage extends Component {
    state = {
        activeResult: null,
        isRemoteInspectionResultOpened: false,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.activeResult === null) {
            const hasFirstRow = nextProps && nextProps.rows.length > 0
            return {
                activeResult: hasFirstRow ? nextProps.rows[0] : null,
            }
        }

        return null
    }

    closeRemoteInspectionResult = () => {
        this.setState({isRemoteInspectionResultOpened: false})
    }

    onItemClick = (result) => {
        this.setState({
            isRemoteInspectionResultOpened: true,
            activeResult: result,
        })
    }

    renderBlindContent = () => {
        const {activeResult} = this.state
        const {remoteInspection} = this.props

        return (
            <>
                <RemoteInspectionsNextRI
                    created={activeResult.created}
                    next={remoteInspection.next}
                    repetition={remoteInspection.repetition}
                />

                <Tabs Layout={TabsLayout} className="rri-tabs">
                    <RemoteInspectionsTab
                        name={__('Failed tests')}
                        state={RESULT_RRI_FAIL}
                        activeResult={activeResult}
                    />
                    <RemoteInspectionsTab
                        name={__('Not completed tests')}
                        state={RESULT_RRI_NOT_AVAILABLE}
                        activeResult={activeResult}
                    />
                    <RemoteInspectionsTab
                        name={__('PASSED TESTS')}
                        state={RESULT_RRI_PASS}
                        activeResult={activeResult}
                    />
                </Tabs>

                <div className="rri-footer">
                    <div className="rri-footer-btns">
                        <ReviewButton remoteInspectionResult={activeResult} />
                        <SendEmailButton remoteInspectionResult={activeResult} />
                    </div>
                    <InitiateButton />
                </div>
            </>
        )
    }

    renderHeader() {
        return <></> // :TODO implement arrows in feature tasks
    }

    render() {
        const {isRemoteInspectionResultOpened, activeResult} = this.state

        return (
            <Fragment>
                <RemoteInspectionsCalendar onItemClick={this.onItemClick} />
                <CardBlindModal
                    opened={isRemoteInspectionResultOpened}
                    title={this.renderHeader()}
                    onClose={this.closeRemoteInspectionResult}
                    className="blindModalPanelRemoteInspection"
                >
                    {activeResult && this.renderBlindContent()}
                </CardBlindModal>
            </Fragment>
        )
    }
}

const RemoteInspectionsPageRejection =
    withPermissionRejection(page)(RemoteInspectionsPage)

export default function RemoteInspections() {
    const {isPermitted} = usePermission(page)
    const panelRemoteInspection = usePanelRemoteInspection()

    useEffect(() => {
        isPermitted && panelRemoteInspection.reset()

        return panelRemoteInspection.stopPoll
    }, [])

    return (
        <RemoteInspectionsPageRejection
            {...panelRemoteInspection}
            isAllowed={isPermitted}
        />
    )
}
