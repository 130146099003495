import React from 'react'

import UsersPage from './UsersPage'
import path from 'utils/path'

export default class UsersRoute {
    path = path('users')

    element = (<UsersPage />)
}
