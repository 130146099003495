import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'

export default function DskReportButtons({onClick, onDismiss}) {
    return (
        <>
            <Button onClick={onDismiss} borderless>
                {__('Dismiss')}
            </Button>
            <Button primary onClick={onClick}>
                {__('Send')}
            </Button>
        </>
    )
}

DskReportButtons.propTypes = {
    onClick: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
}
