import React from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'

const ActionCards = ({label, className, children}) => {
    return (
        <div className={classes('action-cards-container', className)}>
            {label && <label>{label}</label>}
            <div className="action-cards">{children}</div>
        </div>
    )
}

ActionCards.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
}

export default ActionCards

export const ActionCard = ({label, children, className}) => {
    return (
        <div className={classes('action-card', className)}>
            {label && <label>{label}</label>}
            <div className="action-card-buttons">{children}</div>
        </div>
    )
}

ActionCard.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
}

export const ActionCardsGroup = ({label, children}) => {
    return (
        <div className="action-cards-group">
            {label && <label>{label}</label>}
            {children}
        </div>
    )
}

ActionCardsGroup.propTypes = {
    label: PropTypes.string,
    children: PropTypes.node,
}
