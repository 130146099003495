import React from 'react'

import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import {useActions} from 'modules/higherOrder/useActions'
import {startClearDevice} from 'modules/devices/list/actions'
import {getProtocolBySubtype} from 'constants/protocols'

export default function StartClearDeviceButton({
    batch,
    flat,
    panelId,
    subtype,
    disabled,
}) {
    const {handleStartClear} = useActions({
        handleStartClear: () => startClearDevice(panelId, getProtocolBySubtype(subtype)),
    })

    return (
        <Button
            disabled={disabled}
            flat={flat}
            primary
            panelId={panelId}
            onClick={handleStartClear}
            isRefreshing={!!batch}
            label={__('Start Clear')}
        />
    )
}
