import React from 'react'

import {ReactComponent as IconAuxiliary} from 'ipmp-react-ui/icons/events/IconAuxiliary.svg'
import {ReactComponent as IconAlertOff} from 'ipmp-react-ui/icons/alert-off.svg'
import {ReactComponent as IconAlarm} from 'ipmp-react-ui/icons/alarm.svg'

import __ from 'utils/i18n'
import PartitionsSelect from 'components/Panel/Emergencies/PartitionsSelect'
import {PARTITION_ALL, PARTITION_LABEL_ALL} from 'constants/partitions'
import {TYPE_EMERGENCY_SILENT_EMERGENCY} from 'constants/events'

export default function EmergencyContent({panelId, data, setSelected}) {
    const Icon = data[TYPE_EMERGENCY_SILENT_EMERGENCY] ? IconAlertOff : IconAlarm
    const disclaimerTitle = data[TYPE_EMERGENCY_SILENT_EMERGENCY]
        ? __('SILENT ALARM')
        : __('AUDIBLE ALARM')

    const disclaimerMessage = data[TYPE_EMERGENCY_SILENT_EMERGENCY]
        ? __(
              'Emergency alarm will be transmitted to the central monitoring station but will not activate the siren'
          )
        : __(
              'Emergency alarm will be transferred to central monitoring stations and activate siren, that may lead to ' +
                  'hearing damage or other unwanted consequences, if facility is currently attended'
          )

    return (
        <div className="init-emergency-chosen-block">
            <div className="init-emergency-block-content-disclaimer">
                <div className="init-emergency-block-content-image">
                    <IconAuxiliary />
                </div>
                <h1>{__('Your are going to initiate emergency panic')}</h1>
                <h3>
                    {__(
                        'Please choose the partitions for which you need to apply emergency panic'
                    )}
                </h3>
                <div className="disclaimer">
                    <Icon />
                    <div>
                        <h2>{disclaimerTitle}</h2>
                        <h3>{disclaimerMessage}</h3>
                    </div>
                </div>
            </div>
            <hr />
            <PartitionsSelect
                setSelected={setSelected}
                panelId={panelId}
                defaultValues={[{value: PARTITION_ALL, label: PARTITION_LABEL_ALL}]}
            />
        </div>
    )
}
