import {createSelector} from 'reselect'

export const selectInteractiveUsersList = createSelector(
    (state) => state.interactiveUsers.list,
    ({isLoading, page, error, total, start}) => ({isLoading, page, error, total, start})
)

export const selectInteractiveUsersListSearch = createSelector(
    (state) => state.interactiveUsers.list,
    (list) => list
)
