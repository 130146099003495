import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import groupBy from 'lodash-es/groupBy'

import {status} from 'permissions/panel/devices/walktest'

import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import DevicesList from 'components/Devices/DevicesList'
import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'
import useDevices from 'hooks/pages/useDevices'

export class WalktestBase extends Component {
    static propTypes = {
        devices: PropTypes.objectOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
            })
        ).isRequired,
        panel: PropTypes.shape({
            isNeo: PropTypes.bool,
        }),
        status: PropTypes.objectOf(PropTypes.string).isRequired,
    }

    getLocations(devices) {
        if (!devices) {
            return {}
        }

        return Object.keys(devices).reduce(
            (acc, value) => ({
                ...acc,
                [value]: value,
            }),
            {}
        )
    }

    render() {
        const {devices, status, ...props} = this.props

        const activeDevices = groupBy(
            Object.keys(status).map((id) => devices[id]),
            ({location}) => location || __('Other')
        )

        if (!devices || !Object.keys(activeDevices).length) {
            return <Error title={__('No devices for walktest')} />
        }

        return (
            <DevicesList
                groups={this.getLocations(activeDevices)}
                devices={activeDevices}
                status={status}
                {...props}
            />
        )
    }
}

const ConnectedWalktest = compose(
    withPermissionRejection(status),
    withLoader(({fetch}) => fetch())
)(WalktestBase)

export default function Walktest() {
    const deviceProps = useDevices()
    const {fetchWalktest, walktest, byId} = deviceProps

    const props = {...deviceProps, ...walktest}

    return (
        <ConnectedWalktest
            {...props}
            panel={null}
            fetch={fetchWalktest}
            devices={byId}
            isLoading={!walktest || (!walktest.status && !walktest.error)}
        />
    )
}
