// see process->prs_type enum in database
export const PROCESS_TYPE_FEATURES = 'Features'
export const PROCESS_TYPE_PLINKSOFTWAREUPGRADE = 'PLinkSoftwareUpgrade'
export const PROCESS_TYPE_PMAXANONYMOUS = 'Anonymous'
export const PROCESS_TYPE_PMAXCANCELSOAKZONE = 'CancelSoakZone'
export const PROCESS_TYPE_PMAXCLEARBYPASSZONE = 'ClearByPassZone'
export const PROCESS_TYPE_PMAXCONFIGBACKUP = 'ConfigBackup'
export const PROCESS_TYPE_PMAXCONFIGBASICUPLOAD = 'ConfigBasicUpload'
export const PROCESS_TYPE_PMAXCONFIGDOWNLOAD = 'ConfigDownload'
export const PROCESS_TYPE_PMAXCONFIGUPLOAD = 'ConfigUpload'
export const PROCESS_TYPE_PMAXFILMONDEMAND = 'FilmOnDemand'
export const PROCESS_TYPE_PMAXLOGLEGACY = 'LogLegacy'
export const PROCESS_TYPE_PMAXLOGSTANDARD = 'LogStandard'
export const PROCESS_TYPE_NEOLIVELOG = 'NeoLiveLog'
export const PROCESS_TYPE_PMAXPYTHONUPGRADE = 'PythonUpgrade'
export const PROCESS_TYPE_PMAXSETBYPASSZONE = 'SetByPassZone'
export const PROCESS_TYPE_PMAXSETCUSTOMZONES = 'SetCustomZones'
export const PROCESS_TYPE_PMAXSETSOAKZONE = 'SetSoakZone'
export const PROCESS_TYPE_PMAXSOFTWAREUPGRADE = 'SoftwareUpgrade'
export const PROCESS_TYPE_PMAXSTATEGET = 'StateGet'
export const PROCESS_TYPE_PMAXSTATESET = 'StateSet'
export const PROCESS_TYPE_PMAXZONEADD = 'ZoneAdd'
export const PROCESS_TYPE_PMAXZONEREMOVE = 'ZoneRemove'
export const PROCESS_TYPE_PMAXACTIVATESIREN = 'ActivateSiren'
export const PROCESS_TYPE_PMAXZONERSSI = 'ZoneRssi'
export const PROCESS_TYPE_REFRESH_GSM = 'GsmSignalLevel'
export const PROCESS_TYPE_PMAXZONEWALKTEST = 'ZoneWalkTest'
export const PROCESS_TYPE_RESETPASSWORD = 'ResetPassword'
export const PROCESS_TYPE_REMOTEINSPECTION = 'RemoteInspection'
export const PROCESS_TYPE_SOFTWAREREPOSYNC = 'SoftwareRepoSync'
export const PROCESS_TYPE_DISABLEX10 = 'DisableX10'
export const PROCESS_TYPE_ENABLEX10 = 'EnableX10'
export const PROCESS_TYPE_DIMMERX10 = 'DimmerX10'
export const PROCESS_TYPE_BRIGHTERX10 = 'BrighterX10'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0 = 'SetPrivateReportsEmail0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1 = 'SetPrivateReportsEmail1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2 = 'SetPrivateReportsEmail2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3 = 'SetPrivateReportsEmail3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0 = 'SetPrivateReportsPhone0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1 = 'SetPrivateReportsPhone1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2 = 'SetPrivateReportsPhone2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3 = 'SetPrivateReportsPhone3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0 =
    'SetPrivateReportsEmailFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1 =
    'SetPrivateReportsEmailFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2 =
    'SetPrivateReportsEmailFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3 =
    'SetPrivateReportsEmailFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0 = 'SetPrivateReportsSmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1 = 'SetPrivateReportsSmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2 = 'SetPrivateReportsSmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3 = 'SetPrivateReportsSmsFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0 = 'SetPrivateReportsMmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1 = 'SetPrivateReportsMmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2 = 'SetPrivateReportsMmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3 = 'SetPrivateReportsMmsFilter3'
export const PROCESS_TYPE_REPORT = 'Report'
export const PROCESS_TYPE_PMAXSETVODMODE = 'SetVODMode'
export const PROCESS_TYPE_PMAXRESETRECEIVERIP = 'ResetReceiverIp'
export const PROCESS_TYPE_PMAXASSIGNPARTITIONS = 'AssignPartitions'
export const PROCESS_TYPE_PMAXDISABLESIREN = 'DisableSiren'
export const PROCESS_TYPE_NEOACTIVATION = 'Activation'
export const PROCESS_TYPE_USER_ACTIVATION = 'UserActivation'
export const PROCESS_TYPE_FIRMWARE_UPGRADE = 'FirmwareUpgrade'
export const PROCESS_TYPE_NEO_UPGRADE = 'SoftwareUpgrade'
export const PROCESS_TYPE_LTE_UPGRADE = 'LTEUpgrade'
export const PROCESS_TYPE_SET_LABEL = 'SetLabel'
export const PROCESS_TYPE_SET_ICON = 'SetIcon'
export const PROCESS_TYPE_EDITPGM = 'EditPGM'
export const PROCESS_TYPE_DISABLEPGM = 'DisablePGM'
export const PROCESS_TYPE_PANEL_DISCOVERY = 'PanelDiscovery'
export const PROCESS_TYPE_NEO_DIAGNOSTIC = 'NeoDiagnostic'
export const PROCESS_TYPE_HAD_COMMAND = 'HadCommand'
export const PROCESS_TYPE_CHANGE_INSTALLER_PIN = 'SetInstallerCode'
export const PROCESS_TYPE_SET_PANEL_USER_CODE = 'SetUserCode'
export const PROCESS_TYPE_SET_PANEL_USER_LASTNAME = 'SetLastName'
export const PROCESS_TYPE_SET_PANEL_USER_EXPIRATION_DATE = 'SetExpirationDate'
export const PROCESS_TYPE_DISABLE_PANEL_USER = 'DisablePanelUser'
export const PROCESS_TYPE_SET_USER_PARTITIONS = 'SetUserPartitions'
export const PROCESS_TYPE_EVENT_INITIATE = 'EventInitiate'
export const PROCESS_TYPE_FIRE_ALARM = 'FireAlarm'
export const PROCESS_TYPE_PANIC_ALARM = 'PanicAlarm'
export const PROCESS_TYPE_EMERGENCY_ALARM = 'EmergencyAlarm'
export const PROCESS_TYPE_ACTIVATE_SIREN = 'ActivateSiren'
export const PROCESS_TYPE_CONNECT_DLS = 'ConnectDLS'
export const PROCESS_TYPE_DISCONNECT_DLS = 'DisconnectDLS'
export const PROCESS_TYPE_SYSTEM_LOG_FETCH = 'SysLogFetch'
export const PROCESS_TYPE_CLEAR_DEVICE_START = 'Z-Wave Clear Session'
export const PROCESS_TYPE_CLEAR_DEVICE_STOP = 'Z-Wave Stop Clear Session'
export const PROCESS_TYPE_SILENT_PANIC_ALARM = 'SilentPanicAlarm'
export const PROCESS_TYPE_SILENT_EMERGENCY_ALARM = 'SilentEmergencyAlarm'
export const PROCESS_TYPE_BILLING_ACTIVATE = 'ActivatePlan'
export const PROCESS_TYPE_BILLING_DEACTIVATE = 'BillingDeactivate'
export const PROCESS_TYPE_REBOOT = 'Reboot'
export const PROCESS_TYPE_SYS_LOG_FETCH_OUTSIDE_PM = 'SyslogFetchOutsidePm'

const ALLOW_RETRY_PROCESSES = [PROCESS_TYPE_NEO_UPGRADE, PROCESS_TYPE_FIRMWARE_UPGRADE]

export const ALLOWED_REFRESH_RUNNER_PROCESS_TYPES = [
    PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
    PROCESS_TYPE_PMAXCONFIGUPLOAD,
    PROCESS_TYPE_PMAXZONEADD,
    PROCESS_TYPE_PMAXZONEREMOVE,
]

export const isAllowRetry = (type) => ALLOW_RETRY_PROCESSES.includes(type)
