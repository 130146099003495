import {all, takeEvery, put, call} from 'redux-saga/effects'

import {fetch, fetchWithoutLoader, update} from './actions'

import * as actions from 'modules/panels/one/actions'
import * as api from 'api/panel/features'

export default function* () {
    yield all([takeEvery(fetch, watchFetch), takeEvery(fetchWithoutLoader, watchFetch)])
}

function* watchFetch({payload: panelId}) {
    try {
        const data = yield call(api.fetch, panelId)

        yield put(update({panelId, ...data}))
    } catch (e) {
        yield put(actions.setError(e))
    }
}
