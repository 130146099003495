import React from 'react'

import {ReactComponent as IconSecurity} from 'ipmp-react-ui/icons/events/IconPolice.svg'
import {ReactComponent as IconFire} from 'ipmp-react-ui/icons/events/IconFire.svg'
import {ReactComponent as IconAuxiliary} from 'ipmp-react-ui/icons/events/IconAuxiliary.svg'
import {ReactComponent as IconAlertOff} from 'ipmp-react-ui/icons/alert-off.svg'
import {ReactComponent as IconAlert} from 'ipmp-react-ui/icons/alarm.svg'
import Button from 'ipmp-react-ui/Button'
import Togglebox from 'ipmp-react-ui/ToggleBox'

import __ from 'utils/i18n'
import {
    EMERGENCY_ACTIONS_EMERGENCY,
    EMERGENCY_ACTIONS_FIRE,
    EMERGENCY_ACTIONS_PANIC,
    EMERGENCY_ACTIONS_SILENT_EMERGENCY,
    EMERGENCY_ACTIONS_SILENT_PANIC,
    TYPE_EMERGENCY_EMERGENCY,
    TYPE_EMERGENCY_FIRE,
    TYPE_EMERGENCY_SECURITY,
    TYPE_EMERGENCY_SILENT_EMERGENCY,
    TYPE_EMERGENCY_SILENT_SECURITY,
} from 'constants/events'

export default function InitEmergencyContent({data, setData, emergencyActions}) {
    const SecurityIcon = data[TYPE_EMERGENCY_SILENT_SECURITY] ? IconAlertOff : IconAlert
    const EmergencyIcon = data[TYPE_EMERGENCY_SILENT_EMERGENCY] ? IconAlertOff : IconAlert

    const handleClick = (name) => {
        setData((state) => ({...state, chosen: name}))
    }

    const handleToggleChange = (name, e) => {
        setData((state) => {
            return {
                ...state,
                [name]: e.target.checked,
            }
        })
    }

    return (
        <div className="init-emergency-default-screen">
            {emergencyActions.includes(EMERGENCY_ACTIONS_PANIC) && (
                <div className="init-emergency-block-content-wrapper">
                    <div className="init-emergency-block-content">
                        <div className="init-emergency-block-title-block">
                            <IconSecurity />
                            <div className="init-emergency-block-title">
                                <h1>{__('Panic')}</h1>
                                <h3>{__('Choose to trigger security panic')}</h3>
                            </div>
                        </div>
                        <Button
                            primary
                            label={__('Choose')}
                            onClick={() =>
                                handleClick(
                                    data[TYPE_EMERGENCY_SILENT_SECURITY]
                                        ? TYPE_EMERGENCY_SILENT_SECURITY
                                        : TYPE_EMERGENCY_SECURITY
                                )
                            }
                        />
                    </div>
                    {emergencyActions.includes(EMERGENCY_ACTIONS_SILENT_PANIC) && (
                        <>
                            <hr />
                            <div className="init-emergency-block-toggle">
                                <div className="init-emergency-block-title-block">
                                    <SecurityIcon />
                                    {__('Silent alarm')}
                                </div>
                                <Togglebox
                                    name="silent"
                                    checked={data[TYPE_EMERGENCY_SILENT_SECURITY]}
                                    defaultChecked={false}
                                    onChange={(e) => {
                                        handleToggleChange(
                                            TYPE_EMERGENCY_SILENT_SECURITY,
                                            e
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
            {emergencyActions.includes(EMERGENCY_ACTIONS_FIRE) && (
                <div className="init-emergency-block-content-wrapper">
                    <div className="init-emergency-block-content">
                        <div className="init-emergency-block-title-block">
                            <IconFire />
                            <div className="init-emergency-block-title">
                                <h1>{__('Fire')}</h1>
                                <h3>{__('Choose to trigger fire panic')}</h3>
                            </div>
                        </div>
                        <Button
                            primary
                            label={__('Choose')}
                            onClick={() => handleClick(TYPE_EMERGENCY_FIRE)}
                        />
                    </div>
                </div>
            )}
            {emergencyActions.includes(EMERGENCY_ACTIONS_EMERGENCY) && (
                <div className="init-emergency-block-content-wrapper">
                    <div className="init-emergency-block-content">
                        <div className="init-emergency-block-title-block">
                            <IconAuxiliary />
                            <div className="init-emergency-block-title">
                                <h1>{__('Emergency')}</h1>
                                <h3>{__('Choose to trigger emergency panic')}</h3>
                            </div>
                        </div>
                        <Button
                            primary
                            label={__('Choose')}
                            onClick={() =>
                                handleClick(
                                    data[TYPE_EMERGENCY_SILENT_EMERGENCY]
                                        ? TYPE_EMERGENCY_SILENT_EMERGENCY
                                        : TYPE_EMERGENCY_EMERGENCY
                                )
                            }
                        />
                    </div>
                    {emergencyActions.includes(EMERGENCY_ACTIONS_SILENT_EMERGENCY) && (
                        <>
                            <hr />
                            <div className="init-emergency-block-toggle">
                                <div className="init-emergency-block-title-block">
                                    <EmergencyIcon />
                                    {__('Silent alarm')}
                                </div>
                                <Togglebox
                                    name="silent"
                                    checked={data[TYPE_EMERGENCY_SILENT_EMERGENCY]}
                                    defaultChecked={false}
                                    onChange={(e) => {
                                        handleToggleChange(
                                            TYPE_EMERGENCY_SILENT_EMERGENCY,
                                            e
                                        )
                                    }}
                                />
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    )
}
