// possible levels - 99 (1..99)
// where 1 - 1%, 99 - 100%
import {HAD_STATE_OFF, HAD_STATE_ON, isOff, isOn} from 'constants/had/hadState'
import {switcherTypeActionTitleMap} from 'constants/had/switcher'

export const resolveDimmerButtonLabel = (state) => {
    switch (true) {
        case isOn(state):
            return switcherTypeActionTitleMap.get(HAD_STATE_OFF)()
        case isOff(state):
            return switcherTypeActionTitleMap.get(HAD_STATE_ON)()
        default:
            return switcherTypeActionTitleMap.get(HAD_STATE_ON)()
    }
}
