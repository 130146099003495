import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRefresh} from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {download} from 'permissions/panel/configuration/actions'

const ButtonVisibility = withVisibility()(Button)

export default function RefreshConfigurationButton({onClick}) {
    const {isPermitted} = usePermission(download)

    return (
        <ButtonVisibility
            onClick={onClick}
            Icon={IconRefresh}
            isVisible={isPermitted}
            label={__('Refresh')}
        />
    )
}
