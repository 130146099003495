import React, {useMemo, useState} from 'react'
import {useSelector} from 'react-redux'

import usePanel from 'hooks/pages/usePanel'
import {withPermissionVisibility} from 'containers/withPermission'
import PanelBillingBlind from 'components/Panel/Billing/PanelBillingBlind'
import PanelBillingPlanBadge from 'components/Panel/Billing/PanelBillingPlanBadge'
import {status as billingStatusPermission} from 'permissions/panel/billing/actions'
import {selectStatus} from 'modules/panels/billingPlan/selectors'
import {NO_PLAN_ID} from 'constants/billingPlan'
import __ from 'utils/i18n'

function BillingPlanButton() {
    const {panel} = usePanel()
    const [active, setActive] = useState(false)
    const billingStatus = useSelector((state) => selectStatus(state, panel.id))

    const currentPlan = useMemo(
        () =>
            billingStatus?.availablePlans?.find(
                (availablePlan) => availablePlan.id === billingStatus.currentPlanId
            ),
        [billingStatus]
    )

    const onClick = () => {
        setActive(true)
    }

    const onCloseBlind = () => {
        setActive(false)
    }

    return (
        <>
            <PanelBillingBlind
                panel={{
                    id: panel.id,
                    serial: panel.serial,
                }}
                onClose={onCloseBlind}
                opened={active}
            />
            {billingStatus?.availablePlans && (
                <PanelBillingPlanBadge
                    className="panelBillingPlanBadge--panel-top"
                    name={currentPlan?.name || __('NO PLAN')}
                    id={currentPlan?.id || NO_PLAN_ID}
                    onClick={onClick}
                />
            )}
        </>
    )
}

export default withPermissionVisibility(billingStatusPermission)(BillingPlanButton)
