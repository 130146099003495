import React, {useEffect} from 'react'
import {compose} from 'redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'

import withLoader from 'containers/withLoader'
import useActionLog from 'hooks/pages/useActionLog'
import usePermission from 'hooks/usePermission'
import User from 'components/Entities/User'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'
import list from 'permissions/actionLog/list'
import {withPermissionRejection} from 'containers/withPermission'

import ActionLogDescriptionCell from './Cell/ActionLogDescriptionCell'
import ActionLogStatusCell from './Cell/ActionLogStatusCell'

const columns = [
    {
        name: () => __('Time'),
        maxWidth: 150,
        fixed: true,
        render: ({time}) => <DateTime datetime={time} timeFirst multiLine />,
    },
    {
        name: () => __('User'),
        width: 50,
        fixed: true,
        render: ({userName, userId}) => <User name={userName} id={userId} withTooltip />,
    },
    {
        name: () => __('Description'),
        component: ActionLogDescriptionCell,
    },
    {
        name: () => __('Status'),
        maxWidth: 150,
        component: ActionLogStatusCell,
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function ActionLogTable() {
    const actionLogProps = useActionLog()
    const {isPermitted} = usePermission(list)

    useEffect(() => {
        if (isPermitted) {
            actionLogProps.init()
            actionLogProps.startPoll()
        }

        return () => {
            isPermitted && actionLogProps.stopPoll()
        }
    }, [])

    return (
        <ConnectedTable
            isAllowed={isPermitted}
            className="actionLog-page-table"
            columns={columns}
            emptyMessage={__('No action logs found')}
            fullHeight
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
            {...actionLogProps}
        />
    )
}
