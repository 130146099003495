import React from 'react'
import get from 'lodash-es/get'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import {isNeoSerial} from 'utils/panelType'
import ModalCardForm from 'ui/ModalCardForm'
import {getDeviceNameLength} from 'constants/deviceCategory'
import {renameDevice, renamePartition} from 'modules/forms/handlers'
import {DEVICE_TYPE_PARTITION} from 'constants/deviceType'
import {selectDevicesLabelValidationRules} from 'modules/features/store/selectors'

const featureRules = (panelId) =>
    useSelector(({features}) => selectDevicesLabelValidationRules(features, panelId))

const RenameModal = ({
    handle,
    hide,
    defaultName,
    header,
    isLoading,
    maxLength,
    panelId,
    labelRules,
    presence = true,
}) => {
    const handleSubmit = ({name}) => {
        handle(name)
    }

    const onClose = () => {
        hide()
    }

    const rules = {
        name: {
            presence,
            labelByFeatureset: {
                panelId,
                maxLength,
                validationRules: labelRules,
            },
        },
    }

    return (
        <ModalCardForm
            onSubmit={handleSubmit}
            rules={rules}
            hide={onClose}
            isLoading={isLoading}
            submitLabel={__('Rename')}
            header={header}
            panelId={panelId}
        >
            {labelRules ? (
                <Input
                    autoFocus
                    label={__('Name')}
                    name="name"
                    defaultValue={defaultName}
                    dataTestId="name"
                />
            ) : (
                <Input
                    autoFocus
                    label={__('Name')}
                    name="name"
                    defaultValue={defaultName}
                    maxLength={maxLength}
                    dataTestId="name"
                />
            )}
        </ModalCardForm>
    )
}

RenameModal.propTypes = {
    handle: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    defaultName: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
    panelId: PropTypes.number.isRequired,
    labelRules: PropTypes.array,
}

export function RenameDevice({
    panelId,
    hide,
    device: {zone: entityId, deviceType: entityClass, name: defaultName, category},
}) {
    const {handle, isLoading, form} = useForm(renameDevice)

    const labelRules = get(featureRules(panelId), [entityClass])

    const handler = (name) => handle(panelId, entityClass, entityId, name)

    return (
        <RenameModal
            isLoading={isLoading}
            hide={hide}
            defaultName={defaultName}
            header={__('Rename Device')}
            handle={handler}
            maxLength={getDeviceNameLength(category)}
            panelId={panelId}
            labelRules={labelRules}
            {...form}
        />
    )
}

export function RenamePartition({panelId, partition: {id: entityId, name}, hide}) {
    const {handle, isLoading, form} = useForm(renamePartition)

    const {isNeo} = useSelector((state) =>
        isNeoSerial(get(state, ['panels', 'store', 'byIds', panelId, 'serial'], ''))
    )

    const labelRules = get(featureRules(panelId), [DEVICE_TYPE_PARTITION])

    const handler = (name) => handle(panelId, entityId, name)

    return (
        <RenameModal
            isLoading={isLoading}
            defaultName={name}
            hide={hide}
            header={__('Rename Partition')}
            handle={handler}
            maxLength={14}
            presence={!isNeo}
            panelId={panelId}
            labelRules={labelRules}
            {...form}
        />
    )
}
