import React from 'react'
import PropTypes from 'prop-types'

import {CardActions} from 'ipmp-react-ui/Card'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'

import {EventRuleStep} from 'constants/eventRules'
import __ from 'utils/i18n'

const EventRuleStepPagination = ({currentStep, onPrev, editable, isLoading}) => {
    const isPrev = !currentStep.isFirstStep
    const isNext = !currentStep.isLastStep
    const isSave = !isNext

    return (
        <CardActions className="eventRules-master-steps-cardActions">
            {isPrev && (
                <Button
                    className="eventRules-master-steps-prevButton"
                    flat
                    onClick={onPrev}
                    Icon={IconRun}
                >
                    {__('Prev')}
                </Button>
            )}

            {isNext && (
                <Button
                    className="eventRules-master-steps-nextButton"
                    type="submit"
                    primary
                    Icon={IconRun}
                >
                    {__('Next')}
                </Button>
            )}

            {isSave && editable && (
                <Button
                    className="eventRules-master-steps-saveButton"
                    primary
                    disabled={isLoading}
                    type="submit"
                >
                    {__('Save')}
                </Button>
            )}
        </CardActions>
    )
}

EventRuleStepPagination.propTypes = {
    currentStep: PropTypes.instanceOf(EventRuleStep).isRequired,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    editable: PropTypes.bool,
    isLoading: PropTypes.bool,
}

export default EventRuleStepPagination
