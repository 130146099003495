import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {useActions} from 'modules/higherOrder/useActions'
import {retry} from 'modules/upgradeStatus/store/actions'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/upgradeStatus/upgradeStatusRunnersList/actions'
import {
    selectUpgradeStatusRunnersList,
    selectUpgradeStatusRunnersListRows,
} from 'modules/upgradeStatus/upgradeStatusRunnersList/selectors'
import {selectRunnersStoppingIds} from 'modules/runners/store/selectors'

export default function useUpgradeStatusRunners() {
    const {onPerPageChange, perPage} = usePerPage('upgradeStatusRunners')
    const {isLoading, error, total, start} = useSelector(selectUpgradeStatusRunnersList)

    return {
        perPage,
        isLoading,
        error,
        total,
        start,
        onPerPageChange,
        stoppingIds: useSelector(selectRunnersStoppingIds),
        rows: useSelector(selectUpgradeStatusRunnersListRows),
        ...useActions({
            init,
            reset,
            setStart,
            fetch,
            onPageChange: setStart,
            startPoll,
            stopPoll,
            retry,
        }),
    }
}
