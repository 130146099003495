import React from 'react'
import {useNavigate} from 'react-router'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import DateTime from 'ipmp-react-ui/DateTime'
import {TableGrouped} from 'ipmp-react-ui/Table'

import path from 'utils/path'
import {__} from 'utils/i18n'
import BackupCell from './BackupCell'
import ExportCell from './ExportCell'
import CompareCell from './CompareCell'
import usePermission from 'hooks/usePermission'
import {backup} from 'permissions/panel/configuration/actions'
import RefreshConfigurationButton from './RefreshConfigurationButton'

const configurationsColumns = [
    {
        render: ({verified}) => <DateTime datetime={verified} />,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: CompareCell,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: ExportCell,
    },
    {
        fixed: true,
        width: 40,
        align: 'center',
        component: BackupCell,
    },
]

const separatorCallback = (row) => {
    if (row.current) {
        return __('Current')
    }

    return __('Previous')
}

export default function PanelConfigurationsList({rows, refresh, markAsBackup, panelId}) {
    const navigate = useNavigate()
    const {isPermitted: allowBackup} = usePermission(backup)

    const handleRowClick = ({current, id}) => {
        if (current) {
            navigate(path('panel.configuration.current', {id: panelId}))
        } else {
            navigate(path('panel.configuration.view', {id: panelId, configId: id}))
        }
    }

    const handleCompareClick = ({id}) => {
        navigate(path('panel.configuration.compare', {id: panelId, configId: id}))
    }

    const handleBackupClick = ({id}) => {
        markAsBackup(id)
    }

    return (
        <div className="configurations">
            <Bar orientation={RIGHT}>
                <RefreshConfigurationButton onClick={refresh} />
            </Bar>

            <TableGrouped
                rows={rows}
                columns={configurationsColumns}
                separatorCallback={separatorCallback}
                onRowClick={handleRowClick}
                compare={handleCompareClick}
                markAsBackup={handleBackupClick}
                allowBackup={allowBackup}
                panelId={panelId}
            />
        </div>
    )
}
