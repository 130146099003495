import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const ROLE_POWER_MANAGE = 'POWER_MANAGE'
export const ROLE_INSTALLER = 'INSTALLER'
export const ROLE_SUPERVISOR = 'SUPERVISOR'
export const ROLE_MASTER_USER = 'MASTER_USER'
export const ROLE_USER = 'USER'
export const ROLE_DURESS = 'DURESS'
export const ROLE_GUEST = 'GUEST'
export const ROLE_DEALER = 'DEALER'
export const ROLE_DWELLER_MASTER = 'DWELLER_MASTER'

const userRoleMap = new Map([
    [ROLE_POWER_MANAGE, () => __('Power Manage')],
    [ROLE_INSTALLER, () => __('Installer')],
    [ROLE_SUPERVISOR, () => __('Supervisor')],
    [ROLE_MASTER_USER, () => __('Master')],
    [ROLE_USER, () => __('User')],
    [ROLE_DURESS, () => __('Duress')],
    [ROLE_GUEST, () => __('Guest')],
    [ROLE_DEALER, () => __('Dealer')],
    [ROLE_DWELLER_MASTER, () => __('Dweller Master')],
])

const DISALLOW_MANIPULATE_ROLES = [ROLE_INSTALLER, ROLE_DURESS, ROLE_DEALER]
const DISALLOW_DISABLE_ROLES = [
    ROLE_MASTER_USER,
    ROLE_INSTALLER,
    ROLE_DURESS,
    ROLE_DEALER,
]

export const getUserRoleTitle = (role) => {
    if (userRoleMap.has(role)) {
        return userRoleMap.get(role)()
    }

    warn(`No role title for role: ${role}`)

    return role
}

export const isAllowManipulateUser = (role) => !DISALLOW_MANIPULATE_ROLES.includes(role)

export const isAllowDisableUser = (role) => !DISALLOW_DISABLE_ROLES.includes(role)

export const getRoleClassname = (role) => {
    switch (role) {
        case ROLE_MASTER_USER:
        case ROLE_DWELLER_MASTER:
            return 'panelInteractiveUser-role--master'
        case ROLE_POWER_MANAGE:
        case ROLE_INSTALLER:
        case ROLE_SUPERVISOR:
        case ROLE_DURESS:
        case ROLE_GUEST:
        case ROLE_DEALER:
            return 'panelInteractiveUser-role--supervision'
        default:
            return 'panelInteractiveUser-role'
    }
}
