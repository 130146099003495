import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/eventTypes'
import toIds from 'utils/toIds'
import * as actions from './actions'

import {POLL_EVENT_TYPES} from 'configs/pollers'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {update, purge} from 'modules/eventTypes/store/actions'
import {snackShow} from 'modules/snacks'

import {__} from 'utils/i18n'
import createListWithoutPaginationSaga from 'modules/higherOrder/createListWithoutPaginationSaga'
import {addEventType} from 'modules/forms/handlers'

const selector = (state) => ({
    ...state.eventTypes.list,
    listRoute: 'eventTypes',
})

export default function* () {
    yield all([
        createListWithoutPaginationSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_EVENT_TYPES, watchPoll),
        takeEvery(addEventType.SUCCESS, watchAddEventType),
    ])
}

function* watchAddEventType() {
    yield put(actions.fetch())
    yield put(snackShow(__('Event type was created successfully')))
}

function* watchPoll() {
    const {rows, count} = yield call(api.fetch)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}
