import React, {Component} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {get} from 'lodash-es'

import devicesList from 'permissions/panel/devices/list'
import {fetch as fetchDevices} from 'modules/devices/list/actions'
import withLoader from 'containers/withLoader'
import {withPermissionVisibility} from 'containers/withPermission'
import Panel from 'components/Devices/Panel'

import {DeviceCard} from './EventDevice'

const PanelCard = compose(
    withPermissionVisibility(devicesList),
    connect(
        ({panels}, {panelId}) => ({
            panel: panels.store.byIds[panelId],
            isLoading: !panels.store.byIds[panelId],
        }),
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                },
                dispatch
            )
    ),
    withLoader(({fetchDevices}) => fetchDevices())
)(Panel)

class EventsDeviceInfo extends Component {
    render() {
        const {event, panelId} = this.props
        const {hasDevice, hasPanel} = event

        if (hasDevice) {
            return <DeviceCard {...{event, panelId}} />
        }

        if (hasPanel) {
            return <PanelCard panelId={panelId} />
        }

        return null
    }
}

export default connect(({events}, {eventId}) => ({
    event: get(events, ['store', 'byIds', eventId], {}),
}))(EventsDeviceInfo)
