import React from 'react'

import {__} from 'utils/i18n'
import GroupForm from './GroupForm'
import useForm from 'hooks/useForm'
import {addGroup} from 'modules/forms/handlers'

const defaultValues = {
    name: null,
    description: null,
    localWakeUp: true,
    upgradeMethod: 'gprs',
    BBAEncryption: true,
    timeSynchronization: false,
    serverMessagingLanguage: null,
    serverMessagingLanguageId: null,
    pscConnection: false,
    allowUpgradeOverGprsDSCPanels: false,
    enableTemperatureAndLight: false,
    communications: {
        pmaster: {
            bba: {
                isEnabled: true,
                supervision: 5,
                timeout: 60,
            },
            gprs: {
                isEnabled: true,
                supervision: 120,
                timeout: 240,
            },
        },
        quazar: {
            bba: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
            gprs: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
        },
        quazar53: {
            bba: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
            gprs: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
        },
        smart_comm: {
            any: {
                isEnabled: true,
                supervision: 135,
            },
        },
        iq4_fibro: {
            bba: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
            gprs: {
                isEnabled: true,
                supervision: 135,
                timeout: 405,
            },
        },
    },
}

export default function AddGroup({hide}) {
    const {handle, isLoading, form} = useForm(addGroup)

    return (
        <GroupForm
            {...form}
            hide={hide}
            handle={handle}
            isLoading={isLoading}
            data={defaultValues}
            header={__('Add Group')}
        />
    )
}
