import {selectPerPage} from 'modules/settings/selectors'
import {takeEvery, put, all, call, select} from 'redux-saga/effects'

import * as api from 'api/centralStations'
import * as actions from './actions'
import {update, purge} from '../store/actions'

import {snackShow} from 'modules/snacks'
import {addCentralStation} from 'modules/forms/handlers'
import createListSaga from 'modules/higherOrder/createListSaga'

import {POLL_CS_LIST} from 'configs/pollers.js'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import toIds from 'utils/toIds'

import {__} from 'utils/i18n'

const selector = (state) => ({
    ...state.centralStations.list,
    perPage: selectPerPage(state, 'centralStations'),
    listRoute: 'centralStations',
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector, purge),
        takeEvery(addCentralStation.SUCCESS, watchAddCentralStation),
        createListPollerSaga(actions, POLL_CS_LIST, pollCS),
    ])
}

function* pollCS() {
    const state = yield select(selector)

    const {rows, count} = yield call(api.fetch, state)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

pollCS.onError = function* (error) {
    yield put(actions.receive(error))
}

function* watchAddCentralStation() {
    yield put(actions.fetch())
    yield put(snackShow(__('Central station was created successfully')))
}
