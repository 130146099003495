import parseDate from 'api/base/parseDate'
import moment from 'moment'
import React, {Component} from 'react'
import uniq from 'lodash-es/uniq'
import _isObject from 'lodash-es/isObject'
import has from 'lodash-es/has'

import DropDown, {ALIGN_TOP, DropDownContent} from 'ipmp-react-ui/DropDown'
import {ReactComponent as Arrow} from 'ipmp-react-ui/icons/drop-down.svg'
import {__} from 'utils/i18n'
import deviceType from 'constants/deviceType'
import {PARTITION_ALL, PARTITION_LABEL_ALL} from 'constants/partitions'

const varRegExp = /(%[^%]+%)/
const deviceTypeR = /utd_id/

const ITEMS = 2

const get = (data, key) => {
    const keys = key.startsWith('...') ? [key] : key.split('.')

    let result = data
    while (keys.length) {
        let k = keys.shift()

        if (k === '*') {
            if (Array.isArray(result)) {
                const subkey = keys.join('')

                return result.reduce((acc, item) => acc.concat(get(item, subkey)), [])
            }
        }

        if (!has(result, k)) {
            return []
        }

        result = result[k]
    }

    return Array.isArray(result) ? result : [result]
}

const ActionLogVar = ({value}) => {
    if (value === undefined || value.length === 0) {
        return <strong className="logVar logVar--empty">{__('unspecified')}</strong>
    }

    if (!Array.isArray(value)) {
        return <span className="logVar">{value}</span>
    }

    const visibleItems = value.slice(0, ITEMS).reduce((acc, value, index) => {
        if (index) {
            acc.push(', ')
        }

        if (React.isValidElement(value)) {
            acc.push(value)
        } else {
            acc.push(value.toString())
        }

        return acc
    }, [])

    if (value.length <= ITEMS) {
        return <span className="logVar">{visibleItems}</span>
    }

    const trigger = (
        <span>
            {visibleItems}
            <span className="logVar-more">
                … {value.length - ITEMS} <Arrow className="logVar-more-icon" />
            </span>
        </span>
    )
    return (
        <DropDown trigger={trigger} className="logVar logVar--trigger" align={ALIGN_TOP}>
            <DropDownContent>
                {value.map((value, index) => (
                    <div key={index} className="logVar-item">
                        {value}
                    </div>
                ))}
            </DropDownContent>
        </DropDown>
    )
}

const resolver = (values, data, subKey) => {
    if (!_isObject(data)) {
        return [data]
    }

    if ((!values || values.length === 0) && has(data, subKey)) {
        return [data[subKey]]
    }

    values = values.map((value) => {
        const resolved = subKey ? data[value] && data[value][subKey] : data[value]

        if (!resolved) {
            return '#' + value
        }

        return resolved
    })

    return values
}

const parseToken = (token) => {
    const matches = token.match(/^([\w\\.*]+)(?:->([\w\\.]+))?(?:\|(.+))?$/)

    const key = matches[1]
    const resolveKey = matches[2]
    const modifier = matches[3]

    return [key, resolveKey, modifier]
}

export default class ActionLogDescriptionCell extends Component {
    shouldComponentUpdate(props) {
        return props.row.id !== this.props.row.id
    }

    render() {
        const {description, data, params} = this.props.row
        let newParams = {...params}

        if (
            newParams.partitions &&
            newParams.partitions[0] === PARTITION_ALL.toString()
        ) {
            newParams = {...newParams, partitions: [PARTITION_LABEL_ALL]}
        }

        return description.split(varRegExp).map((token) => {
            if (token[0] !== '%') {
                return token
            }

            const [key, subKey, modifier] = parseToken(token.substr(1, token.length - 2))
            const resolveData = data && data.resolve && data.resolve[key]

            let value = get(newParams, key)

            if (resolveData) {
                value = resolver(value, resolveData, subKey)
            }

            switch (modifier) {
                case 'uniq':
                    value = uniq(value)
                    break

                case 'bool':
                    value = value.map((val) => (val ? __('Enabled') : __('Disabled')))
                    break

                case 'date':
                    value = value.map((val) => {
                        const date = parseDate(val)
                        return date ? moment(date).format('LL') : val
                    })
                    break

                case 'firmware':
                    value = value.map((val) => {
                        if (val && val.package) {
                            return val.package
                        }

                        return val
                    })
                    break
                case 'module':
                    value = value.map((val) => {
                        if (val && val.device_type) {
                            return val.device_type
                        }

                        return val
                    })
                    break
                case 'length':
                    value = [value.length]
                    break

                default:
                    value = value.map((val) =>
                        val instanceof Object ? JSON.stringify(val) : val
                    )
                    break
            }

            value = value.map((val) => (val || val === 0 ? val : '-'))

            if (key.match(deviceTypeR)) {
                value = value.map(deviceType)
            }

            return <ActionLogVar key={token} value={value} />
        })
    }
}
