import React, {Fragment} from 'react'

import ZoneTypesTable from './ZoneTypesTable'
import ZoneTypesGridBar from './ZoneTypesGridBar'

export default function ZoneTypesPage() {
    return (
        <Fragment>
            <ZoneTypesGridBar />
            <ZoneTypesTable />
        </Fragment>
    )
}
