import {useEffect} from 'react'
import queryString from 'query-string'
import {useLocation, useParams} from 'react-router'
import {useSelector} from 'react-redux'

export default function useAutoFilters() {
    const location = useLocation()

    const search = queryString.parse(location.search) || {}

    const filters = []

    for (const [key, value] of Object.entries(search)) {
        if (!Array.isArray(value)) {
            filters.push({
                $: key + '$' + value,
                name: key,
                value: value,
            })

            continue
        }

        for (const valueEl of value) {
            filters.push({
                $: key + '$' + valueEl,
                name: key,
                value: valueEl,
            })
        }
    }

    return {filters}
}

const dispatchInit = ({init, scope, action, filters}) => {
    init(scope, action, filters)
}

export function useSearchScope(init) {
    const {scope} = useParams()
    const action = useSelector(({router}) => router.action)
    const {filters} = useAutoFilters()

    useEffect(() => {
        dispatchInit({init, scope, action, filters})
    }, [scope])
}
