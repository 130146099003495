import createPermission from 'permissions/createPermission'

export const refreshRssi = createPermission('unit.devices.rssi')
export const refreshGsmRssi = createPermission('unit.devices.gsm')
export const enroll = createPermission('unit.devices.addDevice')
export const bypass = createPermission('unit.devices.bypass')
export const soak = createPermission('unit.devices.soak')
export const rarelyUsed = createPermission('unit.devices.rarelyUsed')
export const remove = createPermission('unit.devices.remove')
export const clear = createPermission('unit.devices.clear')
