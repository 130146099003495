import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import usePermission from 'hooks/usePermission'
import stopPropagation from 'utils/stopPropagation'
import actions from 'permissions/installers/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {accept, reject} from 'modules/installers/store/actions'
import InstallerStatus from 'components/Installer/InstallerStatus'

export class InstallerStatusChanger extends InstallerStatus {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            status: PropTypes.string,
        }).isRequired,
        isPermitted: PropTypes.bool,
        accept: PropTypes.func.isRequired,
        reject: PropTypes.func.isRequired,
    }

    static defaultProps = {
        isPermitted: false,
    }

    onChange = () => {
        const {
            accept,
            reject,
            row: {id, status},
        } = this.props

        switch (status) {
            case 'pending':
            case 'rejected':
                return accept(id)
            case 'accepted':
            default:
                return reject(id)
        }
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={stopPropagation}>
                <Checkbox
                    checked={this.isChecked()}
                    disabled={this.isDisabled()}
                    indeterminate={this.isIndeterminated()}
                    label={this.getLabel()}
                    onChange={this.onChange}
                />
            </div>
        )
    }
}

export default function InstallerStatusChangerMain({row}) {
    const {isPermitted} = usePermission(actions)
    const {acceptAction, rejectAction} = useActions({
        acceptAction: accept,
        rejectAction: reject,
    })

    return (
        <InstallerStatusChanger
            row={row}
            accept={acceptAction}
            reject={rejectAction}
            isPermitted={isPermitted}
        />
    )
}
