import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import NotificationTypeForm from './NotificationTypeForm'
import {validation} from './NotificationTypeFieldset'
import {addNotificationType} from 'modules/forms/handlers'

export default function AddNotificationType({hide}) {
    const {form, isLoading, handle} = useForm(addNotificationType)

    return (
        <NotificationTypeForm
            {...form}
            hide={hide}
            handle={handle}
            rules={validation}
            isLoading={isLoading}
            header={__('Add Notification Type')}
        />
    )
}
