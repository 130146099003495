import usePermission from 'hooks/usePermission'
import {enroll} from 'permissions/panel/devices/actions'
import {
    addCameraProvision,
    addCameraRTSP,
} from 'permissions/panel/devices/cameras/actions'

export default function useEnrollPermissions() {
    const {isPermitted: enrollPermitted} = usePermission(enroll)
    const {isPermitted: isProvisionPermitted} = usePermission(addCameraProvision)
    const {isPermitted: isRTSPPermitted} = usePermission(addCameraRTSP)

    const camerasPermitted = isProvisionPermitted || isRTSPPermitted

    return {
        enrollPermitted,
        isProvisionPermitted,
        isRTSPPermitted,
        camerasPermitted,
    }
}
