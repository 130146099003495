import React from 'react'
import PropTypes from 'prop-types'

import {Tab} from 'ipmp-react-ui/Tabs'

import {__} from 'utils/i18n'

import DeliveryStatus from './DeliveryStatus'
import EventNoCs from './EventNoCs'

export default function StatusTab({
    event,
    centralStations,
    queued,
    processing,
    delivered,
    retry,
    failed,
    name,
}) {
    const deliveranceByCS = event?.deliverance?.reduce((r, a) => {
        r[a.cls_id] = r[a.cls_id] || []
        r[a.cls_id].push(a)
        return r
    }, Object.create(null))

    const labels = [
        __('Queued'),
        __('Processing'),
        __('Delivered'),
        __('Retry'),
        __('Failed'),
    ]

    const data = {
        labels,
        datasets: [
            {
                data: [queued, processing, delivered, retry, failed],
                barThickness: 16,
                borderColor: [
                    'rgba(140, 138, 135, 1)',
                    'rgba(36, 145, 255, 1)',
                    'rgba(77, 186, 37, 1)',
                    'rgba(222, 167, 0, 1)',
                    'rgba(202, 35, 57, 1)',
                ],
                backgroundColor: [
                    'rgba(140, 138, 135, 0.3)',
                    'rgba(36, 145, 255, 0.3)',
                    'rgba(77, 186, 37, 0.3)',
                    'rgba(222, 167, 0, 0.3)',
                    'rgba(202, 35, 57, 0.3)',
                ],
            },
        ],
    }

    return (
        <Tab name={name}>
            {event?.deliverance?.length > 0 ? (
                <DeliveryStatus
                    centralStations={centralStations}
                    data={data}
                    deliveranceByCS={deliveranceByCS}
                />
            ) : (
                <EventNoCs />
            )}
        </Tab>
    )
}

StatusTab.propTypes = {
    event: PropTypes.object,
    centralStations: PropTypes.object,
    queued: PropTypes.number,
    processing: PropTypes.number,
    delivered: PropTypes.number,
    retry: PropTypes.number,
    failed: PropTypes.number,
    deliveryAllowed: PropTypes.bool,
}
