import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {compose} from 'redux'
import find from 'lodash-es/find'
import some from 'lodash-es/some'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Error from 'ipmp-react-ui/Error'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import withRunnerLoader from 'containers/withRunnerLoader'
import page from 'permissions/panel/firmware/page'
import {VENDOR_NEO, VENDOR_QOLSYS} from 'constants/panelVendorType'
import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'
import {fetch, startPoll, stopPoll} from 'modules/panels/firmware/actions'
import {__} from 'utils/i18n'
import {showFirmwarePatchTagUpdateModal} from 'modules/modals/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

import FirmwarePatchTag from './FirmwarePatchTag'
import UpgradeButton from './Buttons/UpgradeButton'
import FirmwareRow from './FirmwareRow'

const FirmwareBase = ({appliances, hasTransactionSupport, runner}) => {
    const [selectedPackages, setSelectedPakages] = useState([])
    const onPackageChange = (newPackageName, type, number) => {
        if (!hasTransactionSupport) {
            if (newPackageName === '') {
                deselectPackage({type, number})
            } else {
                setSelectedPakages([
                    {
                        type,
                        number,
                        packageName: newPackageName,
                    },
                ])
            }
        } else {
            const newDeselectLinkedPackages = deselectLinkedPackages({
                type,
                number,
            })
            const newSelectedPackages = selectLinkedPackages(
                newPackageName,
                newDeselectLinkedPackages
            )

            setSelectedPakages(newSelectedPackages)
        }
    }

    const deselectPackage = ({type, number}) => {
        const samePackage = find(selectedPackages, {type, number})
        if (samePackage) {
            setSelectedPakages([])
        }
    }

    const deselectLinkedPackages = ({type, number}) => {
        const selectedPackageName = getSelectedPackageName({type, number})
        return !selectedPackageName
            ? [...selectedPackages]
            : selectedPackages.filter(
                  ({packageName}) => packageName !== selectedPackageName
              )
    }

    const selectLinkedPackages = (packageName, packages) => {
        appliances.forEach(({number, type, packages: availablePackages}) => {
            if (some(availablePackages, {name: packageName})) {
                packages = packages.filter(
                    (pkg) => !(pkg.number === number && pkg.type === type)
                )

                packages.push({type, number, packageName})
            }
        })

        return packages
    }

    /** todo: move that state to reducer */
    const getSelectedPackageName = ({type, number}) => {
        const pkg = find(selectedPackages, {type, number})

        return pkg ? pkg.packageName : null
    }

    const renderContent = () => {
        if (!appliances) {
            return null
        }

        return appliances.map((appliance) => (
            <FirmwareRow
                onChange={onPackageChange}
                key={`${appliance.type}${appliance.number}`}
                selectedPackageName={getSelectedPackageName(appliance)}
                {...appliance}
            />
        ))
    }

    const renderHeader = () => {
        const packages = Object.values(selectedPackages)

        return (
            <Bar orientation={RIGHT} className="bar">
                <Buttons>{!runner && <UpgradeButton packages={packages} />}</Buttons>
            </Bar>
        )
    }

    if (!appliances || !appliances.length) {
        return <Error title={__('No upgradable packages')} />
    }

    return (
        <Layout vertical className="firmware panelFirmware">
            {renderHeader()}

            <div className="table-header">
                <div className="table-header-cell">{__('Appliance')}</div>
                <div className="table-header-cell">{__('Current version')}</div>
                <div className="table-header-cell">{__('Apply package')}</div>
            </div>

            <ScrollView className="card">{renderContent()}</ScrollView>
        </Layout>
    )
}

FirmwareBase.propTypes = {
    panelId: PropTypes.number.isRequired,
    hasTransactionSupport: PropTypes.bool,
    appliances: PropTypes.arrayOf(
        PropTypes.shape({
            number: PropTypes.number,
            type: PropTypes.string,
            currentVersion: PropTypes.string,
            packages: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    description: PropTypes.string,
                    version: PropTypes.string,
                })
            ),
        })
    ),
}

const ConnectedFirmware = compose(
    withPermissionRejection(page),
    withLoader(({fetch, panelId}) => {
        fetch(panelId)
    }),
    withRunnerLoader(
        () => __('Upgrading panel software…'),
        ({fetch, panelId}) => fetch(panelId)
    )
)(FirmwareBase)

export default function Firmware() {
    const panelId = useRouterPanelId()

    const {isLoading, appliances, runner, hasTransactionSupport, vendor, info} =
        useSelector(({panels: {firmware, store}, processes}) => {
            const {appliances, runner} = firmware[panelId] || {}

            return {
                panelId,
                isLoading: !appliances,
                appliances,
                runner,
                hasTransactionSupport: store.byIds[panelId].vendor === VENDOR_NEO,
                vendor: store.byIds[panelId].vendor,
                info: store.byIds[panelId].extendedInfo,
            }
        })

    const actions = useActions({
        fetch,
        startPoll,
        stopPoll,
        handler: () => showFirmwarePatchTagUpdateModal({panelId}),
    })

    useEffect(() => {
        actions.startPoll(panelId)
        return () => actions.stopPoll(panelId)
    }, [])

    if (vendor === VENDOR_QOLSYS) {
        return (
            <FirmwarePatchTag
                panelId={panelId}
                info={info}
                actions={actions}
                runner={runner}
                isLoading={isLoading}
            />
        )
    }

    return (
        <ConnectedFirmware
            runner={runner}
            panelId={panelId}
            isLoading={isLoading}
            fetch={actions.fetch}
            appliances={appliances}
            hasTransactionSupport={hasTransactionSupport}
        />
    )
}
