import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import measure, {HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST} from './measure'
import {CONFIGURATION_SEPARATOR, MENU_TYPE} from 'constants/panelConfiguration'
import {MARGIN} from 'constants/themes'

import ConfigurationSection from './ConfigurationSection'
import ConfigurationRowsList from './ConfigurationRowsList'

function ConfigurationList({
    bindMoveTo,
    onChange,
    onMove,
    isChecked,
    changes,
    sections,
    backup,
    heightOffsetBeforeConfiguration = HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST,
    exporting,
    featuresetValidationRules,
    isEditable,
    isShowExportCheckbox,
    isChanged,
    hasUndo,
    onSetExport,
    onUndo,
}) {
    const [state, setState] = useState({
        from: 0,
        height: window.innerHeight - heightOffsetBeforeConfiguration,
        measures: measure(sections),
        sections,
        bottomScrolled: false,
    })

    const elementRef = useRef(null)

    useEffect(() => {
        const handleResize = () =>
            setState((prevState) => ({
                ...prevState,
                height: window.innerHeight,
                measures: measure(sections),
            }))

        window.addEventListener('resize', handleResize)

        if (bindMoveTo) {
            bindMoveTo(moveTo)
        }

        return () => window.removeEventListener('resize', handleResize)
    }, [bindMoveTo, sections])

    useEffect(() => {
        setState((prevState) => ({
            ...prevState,
            measures: measure(sections),
        }))
    }, [sections])

    const handleScroll = (e) => {
        if (e.target !== e.currentTarget) {
            return
        }
        const bottomScrolled =
            e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - MARGIN

        setState((prevState) => ({...prevState, bottomScrolled: bottomScrolled}))

        const from = e.target.scrollTop
        const visibleSection = state.measures
            .filter(({offset}) => offset <= from)
            .slice(-1)
            .pop()

        if (!visibleSection) {
            return
        }

        setState((prevState) => ({...prevState, from}))

        if (onMove && !bottomScrolled) {
            const lastPassedHead = visibleSection.items
                .filter(({offset, node}) => node.type === MENU_TYPE && offset <= from)
                .slice(-1)
                .pop()

            const currentHeadlineElement = lastPassedHead
                ? lastPassedHead.key
                : visibleSection.key
            onMove(currentHeadlineElement.join(CONFIGURATION_SEPARATOR))
        }
    }

    const moveTo = (key) => {
        if (!elementRef.current) {
            return
        }

        const sectionKey = key && key.split(CONFIGURATION_SEPARATOR).shift()
        const section = state.measures.find(({node}) => sectionKey === node.key)

        if (!section) {
            return
        }

        const item =
            section.node.key === key
                ? section
                : section.items.find(
                      (node) => node.key.join(CONFIGURATION_SEPARATOR) === key
                  )

        if (item) {
            elementRef.current.scrollTop = ++item.offset
        }
    }

    const {from, height: stateHeight, bottomScrolled} = state
    const to = from + stateHeight

    return (
        <div
            className={classes('configuration-content', {
                'bottom-configuration-scrolled': bottomScrolled,
            })}
            ref={elementRef}
            onScroll={handleScroll}
        >
            {state.measures.map(({node, offset, items, height}) => (
                <ConfigurationSection
                    key={node.key}
                    node={node}
                    offset={offset}
                    height={height}
                    from={from}
                    to={to}
                >
                    <ConfigurationRowsList
                        items={items}
                        from={from}
                        to={to}
                        changes={changes}
                        backup={backup}
                        exporting={exporting}
                        featuresetValidationRules={featuresetValidationRules}
                        isEditable={isEditable}
                        isShowExportCheckbox={isShowExportCheckbox}
                        isChanged={isChanged}
                        isChecked={isChecked}
                        hasUndo={hasUndo}
                        onChange={onChange}
                        onSetExport={onSetExport}
                        onUndo={onUndo}
                    />
                </ConfigurationSection>
            ))}
        </div>
    )
}

ConfigurationList.propTypes = {
    bindMoveTo: PropTypes.func,
    onChange: PropTypes.func,
    onMove: PropTypes.func,
    isChecked: PropTypes.func,
    changes: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    ),
    sections: PropTypes.array,
    backup: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
    heightOffsetBeforeConfiguration: PropTypes.number.isRequired,
}

export default ConfigurationList
