import React from 'react'
import {useSelector} from 'react-redux'
import find from 'lodash-es/find'
import get from 'lodash-es/get'

import MultiSelect from 'ipmp-react-ui/MultiSelect'
import {ReactComponent as IconPartition} from 'ipmp-react-ui/icons/icons-partition.svg'

import {fetch as fetchPartitions} from 'modules/panels/state/actions'
import withSelectLoader from 'containers/withSelectLoader'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import __, {__n} from 'utils/i18n'
import {DEFAULT_PARTITION_VALUE, preparePartitionName} from 'constants/partitions'

const PartitionsMultiSelect = withSelectLoader(
    (prefix, maxOptionsToShow, {panelId}) => fetchPartitions(panelId),
    ({panels: {state}}, {panelId}) => !get(state, ['byIds', panelId, 'partitions'], true),
    ({panels: {state}}, {isLoading, panelId}) => {
        const partitionsList = Object.values(state.byIds[panelId].partitions)
            .filter(({id}) => id > 0)
            .map(({id, name}) => {
                return preparePartitionName(id, name)
            })
        const partitions =
            partitionsList.length !== 0
                ? partitionsList
                : [preparePartitionName(DEFAULT_PARTITION_VALUE)]

        return !isLoading && partitions ? partitions : []
    }
)(MultiSelect)

const PartitionSelect = ({
    enrollmentPrefix,
    panelId,
    handleAddPartition,
    handleRemovePartition,
    defaultValues,
    iconLeft,
}) => {
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))

    const maxPartitions =
        find(features?.devices?.devices, {
            enrollmentPrefix,
        })?.maxPartitions || 1

    return (
        <PartitionsMultiSelect
            label={__('Partitions')}
            name="partitions"
            panelId={panelId}
            hasSelectAll={maxPartitions > 1}
            maxSelectOptions={maxPartitions}
            maxSelectOptionsLabel={__n(
                'Only %d partition allowed to select for this device',
                'Only %d partitions allowed for this device',
                maxPartitions
            )}
            onAdd={handleAddPartition}
            onRemove={handleRemovePartition}
            defaultValues={defaultValues && defaultValues}
            Icon={IconPartition}
            className="full-width"
            iconLeft
        />
    )
}

export default PartitionSelect
