import React from 'react'
import PropTypes from 'prop-types'

import {VENDOR_QOLSYS} from 'constants/panelVendorType'
import Autolearn from 'components/Devices/Autolearn'

export default function Automation({serial, vendor, onClose, automationProtocols}) {
    return VENDOR_QOLSYS !== vendor ? null : (
        <Autolearn
            automationProtocols={automationProtocols}
            serial={serial}
            onClose={onClose}
            onDismiss={onClose}
        />
    )
}

Automation.propTypes = {
    serial: PropTypes.string.isRequired,
    vendor: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    automationProtocols: PropTypes.array.isRequired,
}
