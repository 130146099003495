import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const InteractiveSession = () => {
    const {
        doAutoEnableUserApp,
        doAutoEnableConfiguratorApp,
        isDlsServiceEnabled,
        globalServerApptype,
        maxInteractiveConnections,
        maxInteractiveConnectionsPerPanel,
        doRequireUserInstallerApproval,
        requireUserInstallerApprovalTimeout,
    } = useSelector((state) => state.system.settings.interactiveSession.data)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Enable Customer App for New Incoming Panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doAutoEnableUserApp)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Enable Installer App for New Incoming Panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doAutoEnableConfiguratorApp)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Enable DLS service')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(isDlsServiceEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Application Type')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(globalServerApptype)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Limit of Concurrent Interactive Connections per Server')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxInteractiveConnections)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Limit of Concurrent Interactive Sessions per Panel')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxInteractiveConnectionsPerPanel)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Approve Installer Access by Customer')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doRequireUserInstallerApproval)}
                </InfoCardTileContent>
            </InfoCardTile>
            {doRequireUserInstallerApproval && (
                <InfoCardTile>
                    <InfoCardTileTitle>
                        {__('Installer Access Timeout [Minutes]')}
                    </InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(requireUserInstallerApprovalTimeout)}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
        </>
    )
}

export default InteractiveSession
