import {handleActions} from 'redux-actions'

import {
    fetch,
    fetchTestsHistory,
    setError,
    setLoading,
    updateTestsHistory,
} from './actions'
import {set} from 'immutable-modify'

const defaultState = {
    isLoading: false,
    error: null,
}

export default handleActions(
    {
        [fetch](state, {payload}) {
            const {panelId} = payload

            return {
                ...state,
                error: null,
                isLoading: true,
                panelId: panelId,
            }
        },
        [setLoading](state, {payload}) {
            return set(state, 'isLoading', payload)
        },
        [setError](state, {payload}) {
            return {
                ...state,
                error: payload,
                isLoading: false,
            }
        },
        [fetchTestsHistory](state) {
            return {
                ...state,
                isLoading: true,
            }
        },
        [updateTestsHistory](state) {
            return {
                ...state,
                isLoading: false,
            }
        },
    },
    defaultState
)
