export const HAD_STATE_ON = 0
export const HAD_STATE_OFF = 1
export const HAD_STATE_STATELESS = 2
export const HAD_STATE_STATELESS_TOGGLE = 3

export const HAD_DOORLOCK_LOCKED = 1
export const HAD_DOORLOCK_UNLOCKED = 0
export const HAD_DOORLOCK_JAMMED = 2

export const HAD_WATER_VALVE_OPENED = 0
export const HAD_WATER_VALVE_CLOSED = 1

export const isLocked = (state) => state === HAD_DOORLOCK_LOCKED

export const isOn = (state) => state === HAD_STATE_ON
export const isOff = (state) => state === HAD_STATE_OFF
export const isStateless = (state) => state === HAD_STATE_STATELESS
export const isStatelessToggle = (state) => state === HAD_STATE_STATELESS_TOGGLE

export const reverseHadStateMap = new Map([
    [HAD_STATE_ON, HAD_STATE_OFF],
    [HAD_STATE_OFF, HAD_STATE_ON],
])
