import {put} from 'redux-saga/effects'
import * as actions from '../actions'

export default function* watchEditPanelInfo({meta}) {
    const {id, data} = meta

    yield put(
        actions.update({
            id,
            ...data,
        })
    )
}
