import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import {editEventsProfileEventProfiles} from 'modules/forms/handlers'
import useGroupNotificationsEventProfiles from 'hooks/group/useGroupNotificationsEventProfiles'

import EventsForm, {EVENT_PROFILE} from './EventsForm'

export default function EditEventsProfile({hide, groupId}) {
    const {handle, isLoading, form} = useForm(editEventsProfileEventProfiles)
    const {settings} = useGroupNotificationsEventProfiles(groupId)

    const handler = (data) => handle(groupId, data)

    return (
        <EventsForm
            {...form}
            data={settings}
            hide={hide}
            handle={handler}
            isLoading={isLoading}
            profiles={settings.eventProfile}
            fieldGroupKey={EVENT_PROFILE}
            header={__('Edit events profile')}
            availableProfiles={settings.availableProfiles.eventProfile}
        />
    )
}
