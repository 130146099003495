import __ from 'utils/i18n'

export default function getRules(showPartitions) {
    const rules = {
        deviceId: {
            presence: {
                message: __('You should define enroll code of device'),
            },
            length: {
                is: 6,
            },
        },
        deviceSubtype: {
            presence: {
                message: __('You should define subtype of device'),
            },
        },
    }

    const partitionRules = !showPartitions
        ? {}
        : {
              partitions: {
                  presence: {
                      message: __('You should define partitions to enroll device'),
                  },
              },
          }

    return {
        ...rules,
        ...partitionRules,
    }
}
