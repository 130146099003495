import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import get from 'lodash-es/get'

import {ReactComponent as IconStar} from 'ipmp-react-ui/icons/star.svg'
import {ReactComponent as IconGear} from 'ipmp-react-ui/icons/gear.svg'
import {ReactComponent as IconTimer} from 'ipmp-react-ui/icons/timer-filed.svg'

import Item, {
    PanelBillingCurrentPlanItemsContainer as Container,
} from './PanelBillingCurrentPlanItem'
import useCountries from 'hooks/pages/useCountries'
import useBilling from 'hooks/pages/useBilling'

import {BILLING_PLAN_STATUS_DEACTIVATED} from 'constants/billingPlan'

import __ from 'utils/i18n'
import {stringify} from 'utils/dateParser'

const CurrentPlanItem = ({plan, status}) => {
    const isPlanDeactivated = status === BILLING_PLAN_STATUS_DEACTIVATED

    const value = isPlanDeactivated ? __('Pending') : plan.name

    return (
        <Item
            name={__('Current plan')}
            value={value}
            Icon={IconStar}
            isAnimated={isPlanDeactivated}
        />
    )
}
const CountryItem = ({country: {isLoading, country}, onClick}) => {
    const Country = ({value, onClick}) => {
        return (
            <Item
                name={__('Country')}
                value={value}
                Icon={IconGear}
                onEditClick={onClick}
            />
        )
    }

    if (isLoading) {
        return <Country value={__('Loading...')} isAnimated={isLoading} />
    }

    if (country === null) {
        return <Country value={__('Not Specified')} />
    }

    return <Country value={country.name} onClick={onClick} />
}

const RenewalDateItem = ({value}) => {
    value = stringify(value, 'LL', __('Unlimited'))

    return <Item name={__('Renewal date')} value={value} Icon={IconTimer} />
}

const FuturePlanItem = ({value}) => {
    if (!value) {
        value = __('Not specified')
    }
    return <Item name={__('Future plan')} value={value} Icon={IconStar} />
}

export default function PanelBillingCurrentPlan({
    billingStatus: {
        currentPlanId,
        requestedPlanId,
        currentPlanStatus,
        planTill,
        countryCode,
    },
    panelId,
}) {
    const countries = useCountries()
    const billing = useBilling(panelId)

    useEffect(() => {
        countries.fetch()
    }, [])

    const futurePlan = billing.selectPlan(requestedPlanId)
    const currentPlan = billing.selectPlan(currentPlanId)
    const country = countries.selectByAlpha3Code(countryCode)

    return (
        <Container>
            <CurrentPlanItem plan={currentPlan} status={currentPlanStatus} />

            <CountryItem
                country={country}
                onClick={() => billing.showModal(currentPlanId)}
            />

            <RenewalDateItem value={planTill} />

            <FuturePlanItem value={get(futurePlan, 'name')} />
        </Container>
    )
}

PanelBillingCurrentPlan.propTypes = {
    billingStatus: PropTypes.shape({
        availablePlans: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired,
            })
        ).isRequired,
        currentPlanId: PropTypes.string.isRequired,
        currentPlanStatus: PropTypes.string.isRequired,
        planTill: PropTypes.instanceOf(Date),
        requestedPlanId: PropTypes.string,
        countryCode: PropTypes.string,
    }).isRequired,
    panelId: PropTypes.number.isRequired,
}
