import React from 'react'
import {useLocation} from 'react-router'

import {routesMap} from 'constants/searchRoutes'

const SearchTopNav = () => {
    const location = useLocation()
    const currentRoute = location.pathname

    const matchedKey = Object.keys(routesMap).find((routeKey) => {
        const regex = new RegExp(`^${routeKey}$`)
        return currentRoute.match(regex)
    })

    if (!routesMap[matchedKey]) {
        return null
    }

    const Component = routesMap[matchedKey]

    return <Component className="main-search" />
}

export default SearchTopNav
