import React from 'react'
import PropTypes from 'prop-types'

import {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {convertConfigValue} from 'utils/gettext'
import EditCSCommunicatingProfilesButton from 'pages/Group/Stations/EditCSCommunicatingProfilesButton'
import csProfileType from 'constants/csProfileType'

export default function CentralStationCard({
    links,
    station,
    profiles,
    group: {id: groupId},
}) {
    const {name, protocol, id: stationId} = station
    const editBtn = (
        <EditCSCommunicatingProfilesButton groupId={groupId} stationId={stationId} />
    )

    return (
        <InfoCard cols={4} title={name} subtitle={protocol} headerButton={editBtn}>
            {Object.values(profiles).map((profile) => {
                const isEnabled = links.includes(profile.id)
                const tileContentClass = isEnabled ? 'enabled' : 'disabled'

                return (
                    <InfoCardTile key={profile.name}>
                        <InfoCardTileTitle>
                            {csProfileType(profile.name)}
                        </InfoCardTileTitle>
                        <InfoCardTileContent className={tileContentClass}>
                            {convertConfigValue(isEnabled)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                )
            })}
        </InfoCard>
    )
}

CentralStationCard.propTypes = {
    station: PropTypes.object.isRequired,
    links: PropTypes.array,
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
}
