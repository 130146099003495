import React from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

/**
 * I feel like this component should be generalized and moved to web-ui
 * For now I leave it there, but it should be considered how to move it to the higher level
 *
 * PS I don't like naming I gave. If you have ideas - please rename it.
 */
export default function TooltipList({title, error, success, warning, primary, children}) {
    return (
        <div className="tooltipList">
            {title && (
                <h5
                    className={classes('tooltipList-heading', {
                        'tooltipList-heading--error': error,
                        'tooltipList-heading--success': success,
                        'tooltipList-heading--warning': warning,
                        'tooltipList-heading--primary': primary,
                    })}
                >
                    {title}
                </h5>
            )}
            <ul className="tooltipList-list">{children}</ul>
        </div>
    )
}

TooltipList.propTypes = {
    title: PropTypes.string,
    error: PropTypes.bool,
    success: PropTypes.bool,
    warning: PropTypes.bool,
    primary: PropTypes.bool,
}

export function TooltipListItem({Icon, children, monospace}) {
    return (
        <li className="tooltipList-list-item">
            {Icon && (
                <div className="tooltipList-list-item-icon">
                    <Icon />
                </div>
            )}
            <p
                className={classes('tooltipList-list-item-content', {
                    'tooltipList-list-item-content--monospace': monospace,
                })}
            >
                {children}
            </p>
        </li>
    )
}

TooltipListItem.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    monospace: PropTypes.bool,
}
