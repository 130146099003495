import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Select, {Option} from 'ipmp-react-ui/Select'
import {ReactComponent as IconNumber} from 'ipmp-react-ui/icons/icons_number.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {clear} from 'modules/forms/actions'
import __ from 'utils/i18n'
import {PartitionSelect} from 'components/Panel'
import SelectProtocol from 'components/Devices/SelectProtocol'
import {deviceSubtypeMap, srfDevices} from 'constants/deviceSubtype'
import {DTP_POWERG, DTP_SRF} from 'constants/protocols'

const SRFFields = ({
    panelId,
    deviceId,
    handleDeviceIdChange,
    handleDeviceIdKeyDown,
    handleSRFsubtypeChange,
    showPartitions,
    setProtocol,
}) => {
    const {clearForm} = useActions({clearForm: clear})
    return (
        <Fragment>
            <SelectProtocol
                onChange={setProtocol}
                defaultValue={DTP_SRF}
                protocols={[DTP_POWERG, DTP_SRF]}
            />
            <Input
                label={__('Enrollment ID')}
                name="deviceId"
                type="text"
                maxLength={6}
                value={deviceId}
                onChange={(e) => {
                    clearForm()
                    handleDeviceIdChange(e)
                }}
                onKeyDown={handleDeviceIdKeyDown}
                Icon={IconNumber}
                iconLeft
            />
            <Select
                label={__('Sensor type')}
                name="deviceSubtype"
                onChange={(e, value) => handleSRFsubtypeChange(e, value)}
            >
                {srfDevices.map((srfDevice) => (
                    <Option
                        label={deviceSubtypeMap.get(srfDevice)()}
                        value={srfDevice}
                        key={srfDevice}
                    />
                ))}
            </Select>
            {!!showPartitions && (
                <PartitionSelect
                    enrollmentPrefix={deviceId?.match(/\d*/)?.[0]}
                    showPartitions={showPartitions}
                    panelId={panelId}
                />
            )}
        </Fragment>
    )
}

SRFFields.propTypes = {
    setProtocol: PropTypes.func.isRequired,
}

export default SRFFields
