import {PARTITION_ALL} from 'constants/partitions'
import React, {useCallback, useState} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'

import Tabs from 'ipmp-react-ui/Tabs'
import {CardActions} from 'ipmp-react-ui/Card'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {__} from 'utils/i18n'
import {withPermissionRejection} from 'containers/withPermission'
import sidebar from 'permissions/panel/state/sidebar'
import list from 'permissions/panel/devices/list'
import {
    selectBypassableDevices,
    selectBypassableDevicesByPartition,
} from 'modules/devices/list/selectors'
import withLoader from 'containers/withLoader'
import {bypass} from 'permissions/panel/devices/actions'
import PanelStateBypassDevices from 'pages/Panel/PanelState/SideBar/PanelStateBybassDevices'
import PanelStateSidebarBypassAll from 'pages/Panel/PanelState/SideBar/PanelStateSidebarBypassAll'
import {openedBypassableDeviceFilter} from 'constants/device'
import {MOTION_TROUBLE} from 'constants/troubleType'
import usePermission from 'hooks/usePermission'
import SetLabelButton from './Buttons/SetLabelButton'
import SetBypassButton from './Buttons/SetBypassButton'
import {SEVERITY_TROUBLE} from 'constants/severityType'

const filterBypassState = (deviceBypassStates, deviceId) => {
    const newDeviceBypassStates = new Map(deviceBypassStates)
    newDeviceBypassStates.delete(deviceId)
    return newDeviceBypassStates
}

const PanelStateDevicesTools = ({devices, panelId, partition}) => {
    const {isPermitted: isBypassAllowed} = usePermission(bypass)
    const [deviceBypassStates, setDeviceBypassStates] = useState(new Map())

    const onBypassChange = useCallback((deviceId, enabled) => {
        if (!deviceBypassStates.has(deviceId)) {
            const newDeviceBypassStates = new Map(deviceBypassStates)
            newDeviceBypassStates.set(deviceId, enabled)
            setDeviceBypassStates(newDeviceBypassStates)
        } else if (enabled !== deviceBypassStates.get(deviceId)) {
            setDeviceBypassStates(filterBypassState(deviceBypassStates, deviceId))
        }
    })

    const onBypassAll = (devices, checked) => {
        const newDeviceBypassStates = new Map()

        if (checked) {
            devices.forEach((device) => {
                const hasTroubles =
                    device?.warnings &&
                    device?.warnings.filter(
                        (warning) => warning.severity === SEVERITY_TROUBLE
                    )
                if (!device?.traits?.bypass?.enabled && hasTroubles) {
                    newDeviceBypassStates.set(device.id, true)
                }
            })
        }

        setDeviceBypassStates(newDeviceBypassStates)
    }

    const openedDevices = devices.filter(openedBypassableDeviceFilter)
    const troubledDevices = devices.filter(
        (device) =>
            device.hasTroubles &&
            device.warnings.find(
                (warning) =>
                    warning.type !== MOTION_TROUBLE &&
                    warning.severity === SEVERITY_TROUBLE
            )
    )

    const selectedToEnableCount = Array.from(deviceBypassStates.values()).filter(
        Boolean
    ).length
    const selectedToDisableCount = Array.from(deviceBypassStates.values()).filter(
        (enabled) => !enabled
    ).length

    return (
        <div className="panelState-devicesTools">
            <Tabs>
                <PanelStateBypassDevices
                    name={__('All Bypassable Devices')}
                    errorTitle={__('No Bypassable Devices')}
                    devices={devices}
                    partitionId={partition.id}
                    deviceBypassStates={deviceBypassStates}
                    isBypassAllowed={isBypassAllowed}
                    onBypassChange={onBypassChange}
                    ByPassAll={
                        <PanelStateSidebarBypassAll
                            selectedToEnableCount={selectedToEnableCount}
                            selectedToDisableCount={selectedToDisableCount}
                            isBypassAllowed={isBypassAllowed}
                            onBypassChange={(e) =>
                                onBypassAll(troubledDevices, e.target.checked)
                            }
                            deviceBypassStates={deviceBypassStates}
                            devices={troubledDevices}
                            label={__('Bypass with troubles')}
                        />
                    }
                />
                <PanelStateBypassDevices
                    name={__('Opened Bypassable Devices')}
                    errorTitle={__('No opened bypassable devices')}
                    className="partitionDevices-sidebar-openedDevices"
                    devices={openedDevices}
                    deviceBypassStates={deviceBypassStates}
                    isBypassAllowed={isBypassAllowed}
                    onBypassChange={onBypassChange}
                    ByPassAll={
                        <PanelStateSidebarBypassAll
                            selectedToEnableCount={selectedToEnableCount}
                            selectedToDisableCount={selectedToDisableCount}
                            isBypassAllowed={isBypassAllowed}
                            onBypassChange={(e) =>
                                onBypassAll(openedDevices, e.target.checked)
                            }
                            deviceBypassStates={deviceBypassStates}
                            devices={openedDevices}
                        />
                    }
                />
            </Tabs>
            <CardActions>
                {list &&
                    (Boolean(selectedToEnableCount) ||
                        Boolean(selectedToDisableCount)) && (
                        <SetBypassButton
                            primary
                            className="partitionDevices-sidebar-button"
                            deviceBypassStates={deviceBypassStates}
                            panelId={panelId}
                            resetSelection={() => setDeviceBypassStates(new Map())}
                        >
                            {__('Set Bypass')}
                        </SetBypassButton>
                    )}
                {partition.id !== PARTITION_ALL && (
                    <SetLabelButton
                        flatShadowed
                        Icon={IconEdit}
                        className="partitionDevices-setLabelBtn"
                        partition={partition}
                        panelId={panelId}
                    >
                        {__('Edit name')}
                    </SetLabelButton>
                )}
            </CardActions>
        </div>
    )
}

PanelStateDevicesTools.propTypes = {
    partition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
    isAbstract: PropTypes.bool,
    devices: PropTypes.array.isRequired,
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func,
}

export default compose(
    withPermissionRejection(sidebar),
    connect((state, {panelId, partition}) => {
        const devices =
            partition.id === PARTITION_ALL
                ? selectBypassableDevices(state, panelId)
                : selectBypassableDevicesByPartition(state, panelId, partition.id)

        return {
            devices,
            isLoading: !devices,
        }
    }),
    withLoader()
)(PanelStateDevicesTools)
