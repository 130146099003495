import React, {Component} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'

import Button from 'ipmp-react-ui/Button'

import {withVisibility} from 'containers/withVisibility'
import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/basicConfiguration/push/actions'
import __ from 'utils/i18n'
import path from 'utils/path'
import IconBack from 'ui/IconBack'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {pushBasic} from 'permissions/panels/selection'
import {useActions} from 'modules/higherOrder/useActions'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectBasicConfigurationPushList} from 'modules/basicConfiguration/push/selectors'

function PushBasicConfigurationSearchBase({className}) {
    const {scope} = useParams()
    const {filters: selected, suggests} = useSelector(selectBasicConfigurationPushList)

    const {apply, onSelect, onDeselect, onClear, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('serial', __('Panel Id')),
        new SuggestFilter('account', __('Account')),
        new SuggestFilter('group', __('Group')),
        new SuggestFilter('model', __('Model')),
    ]

    return (
        <Search
            key={scope}
            apply={apply}
            onClear={onClear}
            filters={filters}
            suggests={suggests}
            selected={selected}
            onSelect={onSelect}
            className={className}
            onSuggest={onSuggest}
            onDeselect={onDeselect}
        />
    )
}

class PushBasicConfigurationSearchBar extends Component {
    handleGoBack = () => {
        this.props.navigate(path('basicConfigurations', {}))
    }

    render() {
        return (
            <header className="pushBasicConfiguration-header">
                <Button
                    onClick={this.handleGoBack}
                    className="btn--goBack pushBasicConfiguration-btn--goBack"
                >
                    <IconBack />
                </Button>

                <PushBasicConfigurationSearchBase className="pushBasicConfiguration-search" />
            </header>
        )
    }
}

const PushBasicConfigurationSearchBarVisibility = withVisibility()(
    PushBasicConfigurationSearchBar
)

export default function PushBasicConfigurationSearch() {
    const navigate = useNavigate()
    const {isPermitted} = usePermission(pushBasic)

    return (
        <PushBasicConfigurationSearchBarVisibility
            navigate={navigate}
            isVisible={isPermitted}
        />
    )
}
