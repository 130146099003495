import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Togglebox from 'ipmp-react-ui/ToggleBox'

import __ from 'utils/i18n'
import {configurator} from 'permissions/panels/apps'
import {withFeatureRejection} from 'containers/withFeature'
import {selectAppConfigurator} from 'modules/features/store/selectors'
import Void from 'components/Void'
import {useActions} from 'modules/higherOrder/useActions'
import usePanel from 'hooks/pages/usePanel'
import {setConfigurationAppState} from 'modules/panels/one/actions'
import {withPermissionVisibility} from 'containers/withPermission'

class ToggleInstallerAppItemBase extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            configuratorApp: PropTypes.bool,
        }),
    }

    handleInstallerAppChange = (e) => {
        const {setConfigurationAppState, panel} = this.props
        setConfigurationAppState(panel, e.target.checked)
    }

    render() {
        const {panel} = this.props

        return (
            <div className="menu-item">
                <Togglebox
                    label={__('Installer App')}
                    onChange={this.handleInstallerAppChange}
                    checked={panel.configuratorApp || false}
                />
            </div>
        )
    }
}

const ToggleInstallerAppItemRejection = compose(
    withPermissionVisibility(configurator),
    withFeatureRejection(selectAppConfigurator, Void)
)(ToggleInstallerAppItemBase)

export default function ToggleInstallerAppItem() {
    const panel = usePanel()
    const {setConfigurationAppState: setConfigurationAppStateAction} = useActions({
        setConfigurationAppState,
    })

    return (
        <ToggleInstallerAppItemRejection
            {...panel}
            setConfigurationAppState={setConfigurationAppStateAction}
        />
    )
}
