import React from 'react'

import Page from 'ipmp-react-ui/Page'

import UsersGridBar from './UsersGridBar'
import UsersTable from './UsersTable'

export default function UsersPage() {
    return <Page Bar={UsersGridBar} Content={UsersTable} />
}
