import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {DTP_POWERG} from 'constants/protocols'
import useEnrollPermissions from 'hooks/panel/devices/useEnrollPermissions'

import PowerG from './PowerG'
import Sfr from './SRF'
import Auto from './Auto'

export default function Security({panelId, onClose, isAutoLearnAvailable}) {
    const [isAuto, setIsAuto] = useState(false)
    const [protocol, setProtocol] = useState(DTP_POWERG)
    const {enrollPermitted} = useEnrollPermissions()
    const {srf} = useSelector(({features}) => features.store.byIds[panelId] || {})
    const isSRFEnabled = enrollPermitted && srf?.isEnabled

    return (
        <>
            {isAuto && (
                <Auto
                    panelId={panelId}
                    onClose={onClose}
                    onDismiss={() => setIsAuto(false)}
                />
            )}

            {!isAuto && (
                <PowerG
                    panelId={panelId}
                    onClose={onClose}
                    protocol={protocol}
                    setProtocol={setProtocol}
                    startAuto={() => setIsAuto(true)}
                    isSRFEnabled={isSRFEnabled}
                    isAutoLearnAvailable={isAutoLearnAvailable}
                />
            )}

            {!isAuto && isSRFEnabled && (
                <Sfr
                    panelId={panelId}
                    onClose={onClose}
                    protocol={protocol}
                    setProtocol={setProtocol}
                    startAuto={() => setIsAuto(true)}
                    isAutoLearnAvailable={isAutoLearnAvailable}
                />
            )}
        </>
    )
}

Security.propTypes = {
    panelId: PropTypes.number.isRequired,
    serial: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    isAutoLearnAvailable: PropTypes.bool.isRequired,
}
