import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import classNames from 'classnames'

import Spinner from 'ipmp-react-ui/Spinner'
import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import __ from 'utils/i18n'
import {selectAddedDevices} from 'modules/devices/autolearn/selectors'
import {getTransportProtocolTitle} from 'constants/protocols'

import AddedDevicesTable from './AddedDevicesTable'

export default function SessionContentAutomation({session}) {
    const devices = useSelector((state) => selectAddedDevices(state, {session}))
    const hasTable = devices && devices.length > 0

    return (
        <>
            <div
                className={classNames({
                    'autolearn-with-table': hasTable,
                    'autolearn-in-process': !hasTable,
                })}
            >
                <div className="autolearn-description-content">
                    <IconAutolearn />
                    <div className="autolearn-description-content-wrapper">
                        <div className="autolearn-description-content-title">
                            {hasTable
                                ? __(
                                      'Enroll %s',
                                      getTransportProtocolTitle(session.protocol)
                                  )
                                : __(
                                      'Enroll %s automation',
                                      getTransportProtocolTitle(session.protocol)
                                  )}
                        </div>
                        <div className="autolearn-description-content-message">
                            {hasTable
                                ? __(
                                      'Press and hold enroll button on device for 5 seconds'
                                  )
                                : __('Press "Learn" button on device')}
                        </div>
                    </div>
                </div>
                <Spinner />
            </div>
            {devices && devices.length > 0 && (
                <div className="autolearned-devices-table">
                    <div className="autolearned-devices-table-title">
                        {__('Autolearn status')}
                    </div>
                    <AddedDevicesTable devices={devices} />
                </div>
            )}
        </>
    )
}

SessionContentAutomation.propTypes = {
    session: PropTypes.shape({
        protocol: PropTypes.number.isRequired,
    }).isRequired,
}
