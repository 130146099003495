import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

const CommonConnected = ({data}) => {
    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__("System ID's Mask")}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.systemIdRegex)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Remove Panel by Resetting the Receiver IP')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(data.isPanelRemoveForever)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

CommonConnected.propTypes = {
    data: PropTypes.shape({
        systemIdRegex: PropTypes.string,
        isPanelRemoveForever: PropTypes.bool,
    }),
}

export default compose(
    connect(
        ({
            system: {
                settings: {commonConnected},
            },
        }) => ({...commonConnected})
    )
)(CommonConnected)
