import {get, post} from '../http'
import {mapProcess} from '../processes'
import mapValidationErrors from 'api/base/mapValidationErrors'
import dataMapper from 'utils/dataMapper'
import {timestampToDate} from '../base/parseDate'

export function activate({panelId, planId, countryId}, batchId) {
    return post(`/unit/${panelId}/billing/activate`, {
        plan_id: planId,
        coy_id: countryId,
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch((e) => mapValidationErrors(e))
}

export function deactivate({panelId}, batchId) {
    return post(`/unit/${panelId}/billing/deactivate`, {
        batch_id: batchId,
    })
        .then((process) => mapProcess(process))
        .catch((e) => mapValidationErrors(e))
}

export function status(panelId) {
    return get(`/unit/${panelId}/billing/status`)
        .then((status) => mapBillingStatus(status))
        .catch((e) => mapValidationErrors(e))
}

const mapBillingPlan = dataMapper((data) => ({
    id: data.plan_id,
    name: data.name,
    description: data.description,
    price: data.price,
}))

const mapBillingStatus = dataMapper((data) => ({
    currentPlanId: data.current_plan_id,
    requestedPlanId: data.requested_plan_id,
    dealerStatus: data.dealer_status,
    currentPlanStatus: data.current_plan_status,
    planSince: data.plan_since ? timestampToDate(data.plan_since) : null,
    planTill: data.plan_till ? timestampToDate(data.plan_till) : null,
    availablePlans: (data.available_plans || []).map(mapBillingPlan),
    countryCode: data.country_code,
}))
