import {createSelector} from 'reselect'

export const selectFirmwarePatchTagUpdateListRows = createSelector(
    (state) => state.firmware.patchTagUpdate.page,
    (state) => state.panels.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectFirmwarePatchTagUpdateListTotal = createSelector(
    (state) => state.firmware.patchTagUpdate.total,
    (total) => ({total})
)

export const selectFirmwarePatchTagUpdateList = createSelector(
    (state) => state.firmware.patchTagUpdate,
    (list) => list
)
