import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

import __ from 'utils/i18n'
import {HAD_WATER_VALVE_OPENED, HAD_WATER_VALVE_CLOSED} from 'constants/had/hadState'
import waterValveTypeStateTitle from 'constants/had/waterValve'
import {resolveWaterValveCommands} from 'constants/had/command'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {withPermissionRejection} from 'containers/withPermission'
import {waterValveControl} from 'permissions/panel/output/actions'
import Empty from 'ui/Empty'
import useForm from 'hooks/useForm'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'

const WaterValveControlFormContent = ({had, state, handleChange, isDisabled}) => {
    if ((had?.supportedCommands || []).length === 0) {
        return null
    }

    const isOpen = HAD_WATER_VALVE_OPENED === state

    return (
        <ToggleBox
            value={had?.state?.water_valve?.mode}
            onChange={(e) => {
                handleChange(e.target.checked)
            }}
            checked={isOpen}
            defaultChecked={isOpen}
            name="state"
            label={
                <>
                    {__('Device control')}{' '}
                    <Empty>({waterValveTypeStateTitle(state)})</Empty>
                </>
            }
            disabled={isDisabled}
        />
    )
}

const WaterValveControlFormBase = ({errors, isLoading, handle, had, panelId}) => {
    const [state, setState] = useState(had?.state?.water_valve?.mode)

    const onSubmitHandler = () => {
        handle &&
            handle(
                new HadControlForm({
                    commands: resolveWaterValveCommands({
                        mode: Number(state),
                        supportedCommands: had?.supportedCommands,
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }

    const handleChange = (checked) => {
        setState(checked ? HAD_WATER_VALVE_OPENED : HAD_WATER_VALVE_CLOSED)
    }

    return (
        <Form
            className="pgmControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
        >
            <WaterValveControlFormContent
                had={had}
                state={state}
                handleChange={handleChange}
            />
        </Form>
    )
}

const WaterValveControlFormRejection = withPermissionRejection(waterValveControl)(
    WaterValveControlFormBase
)

export default function WaterValveControlForm({had, panelId}) {
    const {handle, isLoading, form} = useForm(submitHadDeviceControlForm)

    return (
        <WaterValveControlFormRejection
            {...form}
            had={had}
            panelId={panelId}
            handle={handle}
            isLoading={isLoading}
        />
    )
}

WaterValveControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
    panelId: PropTypes.number,
}
