import React from 'react'
import PropTypes from 'prop-types'

import DropDown from 'ipmp-react-ui/DropDownButton'
import {ReactComponent as MoreIcon} from 'ipmp-react-ui/icons/more-vert.svg'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'

export default function RowActions({children}) {
    return (
        <DropDown Icon={MoreIcon} borderless hideArrowDown>
            <Menu>{children}</Menu>
        </DropDown>
    )
}

RowActions.propTypes = {
    children: PropTypes.arrayOf(PropTypes.instanceOf(RowAction)),
}

export function RowAction({Icon, onClick, children, disabled}) {
    return (
        <MenuItem Icon={Icon} disabled={disabled} onClick={onClick}>
            {children}
        </MenuItem>
    )
}

RowActions.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    Icon: PropTypes.func,
}
