import React from 'react'
import PropTypes from 'prop-types'

import {__n} from 'utils/i18n'
import useForm from 'hooks/useForm'
import MaintainForm from './MaintainForm'
import {reassignService} from 'modules/forms/handlers'

export default function ReassignService({panelIds, hide}) {
    const {handle, isLoading, form} = useForm(reassignService)

    return (
        <MaintainForm
            handle={handle}
            panelIds={panelIds}
            hide={hide}
            isLoading={isLoading}
            {...form}
            header={__n(
                'Reassign %s panel for service',
                'Reassign %s panels for service',
                panelIds.length
            )}
        />
    )
}

ReassignService.propTypes = {
    panelIds: PropTypes.arrayOf(PropTypes.number).isRequired,
}
