import React, {Fragment, PureComponent} from 'react'
import PropTypes from 'prop-types'

import Tooltip from 'ipmp-react-ui/Tooltip'

import __ from 'utils/i18n'

export default class FeatureNotSupported extends PureComponent {
    static propTypes = {
        message: PropTypes.string.isRequired,
    }

    render() {
        const {message} = this.props

        return (
            <Fragment>
                <div className="featureNotSupported">
                    <Tooltip tooltip={message || __('Feature not supported')}>
                        <span className="empty">—</span>
                    </Tooltip>
                </div>
            </Fragment>
        )
    }
}
