import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconGetLog} from 'ipmp-react-ui/icons/system-log-download.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useTrackProcess from 'hooks/useTrackProcess'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import {withVisibility} from 'containers/withVisibility'
import {refresh as refreshPermission} from 'permissions/panel/log/bar'
import {refresh, selectRefreshProcessByPanelId} from 'modules/panels/systemLogs'

const ButtonVisibility = withVisibility()(Button)

export default function NewSystemLogButton() {
    const panelId = useRouterPanelId()
    const refreshAction = useActions(refresh, [panelId])
    const {isPermitted} = usePermission(refreshPermission)
    const process = useSelector((state) => selectRefreshProcessByPanelId(state, panelId))

    useTrackProcess({process})

    const onClick = () => refreshAction(panelId)

    return (
        <ButtonVisibility
            flat
            Icon={IconGetLog}
            onClick={onClick}
            process={process}
            panelId={panelId}
            disabled={!!process}
            isVisible={isPermitted}
            label={__('New System Log')}
            className="btn--primary smartCommunicator-new-log"
        />
    )
}
