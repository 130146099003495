import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

export default function SupervisionSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.Supervision)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            onSubmit={handle}
            confirmOnDismiss
            isLoading={isLoading}
            header={__('Edit Supervision Settings')}
        >
            <Checkbox
                label={__('Send Notification Email on Online/Offline Event')}
                name="doSendEmailOnOnlineOffline"
                defaultChecked={data.doSendEmailOnOnlineOffline}
            />

            <Checkbox
                label={__('Send Notification SMS on Online/Offline Event')}
                name="doSendSmsOnOnlineOffline"
                defaultChecked={data.doSendSmsOnOnlineOffline}
            />

            <Checkbox
                label={__(
                    'Generate `SYSTEM OFFLINE/ONLINE` Events for One-Channel Panels'
                )}
                name="doSendOneChannelPanelOnlineOffline"
                defaultChecked={data.doSendOneChannelPanelOnlineOffline}
            />

            <Checkbox
                label={__(
                    'Generate `SYSTEM OFFLINE/ONLINE` Events for Two-Channel Panels'
                )}
                name="doSendTwoChannelPanelOnlineOffline"
                defaultChecked={data.doSendTwoChannelPanelOnlineOffline}
            />
        </ModalCardForm>
    )
}

SupervisionSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        doSendEmailOnOnlineOffline: PropTypes.bool,
        doSendSmsOnOnlineOffline: PropTypes.bool,
        doSendOneChannelPanelOnlineOffline: PropTypes.bool,
        doSendTwoChannelPanelOnlineOffline: PropTypes.bool,
    }),
}
