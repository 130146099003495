import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {__} from 'utils/i18n'

const getProcessStatusText = (batchStats, runningStartClearProcess) => {
    switch (true) {
        case runningStartClearProcess?.isSuccessful ?? batchStats?.succeeded > 0:
            return __('Clear successful')
        case runningStartClearProcess?.isFailed ?? batchStats?.failed > 0:
        case runningStartClearProcess?.isFailedToStart ?? batchStats?.failed_to_start > 0:
            return __('Clear failed')
        case runningStartClearProcess?.isStarted ?? batchStats?.started > 0:
        case runningStartClearProcess?.isHandled ?? batchStats?.handled > 0:
            return __('Clear in progress')
        default:
            return __('Clear not started')
    }
}

const ClearDeviceCardStatus = ({batch, runningStartClearProcess}) => {
    return (
        <div
            className={classnames('device-card-clear-status', {
                success:
                    runningStartClearProcess?.isSuccessful ?? batch?.stats?.succeeded > 0,
                failed: runningStartClearProcess?.isFailed ?? batch?.stats?.failed > 0,
                running:
                    runningStartClearProcess?.isRunning ||
                    batch?.stats?.started > 0 ||
                    batch?.stats?.handled > 0,
            })}
        >
            <span className="device-card-bottom-separator">|</span>
            {getProcessStatusText(batch?.stats, runningStartClearProcess)}
        </div>
    )
}

ClearDeviceCardStatus.propTypes = {
    batch: PropTypes.object,
    runningStartClearProcess: PropTypes.object,
}

export default ClearDeviceCardStatus
