import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import __ from 'utils/i18n'
import {showAssignUserToPanelModal} from 'modules/modals/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'

export default function AssignUserButton() {
    const panelId = useRouterPanelId()
    const panelsByIds = useSelector(({panelInteractiveUsers: {store}}) => store)
    const {showAssignUserToPanelModal: showAssignUserToPanel} = useActions({
        showAssignUserToPanelModal,
    })
    const onClick = () =>
        showAssignUserToPanel({
            panelId,
            hasPanelUser: Object.values(panelsByIds.byIds[panelId]).some(
                ({isAssign, isPinSet}) => !isAssign && isPinSet
            ),
        })

    return (
        <Button
            flat
            primary
            Icon={IconPlus}
            className="assignUserButton"
            label={__('Assign Customer')}
            onClick={onClick}
        />
    )
}
