import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

export default function AdvertisementSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.Advertisement)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Edit Advertisement Settings')}
        >
            <Input label={__('URL')} name="url" defaultValue={data.url} />
        </ModalCardForm>
    )
}

AdvertisementSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        url: PropTypes.string,
    }),
}
