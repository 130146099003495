import {createAction} from 'redux-actions'

export const check = createAction('AUTH/SIGN/CHECK')
export const checked = createAction('AUTH/SIGN/CHECKED')
export const setLoading = createAction(
    'AUTH/SIGN/SET_LOADING',
    (isLoading = true) => isLoading
)
export const login = createAction('AUTH/SIGN/LOGIN', (email, password) => ({
    email,
    password,
}))

export const authEmailPassword = createAction(
    'AUTH/SIGN/AUTH_EMAIL_PASSWORD',
    (email, password) => ({
        email,
        password,
    })
)

export const authEmailPasswordComplete = createAction(
    'AUTH/SIGN/AUTH_EMAIL_PASSWORD_COMPLETE'
)

export const clearErrors = createAction('AUTH/SIGN/CLEAR_ERRORS')

export const sendCode = createAction('AUTH/SIGN/SEND_CODE')

export const confirmCode = createAction('AUTH/SIGN/CONFIRM_CODE', (code) => ({code}))

export const resendCode = createAction('AUTH/SIGN/RESEND_CODE')

export const setPhone = createAction('AUTH/SIGN/SET_PHONE', (phone) => ({phone}))
export const setEmail = createAction('AUTH/SIGN/SET_EMAIL', (email) => ({email}))

export const loggedIn = createAction('AUTH/SIGN/LOGGED_IN')
export const receive = createAction('AUTH/SIGN/RECEIVE', (user) => user)
export const update = createAction('AUTH/SIGN/UPDATE', (user) => user)
export const logout = createAction('AUTH/SIGN/LOGOUT')
export const loggedOut = createAction('AUTH/SIGN/LOGGED_OUT')
export const setLoginScreenForm = createAction('AUTH/SIGN/SET_LOGIN_SCREEN_FORM')
export const autologoutContinue = createAction('AUTH/SIGN/AUTOLOGOUT_CONTINUE')
