import React from 'react'
import PropTypes from 'prop-types'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {
    HAD_CONFIGURABLE_ICON_ARM_MODE,
    HAD_CONFIGURABLE_ICON_ARM_TOGGLE,
    HAD_CONFIGURABLE_ICON_DISARM,
    hadConfigurableIcon,
    hadConfigurableIconTitle,
    hadIcon,
    isHadIconConfigurable,
} from 'constants/had'
import classes from 'classnames'
import {HAD_STATE_OFF, HAD_STATE_ON, HAD_STATE_STATELESS} from 'constants/had/hadState'

import HadConfigurationTabTableRow from './HadConfigurationTabTableRow'
import SetHadLabelButton from './SetHadLabelButton'
import SetHadIconButton from './SetHadIconButton'

const HadConfigurationTabTable = ({had}) => {
    const panelId = useRouterPanelId()
    const isIconHadConfigurable = isHadIconConfigurable(had?.deviceType)

    const getConfigurableIcon = () => {
        if (had?.userType || isIconHadConfigurable) {
            return hadConfigurableIcon(had.userType, had.state)
        }
        if (!had?.userType && isIconHadConfigurable) {
            const backgroundImage = `url(${hadIcon(had.subtype, had.deviceType)})`
            return <div className="device-image-src" style={{backgroundImage}} />
        }
    }

    const iconValue = isIconHadConfigurable && (
        <>
            <div
                className={classes('hadConfigurationTabTable-iconValue', {
                    'deviceHad-ArmMode-type':
                        had.userType === HAD_CONFIGURABLE_ICON_ARM_MODE,
                    'deviceHad-ArmToggle-type':
                        had.userType === HAD_CONFIGURABLE_ICON_ARM_TOGGLE,
                    'deviceHad-Disarm-type':
                        had.userType === HAD_CONFIGURABLE_ICON_DISARM,
                    'deviceHad-enabled': had.state?.pgm === HAD_STATE_ON,
                    'deviceHad-disabled': had.state?.pgm === HAD_STATE_OFF,
                    'deviceHad-stateless': had.state?.pgm === HAD_STATE_STATELESS,
                    'deviceHad-simple-pgm': isHadIconConfigurable(had?.deviceType),
                })}
            >
                {getConfigurableIcon()}
            </div>
            {hadConfigurableIconTitle(had.userType)}
        </>
    )

    return (
        <div className="hadConfigurationTabTable table">
            {isHadIconConfigurable(had?.deviceType) && (
                <HadConfigurationTabTableRow
                    value={iconValue}
                    title={__('Icon')}
                    EditButton={
                        <SetHadIconButton
                            panelId={panelId}
                            hadId={had?.id}
                            deviceType={had?.deviceType}
                            defaultValue={had?.userType}
                        />
                    }
                />
            )}
            <HadConfigurationTabTableRow
                value={had?.name}
                title={__('Label')}
                missingTitle={__('No Label')}
                EditButton={
                    <SetHadLabelButton
                        panelId={panelId}
                        hadId={had?.id}
                        deviceType={had?.deviceType}
                        defaultValue={had?.name}
                        disabled={!had?.isLabelEditable}
                    />
                }
            />
        </div>
    )
}

HadConfigurationTabTable.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        deviceType: PropTypes.string,
        userType: PropTypes.string,
    }).isRequired,
}

export default HadConfigurationTabTable
