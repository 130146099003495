import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/groups/list'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import useGroups from 'hooks/pages/useGroups'

const PagerVisibility = withVisibility()(Pager)

export default function GroupsPager() {
    const {isPermitted} = usePermission(list)
    const {total, start, perPage, onPageChange, onPerPageChange} = useGroups()

    return (
        <PagerVisibility
            isVisible={isPermitted}
            total={total}
            start={start}
            perPage={perPage}
            onPerPageChange={onPerPageChange}
            onPageChange={onPageChange}
        />
    )
}
