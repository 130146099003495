import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import {useActions} from 'modules/higherOrder/useActions'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectEventRulesList} from 'modules/eventRules/list/selectors'
import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/eventRules/list/actions'

export default function EventRulesSearch({className}) {
    const {filters: selected, query, suggests} = useSelector(selectEventRulesList)

    const filters = [
        new SuggestFilter('zoneTypeId', __('Zone Type')),
        new SuggestFilter('notificationId', __('Notification Type')),
        new SuggestFilter('stateId', __('State Type')),
        new SuggestFilter('eventId', __('Event Type')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    return (
        <Search
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            className={className}
            placeholder={__('Event rules search')}
        />
    )
}
