import React from 'react'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'

import InfoCards from 'ipmp-react-ui/InfoCards'

import page, {ownerInfo, panelInfo} from 'permissions/panel/info/page'
import {withPermissionRejection} from 'containers/withPermission'

import PanelInfo from './PanelInfo'
import CustomerInfo from './CustomerInfo'
import {selectPanelInfo} from 'modules/panels/one/selectors'
import usePermission from 'hooks/usePermission'

const Info = () => {
    const {isAllowPanelInfo, isAllowCustomerInfo} = usePermission({
        isAllowPanelInfo: panelInfo,
        isAllowCustomerInfo: ownerInfo,
    })

    const params = useParams()
    const {id} = params
    const {fotaAotaVersions, panel} = useSelector((state) => selectPanelInfo(state, id))
    const isLoading = useSelector((state) => state.panels.one.isLoading)

    return (
        <InfoCards cols={1}>
            {isAllowCustomerInfo && <CustomerInfo isLoading={isLoading} panel={panel} />}
            {isAllowPanelInfo && (
                <PanelInfo
                    isLoading={isLoading || !panel.firmware}
                    fotaAotaVersions={fotaAotaVersions}
                    panel={panel}
                />
            )}
        </InfoCards>
    )
}

export default withPermissionRejection(page)(Info)
