import React, {useEffect} from 'react'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'
import {humanTime} from 'ipmp-react-ui/humanTime'

import {convertConfigValue} from 'utils/gettext'

import {
    getClass,
    CURRENT_STATUS_FIELD_NAME,
    LAST_STARTED_TIME_FIELD_NAME,
} from 'constants/panelTests'

const AdditionalInfo = ({testType, testsData, testDeviceId}) => {
    let currentTest
    if (testDeviceId) {
        currentTest = testsData.filter(
            (test) =>
                testDeviceId === test?.testDeviceId &&
                test?.testType === testType &&
                test?.additionalInfo
        )
    } else {
        currentTest = testsData.filter(
            (test) => test?.testType === testType && test.additionalInfo
        )
    }

    const updatedTest = currentTest.map((test) => {
        return {
            ...test,
            additionalInfo: {
                ...test.additionalInfo,
                [CURRENT_STATUS_FIELD_NAME]: test.currentStatus,
                [LAST_STARTED_TIME_FIELD_NAME]: humanTime(
                    new Date(test.timestamp * 1000)
                ),
            },
        }
    })

    useEffect(() => {
        return () => {}
    }, [updatedTest])

    return updatedTest.map((test) =>
        Object.keys(test.additionalInfo).map((key) => {
            if (test.additionalInfo[key]) {
                return (
                    <InfoCardTile key={key}>
                        <InfoCardTileTitle>{key}</InfoCardTileTitle>
                        <InfoCardTileContent
                            className={getClass(test?.currentStatus, key)}
                        >
                            {convertConfigValue(test.additionalInfo[key])}
                        </InfoCardTileContent>
                    </InfoCardTile>
                )
            }
        })
    )
}

export default AdditionalInfo
