import React, {Fragment} from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Spinner from 'ipmp-react-ui/Spinner'
import Definition from 'ipmp-react-ui/Definition'
import {humanDate} from 'ipmp-react-ui/humanTime/humanTime'

import path from 'utils/path'
import IconBack from 'ui/IconBack'
import useRunnerGridBar from 'hooks/pages/useRunnerGridBar'

import RunnersPager from './GridBar/RunnersPager'
import RunnersDropDown from './GridBar/RunnersDropDown'
import ServiceDropDown from './GridBar/DropDowns/ServiceDropDown'
import CreateReportButton from './GridBar/Buttons/CreateReportButton'
import InitiateRemoteInspectionButton from './GridBar/Buttons/InitiateRemoteInspectionButton'
import RunnerStopButton from './GridBar/Buttons/RunnerStopButton'
import UpgradeStatusRetryButton from './GridBar/Buttons/UpgradeStatusRetryButton'

const renderTitle = (batchInfo) => {
    if (!batchInfo) {
        return <Spinner />
    }

    return (
        <Definition
            className="title"
            title={batchInfo.batchType}
            detail={humanDate(batchInfo.started)}
            multiLine
        />
    )
}

export default function RunnersGridBar() {
    const {navigate, batchInfo} = useRunnerGridBar()

    const handleGoBack = () => {
        navigate(path('batches', {id: null}))
    }

    return (
        <Fragment>
            <Bar>
                <Button
                    shortcut="backspace"
                    onClick={handleGoBack}
                    className="btn--goBack"
                >
                    <IconBack />
                </Button>
                {renderTitle(batchInfo)}

                <BarSpace />

                <RunnersPager />
            </Bar>
            <Bar>
                <RunnersDropDown />
                <ServiceDropDown />
                <CreateReportButton />
                <InitiateRemoteInspectionButton />
                <RunnerStopButton />
                <UpgradeStatusRetryButton />
            </Bar>
        </Fragment>
    )
}
