import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import withModalLoader from 'containers/withModalLoader'
import {addCentralStation} from 'modules/forms/handlers'
import withInterruption from 'containers/withInterruption'
import {fetchCanAdd} from 'modules/centralStations/licensing/actions'
import CentralStationFormFields, {rules} from './CentralStationFormFields'
import AddCentralStationLimitExceeded from './AddCentralStationLimitExceeded'
import {selectAddCentralStation} from 'modules/centralStations/store/selectors'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'
import {fetch as fetchSerialPorts} from 'modules/centralStations/serialPorts/actions'

const defaultData = {
    isHeartBeat: true,
    heartBeat: 25,
    retryTime: 5,
    retryCount: 1,
    connectionType: 'tcp',
    ssl: '',
    receiver: 33,
    line: 2,
}

const AddCentralStation = ({hide, serialPorts, protocols}) => {
    const {handle, isLoading, form} = useForm(addCentralStation)

    const onSubmit = (data) => {
        if (!data.isHeartBeat) {
            data.heartBeat = 0
        }

        handle(data)
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            rules={rules}
            header={__('Create Central Station')}
            hide={hide}
            isLoading={isLoading}
            onSubmit={onSubmit}
            {...form}
        >
            <CentralStationFormFields
                isNew
                data={defaultData}
                serialPorts={serialPorts}
                protocols={protocols}
            />
        </ModalCardForm>
    )
}

AddCentralStation.propTypes = {
    hide: PropTypes.func.isRequired,
    handle: PropTypes.any,
    serials: PropTypes.any,
    protocols: PropTypes.any,
    isLoading: PropTypes.bool,
    serialPorts: PropTypes.array,
}

export default compose(
    connect(selectAddCentralStation, (dispatch) =>
        bindActionCreators(
            {
                fetchSerialPorts,
                fetchProtocols,
                fetchCanAdd,
            },
            dispatch
        )
    ),
    withModalLoader(({fetchSerialPorts, fetchProtocols, fetchCanAdd}) => {
        fetchSerialPorts()
        fetchProtocols()
        fetchCanAdd()
    }),
    withInterruption(
        ({creatingAllowed}) => !creatingAllowed,
        AddCentralStationLimitExceeded
    )
)(AddCentralStation)
