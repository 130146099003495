import {PARTITION_ALL} from 'constants/partitions'
import React from 'react'
import PropTypes from 'prop-types'

import {stateTitle, subStateTitle} from 'constants/state'
import Definition from 'ipmp-react-ui/Definition'
import {__} from 'utils/i18n'

export default function PartitionStateDefinition({partition, state}) {
    const renderStateQualifier = () => {
        const {name, ready, substate, stateSet} = state

        if (!stateSet[name] || !stateSet[name].visible) {
            return stateTitle(name)
        }

        if (substate) {
            return subStateTitle(substate)
        }

        if (ready) {
            return __('Ready')
        } else {
            return __('Not ready')
        }
    }

    return (
        <Definition
            className="title title--medium"
            title={partition.id === PARTITION_ALL ? __('All') : partition.name}
            detail={renderStateQualifier()}
        />
    )
}

PartitionStateDefinition.propTypes = {
    partition: PropTypes.shape({
        name: PropTypes.string,
    }).isRequired,

    state: PropTypes.shape({
        name: PropTypes.string,
        substate: PropTypes.string,
        ready: PropTypes.bool,
        stateSet: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.string,
                visible: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,
}
