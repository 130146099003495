import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import Account from './Field/Account'
import Group from './Field/Group'
import Serial from './Field/Serial'
import {SimNumber} from './Field/SimNumber'
import * as Generic from './Generic'

import {__} from 'utils/i18n'

export const Fieldset = ({isNew, data}) => {
    const {setSimNumber} = useSelector(
        ({features}) => features.store.byIds[data?.id] || {}
    )

    return (
        <div className="form-field">
            <Serial isNew={isNew} serial={data.serial} label={__('IMEI')} />
            <Account isNew={isNew} account={data.account} />
            <Group groupName={data.group} groupId={data.groupId} />
            {(isNew || (!isNew && setSimNumber?.isEnabled)) && (
                <SimNumber simNumber={data.simNumber} />
            )}
        </div>
    )
}

Fieldset.propTypes = {
    isNew: PropTypes.bool,
    data: PropTypes.shape({
        id: PropTypes.number,
        serial: PropTypes.string,
        account: PropTypes.string,
        group: PropTypes.string,
        groupId: PropTypes.number,
    }),
}

export const rules = {
    ...Generic.rules,
    groupId: {
        presence: true,
    },
    serial: {
        presence: true,
        format: {
            pattern: '[a-f0-9]{15}',
            flags: 'i',
            message: () => __('Panel ID must be 15 hexadecimal'),
        },
    },
    account: {
        presence: true,
        format: {
            pattern: '([a-f0-9]{2}){2,3}',
            flags: 'i',
            message: () => __('Must be a 4 or 6 digit hexadecimal'),
        },
    },
}
