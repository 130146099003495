import React from 'react'
import {compose} from 'redux'

import {TableSelection} from 'ipmp-react-ui/Table'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'

import useSelection from 'hooks/useSelection'
import list from 'permissions/remoteInspections/list'
import {withPermissionRejection} from 'containers/withPermission'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'
import RemoteInspectionsTable from './Content/RemoteInspectionsTable'
import RemoteInspectionsResultSidebar from './Content/Result/RemoteInspectionsResultSideBar'
import withLoader from 'containers/withLoader'
import {useSearchScope} from 'hooks/useAutoFilters'

function RemoteInspectionContentBase() {
    const {selection} = useSelection()

    return (
        <TableSelection selection={selection} className="rri">
            <Layout>
                <ScrollView className="rri-list">
                    <RemoteInspectionsTable />
                </ScrollView>

                <RemoteInspectionsResultSidebar />
            </Layout>
        </TableSelection>
    )
}

const ConnectedRemoteInspectionContent = compose(
    withPermissionRejection(list),
    withLoader()
)(RemoteInspectionContentBase)

export default function RemoteInspectionContent(props) {
    const remoteInspections = useRemoteInspections()
    useSearchScope(remoteInspections.init)

    const componentProps = {...props, ...remoteInspections}

    return <ConnectedRemoteInspectionContent {...componentProps} />
}
