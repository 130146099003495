import {combineReducers} from 'redux'
import store from './store/reducer'
import list from './list/reducer'
import applianceSelection from './applianceSelection/reducer'
import patchTagUpdate from './patchTagUpdate/reducer'

export default combineReducers({
    applianceSelection,
    list,
    store,
    patchTagUpdate,
})
