import React from 'react'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import Input from 'ipmp-react-ui/Input'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'
import FormFieldWithDisclaimer from 'ui/FormFieldWithDisclaimer'

const rules = {
    token: {
        length: {maximum: 300},
    },
}

export default function GoogleSettings({hide, data: {token}}) {
    const {handle, form, isLoading} = useForm(setSystemSettings.GoogleSettings)

    return (
        <ModalCardForm
            rules={rules}
            hide={hide}
            confirmOnDismiss
            isLoading={isLoading}
            className="googleSettings-form"
            onSubmit={(data) => handle(data)}
            header={__('Google settings')}
            {...form}
        >
            <FormFieldWithDisclaimer
                disclaimer={__(
                    'When Google API key is set you can use location features as panel location displaying'
                )}
            >
                <Input defaultValue={token} label={__('Google API key')} name="token" />
            </FormFieldWithDisclaimer>
        </ModalCardForm>
    )
}
