import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Tabs, {VERTICAL_TABS_ALIGN} from 'ipmp-react-ui/Tabs'
import {ReactComponent as IconCameraRTSP} from 'ipmp-react-ui/icons/camera-rtsp.svg'
import {ReactComponent as IconCameraProvision} from 'ipmp-react-ui/icons/camera-provision-isr.svg'
import Modal from 'ipmp-react-ui/Modal'
import Card from 'ipmp-react-ui/Card'

import {clear as errorClear} from 'modules/forms/actions'

import CameraRTSP from './AddCamera/RTSP'
import ProvisionISR from './AddCamera/ProvisionISR'
import {provisionState, RtspState} from './AddCamera/state'
import useEnrollPermissions from 'hooks/panel/devices/useEnrollPermissions'

const AddCamera = ({hide, panelId}) => {
    const [rtspData, setRtspData] = useState(RtspState)
    const [provisionData, setProvisionData] = useState(provisionState)

    const {isRTSPPermitted, isProvisionPermitted} = useEnrollPermissions()

    const handleClose = () => hide()

    const dispatch = useDispatch()

    const handleChangeTab = () => {
        dispatch(errorClear())
    }
    const panel = useSelector(({panels}) => panels.store.byIds[panelId])

    return (
        <Modal onClose={handleClose}>
            <Card className="add-camera-form">
                <Tabs
                    isTabLabel={false}
                    align={VERTICAL_TABS_ALIGN}
                    onChangeTab={handleChangeTab}
                >
                    {isProvisionPermitted && (
                        <ProvisionISR
                            name={'Provision ISR'}
                            Icon={IconCameraProvision}
                            panelId={panelId}
                            panelSerial={panel?.serial}
                            setProvisionData={setProvisionData}
                            data={provisionData}
                            onClose={handleClose}
                        />
                    )}

                    {isRTSPPermitted && (
                        <CameraRTSP
                            name={'RTSP'}
                            Icon={IconCameraRTSP}
                            panelId={panelId}
                            panelSerial={panel?.serial}
                            setRtspData={setRtspData}
                            data={rtspData}
                            onClose={handleClose}
                        />
                    )}
                </Tabs>
            </Card>
        </Modal>
    )
}

export default AddCamera
