import React from 'react'

import Input from 'ipmp-react-ui/Input'
import Textarea from 'ipmp-react-ui/Textarea'

import {__} from 'utils/i18n'
import {MAIN_INFORMATION} from 'constants/groups'

import {TabContentWrapper} from './TabContentWrapper'

const Main = ({name, description, activeTab, handleFieldChanged, linkedToDealer}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={MAIN_INFORMATION}
            styleName={'group-tab-common'}
        >
            <Input
                readOnly={linkedToDealer}
                autoFocus
                name="name"
                maxLength="24"
                defaultValue={name}
                label={__('Name')}
                onChange={(e) => {
                    handleFieldChanged('name', e.target.value)
                }}
                dataTestId="group-name"
            />
            <Textarea
                name="description"
                defaultValue={description}
                label={__('Description')}
                onChange={(e) => {
                    handleFieldChanged('description', e.target.value)
                }}
                dataTestId="group-description"
            />
        </TabContentWrapper>
    )
}

export default Main
