import React, {useEffect, useMemo} from 'react'
import {useSelector} from 'react-redux'

import isEmpty from 'lodash-es/isEmpty'

import {
    BlindModalCard,
    BlindModalCardActions,
    BlindModalCardContent,
    CardHeaderBlindModal,
    CardHeaderClose,
} from 'ipmp-react-ui/Card'
import Bar from 'ipmp-react-ui/Bar'
import Spinner from 'ipmp-react-ui/Spinner'
import BlindModal from 'ipmp-react-ui/BlindModal'

import Paginator from 'ui/DoubleBlindModalWithPagination/Paginator'

import usePermission from 'hooks/usePermission'

import {fetch, startPoll, stopPoll} from 'modules/panels/billingPlan/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {selectStatus} from 'modules/panels/billingPlan/selectors'
import {deactivate} from 'permissions/panel/billing/actions'

import PanelBillingCurrentPlan from './PanelBillingCurrentPlan'
import PanelBillingAllPlans from './PanelBillingAllPlans'
import PanelBillingDeactivateButton from './PanelBillingDeactivateButton'

import {NO_PLAN_ID} from 'constants/billingPlan'

function BlindContent({panel, billingStatus}) {
    if (
        billingStatus === null ||
        billingStatus.isLoading ||
        !isEmpty(billingStatus.error)
    ) {
        return <Spinner />
    }

    return (
        <>
            {billingStatus.currentPlanId && (
                <>
                    <PanelBillingCurrentPlan
                        billingStatus={billingStatus}
                        panelId={panel.id}
                    />
                    <Bar divider />
                </>
            )}
            <PanelBillingAllPlans
                panelId={panel.id}
                currentPlanId={billingStatus.currentPlanId}
                availablePlans={billingStatus.availablePlans}
                requestedPlanId={billingStatus.requestedPlanId}
            />
        </>
    )
}

export default function PanelBillingBlind({panel, rows, opened, onClose, onChange}) {
    const actions = useActions({fetch, startPoll, stopPoll})
    const {isPermitted: isAllowDeactivate} = usePermission(deactivate)
    const billingStatus = useSelector((state) => selectStatus(state, panel?.id))

    useEffect(() => {
        if (panel?.id) {
            actions.fetch(panel.id)
            actions.startPoll(panel.id)
        }

        return actions.stopPoll
    }, [panel?.id])

    const panelsWithBilling = useMemo(
        () => (rows || []).filter((row) => !!row.billing),
        [rows]
    )

    return (
        <BlindModal opened={opened} onClose={onClose} className="panelBillingBlind">
            <BlindModalCard>
                <CardHeaderBlindModal>
                    {panelsWithBilling.length > 0 && (
                        <Paginator
                            rows={panelsWithBilling}
                            current={{id: panel.id}}
                            onChange={onChange}
                        />
                    )}
                    <span className="panelBillingBlind__serial">{panel?.serial}</span>
                    <CardHeaderClose onClick={onClose} />
                </CardHeaderBlindModal>
                <BlindModalCardContent>
                    <BlindContent panel={panel} billingStatus={billingStatus} />
                </BlindModalCardContent>
                {isAllowDeactivate &&
                    billingStatus?.currentPlanId &&
                    billingStatus?.currentPlanId !== NO_PLAN_ID && (
                        <BlindModalCardActions className="panelBillingBlind__footer">
                            <PanelBillingDeactivateButton panelId={panel?.id} />
                        </BlindModalCardActions>
                    )}
            </BlindModalCard>
        </BlindModal>
    )
}
