import React from 'react'

import Page from 'ipmp-react-ui/Page'

import InstallersBar from './InstallersBar'
import InstallersTable from './InstallersTable'
import InstallersBlind from './InstallersBlind'
import TableWithBlind from 'components/Table/TableWithBlind'

function InstallersContent() {
    return (
        <TableWithBlind>
            {(props) => <InstallersBlind {...props} />}
            {(props) => <InstallersTable {...props} />}
        </TableWithBlind>
    )
}

export default function InstallersPage() {
    return <Page Bar={InstallersBar} Content={InstallersContent} />
}
