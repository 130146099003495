import React from 'react'
import PropTypes from 'prop-types'

import Select, {Option} from 'ipmp-react-ui/Select'

import {getTransportProtocolTitle} from 'constants/protocols'
import __ from 'utils/i18n'

export default function SelectProtocol({
    defaultValue,
    protocols,
    onChange,
    isDisabled = false,
}) {
    return (
        <Select
            label={__('Protocol')}
            name="protocol"
            onChange={(e, value) => onChange(value)}
            defaultValue={defaultValue}
            disabled={isDisabled}
        >
            {protocols.map((protocol) => (
                <Option
                    label={getTransportProtocolTitle(protocol)}
                    value={protocol}
                    key={protocol}
                />
            ))}
        </Select>
    )
}

SelectProtocol.propTypes = {
    defaultValue: PropTypes.number,
    protocols: PropTypes.arrayOf(PropTypes.number).isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
}
