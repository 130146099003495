export const MAIN_GROUP_ID = 1

export const THEME_LIGHT = 'light'
export const THEME_DARK = 'dark'

export const UPGRADE_METHOD_GPRS = 'gprs'
export const UPGRADE_METHOD_BBA = 'bba'

export const SERVER_MESSAGING_LANGUAGE_ID = 'serverMessagingLanguageId'
export const VOD_HISTORY_SIZE = 'vodHistorySize'

export const MAIN_INFORMATION = 'Main information'
export const POWER_MAX = 'PowerMax'
export const QUAZAR = 'Quazar 5.3'
export const QUAZAR_5_3 = 'Quazar 5.3 and higher'
export const VIRTUAL_PANEL = 'Virtual Panels'
export const SMART_COMMUNICATOR = 'Smart Communicator'
export const IQ4 = 'IQ4'
export const GENERAL = 'General'

const NAME = 'name'
const DESCRIPTION = 'description'

const PMASTER_GPRS_SUPERVISION = 'pmasterGprsSupervision'
const PMASTER_GPRS_TIMEOUT = 'pmasterGprsTimeout'
const PMASTER_BBA_SUPERVISION = 'pmasterBbaSupervision'
const PMASTER_BBA_TIMEOUT = 'pmasterBbaTimeout'

const QUAZAR_GPRS_SUPERVISION = 'quazarGprsSupervision'
const QUAZAR_GPRS_TIMEOUT = 'quazarGprsTimeout'
const QUAZAR_BBA_TIMEOUT = 'quazarBbaTimeout'

const QUAZAR_53_BBA_SUPERVISION = 'quazar53BbaSupervision'
const QUAZAR_53_BBA_TIMEOUT = 'quazar53BbaTimeout'
const QUAZAR_53_GPRS_SUPERVISION = 'quazar53GprsSupervision'
const QUAZAR_53_GPRS_TIMEOUT = 'quazar53GprsTimeout'

const SMART_COMMUNICATOR_SUPERVISION = 'smartCommAnySupervision'
const SMART_COMMUNICATOR_TIMEOUT = 'smartCommAnyTimeout'

const IQ4_GPRS_SUPERVISION = 'iq4GprsSupervision'
const IQ4_GPRS_TIMEOUT = 'iq4GprsTimeout'
const IQ4_BBA_SUPERVISION = 'iq4BbaSupervision'
const IQ4_BBA_TIMEOUT = 'iq4BbaTimeout'

const IQ4_FIBRO_GPRS_SUPERVISION = 'iq4FibroGprsSupervision'
const IQ4_FIBRO_GPRS_TIMEOUT = 'iq4FibroGprsTimeout'
const IQ4_FIBRO_BBA_SUPERVISION = 'iq4FibroBbaSupervision'
const IQ4_FIBRO_BBA_TIMEOUT = 'iq4FibroBbaTimeout'

export const tabsErrors = {
    [MAIN_INFORMATION]: [NAME, DESCRIPTION],
    [POWER_MAX]: [
        PMASTER_GPRS_SUPERVISION,
        PMASTER_GPRS_TIMEOUT,
        PMASTER_BBA_TIMEOUT,
        PMASTER_BBA_SUPERVISION,
    ],
    [QUAZAR]: [QUAZAR_GPRS_SUPERVISION, QUAZAR_GPRS_TIMEOUT, QUAZAR_BBA_TIMEOUT],
    [QUAZAR_5_3]: [
        QUAZAR_53_BBA_SUPERVISION,
        QUAZAR_53_BBA_TIMEOUT,
        QUAZAR_53_GPRS_SUPERVISION,
        QUAZAR_53_GPRS_TIMEOUT,
    ],
    [SMART_COMMUNICATOR]: [SMART_COMMUNICATOR_SUPERVISION, SMART_COMMUNICATOR_TIMEOUT],
    [IQ4]: [
        IQ4_GPRS_SUPERVISION,
        IQ4_GPRS_TIMEOUT,
        IQ4_BBA_TIMEOUT,
        IQ4_BBA_SUPERVISION,
        IQ4_FIBRO_GPRS_SUPERVISION,
        IQ4_FIBRO_GPRS_TIMEOUT,
        IQ4_FIBRO_BBA_SUPERVISION,
        IQ4_FIBRO_BBA_TIMEOUT,
    ],
    [GENERAL]: [SERVER_MESSAGING_LANGUAGE_ID, VOD_HISTORY_SIZE],
}
