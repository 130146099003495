import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import list from 'permissions/roles/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {selectRolesList} from 'modules/roles/list/selectors'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {
    fetch,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/roles/list/actions'

const SearchVisibility = withVisibility()(Search)

export default function RolesSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectRolesList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('name', __('Name')),
        new SuggestFilter('parentName', __('Parent Role')),
        new SuggestFilter('userName', __('Creator')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Roles')}
        />
    )
}
