import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import {useLocation} from 'react-router'

import {NavTabPopup} from 'ipmp-react-ui/NavTabs'
import Menu, {MenuLink} from 'ipmp-react-ui/Menu'

import __ from 'utils/i18n'
import path, {getPathKey} from 'utils/path'
import {VIRTUAL_PANEL_SETTINGS} from './SettingsPageRouting'
import {isVirtualPanelEnabled} from 'constants/virtualPanel'

const VirtualPanelsLinks = ({menu}) => {
    const {license} = useSelector(({system}) => system.settings)
    const isVPAllowed = isVirtualPanelEnabled(license)
    const location = useLocation()
    const subTab = location.pathname

    const virtualPanelLinks = Object.keys(menu).filter(
        (value) => menu[value].parent === VIRTUAL_PANEL_SETTINGS
    )

    const currentVPTabName = virtualPanelLinks
        .map((link) => path('settings.' + link))
        .includes(subTab)
        ? menu[getPathKey(subTab, false)].label()
        : __('Virtual panel')

    if (isVPAllowed && virtualPanelLinks.length > 0) {
        return (
            <NavTabPopup label={currentVPTabName}>
                <Menu>
                    {virtualPanelLinks.map((item) => {
                        const {label, to} = menu[item]

                        return (
                            <MenuLink key={item} to={to}>
                                {label()}
                            </MenuLink>
                        )
                    })}
                </Menu>
            </NavTabPopup>
        )
    }
    return null
}

VirtualPanelsLinks.propTypes = {
    menu: PropTypes.object,
}

export default React.memo(VirtualPanelsLinks)
