import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'

import {update, fetch, setLoading} from './actions'

const defaultState = {
    byIds: {},
    isLoading: false,
}

export default handleActions(
    {
        [update](state, {payload: {panelId, data}}) {
            return set(state, ['byIds', panelId], data)
        },
        [fetch](state) {
            return set(state, 'isLoading', true)
        },
        [setLoading](state, {payload}) {
            return set(state, 'isLoading', payload)
        },
    },
    defaultState
)
