import React from 'react'
import {useSelector} from 'react-redux'

import {
    applyFilter,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/panelInteractiveUsers/list/actions'
import {
    CREATED_AT_FILTER,
    IS_ASSIGN_FILTER,
    IS_CODE_SET_FILTER,
    LABEL_FILTER,
    PARTITIONS_FILTER,
    ROLE_FILTER,
    STATUS_FILTER,
} from 'constants/panelUsersSearch'
import __ from 'utils/i18n'
import {TRUE, FALSE} from 'constants/boolean'
import Search from 'components/Search/Search'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectPanelInteractiveUsersList} from 'modules/panelInteractiveUsers/list/selectors'
import usePermission from 'hooks/usePermission'
import interactiveUsersList from 'permissions/interactiveUsers/list'

const getFilters = (isAllowedInteractiveUsers) => {
    let filters = [
        new SuggestFilter(ROLE_FILTER, __('Role')),
        new SuggestFilter(LABEL_FILTER, __('Label')),
        new ValuesFilter(IS_CODE_SET_FILTER, __('Code'), {
            [TRUE]: __('Set'),
            [FALSE]: __('Not set'),
        }),
        new SuggestFilter(PARTITIONS_FILTER, __('Partitions')),
    ]

    if (isAllowedInteractiveUsers) {
        filters = [
            ...filters,
            new ValuesFilter(IS_ASSIGN_FILTER, __('Customer email'), {
                [TRUE]: __('Assigned'),
                [FALSE]: __('Not assigned'),
            }),
            new DateFilter(CREATED_AT_FILTER, __('Customer registered')),
            new SuggestFilter(STATUS_FILTER, __('Customer state')),
        ]
    }

    return filters.sort((item, next) => item.title.localeCompare(next.title))
}

export default function PanelInteractiveUsersSearch({className}) {
    const {isAllowedInteractiveUsers} = usePermission({
        isAllowedInteractiveUsers: interactiveUsersList,
    })

    const filters = getFilters(isAllowedInteractiveUsers)

    const {
        filters: selected,
        query,
        suggests,
    } = useSelector(selectPanelInteractiveUsersList)
    const {apply, onSelect, onQuery, onDeselect, onClear, onSuggest} = useActions({
        apply: applyFilter,
        onSelect: addFilters,
        onQuery: setQuery,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onSuggest: fetchSuggests,
    })

    return (
        <Search
            apply={apply}
            query={query}
            filters={filters}
            selected={selected}
            suggests={suggests}
            onClear={onClear}
            onQuery={onQuery}
            onSelect={onSelect}
            className={className}
            onSuggest={onSuggest}
            onDeselect={onDeselect}
            placeholder={__('Search Customers')}
        />
    )
}
