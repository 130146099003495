import createPermission, {any} from 'permissions/createPermission'

export const genericToggle = createPermission('unit.automation.generic.toggle')
export const genericEnable = createPermission('unit.automation.generic.enable')
export const genericDisable = createPermission('unit.automation.generic.disable')
export const genericEdit = createPermission('unit.automation.edit')

export const dimmerLevel = createPermission('unit.automation.dimmer.level')
export const doorlockControl = createPermission('unit.automation.doorlock.control')
export const thermostatMode = createPermission('unit.automation.thermostat.mode')
export const thermostatFanMode = createPermission('unit.automation.thermostat.fanMode')
export const thermostatTarget = createPermission('unit.automation.thermostat.target')
export const waterValveControl = createPermission('unit.automation.water-valve.control')

export const pgmControl = createPermission(
    any(
        genericEnable,
        genericDisable,
        genericToggle,
        dimmerLevel,
        doorlockControl,
        thermostatMode,
        thermostatTarget,
        thermostatFanMode
    )
)
export const thermostatControl = createPermission(
    any(thermostatMode, thermostatTarget, thermostatFanMode)
)
