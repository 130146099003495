import React from 'react'
import isNil from 'lodash-es/isNil'
import {useSelector} from 'react-redux'

import useForm from 'hooks/useForm'
import usePermission from 'hooks/usePermission'
import NoPanelUsersError from '../NoPanelUsersError'
import {assignUserToPanel} from 'modules/forms/handlers'
import {register} from 'permissions/interactiveUsers/actions'
import {AssignUserToPanelForm} from './AssignUserToPanelForm'

export default function AssignUserToPanel({
    hide,
    panelId,
    hasPanelUser,
    interactiveUserId,
    panelUserId,
}) {
    const {isPermitted} = usePermission(register)
    const {form, handle, isLoading} = useForm(assignUserToPanel)

    const {interactiveUser, panel} = useSelector(
        ({
            interactiveUsers: {
                store: {byIds},
            },
            panels,
            interactiveUserPanels: {
                accessiblePanels: {
                    store: {byIds: accessiblePanels},
                },
            },
            panelInteractiveUsers: {store},
        }) => ({
            interactiveUser: byIds[interactiveUserId],
            panel: panels.store.byIds[panelId] || accessiblePanels[panelId],
        })
    )

    if (isNil(hasPanelUser) ? false : !hasPanelUser) {
        return <NoPanelUsersError />
    }

    return (
        <AssignUserToPanelForm
            {...form}
            hide={hide}
            panel={panel}
            panelId={panelId}
            handle={handle}
            isLoading={isLoading}
            panelUserId={panelUserId}
            isRegisterNew={isPermitted}
            interactiveUser={interactiveUser}
        />
    )
}
