import {POLL_ACTION_LOG} from 'configs/pollers'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {selectPerPage} from 'modules/settings/selectors'
import {all, select, put, call} from 'redux-saga/effects'
import toIds from 'utils/toIds'

import * as actions from './actions'
import * as api from 'api/actionLog'

import createListSaga from 'modules/higherOrder/createListSaga'
import {update, purge} from 'modules/actionLog/store/actions'

const selector = (state) => ({
    ...state.actionLog.list,
    perPage: selectPerPage(state, 'actionLog'),
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_ACTION_LOG, watchPoll),
    ])
}

function* watchPoll() {
    const state = yield select(selector)

    if (state.start !== 0) {
        return
    }

    const {rows, count} = yield call(api.fetch, state)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}
