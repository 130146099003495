import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'

const ButtonWithConfirmation = withConfirmation()(Button)

export default function BrokersDeleteButton({isUsedByMfa, onClick}) {
    return (
        <ButtonWithConfirmation
            flat
            small
            Icon={IconMinus}
            onClick={onClick}
            title={__('Remove broker')}
            message={__('Do you want to remove broker?')}
            isConfirmationEnabled={!isUsedByMfa}
        />
    )
}
