import React from 'react'

import Disclaimer from 'ipmp-react-ui/Disclaimer'
import InfoCards, {InfoCard} from 'ipmp-react-ui/InfoCards'

import {DEFAULT_ACTIVE_SYSTEM_TEST_GROUP, getTestDisclaimer} from 'constants/panelTests'
import RunPanelTestButton from './Buttons/RunPanelTestButton'
import AdditionalInfo from './AdditionalInfo'

const TestDisclaimer = ({testType}) => {
    const text = getTestDisclaimer(testType)

    if (!text) {
        return null
    }

    return <Disclaimer>{text}</Disclaimer>
}

const PanelTestGroup = ({
    activeGroup,
    panelId,
    supportedTests,
    testsData,
    serial,
    startedGroup,
}) => {
    let activeGroupTests = supportedTests.filter((test) => test.groupName === activeGroup)

    if (activeGroup === DEFAULT_ACTIVE_SYSTEM_TEST_GROUP) {
        activeGroupTests = supportedTests.filter((test) => test.groupName === null)
    }

    return (
        <InfoCards cols={1} className="panel-system-tests-info-cards">
            {activeGroupTests.map((matchedTest) => {
                return (
                    <InfoCard
                        className="panel-system-grouped-test-info-card"
                        cols={2}
                        title={matchedTest.testName}
                        headerButton={
                            <RunPanelTestButton
                                panelId={panelId}
                                test={matchedTest}
                                panelSerial={serial}
                                startedGroup={startedGroup}
                            />
                        }
                        key={matchedTest.testType + matchedTest?.testDeviceId}
                    >
                        <AdditionalInfo
                            testsData={testsData}
                            testType={matchedTest?.testType}
                            testDeviceId={matchedTest?.testDeviceId}
                        />
                        <TestDisclaimer testType={matchedTest.testType} />
                    </InfoCard>
                )
            })}
        </InfoCards>
    )
}

export default PanelTestGroup
