import React from 'react'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import AddStateTypeButton from './GridBar/AddStateTypeButton'

export const StateTypesGridBar = () => (
    <Bar orientation={RIGHT}>
        <AddStateTypeButton />
    </Bar>
)

export default StateTypesGridBar
