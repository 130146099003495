import {ROLE_USER} from './accountRoles'

export const PANEL_MASTER_USER_ID = 0

export const getEntityNumber = (userId) => {
    return parseInt(userId) + 1
}

const userFields = {
    label: null,
    email: null,
    isPinSet: false,
    partitions: '',
    createdAt: null,
    isAssign: false,
    status: null,
    interactiveUserId: null,
}

const groupById = (users) => {
    return users.reduce(
        (users, user) => ({
            ...users,
            [user.id]: {
                ...userFields,
                ...user,
                index: user.id,
                partitions: user.partitions?.length ? user.partitions.map(String) : '',
            },
        }),
        {}
    )
}

const getUsers = (maxUserCount, existsUsers, reservedIds) => {
    const users = groupById(existsUsers)

    for (let i = 0; i < maxUserCount; i++) {
        if (users[i]) {
            continue
        }

        users[i] = {
            id: i,
            role: ROLE_USER,
            ...userFields,
            index: i,
        }
    }

    return Object.values(users).filter((user) => {
        return !(reservedIds.includes(user.id) && !user.isPinSet)
    })
}

export const getPanelUsersList = (existsUsers, count, reservedIds) => {
    const users = getUsers(count, existsUsers, reservedIds)

    return {users, count}
}
