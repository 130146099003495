import React from 'react'

import {useAllProcesses} from 'hooks/pages/useDashboard'
import AllProcessesBase from 'components/Dashboard/AllProcesses'
import {allProcesses} from 'permissions/dashboard/charts'
import {withPermissionRejection} from 'containers/withPermission'

const AllProcessesRejection = withPermissionRejection(allProcesses)(AllProcessesBase)

export default function AllProcesses() {
    const {dataset, labels} = useAllProcesses()

    return <AllProcessesRejection dataset={dataset} labels={labels} />
}
