import {put} from 'redux-saga/effects'

import {EVENT_ADD_SENSOR_CONFIRMATION} from 'constants/autolearn'
import {snackShow} from 'modules/snacks'

import * as actions from '../actions'

export default function* (session, message) {
    if (EVENT_ADD_SENSOR_CONFIRMATION !== message.event) {
        return null
    }

    if ('unsupported' === message?.status) {
        let deviceType = message.verboseMessage?.template || 'Unsupported Device'
        if (Array.isArray(message.verboseMessage?.params)) {
            const [param] = message.verboseMessage?.params || []
            const {value} = param || {}

            deviceType = deviceType.replace(
                ' (%1$s)',
                value !== undefined ? ` (${value})` : ''
            )
        }

        yield put(snackShow(deviceType))

        yield put(
            actions.addDeviceComplete(session, {
                type: deviceType,
                name: message?.enrollmentId,
                status: message?.status,
                state: 'failed',
            })
        )
    }

    if ('request' === message?.status) {
        yield put(
            actions.requestAddSensorConfirmation(session, {
                sensor: message.enrollmentId,
                information: {...message.information},
            })
        )
    }
}
