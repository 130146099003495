import {put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

export default function watch(action) {
    return function* (...args) {
        try {
            yield action(...args)
        } catch (error) {
            if ('message' in error) {
                yield put(snackShow(error.message))
            }

            if (action.onError instanceof Function) {
                yield action.onError(error, ...args)
            }
        }
    }
}
