import {takeEvery, all, put, select, call} from 'redux-saga/effects'
import {POLL_PANEL_INSTALLERS} from 'configs/pollers'
import isNil from 'lodash-es/isNil'

import * as api from 'api/installers'
import * as actions from './actions'

import {update, purge} from '../store/actions'
import toIds from 'utils/toIds'
import {getPanelId} from 'utils/panelIdMatchSelector'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {assignInstallerToPanel} from 'modules/forms/handlers'
import {snackShow} from 'modules/snacks'

const selector = (state) => {
    const panelId = getPanelId(state)

    return {
        ...state.panelInstallers.list,
        panelId,
    }
}

export default function* () {
    yield all([
        takeEvery(actions.init, watchInit),
        takeEvery([actions.reset, actions.fetch], watchFetch),
        createListPollerSaga(actions, POLL_PANEL_INSTALLERS, watchInvalidate),
        takeEvery(assignInstallerToPanel.SUCCESS, watchAssignUserToPanel),
        takeEvery(assignInstallerToPanel.FAILURE, watchAssignInstallerToPanelFailed),
        takeEvery(actions.purgeList, watchPurgeList),
    ])
}

function* watchInit() {
    yield put(actions.reset())
}

function* watchFetch() {
    const state = yield select(selector)
    try {
        const {rows, count} = yield call(api.panelInstallers, state)

        yield put(update(rows, state.panelId))

        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchInvalidate() {
    const state = yield select(selector)

    const {rows, count} = yield call(api.panelInstallers, state)

    yield put(update(rows, state.panelId))
    yield put(actions.receive(toIds(rows), count))
}

watchInvalidate.onError = function* (error) {
    yield put(actions.receive(error))
}

function* watchAssignUserToPanel() {
    const installerId = yield select((state) => state.installerPanels.list.installerId)

    // On panel page
    if (!isNil(installerId)) {
        return
    }
    const state = yield select(selector)

    yield put(actions.fetch(state))
}

function* watchAssignInstallerToPanelFailed({payload}) {
    const installerId = yield select((state) => state.installerPanels.list.installerId)

    // On panel page
    if (!isNil(installerId)) {
        return
    }

    yield put(snackShow(payload.error))
}

function* watchPurgeList() {
    yield put(purge())
}
