import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const Advertisement = () => {
    const {data} = useSelector((state) => state.system.settings.advertisement)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('URL')}</InfoCardTileTitle>
                <InfoCardTileContent>{convertConfigValue(data.url)}</InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default Advertisement
