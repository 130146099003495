import {takeEvery, all, call, put, select, delay} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/state'

import {renamePartition} from 'modules/forms/handlers'
import generateProcess from 'modules/processes/manager/generateProcess'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import {selectBatchInProgress} from 'modules/batches/widget/selectors'
import watch from 'modules/higherOrder/watch'
import {snackShow} from 'modules/snacks'
import {PROCESS_TYPE_PMAXSTATESET} from 'constants/processTypes'

import {skipDelayPollPanelData} from '../one/actions'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watch(fetch)),
        takeEvery(actions.sendState, watchSendState),
        takeEvery(renamePartition.SUCCESS, watchRenamePartition),
    ])
}

export function* fetch({payload: {panelId}}) {
    const state = yield call(api.getPartitionStates, panelId)
    yield put(actions.receive(state, panelId))
}

fetch.onError = function* (error, {payload: {panelId}}) {
    yield put(actions.receive(error, panelId))
}

function* watchSendState(action) {
    const {panelId, partition, state, modifier} = action.payload
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_PMAXSTATESET,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_PMAXSTATESET, panelId)

        yield execute(api.sendState, panelId, partition, state, modifier, batchId)

        while (true) {
            const inProgress = yield select((store) =>
                selectBatchInProgress(store, batchId)
            )

            if (!inProgress) {
                yield put(actions.setStateInProgress(panelId, partition, state, false))
                yield put(skipDelayPollPanelData())
                break
            }

            yield put(actions.setStateInProgress(panelId, partition, state, true))

            yield delay(1000)
        }
    } catch (e) {
        yield put(snackShow(e.message))
        yield removeBatch()
    }
    yield put(actions.fetch(panelId))
}

function* watchRenamePartition({
    meta: {panelId, partitionId, name},
    payload: {result: process},
}) {
    yield ensureProcess(process)

    if (!process.isFailed) {
        yield put(actions.renamePartition(panelId, partitionId, name))
    } else {
        yield put(snackShow(process.errorMessage))
    }
}
