import React, {Component} from 'react'
import PropTypes from 'prop-types'

import AutohideList from 'ipmp-react-ui/AutohideList'
import DropDown, {DropDownContent} from 'ipmp-react-ui/DropDown'

import Each from 'ui/Each'

import {GroupTitle} from 'components/TableCells/GroupCell'

export default class RoleGroupCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            groups: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                })
            ).isRequired,
        }).isRequired,
    }

    render() {
        const {groups} = this.props.row

        const trigger = (
            <AutohideList className="colons">
                <Each
                    of={groups}
                    render={({id, name, linkedToDealer}) => (
                        <span className="colon" key={id}>
                            <GroupTitle name={name} linkedToDealer={linkedToDealer} />
                        </span>
                    )}
                />
            </AutohideList>
        )

        return (
            <DropDown trigger={trigger}>
                <DropDownContent>
                    <Each
                        of={groups}
                        render={({id, name, linkedToDealer}) => (
                            <GroupTitle
                                id={id}
                                name={name}
                                linkedToDealer={linkedToDealer}
                            />
                        )}
                    />
                </DropDownContent>
            </DropDown>
        )
    }
}
