import {createAction} from 'redux-actions'

export const fetch = createAction('PANEL/HAD/FETCH')
export const fetchSwitchable = createAction('PANEL/HAD/FETCH_SWITCHABLE')
export const receive = createAction('PANEL/HAD/RECEIVE')
export const receiveSwitchable = createAction('PANEL/HAD/RECEIVE_SWITCHABLE')
export const on = createAction('PANEL/HAD/ON')
export const lock = createAction('PANEL/HAD/LOCK')
export const unlock = createAction('PANEL/HAD/UNLOCK')
export const toggle = createAction('PANEL/HAD/TOGGLE')
export const onWithTimer = createAction('PANEL/HAD/ON_WITH_TIMER')
export const off = createAction('PANEL/HAD/OFF')
export const setLabel = createAction('PANEL/HAD/SET_LABEL')

export const updateHad = createAction('PANEL/HAD/UPDATE_HAD')
export const revertUpdateHad = createAction('PANEL/HAD/REVERT_UPDATE_HAD')

export const startPoll = createAction('PANEL/HAD/START_POLL')
export const startPollSwitchable = createAction('PANEL/HAD/START_POLL_SWITCHABLE')
export const stopPoll = createAction('PANEL/HAD/STOP_POLL')
export const stopPollSwitchable = createAction('PANEL/HAD/STOP_POLL_SWITCHABLE')
export const update = createAction('PANEL/HAD/UPDATE')
export const updateSwitchable = createAction('PANEL/HAD/UPDATE_SWITCHABLE')

export const openWaterValve = createAction('PANEL/HAD/WATER_VALVE/OPEN')
export const closeWaterValve = createAction('PANEL/HAD/WATER_VALVE/CLOSE')
