import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import {eventsInitiate} from 'permissions/panel/actions'
import {get} from 'permissions/panel/state/actions'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showEventInitiate} from 'modules/modals/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {selectPanelFeatures} from 'modules/features/store/selectors'

const ButtonVisibility = withVisibility()(Button)

export default function InitEmergenciesButton() {
    const {isPermitted: eventInitiatePermitted} = usePermission(eventsInitiate)
    const {isPermitted: getPartitions} = usePermission(get)
    const panelId = useRouterPanelId()
    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    const isEmergenciesEnabled = features.emergency?.isEnabled
    const {onInit} = useActions({onInit: () => showEventInitiate(panelId)})

    return (
        <ButtonVisibility
            label={__('Emergencies')}
            isVisible={eventInitiatePermitted && getPartitions && isEmergenciesEnabled}
            dataTestId="emergencies"
            onClick={onInit}
        />
    )
}
