import {createSelector} from 'reselect'
import {get} from 'lodash-es'

export const getByGroupId = createSelector(
    (state, groupId) =>
        get(state, `groups.notifications.eventProfiles.byIds.${groupId}`, {}),
    (settings) => settings
)

export const getIsLoading = createSelector(
    (state) => get(state, 'groups.notifications.eventProfiles.isLoading', false),
    (isLoading) => isLoading
)
