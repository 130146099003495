import React from 'react'
import {compose} from 'redux'
import {useNavigate} from 'react-router'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import Empty from 'ui/Empty'

import GroupCell from 'components/TableCells/GroupCell'

import list from 'permissions/groups/list'
import group from 'permissions/group/page'
import selectionPermission from 'permissions/groups/selection'
import {roles} from 'permissions/groups/actions'

import {MAIN_GROUP_ID} from 'constants/groups'

import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import usePermission from 'hooks/usePermission'
import useGroups from 'hooks/pages/useGroups'
import useSelection from 'hooks/useSelection'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import GroupRoles from './TableCell/GroupRoles'

import {__, __n} from 'utils/i18n'
import path from 'utils/path'

const allColumns = [
    {
        name: () => __('Name'),
        width: 500,
        render: ({name, description, linkedToDealer}) => (
            <Definition
                title={<GroupCell row={{name, linkedToDealer}} />}
                detail={description}
                multiLine
            />
        ),
    },
    {
        name: () => __('Panels'),
        tooltip: false,
        width: 100,
        render: ({total}) => {
            if (total === 0) {
                return <span className="empty">{__('No panels')}</span>
            }
            return <Definition title={total} detail={__n('panel', 'panels', total)} />
        },
    },
    {
        tooltip: false,
        width: 100,
        render: ({online, total}) => {
            if (total === 0) {
                return <Empty />
            }

            return <Definition title={online} detail={__('online')} />
        },
    },
    {
        tooltip: false,
        width: 100,
        render: ({connected, total}) => {
            if (total === 0) {
                return <Empty />
            }

            return <Definition title={connected} detail={__('connected')} />
        },
    },
    {
        tooltip: false,
        permission: roles,
        fixed: true,
        width: 75,
        render: ({id}) => <GroupRoles groupId={id} />,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({init}) => init())
)(Table)

export default function GroupsTable() {
    const {hasSelection, hasGroupLink} = usePermission({
        hasSelection: selectionPermission,
        hasGroupLink: group,
    })

    const navigate = useNavigate()
    const {rows, init, isLoading, error} = useGroups()
    const {selection, select, deselect} = useSelection()
    const {columns} = useTableColumnsPermission(allColumns)
    const onRowClick = hasGroupLink && (({id}) => navigate(path('group', {id})))

    return (
        <ConnectedTable
            fullHeight
            init={init}
            rows={rows}
            error={error}
            isLoading={isLoading}
            columns={columns}
            select={select}
            deselect={deselect}
            selection={selection}
            hasSelection={hasSelection}
            disabledRowsIds={[MAIN_GROUP_ID]}
            emptyMessage={__('No groups found')}
            onRowClick={onRowClick}
            className="groups-table"
        />
    )
}
