import React, {Fragment} from 'react'

import ZoneTypesTable from 'pages/Settings/EventRules/NotificationTypes/NotificationTypesTable'
import ZoneTypesGridBar from 'pages/Settings/EventRules/NotificationTypes/NotificationTypesGridBar'

export default function NotificationTypesPage() {
    return (
        <Fragment>
            <ZoneTypesGridBar />
            <ZoneTypesTable />
        </Fragment>
    )
}
