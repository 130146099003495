import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import Select, {Option} from 'ipmp-react-ui/Select'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import withModalLoader from 'containers/withModalLoader'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch} from 'modules/remoteInspections/values/actions'
import {editRemoteInspectionValues} from 'modules/forms/handlers'

const allowedValues = Object.freeze({
    reportedFaultsOption: ['10', '20', '30'],
    systemStateOption: ['40', '60', '120'],
    frequentlyUsedZonesOption: ['1', '2', '5'],
    treatAdjustedDateOption: ['15', '30', '45'],
})

const rules = {
    reportedFaultsOption: {
        presence: true,
        inclusion: allowedValues.reportedFaultsOption,
    },
    systemStateOption: {
        presence: true,
        inclusion: allowedValues.systemStateOption,
    },
    frequentlyUsedZonesOption: {
        presence: true,
        inclusion: allowedValues.frequentlyUsedZonesOption,
    },
    treatAdjustedDateOption: {
        presence: true,
        inclusion: allowedValues.treatAdjustedDateOption,
    },
}

function RemoteInspectionValuesBase({hide, data, editable}) {
    const {form, isLoading, handle} = useForm(editRemoteInspectionValues)

    return (
        <ModalCardForm
            wide
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handle}
            editable={editable}
            isLoading={isLoading}
            header={__('Remote Inspection Values')}
        >
            <Select
                disabled={!editable}
                name="reportedFaultsOption"
                label={__('Check for reported faults in last')}
                defaultValue={data.reportedFaultsOption}
            >
                {allowedValues.reportedFaultsOption.map((value, key) => (
                    <Option
                        key={key}
                        value={value}
                        label={__('%d faulted events', value)}
                    />
                ))}
            </Select>

            <Select
                disabled={!editable}
                name="systemStateOption"
                label={__('Total System States in last')}
                defaultValue={data.systemStateOption}
            >
                {allowedValues.systemStateOption.map((value, key) => (
                    <Option key={key} value={value} label={__('%d reports', value)} />
                ))}
            </Select>

            <Select
                disabled={!editable}
                name="frequentlyUsedZonesOption"
                label={__('Check frequently used zones not used over:')}
                defaultValue={data.frequentlyUsedZonesOption}
            >
                {allowedValues.frequentlyUsedZonesOption.map((value, key) => (
                    <Option key={key} value={value} label={__('%d week', value)} />
                ))}
            </Select>

            <Select
                disabled={!editable}
                name="treatAdjustedDateOption"
                label={__('Treat Adjusted Date / time as failure if adjustment is over')}
                defaultValue={data.treatAdjustedDateOption}
            >
                {allowedValues.treatAdjustedDateOption.map((value, key) => (
                    <Option key={key} value={value} label={__('%d minutes', value)} />
                ))}
            </Select>
        </ModalCardForm>
    )
}

RemoteInspectionValuesBase.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        reportedFaultsOption: PropTypes.string.isRequired,
        systemStateOption: PropTypes.string.isRequired,
        frequentlyUsedZonesOption: PropTypes.string.isRequired,
        treatAdjustedDateOption: PropTypes.string.isRequired,
    }),
    editable: PropTypes.bool,
}

const RemoteInspectionValuesLoader = withModalLoader(({fetchHandler}) => fetchHandler())(
    RemoteInspectionValuesBase
)

export default function RemoteInspectionValues({hide, editable}) {
    const fetchHandler = useActions(fetch)
    const {data, isLoading} = useSelector(
        ({remoteInspections}) => remoteInspections.values
    )

    return (
        <RemoteInspectionValuesLoader
            hide={hide}
            data={data}
            editable={editable}
            isLoading={isLoading}
            fetchHandler={fetchHandler}
        />
    )
}
