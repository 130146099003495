import FilterItemsMenu from 'components/Search/Autocomplete/FilterItemsMenu'
import FiltersMenu from 'components/Search/Autocomplete/FiltersMenu'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import has from 'lodash-es/has'

import {ReactComponent as CrossIcon} from 'ipmp-react-ui/icons/cross.svg'
import Hotkey from 'ipmp-react-ui/Hotkey'
import SlideDown from 'ipmp-react-ui/SlideDown'
import OuterClick from 'ipmp-react-ui/OuterClick'

import FilterDefinition from './Filters/Filter'
import {__} from 'utils/i18n'

export default class SearchAutoComplete extends Component {
    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.instanceOf(FilterDefinition)),
        suggestCount: PropTypes.objectOf(PropTypes.number),
        suggests: PropTypes.object,
        onSuggest: PropTypes.func,
        onSearch: PropTypes.func,
        disabled: PropTypes.bool,
        handleCurrentSearch: PropTypes.func,
        hideAutoCompleteAfterQuery: PropTypes.bool,
    }

    static defaultProps = {
        hideAutoCompleteAfterQuery: false,
    }

    constructor(props) {
        super(props)

        this.state = {
            filter: null,
            queryPos: this.props.query ? this.props.selected.length : null,
            selected: null,
            query: null,
        }
    }

    moveLineToEnd() {
        if (!this.lineElement) {
            return
        }

        if (isRtl()) {
            const maxScrollRight =
                this.lineElement.clientWidth - this.lineElement.scrollWidth

            this.lineElement.scrollLeft = maxScrollRight
        } else {
            const maxScrollLeft = has(this.lineElement, 'scrollLeftMax')
                ? this.lineElement.scrollLeftMax
                : this.lineElement.scrollWidth - this.lineElement.clientWidth

            this.lineElement.scrollLeft = maxScrollLeft
        }
    }

    static getDerivedStateFromProps({selected, query, active}, prevState) {
        if (!query) {
            return {
                queryPos: null,
                selected,
                query,
            }
        }

        if (query !== prevState.query) {
            return {
                queryPos: selected.length,
                selected,
                query,
            }
        }

        if (prevState.selected !== selected) {
            const removed = prevState.selected
                .slice(0, prevState.queryPos)
                .filter(({$}) => selected.every((item) => item.$ !== $)).length

            return {
                queryPos: prevState.queryPos - removed,
                selected,
                query,
            }
        }

        return {
            selected,
            query,
        }
    }

    handleSelectItem = (item) => {
        const {handleCurrentSearch, onSelect} = this.props
        onSelect(item)
        handleCurrentSearch('')
        this.setState({filter: null})
    }

    handleSelectFilter = (filter) => {
        const {handleCurrentSearch} = this.props
        handleCurrentSearch('')
        return this.setState({filter})
    }

    handleSearch = () => {
        const {value} = this.props
        return this.onQuery(value)
    }

    handleClearQuery = () => this.props.onQuery && this.props.onQuery(null)

    onQuery = (query = null) => {
        const {onQuery, handleCurrentSearch, hideAutoCompleteAfterQuery} = this.props

        if (onQuery) {
            onQuery(query)
            if (hideAutoCompleteAfterQuery) {
                handleCurrentSearch(query)
                this.handleOuterClick()
            } else {
                handleCurrentSearch('')
            }
        }
    }

    handleBackspace = (e) => {
        const {filter, queryPos} = this.state
        const {value} = this.props

        if (value.length > 1) {
            return
        }

        if (filter) {
            this.setState({filter: null})
            return
        }

        const {selected, filters, disabled, onDeselect} = this.props

        if (queryPos >= selected.length) {
            this.handleClearQuery()
            return
        }

        if (selected.length === 0) {
            return
        }

        const lastFilter = selected[selected.length - 1]

        onDeselect(lastFilter)

        if (!disabled) {
            this.setState({
                filter: filters.find((filter) => filter.name === lastFilter.name),
            })
        }
    }

    handleFocus = () => {
        if (!this.props.hideAutoCompleteAfterQuery) {
            this.activate()
        }
        this.setState({focus: true})
    }

    handleBlur = () => {
        this.setState({focus: false})
    }

    activate() {
        if (!this.props.disabled && !this.state.active) {
            this.setState({active: true})
        }
    }

    componentDidUpdate(prevProps, prevState) {
        this.moveLineToEnd()
    }

    handleDeselect = (item) => {
        this.props.onDeselect(item)

        if (this.state.active) {
            this.setState({}, () => this.inputElement && this.inputElement.focus())
        }
    }

    handleChange = (e) => {
        const {handleCurrentSearch} = this.props
        const {value} = e.target
        handleCurrentSearch(value)
        this.activate()
    }

    handleOuterClick = () => {
        this.setState({active: false})
    }

    handleMenuHide = () => {
        const {handleCurrentSearch, hideAutoCompleteAfterQuery} = this.props
        this.setState(({active}) => {
            if (!active) {
                if (!hideAutoCompleteAfterQuery) {
                    handleCurrentSearch('')
                }
                return {filter: null}
            }

            return {}
        })
    }

    handleLineRef = (lineElement) => {
        this.lineElement = lineElement
        lineElement && this.moveLineToEnd()
    }

    handleInputRef = (inputElement) => (this.inputElement = inputElement)

    renderMenu() {
        const {filters, suggests, selected, onSuggest, onQuery, value} = this.props
        const {filter} = this.state

        if (!filter) {
            return (
                <FiltersMenu
                    onSelect={this.handleSelectFilter}
                    onSearch={onQuery ? this.handleSearch : null}
                    suggests={suggests}
                    selected={selected}
                    filters={filters}
                    prefix={value}
                />
            )
        }

        return (
            <FilterItemsMenu
                filter={filter}
                suggest={suggests && suggests[filter.name]}
                onSuggest={onSuggest}
                selected={selected}
                onSelect={this.handleSelectItem}
                prefix={value}
                onHide
            />
        )
    }

    renderSelectedFilters() {
        const {filters, selected, query} = this.props

        const items = selected.map((item, index) => {
            const filter = filters.find(({name}) => name === item.name)

            const name = filter ? filter.title : item.name
            const label = filter ? filter.getItemLabel(item.value) : item.value

            return (
                <div
                    className="search-autoComplete-item"
                    key={index}
                    onClick={() => this.handleDeselect(item)}
                >
                    <span className="search-autoComplete-name">{name}</span>
                    <span className="search-autoComplete-value">
                        {label || __('Empty')}
                    </span>
                </div>
            )
        })

        if (query) {
            const searchItem = (
                <div
                    key="search"
                    className="search-autoComplete-item"
                    onClick={this.handleClearQuery}
                >
                    <span className="search-autoComplete-name">{__('Search')}</span>
                    <span className="search-autoComplete-value">{query}</span>
                </div>
            )
            items.splice(this.state.queryPos, 0, searchItem)
        }

        return items
    }

    get left() {
        let left = 0

        if (this.inputElement && this.lineElement && this.state.active) {
            left = this.inputElement.offsetLeft - this.lineElement.scrollLeft
        }

        return `${left}px`
    }

    get right() {
        let right = 0

        if (this.inputElement && this.lineElement && this.state.active) {
            right = this.lineElement.offsetWidth - this.inputElement.offsetWidth
        }

        return `${right}px`
    }

    render() {
        const {
            query,
            selected,
            onClear,
            hideAutoCompleteAfterQuery,
            disabled,
            value,
            placeholder,
        } = this.props
        const {active, focus, filter} = this.state

        const clearable = query || (selected && selected.length > 0)

        const slideDownStyle = isRtl() ? {right: this.right} : {left: this.left}

        return (
            <OuterClick
                className="search-autoComplete"
                onOuterClick={active ? this.handleOuterClick : null}
            >
                {clearable && (
                    <CrossIcon className="search-autoComplete-clear" onClick={onClear} />
                )}

                <div className="search-autoComplete-line" ref={this.handleLineRef}>
                    {this.renderSelectedFilters()}

                    {filter && active && (
                        <div className="search-autoComplete-name">{filter.title}</div>
                    )}

                    <label className="search-autoComplete-input">
                        <input
                            ref={this.handleInputRef}
                            className="search-autoComplete-input-element"
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            placeholder={placeholder || __('Search')}
                            value={value}
                        />

                        {focus && (
                            <Hotkey
                                shortcut="backspace"
                                action={this.handleBackspace}
                                scope="input"
                            />
                        )}
                        {focus && (hideAutoCompleteAfterQuery || disabled) && (
                            <Hotkey
                                shortcut="enter"
                                action={this.handleSearch}
                                scope="input"
                            />
                        )}

                        <SlideDown
                            popdown
                            className="search-autoComplete-popup"
                            onHide={this.handleMenuHide}
                            style={slideDownStyle}
                        >
                            {active && this.renderMenu()}
                        </SlideDown>
                    </label>
                </div>
            </OuterClick>
        )
    }
}
