import React from 'react'
import {compose} from 'redux'
import {useNavigate} from 'react-router'

import Table from 'ipmp-react-ui/Table'
import DateTime from 'ipmp-react-ui/DateTime'
import LinkButton from 'ipmp-react-ui/LinkButton'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {__} from 'utils/i18n'
import path from 'utils/path'
import useSelection from 'hooks/useSelection'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import list from 'permissions/basicConfiguration/list'
import {withPermissionRejection} from 'containers/withPermission'
import useBasicConfigurations from 'hooks/pages/useBasicConfigurations'
import selectionPermission from 'permissions/basicConfiguration/selection'
import {pushBasic as pushBasicPermission} from 'permissions/panels/selection'

const columns = [
    {
        width: 100,
        name: () => __('Name'),
        render: ({name}) => name,
    },
    {
        width: 200,
        name: () => __('Configuration type'),
        render: ({vendor}) => vendor,
    },
    {
        name: () => __('Created'),
        maxWidth: 250,
        render: ({created}) => <DateTime datetime={created} />,
    },
    {
        name: () => __('Updated'),
        maxWidth: 250,
        render: ({updated}) => <DateTime datetime={updated} />,
    },
    {
        fixed: true,
        width: 50,
        align: 'right',
        render: ({id}) => (
            <LinkButton
                to={path('basicConfiguration.edit', {id})}
                small
                primary
                Icon={IconEdit}
            />
        ),
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({init}) => init())
)(Table)

export default function BasicConfigurationsTable() {
    const {hasSelection, pushBasic} = usePermission({
        hasSelection: selectionPermission,
        pushBasic: pushBasicPermission,
    })

    const navigate = useNavigate()
    const {selection, select, deselect} = useSelection()
    const basicConfigurationProps = useBasicConfigurations()

    const onRowClick = pushBasic
        ? ({id}) => navigate(path('basicConfiguration.push', {id}))
        : null

    return (
        <ConnectedTable
            {...basicConfigurationProps}
            fullHeight
            columns={columns}
            hasSelection={hasSelection}
            select={select}
            deselect={deselect}
            selection={selection}
            emptyMessage={__('No basic configurations found')}
            onRowClick={onRowClick}
        />
    )
}
