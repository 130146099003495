import React, {useState} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Detector from './ChartsTab/Detector'
import {TEMPERATURE, BRIGHTNESS, GSM_RSSI} from 'constants/meteoTypes'

ChartsTab.propTypes = {
    panelId: PropTypes.number.isRequired,
    device: PropTypes.object,
    hasBrightness: PropTypes.bool,
    hasTemperature: PropTypes.bool,
    hasGsmRssi: PropTypes.bool,
}

export default function ChartsTab({
    panelId,
    device,
    hasTemperature,
    hasBrightness,
    hasGsmRssi,
}) {
    const [range] = useState({
        from: moment().subtract(1, 'day'),
        to: moment(),
    })

    return (
        <div className="blocks charts">
            {hasTemperature && (
                <Detector
                    type={TEMPERATURE}
                    range={range}
                    panelId={panelId}
                    deviceId={device.id}
                />
            )}

            {hasBrightness && (
                <Detector
                    type={BRIGHTNESS}
                    range={range}
                    panelId={panelId}
                    deviceId={device.id}
                />
            )}

            {hasGsmRssi && (
                <Detector
                    type={GSM_RSSI}
                    range={range}
                    panelId={panelId}
                    deviceId={device.id}
                />
            )}
        </div>
    )
}
