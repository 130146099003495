import {get} from 'lodash-es'
import {createSelector} from 'reselect'

export const selectGroupById = createSelector(
    (state, groupId) => get(state, `groups.store.byIds.${groupId}`),
    (group) => group
)

export const selectByGroupsIds = createSelector(
    (state) => state.groups.store.byIds,
    (byIds) => byIds
)
