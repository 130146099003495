import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import Void from 'components/Void'
import usePermission from 'hooks/usePermission'
import {activate} from 'permissions/panel/actions'
import {showActivatePanel} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {selectPanel} from 'modules/panels/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'
import {selectActivation} from 'modules/features/store/selectors'
import {ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED} from 'constants/panelActivationStatus'

const ConnectedButton = compose(
    withVisibility(),
    withFeatureRejection(selectActivation, Void)
)(Button)

export default function ActivateButton({panelId}) {
    const {isPermitted} = usePermission(activate)
    const {onClick} = useActions(
        {
            onClick: () => showActivatePanel(panelId),
        },
        [panelId]
    )

    const {isActivating, activationStatus, activatingStarted} = useSelector((state) =>
        selectPanel(state, {panelId})
    )

    const isVisible =
        isPermitted &&
        !isActivating &&
        !activatingStarted &&
        activationStatus === ACTIVATION_STATUS_INSTALLER_ACTIVATION_REQUIRED

    return (
        <ConnectedButton
            onClick={onClick}
            panelId={panelId}
            isVisible={isVisible}
            label={__('Activate')}
        />
    )
}
