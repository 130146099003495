import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import useRunners from 'hooks/pages/useRunners'

export default function RunnersPager() {
    const {total, start, perPage, onPageChange, onPerPageChange} = useRunners()

    return (
        <Pager
            total={total}
            start={start}
            perPage={perPage}
            onPerPageChange={onPerPageChange}
            onPageChange={onPageChange}
        />
    )
}
