import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as PowerIcon} from 'ipmp-react-ui/icons/unblock.svg'
import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'
import {ReactComponent as EnableIcon} from 'ipmp-react-ui/icons/run.svg'

import {RowAction} from 'ui/Row/Actions'

import usePermission from 'hooks/usePermission'
import useConfirmationModal from 'hooks/useConfirmationModal'

import {enable, suspend, unblock} from 'permissions/users/selection'
import {setEnabled, unblockUser} from 'modules/users/store/actions'

import {withVisibility} from 'containers/withVisibility'

import __ from 'utils/i18n'

export default function ChangeStatusAction({isEnabled, isBlockedMfa, name, id}) {
    switch (true) {
        case isBlockedMfa:
            return (
                <UnblockAction id={id} name={name}>
                    {__('Unblock')}
                </UnblockAction>
            )
        case isEnabled:
            return (
                <SuspendAction id={id} name={name}>
                    {__('Suspend')}
                </SuspendAction>
            )
        case !isEnabled:
            return (
                <EnableAction id={id} name={name}>
                    {__('Enable')}
                </EnableAction>
            )
    }
}

ChangeStatusAction.propTypes = {
    isEnabled: PropTypes.bool,
    isBlockedMfa: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
}

const DecoratedRowAction = withVisibility()(RowAction)
const UnblockAction = ({id, name, children}) => {
    const {isPermitted} = usePermission(unblock)

    const {onClick} = useConfirmationModal(
        ({id}) => unblockUser(id),
        () => ({
            title: __('Unblock user'),
            message: __('Do you really want to unblock the user: %s?', name),
            positive: __('Unblock'),
        }),
        {id}
    )

    return (
        <DecoratedRowAction isVisible={isPermitted} onClick={onClick} Icon={PowerIcon}>
            {children}
        </DecoratedRowAction>
    )
}

UnblockAction.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
}

const SuspendAction = ({id, name, children}) => {
    const {isPermitted} = usePermission(suspend)

    const {onClick} = useConfirmationModal(
        ({id}) => setEnabled([id], false),
        () => ({
            title: __('Suspend user'),
            message: __('Do you really want to suspend the user: %s?', name),
            positive: __('Suspend'),
        }),
        {id}
    )

    return (
        <DecoratedRowAction isVisible={isPermitted} onClick={onClick} Icon={PauseIcon}>
            {children}
        </DecoratedRowAction>
    )
}

SuspendAction.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
}

const EnableAction = ({id, name, children}) => {
    const {isPermitted} = usePermission(enable)

    const {onClick} = useConfirmationModal(
        ({id}) => setEnabled([id], true),
        () => ({
            title: __('Enable user'),
            message: __('Do you really want to enable the user: %s?', name),
            positive: __('Enable'),
        }),
        {id}
    )

    return (
        <DecoratedRowAction isVisible={isPermitted} onClick={onClick} Icon={EnableIcon}>
            {children}
        </DecoratedRowAction>
    )
}

EnableAction.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
}
