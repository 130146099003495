import React, {useEffect} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Table from 'ipmp-react-ui/Table'
import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'

import list from 'permissions/installers/list'
import selectionPermission from 'permissions/installers/selection'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import useInstallers from 'hooks/pages/useInstallers'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import {selectIsDefaultRoleType} from 'modules/auth/sign/selectors'

import User from 'components/Entities/User'

import InstallerStatusChanger from './TableCell/InstallerStatusChanger'

import {__, __n} from 'utils/i18n'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

const STATUS_KEY = 'status'

const allColumns = [
    {
        name: () => __('Installer'),
        width: 230,
        render: ({name, email}) => <User withInfo name={name} email={email} />,
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        width: 120,
        render: ({createdAt}) => <DateTime datetime={createdAt} multiLine />,
    },
    {
        name: () => __('Last Login'),
        width: 120,
        render: ({lastLogin}) => <DateTime datetime={lastLogin} multiLine />,
    },
    {
        name: () => __('Panels'),
        render: ({panels}) => {
            if (!panels) {
                return <span className="empty">{__('No panels')}</span>
            }

            return (
                <Definition
                    title={panels.length}
                    detail={__n('panel', 'panels', panels.length)}
                />
            )
        },
    },
    {
        key: STATUS_KEY,
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatusChanger,
    },
]

const ConnectedInstallersTable = compose(
    withPermissionRejection(list),
    withLoader(({init}) => init())
)(Table)

function InstallersTable({onRowClick, active}) {
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    const {selection, select, deselect} = useSelection()
    const {rows, init, error, isLoading, startPoll, stopPoll} = useInstallers()
    let {columns} = useTableColumnsPermission(allColumns)

    useEffect(() => {
        startPoll()

        return stopPoll
    }, [])

    const isDefaultRoleType = useSelector(selectIsDefaultRoleType)

    const onTableRowClick = isDefaultRoleType ? onRowClick : null

    if (!isDefaultRoleType) {
        columns = columns.filter((column) => column.key !== STATUS_KEY)
    }

    return (
        <ConnectedInstallersTable
            init={init}
            rows={rows}
            error={error}
            onRowClick={onTableRowClick}
            active={active}
            isLoading={isLoading}
            fullHeight
            columns={columns}
            hasSelection={hasSelection}
            select={select}
            deselect={deselect}
            selection={selection}
            emptyMessage={__('No installers found')}
            className="accounts-page-installers-table"
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}

InstallersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}

export default InstallersTable
