import {createAction} from 'redux-actions'

export const fetchSupportedTestsList = createAction(
    'PANELS/PANEL_TESTS/FETCH_SUPPORTED_TESTS',
    (panelId) => ({panelId})
)

export const startPollSupportedTestsList = createAction(
    'PANELS/PANEL_TESTS/START_POLL_SUPPORTED_TESTS_LIST',
    (panelId) => ({panelId})
)

export const stopPollSupportedTestsList = createAction(
    'PANELS/PANEL_TESTS/STOP_POLL_SUPPORTED_TESTS_LIST'
)

export const updateSupportedTestsList = createAction(
    'PANELS/PANEL_TESTS/UPDATE_SUPPORTED_TESTS_LIST',
    (payload) => payload
)
