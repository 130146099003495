import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import AddInstaller from './GridBar/AddInstaller'
import InstallersPager from './GridBar/InstallersPager'
import InstallerRemoveButton from './GridBar/InstallerRemoveButton'
import InstallersAcceptButton from './GridBar/InstallersAcceptButton'
import InstallersRejectButton from './GridBar/InstallersRejectButton'
import InstallersSelectionDropDown from './GridBar/InstallersSelectionDropDown'

export default function InstallersBar() {
    return (
        <Bar>
            <InstallersSelectionDropDown />
            <InstallersAcceptButton />
            <InstallersRejectButton />
            <InstallerRemoveButton />

            <BarSpace />

            <InstallersPager />
            <AddInstaller />
        </Bar>
    )
}
