import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {useActions} from 'modules/higherOrder/useActions'
import {sendAddSensorConfirmation} from 'modules/devices/autolearn/actions'
import __ from 'utils/i18n'

export default function ConfirmWithPinCodeButtons({
    session,
    sensor,
    pin,
    isValid,
    setHasPin,
}) {
    const [handle] = useActions([sendAddSensorConfirmation])
    const onSubmit = () => {
        handle({session, data: {sensor, pin, isConfirmed: true}})
    }

    return (
        <>
            <Button onClick={() => setHasPin(false)} borderless>
                {__('Back')}
            </Button>
            <Button primary onClick={onSubmit} disabled={!isValid(pin)}>
                {__('Send PIN')}
            </Button>
        </>
    )
}

ConfirmWithPinCodeButtons.propTypes = {
    session: PropTypes.object.isRequired,
    sensor: PropTypes.string.isRequired,
    pin: PropTypes.string.isRequired,
    isValid: PropTypes.func.isRequired,
    setHasPin: PropTypes.func.isRequired,
}
