import React from 'react'

import useSelection from 'hooks/useSelection'
import useEvents from 'hooks/pages/useEvents'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import SelectionDropDown from 'components/SelectionDropDown'
import selectionPermission from 'permissions/events/selection'

const DropDownVisible = withVisibility()(SelectionDropDown)

export default function EventDropDown() {
    const {rows, total} = useEvents()
    const {isPermitted} = usePermission(selectionPermission)
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <DropDownVisible
            rows={rows}
            total={total}
            selection={selection}
            select={select}
            deselect={deselect}
            deselectAll={deselectAll}
            selectAll={selectAll}
            isVisible={isPermitted}
        />
    )
}
