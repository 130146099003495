import React from 'react'

import Page from 'ipmp-react-ui/Page'

import ReportsTable from './ReportsTable'
import ReportsGridBar from './ReportsGridBar'

export default function ReportsPage() {
    return <Page Bar={ReportsGridBar} Content={ReportsTable} />
}
