import React, {PureComponent} from 'react'
import timezones from 'utils/timezones'
import moment from 'moment/moment'

import InputDatePicker from 'ipmp-react-ui/InputDatePicker'
import {__} from 'utils/i18n'

// It's not clear that format always be the same,
// but we don't have information about date format
const dateFormat = 'MM/DD/YY'
// const momentFormat = 'YYYY-MM-DD'
const empty = '00/00/00'
const unlimited = 'unlimited'

const parseDateValue = (value) => {
    if ([empty, unlimited].includes(value)) {
        return null
    }

    return timezones.server(value, dateFormat)
}

export default class DateValue extends PureComponent {
    handleChange = (value, ...args) => {
        const val = value ? value.format(dateFormat) : empty
        const valid = !this.validate(value)
        this.props.onChange(val, valid)
    }

    getMinDate() {
        const {
            item: {min_date},
        } = this.props

        let min = null
        if (min_date === 'now()') {
            min = moment().startOf('day')
        }

        return min
    }

    validate(value) {
        if (value && value.length === 0) {
            return null
        }

        const date = moment(value)

        if (date.isValid() === false) {
            return __('Date is not valid')
        }

        if (this.getMinDate()) {
            if (date.isBefore(this.getMinDate())) {
                return __('Selected date is before min date')
            }
        }

        return null
    }

    render() {
        const {value, isChanged} = this.props

        return (
            <InputDatePicker
                placeholder={unlimited === value ? __('Unlimited') : __('Not set')}
                value={parseDateValue(value)}
                onChange={this.handleChange}
                min={this.getMinDate()}
                error={isChanged ? this.validate(value) : null}
            />
        )
    }
}
