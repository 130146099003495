import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import has from 'lodash-es/has'

import {showUnavailableFeatureModal} from 'modules/modals/actions'
import FeatureNotSupported from 'components/Panel/FeatureNotSupported'
import withInterruption from './withInterruption'
import {selectSelectedFeature} from 'modules/panels/store/selectors'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {fetch, fetchWithoutLoader} from 'modules/features/store/actions'
import getHocName from '../utils/getHocName'

export function withSelectionFeature(feature, selector, message) {
    return compose(
        connect(
            (state) => ({state}),
            (dispatch) =>
                bindActionCreators(
                    {
                        showUnavailableFeatureModal,
                    },
                    dispatch
                ),
            ({...state}, dispatch, {onClick, ...props}) => ({
                ...state,
                ...props,
                onClick,
                showUnavailableFeatureModal: ({unavailablePanels, availableIds}) => {
                    dispatch.showUnavailableFeatureModal({
                        message: message,
                        unavailablePanels,
                        action: onClick,
                        availableIds,
                    })
                },
            })
        ),
        (WrappedComponent) => {
            const fn = (props) => {
                const {onClick, showUnavailableFeatureModal, state} = props
                const handler = (...args) => {
                    const {isFeatureAvailable, unavailablePanels, availableIds} = selector
                        ? selector(state, {feature})
                        : selectSelectedFeature(state, {feature})

                    isFeatureAvailable
                        ? onClick(...args)
                        : showUnavailableFeatureModal({
                              unavailablePanels,
                              availableIds,
                          })
                }

                return <WrappedComponent {...props} onClick={handler} />
            }

            fn.displayName = getHocName('withCloseConfirmation', WrappedComponent)

            return fn
        }
    )
}

export function withSelectionFilter(selector) {
    return compose(
        connect(
            (state) => ({state}),
            (dispatch) =>
                bindActionCreators(
                    {
                        showUnavailableFeatureModal,
                    },
                    dispatch
                ),
            ({...state}, dispatch, {onClick, ...props}) => ({
                ...state,
                ...props,
                onClick,
                showUnavailableFeatureModal: ({
                    message,
                    text,
                    unavailablePanels,
                    availableIds,
                }) => {
                    dispatch.showUnavailableFeatureModal({
                        message,
                        text,
                        unavailablePanels,
                        action: onClick,
                        availableIds,
                    })
                },
            })
        ),
        (WrappedComponent) => {
            const fn = (props) => {
                const {onClick, showUnavailableFeatureModal, state} = props
                const handler = (
                    {unavailableFeatureModalTitle, unavailableFeatureModalText},
                    ...args
                ) => {
                    const {isAvailable, unavailablePanels, availableIds} = selector(state)

                    isAvailable
                        ? onClick(...args)
                        : showUnavailableFeatureModal({
                              message: unavailableFeatureModalTitle,
                              text: unavailableFeatureModalText,
                              unavailablePanels,
                              availableIds,
                          })
                }

                return <WrappedComponent {...props} onClick={handler} />
            }

            fn.displayName = getHocName('withCloseConfirmation', WrappedComponent)

            return fn
        }
    )
}

export function withFeatureRejection(selector, Component = FeatureNotSupported) {
    return compose(
        connect(selector),
        withInterruption(({isAvailable}) => !isAvailable, Component)
    )
}

export const filterPanelTabsWithFeature = (state, menu, panelId) => {
    return Object.keys(menu).reduce((acc, key) => {
        const menuObj = menu[key]

        if (!has(menuObj, 'selector')) {
            acc[key] = menuObj
        } else {
            const {isAvailable} = menuObj.selector(state, {panelId})

            if (isAvailable) {
                acc[key] = menuObj
            }
        }

        return acc
    }, {})
}

export default function withFeatures() {
    return connect(selectPanelFeatures, (dispatch) =>
        bindActionCreators(
            {
                fetchFeaturesWithoutLoader: fetchWithoutLoader,
                fetchFeatures: fetch,
            },
            dispatch
        )
    )
}
