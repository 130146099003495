import React, {useEffect} from 'react'
import {compose} from 'redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import Empty from 'ui/Empty'

import {disableUser, list} from 'permissions/panel/customers/actions'
import {unassign} from 'permissions/interactiveUsers/actions'
import {panels as interactiveUsersPanels} from 'permissions/interactiveUsers/list'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import {usePanelInteractiveUsers} from 'hooks/pages/useInteractiveUsers'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import DisablePanelUserButton from 'components/Panel/PanelUser/DisablePanelUserButton'
import User, {USER_TYPE_CUSTOMER} from 'components/Entities/User'
import {Partitions} from 'components/Partitions'

import DisconnectUserCell from './TableCell/DisconnectUserCell'
import PanelInteractiveUserStatusChanger from './TableCell/PanelInteractiveUserStatusChanger'
import {PinCell} from './TableCell/PinCell'
import {RoleCell} from './TableCell/RoleCell'

import {normalizePanelUserIndex} from 'utils/panelUser'
import {__} from 'utils/i18n'

const CUSTOMER_TABLE_ROW_MIN_HEIGHT = 51

const allColumns = [
    {
        name: () => __('№'),
        render: ({index}) => normalizePanelUserIndex(index),
        width: 16,
    },
    {
        name: () => __('Role'),
        render: ({role}) => <RoleCell role={role} />,
    },
    {
        name: () => __('Label'),
        render: ({label}) => label || <span className="empty">{__('No Label')}</span>,
    },
    {
        name: () => __('Code'),
        render: ({isPinSet}) => <PinCell isPinSet={isPinSet} />,
    },
    {
        name: () => __('Partitions'),
        render: ({partitions}) =>
            partitions ? (
                <Partitions partitions={partitions} />
            ) : (
                <span className="empty">{__('No partitions')}</span>
            ),
    },
    {
        name: () => __('Customer'),
        permission: interactiveUsersPanels,
        width: 170,
        render: ({email}) => <User name={email} withInfo type={USER_TYPE_CUSTOMER} />,
    },
    {
        name: () => __('Created'),
        permission: interactiveUsersPanels,
        width: 70,
        render: ({createdAt}) =>
            createdAt ? <DateTime datetime={createdAt} multiLine /> : <Empty />,
    },
    {
        name: () => __('Status'),
        tooltip: false,
        permission: interactiveUsersPanels,
        component: PanelInteractiveUserStatusChanger,
    },
    {
        permission: unassign,
        fixed: true,
        width: 40,
        tooltip: false,
        component: DisconnectUserCell,
    },
    {
        tooltip: false,
        permission: disableUser,
        fixed: true,
        width: 46,
        component: DisablePanelUserButton,
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function PanelInteractiveUsersTable({isShowUnassignedUsers, ...props}) {
    const panelInteractiveUsersProps = usePanelInteractiveUsers()

    useEffect(() => {
        panelInteractiveUsersProps.init()
        panelInteractiveUsersProps.startPoll()

        return panelInteractiveUsersProps.stopPoll
    }, [])

    const tableProps = {...props, ...panelInteractiveUsersProps}
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <ConnectedTable
            {...tableProps}
            fullHeight
            className="panelUsers-table"
            columns={columns}
            emptyMessage={__('No customers found')}
            rowHeight={CUSTOMER_TABLE_ROW_MIN_HEIGHT}
        />
    )
}
