import {put} from 'redux-saga/effects'

import {EVENT_PANEL_EVENT} from 'constants/autolearn'
import {snackShow} from 'modules/snacks'
import isBitOn from 'utils/bitmask'
import __ from 'utils/i18n'

import {stopSession} from '../actions'

const PANEL_EVENT_AUTOLEARN_STATUS = 64
const PANEL_EVENT_ZWAVE_NODE_REMOTE_ADD_ENDED = 39

export default function* (session, message) {
    if (EVENT_PANEL_EVENT !== message.event) {
        return null
    }

    const {event, payload0: payload} = message.panelEvent || {}

    if (PANEL_EVENT_AUTOLEARN_STATUS === event) {
        const isAutolearnSessionStarted = isBitOn(payload, 0)

        if (!isAutolearnSessionStarted) {
            yield put(snackShow(__('Autolearn session finished')))
            yield put(stopSession(session))
        }
    }

    if (PANEL_EVENT_ZWAVE_NODE_REMOTE_ADD_ENDED === event) {
        const isAutolearnSessionFinished = isBitOn(payload, 0)

        if (isAutolearnSessionFinished) {
            yield put(snackShow(__('Autolearn session finished')))
            yield put(stopSession(session))
        }
    }
}
