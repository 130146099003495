import React from 'react'
import PropTypes from 'prop-types'

import __, {__n} from 'utils/i18n'

import DeliveranceTableRow from './DeliveranceTableRow'

export default function DeliveranceTable({deliverance, centralStation}) {
    return (
        <div className="deliverance-centralstation-table">
            <div>
                <h3 className="deliverance-heading">{centralStation?.name}</h3>
                <span className="deliverance-partitions">
                    {__n('(%d partition)', '(%d partitions)', deliverance.length)}
                </span>
            </div>
            <div className="deliverance-centralstation-table-content">
                <div className="row header-row">
                    <div className="col">№</div>
                    <div className="col deliverance-centralstation-protocol">
                        {__('Protocol')}
                    </div>
                    <div className="col deliverance-centralstation-host">
                        {__('Communication')}
                    </div>
                    <div className="col">{__('CS Status')}</div>
                    <div className="col">{__('Delivery')}</div>
                </div>
                {Object.keys(deliverance).map((key) => (
                    <DeliveranceTableRow
                        centralStation={centralStation}
                        delivery={deliverance[key]}
                        key={key}
                    />
                ))}
            </div>
        </div>
    )
}

DeliveranceTable.propTypes = {
    deliverance: PropTypes.array,
    centralStation: PropTypes.object,
}
