import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconUpload} from 'ipmp-react-ui/icons/upload.svg'
import InfoCards, {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import __ from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import {upgrade as upgradePermission} from 'permissions/panel/firmware/actions'

const ButtonVisibility = withVisibility()(Button)

const PatchTagButton = ({handler}) => {
    const {isPermitted} = usePermission(upgradePermission)
    return (
        <ButtonVisibility
            flat
            Icon={IconUpload}
            onClick={handler}
            isVisible={isPermitted}
        >
            {__('Patch tag ')}
        </ButtonVisibility>
    )
}

const FirmwarePatchTagCard = ({handler, info}) => {
    return (
        <InfoCards cols={1}>
            <InfoCard
                title={__('Update IQ Panel')}
                cols={2}
                headerButton={<PatchTagButton handler={handler} />}
            >
                {info &&
                    info.map(({name, value}) => (
                        <InfoCardTile key={name}>
                            <InfoCardTileTitle>{name}</InfoCardTileTitle>
                            <InfoCardTileContent>
                                {convertConfigValue(value)}
                            </InfoCardTileContent>
                        </InfoCardTile>
                    ))}
            </InfoCard>
        </InfoCards>
    )
}

FirmwarePatchTagCard.propType = {
    info: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            withButton: PropTypes.bool.isRequired,
        })
    ),
    handler: PropTypes.func,
}

export default FirmwarePatchTagCard
