import React from 'react'
import {Route, Routes} from 'react-router-dom'
import {pathLastChild} from 'utils/path'
import map from 'lodash-es/map'

import General from './General/General'
import Receiver from './Receiver/Receiver'
import Resolve from './Resolve/Resolve'
import Interactive from './Interactive/Interactive'
import EventRulesPageContent from './EventRules/EventRulesPageContent'
import ZoneTypesPage from './EventRules/ZoneTypes/ZoneTypesPage'
import NotificationTypesPage from './EventRules/NotificationTypes/NotificationTypesPage'
import StateTypesPage from './EventRules/StateTypes/StateTypesPage'
import EventTypesPage from './EventRules/EventTypes/EventTypesPage'

const componentsMap = {
    general: <General />,
    receiver: <Receiver />,
    resolve: <Resolve />,
    interactive: <Interactive />,
    eventRules: <EventRulesPageContent />,
    zoneTypes: <ZoneTypesPage />,
    notificationTypes: <NotificationTypesPage />,
    stateTypes: <StateTypesPage />,
    eventTypes: <EventTypesPage />,
}

export default function SettingsPageContent({menu}) {
    const children = map(menu, ({path: pathName, index}, route) => (
        <Route
            path={pathLastChild(pathName)}
            element={componentsMap[route]}
            key={route}
        />
    ))

    return <Routes>{children}</Routes>
}
