import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import __ from 'utils/i18n'
import usePanelInfo from 'hooks/pages/usePanelInfo'

export function EditCustomerInfoButtonWithText({googleApiError}) {
    const {editCustomerInfo} = usePanelInfo({googleApiError})

    return (
        <Button
            primary
            onClick={editCustomerInfo}
            label={__('Add Owner Info')}
            Icon={IconPlus}
        />
    )
}

export default function EditCustomerInfoButton({googleApiError}) {
    const {editCustomerInfo} = usePanelInfo({googleApiError})

    return <Button borderless onClick={editCustomerInfo} Icon={IconEdit} />
}
