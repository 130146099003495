import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import Togglebox from 'ipmp-react-ui/ToggleBox'
import DropDown, {DropDownContent} from 'ipmp-react-ui/DropDown'

import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import {withFeatureRejection} from 'containers/withFeature'
import apps, {user, configurator} from 'permissions/panels/apps'
import {setConfigurationAppState, setUserAppState} from 'modules/panels/one/actions'
import {
    selectUserAppFeature,
    selectInstallerAppFeature,
} from 'modules/panels/store/selectors'
import {withPermissionVisibility} from 'containers/withPermission'

const AppToggleUserAppFeatureRejection = compose(
    withPermissionVisibility(user),
    withFeatureRejection(selectUserAppFeature)
)(Togglebox)

const AppToggleInstallerAppRejection = compose(
    withPermissionVisibility(configurator),
    withFeatureRejection(selectInstallerAppFeature)
)(Togglebox)

function ApplicationInteractiveDropdownBase({
    panel,
    trigger,
    align,
    setUserAppState,
    setConfigurationAppState,
}) {
    const [userApp, setUserApp] = useState(panel.userApp)
    const [configuratorApp, setConfiguratorApp] = useState(panel.configuratorApp)

    const handleUserAppChange = (e) => {
        setUserApp(e.target.checked)
        setUserAppState(panel, e.target.checked)
    }

    const handleInstallerAppChange = (e) => {
        setConfiguratorApp(e.target.checked)
        setConfigurationAppState(panel, e.target.checked)
    }

    return (
        <DropDown trigger={trigger} align={align} className="panels-table-app-cell">
            <DropDownContent>
                <AppToggleUserAppFeatureRejection
                    panelId={panel.id}
                    checked={userApp}
                    onChange={handleUserAppChange}
                    label={__('Customer App')}
                />

                <AppToggleInstallerAppRejection
                    panelId={panel.id}
                    checked={configuratorApp}
                    onChange={handleInstallerAppChange}
                    label={__('Installer App')}
                />
            </DropDownContent>
        </DropDown>
    )
}

ApplicationInteractiveDropdownBase.propTypes = {
    panel: PropTypes.object.isRequired,
    trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
}

const ApplicationInteractiveDropdownVisibility = withPermissionVisibility(apps)(
    ApplicationInteractiveDropdownBase
)

export default function ApplicationInteractiveDropdown({panel, trigger, align}) {
    const {user, configurationApp} = useActions({
        user: setUserAppState,
        configurationApp: setConfigurationAppState,
    })

    return (
        <ApplicationInteractiveDropdownVisibility
            align={align}
            panel={panel}
            trigger={trigger}
            setUserAppState={user}
            setConfigurationAppState={configurationApp}
        />
    )
}
