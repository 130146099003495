import React, {useState} from 'react'
import * as PropTypes from 'prop-types'
import moment from 'moment'

import InputDatePicker from 'ipmp-react-ui/InputDatePicker'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setPanelUserExpirationDate} from 'modules/forms/handlers'
import timezones from 'utils/timezones'

const parseDateValue = (value) => {
    if (!value) {
        return null
    }

    return timezones.server(value)
}

const rules = {
    expDate: {
        presence: true,
    },
}

export default function SetPanelUserExpirationDate({
    hide,
    panelId,
    panelUserId,
    expirationDate,
}) {
    const {form, handle, isLoading} = useForm(setPanelUserExpirationDate)
    const [expDate, setExpDate] = useState(
        parseDateValue(expirationDate && new Date(expirationDate))
    )
    const [isChanged, setIsChanged] = useState(false)

    const onChangeExpDate = (value) => {
        setExpDate(value)
        setIsChanged(true)
    }

    const validate = (value) => {
        if (!isChanged || !value || value.length === 0) {
            return null
        }

        const date = moment(value)

        if (date.isValid() === false) {
            return __('Date is not valid')
        }

        return null
    }

    const onHandle = () => {
        const expirationDate = expDate ? expDate.format() : null
        setIsChanged(true)

        handle({panelId, panelUserId, expirationDate})
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={onHandle}
            isLoading={isLoading}
            rules={rules}
            header={__('Set panel user expiration date')}
        >
            <InputDatePicker
                label={__('Expiration date')}
                name="expDate"
                value={expDate}
                onChange={onChangeExpDate}
                min={moment()}
                error={validate(expDate)}
            />
        </ModalCardForm>
    )
}

SetPanelUserExpirationDate.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
