import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/users/bar'
import UserPager from './GridBar/UserPager'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import CreateUserButton from './GridBar/CreateUserButton'
import RemoveUsersButton from './GridBar/RemoveUsersButton'
import EnableUsersButton from './GridBar/EnableUsersButton'
import SuspendUsersButton from './GridBar/SuspendUsersButton'
import UserSelectionDropDown from './GridBar/UserSelectionDropDown'

function UsersBarBase() {
    return (
        <Bar>
            <UserSelectionDropDown />

            <RemoveUsersButton />
            <SuspendUsersButton />
            <EnableUsersButton />

            <BarSpace />

            <UserPager />
            <CreateUserButton />
        </Bar>
    )
}

const UsersBarVisibility = withVisibility()(UsersBarBase)

export default function UsersGridBar() {
    const {isPermitted} = usePermission(bar)

    return <UsersBarVisibility isVisible={isPermitted} />
}
