import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'

import usePermission from 'hooks/usePermission'
import bar from 'permissions/remoteInspections/bar'
import {withVisibility} from 'containers/withVisibility'
import RemoteInspectionsPager from './Bar/RemoteInspectionsPager'
import RemoteInspectionsDropDown from './Bar/RemoteInspectionsDropDown'
import CancelRemoteInspectionButton from './Bar/CancelRemoteInspectionButton'
import InitiateRemoteInspectionButton from './Bar/InitiateRemoteInspectionButton'
import ScheduleRemoteInspectionButton from './Bar/ScheduleRemoteInspectionButton'
import MarkAsViewedRemoteInspectionButton from './Bar/MarkAsViewedRemoteInspectionButton'
import ScheduleRemoteInspectionsByCsvButton from './Bar/ScheduleRemoteInspectionsByCsvButton'
import ConfigureRemoteInspectionOptionsButton from './Bar/ConfigureRemoteInspectionOptionsButton'

function RemoteInspectionBarBase() {
    return (
        <Bar>
            <RemoteInspectionsDropDown />
            <MarkAsViewedRemoteInspectionButton />

            <Buttons>
                <ScheduleRemoteInspectionButton />
                <InitiateRemoteInspectionButton />
                <CancelRemoteInspectionButton />
            </Buttons>

            <BarSpace />

            <RemoteInspectionsPager />
            <ConfigureRemoteInspectionOptionsButton />
            <ScheduleRemoteInspectionsByCsvButton />
        </Bar>
    )
}

const RemoteInspectionBarVisibility = withVisibility()(RemoteInspectionBarBase)

export default function RemoteInspectionBar() {
    const {isPermitted} = usePermission(bar)

    return <RemoteInspectionBarVisibility isVisible={isPermitted} />
}
