import React, {useMemo} from 'react'

import __ from 'utils/i18n'
import PanelBillingPlan from './PanelBillingPlan'
import {NO_PLAN_ID} from 'constants/billingPlan'

export default function PanelBillingAllPlans({
    panelId,
    availablePlans,
    currentPlanId,
    requestedPlanId,
}) {
    const availablePlansWithoutNoPlan = useMemo(
        () => availablePlans.filter(({id}) => id !== NO_PLAN_ID),
        [availablePlans]
    )

    return (
        <div className="panelBillingAllPlans">
            <h4 className="panelBillingAllPlans__title">{__('All plans')}</h4>
            <div className="panelBillingAllPlans__items">
                {availablePlansWithoutNoPlan.map((plan) => (
                    <PanelBillingPlan
                        id={plan.id}
                        key={plan.id}
                        name={plan.name}
                        panelId={panelId}
                        price={plan.price}
                        description={plan.description}
                        active={plan.id === currentPlanId}
                        requested={plan.id === requestedPlanId}
                    />
                ))}
            </div>
        </div>
    )
}
