import {handleActions} from 'redux-actions'

import * as actions from './actions'

const defaultState = {
    cellularConnected: {
        isLoading: false,
        error: null,
        data: {
            isAutoEnrollmentEnabled: undefined,
            messageBroker: undefined,
            retriesMax: undefined,
            retryInterval: undefined,
            wakeupOnUserDiscovery: undefined,
        },
    },

    broadbandConnected: {
        isLoading: false,
        error: null,
        data: {
            isAutoEnrollmentEnabled: undefined,
        },
    },

    commonConnected: {
        isLoading: false,
        error: null,
        data: {
            systemIdRegex: undefined,
            isPanelRemoveForever: undefined,
        },
    },

    supervision: {
        isLoading: false,
        error: null,
        data: {
            doSendEmailOnOnlineOffline: undefined,
            doSendSmsOnOnlineOffline: undefined,
            doSendOneChannelPanelOnlineOffline: undefined,
            doSendTwoChannelPanelOnlineOffline: undefined,
        },
    },

    remoteInspection: {
        isLoading: false,
        error: null,
        data: {
            doSendEmailOnSuccess: undefined,
            doGenerateResultEvent: undefined,
        },
    },

    interactiveSession: {
        isLoading: false,
        error: null,
        data: {
            doAutoEnableUserApp: undefined,
            doAutoEnableConfiguratorApp: undefined,
            isDlsServiceEnabled: undefined,
            globalServerApptype: undefined,
            maxInteractiveConnections: undefined,
            maxInteractiveConnectionsPerPanel: undefined,
            doRequireUserInstallerApproval: undefined,
        },
    },

    userNotifications: {
        isLoading: false,
        error: null,
        data: {
            isEmailWithVideoEnabled: undefined,
            isEmailWithoutVideoEnabled: undefined,
            messageBroker: undefined,
        },
    },

    advertisement: {
        isLoading: false,
        error: null,
        data: {
            url: undefined,
        },
    },

    messageBrokers: {
        isLoading: false,
        error: null,
        data: [
            /*
                {
                    id: undefined,
                    name: undefined,
                    description: undefined,
                    sender: undefined,
                    login: undefined,
                    password: undefined,
                    host: undefined,
                    port: undefined,
                    useTLS: undefined,
                    messageTemplate: undefined,
                    serial: undefined,
                },
            */
        ],
    },

    serialPorts: {
        isLoading: false,
        error: null,
        data: [
            /*
                        {
                            id: undefined,
                            name: undefined,
                            speed: undefined,
                            databits: undefined,
                            parity: undefined,
                            stopbits: undefined,
                            application: undefined,
                        },
        */
        ],
    },

    license: null,

    authorizationSettings: {
        isLoading: false,
        error: null,
        data: {
            first: {
                maxAttemptsCount: 0,
                timeout: 0,
                expirationPeriod: 0,
            },
            second: {
                maxAttemptsCount: 0,
                timeout: 0,
                expirationPeriod: 0,
            },
            email: {
                codeLength: 0,
                expirationPeriod: 0,
            },
            userLifetime: 0,
            installerAllowedToRemovePanels: false,
        },
    },

    rotation: {
        isLoading: false,
        error: null,
        data: {
            maxEventAge: 0,
            maxProcessAge: 0,
            maxReportsAge: 0,
            maxRuleEngineLogs: 0,
        },
    },

    mfa: {
        isLoading: false,
        error: null,
        data: {
            mfaEnabled: false,
            minute: 0,
            otpTtl: 0,
            otpLength: 0,
            resendTimeout: 0,
            resendCount: 0,
            verificationSessionTimes: 0,
            maxAttempts: 0,
            blockTimeout: 0,
            overallBlockTimeout: 0,
        },
    },

    googleSettings: {
        isLoading: false,
        error: null,
        data: {
            token: null,
        },
    },

    pgmActivationTimeSlot: {
        isLoading: false,
        error: null,
        data: {
            enabled: null,
            timeWindow: null,
            automationDevicesEnabled: null,
        },
    },
}

function genericFetch(state, key) {
    return {
        ...state,
        [key]: {
            ...state[key],
            isLoading: true,
        },
    }
}

function genericUpdate(state, {payload, error}, key) {
    if (error) {
        return {
            ...state,
            [key]: {
                ...state[key],
                isLoading: false,
                error: error,
            },
        }
    }
    if (Array.isArray(payload)) {
        return {
            ...state,
            [key]: {
                ...state[key],
                isLoading: false,
                error: null,
                data: [...payload],
            },
        }
    }
    return {
        ...state,
        [key]: {
            ...state[key],
            isLoading: false,
            error: null,
            data: {
                ...payload,
            },
        },
    }
}

export default handleActions(
    {
        [actions.fetchCellularConnected](state) {
            return genericFetch(state, 'cellularConnected')
        },
        [actions.updateCellularConnected](state, data) {
            return genericUpdate(state, data, 'cellularConnected')
        },
        [actions.fetchBroadbandConnected](state) {
            return genericFetch(state, 'broadbandConnected')
        },
        [actions.updateBroadbandConnected](state, data) {
            return genericUpdate(state, data, 'broadbandConnected')
        },
        [actions.fetchCommonConnected](state) {
            return genericFetch(state, 'commonConnected')
        },
        [actions.updateCommonConnected](state, data) {
            return genericUpdate(state, data, 'commonConnected')
        },
        [actions.fetchSupervision](state) {
            return genericFetch(state, 'supervision')
        },
        [actions.updateSupervision](state, data) {
            return genericUpdate(state, data, 'supervision')
        },
        [actions.fetchRemoteInspection](state) {
            return genericFetch(state, 'remoteInspection')
        },
        [actions.updateRemoteInspection](state, data) {
            return genericUpdate(state, data, 'remoteInspection')
        },
        [actions.fetchInteractiveSession](state) {
            return genericFetch(state, 'interactiveSession')
        },
        [actions.updateInteractiveSession](state, data) {
            return genericUpdate(state, data, 'interactiveSession')
        },
        [actions.fetchUserNotifications](state) {
            return genericFetch(state, 'userNotifications')
        },
        [actions.updateUserNotifications](state, data) {
            return genericUpdate(state, data, 'userNotifications')
        },
        [actions.fetchAdvertisement](state) {
            return genericFetch(state, 'advertisement')
        },
        [actions.updateAdvertisement](state, data) {
            return genericUpdate(state, data, 'advertisement')
        },
        [actions.fetchMessageBrokers](state) {
            return genericFetch(state, 'messageBrokers')
        },
        [actions.updateMessageBrokers](state, data) {
            return genericUpdate(state, data, 'messageBrokers')
        },
        [actions.addMessageBrokerToStore](state, {payload}) {
            return {
                ...state,
                messageBrokers: {
                    ...state.messageBrokers,
                    data: [...state.messageBrokers.data, {...payload}],
                },
            }
        },
        [actions.removeMessageBrokerFromStore](state, {payload: {brokerId}}) {
            return {
                ...state,
                messageBrokers: {
                    ...state.messageBrokers,
                    data: state.messageBrokers.data.filter(
                        (broker) => broker.id !== brokerId
                    ),
                },
            }
        },
        [actions.saveMessageBrokerInStore](state, {payload}) {
            let brokers = [...state.messageBrokers.data]
            const brokerIndex = state.messageBrokers.data.findIndex(
                (broker) => broker.id === payload.id
            )
            if (brokerIndex === -1) {
                brokers.push(payload)
            } else {
                brokers[brokerIndex] = payload
            }
            return {
                ...state,
                messageBrokers: {
                    ...state.messageBrokers,
                    data: brokers,
                },
            }
        },
        [actions.fetchSerialPorts](state) {
            return genericFetch(state, 'serialPorts')
        },
        [actions.updateSerialPorts](state, data) {
            return genericUpdate(state, data, 'serialPorts')
        },
        [actions.updateLicense](state, {payload}) {
            return {
                ...state,
                isLoadingLicense: false,
                license: payload,
            }
        },
        [actions.fetchLicense](state) {
            return {
                ...state,
                isLoadingLicense: true,
                license: null,
            }
        },
        [actions.updateServerVersion](state, {payload}) {
            return {
                ...state,
                serverVersion: payload,
            }
        },
        [actions.fetchServerVersion](state) {
            return {
                ...state,
                serverVersion: null,
            }
        },
        [actions.setGoogleAuthFailed](state) {
            return {
                ...state,
                googleAuthFailed: true,
            }
        },
        [actions.fetchAuthorizationSettings](state) {
            return genericFetch(state, 'authorizationSettings')
        },
        [actions.updateAuthorizationSettings](state, data) {
            return genericUpdate(state, data, 'authorizationSettings')
        },
        [actions.fetchRotation](state) {
            return genericFetch(state, 'rotation')
        },
        [actions.updateRotation](state, data) {
            return genericUpdate(state, data, 'rotation')
        },
        [actions.fetchMFA](state) {
            return genericFetch(state)
        },
        [actions.updateMFA](state, data) {
            return genericUpdate(state, data, 'mfa')
        },
        [actions.fetchGoogleSettings](state) {
            return genericFetch(state, 'googleSettings')
        },
        [actions.updateGoogleSettings](state, data) {
            return genericUpdate(state, data, 'googleSettings')
        },
        [actions.fetchPgmActivationTimeSlot](state) {
            return genericFetch(state, 'pgmActivationTimeSlot')
        },
        [actions.updatePgmActivationTimeSlot](state, data) {
            return genericUpdate(state, data, 'pgmActivationTimeSlot')
        },
    },
    defaultState
)
