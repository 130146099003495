import React from 'react'

import {CONFIGURATION_DOWNLOAD} from 'constants/features'
import {useActions} from 'modules/higherOrder/useActions'
import {withSelectionFeature} from 'containers/withFeature'
import {refreshConfiguration} from 'modules/runners/store/actions'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'
import {DownloadConfigurationBase} from 'pages/Panels/GridBar/DropDowns/Items/DownloadConfigurationItem'

const DownloadConfigurationFeature = withSelectionFeature(
    CONFIGURATION_DOWNLOAD,
    selectSelectedRunnersFeature
)(DownloadConfigurationBase)

export default function DownloadConfigurationItem() {
    const onClick = useActions(refreshConfiguration)

    return <DownloadConfigurationFeature onClick={onClick} />
}
