export default class InteractiveUserPanelsById {
    panelIds
    isLoading
    error

    constructor({isLoading = false, error = null, panels = []}) {
        this.isLoading = isLoading
        this.error = error
        this.panels = panels
    }
}
