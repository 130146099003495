import React, {useMemo} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import {stateIcon, stateTitle, stateModifierTitle} from 'constants/state'

import {ReactComponent as Spinner} from 'ipmp-react-ui/icons/loader-grey.svg'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import DropDownButton from 'ipmp-react-ui/DropDownButton'
import {ALIGN_RIGHT, ALIGN_LEFT, ALIGN_BOTTOM} from 'ipmp-react-ui/DropDown'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import isRtl from 'ipmp-react-ui/_utils/isRtl'
import stopPropagation from 'utils/stopPropagation'
import classes from 'classnames'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {PARTITION_ALL} from 'constants/partitions'

import {selectStatesInProgress} from 'modules/panels/state/selectors'

const StateButton = ({
    modifiers,
    sendStateAction,
    name,
    active,
    disabled,
    partitionId,
}) => {
    const clickHandlers = useMemo(
        () =>
            modifiers.reduce(
                (acc, modifier) => ({
                    ...acc,
                    [modifier]: () => handleSetState(modifier),
                }),
                {}
            ),
        [modifiers]
    )

    const panelId = useRouterPanelId()
    const statesInProgress = useSelector((state) =>
        selectStatesInProgress({state, panelId})
    )

    const allPartitionsInProgress = useMemo(() => {
        if (PARTITION_ALL in statesInProgress) {
            const allStatesInProgress = get(statesInProgress, [PARTITION_ALL], {})
            for (let key in allStatesInProgress) {
                if (key === name && true === allStatesInProgress[key]) {
                    return true
                }
            }
        }

        return false
    }, [statesInProgress])

    const partitionStatesInProgress = get(statesInProgress, [partitionId], {})

    const isStateInProgress = useMemo(() => {
        return (
            partitionStatesInProgress !== {} &&
            (allPartitionsInProgress ||
                (name in partitionStatesInProgress &&
                    partitionStatesInProgress[name] === true))
        )
    }, [allPartitionsInProgress, partitionStatesInProgress])

    const handleSetState = (modifier = null) => sendStateAction(name, modifier)

    const handleDropdownSetState = (modifier) => clickHandlers[modifier]()

    const handleSetDefaultState = () => {
        handleSetState()
    }

    const Icon = isStateInProgress ? Spinner : stateIcon(name)

    return (
        <Buttons small flat primary={active} onClick={stopPropagation}>
            <Button
                Icon={Icon}
                onClick={handleSetDefaultState}
                disabled={disabled}
                className={classes({
                    'button-state-target': isStateInProgress,
                })}
            >
                {stateTitle(name)}
            </Button>

            {!disabled && modifiers.length > 0 && (
                <DropDownButton
                    align={(isRtl() ? ALIGN_LEFT : ALIGN_RIGHT) | ALIGN_BOTTOM}
                >
                    <Menu>
                        {modifiers.map((modifier) => (
                            <MenuItem
                                className="menu-item--uppercase"
                                key={modifier}
                                onClick={() => handleDropdownSetState(modifier)}
                            >
                                {stateModifierTitle(modifier)}
                            </MenuItem>
                        ))}
                    </Menu>
                </DropDownButton>
            )}
        </Buttons>
    )
}

StateButton.propTypes = {
    sendStateAction: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    modifiers: PropTypes.array.isRequired,
    active: PropTypes.bool.isRequired,
    disabled: PropTypes.bool,
    partitionId: PropTypes.number,
}

export default StateButton
