import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'

import Layout from 'ipmp-react-ui/Layout'
import FoldingCards, {FoldingCard} from 'ipmp-react-ui/FoldingCards'

import {set as isSetAllowed} from 'permissions/panel/state/actions'
import page from 'permissions/panel/state/page'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import {useActions} from 'modules/higherOrder/useActions'
import {sendState} from 'modules/panels/state/actions'
import {
    selectActivePartitions,
    selectActivePartitionsState,
} from 'modules/panels/state/selectors'

import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import PartitionStateDefinition from 'components/Panel/PartitionStateDefinition'
import PartitionStateButtons from 'components/Panel/PartitionStateButtons'
import PanelStateDevicesTools from './PanelStateDevicesTools'
import PartitionStateButton from './Buttons/PartitionStateButton'

function PanelState({isSetAllowed, partitions, partitionStates, sendState, panelId}) {
    const partitionTitle = (partition) => (
        <PartitionStateDefinition
            partition={partition}
            state={partitionStates[partition.id]}
        />
    )

    const stateButtons = (partition) => (
        <PartitionStateButtons
            state={partitionStates[partition.id]}
            partition={partition}
            sendState={sendState}
            disabled={!isSetAllowed}
            ButtonComponent={PartitionStateButton}
        />
    )

    return (
        <Layout className="panelState">
            <FoldingCards>
                {partitions.map((partition) => (
                    <FoldingCard
                        key={partition?.id}
                        title={partitionTitle(partition)}
                        headerContent={stateButtons(partition)}
                        className="panelState-partition"
                    >
                        <PanelStateDevicesTools panelId={panelId} partition={partition} />
                    </FoldingCard>
                ))}
            </FoldingCards>
        </Layout>
    )
}

PanelState.propTypes = {
    partitions: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
        })
    ).isRequired,
    sendState: PropTypes.func.isRequired,
}

const ConnectedPanelState = compose(
    withPermissionRejection(page),
    withLoader()
)(PanelState)

export default function PanelStateMain() {
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(isSetAllowed)
    const {sendStateAction} = useActions({
        sendStateAction: (...args) => sendState(panelId, ...args),
    })

    const {partitions, partitionStates, error, isLoading} = useSelector((state) => {
        const panel = get(state, ['panels', 'state', 'byIds', panelId])
        const {error} = panel || {}
        const partitions = selectActivePartitions(state, panelId)
        const partitionStates = selectActivePartitionsState(state, panelId)

        return {
            partitions,
            partitionStates,
            error,
            isLoading: !panel,
        }
    })

    return (
        <ConnectedPanelState
            error={error}
            panelId={panelId}
            isLoading={isLoading}
            partitions={partitions}
            isSetAllowed={isPermitted}
            sendState={sendStateAction}
            partitionStates={partitionStates}
        />
    )
}
