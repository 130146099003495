import moment from 'moment-timezone'
import timezones, {getTz} from 'utils/timezones'

const smartDateR = /{([-+]?\d+)?(day|month|year)(?::([-+]?\d+)(day|month|year))?}/
const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const myTimezone = moment.tz.guess()

function parseMoment(key, value) {
    if (moment.isMoment(value)) {
        const from = moment.tz(value?._i?.from || value, myTimezone).startOf('day')
        const to = moment.tz(value?._i?.to || value, myTimezone).endOf('day')

        return [
            filterField(key, moment.tz(from, getTz()).format(dateFormat), '>='),
            filterField(key, moment.tz(to, getTz()).format(dateFormat), '<='),
        ].join('&&')
    }
}

function parseDateObject(key, value) {
    if (value instanceof Object) {
        let result = []

        if (value.from) {
            const from = moment.isMoment(value.from)
                ? value.from.tz(myTimezone).startOf('day')
                : moment.tz(value.from, myTimezone).startOf('day')
            const fromServer = moment.tz(from, getTz()).format(dateFormat)

            result.push(filterField(key, fromServer, '>='))
        }

        if (value.to) {
            const to = moment.isMoment(value.to)
                ? value.to.tz(myTimezone).endOf('day')
                : moment.tz(value.to, myTimezone).endOf('day')
            const toServer = moment.tz(to, getTz()).format(dateFormat)

            result.push(filterField(key, toServer, '<='))
        }

        return result.join('&&')
    }
}

function parseArray(key, value, comparator = '=') {
    const matches = smartDateR.exec(value)
    if (Array.isArray(value) && !matches) {
        return value
            .map((v) => filterField(key, moment.isDate(v) || v, comparator))
            .join('||')
    }
}

function parseSmartDateMatches(matches) {
    const from = timezones.server().add(matches[1], matches[2])
    const to = timezones.server().add(matches[3], matches[4])

    return {
        from: moment.min(from, to),
        to: moment.max(from, to),
    }
}

function parseSmartDate(key, value) {
    const matches = smartDateR.exec(value)

    if (matches) {
        return filterField(key, parseSmartDateMatches(matches))
    }
}

function parseSmart(key, value) {
    const matches = /^{([!><=]+|:)(.*)}$/.exec(value)

    if (matches) {
        return filterField(key, matches[2], matches[1])
    }
}

function parseRange(key, value) {
    const matches = /^{(\d+):(\d+)}$/.exec(value)

    if (matches) {
        return [
            filterField(key, --matches[1], '>'),
            filterField(key, ++matches[2], '<'),
        ].join(' && ')
    }
}

function parseNull(key, value) {
    if (value === null || value === undefined) {
        return ` :${key}!!; `
    }
}

function filterField(key, value, comparator = '=') {
    return (
        parseArray(key, value, comparator) ||
        parseMoment(key, value) ||
        parseDateObject(key, value) ||
        parseSmart(key, value) ||
        parseSmartDate(key, value, comparator) ||
        parseNull(key, value, comparator) ||
        parseRange(key, value) ||
        ` :${key}${comparator}${value}; `
    )
}

const isSmartDate = (value) => !!smartDateR.exec(value)
// const isDateObject = (value) => value instanceof Object && (value.from || value.to)

const isDateFilter = (value) =>
    isSmartDate(value) || moment.isMoment(value) /* || isDateObject(value)*/

function sortDataFilters(filters) {
    const dateFilters = []
    const restFilters = []

    filters.forEach((filter) => {
        if (isDateFilter(filter.value)) {
            dateFilters.push(filter)
        } else {
            restFilters.push(filter)
        }
    })

    dateFilters.sort((leftFilter, rightFilter) => {
        const normalLeftFilterValue = isSmartDate(leftFilter.value)
            ? parseSmartDateMatches(smartDateR.exec(leftFilter.value)).from
            : leftFilter.value
        const normalRightFilterValue = isSmartDate(rightFilter.value)
            ? parseSmartDateMatches(smartDateR.exec(rightFilter.value)).from
            : rightFilter.value

        return moment(normalLeftFilterValue).diff(normalRightFilterValue)
    })

    return [...restFilters, ...dateFilters]
}

export default function generateFilterString(obj, keysmap = {}, valuesMap = {}) {
    if (!obj) {
        return
    }

    if (Array.isArray(obj)) {
        obj = sortDataFilters(obj).reduce((acc, {name, value}) => {
            acc[name] = [...(acc[name] || []), value]
            return acc
        }, {})
    }

    const result = Object.keys(obj)
        .map((key) => {
            const value = valuesMap[key] ? valuesMap[key][obj[key]] : obj[key]
            return filterField(keysmap[key] || key, value)
        })
        .join('&&')

    return result.trim()
}
