import React from 'react'
import PropTypes from 'prop-types'

import Modal from 'ipmp-react-ui/Modal'
import CardMessage from 'ipmp-react-ui/CardMessage'
import {FormValidationGroup} from 'ipmp-react-ui/Form'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {resumeFaults} from 'modules/forms/handlers'
import {usePanelsRefresh} from 'hooks/pages/usePanels'
import withModalLoader from 'containers/withModalLoader'
import FaultChecklist from 'components/List/FaultChecklist'

const rules = {
    faultId: {
        presence: {
            message: () => __('You must select at least one fault to resume'),
        },
    },
}

const filterFault = (fault) => fault.isSuspended

function ResumeFaults({hide, panels}) {
    const {handle, isLoading, form} = useForm(resumeFaults)

    const handler = ({faultId}) => {
        if (!Array.isArray(faultId)) {
            faultId = [faultId]
        }

        handle(faultId.map((id) => parseInt(id)))
    }

    if (!panels.some((panel) => panel.faults && panel.faults.find(filterFault))) {
        return (
            <Modal onClose={hide}>
                <CardMessage
                    title={__('Resume Faults')}
                    message={__('No faults to resume found')}
                    onClose={hide}
                    onPositive={hide}
                />
            </Modal>
        )
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            onSubmit={handler}
            header={__('Resume Faults')}
            rules={rules}
            isLoading={isLoading}
        >
            <FormValidationGroup name="faultId">
                <FaultChecklist
                    panels={panels}
                    filter={filterFault}
                    emptyMessage={__('There are no resumable faults')}
                />
            </FormValidationGroup>
        </ModalCardForm>
    )
}

ResumeFaults.propTypes = {
    panels: PropTypes.arrayOf(
        PropTypes.shape({
            serial: PropTypes.string,
            account: PropTypes.string,
            faults: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string,
                    zone: PropTypes.number,
                    comment: PropTypes.string,
                    isSuspended: PropTypes.bool,
                })
            ),
        })
    ),
    hide: PropTypes.func.isRequired,
}

const ResumeFaultsWithLoader = withModalLoader(({refresh}) => refresh())(ResumeFaults)

export default function Resume({panelIds, hide}) {
    const {refresh, panels, isLoading} = usePanelsRefresh(panelIds)

    return (
        <ResumeFaultsWithLoader
            hide={hide}
            refresh={refresh}
            panels={panels}
            isLoading={isLoading}
        />
    )
}
