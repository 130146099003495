import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const RemoteInspection = () => {
    const {doSendEmailOnSuccess, doGenerateResultEvent} = useSelector(
        (state) => state.system.settings.remoteInspection.data
    )

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Send Email to the Customer of Succeed RI')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doSendEmailOnSuccess)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__("Generate 'Succeed/Failed RI' Event")}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(doGenerateResultEvent)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default RemoteInspection
