import React from 'react'
import {useSelector} from 'react-redux'

import {ReactComponent as PauseIcon} from 'ipmp-react-ui/icons/pause.svg'

import {__} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import {setEnabled} from 'modules/users/store/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {selectUsersListRows} from 'modules/users/list/selectors'
import {suspend as suspendPermission} from 'permissions/users/selection'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'

const selectedUsers = (selection) => {
    const selected = selection.toJS()
    const {rows} = useSelector(selectUsersListRows)

    return rows.filter((user) => {
        return selected.includes(user.id) && user.isEnabled
    })
}

export default function SuspendUserButton() {
    const {selection} = useSelection()
    const suspend = useActions(setEnabled)
    const selected = selectedUsers(selection)
    const {isPermitted} = usePermission(suspendPermission)

    if (selected.length === 0) {
        return null
    }

    const isMassiveAction = selected.length > 1
    const userSelected = !isMassiveAction && selected[0]

    const getMessage = () => {
        return isMassiveAction
            ? __('Do you really want to suspend %d selected users?', selected.length)
            : __('Do you really want to suspend the user: %s?', userSelected.name)
    }
    const onEnabled = () => {
        suspend(selection.toJS(), false)
    }

    if (!isPermitted) {
        return null
    }

    return (
        <WithConfirmationButton
            onClick={onEnabled}
            message={getMessage()}
            title={__('Suspend users')}
            Icon={PauseIcon}
            label={__('Suspend')}
        />
    )
}
