import React from 'react'

import __ from 'utils/i18n'
import list from 'permissions/groups/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {fetch, setQuery, clearFilters} from 'modules/groups/list/actions'

const SearchVisibility = withVisibility()(Search)

export default function GroupsSearch({className}) {
    const {isPermitted} = usePermission(list)

    const {apply, onQuery, onClear} = useActions({
        apply: fetch,
        onQuery: setQuery,
        onClear: clearFilters,
    })

    return (
        <SearchVisibility
            apply={apply}
            onQuery={onQuery}
            filters={[]}
            selected={[]}
            onClear={onClear}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Groups')}
            hideAutoCompleteAfterQuery
        />
    )
}
