import React from 'react'

import {ScrollView} from 'ipmp-react-ui/Layout'
import Page from 'ipmp-react-ui/Page'
import Layout from 'ipmp-react-ui/Layout'

import BatchesTable from './BatchesTable'
import BatchesGridBar from './BatchesGridBar'

export default class BatchesPage extends Page {
    renderBar() {
        return <BatchesGridBar />
    }

    renderContent() {
        return (
            <Layout className="batches-page">
                <ScrollView>
                    <BatchesTable />
                </ScrollView>
            </Layout>
        )
    }
}
