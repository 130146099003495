import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import StateTypeForm from './StateTypeForm'
import {validation} from './StateTypeFieldset'
import {editStateType} from 'modules/forms/handlers'

export default function EditStateType({stateTypeId, hide}) {
    const {handle, form, isLoading} = useForm(editStateType)
    const handler = (data) => handle({id: stateTypeId, ...data})
    const stateType = useSelector(({stateTypes}) => stateTypes.store.byIds[stateTypeId])

    return (
        <StateTypeForm
            {...form}
            hide={hide}
            handle={handler}
            data={stateType}
            rules={validation}
            isLoading={isLoading}
            header={__('Edit State Type')}
        />
    )
}
