import dataMapper from 'utils/dataMapper'

const mapPgmPartitions = (partitions) =>
    partitions
        ? {
              type: partitions.type,
              numbers: Object.keys(partitions.numbers).map(Number),
          }
        : null

export const mapHad = (data) => {
    return data.map(
        dataMapper((had) => ({
            id: had.id,
            name: had.name,
            userType: had.userType,
            deviceType: had.device_type,
            idWithDeviceType: `${had.id}_${had.device_type}`,
            subtype: had.subtype,
            state: had.state,
            location: had.location,
            partitions: mapPgmPartitions(had.partitions),
            supportedCommands: had.supportedCommands,
            hasTimer: Boolean(had.has_timer),
            isLabelEditable: Boolean(had.is_label_editable),
        }))
    )
}

export const keysMap = {
    mode: 'mode',
    target_1: 'temperature',
    target_2: 'temperature',
    dimmerLevel: 'level',
    label: 'label',
    icon: 'icon',
}

export const mapIsSwitchable = (data) => ({
    isSwitchable: Boolean(data.isSwitchable),
    timeSlot: Number(data.timeSlot),
    isTimeSlotEnabled: Boolean(data.isTimeSlotEnabled),
})
