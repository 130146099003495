import {selectPerPage} from 'modules/settings/selectors'
import {all, select, call, put, takeEvery} from 'redux-saga/effects'

import * as api from 'api/interactiveUsers'
import * as actions from './actions'

import {update, purge} from '../store/actions'

import listSaga from 'modules/higherOrder/createListSaga'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {POLL_INTERACTIVE_USERS} from 'configs/pollers'
import {registerInteractiveUser} from 'modules/forms/handlers'
import toIds from 'utils/toIds'
import {__} from 'utils/i18n'
import {snackShow} from 'modules/snacks'

const selector = (state) => ({
    ...state.interactiveUsers.list,
    perPage: selectPerPage(state, 'interactiveUsers'),
    listRoute: 'interactiveUsers',
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_INTERACTIVE_USERS, watchPoll),
        takeEvery(registerInteractiveUser.SUCCESS, watchInteractiveUserRegisterSuccess),
    ])
}

function* watchPoll() {
    const state = yield select(selector)

    const {rows, count} = yield call(api.fetch, state)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}

export function* watchInteractiveUserRegisterSuccess() {
    yield put(actions.fetch())
    yield put(snackShow(__('Customer was registered successfully')))
}
