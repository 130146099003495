import React, {PureComponent} from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import {useNavigate} from 'react-router'
import uniqid from 'uniqid'

import {saveSearchScope} from 'modules/settings/actions'

import ModalCardForm from 'ui/ModalCardForm'
import Input from 'ipmp-react-ui/Input'

import path from 'utils/path'
import {__} from 'utils/i18n'
import {useActions} from '../../modules/higherOrder/useActions'

class SaveSearch extends PureComponent {
    static propTypes = {
        page: PropTypes.any,
        navigate: PropTypes.func.isRequired,
        filter: PropTypes.any,
        scopes: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        hide: PropTypes.func.isRequired,
    }

    rules = {
        name: {
            presence: true,
        },
    }

    handle = ({name}) => {
        const {onSave, hide, page, navigate, filter, onClose} = this.props
        const scope = uniqid()

        onSave(page, scope, name, filter)
        hide()
        navigate(path(page, {scope}))
        onClose()
    }

    render() {
        const {hide} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                hide={hide}
                header={__('Save search')}
                rules={this.rules}
            >
                <Input autoFocus name="name" maxLength={30} label={__('Name')} />
            </ModalCardForm>
        )
    }
}

export default function SaveSearchScope({page, onClose, hide, filter}) {
    const navigate = useNavigate()
    const onSave = useActions(saveSearchScope)
    const scopes = useSelector((state) => state.settings.scopes[page])

    return (
        <SaveSearch
            page={page}
            hide={hide}
            scopes={scopes}
            onSave={onSave}
            filter={filter}
            onClose={onClose}
            navigate={navigate}
        />
    )
}
