import React from 'react'

import AccountsPage from './AccountsPage'
import {pathParent} from 'utils/path'

export default class {
    path = pathParent('accounts')

    element = (<AccountsPage />)
}
