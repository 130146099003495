import React from 'react'
import PropTypes from 'prop-types'

import {withPermissionVisibility} from 'containers/withPermission'
import {download} from 'permissions/panel/configuration/actions'

import configurationFindNode from 'utils/configuration/configurationFindNode'
import ConfigurationRows from 'components/Configuration/ConfigurationRows'

import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'

import {humanTime} from 'ipmp-react-ui/humanTime'
import {__} from 'utils/i18n'

const DownloadButton = withPermissionVisibility(download)(Button)

function DeviceConfiguration({sectionName, changes, configuration, onChange, refresh}) {
    const node = configuration && configurationFindNode(configuration, sectionName)

    if (!node) {
        return (
            <Error title={__('No configuration available for the given device type')} />
        )
    }

    return (
        <div className="blocks device-configuration">
            <div className="block">
                <div className="configuration-outdateNotice">
                    <h6 className="title title--medium">
                        {__('This information can be outdated!')}
                    </h6>

                    <div className="configuration-outdateNotice--content">
                        {__(
                            'Configuration was downloaded %s',
                            humanTime(configuration.timestamp)
                        )}
                    </div>

                    <DownloadButton onClick={refresh} flat>
                        {__('Refresh')}
                    </DownloadButton>
                </div>
            </div>

            <div className="hr" />

            <ConfigurationRows nodes={node.val} changes={changes} onChange={onChange} />
        </div>
    )
}

DeviceConfiguration.propTypes = {
    configuration: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    sectionName: PropTypes.string,
}

export default DeviceConfiguration
