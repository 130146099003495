import React from 'react'
import {__} from 'utils/i18n'
import {Tab} from 'ipmp-react-ui/Tabs'

import configuration from 'permissions/panel/configuration/page'

import {withConfigurationPreloader} from 'containers/withConfiguration'
import {withPermissionRejection} from 'containers/withPermission'
import {bindActionCreators, compose} from 'redux'
import DeviceConfiguration from 'components/Configuration/DeviceConfiguration'
import withLoader from 'containers/withLoader'
import isNil from 'lodash-es/isNil'
import {useDispatch, useSelector} from 'react-redux'
import {selectCurrentConfiguration} from 'modules/panels/configuration/selectors'
import {
    changeValue,
    refresh,
    refreshDevice,
    refreshRunner,
    fetch,
} from 'modules/panels/configuration/actions'
import {startPollRunnerData, stopPollRunnerData} from 'modules/runners/actions'
import configurationFindNode from 'utils/configuration/configurationFindNode'
import useRunnerPoll from 'hooks/useRunnerPoll'

const DeviceConfigurationConnected = compose(
    withLoader(({outdated, fetch}) => {
        !outdated && fetch()
    }),
    withConfigurationPreloader({
        buttonProps: {primary: true},
        isErrorCallback: ({configuration, sectionName}) => {
            const node =
                configuration && configurationFindNode(configuration, sectionName)

            return isNil(configuration) || !node
        },
    })
)(DeviceConfiguration)

const ConfigurationTab = ({panelId, deviceType, zone, ...props}) => {
    const currentConfiguration = useSelector((state) =>
        selectCurrentConfiguration(state, {panelId})
    )
    const dispatch = useDispatch()

    props = {
        panelId, // node,

        ...bindActionCreators(
            {
                fetch: () => fetch(panelId),
                refresh: () =>
                    zone ? refreshDevice({panelId, deviceType, zone}) : refresh(panelId),
                onChange: (...arg) => changeValue(panelId, ...arg),
                startPollRunnerData,
                stopPollRunnerData,
                refreshRunner,
            },
            dispatch
        ),
        ...currentConfiguration,
        ...props,
    }

    useRunnerPoll(props)

    return (
        <Tab name={__('Configuration')}>
            <DeviceConfigurationConnected {...props} />
        </Tab>
    )
}

export default withPermissionRejection(configuration)(ConfigurationTab)
