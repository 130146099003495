import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {
    selectProcessesList,
    selectProcessesListRows,
} from 'modules/processes/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/processes/list/actions'
import {stop} from 'modules/processes/store/actions'

export default function useProcesses() {
    const {onPerPageChange, perPage} = usePerPage('processes')
    const {isLoading, error, total, start} = useSelector(selectProcessesList)

    return {
        onPerPageChange,
        perPage,
        isLoading,
        error,
        total,
        start,
        rows: useSelector(selectProcessesListRows),
        ...useActions({
            init,
            reset,
            setStart,
            fetch,
            onPageChange: setStart,
            stop,
            startPoll,
            stopPoll,
        }),
    }
}
