import {useDispatch, useSelector} from 'react-redux'
import {bindActionCreators} from 'redux'

import * as panelInteractiveUsersActions from 'modules/panelInteractiveUsers/list/actions'
import * as actions from 'modules/interactiveUsers/list/actions'
import {suspend, enable} from 'modules/interactiveUsers/store/actions'
import {selectPanelInteractiveUsersFiltered} from 'modules/panelInteractiveUsers/store/selectors'
import {selectInteractiveUsersByPage} from 'modules/interactiveUsers/store/selectors'

import useRouterPanelId from '../useRouterPanelId'

export default function useInteractiveUsers() {
    const dispatch = useDispatch()

    const {rows, isLoading, error, total} = useSelector(selectInteractiveUsersByPage)

    const {init, fetch, reset, startPoll, stopPoll} = bindActionCreators(
        {
            init: actions.init,
            fetch: actions.fetch,
            reset: actions.reset,
            startPoll: actions.startPoll,
            stopPoll: actions.stopPoll,
        },
        dispatch
    )

    return {
        rows,
        isLoading,
        error,
        init,
        fetch,
        reset,
        startPoll,
        stopPoll,
        total,
    }
}

export function usePanelInteractiveUsers() {
    const dispatch = useDispatch()
    const panelId = useRouterPanelId()

    return {
        rows: useSelector((state) =>
            selectPanelInteractiveUsersFiltered(state, {panelId})
        ),
        ...bindActionCreators(
            {
                init: panelInteractiveUsersActions.init,
                reset: panelInteractiveUsersActions.reset,
                startPoll: panelInteractiveUsersActions.startPoll,
                stopPoll: panelInteractiveUsersActions.stopPoll,
                suspend,
                enable,
            },
            dispatch
        ),
    }
}
