import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {selectRunnersList} from 'modules/runners/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/runners/list/actions'
import {stop} from 'modules/runners/store/actions'

export default function useRunners() {
    const {onPerPageChange, perPage} = usePerPage('runners')
    const {rows, isLoading, error, total, start, disabledRowsIds, stoppingIds} =
        useSelector(selectRunnersList)

    return {
        perPage,
        onPerPageChange,
        rows,
        isLoading,
        error,
        total,
        start,
        disabledRowsIds,
        stoppingIds,
        ...useActions({
            init,
            reset,
            setStart,
            fetch,
            onPageChange: setStart,
            startPoll,
            stopPoll,
            stop,
        }),
    }
}
