import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconPin} from 'ipmp-react-ui/icons/autolearn/Pin.svg'

import {DELIM} from 'constants/device'
import __ from 'utils/i18n'

import DskReportFormFieldset from './DskReportFormFieldset'

export default function DskReportForm({dsk, setCode}) {
    const [compound, setCompound] = useState([])

    useEffect(() => {
        const num = +compound.join('')
        setCode([(num & 0xff00) >> 8, num & 0xff].map((x) => (x << 24) >> 24))
    }, [compound, setCode])

    const onChange = (index, value) => {
        setCompound(
            Array.from({length: 5}, (v, i) => (i === index ? value : compound[i]))
        )
    }

    return (
        <>
            <div className="autolearn-in-process">
                <IconPin />
                <div className="autolearn-description-content-wrapper">
                    <div className="autolearn-description-content-title">
                        {__('Panel requires to confirm DSK')}
                    </div>
                    <div className="autolearn-description-content-message">
                        {__(
                            'Enter the first five digits of the code shown on the device'
                        )}
                    </div>
                </div>
            </div>
            <div className="autolearn-device-card-wrapper">
                <div className="autolearn-zwave-pin-template">
                    <div className="hidden-part">XXXXXX</div>
                    <div className="open-part">
                        {DELIM + dsk?.filter((i) => i > 0).join(DELIM)}
                    </div>
                </div>
                <DskReportFormFieldset onChange={onChange} />
            </div>
        </>
    )
}

DskReportForm.propTypes = {
    dsk: PropTypes.array,
    setCode: PropTypes.func.isRequired,
}
