import createRowsResponseParser from 'api/base/createRowsResponseParser'
import moment from 'moment-timezone'

import {get} from 'api/http'
import getProperty from 'lodash-es/get'
import has from 'lodash-es/has'

import {keysMap, mapActionLog} from 'api/actionLog.map'
import {getTz} from '../utils/timezones'

const dateFormat = 'YYYY-MM-DD HH:mm:ss'
const myTimezone = moment.tz.guess()

/** implements another filter logic, cuz action log api not work like other lists */
function generateActionLogFilters(filters) {
    return filters.reduce((acc, item) => {
        const name = item.name
        let value = has(item, 'key') ? item.key : item.value

        const matches =
            /{([-+]?\d+)?(day|month|year)(?::([-+]?\d+)(day|month|year))?}/.exec(value)

        if (matches) {
            const from = moment().add(matches[1], matches[2])
            const to = moment().add(matches[3], matches[4])

            value = {
                from: moment.min(from, to),
                to: moment.max(from, to),
            }
        }

        if (moment.isMoment(value)) {
            value = {
                from: value,
                to: value,
            }
        }

        if (value && (value.from || value.to)) {
            const fromKey = `filter[${keysMap[name] || name}][from]`
            const toKey = `filter[${keysMap[name] || name}][to]`

            const fromDate = value.from.tz(myTimezone).startOf('day')
            const toDate = moment.tz(value.to, myTimezone).endOf('day')

            return {
                ...acc,
                [fromKey]: moment.tz(fromDate, getTz()).format(dateFormat),
                [toKey]: moment.tz(toDate, getTz()).format(dateFormat),
            }
        }

        // hack for select user with null id, like default super admin
        if (name === 'users' && value === null) {
            value = 'null'
        }

        const key = `filter[${keysMap[name] || name}][]`

        return {
            ...acc,
            [key]: [...(acc[key] || []), value],
        }
    }, {})
}

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateActionLogFilters(filters)

    return get('/actionlog/list', {start, count, ...filter, search: query}).then(
        createRowsResponseParser(mapActionLog)
    )
}

function mapRowsToValues(data) {
    const values = getProperty(data, 'rows')
    delete data.rows
    return {...data, values}
}

function suggestsUsers() {
    return get('actionlog/users')
        .then(
            createRowsResponseParser((data) => ({
                key: data.usr_id,
                value: data.usr_name,
            }))
        )
        .then(mapRowsToValues)
}

function suggestsStatuses() {
    return get('actionlog/statuses')
        .then(createRowsResponseParser())
        .then(mapRowsToValues)
}

function suggestsActivities() {
    return get('actionlog/activities')
        .then(createRowsResponseParser())
        .then(mapRowsToValues)
}

export function suggest() {
    return Promise.all([suggestsUsers(), suggestsStatuses(), suggestsActivities()]).then(
        (data) => ({
            users: data[0],
            statuses: data[1],
            activities: data[2],
        })
    )
}
