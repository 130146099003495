import {all, put, call, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/about'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import watch from 'modules/higherOrder/watch'
import {POLL_TIME_INFO} from 'configs/pollers'

export default function* () {
    yield all([
        createListPollerSaga(actions, POLL_TIME_INFO, fetch),
        yield takeEvery(actions.fetch, watch(fetch)),
    ])
}

function* fetch() {
    const data = yield call(api.fetchServerTime)
    yield put(actions.receive(data))
}

fetch.onError = function* (error) {
    yield put(actions.receive(error))
}
