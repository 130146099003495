import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const fetch = createAction(
    'INTERACTIVE_USERS/AUTOCOMPLETE/FETCH',
    (prefix = '', count) => ({prefix, count})
)
export const setLoading = createAction(
    'INTERACTIVE_USERS/AUTOCOMPLETE/SET_LOADING',
    (isLoading) => ({isLoading})
)

export const update = createAction('INTERACTIVE_USERS/AUTOCOMPLETE/UPDATE', toArray)
export const clear = createAction('INTERACTIVE_USERS/AUTOCOMPLETE/CLEAR')
