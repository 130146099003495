import {all, takeEvery, call, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import * as api from 'api/group'
import * as actions from './actions'
import {editEventsProfileEndUser} from 'modules/forms/handlers'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(editEventsProfileEndUser.SUCCESS, watchEditProfiles),
    ])
}

function* watchFetch({payload: id}) {
    try {
        const endUserNotifications = yield call(api.receiveGroupNotificationsEndUser, id)

        yield put(
            actions.receive({
                notifications: {
                    ...endUserNotifications,
                    availableProfiles: {
                        ...endUserNotifications.availableProfiles,
                    },
                },
                id,
            })
        )
    } catch (error) {
        yield put(actions.receive(error))
        yield put(snackShow(error.message))
    }
}

function* watchEditProfiles({meta}) {
    try {
        yield put(actions.update({...meta}))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
