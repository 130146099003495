import {useState} from 'react'
import {useSelector} from 'react-redux'

import {selectToken} from 'modules/devices/autolearn/selectors'

import useIdleScreen from './Idle'
import usePowerG from './PowerG'
import useZWave from './ZWaive'
import useSessionScreen from './SessionScreen'

export default function useScreens(session, onDismiss, automationProtocols) {
    const token = useSelector((state) => selectToken(state, {session}))
    const [protocol, setProtocol] = useState(
        undefined === session?.protocol ? automationProtocols[0] : session.protocol
    )

    session.token = token
    session.protocol = protocol

    const idle = useIdleScreen(session, setProtocol, onDismiss, automationProtocols)
    const sessionScreen = useSessionScreen(session)
    const powerG = usePowerG(session)
    const zWave = useZWave(session)

    return powerG || zWave || sessionScreen || idle
}
