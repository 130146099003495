import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {showEditNotificationTypeModal} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function EditNotificationType({row: {id}}) {
    const {showEditNotificationType} = useActions({
        showEditNotificationType: showEditNotificationTypeModal,
    })

    const onClick = () => showEditNotificationType(id)

    return <Button Icon={IconEdit} flat small primary onClick={onClick} />
}
