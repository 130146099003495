import React from 'react'
import classnames from 'classnames'

export class DeviceActionsWrapper extends React.Component {
    wrapperClassName = 'deviceActionsWrapper'

    element = null

    firstMount = true

    state = {
        isEmpty: false,
    }

    onRef = (element) => {
        if (!element) {
            return
        }

        this.element = element

        const isEmpty = !element.children.length

        if (isEmpty) {
            this.setState({isEmpty: true})
        } else {
            const isChildrenIsOnlyNestedWrappers = Array.prototype.every.call(
                element.children,
                (element) => element.classList.contains(this.wrapperClassName)
            )

            if (isChildrenIsOnlyNestedWrappers) {
                const isNestedWrappersEmpty = this.isNestedWrappersEmpty(element.children)
                if (isNestedWrappersEmpty) {
                    this.setState({isEmpty: true})
                }
            }
        }
    }

    isNestedWrappersEmpty = (children) => {
        return Array.prototype.every.call(children, (element) => {
            return element.children.length === 0
        })
    }

    render() {
        const {children, className} = this.props
        const {isEmpty} = this.state

        if (!this.firstMount && isEmpty) {
            return null
        }

        if (this.firstMount) {
            this.firstMount = false
        }

        return (
            <div
                ref={this.onRef}
                className={classnames(this.wrapperClassName, className)}
            >
                {children}
            </div>
        )
    }
}

export default DeviceActionsWrapper
