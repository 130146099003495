import {takeEvery, put, call} from 'redux-saga/effects'

import * as api from 'api/roles'
import * as actions from './actions'
import {snackShow} from 'modules/snacks'
import {editRolePermissions} from 'modules/forms/handlers'

export default function* () {
    yield takeEvery(actions.fetch, watchFetch)
    yield takeEvery(editRolePermissions.FAILURE, watchEditRolePermissionFail)
}

function* watchFetch({payload: roleId}) {
    try {
        const permissions = yield call(api.fetchPermissions, roleId)
        yield put(actions.receive(permissions, roleId))
    } catch (error) {
        yield put(actions.receive(error, roleId))
    }
}

function* watchEditRolePermissionFail({payload: {errors}}) {
    yield put(snackShow(errors.permissions))
}
