import {createAction} from 'redux-actions'

export const startSession = createAction('DEVICES/AUTOLEARN/START_SESSION')
export const stopSession = createAction('DEVICES/AUTOLEARN/STOP_SESSION')
export const clearSession = createAction('DEVICES/AUTOLEARN/CLEAR_SESSION')

export const addMessage = createAction(
    'DEVICES/AUTOLEARN/ADD_MESSAGE',
    ({serial, protocol}, message) => ({serial, protocol, message})
)

export const setToken = createAction(
    'DEVICES/AUTOLEARN/SET_TOKEN',
    ({serial, protocol}, token) => ({serial, protocol, token})
)

export const startPoll = createAction('DEVICES/AUTOLEARN/START_POLL')

export const stopPoll = createAction('DEVICES/AUTOLEARN/STOP_POLL')

export const addDeviceComplete = createAction(
    'DEVICES/AUTOLEARN/ADD_DEVICE_COMPLETE',
    ({serial, protocol}, device) => ({serial, protocol, device})
)

export const requestAddSensorConfirmation = createAction(
    'DEVICES/AUTOLEARN/REQUEST_ADD_SENSOR_CONFIRMATION',
    ({serial, protocol}, message) => ({serial, protocol, message})
)

export const sendAddSensorConfirmation = createAction(
    'DEVICES/AUTOLEARN/SEND_ADD_SENSOR_CONFIRMATION'
)

export const completeAddSensorConfirmation = createAction(
    'DEVICES/AUTOLEARN/COMPLETE_ADD_SENSOR_CONFIRMATION',
    ({serial, protocol}, sensor) => ({serial, protocol, sensor})
)

export const requestZWaveKeysReport = createAction(
    'DEVICES/AUTOLEARN/REQUEST_ZWAVE_KEYS_REPORT',
    ({serial, protocol}, message) => ({serial, protocol, message})
)

export const sendZWaveKeysReport = createAction(
    'DEVICES/AUTOLEARN/SEND_ZWAVE_KEYS_REPORT'
)

export const completeZWaveKeysReport = createAction(
    'DEVICES/AUTOLEARN/COMPLETE_ZWAVE_KEYS_REPORT',
    ({serial, protocol}, nodeId) => ({serial, protocol, nodeId})
)

export const requestZWaveDskReport = createAction(
    'DEVICES/AUTOLEARN/REQUEST_ZWAVE_DSK_REPORT',
    ({serial, protocol}, message) => ({serial, protocol, message})
)

export const sendZWaveDskReport = createAction('DEVICES/AUTOLEARN/SEND_ZWAVE_DSK_REPORT')

export const completeZWaveDskReport = createAction(
    'DEVICES/AUTOLEARN/COMPLETE_ZWAVE_DSK_REPORT',
    ({serial, protocol}, data) => ({serial, protocol, data})
)
