import React from 'react'
import {POWER_MAX, UPGRADE_METHOD_BBA, UPGRADE_METHOD_GPRS} from 'constants/groups'
import Select, {Option} from 'ipmp-react-ui/Select'
import {__} from 'utils/i18n'
import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import Togglebox from 'ipmp-react-ui/ToggleBox'
import _get from 'lodash-es/get'
import Input from 'ipmp-react-ui/Input'
import {TabContentWrapper} from './TabContentWrapper'
import classes from 'classnames'

const PowerMaster = ({
    upgradeMethod,
    activeTab,
    handleFieldChanged,
    pmasterCommunications,
    BBAEncryption,
    errors,
}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={POWER_MAX}
            styleName={'power-max-content communications-content'}
        >
            <div className="col">
                <Select
                    name="upgradeMethod"
                    label={__('Power Master upgrade method')}
                    dataTestId="power-master-upgrade-method"
                    defaultValue={upgradeMethod}
                    defaultLabel={
                        upgradeMethod === UPGRADE_METHOD_GPRS
                            ? __('GRPS')
                            : __('Broadband')
                    }
                    onChange={(e, value) => {
                        handleFieldChanged('upgradeMethod', value)
                    }}
                >
                    <Option value={UPGRADE_METHOD_GPRS} label={__('Cellular')} />
                    <Option value={UPGRADE_METHOD_BBA} label={__('Broadband')} />
                </Select>
            </div>

            <div className="cols cols--stretch">
                <FormSection
                    className={classes('col', {
                        'group-form-section-with-error':
                            errors?.pmasterGprsTimeout || errors?.pmasterGprsSupervision,
                    })}
                    title={__('Cellular')}
                >
                    <Togglebox
                        name="pmasterGprsIsEnabled"
                        label={__('Supervision enabled')}
                        labelOff={__('Supervision disabled')}
                        dataTestId="pmaster-gprs-is-enabled"
                        defaultChecked={_get(
                            pmasterCommunications,
                            'gprs.isEnabled',
                            false
                        )}
                        onChange={(e) =>
                            handleFieldChanged(
                                'communications.pmaster.gprs.isEnabled',
                                e.target.checked
                            )
                        }
                    />
                    <FormRow>
                        <Input
                            name="pmasterGprsSupervision"
                            label={__('Supervision Period')}
                            dataTestId="pmaster-gprs-supervision"
                            type="number"
                            defaultValue={_get(
                                pmasterCommunications,
                                'gprs.supervision',
                                false
                            )}
                            disabled={
                                !_get(pmasterCommunications, 'gprs.isEnabled', false)
                            }
                            onChange={(e) => {
                                handleFieldChanged(
                                    'communications.pmaster.gprs.supervision',
                                    Number(e.target.value)
                                )
                            }}
                        />
                        <Input
                            name="pmasterGprsTimeout"
                            label={__('Offline Timer')}
                            dataTestId="pmaster-gprs-timeout"
                            type="number"
                            defaultValue={_get(
                                pmasterCommunications,
                                'gprs.timeout',
                                false
                            )}
                            disabled={
                                !_get(pmasterCommunications, 'gprs.isEnabled', false)
                            }
                            onChange={(e) => {
                                handleFieldChanged(
                                    'communications.pmaster.gprs.timeout',
                                    Number(e.target.value)
                                )
                            }}
                        />
                    </FormRow>
                </FormSection>
                <FormSection className="col" title={__('Broadband')}>
                    <Togglebox
                        name="pmasterBbaIsEnabled"
                        label={__('Encryption enabled')}
                        dataTestId="pmaster-bba-is-enabled"
                        labelOff={__('Encryption disabled')}
                        defaultChecked={BBAEncryption}
                        onChange={(e) => {
                            handleFieldChanged('BBAEncryption', e.target.checked)
                        }}
                    />
                    <FormRow>
                        <Input
                            name="pmasterBbaSupervision"
                            label={__('Supervision Period')}
                            dataTestId="pmaster-bba-supervision"
                            type="number"
                            defaultValue={_get(
                                pmasterCommunications,
                                'bba.supervision',
                                false
                            )}
                            onChange={(e) => {
                                handleFieldChanged(
                                    'communications.pmaster.bba.supervision',
                                    Number(e.target.value)
                                )
                            }}
                        />
                        <Input
                            name="pmasterBbaTimeout"
                            label={__('Offline Timer')}
                            dataTestId="pmaster-bba-timeout"
                            type="number"
                            defaultValue={_get(
                                pmasterCommunications,
                                'bba.timeout',
                                false
                            )}
                            onChange={(e) => {
                                handleFieldChanged(
                                    'communications.pmaster.bba.timeout',
                                    Number(e.target.value)
                                )
                            }}
                        />
                    </FormRow>
                </FormSection>
            </div>
        </TabContentWrapper>
    )
}

export default PowerMaster
