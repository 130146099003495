import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {
    HAD_TYPE_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_IQ_SMART_DOORLOCK,
    HAD_TYPE_LEEDARSON_DIMMER,
    HAD_TYPE_PG_PLUS_DOORLOCK,
    HAD_TYPE_PG_PLUS_WATER_VALVE,
    HAD_TYPE_PGM,
    HAD_TYPE_THERMOSTAT,
    HAD_TYPE_YALE_DOORLOCK_L2,
    HAD_TYPE_YALE_DOORLOCK_L3,
    HAD_TYPE_ZWAVE_WATER_VALVE,
} from 'constants/had'
import {warn} from 'utils/log'
import {getPanelId} from 'utils/panelIdMatchSelector'

import PgmControlForm from './DeviceControlForm/PgmControlForm'
import ThermostatControlForm from './DeviceControlForm/ThermostatControlForm'
import DimmerControlForm from './DeviceControlForm/DimmerControlForm'
import HadDisclaimer from './HadDisclaimer'
import DoorlockControlForm from './DeviceControlForm/DoorlockControlForm'
import WaterValveControlForm from './DeviceControlForm/WaterValveControlForm'

const deviceControlFormMap = new Map([
    [HAD_TYPE_PGM, PgmControlForm],
    [HAD_TYPE_DIMMER, DimmerControlForm],
    [HAD_TYPE_LEEDARSON_DIMMER, DimmerControlForm],
    [HAD_TYPE_THERMOSTAT, ThermostatControlForm],
    [HAD_TYPE_DOORLOCK, DoorlockControlForm],
    [HAD_TYPE_YALE_DOORLOCK_L2, DoorlockControlForm],
    [HAD_TYPE_YALE_DOORLOCK_L3, DoorlockControlForm],
    [HAD_TYPE_PG_PLUS_DOORLOCK, DoorlockControlForm],
    [HAD_TYPE_IQ_SMART_DOORLOCK, DoorlockControlForm],
    [HAD_TYPE_ZWAVE_WATER_VALVE, WaterValveControlForm],
    [HAD_TYPE_PG_PLUS_WATER_VALVE, WaterValveControlForm],
])

const deviceControlResolve = (hadType) => {
    if (deviceControlFormMap.has(hadType)) {
        return deviceControlFormMap.get(hadType)
    }

    warn(`No home automation device settings for type:${hadType}`)

    return deviceControlFormMap.get(HAD_TYPE_PGM)
}

const DeviceControlTab = ({had, disabled, setIsActiveSaveButton}) => {
    if (disabled) {
        return <HadDisclaimer />
    }

    const panelId = useSelector((state) => getPanelId(state))
    const DeviceControlForm = deviceControlResolve(had.subtype)

    return (
        <DeviceControlForm
            had={had}
            panelId={Number(panelId)}
            setIsActiveSaveButton={setIsActiveSaveButton}
        />
    )
}

DeviceControlTab.propTypes = {
    had: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    setIsActiveSaveButton: PropTypes.func,
}

export default DeviceControlTab
