import React from 'react'
import {GENERAL} from 'constants/groups'
import {FormSection} from 'ipmp-react-ui/Form'
import {__} from 'utils/i18n'
import Checkbox from 'ipmp-react-ui/Checkbox'
import Input from 'ipmp-react-ui/Input'
import _get from 'lodash-es/get'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchLanguages} from 'modules/languages/actions'
import Select from 'ipmp-react-ui/Select'
import {TabContentWrapper} from './TabContentWrapper'

const LanguageSelect = withSelectLoader(
    fetchLanguages,
    (store) => store.languages.isLoading,
    (store) =>
        Object.values(store.languages.byIds).map(({id, name}) => ({
            value: id,
            label: name,
        }))
)(Select)

const General = ({data, activeTab, handleFieldChanged}) => {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={GENERAL}
            styleName={'group-tab-general'}
        >
            <FormSection className="col" title={__('Interactive')}>
                <Checkbox
                    name="localWakeUp"
                    label={__('Local wake up')}
                    dataTestId="local-wake-up"
                    defaultChecked={data.localWakeUp}
                    onChange={(e) => {
                        handleFieldChanged('localWakeUp', e.target.checked)
                    }}
                />
                <LanguageSelect
                    name="serverMessagingLanguageId"
                    label={__('Server Messaging Language')}
                    dataTestId="server-messaging-language"
                    defaultValue={data.serverMessagingLanguageId}
                    defaultLabel={data.serverMessagingLanguage}
                    onChange={(e, value) => {
                        handleFieldChanged('serverMessagingLanguageId', Number(value))
                    }}
                />
                <Input
                    name="vodHistorySize"
                    label={__('Video On Demand History Size')}
                    dataTestId="vod-history-size"
                    defaultValue={data.vodHistorySize}
                    onChange={(e) => {
                        handleFieldChanged('vodHistorySize', Number(e.target.value))
                    }}
                />
            </FormSection>

            <FormSection className="col" title={__('Other')}>
                <Checkbox
                    name="timeSynchronization"
                    label={__("Panel's time synchronization")}
                    dataTestId="time-synchronization"
                    defaultChecked={data.timeSynchronization}
                    onChange={(e) => {
                        handleFieldChanged('timeSynchronization', e.target.checked)
                    }}
                />
                <Checkbox
                    name="allowUpgradeOverGprsDSCPanels"
                    label={__('Allow upgrade over Cellular for DSC panel')}
                    dataTestId="allow-upgrade-over-gprs-dsc-panels"
                    defaultChecked={data.allowUpgradeOverGprsDSCPanels}
                    onChange={(e) =>
                        handleFieldChanged(
                            'allowUpgradeOverGprsDSCPanels',
                            e.target.checked
                        )
                    }
                />
                <Checkbox
                    name="enableTemperatureAndLight"
                    label={__('Temperature/light statistic')}
                    dataTestId="enable-temperature-and-light"
                    defaultChecked={_get(data, 'enableTemperatureAndLight', false)}
                    onChange={(e) => {
                        handleFieldChanged('enableTemperatureAndLight', e.target.checked)
                    }}
                />
            </FormSection>
        </TabContentWrapper>
    )
}

export default General
