import React, {useState} from 'react'
import PanelsTable from './PanelsTable'
import PanelBillingBlind from '../../components/Panel/Billing/PanelBillingBlind'

const BILLING_BLIND = 'billing'

export default function PanelsWithBlinds(props) {
    const [row, setRow] = useState(null)
    const [activeBlind, setActiveBlind] = useState(null)

    const onOpenBillingBlind = (row) => {
        setRow(row)
        setActiveBlind(BILLING_BLIND)
    }

    const onCloseBlind = () => {
        setRow(null)
        setActiveBlind(null)
    }

    return (
        <>
            <PanelBillingBlind
                panel={{
                    id: row?.id,
                    serial: row?.serial,
                }}
                onChange={setRow}
                rows={props.rows}
                onClose={onCloseBlind}
                opened={BILLING_BLIND === activeBlind}
            />
            <PanelsTable
                {...props}
                active={{id: row && row?.id}}
                openBillingBlind={onOpenBillingBlind}
            />
        </>
    )
}
