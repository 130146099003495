import {createSelector} from 'reselect'

export const selectUpgradeStatusListRows = createSelector(
    (state) => state.upgradeStatus.list.page,
    (state) => state.upgradeStatus.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectUpgradeStatusList = createSelector(
    (state) => state.upgradeStatus.list,
    (list) => list
)
