import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import HadDeviceHint from 'components/Had/HadDevice/HadDeviceHint'
import HadDevicePartitions from 'components/Had/HadDevice/HadDevicePartitions'

import {
    HAD_CONFIGURABLE_ICON_ARM_MODE,
    HAD_CONFIGURABLE_ICON_ARM_TOGGLE,
    HAD_CONFIGURABLE_ICON_DISARM,
    hadConfigurableIcon,
    hadIcon,
    isHadIconConfigurable,
} from 'constants/had'
import HadDeviceState from 'components/Had/HadDevice/HadDeviceState'
import HadContext from 'pages/Panel/Had/HadContext'
import {HAD_STATE_OFF, HAD_STATE_ON, HAD_STATE_STATELESS} from 'constants/had/hadState'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

const HadDeviceGeneric = ({
    name,
    type,
    subtype,
    userType,
    id,
    idWithDeviceType,
    location,
    partitions,
    state,
    RightCornerComponent,
    onClick,
}) => {
    const onClickHandler = () => {
        onClick && onClick(idWithDeviceType)
    }
    const {selectedHadId} = useContext(HadContext)

    const isConfigurable = isHadIconConfigurable(type)

    const getIcon = () => {
        if (!userType && !isConfigurable) {
            const backgroundImage = `url(${hadIcon(subtype, type, state)})`

            return <div className="device-image-src" style={{backgroundImage}} />
        }

        return hadConfigurableIcon(userType, state)
    }

    return (
        <div
            className={classes('device deviceHad', {
                'device--active': selectedHadId === idWithDeviceType,
            })}
            onClick={onClickHandler}
        >
            <div
                className={classes('device-image', {
                    'deviceHad-ArmMode-type': userType === HAD_CONFIGURABLE_ICON_ARM_MODE,
                    'deviceHad-ArmToggle-type':
                        userType === HAD_CONFIGURABLE_ICON_ARM_TOGGLE,
                    'deviceHad-Disarm-type': userType === HAD_CONFIGURABLE_ICON_DISARM,
                    'deviceHad-enabled': state?.pgm === HAD_STATE_ON,
                    'deviceHad-disabled': state?.pgm === HAD_STATE_OFF,
                    'deviceHad-stateless': state?.pgm === HAD_STATE_STATELESS,
                    'deviceHad-simple-pgm': isHadIconConfigurable(type),
                })}
            >
                {getIcon()}
            </div>

            <div className="device-content">
                <HadDeviceHint id={id} location={location} subtype={subtype} />

                <div className="device-title">
                    <OverflowTextTooltip className="device-title-text">
                        {name}
                    </OverflowTextTooltip>
                    {partitions && partitions.numbers && (
                        <HadDevicePartitions partitions={partitions} />
                    )}
                </div>

                <HadDeviceState state={state} subtype={subtype} type={type} />

                <div className="device-had-controls">{RightCornerComponent}</div>
            </div>
        </div>
    )
}

HadDeviceGeneric.propTypes = {
    deviceType: PropTypes.string,
    subtype: PropTypes.string,
    userType: PropTypes.string,
    name: PropTypes.string,
    idWithDeviceType: PropTypes.string,
    state: PropTypes.object,
    location: PropTypes.shape({
        name: PropTypes.string,
    }),
    partitions: PropTypes.shape({
        type: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        numbers: PropTypes.arrayOf(PropTypes.number),
    }),
    id: PropTypes.number,
    RightCornerComponent: PropTypes.element,
    onClick: PropTypes.func,
}

export default HadDeviceGeneric
