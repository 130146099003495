import BoardFilter from 'components/Search/Board/BoardFilter'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import InputDatePicker from 'ipmp-react-ui/InputDatePicker'

import Filter from 'components/Search/Filters/Filter'
import {__} from 'utils/i18n'
import {PAST_PERIOD} from 'constants/dateFilter'

export default class SuggestBoardFilter extends BoardFilter {
    static propTypes = {
        filter: PropTypes.instanceOf(Filter).isRequired,
        selected: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
    }

    className = 'search-filter--date'

    getFastItems(fastItemsCount) {
        return this.props.filter.getItems()
    }

    getActiveCustomFilter() {
        const {selected, filter} = this.props
        return selected.find(
            (item) => item.name === filter.name && item.value instanceof Object
        )
    }

    handleChange = (from, to) => {
        const {filter, onSelect, onDeselect} = this.props
        const active = this.getActiveCustomFilter()

        if (!from) {
            active && onDeselect(active)
            return
        }

        if (!to) {
            onSelect(filter.createItem(from))
            return
        }

        from = from.toDate()
        to = to.toDate()

        if (this.isPastPeriod() && to > moment().toDate()) {
            to = moment()
        }

        onSelect(filter.createItem({from, to}))
    }

    isPastPeriod = () => {
        const {filter} = this.props
        return filter?.period === PAST_PERIOD
    }

    renderAutocomplete(fastItemsCount) {
        const item = this.getActiveCustomFilter()
        const currentDate = moment()

        return (
            <InputDatePicker
                max={this.isPastPeriod() && currentDate}
                range
                value={item ? item.value : null}
                placeholder={__('Choose date')}
                onChange={this.handleChange}
            />
        )
    }

    renderSelectedItems(fastItems) {
        return null
    }
}
