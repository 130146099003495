import React from 'react'

import {setStart} from 'modules/firmware/list/actions'

import {withVisibility} from 'containers/withVisibility'

import Pager from 'ipmp-react-ui/Pager'
import list from 'permissions/firmware/list'
import usePermission from 'hooks/usePermission'
import useUpgradablePanels from 'hooks/pages/useUpgradablePanels'
import {useActions} from 'modules/higherOrder/useActions'

const PagerVisibility = withVisibility()(Pager)

export default function FirmwarePager() {
    const {isPermitted} = usePermission(list)
    const {total, start, perPage} = useUpgradablePanels()

    const actions = useActions({
        setStart,
    })

    return (
        <PagerVisibility
            total={total}
            start={start}
            perPage={perPage}
            isVisible={isPermitted}
            onPageChange={actions.setStart}
            onPerPageChange={actions.setStart}
        />
    )
}
