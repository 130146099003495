import React, {useEffect} from 'react'
import {compose} from 'redux'

import Definition from 'ipmp-react-ui/Definition'
import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import RowActions from 'ui/Row/Actions'

import list from 'permissions/users/list'
import selectionPermission from 'permissions/users/selection'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import useUsers from 'hooks/pages/useUsers'
import usePermission from 'hooks/usePermission'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import useSelection from 'hooks/useSelection'

import User from 'components/Entities/User'
import Role from 'components/Entities/Role'

import StatusCell from './TableCell/StatusCell'
import EditAction from './Actions/EditAction'
import ChangeStatusAction from './Actions/ChangeStatusAction'

import {__} from 'utils/i18n'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

const allColumns = [
    {
        name: () => __('Status'),
        width: 42,
        fixed: true,
        component: StatusCell,
    },
    {
        width: 200,
        name: () => __('User'),
        render: ({id, name, email}) => {
            return <User id={id} name={name} email={email} withInfo />
        },
    },
    {
        name: () => __('Role'),
        width: 120,
        render: ({roleName, roleType}) => <Role name={roleName} type={roleType} />,
    },
    {
        name: () => __('Phone'),
        maxWidth: 170,
        render: ({phone, countryName}) => (
            <Definition title={phone} detail={countryName} multiLine />
        ),
    },
    {
        name: () => __('Last login'),
        maxWidth: 150,
        render: ({lastLogin}) => <DateTime datetime={lastLogin} multiLine />,
    },
    {
        name: () => __('Creator'),
        width: 65,
        align: 'center',
        fixed: true,
        render: ({userCreatorName, parentId}) => (
            <User name={userCreatorName} id={parentId} withTooltip />
        ),
    },
    {
        width: 60,
        fixed: true,
        render: ({isEnabled, isBlockedMfa, name, id}) => (
            <RowActions>
                <EditAction id={id}>{__('Edit')}</EditAction>
                <ChangeStatusAction
                    id={id}
                    isEnabled={isEnabled}
                    isBlockedMfa={isBlockedMfa}
                    name={name}
                />
            </RowActions>
        ),
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function UsersTable() {
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    const {columns} = useTableColumnsPermission(allColumns)

    const {selection, select, deselect} = useSelection()
    const {rows, init, error, isLoading, startPoll, stopPoll} = useUsers()

    useEffect(() => {
        init()
        startPoll()

        return stopPoll
    }, [])

    return (
        <ConnectedTable
            fullHeight
            rows={rows}
            selection={selection}
            select={select}
            deselect={deselect}
            error={error}
            isLoading={isLoading}
            hasSelection={hasSelection}
            columns={columns}
            emptyMessage={__('No users found')}
            className="user-status-table"
            rowClassName="user-status-table-row"
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
