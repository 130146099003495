import {
    HAD_TYPE_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_IQ_SMART_DOORLOCK,
    HAD_TYPE_LEEDARSON_DIMMER,
    HAD_TYPE_PG_PLUS_DOORLOCK,
    HAD_TYPE_PG_PLUS_WATER_VALVE,
    HAD_TYPE_PGM,
    HAD_TYPE_THERMOSTAT,
    HAD_TYPE_YALE_DOORLOCK_L2,
    HAD_TYPE_YALE_DOORLOCK_L3,
    HAD_TYPE_ZWAVE_WATER_VALVE,
} from 'constants/had'
import Void from 'components/Void'

import HadDeviceThermostatValues from './HadDeviceThermostatValues'
import HadDeviceDimmerValues from './HadDeviceDimmerValues'
import HadDeviceDoorlockControl from './HadDeviceDoorlockControl'
import HadDevicePgmControl from './HadDevicePgmControl'
import HadWaterValveControl from './HadWaterValveControl'

const hadDeviceListMapControl = new Map([
    [HAD_TYPE_DIMMER, HadDeviceDimmerValues],
    [HAD_TYPE_LEEDARSON_DIMMER, HadDeviceDimmerValues],
    [HAD_TYPE_THERMOSTAT, HadDeviceThermostatValues],
    [HAD_TYPE_DOORLOCK, HadDeviceDoorlockControl],
    [HAD_TYPE_YALE_DOORLOCK_L2, HadDeviceDoorlockControl],
    [HAD_TYPE_YALE_DOORLOCK_L3, HadDeviceDoorlockControl],
    [HAD_TYPE_IQ_SMART_DOORLOCK, HadDeviceDoorlockControl],
    [HAD_TYPE_PG_PLUS_DOORLOCK, HadDeviceDoorlockControl],
    [HAD_TYPE_PGM, HadDevicePgmControl],
    [HAD_TYPE_ZWAVE_WATER_VALVE, HadWaterValveControl],
    [HAD_TYPE_PG_PLUS_WATER_VALVE, HadWaterValveControl],
])

const hadDeviceBlindModalMapControl = new Map([
    [HAD_TYPE_DIMMER, HadDeviceDimmerValues],
    [HAD_TYPE_THERMOSTAT, HadDeviceThermostatValues],
])

const hadDeviceControlResolve = (type) => {
    if (hadDeviceListMapControl.has(type)) {
        return hadDeviceListMapControl.get(type)
    }

    return hadDeviceListMapControl.get(HAD_TYPE_PGM)
}

export const hadDeviceBlindModalControlResolve = (type) => {
    if (hadDeviceBlindModalMapControl.has(type)) {
        return hadDeviceBlindModalMapControl.get(type)
    }

    return Void
}

export default hadDeviceControlResolve
