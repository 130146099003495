import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {withPermissionVisibility} from 'containers/withPermission'
import {useActions} from 'modules/higherOrder/useActions'
import {setLastName} from 'permissions/panel/customers/actions'
import {showSetUserLastNameModal} from 'modules/modals/actions'

const ButtonVisibility = withPermissionVisibility(setLastName)(Button)

export default function SetLastNameButton({panelId, panelUserId, isPinSet, lastName}) {
    const {onClick} = useActions(
        {
            onClick: () => showSetUserLastNameModal({panelId, panelUserId, lastName}),
        },
        [lastName]
    )

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconEdit}
            onClick={onClick}
            disabled={!isPinSet}
        />
    )
}
