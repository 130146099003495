import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {NavLink as NavLinkRouter} from 'react-router-dom'
import classes from 'classnames'

class NavLink extends PureComponent {
    static propTypes = {
        to: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        isPermitted: PropTypes.bool,
    }

    static defaultProps = {
        isPermitted: true,
    }

    render() {
        const {className, to, isPermitted, name, children, Icon, handleClick} = this.props

        if (!isPermitted) {
            return null
        }

        return (
            <div className={classes('nav-link-wrapper', className)} onClick={handleClick}>
                <NavLinkRouter
                    className={({isActive}) =>
                        'nav-link' + (isActive ? ' nav-link--active' : '')
                    }
                    to={to}
                >
                    {Icon && <Icon className="nav-link-icon" />}
                    <div className="nav-link-content">{name}</div>
                </NavLinkRouter>

                {children}
            </div>
        )
    }
}

export default NavLink
