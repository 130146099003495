import React, {useEffect} from 'react'
import PropTypes from 'prop-types'

import Spinner from 'ipmp-react-ui/Spinner'
import {InfoCard} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {notificationsListUserNotifications} from 'permissions/group/navigation'
import useGroupNotificationsEndUser from 'hooks/group/useGroupNotificationsEndUser'

import EditNotificationsButton from './EditNotificationsButton'
import EventProfileList from './EventProfileList'

export default function NotificationProfile({groupId}) {
    const {settings, isLoading, fetch} = useGroupNotificationsEndUser(groupId)
    const {isPermitted} = usePermission(notificationsListUserNotifications)

    useEffect(() => {
        isPermitted && fetch()
    }, [groupId])

    if (!isPermitted) {
        return null
    }

    if (isLoading) {
        return <Spinner />
    }

    return (
        <InfoCard
            cols={4}
            title={__('End-User notifications')}
            headerButton={<EditNotificationsButton groupId={groupId} />}
        >
            {settings?.notificationProfile && (
                <EventProfileList
                    profiles={settings.notificationProfile}
                    availableProfiles={settings.availableProfiles.notificationProfile}
                />
            )}
        </InfoCard>
    )
}

NotificationProfile.propTypes = {
    groupId: PropTypes.number.isRequired,
}
