import React from 'react'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'
import Autocomplete from 'ipmp-react-ui/Autocomplete'
import Disclaimer from 'ipmp-react-ui/Disclaimer'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import getCountryTitle from 'constants/country'
import {editProfile} from 'modules/forms/handlers'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchCountries} from 'modules/countries/actions'

const CountrySelect = withSelectLoader(
    fetchCountries,
    (state) => state.countries.isLoading,
    ({countries}) =>
        Object.values(countries.byIds).map(({id, name}) => ({
            value: id,
            label: getCountryTitle(name),
        }))
)(Autocomplete)

const rules = {
    email: {
        presence: true,
        emailRFC: true,
    },
    phone: {
        presence: true,
        phone: true,
    },
    countryId: {
        presence: true,
    },
}

export default function EditProfileInfo({hide}) {
    const {isLoading, form, handle} = useForm(editProfile)
    const {countryId, countryName, phone, email} = useSelector(
        ({
            auth: {
                sign: {user},
            },
        }) => ({
            countryId: user.countryId,
            countryName: user.countryName,
            phone: user.phone,
            email: user.email,
        })
    )
    const handler = (newData) => {
        const isChanged =
            email !== newData.email ||
            phone !== newData.phone ||
            countryId !== newData.countryId

        isChanged ? handle(newData) : hide()
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            header={__('Edit Profile')}
            className="edit-user-profile-modal"
        >
            <Input
                label={__('Email')}
                name="email"
                defaultValue={email}
                dataTestId="email"
                error={form?.errors?.usr_email}
            />
            <Disclaimer>
                {__(
                    'By changing email address session will be terminated and new authentication will be required'
                )}
            </Disclaimer>
            <Input
                label={__('Phone')}
                name="phone"
                defaultValue={phone}
                dataTestId="phone"
            />

            <CountrySelect
                optional
                label={__('Country')}
                name="countryId"
                defaultValue={countryId}
                defaultLabel={countryName && getCountryTitle(countryName)}
                dataTestId="country"
            />
        </ModalCardForm>
    )
}
