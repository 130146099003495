import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {markForService} from 'permissions/panels/selection'
import {withSelectionHandler} from 'containers/withSelection'
import {showMarkForServiceModal} from 'modules/modals/actions'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export function MarkForServiceItem({onClick}) {
    const {isPermitted} = usePermission(markForService)

    return (
        <ConnectedMenuItem isVisible={isPermitted} onClick={onClick}>
            {__('Mark for service')}
        </ConnectedMenuItem>
    )
}

export default function MarkForService() {
    const onClick = useActions(showMarkForServiceModal)

    return <MarkForServiceItem onClick={onClick} />
}
