import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {fetch} from 'modules/groups/notifications/eventProfiles/actions'
import {
    getByGroupId,
    getIsLoading,
} from 'modules/groups/notifications/eventProfiles/selectors'

export default function useGroupNotificationsEventProfiles(groupId) {
    const dispatch = useDispatch()
    const settings = useSelector((state) => getByGroupId(state, groupId))
    const isLoading = useSelector(getIsLoading)

    return {
        settings,
        isLoading,
        ...bindActionCreators(
            {
                fetch: () => fetch(groupId),
            },
            dispatch
        ),
    }
}
