import {useEffect} from 'react'
import memoize from 'fast-memoize'
import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {track, untrack} from 'modules/processes/manager/actions'
import {createTrackedSelector} from 'modules/processes/manager/selectors'

function getTrackKey(process) {
    if (!process) {
        return null
    }

    return process.key || process.id || process
}

function defaultGetter({processId, process}) {
    return process || processId
}

const memo = memoize(createTrackedSelector)

export default function useTrackProcess(props = {}, processGetter = defaultGetter) {
    const {track: trackAction, untrack: untrackAction} = useActions({track, untrack})

    const key = getTrackKey(processGetter(props))
    const selector = memo(key)
    const process = useSelector((state) => selector(state))

    const id = parseInt(process && process.isRunning && process.id) || 0

    useEffect(() => {
        id && trackAction(id)
        return () => id && untrackAction(id)
    }, [id])

    return {process}
}
