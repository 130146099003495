import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import {editEventsProfileEndUser} from 'modules/forms/handlers'
import useGroupNotificationsEndUser from 'hooks/group/useGroupNotificationsEndUser'

import EventsForm, {NOTIFICATION_PROFILE} from './EventsForm'

export default function EditNotificationsProfile({hide, groupId}) {
    const {form, handle, isLoading} = useForm(editEventsProfileEndUser)
    const {settings} = useGroupNotificationsEndUser(groupId)

    const handler = (data) => handle(groupId, data)

    return (
        <EventsForm
            {...form}
            hide={hide}
            data={settings}
            handle={handler}
            isLoading={isLoading}
            fieldGroupKey={NOTIFICATION_PROFILE}
            profiles={settings.notificationProfile}
            header={__('Edit notifications profile')}
            availableProfiles={settings.availableProfiles.notificationProfile}
        />
    )
}
