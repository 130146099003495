import React, {useState} from 'react'
import isEmpty from 'lodash-es/isEmpty'
import {useSelector} from 'react-redux'

import {InfoCard} from 'ipmp-react-ui/InfoCards'
import {ReactComponent as IconCustomerInfo} from 'ipmp-react-ui/icons/customer-info.svg'

import EditCustomerInfoButton, {
    EditCustomerInfoButtonWithText,
} from './Buttons/EditCustomerInfoButton'

import {__} from 'utils/i18n'
import CustomerGeneralInfo from 'components/Panel/CustomerGeneralInfo'
import CustomerRemarks from 'components/Panel/CustomerRemarks'
import EmptyPageCard from 'ui/EmptyPageCard'
import CustomerMap from 'components/Panel/CustomerMap'
import {selectTheme} from 'modules/settings/selectors'
import {selectGoogleSettings} from 'modules/system/settings/selectors'

const isCustomerInfoEmpty = ({name, email, phone, address, remark, remark2, remark3}) => {
    return (
        isEmpty(name) &&
        isEmpty(email) &&
        isEmpty(phone) &&
        isEmpty(address) &&
        isEmpty(remark) &&
        isEmpty(remark2) &&
        isEmpty(remark3)
    )
}

const CustomerInfo = ({panel}) => {
    const [googleApiError, setGoogleApiError] = useState(null)
    const {token: googleToken} = useSelector(selectGoogleSettings)

    const hasGoogleApiError = (err) => setGoogleApiError(err)
    const {name, email, phone, address, remark, remark2, remark3} = panel.contact || {}
    const theme = useSelector(selectTheme)

    if (isCustomerInfoEmpty(panel.contact || {})) {
        return (
            <EmptyPageCard
                title={__('Owner info')}
                message={__('No Owner Info')}
                Icon={<IconCustomerInfo />}
                Button={
                    <EditCustomerInfoButtonWithText googleApiError={googleApiError} />
                }
            />
        )
    }

    return (
        <InfoCard
            cols={1}
            title={__('Owner Info')}
            headerButton={<EditCustomerInfoButton googleApiError={googleApiError} />}
        >
            <CustomerGeneralInfo
                name={name}
                email={email}
                phone={phone}
                address={address}
            />
            <CustomerMap
                address={address}
                googleToken={googleToken}
                hasGoogleApiError={hasGoogleApiError}
                theme={theme}
            />
            <hr />
            <CustomerRemarks remarks={[remark, remark2, remark3]} />
        </InfoCard>
    )
}

export default CustomerInfo
