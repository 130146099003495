import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'
import useRoles from 'hooks/pages/useRoles'
import usePermission from 'hooks/usePermission'
import {remove} from 'permissions/roles/selection'
import {withSelectionHandler} from 'containers/withSelection'

const ButtonSelection = withSelectionHandler()(Button)

export default function RemoveRolesButton() {
    const {showRemoveRole} = useRoles()
    const {isPermitted} = usePermission(remove)

    return (
        <ButtonSelection
            isVisible={isPermitted}
            Icon={IconRemove}
            onClick={showRemoveRole}
            title={__('Remove roles')}
            message={__('')}
            positive={__('Remove')}
            label={__('Remove')}
        />
    )
}
