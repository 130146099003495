import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {
    PageMessagePaneErrorDisclaimer,
    PageMessagePaneDisclaimer,
} from 'ipmp-react-ui/PageMessagePane'

import {isSwitchableCategories} from 'constants/had'
import {selectCategorizedList, selectHadIsSwitchable} from 'modules/panels/had/selector'
import {pgmControl} from 'permissions/panel/output/actions'
import {
    IsCanActivatedDisclaimerMessage,
    IsDisabledDisclaimerMessage,
    IsOnlyAlarmActivatedDisclaimerMessage,
} from '../HadDisclaimerMessages'
import {withPermissionVisibility} from 'containers/withPermission'

const HadListDisclaimerBase = ({isTimeSlotAutomationDevicesEnabled}) => {
    const categorizedList = useSelector(selectCategorizedList)
    const {isSwitchable, isTimeSlotEnabled} = useSelector(selectHadIsSwitchable)
    const isSwitchableCategoriesPresent = isSwitchableCategories(
        Array.from(categorizedList.keys())
    )

    const [isShowSwitchablePane, setShowPane] = useState(!isSwitchable)
    const [isPaneClosed, setIsPaneClosed] = useState(false)

    const isCanBeActivatedShow =
        isSwitchableCategoriesPresent &&
        isTimeSlotEnabled &&
        !isShowSwitchablePane &&
        !isPaneClosed
    const isCanNotBeActivatedShow =
        isSwitchableCategoriesPresent &&
        isTimeSlotEnabled &&
        isShowSwitchablePane &&
        !isPaneClosed
    const isNotEnabledShow =
        isSwitchableCategoriesPresent && !isTimeSlotEnabled && !isPaneClosed

    useEffect(() => {
        setShowPane(!isSwitchable)
    }, [isSwitchable, categorizedList])

    return (
        <>
            {isCanBeActivatedShow && (
                <PageMessagePaneDisclaimer onClose={() => setIsPaneClosed(true)}>
                    <IsCanActivatedDisclaimerMessage
                        isTimeSlotAutomationDevicesEnabled={
                            isTimeSlotAutomationDevicesEnabled
                        }
                    />
                </PageMessagePaneDisclaimer>
            )}
            {isCanNotBeActivatedShow && (
                <PageMessagePaneErrorDisclaimer onClose={() => setIsPaneClosed(true)}>
                    <IsOnlyAlarmActivatedDisclaimerMessage
                        isTimeSlotAutomationDevicesEnabled={
                            isTimeSlotAutomationDevicesEnabled
                        }
                    />
                </PageMessagePaneErrorDisclaimer>
            )}
            {isNotEnabledShow && (
                <PageMessagePaneErrorDisclaimer onClose={() => setIsPaneClosed(true)}>
                    <IsDisabledDisclaimerMessage
                        isTimeSlotAutomationDevicesEnabled={
                            isTimeSlotAutomationDevicesEnabled
                        }
                    />
                </PageMessagePaneErrorDisclaimer>
            )}
        </>
    )
}

export default withPermissionVisibility(pgmControl)(HadListDisclaimerBase)
