import validate from 'validate.js'
import isFunction from 'lodash-es/isFunction'
import moment, {isMoment} from 'moment'

import {__} from 'utils/i18n'
import toArray from 'utils/toArray'
import {
    PHONE_MAX_LENGTH,
    PHONE_MIN_LENGTH,
    phoneValidateMessage,
} from 'constants/validate'
import {VENDOR_NEO, VENDOR_QOLSYS} from 'constants/panelVendorType'

const defaultFormat = 'L'
export const ipv4RegExp =
    /((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))/
/* eslint-disable no-useless-escape */
const strongPasswordWithSpecialR =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"#$\]\[%&'()*+,-./:;<=>?@^_`{|}~]).+/
/* eslint-enable no-useless-escape */

// Use gist https://gist.github.com/dperini/729294 without protocol
var hostRegExp = new RegExp(
    '^' +
        '(?:' +
        '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).' +
        '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).' +
        '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).' +
        '(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)' +
        '|' +
        // host & domain names, may end with dot
        // can be replaced by a shortest alternative
        // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
        '(?:' +
        '(?:' +
        '[a-z0-9\\u00a1-\\uffff]' +
        '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
        ')?' +
        '[a-z0-9\\u00a1-\\uffff]\\.' +
        ')+' +
        // TLD identifier name, may end with dot
        '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
        ')' +
        // port number (optional)
        '(?::\\d{2,5})?' +
        // resource path (optional)
        '(?:[/?#]\\S*)?' +
        '$',
    'i'
)

const streamLinkRegExp = new RegExp(
    '^(?:([a-zA-Z][a-zA-Z0-9+-.]*)?:\\/\\/)?(?:([^@\\s\\/$.?#]+)@)?(((?:[a-zA-Z0-9-]+\\.)*(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}|(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})|(?:\\w+\\/)*(?:\\w+\\/)+\\w+)(?::(\\d+))?(\\S*)$'
)

function extendValidators() {
    validate.extend(validate.validators.datetime, {
        parse(value) {
            if (isMoment(value)) {
                return value
            }

            const parsed = moment.utc(value, defaultFormat)
            return +parsed
        },

        format(value) {
            return moment(value).format(defaultFormat)
        },
    })

    validate.validators.deviceId = (code, {invalid, notExists, existence}) => {
        if (!code) {
            return
        }

        const digits = code.replace(/\D/g, '')

        if (digits.length !== 7) {
            return invalid
        }

        if (parseInt(digits.substr(3)) > 8191) {
            return invalid
        }

        if (isFunction(existence) && !existence(code)) {
            return notExists
        }
    }

    validate.validators.noEmptyEquality = (value, options, name, values) => {
        const {attribute} = options
        const presence = values[attribute] !== null
        if (presence) {
            const message = validate.single(value, {presence})
            if (message) {
                return message
            }
        }

        return validate.validators.equality(value, options, attribute, values)
    }

    validate.validators.userName = (value) =>
        validate.single(value, {
            format: {
                pattern: /^[a-zA-Z 0-9_\-'.]+$/,
                message: __(
                    "Invalid characters used. Allowed only letters, digits, spaces and symbols [_,-,.,']"
                ),
            },
        })

    validate.validators.phone = (value) =>
        validate.single(value, {
            format: {
                pattern: /^\+?[0-9]*$/,
                message: () =>
                    __('Invalid characters used. Should contain only digits and +'),
            },
            length: {
                minimum: PHONE_MIN_LENGTH,
                maximum: PHONE_MAX_LENGTH,
                tooLong: phoneValidateMessage,
                tooShort: phoneValidateMessage,
            },
        })

    validate.validators.each = (values, options) => {
        values = toArray(values)
        return values
            .map((value) => {
                return validate.single(value, options)
            })
            .find((result) => result)
    }

    validate.validators.parsed = (value, {parse, validators}) => {
        const parsed = validate.isFunction(parse) ? parse(value) : value
        return validate.single(parsed, validators)
    }

    validate.validators.password = (value) =>
        validate.single(value, {
            length: {
                minimum: 8,
            },
            format: {
                pattern: strongPasswordWithSpecialR,
                message: () =>
                    __(
                        'Password should include digits, lower and upper-case characters, special symbols'
                    ),
            },
        })

    validate.validators.ipv4 = function validateIpv4(value, options) {
        options = validate.extend({}, this.options, options)

        return validate.single(value, {
            format: {
                pattern: ipv4RegExp,
                message: options.message,
            },
        })
    }

    validate.validators.host = function validateHost(value, options) {
        options = validate.extend({}, this.options, options)

        return validate.single(value, {
            format: {
                pattern: hostRegExp,
                message: options.message,
            },
        })
    }

    validate.validators.streamLink = function validateStreamLink(value, options) {
        options = validate.extend({}, this.options, options)

        return validate.single(value, {
            format: {
                pattern: streamLinkRegExp,
                message: options.message,
            },
        })
    }

    validate.validators.custom = function validateCustom(value, options) {
        if (!options) {
            return
        }

        if (typeof options === 'string') {
            return options
        }

        options = validate.extend({}, this.options, options)
        return value.single(value, options)
    }

    validate.validators.installerPin = function validateInstallerPin(value, options) {
        const isStringInvalid = validate.single(value, {
            format: {
                pattern: /^[\d]+$/,
                message: __('Only numbers allowed'),
            },
        })
        if (isStringInvalid) {
            return isStringInvalid
        }

        const isEmptyValue = value === null || value.length < 1

        if (isEmptyValue || !options.allowedLengths.includes(value.length)) {
            if (options.allowedLengths.length < 2) {
                return __('The code should contain %{value} numbers').replace(
                    '%{value}',
                    options.allowedLengths[0]
                )
            } else {
                let allowedLengths = [...options.allowedLengths]
                const lengthAlt = allowedLengths.pop()

                return __('The code should contain %{value} numbers').replace(
                    '%{value}',
                    allowedLengths.join(', ') + ' or ' + lengthAlt
                )
            }
        }
    }

    validate.validators.float = (value, {min, max}) => {
        const isValidFloat = validate.single(value, {
            format: {
                pattern: /^[+-]?\d+(\.\d+)?$/,
                message: () => __('Not a valid float value'),
            },
        })

        if (isValidFloat) {
            return isValidFloat
        }

        const floatVal = parseFloat(value)

        if (max < floatVal) {
            return [__('Must be less than or equal to %s', max)]
        }

        if (min > floatVal) {
            return [__('Must be greater than or equal to %s', min)]
        }
    }

    validate.validators.userLabel = (value, options) => {
        const {vendor, validationRules} = options

        if (undefined === validationRules || 0 === validationRules.length) {
            let defaultOptions = {
                length: {
                    maximum: vendor !== VENDOR_NEO ? 50 : 255,
                },
            }
            switch (vendor) {
                case VENDOR_NEO:
                    defaultOptions.format = {
                        pattern: /^[a-zA-Z 0-9,!"#$%&'()*./:;<=>?@[\]^_`{|}°×⁻←→√∞█･ｰ]+$/,
                        message: () =>
                            __(
                                "Invalid characters used. Allowed only digits, letters and !#$%&'()*+,-./:;<=>?@[]^_`{|}°×⁻←→√∞█･ｰ symbols"
                            ),
                    }
                    break
                case VENDOR_QOLSYS:
                    defaultOptions.format = {
                        pattern: /^[a-zA-Z 0-9_\-#&'|]+$/,
                        message: () =>
                            __(
                                "Invalid characters used. Allowed only digits, letters and -#&' symbols"
                            ),
                    }
                    break
                default:
                    defaultOptions.format = {
                        pattern: /^.*$/,
                    }
            }

            return validate.single(value, defaultOptions)
        }

        const errorMessage = validateByFeatureset(validationRules, value)

        if (errorMessage.length > 0) {
            return errorMessage
        }
    }

    validate.validators.labelByFeatureset = (value, options) => {
        const {validationRules, maxLength} = options

        if (undefined === validationRules || 0 === validationRules.length) {
            const defaultOptions = {
                length: {
                    maximum: maxLength,
                },
            }

            return validate.single(value, defaultOptions)
        }

        const errorMessage = validateByFeatureset(validationRules, value)

        if (errorMessage.length > 0) {
            return errorMessage
        }
    }
}

export const validateByFeatureset = (validationRules, value) => {
    let errorMessage = ''
    validationRules.every((item) => {
        const regex = new RegExp(item.regex.ECMAScript, item.regex_flags)
        if (
            (true === item.should_match && false === regex.test(value)) ||
            (false === item.should_match && true === regex.test(value))
        ) {
            errorMessage = item.error_message

            return false
        }

        return true
    })

    return errorMessage
}

function setErrorMessages(messages) {
    Object.keys(messages).map((key) => {
        const data =
            typeof messages[key] === 'object' ? messages[key] : {message: messages[key]}

        validate.validators[key].options = {
            ...validate.validators[key].options,
            ...data,
        }
    })
}

export default function initValidate() {
    extendValidators()

    setErrorMessages({
        presence: () => __('This field is required'),
        email: () => __('Invalid email address'),
        format: () => __('Value is not acceptable'),
        length: {
            tooShort: (value, name, args) =>
                __('Needs to have %(minimum)d characters or more', args),
            tooLong: (value, name, args) =>
                __('Needs to have %(maximum)d characters or less', args),
        },
        numericality: {
            notValid: () => __('Is not a number'),
            notInteger: () => __('Is not a number'),
            notGreaterThanOrEqualTo: (value, name, args) =>
                __('Must be greater than or equal to %(greaterThanOrEqualTo)d', args),
            notLessThanOrEqualTo: (value, name, args) =>
                __('Must be less than or equal to %(lessThanOrEqualTo)d', args),
            notLessThan: (value, name, args) =>
                __('Must be less than %(lessThan)d', args),
            notGreaterThan: (value, name, args) =>
                __('Must be greater than %(greaterThan)d', args),
        },
        ipv4: () => __('Must be valid ipv4 address, like 192.168.168.121'),
        host: () => __('Must be valid ipv4 address, or hostname'),
        streamLink: () => __('Must be valid stream link'),
        url: () => __('Is not a valid url'),
        datetime: (value, attribute, validatorOptions) =>
            validatorOptions.dateOnly
                ? __('Must be a valid date')
                : __('Must be a valid datetime'),
    })
}
