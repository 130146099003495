import {createAction} from 'redux-actions'

export const receive = createAction('PANELS/FIRMWARE/RECEIVE', null, (_, panelId) => ({
    panelId,
}))
export const fetch = createAction('PANELS/FIRMWARE/FETCH', (panelId) => ({panelId}))
export const upgrade = createAction('PANELS/FIRMWARE/UPGRADE')
export const patchTagUpgrade = createAction('PANELS/FIRMWARE/PATCH_TAG')
export const refresh = createAction('PANELS/FIRMWARE/REFRESH')
export const startPoll = createAction('PANELS/FIRMWARE/START_POLL_DATA', (panelId) => ({
    panelId,
}))
export const stopPoll = createAction('PANELS/FIRMWARE/STOP_POLL_DATA', (panelId) => ({
    panelId,
}))
