import React from 'react'

import BasicConfigurationsPage from './BasicConfigurationsPage'
import path from 'utils/path'

export default class BasicConfigurationsRoute {
    path = path('basicConfigurations')

    element = (<BasicConfigurationsPage />)
}
