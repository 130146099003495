import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
    InfoCardTileTrailing,
} from 'ipmp-react-ui/InfoCards'
import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import BrokersDeleteButton from './BrokersDeleteButton'
import {showAlert, showSystemSettingsEditMessageBrokerModal} from 'modules/modals/actions'
import {removeMessageBroker} from 'modules/system/settings/actions'

const MessageBrokers = () => {
    const {messageBrokers} = useSelector(({system}) => system.settings)
    const [brokers, setBrokers] = useState(messageBrokers)
    const [activeRow, setActiveRow] = useState(null)
    const modals = useSelector(({modals}) => modals)

    const {
        remove,
        edit,
        showAlert: showAlertModal,
    } = useActions({
        edit: (broker) =>
            showSystemSettingsEditMessageBrokerModal({
                data: broker,
            }),
        remove: (id) => removeMessageBroker(id),
        showAlert,
    })
    const handleRemove = ({id, isUsedByMfa}) => {
        if (!isUsedByMfa) {
            return remove(id)
        }

        showAlertModal(
            __('Warning'),
            __(
                'Selected message broker is used for MFA authentication and can not be removed. Change message broker firstly then remove.'
            )
        )
    }

    const handleRowClick = (e, broker) => {
        e.preventDefault()
        setActiveRow({id: broker.id})
        edit(broker)
    }

    useEffect(() => {
        if (!modals?.type) {
            setActiveRow(null)
        }
        setBrokers(messageBrokers)
    }, [modals, messageBrokers, brokers])

    return (
        <>
            {brokers.data.map((broker, index) => (
                <InfoCardTile
                    key={index}
                    onClick={(e) => handleRowClick(e, broker)}
                    active={activeRow?.id === broker.id}
                >
                    <InfoCardTileTitle>{broker.name}</InfoCardTileTitle>
                    <InfoCardTileContent>
                        <OverflowTextTooltip>{broker.description}</OverflowTextTooltip>
                    </InfoCardTileContent>
                    <InfoCardTileTrailing>
                        <BrokersDeleteButton
                            isUsedByMfa={broker.isUsedByMfa}
                            onClick={() => handleRemove(broker)}
                        />
                    </InfoCardTileTrailing>
                </InfoCardTile>
            ))}
        </>
    )
}

export default MessageBrokers
