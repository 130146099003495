import React from 'react'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    setQuery,
} from 'modules/batches/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import processType from 'constants/processType'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectBatchesList} from 'modules/batches/list/selectors'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import getBatchStatusTitle, {list} from 'constants/batchesStatuses'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

export default function BatchesSearch({className}) {
    const {filters: selected, query, suggests} = useSelector(selectBatchesList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new TranslateSuggestFilter('type', __('Type'), processType),
        new ValuesFilter(
            'status',
            __('Status'),
            list.reduce((acc, value) => {
                acc[value] = getBatchStatusTitle(value)
                return acc
            }, {})
        ),
        new DateFilter('started', __('Started at')),
        new SuggestFilter('serial', __('Panel Id')),
        new SuggestFilter('group', __('Group')),
        new DateFilter('finished', __('Finished at')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <Search
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            className={className}
            placeholder={__('Search Jobs')}
        />
    )
}
