import React from 'react'

import path from 'utils/path'
import FirmwarePage from './FirmwarePage'

export default class {
    path = path('firmware')

    element = (<FirmwarePage />)
}
