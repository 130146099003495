import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {Route, Navigate, Routes} from 'react-router-dom'
import {connect} from 'react-redux'
import SnackBarComponent from 'ipmp-react-ui/SnackBar'
import {hide} from 'modules/snacks'
import {hide as navBlindHide} from 'modules/navBlind/actions'
import withLoader from 'containers/withLoader'
import pages from 'pages/routes'
import Login from 'pages/App/Login'
import NavMain from 'components/Nav/NavMain'
import ModalsContainer from 'modals/ModalsContainer'
import NavBlindContainer from 'components/Nav/NavBlindContainer'
import NavbarTop from './NavbarTop'
import Sidebar from './Sidebar'
import {showAboutModal} from 'modules/modals/actions'
import classes from 'classnames'
import JobNotifications from './Notifications/JobNotifications'

const SIDEBAR_STATE_OPEN_KEY = 'PMSidebar'
const sidebarState = localStorage.getItem(SIDEBAR_STATE_OPEN_KEY)
const isSideBarOpened = sidebarState === null ? true : sidebarState === 'true'

const SnackBar = connect(
    ({snacks}) => ({snacks}),
    (dispatch) =>
        bindActionCreators(
            {
                hide,
                dispatch,
            },
            dispatch
        )
)(SnackBarComponent)

class Layout extends PureComponent {
    static propTypes = {
        isLogged: PropTypes.bool.isRequired,
        navBlindHide: PropTypes.func.isRequired,
    }

    state = {
        sidebarShowed: isSideBarOpened,
        sidebarPinned: isSideBarOpened,
        selectedBatchId: null,
    }

    closeNavBlind = () => this.props.navBlindHide()

    handleAppRef = (appElement) => {
        this.appElement = appElement

        if (this.appElement) {
            this.appElement.addEventListener('click', this.handleAppClick, true)
        }
    }

    navRefHandler = (navElement) => (this.navElement = navElement)

    processRefHandler = (processElement) => (this.processElement = processElement)

    navBlindRefHandler = (navBlindElement) => (this.navBlindElement = navBlindElement)

    sidebarRef = React.createRef()

    handleClickOutside = (e) => {
        if (
            this.sidebarRef.current &&
            !this.sidebarRef.current.contains(e.target) &&
            this.state.sidebarShowed &&
            !e.target.closest('.sidebar-opener.active') &&
            !e.target.closest('.modal') &&
            !this.state.sidebarPinned
        ) {
            this.setState({
                sidebarShowed: false,
            })
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        if (this.appElement) {
            this.appElement.removeEventListener('click', this.handleAppClick, true)
        }
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    handleAppClick = (event) => {
        if (!this.navElement) {
            return
        }

        const {isNavBlindOpened} = this.props

        const isClickOnBatchInfo =
            this.navBlindElement &&
            (this.navBlindElement.contains(event.target) ||
                this.navBlindElement === event.target)
        const isRecentHistoryWidget = Boolean(
            event.target.closest('.recentHistoryWidgetController')
        )
        const isClickOnNavigation = this.navElement.contains(event.target)
        const isClickOnMyProcesses =
            this.processElement && this.processElement.contains(event.target)

        switch (true) {
            case isClickOnBatchInfo:
                break
            case isRecentHistoryWidget && isNavBlindOpened:
                event.stopPropagation()
                this.closeNavBlind()
                break
            case isClickOnNavigation:
                this.closeNavBlind()
                break
            case isClickOnMyProcesses:
                break
            default:
                this.closeNavBlind()
                break
        }
    }

    toggleSidebar = () => {
        if (!this.state.sidebarPinned) {
            this.setState({
                sidebarShowed: !this.state.sidebarShowed,
            })
        }
    }

    selectBatchId = (batchId) => {
        this.setState({
            sidebarShowed: true,
            selectedBatchId: batchId,
        })
    }

    toggleSidebarPin = () => {
        if (!this.state.sidebarPinned) {
            localStorage.setItem(SIDEBAR_STATE_OPEN_KEY, 'true')
        } else {
            localStorage.setItem(SIDEBAR_STATE_OPEN_KEY, 'false')
        }

        this.setState({
            sidebarPinned: !this.state.sidebarPinned,
        })
    }

    render() {
        const {showAboutModal, isLogged, theme} = this.props
        const {selectedBatchId, sidebarShowed, sidebarPinned} = this.state

        if (!isLogged) {
            return (
                <Login>
                    <SnackBar />
                </Login>
            )
        }

        return (
            <div className="app" ref={this.handleAppRef}>
                <aside className="app-aside">
                    <div className="app-aside-content">
                        <NavMain navRefHandler={this.navRefHandler} />
                    </div>
                </aside>
                <div
                    className={classes('main-content', {
                        'sidebar-pinned': sidebarPinned,
                    })}
                >
                    <NavbarTop
                        theme={theme}
                        handleClick={this.toggleSidebar}
                        sidebarShowed={sidebarShowed}
                        showAboutModal={showAboutModal}
                    />

                    <Routes>
                        <Route exact path="/" element={<Navigate to="/panels" />} />
                        {pages.map((page, i) => (
                            <Route key={i} {...page} />
                        ))}
                    </Routes>
                    {sidebarShowed && (
                        <Sidebar
                            sidebarRef={this.sidebarRef}
                            hide={this.toggleSidebar}
                            pin={this.toggleSidebarPin}
                            isPinned={sidebarPinned}
                            processRefHandler={this.processRefHandler}
                            closeNavBlind={this.closeNavBlind}
                            selectedBatchId={{
                                value: selectedBatchId,
                                clear: () => this.setState({selectedBatchId: null}),
                            }}
                        >
                            <NavBlindContainer
                                navBlindRefHandler={this.navBlindRefHandler}
                                hideSidebar={this.toggleSidebar}
                            />
                        </Sidebar>
                    )}
                    {!sidebarShowed && <JobNotifications onClick={this.selectBatchId} />}
                    <SnackBar />
                </div>
                <ModalsContainer />
            </div>
        )
    }
}

export default compose(
    connect(
        ({auth: {sign}, locales, navBlind: {type}, settings: {theme}}) => ({
            locale: locales.locale,
            isLogged: !!sign.user,
            isLoading: !sign.checked,
            isNavBlindOpened: !!type,
            theme,
        }),

        (dispatch) => bindActionCreators({showAboutModal, navBlindHide}, dispatch)
    ),
    withLoader()
)(Layout)
