import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

export default function ConfirmWithPinCodeInputFields({pin, pinLength, onChange}) {
    const [focus, setFocus] = useState(0)

    useEffect(() => {
        const inputToFocus = document.querySelector(`input[name="pin-${focus}"]`)
        if (inputToFocus) {
            inputToFocus.focus()
            inputToFocus.select()
        }
    }, [focus])

    return (
        <div className="autolearn-pin-input-fields">
            {Array.from({length: pinLength}, (v, i) => (
                <div className="autolearn-pin-input-field" key={`pin-${i}`}>
                    <Input
                        name={`pin-${i}`}
                        dataTestId={`pin-${i}`}
                        onInput={({target}) => {
                            onChange(i, target.value)
                            target.value && setFocus(i === pinLength - 1 ? 0 : i + 1)
                        }}
                        maxLength={1}
                        defaultValue={pin[i]}
                        autoFocus={i === focus}
                    />
                </div>
            ))}
        </div>
    )
}

ConfirmWithPinCodeInputFields.propTypes = {
    pin: PropTypes.array.isRequired,
    pinLength: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}
