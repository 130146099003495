import {get, post} from 'api/http'
import {AUTOLEARN_PROTOCOLS} from 'constants/autolearn'

import message from './autolearn.map'

export function start({serial, protocol}, timeout) {
    const type = protocol in AUTOLEARN_PROTOCOLS ? 'automation' : 'security'

    return post(`/unit/${serial}/autolearn/${type}/start`, {
        timeout,
        ...(protocol in AUTOLEARN_PROTOCOLS
            ? {protocol: AUTOLEARN_PROTOCOLS[protocol]}
            : {}),
    }).then(({session_token}) => session_token)
}

export function stop({serial, protocol, token}) {
    const type = protocol in AUTOLEARN_PROTOCOLS ? 'automation' : 'security'

    return post(`/unit/${serial}/autolearn/${type}/stop`, {
        token,
        ...(protocol in AUTOLEARN_PROTOCOLS
            ? {protocol: AUTOLEARN_PROTOCOLS[protocol]}
            : {}),
    })
}

export function fetch({serial, token}) {
    return get(`/unit/${serial}/autolearn`, {token}).then((response) =>
        response.map(message)
    )
}

export function confirmation({serial, token}, {sensor, pin, isConfirmed}) {
    return post(`/unit/${serial}/autolearn/confirmation`, {
        sensor,
        pin,
        is_confirmed: isConfirmed,
        token,
    })
}

export function keys({serial, token}, data) {
    return post(`/unit/${serial}/autolearn/keys`, {...data, token})
}

export function dsk({serial, token}, data) {
    return post(`/unit/${serial}/autolearn/dsk`, {...data, token})
}
