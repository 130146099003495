import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const UserNotifications = () => {
    const userNotifications = useSelector(
        ({system}) => system.settings.userNotifications.data
    )
    const messageBrokers = useSelector(({system}) => system.settings.messageBrokers.data)
    const messageBroker = messageBrokers.find(
        (broker) => broker.id === userNotifications.messageBroker
    )

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Enable Emails with Attached Video')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(userNotifications?.isEmailWithVideoEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Enable Emails without Attached Video')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(userNotifications?.isEmailWithoutVideoEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Message Broker for SMS by server')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(messageBroker && messageBroker.name)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default React.memo(UserNotifications)
