import React from 'react'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {compose} from 'redux'

import withLoader from 'containers/withLoader'
import withFeature from 'containers/withFeature'
import usePermission from 'hooks/usePermission'
import {videoOnDemand} from 'permissions/events/actions'
import VideoTabsComponent from 'components/Sidebar/VideoTabs'
import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'
import {exports, request} from 'permissions/panel/devices/video/actions'

const ConnectedVideoTabs = compose(
    withFeature(),
    withLoader(({fetch, fetchFeaturesWithoutLoader, panelId}) => {
        fetch()
        fetchFeaturesWithoutLoader(panelId)
    })
)(VideoTabsComponent)

export default function VideoTabs(props) {
    const {
        event: {zone, deviceType, panelId},
        startPoll,
        stopPoll,
    } = props

    const {hasLive, isRequestAllowed, isExportAllowed} = usePermission({
        hasLive: videoOnDemand,
        isRequestAllowed: request,
        isExportAllowed: exports,
    })
    const device = useSelector((state) =>
        selectDeviceByZoneAndType(state, {panelId, deviceType, zone})
    )

    useEffect(() => {
        startPoll()

        return stopPoll
    }, [])

    return (
        <ConnectedVideoTabs
            {...props}
            device={device}
            hasLive={hasLive}
            isExportAllowed={isExportAllowed}
            isRequestAllowed={isRequestAllowed}
        />
    )
}
