import React from 'react'
import {useSelector} from 'react-redux'

import timezones, {getTz} from 'utils/timezones'
import __ from 'utils/i18n'

import {selectServerTime} from 'modules/system/serverTime/selectors'

export default function TimeInfo() {
    const {timestamp} = useSelector(selectServerTime)

    return (
        <>
            <div className="about-info">
                <p className="hint hint--gray">{__('Server time')}</p>
                {timezones.server(timestamp).format('LL – LT')}
            </div>

            <div className="about-info timezone">
                <p className="hint hint--gray">{__('Server timezone')}</p>
                {getTz()} {timezones.server().format('Z z')}
            </div>
        </>
    )
}
