import React from 'react'
import PropTypes from 'prop-types'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/cross.svg'

import path from 'utils/path'

import NavLinkGroup from 'components/Nav/NavLinkGroup'
import NavLink from 'components/Nav/NavLink'
import scopeName from 'constants/navigationScopes'
import {useLocation} from 'react-router'

const activateIfHasScope = (match) => match && match.params.scope

const NavLinkScopesGroup = ({scopes, onRemove, handleClick, ...props}) => {
    const location = useLocation()

    props.path = path(props.link)
    props.to = path(props.link, {})
    props.scopes = scopes
    props.handleClick = handleClick

    return (
        <NavLinkGroup {...props} location={location} activate={activateIfHasScope}>
            {scopes &&
                Object.keys(scopes).map((scope) => (
                    <NavLink
                        className="nav-link-wrapper--action"
                        key={scope}
                        name={scopeName(scopes[scope].name)}
                        to={path(props.link, {scope})}
                    >
                        <div
                            className="nav-link-action"
                            onClick={() => onRemove(props.link, scope)}
                        >
                            <IconRemove className="nav-link-action-icon" />
                        </div>
                    </NavLink>
                ))}
        </NavLinkGroup>
    )
}

NavLinkScopesGroup.propTypes = {
    name: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    Coin: PropTypes.elementType,
    isPermitted: PropTypes.bool,
    scopes: PropTypes.objectOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
        })
    ),
}
export default NavLinkScopesGroup
