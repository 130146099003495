import {__} from 'utils/i18n'

export const rules = {
    name: {
        presence: true,
        length: {
            maximum: 24,
            tooLong: () => __('needs to have less then %{count} characters'),
        },
    },

    description: {
        length: {
            maximum: 1024,
        },
    },

    pmasterGprsSupervision: {
        presence: (value, attributes) => !!attributes.pmasterGprsIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    pmasterGprsTimeout: {
        presence: (value, attributes) => !!attributes.pmasterGprsIsEnabled,
        numericality: (value, {pmasterGprsSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(pmasterGprsSupervision),
        }),
    },

    pmasterBbaTimeout: {
        presence: true,
        numericality: (value, {pmasterBbaSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(pmasterBbaSupervision),
        }),
    },

    pmasterBbaSupervision: {
        presence: true,
        numericality: {
            onlyInteger: true,
        },
    },

    quazarGprsSupervision: {
        presence: (value, attributes) =>
            !!attributes.quazarGprsIsEnabled || !!attributes.quazarBbaIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    quazarBbaTimeout: {
        presence: (value, attributes) => !!attributes.quazarBbaIsEnabled,
        numericality: (value, {quazarGprsSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(quazarGprsSupervision),
        }),
    },

    quazarGprsTimeout: {
        presence: (value, attributes) => !!attributes.quazarGprsIsEnabled,
        numericality: (value, {quazarGprsSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(quazarGprsSupervision),
        }),
    },

    quazar53BbaSupervision: {
        presence: (value, attributes) => !!attributes.quazar53BbaIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    quazar53BbaTimeout: {
        presence: (value, attributes) => !!attributes.quazar53BbaIsEnabled,
        numericality: (value, {quazar53BbaSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(quazar53BbaSupervision),
        }),
    },

    quazar53GprsSupervision: {
        presence: (value, attributes) => !!attributes.quazar53GprsIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    quazar53GprsTimeout: {
        presence: (value, attributes) => !!attributes.quazar53GprsIsEnabled,
        numericality: (value, {quazar53GprsSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(quazar53GprsSupervision),
        }),
    },

    smartCommAnySupervision: {
        presence: (value, attributes) => !!attributes.smartCommunicationIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    iq4FibroGprsSupervision: {
        presence: (value, attributes) => !!attributes.iq4FibroGprsIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    iq4FibroGprsTimeout: {
        presence: (value, attributes) => !!attributes.iq4FibroGprsIsEnabled,
        numericality: (value, {iq4FibroGprsSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(iq4FibroGprsSupervision),
        }),
    },

    iq4FibroBbaTimeout: {
        presence: (value, attributes) => !!attributes.iq4FibroBbaIsEnabled,
        numericality: (value, {iq4FibroBbaSupervision}) => ({
            onlyInteger: true,
            greaterThan: parseInt(iq4FibroBbaSupervision),
        }),
    },

    iq4FibroBbaSupervision: {
        presence: (value, attributes) => !!attributes.iq4FibroBbaIsEnabled,
        numericality: {
            onlyInteger: true,
        },
    },

    serverMessagingLanguageId: {
        presence: true,
    },

    vodHistorySize: {
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
        },
    },
}
