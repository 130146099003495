import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import EventTypeForm from './EventTypeForm'
import {validation} from './EventTypeFieldset'
import {editEventType} from 'modules/forms/handlers'

export default function EditEventType({hide, eventTypeId}) {
    const {handle, isLoading, form} = useForm(editEventType)
    const handler = (data) => handle({id: eventTypeId, ...data})
    const data = useSelector(({eventTypes}) => eventTypes.store.byIds[eventTypeId])

    return (
        <EventTypeForm
            {...form}
            data={data}
            hide={hide}
            isLoading={isLoading}
            handle={handler}
            header={__('Edit Event Type')}
            rules={validation}
        />
    )
}
