import {createSelector} from 'reselect'

export const selectProcessesListRows = createSelector(
    (state) => state.processes.list.page,
    (state) => state.processes.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectProcessesList = createSelector(
    (state) => state.processes.list,
    (list) => list
)
