import React, {useState} from 'react'
import PropTypes from 'prop-types'

import Radiobox from 'ipmp-react-ui/Radiobox'

import __ from 'utils/i18n'

export default function ShowLabels({onChange, savedLabels}) {
    const [isLabelDisabled, disableLabel] = useState(savedLabels ?? false)

    const handle = (e) => {
        if (!e.target.checked) {
            return null
        }

        disableLabel(e.target.name === 'disabled')
        onChange(e.target.name === 'disabled')
    }

    return (
        <>
            <h3 className="form-section-header">{__('Show only icons')}</h3>

            <Radiobox
                label={__('Yes')}
                name="disabled"
                checked={isLabelDisabled}
                onChange={handle}
            />
            <Radiobox
                label={__('No')}
                name="enabled"
                checked={!isLabelDisabled}
                onChange={handle}
            />
        </>
    )
}

ShowLabels.propTypes = {
    onChange: PropTypes.func.isRequired,
}
