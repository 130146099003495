import {createSelector} from 'reselect'
import {get} from 'lodash-es'
import toArray from 'utils/toArray'

export const selectProcessesByKeys = (state) =>
    get(state, ['processes', 'manager', 'byKeys'], {})

export const selectProcessesAsArray = createSelector(selectProcessesByKeys, (processes) =>
    Object.values(processes)
)

export const selectRunningProcessesByType = createSelector(
    selectProcessesAsArray,
    (state, types) => toArray(types),
    (processes, types) =>
        processes.filter((process) => types.includes(process.type) && process.isRunning)
)

export const selectProcessesManagerByKeys = createSelector(
    (store) => store.processes.manager.byKeys,
    (byKeys) => byKeys
)

export const createTrackedSelector = (key) => {
    return createSelector(
        (store) => store.processes.manager.byKeys[key],
        (store) => store.processes.store.byIds,
        (process, byIds) => process || byIds[key]
    )
}
