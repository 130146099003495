import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

const DSK_LENGTH = 5

export default function DskReportFormFieldset({onChange}) {
    const [focus, setFocus] = useState(0)

    useEffect(() => {
        const inputToFocus = document.querySelector(`input[name="fg-${focus}"]`)
        if (inputToFocus) {
            inputToFocus.focus()
            inputToFocus.select()
        }
    }, [focus])

    return (
        <div className="autolearn-pin-input-fields">
            {Array.from({length: DSK_LENGTH}, (v, i) => (
                <div className="autolearn-pin-input-field" key={`fg-${i}`}>
                    <Input
                        name={`fg-${i}`}
                        dataTestId={`fg-${i}`}
                        onInput={({target}) => {
                            onChange(i, target.value)
                            target.value && setFocus(i === DSK_LENGTH - 1 ? 0 : i + 1)
                        }}
                        maxLength={1}
                        autoFocus={i === 0}
                    />
                </div>
            ))}
        </div>
    )
}

DskReportFormFieldset.propTypes = {
    onChange: PropTypes.func.isRequired,
}
