import React, {PureComponent, useContext} from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import {ReactComponent as CheckIcon} from 'ipmp-react-ui/icons/check.svg'
import {ReactComponent as DeliveredIcon} from 'ipmp-react-ui/icons/events/delivered.svg'
import {ReactComponent as FailedIcon} from 'ipmp-react-ui/icons/events/failed.svg'
import {ReactComponent as NotDeliveredIcon} from 'ipmp-react-ui/icons/alert.svg'
import {ReactComponent as ProcessingIcon} from 'ipmp-react-ui/icons/events/processing.svg'
import {ReactComponent as InfoIcon} from 'ipmp-react-ui/icons/info.svg'
import {ReactComponent as QueuedIcon} from 'ipmp-react-ui/icons/events/queued.svg'
import {ReactComponent as BinaryIcon} from 'ipmp-react-ui/icons/binary-file.svg'
import {ReactComponent as ProtocolIcon} from 'ipmp-react-ui/icons/protocol-file.svg'

import Button from 'ipmp-react-ui/Button'
import Tooltip, {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'
import Definition from 'ipmp-react-ui/Definition'
import Chips, {Chip} from 'ipmp-react-ui/Chips'

import EventContext from 'pages/Events/EventContext'
import stopPropagation from 'utils/stopPropagation'

import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
    EDS_VIEW_LONG,
} from 'constants/eventDelivery'
import {useCountDeliveranceStatus} from 'hooks/events/deliverance'

import {
    getSeverityCssClass,
    getSeverityIcon,
    isGeneratedEvents,
} from 'constants/eventSeverity'

import {Partitions} from 'components/Partitions'
import TooltipList, {TooltipListItem} from './TooltipList'

import __ from 'utils/i18n'

export class ActionCell extends PureComponent {
    onClick = (e) => {
        e.stopPropagation()
        this.props.onClick && this.props.onClick()
    }

    render() {
        const {isChecked, disabled, Icon} = this.props

        if (isChecked) {
            return (
                <center>
                    <CheckIcon />
                </center>
            )
        }

        return (
            <Button
                Icon={Icon}
                primary
                small
                disabled={disabled}
                onClick={this.onClick}
            />
        )
    }
}

export const EventDescriptionCell = ({
    severity,
    description,
    zone,
    appointment,
    partitions,
}) => {
    const Icon = getSeverityIcon(severity)

    const Detail = (
        <EventAppointmentCell
            zone={zone}
            appointment={appointment}
            partitions={partitions}
            description={description}
        />
    )

    return (
        <Definition
            className={'severity severity--' + getSeverityCssClass(severity)}
            Icon={Icon}
            iconClassName="severity-icon"
            title={description}
            detail={Detail}
            multiLine
        />
    )
}

export const SeverityCell = ({severity, description}) => {
    const Icon = getSeverityIcon(severity)

    return (
        <span className={'severity severity--' + getSeverityCssClass(severity)}>
            <Icon className="severity-icon" />
            {description}
        </span>
    )
}

export const EventAppointmentCell = ({zone, appointment, partitions, description}) => {
    const isGeneratedEvent = isGeneratedEvents(description)

    if (!isGeneratedEvent && !zone && description !== 'Invalid User Code') {
        return <span>{appointment}</span>
    }

    return (
        <div className="appointment">
            {zone && `#${zone}`}
            <OverflowTextTooltip className="appointment-text" tooltip={appointment}>
                {appointment}
            </OverflowTextTooltip>
            {partitions && (
                <Tooltip tooltip={partitions} inline={true}>
                    <Partitions
                        partitions={partitions.map((str) => Number(str.replace('P', '')))}
                    />
                </Tooltip>
            )}
        </div>
    )
}

export const EventDeliveranceCell = ({event, edsView}) => {
    const {deliverance} = event
    const queued = useCountDeliveranceStatus(deliverance, EDS_QUEUED)
    const processing = useCountDeliveranceStatus(deliverance, EDS_PROCESSING)
    const delivered = useCountDeliveranceStatus(deliverance, EDS_SUCCESS)
    const retry = useCountDeliveranceStatus(deliverance, EDS_RETRY)
    const failed = useCountDeliveranceStatus(deliverance, EDS_FAILED)

    const eventTooltip = (
        <EventDeliveranceTooltip {...{queued, processing, delivered, retry, failed}} />
    )

    const {handleEventSelect} = useContext(EventContext)

    const onShowBlind = (e) => {
        handleEventSelect(event)
        stopPropagation(e)
    }

    return (
        <Tooltip
            className="event-deliverance"
            tooltipContainerClass="eventDelivery-tooltip"
            tooltip={eventTooltip}
            orientation="bottom"
            onClick={onShowBlind}
        >
            {edsView === EDS_VIEW_LONG ? (
                <Chips>
                    <Chip className="eventDeliveryStatus-queued">
                        <QueuedIcon />
                        {queued}
                    </Chip>
                    <Chip className="eventDeliveryStatus-processing">
                        <ProcessingIcon />
                        {processing}
                    </Chip>
                    <Chip className="eventDeliveryStatus-retry">
                        <NotDeliveredIcon />
                        {retry}
                    </Chip>
                    <Chip className="eventDeliveryStatus-failed">
                        <FailedIcon />
                        {failed}
                    </Chip>
                    <Chip className="eventDeliveryStatus-delivered">
                        <DeliveredIcon />
                        {delivered}
                    </Chip>
                </Chips>
            ) : (
                <Chips>
                    {queued > 0 && (
                        <Chip className="eventDeliveryStatus-queued">
                            <QueuedIcon />
                            {queued}
                        </Chip>
                    )}
                    {processing > 0 && (
                        <Chip className="eventDeliveryStatus-processing">
                            <ProcessingIcon />
                            {processing}
                        </Chip>
                    )}
                    {retry > 0 && (
                        <Chip className="eventDeliveryStatus-retry">
                            <NotDeliveredIcon />
                            {retry}
                        </Chip>
                    )}
                    {failed > 0 && (
                        <Chip className="eventDeliveryStatus-failed">
                            <FailedIcon />
                            {failed}
                        </Chip>
                    )}
                    {delivered > 0 && (
                        <Chip className="eventDeliveryStatus-delivered">
                            <DeliveredIcon />
                            {delivered}
                        </Chip>
                    )}
                </Chips>
            )}
        </Tooltip>
    )
}

export const EventDeliveranceTooltip = ({
    queued,
    processing,
    delivered,
    retry,
    failed,
}) => {
    return (
        <div className="eventDeliveryStatus-tooltip">
            <h5 className="deliverance-heading">{__('Delivery Status')}</h5>
            <ul className="deliverance-tooltip-list">
                <li className="deliverance-tooltip-list-item">
                    <span>{__('Queued')}</span>
                    <span className="list-item-divider" />
                    <Chip className="eventDeliveryStatus-queued">
                        <QueuedIcon />
                        {queued}
                    </Chip>
                </li>
                <li className="deliverance-tooltip-list-item">
                    <span>{__('Processing')}</span>
                    <span className="list-item-divider" />
                    <Chip className="eventDeliveryStatus-processing">
                        <ProcessingIcon />
                        {processing}
                    </Chip>
                </li>
                <li className="deliverance-tooltip-list-item">
                    <span>{__('Delivered')}</span>
                    <span className="list-item-divider" />
                    <Chip className="eventDeliveryStatus-delivered">
                        <DeliveredIcon />
                        {delivered}
                    </Chip>
                </li>
                <li className="deliverance-tooltip-list-item">
                    <span>{__('Not Delivered')}</span>
                    <span className="list-item-divider" />
                    <Chip className="eventDeliveryStatus-retry">
                        <NotDeliveredIcon />
                        {retry}
                    </Chip>
                </li>
                <li className="deliverance-tooltip-list-item">
                    <span>{__('Failed')}</span>
                    <span className="list-item-divider" />
                    <Chip className="eventDeliveryStatus-failed">
                        <FailedIcon />
                        {failed}
                    </Chip>
                </li>
            </ul>
            <EventLineChart {...{queued, processing, delivered, retry, failed}} />
        </div>
    )
}

export const EventLineChart = ({
    queued,
    processing,
    delivered,
    retry,
    failed,
    withCounter = false,
}) => {
    if (withCounter) {
        return (
            <div>
                <div className="overall-deliverance">
                    <div className="queued deliverance-status-counter">
                        {__('Queued')}
                        <span>{queued}</span>
                    </div>
                    <div className="processing deliverance-status-counter">
                        {__('Processing')}
                        <span>{processing}</span>
                    </div>
                    <div className="delivered deliverance-status-counter">
                        {__('Delivered')}
                        <span>{delivered}</span>
                    </div>
                    <div className="retry deliverance-status-counter">
                        {__('Retry')}
                        <span>{retry}</span>
                    </div>
                    <div className="event-failed deliverance-status-counter">
                        {__('Failed')}
                        <span>{failed}</span>
                    </div>
                </div>
                <div className="event-linechart">
                    <div className="queued status-line" style={{flexGrow: ++queued}} />
                    <div
                        className="processing status-line"
                        style={{flexGrow: ++processing}}
                    />
                    <div
                        className="delivered status-line"
                        style={{flexGrow: ++delivered}}
                    />
                    <div className="retry status-line" style={{flexGrow: ++retry}} />
                    <div
                        className="event-failed status-line"
                        style={{flexGrow: ++failed}}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="event-linechart">
            <div className="queued status-line" style={{flexGrow: queued}} />
            <div className="processing status-line" style={{flexGrow: processing}} />
            <div className="delivered status-line" style={{flexGrow: delivered}} />
            <div className="retry status-line" style={{flexGrow: retry}} />
            <div className="event-failed status-line" style={{flexGrow: failed}} />
        </div>
    )
}

export const EventRawInfoCell = ({row: {binMessage, sourceProtocol}}) => {
    if (!binMessage && !sourceProtocol) {
        return null
    }
    const Content = (
        <TooltipList title={__('Event Information')}>
            {sourceProtocol && (
                <TooltipListItem Icon={ProtocolIcon}>{sourceProtocol}</TooltipListItem>
            )}

            {binMessage && (
                <TooltipListItem Icon={BinaryIcon} monospace>
                    {nl2br(binMessage)}
                </TooltipListItem>
            )}
        </TooltipList>
    )

    return (
        <Tooltip tooltip={Content}>
            <InfoIcon />
        </Tooltip>
    )
}

EventRawInfoCell.propTypes = {
    binMessage: PropTypes.string,
    sourceProtocol: PropTypes.string,
}
