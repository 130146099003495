import React from 'react'
import {useSelector} from 'react-redux'

import page from 'permissions/panel/log/page'
import {withPermissionRejection} from 'containers/withPermission'
import {PANEL_FAMILY_SMART_COMMUNICATOR} from 'constants/panelFamily'

import GenericLogs from './GenericLogs'
import SystemLogs from './SystemLogs'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {selectPanel} from 'modules/panels/store/selectors'

function Logs() {
    const panelId = useRouterPanelId()
    const panel = useSelector((state) => selectPanel(state, {panelId}))

    if (panel.smartCommPanel || panel.model === PANEL_FAMILY_SMART_COMMUNICATOR) {
        return <SystemLogs panel={panel} />
    }

    return <GenericLogs panel={panel} />
}

export default withPermissionRejection(page)(Logs)
