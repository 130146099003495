import React, {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import Buttons from 'ipmp-react-ui/Buttons'
import Definition from 'ipmp-react-ui/Definition'

import path from 'utils/path'
import IconBack from 'ui/IconBack'
import useSelection from 'hooks/useSelection'
import FirmwarePager from './GridBar/FirmwarePager'
import FirmwareDropDown from './GridBar/FirmwareDropDown'
import UpgradeByFilters from './GridBar/Buttons/UpgradeByFilters'
import {selectApplianceTypeAndPackageName} from 'modules/firmware/store/selectors'

export default function FirmwareUpgradeGridBar() {
    const params = useParams()
    const navigate = useNavigate()
    const {selection} = useSelection()
    const {applianceType, packageName} = useSelector((state) =>
        selectApplianceTypeAndPackageName(state, {params})
    )

    const handleGoBack = () => navigate(path('firmware'))

    return (
        <Fragment>
            <Bar>
                <Button onClick={handleGoBack} className="btn--goBack">
                    <IconBack />
                </Button>

                <Definition
                    className="title"
                    title={applianceType}
                    detail={packageName}
                    multiLine
                />

                <BarSpace />

                <FirmwarePager />
            </Bar>

            <Bar>
                <FirmwareDropDown />
                <Buttons>
                    <UpgradeByFilters
                        applianceType={applianceType}
                        selection={selection}
                    />
                </Buttons>
            </Bar>
        </Fragment>
    )
}
