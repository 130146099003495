import moment, {isMoment} from 'moment'

function parseFormat(value, format, strict) {
    const m = moment(value, format, strict)

    if (m.isValid()) {
        return m
    }
}

function parseMoment(value) {
    if (isMoment(value)) {
        return value
    }
}

export function parseSingleValue(value, strict) {
    if (typeof value === 'string') {
        value = value.trim()
    }

    if (value) {
        const format = moment.localeData().longDateFormat('L')

        return parseMoment(value) || parseFormat(value, format, strict)
    }

    return undefined
}

export default function parse(value, strict = false) {
    let from, to

    if (parseMoment(value)) {
        return {from: value}
    }

    if (value instanceof Date) {
        from = value
        to = null
    } else if (value instanceof Object) {
        from = value.from
        to = value.to
    } else if (value) {
        const values = value
            .trim()
            .replace(/[^\d/\\.]+/g, '—')
            .split('—')

        if (values.length > 2) {
            return
        }

        ;[from, to] = values
    }

    from = parseSingleValue(from, strict)
    to = parseSingleValue(to, strict)

    if (from > to) {
        ;[from, to] = [to, from]
    }

    return {from, to}
}

export function stringify(value, format = 'L', fallback = '') {
    if (!value) {
        return fallback
    }

    if (moment.isMoment(value) || value instanceof Date) {
        return moment(value).format(format)
    }

    const {from, to} = value
    return stringify(from) + (to ? ' — ' + stringify(to) : fallback)
}

export function getValidMomentDate(value) {
    if (!value) {
        return moment()
    } else {
        return moment(value, 'L', moment.locale())
    }
}

export function parseTime(value) {
    if (moment.isMoment(value)) {
        return value
    }

    if (typeof value === 'undefined') {
        return moment()
    }

    const timeFormat = moment.localeData().longDateFormat('LT')

    return moment(`2000-01-01 ${value}`, `YYYY-MM-DD ${timeFormat}`)
}
