import React from 'react'

import __ from 'utils/i18n'
import {
    batteryLevelIcon,
    batteryStatus,
    isBatteryLevelValid,
} from 'constants/signalLevelType'
import {getTransportProtocolTitle, getTransportLinkTitle} from 'constants/protocols'

export default function DeviceVersionInfo({device}) {
    if (
        !device.traits?.firmware?.version &&
        !device.deviceId &&
        !device.traits?.battery
    ) {
        return null
    }

    const battery = device.traits?.battery
    const BatteryIcon = battery ? batteryLevelIcon(battery?.status) : null
    const status = batteryStatus(battery?.status)
    const batteryLevel = isBatteryLevelValid(battery?.level, battery?.status)
        ? battery?.level + '% '
        : __('Unknown ')

    return (
        <div className="device__info">
            {device.traits?.firmware?.version && (
                <div className="device__info-row">
                    <div className="device__info-label">{__('SW/FW version')}</div>
                    <div className="device__info-value">
                        {device.traits.firmware.version}
                    </div>
                </div>
            )}

            {device.deviceId && (
                <div className="device__info-row">
                    <div className="device__info-label">{__('Enrollment ID')}</div>
                    <div className="device__info-value">{device.deviceId}</div>
                </div>
            )}
            {device.traits?.transport?.link && (
                <div className="device__info-row">
                    <div className="device__info-label">{__('Link')}</div>
                    <div className="device__info-value">
                        {getTransportLinkTitle(device.traits.transport.link)}
                    </div>
                </div>
            )}
            {device.traits?.transport?.protocol && (
                <div className="device__info-row">
                    <div className="device__info-label">{__('Protocol')}</div>
                    <div className="device__info-value">
                        {getTransportProtocolTitle(device.traits.transport.protocol)}
                    </div>
                </div>
            )}
            {battery && (
                <>
                    {batteryLevel && (
                        <div className="device__info-row">
                            <div className="device__info-label">
                                {__('Battery level')}
                            </div>
                            <div className="device__info-value">
                                {batteryLevel}
                                <BatteryIcon className="device-icon" />
                            </div>
                        </div>
                    )}
                    {status && (
                        <div className="device__info-row">
                            <div className="device__info-label">
                                {__('Battery status')}
                            </div>
                            <div className="device__info-value">{status}</div>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
