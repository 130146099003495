import React from 'react'

import {useActions} from 'modules/higherOrder/useActions'
import {showChangePanelGroupModal} from 'modules/runners/store/actions'
import {ChangeGroupBase} from 'pages/Panels/GridBar/DropDowns/Items/ChangeGroupItem'

export default function ChangeGroupItem() {
    const onClick = useActions(showChangePanelGroupModal)

    return <ChangeGroupBase onClick={onClick} />
}
