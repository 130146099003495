import React from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {enablePlinkLogging} from 'modules/forms/handlers'
import PlinkLogging, {rules} from './Fieldset/PlinkLogging'

export default function EnablePlinkLogging({hide, panelId}) {
    const {form, handle, isLoading} = useForm(enablePlinkLogging)

    const handler = (data) => {
        handle(panelId, data)
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            submitLabel={__('Enable')}
            header={__('Enable Plink Logging')}
        >
            <PlinkLogging />
        </ModalCardForm>
    )
}

EnablePlinkLogging.propTypes = {
    panelId: PropTypes.number,
    hide: PropTypes.func,
}
