import React, {useMemo} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import reduce from 'lodash-es/reduce'
import get from 'lodash-es/get'
import nl2br from 'react-nl2br'

import Ava, {AvaIcon} from 'ipmp-react-ui/Ava'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/user.svg'
import {ReactComponent as IconEmail} from 'ipmp-react-ui/icons/email.svg'
import {ReactComponent as IconPhone} from 'ipmp-react-ui/icons/phone.svg'
import {ReactComponent as IconRemark} from 'ipmp-react-ui/icons/remark.svg'
import {ReactComponent as IconLocation} from 'ipmp-react-ui/icons/location.svg'
import {ReactComponent as IconInfo} from 'ipmp-react-ui/icons/info.svg'
import Definition from 'ipmp-react-ui/Definition'

import TooltipList, {TooltipListItem} from 'ui/TooltipList'
import Each from 'ui/Each'

import __ from 'utils/i18n'

export const USER_TYPE_OPERATOR = 'operator'
export const USER_TYPE_INSTALLER = 'installer'
export const USER_TYPE_CUSTOMER = 'customer'
export const USER_TYPE_OWNER = 'owner'

const isSquare = (type) => {
    switch (type) {
        case USER_TYPE_CUSTOMER:
        case USER_TYPE_OWNER:
            return true
    }

    return false
}

export function Avatar({id, name, email, type, isActive, className}) {
    const label = name ? name : email

    // display Empty User Avatar
    if (!label) {
        return (
            <AvaIcon
                Icon={IconUser}
                square={isSquare(type)}
                className={classes(className, {'ava--active': isActive})}
            />
        )
    }

    return <Ava id={id} name={label} square={isSquare(type)} className={className} />
}

Avatar.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    type: PropTypes.oneOf([
        USER_TYPE_OPERATOR,
        USER_TYPE_INSTALLER,
        USER_TYPE_CUSTOMER,
        USER_TYPE_OWNER,
    ]),
    className: PropTypes.string,
    isActive: PropTypes.bool,
}

const icons = {
    name: IconUser,
    email: IconEmail,
    phone: IconPhone,
    location: IconLocation,
    remark: IconRemark,
    remark2: IconRemark,
    remark3: IconRemark,
}

const prepare = (name, email, phone, location, remark, remark2, remark3) =>
    reduce(
        {name, email, phone, location, remark, remark2, remark3},
        (acc, value, index) => {
            if (!value) {
                return acc
            }

            acc.push({
                Icon: get(icons, index, IconInfo),
                value: nl2br(value),
            })

            return acc
        },
        []
    )

function Content({items}) {
    if (items.length === 0) {
        return null
    }

    return (
        <TooltipList title={__('User Information')}>
            <Each
                of={items}
                render={({Icon, value}) => (
                    <TooltipListItem Icon={Icon}>{value}</TooltipListItem>
                )}
            />
        </TooltipList>
    )
}

Content.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
            value: PropTypes.any,
        })
    ),
}

export default function User({
    type,
    withInfo,
    withTooltip,
    className,
    id,
    name,
    email,
    phone,
    remark,
    remark2,
    remark3,
    location,
}) {
    const items = useMemo(
        () => prepare(name, email, phone, location, remark, remark2, remark3),
        [name, email, phone, location, remark, remark2, remark3]
    )

    let Trigger = (
        <Avatar
            name={name}
            email={email}
            id={id}
            type={type}
            isActive={items.length > 0}
        />
    )

    if (withInfo) {
        Trigger = (
            <div className={classes('user-info-full', className)}>
                {Trigger}
                <Definition title={name} detail={email} multiLine />
            </div>
        )
    }

    if (!withTooltip) {
        return Trigger
    }

    // if there is nothing to show in tooltip return component without tooltip
    if (items.length === 0) {
        return Trigger
    }

    return (
        <Tooltip
            inline
            className="tooltipList-container"
            tooltip={<Content items={items} />}
        >
            {Trigger}
        </Tooltip>
    )
}

User.propTypes = {
    type: PropTypes.oneOf([
        USER_TYPE_OPERATOR,
        USER_TYPE_INSTALLER,
        USER_TYPE_CUSTOMER,
        USER_TYPE_OWNER,
    ]),
    withInfo: PropTypes.bool,
    withTooltip: PropTypes.bool,
    className: PropTypes.string,

    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    remark: PropTypes.string,
    remark2: PropTypes.string,
    remark3: PropTypes.string,
    location: PropTypes.string,
}
