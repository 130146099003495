import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {setLabel} from 'permissions/panel/customers/actions'
import {USER_FEATURE_EDITABLE_LABEL} from 'constants/features'
import {showSetPanelUserLabelModal} from 'modules/modals/actions'
import {selectUserEditableFeature} from 'modules/features/store/selectors'

const ButtonVisibility = withVisibility()(Button)

export default function SetLabelButton({panelId, panelUserId, isPinSet, userLabel}) {
    const {isPermitted} = usePermission(setLabel)
    const isFeatureEnabled = useSelector((state) =>
        selectUserEditableFeature(state, {
            panelId,
            item: USER_FEATURE_EDITABLE_LABEL,
        })
    )

    const {onClick} = useActions(
        {
            onClick: () => showSetPanelUserLabelModal({panelId, panelUserId, userLabel}),
        },
        [userLabel]
    )

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconEdit}
            onClick={onClick}
            isVisible={isPermitted}
            disabled={!(isFeatureEnabled && isPinSet)}
        />
    )
}
