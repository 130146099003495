import React from 'react'
import map from 'lodash-es/map'
import head from 'lodash-es/head'
import {useParams} from 'react-router'
import {Route, Routes, Navigate} from 'react-router-dom'
import Logs from '../Logs/Logs'
import State from '../PanelState/PanelState'
import Reports from '../Reports/Reports'
import Devices from '../Devices/Devices'
import Info from '../Info/Info'
import Processes from '../Processes/Processes'
import PanelInteractiveUsers from '../PanelInteractiveUsers/PanelInteractiveUsers'
import PanelInstallers from '../PanelInstallers/PanelInstallers'
import Locations from '../Locations/Locations'
import Configuration from '../Configuration/ConfigurationsPage'
import RemoteInspections from '../RemoteInspections/RemoteInspections'
import Events from '../Events/Events'
import Firmware from '../Firmware/Firmware'
import Keypad from '../Keypad/Keypad'
import Had from 'pages/Panel/Had/Had'
import PanelTest from '../PanelTest/PanelTest'
import {content} from 'permissions/panel/page'
import {withPermissionRejection} from 'containers/withPermission'
import path, {pathLastChild} from 'utils/path'
import {PANEL_TAB} from 'pages/Panel/PanelPageRouting'

const componentsMap = {
    [PANEL_TAB.DEVICES]: <Devices />,
    [PANEL_TAB.INFO]: <Info />,
    [PANEL_TAB.STATE]: <State />,
    [PANEL_TAB.LOG]: <Logs />,
    [PANEL_TAB.PROCESSES]: <Processes />,
    [PANEL_TAB.INTERACTIVE_USERS]: <PanelInteractiveUsers />,
    [PANEL_TAB.INSTALLERS]: <PanelInstallers />,
    [PANEL_TAB.PANEL_TEST]: <PanelTest />,
    [PANEL_TAB.REMOTE_INSPECTIONS]: <RemoteInspections />,
    [PANEL_TAB.EVENTS]: <Events />,
    [PANEL_TAB.FIRMWARE]: <Firmware />,
    [PANEL_TAB.CONFIGURATION]: <Configuration />,
    [PANEL_TAB.LOCATIONS]: <Locations />,
    [PANEL_TAB.REPORTS]: <Reports />,
    [PANEL_TAB.KEYPAD]: <Keypad />,
    [PANEL_TAB.AUTOMATION]: <Had />,
}

const RoutesRejection = withPermissionRejection(content)(Routes)

export default function PanelPageContent({routes, defaultTab}) {
    const params = useParams()

    const children = map(routes, ({path: pathName, hasChildren}, route) => (
        <Route
            path={pathLastChild(pathName) + (hasChildren ? '/*' : '')}
            element={componentsMap[route]}
            key={route}
        />
    ))

    const {path: pathName} =
        (defaultTab && routes[defaultTab]) || head(Object.values(routes))
    children.push(
        <Route
            path="*"
            element={<Navigate replace to={path(pathName, params)} key={pathName} />}
        ></Route>
    )

    return <RoutesRejection>{children}</RoutesRejection>
}
