import {takeEvery, call, put, select, all} from 'redux-saga/effects'
import last from 'lodash-es/last'

import {retrieve} from 'api/events'
import {update} from 'modules/events/store/actions'
import {
    receiveSuggests,
    addFilters,
    setQuery,
    removeFilters,
    clearFilters,
} from 'modules/events/list/actions'
import toIds from 'utils/toIds'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import watch from 'modules/higherOrder/watch'
import {POLL_EVENTS_PANEL} from 'configs/pollers'
import {SEARCH_SUGGESTS_COUNT} from 'constants/search'
import {suggest} from 'api/panel/events'

import {
    fetch,
    receive,
    fetchOlder,
    fetchNewer,
    checkNewer,
    setNewerCount,
    setHasOlder,
    startPoll,
    stopPoll,
    fetchPanelEvents,
    fetchSuggests,
    setPanelEventsFiltered,
    receiveFiltered,
} from './actions'

const limit = 10
const sortField = 'evt_id'
const sortDesc = true

export default function* () {
    yield all([
        takeEvery([fetch, fetchPanelEvents], watch(_fetch)),
        takeEvery([fetchSuggests], watchFetchSuggests),
        createListPollerSaga({startPoll, stopPoll}, POLL_EVENTS_PANEL, _fetch),
        takeEvery([fetchOlder], watchFetchOlder),
        takeEvery([fetchNewer], watchFetchNewer),
        takeEvery([checkNewer], watchCheckNewer),
        takeEvery(
            [addFilters, removeFilters, clearFilters, setQuery],
            watchSetPanelEventsFiltered
        ),
    ])
}

export function* watchSetPanelEventsFiltered() {
    const {panelEventsFiltered} = yield select((panels) => panels.events)

    if (!panelEventsFiltered) {
        yield put(setPanelEventsFiltered(true))
    }
}

export function* watchFetchSuggests({payload}) {
    const {id: panelId} = yield select(
        ({panels}) => last(panels.recent.historyItems).panel
    )
    const {fields, prefix = ''} = payload
    const data = Array.isArray(fields)
        ? fields.reduce((acc, key) => {
              return {
                  ...acc,
                  [key]: prefix,
              }
          }, {})
        : {[fields]: prefix}

    try {
        const result = yield call(suggest, data, 0, SEARCH_SUGGESTS_COUNT, panelId)
        yield put(receiveSuggests(result, prefix))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* _fetch() {
    const {panelId, panelEventsFiltered} = yield select((state) => state.panels.events)
    let {filters, query} = yield select((state) => state.events.list)
    filters = [...filters, {name: 'unt_id', value: panelId}]

    const {rows, count} = yield call(
        retrieve,
        0,
        limit,
        filters,
        sortField,
        sortDesc,
        query
    )

    yield put(update(rows))
    yield put(receive(toIds(rows)))
    if (panelEventsFiltered) {
        yield put(receiveFiltered(toIds(rows)))
        yield put(setPanelEventsFiltered(false))
    }

    const {rows: panelEvents} = yield select((state) => state.panels.events)
    yield put(setHasOlder(count > panelEvents.length))
}

_fetch.onError = function* (error) {
    yield put(receive(error))
}

export function* watchFetchOlder() {
    const {panelId, rows: panelEvents} = yield select((state) => state.panels.events)
    let {filters, query} = yield select((state) => state.events.list)
    filters = [
        ...filters,
        {name: 'unt_id', value: panelId},
        {name: 'evt_id', value: `{<${panelEvents.slice(-1).pop()}}`},
    ]

    try {
        const {rows, count} = yield call(
            retrieve,
            0,
            limit,
            filters,
            sortField,
            sortDesc,
            query
        )
        yield put(update(rows))
        yield put(receive(toIds(rows)))
        yield put(setHasOlder(count > panelEvents.length))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* watchFetchNewer() {
    const {panelId, rows, newerCount} = yield select((state) => state.panels.events)
    const filters = {
        panelId,
        id: `{>${getMaxEventId(rows)}}`,
    }

    try {
        const {rows} = yield call(retrieve, 0, newerCount, filters, sortField, sortDesc)

        yield put(update(rows))
        yield put(receive(toIds(rows)))
        yield put(setNewerCount(0))
    } catch (error) {
        yield put(receive(error))
    }
}

export function* watchCheckNewer() {
    const {panelId, rows} = yield select((state) => state.panels.events)
    const filters = {
        panelId,
        id: `{>${getMaxEventId(rows)}}`,
    }

    try {
        const {rows, count} = yield call(retrieve, 0, 1, filters, sortField, sortDesc)

        yield put(update(rows))
        yield put(setNewerCount(count))
    } catch (error) {
        yield put(receive(error))
    }
}

function getMaxEventId(rows) {
    return rows.length > 0 ? rows[0] : 0
}
