import {put} from 'redux-saga/effects'

import {EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION} from 'constants/autolearn'

import {addDeviceComplete} from '../actions'

export default function* (session, message) {
    if (EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION !== message.event) {
        return null
    }

    const {automation_id, device_type, device_name, state, status} = message.information

    yield put(
        addDeviceComplete(session, {
            id: automation_id,
            type: device_type,
            name: device_name,
            state,
            status,
        })
    )
}
