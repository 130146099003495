import React, {PureComponent} from 'react'
import {set} from 'immutable-modify'

import ModalCardForm from 'ui/ModalCardForm'
import NavTabsForm from './NavTabsForm'
import {rules} from './GroupsRules'
import Main from './TabsContent/Main'
import PowerMaster from './TabsContent/PowerMaster'
import Quazar from './TabsContent/Quazar'
import Quazar53 from './TabsContent/Quazar53'
import SmartCommunicator from './TabsContent/SmartCommunicator'
import IQ4 from './TabsContent/IQ4'
import General from './TabsContent/General'

import {MAIN_INFORMATION, tabsErrors} from 'constants/groups'
import __ from 'utils/i18n'

export default class GroupFieldset extends PureComponent {
    constructor(props, context) {
        super(props, context)
        this.state = {
            data: props.data,
            activeTab: props.activeTab || MAIN_INFORMATION,
            error: '',
            hasJustReceivedError: false,
        }
    }

    handleFieldChanged = (path, value) => {
        this.setState(set(this.state, `data.${path}`, value))
    }

    handleSubmit = () => {
        this.setState({hasJustReceivedError: false})
        this.props.handle(this.state.data)
    }

    handleTabClick = (tabName) => {
        this.setState({activeTab: tabName})
    }

    getValidationErrors = (errors) => {
        if (errors) {
            const [error] = Object.keys(errors)
            this.setState({error})
            this.setActiveTabWithError(error)
        }
    }

    handleQuazarSupervison = (value) => {
        let data = {...this.state.data}
        data = set(data, `communications.quazar.gprs.supervision`, value)
        data = set(data, `communications.quazar.bba.supervision`, value)

        this.setState({data})
    }

    setActiveTabWithError = (error) => {
        for (const tab in tabsErrors) {
            const errTab = tabsErrors[tab].find((i) => i === error)
            if (errTab) {
                this.setState({activeTab: tab, hasJustReceivedError: false})
            }
        }
    }

    static getDerivedStateFromProps({errors}, {hasJustReceivedError}) {
        if (errors) {
            const [error] = Object.keys(errors)
            for (const tab in tabsErrors) {
                const errTab = tabsErrors[tab].find((i) => i === error)
                if (errTab && !hasJustReceivedError) {
                    return {activeTab: tab, hasJustReceivedError: true}
                }
            }
        }
        return null
    }

    render() {
        const {errors} = this.props
        const {...props} = this.props
        const {data, activeTab} = this.state
        return (
            <ModalCardForm
                className="group-form-content"
                wide={4}
                confirmOnDismiss
                onSubmit={this.handleSubmit}
                dissmissLabel={__('Cancel')}
                rules={rules}
                receiveErrors={this.getValidationErrors}
                {...props}
            >
                <NavTabsForm
                    activeTab={activeTab}
                    isActiveTab={this.isActiveTab}
                    handleTabClick={this.handleTabClick}
                />
                <div className={'group-form-tabs-content'}>
                    <Main
                        name={data.name}
                        description={data.description}
                        activeTab={activeTab}
                        handleFieldChanged={this.handleFieldChanged}
                        linkedToDealer={data.linkedToDealer}
                    />
                    <PowerMaster
                        upgradeMethod={data.upgradeMethod}
                        pmasterCommunications={data.communications.pmaster}
                        BBAEncryption={data.BBAEncryption}
                        activeTab={activeTab}
                        handleFieldChanged={this.handleFieldChanged}
                        errors={errors}
                    />
                    <Quazar
                        data={data}
                        activeTab={activeTab}
                        handleFieldChanged={this.handleFieldChanged}
                        quazarCommunications={data.communications.quazar}
                        handleQuazarSupervison={this.handleQuazarSupervison}
                        errors={errors}
                    />
                    <Quazar53
                        data={data}
                        activeTab={activeTab}
                        quazar53Communications={data.communications.quazar53}
                        handleFieldChanged={this.handleFieldChanged}
                        errors={errors}
                    />
                    <SmartCommunicator
                        data={data}
                        activeTab={activeTab}
                        smartCommunications={data.communications.smart_comm}
                        handleFieldChanged={this.handleFieldChanged}
                        errors={errors}
                    />
                    <IQ4
                        settingsFibro={data.communications.iq4_fibro}
                        activeTab={activeTab}
                        handleFieldChanged={this.handleFieldChanged}
                        errors={errors}
                    />
                    <General
                        data={data}
                        activeTab={activeTab}
                        handleFieldChanged={this.handleFieldChanged}
                    />
                </div>
            </ModalCardForm>
        )
    }
}
