import React from 'react'

import Page from 'ipmp-react-ui/Page'

import PushBasicConfigurationGridBar from './PushBasicConfigurationGridBar'
import PushBasicConfigurationTable from './PushBasicConfigurationTable'
import PushBasicConfigurationSearch from './PushBasicConfigurationSearch'

export default function PushBasicConfigurationPage() {
    return (
        <Page
            Top={PushBasicConfigurationSearch}
            Bar={PushBasicConfigurationGridBar}
            Content={PushBasicConfigurationTable}
        />
    )
}
