import React from 'react'

import {pathParent} from 'utils/path'
import GroupPage from './GroupPage'

export default class {
    path = pathParent('group')

    element = (<GroupPage />)
}
