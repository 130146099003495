import {DTP_POWERG, DTP_ZWAVE} from './protocols'

export const AUTOLEARN_PROTOCOLS = {
    [DTP_POWERG]: 'PowerG',
    [DTP_ZWAVE]: 'Z-Wave',
}

export const SECURITY_SENSORS = 'SECURITY_SENSORS'
export const AUTOMATION_DEVICE_PG = 'AUTOMATION_DEVICE_PG'
export const AUTOMATION_DEVICE_ZWAVE = 'AUTOMATION_DEVICE_ZWAVE'

export const AUTOLEARN_PROTOCOLS_FEATURES_MAP = new Map([
    [AUTOMATION_DEVICE_PG, DTP_POWERG],
    [AUTOMATION_DEVICE_ZWAVE, DTP_ZWAVE],
])

export const AUTOLEARN_SESSION_TIMEOUT = 60 // max value: 10 min (measure in sec)

export const EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION =
    'EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION'
export const EVENT_ADD_SENSOR_CONFIRMATION = 'EVENT_ADD_SENSOR_CONFIRMATION'
export const EVENT_NOTIFICATION_MESSAGE = 'EVENT_NOTIFICATION_MESSAGE'
export const EVENT_ZWAVE_DSK_DEVICE_ENROLMENT = 'EVENT_ZWAVE_DSK_DEVICE_ENROLMENT'
export const EVENT_PANEL_EVENT = 'PANEL_EVENT'
export const EVENT_NEW_SECURITY_DEVICE_ADDED = 'NEW_SECURITY_DEVICE_ADDED'
export const EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE =
    'EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE'
export const EVENT_START_ZONE_DISCOVERY = 'START_ZONE_DISCOVERY'

export const MESSAGE_ZWAVE_KEYS_REPORT = 'MESSAGE_ZWAVE_KEYS_REPORT'
export const MESSAGE_ZWAVE_DSK_REPORT = 'MESSAGE_ZWAVE_DSK_REPORT'

export const AUTOLEARN_POWERG_CONFIRMATION_PIN_LENGTH = 8

export const getAutomationFeaturesAutolearnProtocols = (featuresAutolearn) =>
    featuresAutolearn
        .filter((feature) => AUTOLEARN_PROTOCOLS_FEATURES_MAP.has(feature))
        .map((feature) => AUTOLEARN_PROTOCOLS_FEATURES_MAP.get(feature))
