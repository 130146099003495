import {warn} from 'utils/log'
import __ from 'utils/i18n'
import {
    HAD_STATE_OFF,
    HAD_STATE_ON,
    HAD_STATE_STATELESS,
    HAD_STATE_STATELESS_TOGGLE,
} from 'constants/had/hadState'

export const switcherTypeStateTitleMap = new Map([
    [HAD_STATE_ON, () => __('Turned On')],
    [HAD_STATE_OFF, () => __('Turned Off')],
    [HAD_STATE_STATELESS, () => __('Stateless switcher')],
    [HAD_STATE_STATELESS_TOGGLE, () => __('Stateless toggle')],
])

export const switcherTypeActionTitleMap = new Map([
    [HAD_STATE_ON, () => __('Turn On')],
    [HAD_STATE_OFF, () => __('Turn off')],
])

const switcherTypeStateTitle = (state) => {
    let result = switcherTypeStateTitleMap.get(state?.pgm)

    if (!result) {
        warn(`No home automation device (switch) state title for ${state}`)
        result = () => __('Undefined')
    }

    return result()
}

export const switcherTypeStateTitleByPgmState = (pgmState) => {
    let result = switcherTypeStateTitleMap.get(pgmState)

    if (!result) {
        warn(`No home automation device (switch) state title for ${pgmState}`)
        result = () => __('Undefined')
    }

    return result()
}

export default switcherTypeStateTitle
