import React from 'react'
import {useSelector} from 'react-redux'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import {selectZoneTypeNameById} from 'modules/zoneTypes/store/selectors'

export default function ZoneTypeCell({row: {zoneTypeId}}) {
    const {name} = useSelector((state) => selectZoneTypeNameById(state, {id: zoneTypeId}))

    return (
        <OverflowTextTooltip className={'evenRules-elements-name'}>
            {name}
        </OverflowTextTooltip>
    )
}
