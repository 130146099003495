import React, {Component, Fragment} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {setQuery, showChanged} from 'modules/basicConfiguration/one/actions'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'
import {__} from 'utils/i18n'

class BasicConfigurationQuickSearch extends Component {
    static propTypes = {
        query: PropTypes.string.isRequired,
        onSearch: PropTypes.func.isRequired,
        handleFilterChanged: PropTypes.func.isRequired,
    }

    handleOnChange = (e) => {
        const {onSearch} = this.props
        onSearch(e.target.value)
    }

    handleOnClear = () => {
        const {onSearch} = this.props
        onSearch('')
    }

    handleFilterChanged = (e) => {
        const {handleFilterChanged} = this.props
        handleFilterChanged(e.target.checked)
    }

    render() {
        const {query, showChanged} = this.props

        return (
            <Fragment>
                <Input
                    placeholder={__('Quick search')}
                    value={query || ''}
                    onChange={this.handleOnChange}
                    Icon={query ? IconCross : null}
                    onIconClick={this.handleOnClear}
                />

                <Checkbox
                    onChange={this.handleFilterChanged}
                    defaultChecked={showChanged}
                    label={__('Show only changed')}
                />
            </Fragment>
        )
    }
}

export default compose(
    connect(
        ({basicConfiguration: {one}}, {params}) => {
            const basicConfigId = params.id

            return {
                query: one[basicConfigId].query,
                showChanged: one[basicConfigId].showChanged,
            }
        },
        (dispatch, {params}) =>
            bindActionCreators(
                {
                    onSearch: (...arg) => setQuery(params.id, ...arg),
                    handleFilterChanged: (...arg) => showChanged(params.id, ...arg),
                },
                dispatch
            )
    )
)(BasicConfigurationQuickSearch)
