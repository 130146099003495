export const DEFAULT_THEME = 'automatic'
export const DARK_THEME = 'valencia'
export const MARGIN = 16

export const DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT = 61

export const LIGHT_CLASSES = ['theme--sanMarino']
export const DARK_CLASSES = ['theme--dark', 'theme--valencia']

export const isDarkTheme = (theme) => theme === DARK_THEME

export const isOSDarkTheme = () =>
    window.matchMedia('(prefers-color-scheme: dark)').matches

export const getThemeClasses = () => (isOSDarkTheme() ? DARK_CLASSES : LIGHT_CLASSES)

export const getOSTheme = () => window.matchMedia('(prefers-color-scheme: dark)')

export default {
    automatic: {
        title: 'Automatic',
        classList: getThemeClasses(),
    },
    sanmarino: {
        title: 'SanMarino',
        classList: LIGHT_CLASSES,
    },
    valencia: {
        title: 'Valencia',
        classList: DARK_CLASSES,
    },
}
