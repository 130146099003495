import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {suspendFaults} from 'permissions/panels/selection'
import {withSelectionHandler} from 'containers/withSelection'
import {showSuspendFaultsModal} from 'modules/modals/actions'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export default function SuspendFaultsItem() {
    const {isPermitted} = usePermission(suspendFaults)
    const onClick = useActions(showSuspendFaultsModal)

    return (
        <ConnectedMenuItem onClick={onClick} isVisible={isPermitted}>
            {__('Suspend faults')}
        </ConnectedMenuItem>
    )
}
