import React, {Component, useCallback} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import ModalCardForm from 'ui/ModalCardForm'

import {__} from 'utils/i18n'
import head from 'lodash-es/head'
import useUsers from 'hooks/pages/useUsers'
import {selectUsersByIds} from 'modules/users/list/selectors'
import RemoveUsersReject from './RemoveUser/RemoveUsersReject'
import RemoveUserReject from './RemoveUser/RemoveUserReject'

class RemoveUserBase extends Component {
    static propTypes = {
        userIds: PropTypes.arrayOf(PropTypes.number),
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isRemovable: PropTypes.bool,
            })
        ),
        hide: PropTypes.func.isRequired,
        handle: PropTypes.func,
    }

    static defaultProps = {
        users: [],
    }

    unRemovableUsers() {
        return this.props.users.filter(({isRemovable}) => !isRemovable)
    }

    submit = () => {
        this.props.handle()
        this.props.hide()
    }

    getMessage = (users) => {
        return users.length > 1
            ? __('Do you really want to remove %d users?', users.length)
            : __('Do you really want to remove the user: %s?', users[0].name)
    }

    render() {
        const unRemovableUsers = this.unRemovableUsers()
        const {hide, users} = this.props
        const header = users.length > 1 ? __('Remove users') : __('Remove user')
        const message = this.getMessage(users)

        switch (unRemovableUsers.length) {
            case 0:
                return (
                    <ModalCardForm
                        hide={hide}
                        header={header}
                        submitLabel={__('Remove')}
                        onSubmit={this.submit}
                    >
                        {message}
                    </ModalCardForm>
                )

            case 1:
                return (
                    <RemoveUserReject
                        hide={hide}
                        header={header}
                        user={head(unRemovableUsers)}
                    />
                )

            default:
                return (
                    <RemoveUsersReject
                        hide={hide}
                        header={header}
                        users={unRemovableUsers}
                    />
                )
        }
    }
}

export default function RemoveUser({hide, userIds}) {
    const {remove} = useUsers()
    const byIds = useSelector(selectUsersByIds)
    const handle = useCallback(() => remove(userIds), [userIds])
    const users = Object.values(byIds).filter(({id}) => userIds.indexOf(id) !== -1)

    return <RemoveUserBase hide={hide} userIds={userIds} handle={handle} users={users} />
}
