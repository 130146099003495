import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useInstallers from 'hooks/pages/useInstallers'
import {withVisibility} from 'containers/withVisibility'
import {reject as rejectPermission} from 'permissions/installers/actions'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedButton = compose(withVisibility(), withSelectionHandler())(Button)

export default function InstallersRejectButton() {
    const {reject} = useInstallers()
    const {isPermitted} = usePermission(rejectPermission)

    const onClick = (selection) => reject(selection)
    return (
        <ConnectedButton isVisible={isPermitted} label={__('Reject')} onClick={onClick} />
    )
}
