import {warn} from 'utils/log'
import {__} from 'utils/i18n'

export const DSC_NETWORK_PROTOCOL_TCP = 'NP_TCP'
export const DSC_NETWORK_PROTOCOL_UDP = 'NP_UDP'

export const DSC_NETWORK_PROTOCOL_TCP_LABEL = 'TCP'
export const DSC_NETWORK_PROTOCOL_UDP_LABEL = 'UDP'

export const DSC_APPLICATION_PROTOCOL_ITV2 = 'AP_ITV2'
export const DSC_APPLICATION_PROTOCOL_DLS = 'AP_DLS'

export const DSC_APPLICATION_PROTOCOL_ITV2_LABEL = 'ITV2'
export const DSC_APPLICATION_PROTOCOL_DLS_LABEL = 'DLS'

export const dlsNetworkProtocols = [DSC_NETWORK_PROTOCOL_TCP, DSC_NETWORK_PROTOCOL_UDP]

export const dlsApplicationProtocols = [
    DSC_APPLICATION_PROTOCOL_ITV2,
    DSC_APPLICATION_PROTOCOL_DLS,
]

// Device transport protocol
export const DTP_UNKNOWN = 0
export const DTP_POWERG = 1
export const DTP_POWERG_PLUS = 2
export const DTP_SRF = 3
export const DTP_MX = 4
export const DTP_CORBUS = 5
export const DTP_NFC = 6
export const DTP_ZWAVE = 7
export const DTP_ZIGBEE = 8
export const DTP_BLUETOOTH = 9

export const DTP_PREFIX = 'DTP_'
export const DTP_UNKNOWN_NAME = 'DTP_UNKNOWN'
export const DTP_POWERG_NAME = 'DTP_POWERG'
export const DTP_POWERG_PLUS_NAME = 'DTP_POWERG_PLUS'
export const DTP_SRF_NAME = 'DTP_SRF'
export const DTP_MX_NAME = 'DTP_MX'
export const DTP_CORBUS_NAME = 'DTP_CORBUS'
export const DTP_NFC_NAME = 'DTP_NFC'
export const DTP_ZWAVE_NAME = 'DTP_ZWAVE'
export const DTP_ZIGBEE_NAME = 'DTP_ZIGBEE'
export const DTP_BLUETOOTH_NAME = 'DTP_BLUETOOTH'

// Device transport link
export const DTL_UNKNOWN = 0
export const DTL_RADIO = 1
export const DTL_WIRED = 2

export const getDlsNetworkProtocolLabel = (protocol) => {
    switch (protocol) {
        case DSC_NETWORK_PROTOCOL_TCP:
            return DSC_NETWORK_PROTOCOL_TCP_LABEL
        case DSC_NETWORK_PROTOCOL_UDP:
            return DSC_NETWORK_PROTOCOL_UDP_LABEL
    }

    warn(`Unknown protocol ${protocol}`)

    return null
}

export const getDlsApplicationProtocolLabel = (protocol) => {
    switch (protocol) {
        case DSC_APPLICATION_PROTOCOL_ITV2:
            return DSC_APPLICATION_PROTOCOL_ITV2_LABEL
        case DSC_APPLICATION_PROTOCOL_DLS:
            return DSC_APPLICATION_PROTOCOL_DLS_LABEL
    }

    warn(`Unknown protocol ${protocol}`)

    return null
}

export const getTransportLinkTitle = (link) => {
    switch (link) {
        case DTL_UNKNOWN:
            return __('Unknown')
        case DTL_RADIO:
            return __('Radio')
        case DTL_WIRED:
            return __('Wired')
        default:
            warn(`Unknown transport link ${link}`)
    }
}

export const getTransportProtocolTitle = (protocol) => {
    switch (protocol) {
        case DTP_UNKNOWN:
            return __('Unknown')
        case DTP_POWERG:
            return __('PowerG')
        case DTP_POWERG_PLUS:
            return __('PowerG+')
        case DTP_SRF:
            return __('SRF')
        case DTP_MX:
            return __('MX')
        case DTP_CORBUS:
            return __('Corbus')
        case DTP_NFC:
            return __('NFC')
        case DTP_ZWAVE:
            return __('Z-Wave')
        case DTP_ZIGBEE:
            return __('Zigbee')
        case DTP_BLUETOOTH:
            return __('Bluetooth')
        default:
            warn(`Unknown transport protocol ${protocol}`)
    }
}

export const getProtocolBySubtype = (subtype) => {
    if (subtype && subtype.includes('ZWAVE')) {
        return DTP_ZWAVE_NAME
    }

    return DTP_UNKNOWN_NAME
}
