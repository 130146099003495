export const EMERGENCY_ACTIONS_FIRE = 'FIRE'
export const EMERGENCY_ACTIONS_PANIC = 'PANIC'
export const EMERGENCY_ACTIONS_SILENT_PANIC = 'SILENT_PANIC'
export const EMERGENCY_ACTIONS_EMERGENCY = 'EMERGENCY'
export const EMERGENCY_ACTIONS_SILENT_EMERGENCY = 'SILENT_EMERGENCY'

export const IS_UNHANDLED_EVENTS_VIEWED_FIRST = '-isViewed'
export const TYPE_EMERGENCY_FIRE = 'fire'
export const TYPE_EMERGENCY_EMERGENCY = 'emergency'
export const TYPE_EMERGENCY_SECURITY = 'panic'
export const TYPE_EMERGENCY_SILENT_SECURITY = 'silent_panic'
export const TYPE_EMERGENCY_SILENT_EMERGENCY = 'silent_emergency'

export const emergencyTypes = [
    TYPE_EMERGENCY_FIRE,
    TYPE_EMERGENCY_EMERGENCY,
    TYPE_EMERGENCY_SECURITY,
    TYPE_EMERGENCY_SILENT_SECURITY,
    TYPE_EMERGENCY_SILENT_EMERGENCY,
]
