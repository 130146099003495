import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {unassign} from 'permissions/installers/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'
import {unassignPanel} from 'modules/installerPanels/list/actions'

const ButtonVisibility = compose(withVisibility(), withConfirmation())(Button)

export default function UnassignPanelCell({row: {id, serial}}) {
    const {isPermitted} = usePermission(unassign)

    const {onClick} = useActions({
        onClick: () => unassignPanel(id),
    })

    return (
        <ButtonVisibility
            small
            flat
            Icon={IconMinus}
            onClick={onClick}
            isVisible={isPermitted}
            message={__('Do you really want to unassign %s panel?', serial)}
            title={__('Unassign panel')}
        />
    )
}
