import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'
import {selectPgmActivationTimeSlot} from 'modules/system/settings/selectors'

const DEFAULT_TIME_WINDOW_VALUE = 300

const rules = {
    time_window: {
        presence: (value, attributes) => attributes.enabled,
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 600,
            greaterThanOrEqualTo: 60,
        },
    },
}

const toggleActivationLabel = (status) => (
    <span className="pgmActivationTimeSlot-form-enable-toggle">
        {__('Activation')}
        <em>({status})</em>
    </span>
)

const toggleDisplayZwaveLabel = (status) => (
    <span className="pgmActivationTimeSlot-form-enable-toggle">
        {__('Display Z-wave and PowerG+ devices')}
        <em>({status})</em>
    </span>
)

export default function PgmActivationTimeSlot({hide}) {
    const {timeWindow, enabled, automationDevicesEnabled} = useSelector(
        selectPgmActivationTimeSlot
    )
    const {form, handle, isLoading} = useForm(setSystemSettings.PgmActivationTimeSlot)

    const [isLocalEnabled, setIsLocalEnabled] = useState(enabled)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            isLoading={isLoading}
            onSubmit={(data) => handle(data)}
            header={__('Automation activation settings')}
            className="pgmActivationTimeSlot-form"
        >
            <ToggleBox
                name="enabled"
                label={toggleActivationLabel(__('Enabled'))}
                labelOff={toggleActivationLabel(__('Disabled'))}
                onChange={(e) => setIsLocalEnabled(e.target.checked)}
                defaultChecked={enabled}
            />
            {isLocalEnabled && (
                <Input
                    defaultValue={timeWindow || DEFAULT_TIME_WINDOW_VALUE}
                    label={__('Time Window after Alarm [SEC]')}
                    name="time_window"
                />
            )}
            <ToggleBox
                name="automation_devices_enabled"
                label={toggleDisplayZwaveLabel(__('Enabled'))}
                labelOff={toggleDisplayZwaveLabel(__('Disabled'))}
                defaultChecked={automationDevicesEnabled}
            />
        </ModalCardForm>
    )
}
