import React from 'react'

import {ReactComponent as IconDisconnected} from 'ipmp-react-ui/icons/connection/disconnected.svg'
import {ReactComponent as IconConnected} from 'ipmp-react-ui/icons/connection/connected.svg'
import {ReactComponent as IconOnline} from 'ipmp-react-ui/icons/connection/online.svg'
import {ReactComponent as IconOffline} from 'ipmp-react-ui/icons/connection/offline.svg'
import {ReactComponent as IconKADisabled} from 'ipmp-react-ui/icons/connection/ka-disabled.svg'
import {ReactComponent as IconConfiguredToSlave} from 'ipmp-react-ui/icons/connection/configured-to-slave.svg'
import {ReactComponent as IconConfiguredOutsidePM} from 'ipmp-react-ui/icons/connection/configured-outside-pm.svg'
import {ReactComponent as IconNotConfigured} from 'ipmp-react-ui/icons/connection/not-configured.svg'

import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const CHANNEL_USB = 'CHANNEL_USB'
export const CHANNEL_BBA = 'CHANNEL_BBA'
export const CHANNEL_GPRS = 'CHANNEL_GPRS'
export const CHANNEL_WIFI = 'CHANNEL_WIFI'
export const CHANNEL_BBA_OVER_GPRS = 'CHANNEL_BBA_OVER_GPRS'

export const ENCRYPTION_NO = 'NO_ENCRYPTION'
export const ENCRYPTION_DEFAULT = 'ENCRYPTED'
export const ENCRYPTION_ITV2_V1 = 'ITv2_V1'
export const ENCRYPTION_ITV2_V2 = 'ITv2_V2'
export const ENCRYPTION_DTLS = 'DTLS'

export const STATUS_NOT_CONFIGURED = 'NOT_CONFIGURED'
export const STATUS_DISCONNECTED = 'DISCONNECTED'
export const STATUS_KA_DISABLED = 'KA_DISABLED'
export const STATUS_OFFLINE = 'OFFLINE'
export const STATUS_ONLINE = 'ONLINE'
export const STATUS_CONNECTED = 'CONNECTED'
export const STATUS_CONFIGURED_TO_SLAVE = 'CONFIGURED_TO_SLAVE'
export const STATUS_CONFIGURED_OUTSIDE_PM = 'CONFIGURED_OUTSIDE_PM'

export const PROTOCOL_LWM2M_BOOTSTRAP = 'LWM2M_BOOTSTRAP'
export const PROTOCOL_DLS = 'DLS'

export const getEncryptionTitle = (encryption) => {
    switch (encryption) {
        case ENCRYPTION_DEFAULT:
            return __('Encrypted')
        case ENCRYPTION_ITV2_V1:
            return __('ITv2 type 1')
        case ENCRYPTION_ITV2_V2:
            return __('ITv2 type 2')
        case ENCRYPTION_DTLS:
            return __('DTLS')
        case ENCRYPTION_NO:
            return __('Unencrypted')
        default:
            return '-'
    }
}

export const getChannelTitle = (channelType) => {
    switch (channelType) {
        case CHANNEL_USB:
            return __('USB')
        case CHANNEL_BBA:
            return __('Broadband')
        case CHANNEL_GPRS:
        case CHANNEL_BBA_OVER_GPRS:
            return __('Cellular')
        case CHANNEL_WIFI:
            return __('WI-FI')
        default:
            return '-'
    }
}

export const getConnectionTypeTitle = (protocolName) => {
    switch (protocolName) {
        case PROTOCOL_LWM2M_BOOTSTRAP:
            return __('LWM2M BOOTSTRAP')
        case PROTOCOL_DLS:
            return protocolName
        default:
            return protocolName
    }
}

export const getStatusTitle = (value) => {
    switch (value) {
        case STATUS_NOT_CONFIGURED:
            return __('Not Configured')
        case STATUS_DISCONNECTED:
            return __('Not Connected')
        case STATUS_KA_DISABLED:
            return __('Keep-Alive disabled')
        case STATUS_OFFLINE:
            return __('Offline')
        case STATUS_ONLINE:
            return __('Online')
        case STATUS_CONNECTED:
            return __('Connected')
        case STATUS_CONFIGURED_TO_SLAVE:
            return __('Configured to Slave')
        case STATUS_CONFIGURED_OUTSIDE_PM:
            return __('Configured outside PM')
        default:
            return value
    }
}

export const getStatusIcon = (value) => {
    switch (value) {
        case STATUS_NOT_CONFIGURED:
            return <IconNotConfigured />
        case STATUS_DISCONNECTED:
            return <IconDisconnected />
        case STATUS_KA_DISABLED:
            return <IconKADisabled />
        case STATUS_OFFLINE:
            return <IconOffline />
        case STATUS_ONLINE:
            return <IconOnline />
        case STATUS_CONNECTED:
            return <IconConnected />
        case STATUS_CONFIGURED_TO_SLAVE:
            return <IconConfiguredToSlave />
        case STATUS_CONFIGURED_OUTSIDE_PM:
            return <IconConfiguredOutsidePM />
        default:
            warn(`Unknown status ${value}`)
    }
}
