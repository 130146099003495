import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {__} from 'utils/i18n'
import {discovery as permission} from 'permissions/panels/selection'
import {unitContent} from 'permissions/panel/page'
import {withVisibility} from 'containers/withVisibility'
import {showTriggerDiscoveryModal} from 'modules/modals/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'

const MenuItemVisibility = compose(withVisibility())(MenuItem)

export default function TriggerDiscoveryItem({isContentHidden}) {
    const panelId = useRouterPanelId()
    const {permission: isPermitted, isUnitPermitted} = usePermission({
        permission,
        isUnitPermitted: unitContent,
    })
    const {onClick} = useActions({
        onClick: () => showTriggerDiscoveryModal([panelId]),
    })
    const isFeatureEnabled = useSelector(
        ({features}) => features.store.byIds[panelId]?.discovery?.forceDiscovery
    )
    const isAllowed =
        isPermitted && ((!isContentHidden && isFeatureEnabled) || !isUnitPermitted)

    return (
        <MenuItemVisibility
            panelId={panelId}
            isVisible={isAllowed}
            onClick={onClick}
            Icon={IconDiscovery}
            title={__('Trigger panel discovery')}
            children={__('Trigger Discovery')}
        />
    )
}
