import React from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import list from 'permissions/panel/devices/list'
import AddDeviceButton from './Buttons/AddDeviceButton'
import RefreshGsmButton from './Buttons/RefreshGSMButton'
import RefreshRssiButton from './Buttons/RefreshRssiButton'
import ToggleWalktestButton from './Buttons/ToggleWalktestButton'

export default function DefaultDevicesBar({
    onToggleFaults,
    onToggleNullZones,
    filterTroubles,
    hideNullZones,
    hasNullDevices,
}) {
    const {hasList} = usePermission({hasList: list})

    return (
        <Bar className="devices-bar">
            {hasList && (
                <Checkbox
                    onChange={onToggleFaults}
                    checked={filterTroubles}
                    label={__('Only trouble devices')}
                />
            )}
            {hasList && hasNullDevices && (
                <Checkbox
                    onChange={onToggleNullZones}
                    checked={hideNullZones}
                    label={__('Hide null devices')}
                />
            )}

            <BarSpace />

            <Buttons className="devices-bar-buttons">
                <RefreshGsmButton />
                <RefreshRssiButton />
                <AddDeviceButton />
                <ToggleWalktestButton />
            </Buttons>
        </Bar>
    )
}
DefaultDevicesBar.propTypes = {
    onToggleFaults: PropTypes.func.isRequired,
    filterTroubles: PropTypes.bool,
    onToggleNullZones: PropTypes.func.isRequired,
    hideNullZones: PropTypes.bool,
}
