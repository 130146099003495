import React, {useEffect} from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import {
    EDS_FAILED,
    EDS_PROCESSING,
    EDS_QUEUED,
    EDS_RETRY,
    EDS_SUCCESS,
} from 'constants/eventDelivery'
import {
    addFilters,
    clearFilters,
    removeFilters,
    setQuery,
} from 'modules/events/list/actions'
import {fetchPanelEvents, fetchSuggests} from 'modules/panels/events/actions'
import __ from 'utils/i18n'
import list from 'permissions/events/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import delivery from 'permissions/events/delivery'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import {selectEventList, selectEventsList} from 'modules/events/list/selectors'
import centralStations from 'permissions/centralStations/list'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

const SearchVisibility = withVisibility()(Search)

const getFilters = (isDeliveryPermitted, isCentralStationsPermitted) => {
    const events = useSelector(selectEventList) || {}
    const {panelId} = useSelector(({panels}) => panels.one)

    let hasEventsVideo = []
    let hasDeliverance = []

    Object.keys(events).map((key) => {
        events[key].hasVideo &&
            events[key].panelId === Number(panelId) &&
            hasEventsVideo.push(key)
        events[key].deliverance &&
            events[key].deliverance.length > 0 &&
            events[key].panelId === Number(panelId) &&
            hasDeliverance.push(key)
    })

    let filters = [
        new TranslateSuggestFilter('severity', __('Severity'), {
            alarm: __('Alarm'),
            alert: __('Alert'),
            offline: __('Offline'),
            online: __('Online'),
            notice: __('Notice'),
            restore: __('Restore'),
            duress: __('Duress'),
            securityopenclose: __('Security open/close'),
        }),

        new DateFilter('datetime', __('Date')),

        new SuggestFilter('description', __('Event Description')),

        new ValuesFilter('sourceProtocol', __('Source Protocol'), {
            sia_over_ip: __('SIA over IP'),
            lwm2m: __('LWM2M'),
            fibro: __('Fibro'),
            itv2: __('ITv2'),
            PowerManage: __('PowerManage'),
        }),
    ]

    if (hasEventsVideo.length > 0) {
        filters.push(
            new ValuesFilter('camera', __('Has Video'), {
                '{>=1}': __('Yes'),
                0: __('No'),
            })
        )
    }

    if (isDeliveryPermitted) {
        if (hasDeliverance.length === 0) {
            return filters.sort((item, next) => item.title.localeCompare(next.title))
        }

        filters = [
            ...filters,
            new ValuesFilter('eds_status', __('Delivery status'), {
                [EDS_QUEUED]: __('Queued'),
                [EDS_PROCESSING]: __('Processing'),
                [EDS_SUCCESS]: __('Success'),
                [EDS_RETRY]: __('Retry'),
                [EDS_FAILED]: __('Failed'),
            }),

            new DateFilter('eds_changed_timestamp', __('Delivery status changed')),
        ]
    }

    if (isCentralStationsPermitted) {
        filters.push(new SuggestFilter('cls_name', __('Central Station')))
    }

    return filters.sort((item, next) => item.title.localeCompare(next.title))
}
export default function PanelEventsSearch({className}) {
    const {scope} = useParams()
    const {filters: selected, query, suggests} = useSelector(selectEventsList)
    const {isVisible, isDeliveryPermitted, isCentralStationsPermitted} = usePermission({
        isVisible: list,
        isDeliveryPermitted: delivery,
        isCentralStationsPermitted: centralStations,
    })

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetchPanelEvents,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    useEffect(() => {
        onClear()
    }, [])

    const filters = getFilters(isDeliveryPermitted, isCentralStationsPermitted)

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            key={scope}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isVisible}
            className={className}
            placeholder={__('Search Panel Events')}
        />
    )
}
