import {warn} from 'utils/log'
import __ from 'utils/i18n'
import {HAD_WATER_VALVE_CLOSED, HAD_WATER_VALVE_OPENED} from 'constants/had/hadState'

const waterValveTypeStateTitleMap = new Map([
    [HAD_WATER_VALVE_OPENED, () => __('Opened')],
    [HAD_WATER_VALVE_CLOSED, () => __('Closed')],
])

const waterValveTypeStateTitle = (state) => {
    let result = waterValveTypeStateTitleMap.get(state)

    if (!result) {
        warn(`No home automation device (water valve) state title for ${state}`)
        result = () => __('Undefined')
    }

    return result()
}

export default waterValveTypeStateTitle
