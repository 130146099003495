import React, {useEffect, useState} from 'react'
import _sortBy from 'lodash-es/sortBy'

import {Option} from 'ipmp-react-ui/Select'
import Autocomplete from 'ipmp-react-ui/Autocomplete'

import __ from 'utils/i18n'
import StringValue from './StringValue'

function AutocompleteStringValue({value, onChange, error, items}) {
    const [options, setOptions] = useState([])

    useEffect(() => {
        const optionsLocal = items.map(([, label]) => label)
        if (!optionsLocal.includes(value)) {
            optionsLocal.unshift(value)
        }

        setOptions(_sortBy(optionsLocal))
    }, [items, value])

    const onChangeLocal = (e, value) => {
        onChange(value)
    }

    const creatableValueResolver = (value) => {
        onChange(value)

        return value
    }

    return (
        <Autocomplete
            isCreatable
            value={value}
            error={error}
            onSet={onChangeLocal}
            maxOptionsToShow={options.length}
            creatableValueResolver={creatableValueResolver}
            placeholder={__('Not set')}
        >
            {options.map((label, index) => (
                <Option label={label} value={label} key={index} />
            ))}
        </Autocomplete>
    )
}

export default class AutocompleteString extends StringValue {
    handleSelectSet = (rawValue) => {
        const value = this.sanitize(rawValue)

        this.props.onChange(value, !this.validate(value))
    }

    render() {
        const {
            value,
            item: {items},
        } = this.props

        if (items) {
            return (
                <AutocompleteStringValue
                    value={value}
                    items={items}
                    onChange={this.handleSelectSet}
                    error={this.validate(value)}
                />
            )
        }

        return super.render()
    }
}
