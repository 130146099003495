import React from 'react'
import PropTypes from 'prop-types'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__, __n} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const SmartCommunicator = ({settings: {isEnabled, supervision}}) => {
    return (
        <>
            {!isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Supervision')}</InfoCardTileTitle>
                    <InfoCardTileContent>{convertConfigValue(false)}</InfoCardTileContent>
                </InfoCardTile>
            )}

            {isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Supervision Period')}</InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(__n('%d second', '%d seconds', supervision))}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
        </>
    )
}

SmartCommunicator.propTypes = {
    isEnabled: PropTypes.bool,
    supervision: PropTypes.number,
}

export default SmartCommunicator
