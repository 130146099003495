import {MODULE_TYPE_BBA, MODULE_TYPE_GPRS, MODULE_TYPE_WIFI} from 'constants/moduleTypes'
import {handleActions} from 'redux-actions'
import get from 'lodash-es/get'

import {
    update,
    clear,
    setRefreshing,
    setActivationStatus,
    setActivationFailed,
    setActivationSuccess,
    setEncryptionState,
    purge,
} from './actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'
import {setTestStatusId, updateTestsHistory, updateTestsStatuses} from '../one/actions'

const defaultState = {
    byIds: {},
    assignedByIds: {},
}

export default handleActions(
    {
        [update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                const panel = {
                    ...old,
                    ...row,
                    info: {
                        ...get(old, 'info', {}),
                        ...get(row, 'info', {}),
                    },
                }

                return {
                    ...acc,
                    [row.id]: panel,
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [setActivationStatus](state, {payload}) {
            const {panelId, isActivating} = payload

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        isFailed: false,
                        isActivating: isActivating,
                        activatingStarted: true,
                    },
                },
            }
        },

        [updateTestsHistory](state, {payload}) {
            const {panelId, ...incomingData} = payload.payload
            const oldTestsData = state.byIds[panelId]?.testsData || []
            const updatedTestData =
                incomingData.testsData &&
                incomingData.testsData.map((incomingTest) => {
                    const matchedTest = oldTestsData.find((oldTest) => {
                        if (incomingTest?.testDeviceId) {
                            return (
                                oldTest?.testDeviceId === incomingTest?.testDeviceId &&
                                oldTest?.testType === incomingTest?.testType
                            )
                        } else {
                            return oldTest?.testType === incomingTest?.testType
                        }
                    })

                    if (matchedTest) {
                        return {
                            ...matchedTest,
                            ...incomingTest,
                        }
                    }

                    return incomingTest
                })

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        testsData: updatedTestData,
                    },
                },
            }
        },

        [updateTestsStatuses](state, {payload}) {
            const {panelId, ...incomingData} = payload.payload
            const oldTestsData = state.byIds[panelId]?.testsData || []
            const updatedTestData =
                incomingData.testsData &&
                oldTestsData.map((oldTest) => {
                    const matchedTest = Object.values(incomingData?.testsData).find(
                        (incomingTest) => {
                            if (incomingTest?.testDeviceId) {
                                return (
                                    oldTest?.testDeviceId ===
                                        incomingTest?.testDeviceId &&
                                    oldTest?.testType === incomingTest?.testType
                                )
                            } else {
                                return oldTest?.testType === incomingTest?.testType
                            }
                        }
                    )

                    if (matchedTest) {
                        return matchedTest
                    }

                    return oldTest
                })

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        testsData: [...updatedTestData],
                    },
                },
            }
        },

        [setTestStatusId](state, {payload: {panelId, processes}}) {
            const testsData = state.byIds[panelId]?.testsData
            const newTestsData = testsData
                ? testsData.map((test) => {
                      const matchingTest = processes.find((process) => {
                          return test?.testType === process.testType
                      })

                      if (matchingTest) {
                          return {
                              ...test,
                              processToken: matchingTest.processToken,
                          }
                      }

                      return test
                  })
                : []

            const newStartedTest = processes.map((process) => {
                const matchedProcess =
                    testsData &&
                    testsData.find((test) => test?.testType === process.testType)

                if (!matchedProcess) {
                    return {
                        testType: process.testType,
                        batchId: process.batchId,
                        processToken: process.processToken,
                    }
                }
            })

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        testsData: [...newTestsData, ...newStartedTest],
                    },
                },
            }
        },

        [setActivationSuccess](state, {payload}) {
            const {panelId} = payload

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        isActivated: true,
                        isActivating: false,
                    },
                },
            }
        },

        [setActivationFailed](state, {payload}) {
            const {panelId, error} = payload

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        isFailed: true,
                        isActivating: false,
                        activationError: error,
                        activatingStarted: false,
                    },
                },
            }
        },

        [setRefreshing](state, {payload: {isRefreshing}}) {
            return {
                ...state,
                isRefreshing,
            }
        },

        [clear]() {
            return defaultState
        },

        [setEncryptionState](state, {payload}) {
            const {id: panelId, channel, encrypted} = payload

            const set = (module, type) =>
                type === channel ? {...module, encrypted} : module

            return {
                ...state,
                byIds: {
                    ...state.byIds,
                    [panelId]: {
                        ...state.byIds[panelId],
                        modules: {
                            bba: set(state.byIds[panelId].modules.bba, MODULE_TYPE_BBA),
                            wifi: set(
                                state.byIds[panelId].modules.wifi,
                                MODULE_TYPE_WIFI
                            ),
                            gprs: set(
                                state.byIds[panelId].modules.gprs,
                                MODULE_TYPE_GPRS
                            ),
                        },
                    },
                },
            }
        },
        ...createPurgeStoreHandler(purge),
    },
    defaultState
)
