import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'

import Page from 'ipmp-react-ui/Page'

import usePanels from 'hooks/pages/usePanels'
import PanelsGridBar from './PanelsGridBar'
import PanelsWithBlinds from './PanelsWithBlinds'
import {selectBillingStatusView} from 'modules/settings/selectors'

export default function PanelsPage() {
    const panels = usePanels()
    const billingStatusView = useSelector(selectBillingStatusView)

    useEffect(() => {
        panels.startPoll()

        return panels.stopPoll
    }, [])

    return (
        <Page
            {...panels}
            billingStatusView={billingStatusView}
            Bar={PanelsGridBar}
            Content={PanelsWithBlinds}
        />
    )
}
