const TIMERS_SLOWDOWN_RATIO = 2

export const POLL_DEVICES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_DEVICES_BACKGROUND = POLL_DEVICES * 10
export const POLL_PGMS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_WALKTEST = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_STATE = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_STATE_BACKGROUND = POLL_STATE * 10
export const POLL_PANEL_REMOTE_INSPECTIONS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANEL_LOCATIONS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANEL_IS_ONLINE = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANELS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_EVENT_VIDEO = TIMERS_SLOWDOWN_RATIO * 2000
export const POLL_VIDEO_ON_DEMAND = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_UPGRADE_STATUS = TIMERS_SLOWDOWN_RATIO * 15000
export const POLL_UPGRADE_RUNNERS_STATUS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_REPORTS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_SESSION = TIMERS_SLOWDOWN_RATIO * 7500
export const POLL_ACTION_LOG = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_USERS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_ROLES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PROCESSES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_DASHBOARD = TIMERS_SLOWDOWN_RATIO * 60000
export const POLL_BATCHES = TIMERS_SLOWDOWN_RATIO * 1500
export const POLL_BATCH_RUNNERS = TIMERS_SLOWDOWN_RATIO * 1000
export const POLL_BATCHES_LIST = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_RUNNERS_LIST = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PROCESS_MANAGER = TIMERS_SLOWDOWN_RATIO * 1000
export const POLL_RUNNER_STATUS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_CS_LIST = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANEL_INTERACTIVE_USERS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_INTERACTIVE_USERS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_INSTALLERS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANEL_INSTALLERS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_EVENT_RULES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_ZONE_TYPES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_NOTIFICATION_TYPES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_EVENT_TYPES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_STATE_TYPES = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_EVENTS = TIMERS_SLOWDOWN_RATIO * 500
export const POLL_EVENTS_PANEL = TIMERS_SLOWDOWN_RATIO * 2500
export const POLL_HAD = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_CS_GROUPS = TIMERS_SLOWDOWN_RATIO * 3000
export const POLL_PANEL_REMARKS = TIMERS_SLOWDOWN_RATIO * 2500
export const POLL_TIME_INFO = TIMERS_SLOWDOWN_RATIO * 500
export const POLL_AUTOLEARN = TIMERS_SLOWDOWN_RATIO * 500
export const POLL_BILLING_STATUS = TIMERS_SLOWDOWN_RATIO * 15000
