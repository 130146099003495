import React from 'react'
import PropTypes from 'prop-types'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__, __n} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

export default function PowerMasterCommunications({
    settings: {bba, gprs},
    isEncryptionEnabled,
    upgradeMethod,
}) {
    return (
        <>
            {gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>
                        {__('Cellular Supervision period')}
                    </InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(
                            __n('%d second', '%d seconds', gprs.supervision)
                        )}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
            {gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Cellular Offline Timer')}</InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(__n('%d second', '%d seconds', gprs.timeout))}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Broadband Supervision period')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(__n('%d second', '%d seconds', bba.supervision))}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Broadband Offline Timer')}</InfoCardTileTitle>
                <InfoCardTileContent>{`${bba.timeout} seconds`}</InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Broadband Encryption')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(isEncryptionEnabled)}
                </InfoCardTileContent>
            </InfoCardTile>
            {!gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Cellular Supervision')}</InfoCardTileTitle>
                    <InfoCardTileContent>{convertConfigValue(false)}</InfoCardTileContent>
                </InfoCardTile>
            )}
            <InfoCardTile>
                <InfoCardTileTitle>{__('Upgrade Method')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(
                        upgradeMethod === 'gprs' ? __('Cellular') : __('Broadband')
                    )}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

PowerMasterCommunications.propTypes = {
    settings: PropTypes.objectOf(
        PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })
    ),
    isEncryptionEnabled: PropTypes.bool,
}
