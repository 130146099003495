import React, {Component} from 'react'
import {useSelector} from 'react-redux'
import has from 'lodash-es/has'

import configuration from 'permissions/panel/configuration/page'
import video from 'permissions/panel/devices/video/tab'
import meteo from 'permissions/panel/devices/meteo/tab'
import gsmRssiChart from 'permissions/panel/devices/gsmRssiChart/tab'

import GeneralInfoTab from './Tabs/GeneralInfoTab'
import ParentTab from './Tabs/ParentTab'
import ChildrenTab from './Tabs/ChildrenTab'
import ConfigurationTab from './Tabs/ConfigurationTab'
import ChartsTab from './Tabs/ChartsTab'
import VideoOnDemandTab from './Tabs/VideoOnDemandTab'

import Tabs from 'ipmp-react-ui/Tabs'
import {__} from 'utils/i18n'
import deviceSectionName from 'utils/configuration/deviceSectionName'

import {selectDeviceInfo} from 'modules/devices/list/selectors'
import {selectPanelFirmwareByDeviceType} from 'modules/panels/firmware/selectors'
import {
    selectDownloadConfigutionFeature,
    selectVod,
} from 'modules/features/store/selectors'
import SidebarTabsLayout from 'ui/SidebarTabsLayout'
import {DEVICE_TYPE_VIRTUAL_KEYPAD, DEVICE_TYPE_ZONE} from 'constants/deviceType'
import {PANEL_FAMILY_SMART_COMMUNICATOR} from 'constants/panelFamily'
import usePermission from 'hooks/usePermission'
import DeviceVersionInfo from './DeviceVersionInfo'

class DeviceSidebar extends Component {
    /**
     * IMPORTANT
     * returns index according with order in render method
     */
    get defaultTabIndex() {
        const {startWithVideoOnDemandTab, startWithMeteoTab} = this.props

        // first tab is general
        const tabsAvailable = [
            this.hasParent,
            this.hasChildren,
            this.hasConfiguration,
            this.hasCharts,
            this.hasFirmware,
            this.hasVOD,
        ]

        if (startWithVideoOnDemandTab && this.hasVOD) {
            return tabsAvailable.filter((isAvailable) => !!isAvailable).length
        } else if (startWithMeteoTab && this.hasCharts) {
            return tabsAvailable
                .slice(0, -1) // remove last VOD tab
                .filter((isAvailable) => !!isAvailable).length
        }

        return 0
    }

    get hasParent() {
        return this.props.parent
    }

    get hasChildren() {
        return this.props.childDevices && this.props.childDevices.length > 0
    }

    get hasConfiguration() {
        if (
            (this.props.device.deviceType === DEVICE_TYPE_ZONE ||
                this.props.device.deviceType === DEVICE_TYPE_VIRTUAL_KEYPAD) &&
            this.props.panel.model === PANEL_FAMILY_SMART_COMMUNICATOR
        ) {
            return null
        }
        return (
            this.props.isConfigurationAllowed &&
            deviceSectionName(this.props.device, this.props.panel)
        )
    }

    get hasCharts() {
        return this.props.hasMeteo && this.props.isMeteoAllowed
    }

    get hasVOD() {
        return (
            this.props.isVideoOnDemandAllowed &&
            this.props.hasVideoOnDemand &&
            !this.props.device.preenroll
        )
    }

    get hasFirmware() {
        return this.props.firmware && has(this.props.firmware, 'packages')
    }

    render() {
        const {
            device,
            panel,
            // firmware,
            // firmwareRunner,
            childDevices,
            parent,
            panelId,
            hasTemperature,
            hasBrightness,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
            hasGsmRssi,
            isGsmRssiChartAllowed,
        } = this.props

        const configurationSectionName = deviceSectionName(device, panel)

        return (
            <Tabs Layout={SidebarTabsLayout} defaultTabIndex={this.defaultTabIndex}>
                {/* Tabs order is important. See defaultTabIndex method */}
                <GeneralInfoTab
                    name={__('General')}
                    device={device}
                    panelId={panelId}
                    parent={parent}
                />

                {this.hasParent && (
                    <ParentTab name={__('Parent')} device={parent} panel={panel} />
                )}

                {this.hasChildren > 0 && (
                    <ChildrenTab name={__('Children')} devices={childDevices} />
                )}

                {this.hasConfiguration && (
                    <ConfigurationTab
                        name={__('Configuration')}
                        sectionName={configurationSectionName}
                        panelId={panelId}
                        deviceType={device.deviceType}
                        zone={device.zone}
                    />
                )}

                {this.hasCharts && (
                    <ChartsTab
                        name={__('Meteo')}
                        panelId={panelId}
                        device={device}
                        isDefaultTab={startWithMeteoTab}
                        hasTemperature={hasTemperature}
                        hasBrightness={hasBrightness}
                        hasGsmRssi={hasGsmRssi}
                    />
                )}

                {hasGsmRssi && isGsmRssiChartAllowed && (
                    <ChartsTab
                        name={__('Gsm Rssi')}
                        panelId={panelId}
                        device={device}
                        isDefaultTab={startWithMeteoTab}
                        hasGsmRssi={hasGsmRssi}
                    />
                )}

                {this.hasVOD && (
                    <VideoOnDemandTab
                        name={__('Video on demand')}
                        panelId={panelId}
                        device={device}
                        isDefaultTab={startWithVideoOnDemandTab}
                    />
                )}

                {/* {this.hasFirmware && <FirmwareTab
                    name={__('Firmware')}
                    panelId={panelId}
                    runner={firmwareRunner}
                    {...firmware}
                />} */}
            </Tabs>
        )
    }
}

export default function DeviceSidebarMain({
    isNeo,
    startWithVideoOnDemandTab,
    startWithMeteoTab,
    onSelectDevice,
    deviceId,
    panelId,
}) {
    const {
        isConfigurationAllowed,
        isVideoOnDemandAllowed,
        isMeteoAllowed,
        isGsmRssiChartAllowed,
    } = usePermission({
        isConfigurationAllowed: configuration,
        isVideoOnDemandAllowed: video,
        isMeteoAllowed: meteo,
        isGsmRssiChartAllowed: gsmRssiChart,
    })

    const selectorProps = useSelector((state) => {
        const {device, parent, childDevices} = selectDeviceInfo(state, {
            panelId,
            deviceId,
        })

        if (!device) {
            onSelectDevice()

            return {}
        }

        const panel = state.panels.store.byIds[panelId]

        const {isAvailable} = selectDownloadConfigutionFeature(state, {panelId})
        const {
            feature: {isEnabled: isEnabledVod},
        } = selectVod(state, {panelId})
        const {firmware, runner: firmwareRunner} = selectPanelFirmwareByDeviceType(
            state,
            {panelId, device}
        )

        const hasMeteo = device.traits && !!device.traits.meteo_info
        const hasTemperature = hasMeteo && !!device.traits.meteo_info.temperature
        const hasBrightness = hasMeteo && !!device.traits.meteo_info.brightness
        const hasGsmRssi = has(device, 'traits.signal_level')
        const hasVideoOnDemand = isEnabledVod && device.traits && device.traits.vod

        return {
            device,
            panel,
            firmware,
            firmwareRunner,
            parent,
            childDevices,
            hasMeteo,
            hasTemperature,
            hasBrightness,
            hasVideoOnDemand,
            hasGsmRssi,
            isConfigurationAllowed: isAvailable && isConfigurationAllowed,
        }
    })

    return (
        <>
            <DeviceVersionInfo device={selectorProps.device} />
            <DeviceSidebar
                {...selectorProps}
                isNeo={isNeo}
                key={deviceId}
                panelId={panelId}
                deviceId={deviceId}
                onSelectDevice={onSelectDevice}
                isMeteoAllowed={isMeteoAllowed}
                startWithMeteoTab={startWithMeteoTab}
                isGsmRssiChartAllowed={isGsmRssiChartAllowed}
                isConfigurationAllowed={isConfigurationAllowed}
                isVideoOnDemandAllowed={isVideoOnDemandAllowed}
                startWithVideoOnDemandTab={startWithVideoOnDemandTab}
            />
        </>
    )
}
