import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {closeWaterValve} from 'modules/panels/had/actions'
import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'

const SetWaterValveCloseButton = ({id, deviceType, disabled, label}) => {
    const {onClick} = useActions({
        onClick: closeWaterValve,
    })

    const onClickHandler = (event) => {
        event.stopPropagation()
        onClick({id, deviceType})
    }

    return (
        <Button
            onClick={onClickHandler}
            flat
            label={label ? label : __('Close')}
            disabled={disabled}
        />
    )
}

SetWaterValveCloseButton.propTypes = {
    id: PropTypes.number,
    disabled: PropTypes.bool,
    deviceType: PropTypes.string,
}

export default SetWaterValveCloseButton
