import React, {useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import {useParams} from 'react-router'

import {CollapsibleTabs} from 'ipmp-react-ui/NavTabs/navTabs'

import {pathTrimEndSlash} from 'utils/path'

import VirtualPanelsLinks from './VirtualPanelsLinks'
import {VIRTUAL_PANEL_SETTINGS} from './SettingsPageRouting'

const SettingsPageTabs = ({menu}) => {
    const elementRef = useRef(null)

    const params = useParams()

    useEffect(() => {
        Object.keys(menu).map((key) => {
            const {path: pathName} = menu[key]
            menu[key].to = pathTrimEndSlash(pathName, params)
        })
    }, [menu])

    const menuLinks = Object.keys(menu)
        .filter((value) => menu[value].parent !== VIRTUAL_PANEL_SETTINGS)
        .reduce((links, val) => {
            links[val] = menu[val]
            return links
        }, {})

    return (
        <div ref={elementRef}>
            <CollapsibleTabs
                className="settings-page-tabs"
                menu={menuLinks}
                elementRef={elementRef}
            >
                <VirtualPanelsLinks menu={menu} />
            </CollapsibleTabs>
        </div>
    )
}

SettingsPageTabs.propTypes = {
    menu: PropTypes.object,
}

export default SettingsPageTabs
