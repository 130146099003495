import {handleActions} from 'redux-actions'
import {set} from 'immutable-modify'
import has from 'lodash-es/has'

import {FAULTY_PANELS, SUSPENDED_FAULTS} from 'constants/navigationScopes'

import {
    update,
    revert,
    changePerPage,
    saveSearchScope,
    removeSearchScope,
    setLanguage,
    setAutologout,
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
    setKeypadSoundEnabled,
    setTheme,
    setTemperature,
    setAlarmSound,
    setEDSView,
    updateFromServiceWorker,
    updateMenuOrder,
    resetMenuOrderUpdateFlag,
    setMainMenuSettings,
    setBillingStatusView,
} from './actions'

import {DEFAULT_TEMP_UNIT} from 'utils/temperature'
import {EVENT_SEVERITY_ALARM} from 'constants/eventSeverity'
import {SOUND_KEY_1} from 'audio/sounds'
import {DEFAULT_THEME} from 'constants/themes'
import {DEFAULT_MENU_ORDER, DEFAULT_MENU_ORDER_MANUAL} from 'constants/mainMenu'

export const DEFAULT_ALARM_SOUNDS = {[EVENT_SEVERITY_ALARM]: SOUND_KEY_1}

const defaultState = {
    perPage: {},
    scopes: {
        panels: {
            'faulty-panels': {
                name: FAULTY_PANELS,
                filters: [{name: 'faults', value: 'active', $: 'faults$active'}],
            },
            suspended: {
                name: SUSPENDED_FAULTS,
                filters: [{name: 'faults', value: 'suspended', $: 'faults$suspended'}],
            },
        },
    },
    alarms: {
        isEnabled: true,
        isNotificationsEnabled: true,
        isSoundEnabled: true,
        isKeypadSoundEnabled: true,
        sounds: DEFAULT_ALARM_SOUNDS,
    },
    temperature: DEFAULT_TEMP_UNIT,
    theme: DEFAULT_THEME,
    menuOrder: DEFAULT_MENU_ORDER,
    menuOrderManual: DEFAULT_MENU_ORDER_MANUAL,
    shouldUpdateMenuOrder: false,
}

export default handleActions(
    {
        [update](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },

        [updateFromServiceWorker](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },

        [revert](state, {payload}) {
            return payload
        },

        [changePerPage](state, {payload}) {
            return {
                ...state,
                perPage: {
                    ...state.perPage,
                    ...payload,
                },
            }
        },

        [removeSearchScope](state, {payload: {page, scope}}) {
            const data = {...state.scopes[page]}

            if (!has(data, scope)) {
                return state
            }

            delete data[scope]

            return {
                ...state,
                scopes: {
                    ...state.scopes,
                    [page]: data,
                },
            }
        },

        [saveSearchScope](state, {payload: {page, scope, name, filters}}) {
            const data = {
                ...state.scopes[page],
                [scope]: {
                    name,
                    filters,
                },
            }

            return {
                ...state,
                scopes: {
                    ...state.scopes,
                    [page]: data,
                },
            }
        },

        [updateMenuOrder](state, {payload}) {
            const updatedOrder = {
                ...state.menuOrder,
                ...{[payload]: state.menuOrder[payload] + 1},
            }
            return {
                ...state,
                menuOrder: updatedOrder,
                shouldUpdateMenuOrder: true,
            }
        },

        [resetMenuOrderUpdateFlag](state) {
            return {
                ...state,
                shouldUpdateMenuOrder: false,
            }
        },

        [setLanguage](state, {payload: language}) {
            return {
                ...state,
                language,
            }
        },

        [setTemperature](state, {payload: temperature}) {
            return {
                ...state,
                temperature,
            }
        },

        [setAutologout](state, {payload: autologout}) {
            return {
                ...state,
                autologout,
            }
        },

        [setAlarmsEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isEnabled,
                },
            }
        },

        [setAlarmsNotificationsEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isNotificationsEnabled: isEnabled,
                },
            }
        },

        [setAlarmsSoundEnabled](state, {payload: {isSoundEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isSoundEnabled,
                },
            }
        },

        [setKeypadSoundEnabled](state, {payload: {isEnabled}}) {
            return {
                ...state,
                alarms: {
                    ...state.alarms,
                    isKeypadSoundEnabled: isEnabled,
                },
            }
        },

        [setTheme](state, {payload}) {
            return {
                ...state,
                theme: payload,
            }
        },

        [setEDSView](state, {payload}) {
            return {
                ...state,
                edsView: payload,
            }
        },

        [setBillingStatusView](state, {payload}) {
            return {
                ...state,
                billingStatusView: payload,
            }
        },

        [setMainMenuSettings](state, {payload}) {
            return {
                ...state,
                ...payload,
            }
        },

        [setAlarmSound](state, {payload: {soundKey, eventSeverityKey}}) {
            return set(state, ['alarms', 'sounds', eventSeverityKey], soundKey)
        },
    },
    defaultState
)
