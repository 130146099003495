import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import Empty from 'ui/Empty'

const LabelValue = ({label, missingTitle}) => {
    if (!label) {
        return <Empty>{missingTitle}</Empty>
    }

    return <OverflowTextTooltip tooltip={label}>{label}</OverflowTextTooltip>
}

export default function Row({
    title,
    value,
    missingTitle,
    isDisabled,
    EditButton,
    RemoveButton,
}) {
    return (
        <div
            className={classnames('table-row', {
                'table-row--disabled': isDisabled,
            })}
        >
            <div className="table-cell table-cell--fixed" style={{width: 125}}>
                {title}
            </div>
            <div className="table-cell panelUserInfoTable-labelCell">
                <LabelValue label={value} missingTitle={missingTitle} />
            </div>
            <div className="table-cell table-cell--fixed panelUserInfoTable-row-buttonsCell">
                {EditButton}
                {value && RemoveButton}
            </div>
        </div>
    )
}

Row.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    missingTitle: PropTypes.string,
    isDisabled: PropTypes.bool,
    EditButton: PropTypes.node.isRequired,
    RemoveButton: PropTypes.node,
}
