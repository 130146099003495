import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/firmware/list/actions'
import __ from 'utils/i18n'
import list from 'permissions/firmware/list'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {selectFirmwareList} from 'modules/firmware/list/selectors'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import deviceType, {
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_POWER_LINK,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_AUDIO_VERIFICATION,
} from 'constants/deviceType'

const SearchVisibility = withVisibility()(Search)

export default function FirmwareUpgradeSearch({className}) {
    const {scope} = useParams()
    const {isPermitted} = usePermission(list)
    const {filters: selected, suggests} = useSelector(selectFirmwareList)

    const {apply, onSelect, onDeselect, onClear, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('serial', __('Panel Id')),
        new SuggestFilter('account', __('Account')),
        new SuggestFilter('group', __('Group')),
        new SuggestFilter('model', __('Model')),
        new SuggestFilter(
            DEVICE_TYPE_CONTROL_PANEL,
            deviceType(DEVICE_TYPE_CONTROL_PANEL)
        ),
        new SuggestFilter(DEVICE_TYPE_POWER_LINK, deviceType(DEVICE_TYPE_POWER_LINK)),
        new SuggestFilter(DEVICE_TYPE_WIRED_KEYPAD, deviceType(DEVICE_TYPE_WIRED_KEYPAD)),
        new SuggestFilter(
            DEVICE_TYPE_ZONE_EXPANDER,
            deviceType(DEVICE_TYPE_ZONE_EXPANDER)
        ),
        new SuggestFilter(
            DEVICE_TYPE_OUTPUT_EXPANDER,
            deviceType(DEVICE_TYPE_OUTPUT_EXPANDER)
        ),
        new SuggestFilter(DEVICE_TYPE_POWER_SUPPLY, deviceType(DEVICE_TYPE_POWER_SUPPLY)),
        new SuggestFilter(
            DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
            deviceType(DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS)
        ),
        new SuggestFilter(DEVICE_TYPE_PGH, deviceType(DEVICE_TYPE_PGH)),
        new SuggestFilter(DEVICE_TYPE_COMMUNICATOR, deviceType(DEVICE_TYPE_COMMUNICATOR)),
        new SuggestFilter(
            DEVICE_TYPE_AUDIO_VERIFICATION,
            deviceType(DEVICE_TYPE_AUDIO_VERIFICATION)
        ),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            key={scope}
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onSuggest={onSuggest}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Upgrades')}
        />
    )
}
