import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'
import __ from 'utils/i18n'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'

import * as actions from 'modules/auth/sign/actions'
import {PHONE_NUMBER_CONFIRMATION_SCREEN, LOGIN_SCREEN} from 'constants/loginScreen'
import {AUTHENTICATION_REQUIRED, messageByError} from 'constants/errorType'
import {useActions} from 'modules/higherOrder/useActions'

const PHONE_HEAD_LENGTH = 3
const PHONE_TAIL_LENGTH = 4

const ConfirmationCodeForm = () => {
    const {error, otpLength, userConfirmed, phone, isLoading} = useSelector(
        ({auth: {sign}}) => ({...sign})
    )

    const rules = {
        code: {
            presence: true,
            length: {is: otpLength},
        },
    }

    const [code, setCode] = useState('')
    const {
        confirmCode,
        resendCode,
        setLoginScreenForm,
        clearErrors,
        sendCode,
        setLoading,
    } = useActions({
        confirmCode: actions.confirmCode,
        resendCode: actions.resendCode,
        setLoginScreenForm: actions.setLoginScreenForm,
        clearErrors: actions.clearErrors,
        sendCode: actions.sendCode,
        setLoading: actions.setLoading,
    })

    useEffect(() => {
        userConfirmed && sendCode()
    }, [])

    const handleSubmit = () => {
        clearErrors()
        setLoading(true)
        confirmCode(code)
    }

    const handleResend = (e) => {
        e.preventDefault()
        clearErrors()
        resendCode()
    }

    const handleBack = () => {
        userConfirmed
            ? setLoginScreenForm(LOGIN_SCREEN)
            : setLoginScreenForm(PHONE_NUMBER_CONFIRMATION_SCREEN)
    }

    const onChangeCode = (e) => {
        clearErrors()
        setCode(e.target.value)
    }

    const hidePhone = (phone) => {
        return phone.length > PHONE_HEAD_LENGTH + PHONE_TAIL_LENGTH
            ? phone.substring(0, PHONE_HEAD_LENGTH) +
                  '*'.repeat(phone.length - PHONE_HEAD_LENGTH - PHONE_TAIL_LENGTH) +
                  phone.substring(phone.length - PHONE_TAIL_LENGTH)
            : phone
    }

    if (error?.message === messageByError(AUTHENTICATION_REQUIRED)) {
        return setLoginScreenForm(LOGIN_SCREEN)
    }
    return (
        <Form
            className="card login-form"
            isLoading={isLoading}
            onSubmit={handleSubmit}
            errors={error && error.errors}
            rules={rules}
        >
            <CardHeader className={'login-form-header'}>{__('Enter code')}</CardHeader>
            <CardContent>
                <span className={'login-form-content-label'}>
                    {__('Enter verification code we sent to ')}
                </span>
                <div className={'login-form-phone'}>
                    {userConfirmed ? hidePhone(phone) : phone}
                </div>
                <Input
                    name="code"
                    label={__('Code')}
                    autoComplete={'off'}
                    onChange={onChangeCode}
                    dataTestId="confirmation-code-input"
                />
            </CardContent>
            <a href="#" className="link" onClick={handleResend}>
                {__('Send again?')}
            </a>
            {error?.errors?.limit && (
                <div className={'form-field-error login-form-limit-message'}>
                    {error.errors.limit}
                </div>
            )}
            <CardActions justify>
                <Button
                    primary
                    className={'login-form-button'}
                    disabled={isLoading}
                    type="submit"
                >
                    {__('Confirm')}
                </Button>
                <Button borderless className={'login-form-back'} onClick={handleBack}>
                    <IconPrev />
                    {__('Previous step')}
                </Button>
            </CardActions>
        </Form>
    )
}

export default ConfirmationCodeForm
