import {put} from 'redux-saga/effects'

import {EVENT_START_ZONE_DISCOVERY} from 'constants/autolearn'
import {snackShow} from 'modules/snacks'
import __ from 'utils/i18n'

import {stopSession} from '../actions'

export default function* (session, message) {
    if (EVENT_START_ZONE_DISCOVERY !== message.event) {
        return null
    }

    const {genericRequestResponse, verboseMessage} = message

    if ('0' === genericRequestResponse) {
        return null
    }

    yield put(snackShow(verboseMessage || __('Operation not allowed.')))
    yield put(stopSession(session))
}
