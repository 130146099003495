import React from 'react'
import {compose} from 'redux'

import RefreshButton from 'ipmp-react-ui/RefreshButton'

import {__} from 'utils/i18n'
import Void from 'components/Void'
import useDevices from 'hooks/pages/useDevices'
import usePermission from 'hooks/usePermission'
import useTrackProcess from 'hooks/useTrackProcess'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRSSIDevices} from 'modules/features/store/selectors'
import {refreshRssi as refreshRssiPermission} from 'permissions/panel/devices/actions'

const RefreshButtonVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectRSSIDevices, Void)
)(RefreshButton)

export default function RefreshRssiButton({flat}) {
    const {refreshRssi, panel} = useDevices()
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(refreshRssiPermission)

    const {process} = useTrackProcess({process: panel.rssiRefreshProcess})

    return (
        <RefreshButtonVisibility
            flat={flat}
            panelId={panelId}
            onClick={refreshRssi}
            isRefreshing={!!process}
            isVisible={isPermitted}
            label={__('Refresh RSSI')}
        />
    )
}
