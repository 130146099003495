import {takeEvery, all, put, select, fork} from 'redux-saga/effects'
import {POLL_PANEL_INTERACTIVE_USERS} from 'configs/pollers'
import isNil from 'lodash-es/isNil'

import * as actions from './actions'

import {getPanelId} from 'utils/panelIdMatchSelector'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import watch from 'modules/higherOrder/watch'
import {assignUserToPanel} from 'modules/forms/handlers'
import {snackShow} from 'modules/snacks'
import {fetch} from './watchers/watchFetchPanelUsers'
import updateRecentWidget from 'modules/panelInteractiveUsers/list/watchers/updateRecentWidget'

const selector = (state) => {
    const panelId = getPanelId(state)
    return {
        ...state.panelInteractiveUsers.list,
        panelId,
    }
}

export default function* () {
    yield all([
        takeEvery(actions.init, watchInit),
        takeEvery([actions.reset, actions.fetch], watch(fetch)),
        createListPollerSaga(actions, POLL_PANEL_INTERACTIVE_USERS, fetch),
        takeEvery(assignUserToPanel.SUCCESS, watchAssignUserToPanel),
        takeEvery(assignUserToPanel.FAILURE, watchAssignUserToPanelFailed),
    ])
}

function* watchInit() {
    yield put(actions.reset())
}

function* watchAssignUserToPanel() {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )

    // On panel page
    if (!isNil(interactiveUserId)) {
        return
    }
    const state = yield select(selector)

    yield put(actions.fetch(state))

    yield fork(updateRecentWidget)
}

function* watchAssignUserToPanelFailed({payload}) {
    const interactiveUserId = yield select(
        (state) => state.interactiveUserPanels.list.interactiveUserId
    )

    // On panel page
    if (!isNil(interactiveUserId)) {
        return
    }

    yield put(snackShow(payload.error))
}
