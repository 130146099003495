import React, {useCallback, useState} from 'react'

import list from 'permissions/events/list'
import EventContext from './EventContext'
import EventsListPage from './EventsListPage'
import useEvents from 'hooks/pages/useEvents'
import usePermission from 'hooks/usePermission'
import EventsBlindModal from './EventsBlindModal'
import {useSearchScope} from 'hooks/useAutoFilters'
import selectionPermission from 'permissions/events/selection'
import {withPermissionRejection} from 'containers/withPermission'
import {useParams} from 'react-router'

const EventsPageBase = (props) => {
    let params = useParams()

    if (params.scope && params.serial && !params.id) {
        params = {
            ...params,
            id: params.serial,
            serial: params.scope,
            scope: undefined,
        }
    }

    return <EventsListPage {...props} params={params} />
}

const ConnectedEventsPage = withPermissionRejection(list)(EventsPageBase)

export default function EventsPage() {
    const eventsProps = useEvents()
    const permissions = usePermission({
        hasSelection: selectionPermission,
    })

    const eventsBySerialProps = {...eventsProps, ...permissions}
    const [selectedEventId, setEventId] = useState(null)

    const handleEventSelect = (event) => {
        setEventId(event?.id)
    }
    const handleEventDeliverance = useCallback(handleEventSelect)
    useSearchScope(eventsProps.init)

    return (
        <EventContext.Provider
            value={{
                handleEventSelect: handleEventDeliverance,
                selectedEventId,
            }}
        >
            <ConnectedEventsPage {...eventsBySerialProps} />
            <EventsBlindModal
                {...eventsBySerialProps}
                selectedEventId={selectedEventId}
            />
        </EventContext.Provider>
    )
}
