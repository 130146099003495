import React from 'react'
import isEmpty from 'lodash-es/isEmpty'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'

export default function EmergencyButtons({onClose, setData, selectedPartitions}) {
    const handleBack = () => setData((state) => ({...state, chosen: null}))

    return (
        <div className="init-emergency-card-actions">
            <Button onClick={handleBack} borderless>
                {__('Back')}
            </Button>
            <div>
                <Button onClick={onClose} borderless>
                    {__('Dismiss')}
                </Button>
                <Button type="submit" primary disabled={isEmpty(selectedPartitions)}>
                    {__('Initiate emergency panic')}
                </Button>
            </div>
        </div>
    )
}
