import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'

import {list} from 'permissions/panel/panelInstallers/actions'

import {selectIsDefaultRoleType} from 'modules/auth/sign/selectors'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'

import InstallerStatus from 'components/Installer/InstallerStatus'
import User, {USER_TYPE_INSTALLER} from 'components/Entities/User'

import useRouterPanelId from 'hooks/useRouterPanelId'
import {usePanelInstallers} from 'hooks/installer'

import UnassignButton from './GridBar/UnassignInstallerButton'

import {__} from 'utils/i18n'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

const STATUS_KEY = 'status'

const allColumns = [
    {
        name: () => __('Installer'),
        width: 170,
        render: ({name, email}) => (
            <User name={name} email={email} withInfo type={USER_TYPE_INSTALLER} />
        ),
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        width: 70,
        render: ({createdAt}) => <DateTime datetime={createdAt} multiLine />,
    },
    {
        key: STATUS_KEY,
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatus,
    },
    {
        tooltip: false,
        fixed: true,
        width: 56,
        render: (row) => <UnassignButton row={row} />,
    },
]

const TableContainer = compose(
    withPermissionRejection(list),
    withLoader(
        ({init, startPoll}) => {
            init()
            startPoll()
        },
        ({purgeList, stopPoll}) => {
            purgeList()
            stopPoll()
        }
    )
)(Table)

export default function PanelInstallersTable({onRowClick}) {
    const panelId = useRouterPanelId()
    const {rows, isLoading, init, startPoll, stopPoll, purgeList} =
        usePanelInstallers(panelId)

    const isDefaultRoleType = useSelector(selectIsDefaultRoleType)

    const rowClickWithCondition = (row) => {
        if (row.installerId) {
            onRowClick(row)
        }
    }

    const onTableRowClick = isDefaultRoleType ? rowClickWithCondition : null

    let columns = allColumns
    if (!isDefaultRoleType) {
        columns = columns.filter((column) => column.key !== STATUS_KEY)
    }

    return (
        <TableContainer
            rows={rows}
            isLoading={isLoading}
            panelId={panelId}
            onRowClick={onTableRowClick}
            fullHeight
            className="panelInstallers-table"
            columns={columns}
            emptyMessage={__('No installers found')}
            init={init}
            startPoll={startPoll}
            stopPoll={stopPoll}
            purgeList={purgeList}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}

PanelInstallersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}
