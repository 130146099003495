import React from 'react'

import PanelPage from './PanelPage/PanelPage'
import {pathParent} from 'utils/path'

export default class UnitRoute {
    path = pathParent('panel')

    element = (<PanelPage />)
}
