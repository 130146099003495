import head from 'lodash-es/head'

import {get, post} from 'api/http'

import mapValidationErrors from '../base/mapValidationErrors'
import {mapProcess} from '../processes'
import {keysMap} from '../eventRules.map'

export function getPanelTestsHistory(panelId) {
    return get(`unit/${panelId}/tests/history`)
}

export function getPanelSupportedTestsList(panelId) {
    return get(`unit/${panelId}/tests/supported`)
}

export function getPanelTestsStatus(panelId, tokens = []) {
    const uniqTokens = [...new Set(tokens)]
    return get(`unit/${panelId}/tests/status`, {'tokens[]': uniqTokens})
}

export function runPanelTests(panelId, tests) {
    const formattedTests = tests.map((test) => ({
        testType: test?.testType,
        testParams: test?.testParams,
        testDeviceId: test?.testDeviceId,
    }))

    return post(`unit/${panelId}/tests/run`, {tests: formattedTests})
        .then((data) =>
            data.map(({processes, batchId, processToken}) => ({
                ...mapProcess(head(processes)),
                batchId: batchId,
                processToken: processToken,
            }))
        )
        .catch((e) => mapValidationErrors(e, keysMap))
}
