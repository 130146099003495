import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import list from 'permissions/processes/list'
import usePermission from 'hooks/usePermission'
import useProcesses from 'hooks/pages/useProcesses'
import {withVisibility} from 'containers/withVisibility'

const PagerVisibility = withVisibility()(Pager)

export default function ProcessesPager() {
    const {isPermitted} = usePermission(list)
    const {total, start, perPage, onPageChange, onPerPageChange} = useProcesses()

    return (
        <PagerVisibility
            isVisible={isPermitted}
            total={total}
            start={start}
            perPage={perPage}
            onPerPageChange={onPerPageChange}
            onPageChange={onPageChange}
        />
    )
}
