import {createSelector} from 'reselect'

export const selectUpgradeStatusRunnersListRows = createSelector(
    (state) => state.upgradeStatus.upgradeStatusRunnersList.page,
    (state) => state.runners.store.byIds,
    (page, store) => page.map((id) => store[id] || null).filter((row) => row !== null)
)

export const selectUpgradeStatusRunnersList = createSelector(
    (state) => state.upgradeStatus.upgradeStatusRunnersList,
    (list) => list
)
