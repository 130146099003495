import {createSelector} from 'reselect'

export const selectCountryNameByAlphaCode = createSelector(
    (state) => state.countries.isLoading,
    (state, countryCode) =>
        Object.values(state.countries.byIds).find(
            ({alpha3Code}) => alpha3Code === countryCode
        ),
    (isLoading = false, country = null) => ({
        isLoading,
        country,
    })
)
