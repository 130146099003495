import React, {Component} from 'react'
import {compose} from 'redux'
import {Route, Routes} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {useNavigate, useParams} from 'react-router'

import path, {pathLastChild} from 'utils/path'
import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'
import page from 'permissions/panel/configuration/page'
import {upload} from 'permissions/panel/configuration/actions'
import {withConfigurationPreloader} from 'containers/withConfiguration'
import PanelConfigurationsList from './List/PanelConfigurationsList'
import PanelViewConfiguration from './View/PanelViewConfiguration'
import {selectUploadConfigurationFeature} from 'modules/features/store/selectors'
import {
    useBackupConfiguration,
    useCompareConfiguration,
    useCurrentConfiguration,
} from 'hooks/pages/useConfiguration'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useConfigurationsList} from 'hooks/pages/useConfigurationsList'
import useRunnerPoll from 'hooks/useRunnerPoll'

const CurrentConfiguration = (props) => {
    const feature = useSelector((state) => selectUploadConfigurationFeature(state, props))
    const current = useCurrentConfiguration(props)

    const viewProps = {...props, ...feature, ...current}

    return <PanelViewConfiguration {...viewProps} isEditable={viewProps.isAvailable} />
}

const CompareConfiguration = (props) => {
    const {configId} = useParams()

    const compare = useCompareConfiguration({...props, configId})
    const viewProps = {...props, ...compare}

    return (
        <PanelViewConfiguration configId={configId} {...viewProps} isCompareWithBackup />
    )
}

const BackupConfiguration = (props) => {
    const {configId} = useParams()

    const backup = useBackupConfiguration({...props, configId})
    const viewProps = {...props, ...backup}

    return (
        <PanelViewConfiguration configId={configId} {...viewProps} isEditable={false} />
    )
}

class ConfigurationsPageBase extends Component {
    componentDidMount() {
        const {panelId, pendingChanges, navigate} = this.props

        if (pendingChanges) {
            navigate(path('panel.configuration.current', {id: panelId}))
        }
    }

    render() {
        return (
            <Routes>
                <Route
                    index
                    path="/"
                    element={<PanelConfigurationsList {...this.props} />}
                />
                <Route
                    path={pathLastChild('panel.configuration.current')}
                    element={
                        <CurrentConfiguration
                            panelId={this.props.panelId}
                            isEditable={this.props.isEditable}
                        />
                    }
                />
                <Route
                    path={pathLastChild('panel.configuration.view')}
                    element={<BackupConfiguration panelId={this.props.panelId} />}
                />
                <Route
                    path={pathLastChild('panel.configuration.compare')}
                    element={<CompareConfiguration panelId={this.props.panelId} />}
                />
            </Routes>
        )
    }
}

const ConfigurationsPageConnected = compose(
    withPermissionRejection(page),
    withLoader(({fetch}) => fetch()),
    withConfigurationPreloader({
        isErrorCallback: ({rows}) => !rows || !rows.length,
    })
)(ConfigurationsPageBase)

export default function ConfigurationsPage() {
    const {isEditable} = usePermission({
        isEditable: upload,
    })

    const panelId = useRouterPanelId()

    const {
        rows,
        runner,
        isLoading,
        error,
        outdated,
        makeBasic,
        pendingChanges,
        pendingExport,
        fetch,
        markAsBackup,
        refresh,
        startPollRunnerData,
        stopPollRunnerData,
        refreshRunner,
    } = useConfigurationsList({panelId})

    useRunnerPoll({
        runner,
        panelId,
        startPollRunnerData,
        stopPollRunnerData,
        refreshRunner,
    })

    const navigate = useNavigate()

    return (
        <ConfigurationsPageConnected
            panelId={panelId}
            isEditable={isEditable}
            rows={rows}
            runner={runner}
            isLoading={isLoading}
            error={error}
            outdated={outdated}
            makeBasic={makeBasic}
            navigate={navigate}
            pendingChanges={pendingChanges}
            pendingExport={pendingExport}
            fetch={fetch}
            markAsBackup={markAsBackup}
            refresh={refresh}
        />
    )
}
