import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showSetUserPinModal} from 'modules/modals/actions'
import {USER_FEATURE_EDITABLE_CODE} from 'constants/features'
import {selectUserEditableFeature} from 'modules/features/store/selectors'
import {create as createPanelUser} from 'permissions/panel/customers/actions'

const ButtonVisibility = withVisibility()(Button)

export default function SetCodeButton({panelId, panelUserId}) {
    const {isPermitted} = usePermission(createPanelUser)
    const isAllowedSetCode = useSelector((state) =>
        selectUserEditableFeature(state, {
            panelId,
            item: USER_FEATURE_EDITABLE_CODE,
        })
    )

    const {onClick} = useActions({
        onClick: () => showSetUserPinModal({panelId, panelUserId}),
    })

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconEdit}
            onClick={onClick}
            isVisible={isPermitted}
            disabled={!isAllowedSetCode}
        />
    )
}
