import {put} from 'redux-saga/effects'
import * as api from 'api/panels'

import generateBatch from 'modules/batches/manager/generateBatch'
import {reset} from 'modules/panels/processes/actions'
import {PROCESS_TYPE_PANEL_DISCOVERY} from 'constants/processTypes'
import {snackShow} from 'modules/snacks'
import __ from 'utils/i18n'

export default function* watchDiscovery({payload: {panelIds, timeout}}) {
    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_PANEL_DISCOVERY,
        panelIds
    )

    try {
        yield api.discovery(panelIds, timeout, batchId)
        yield put(snackShow(__('Discovery triggered')))
        for (const id of panelIds) {
            yield put(reset(id))
        }
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
