import React from 'react'
import {useNavigate} from 'react-router'

import Table from 'ipmp-react-ui/Table'

import __ from 'utils/i18n'
import path from 'utils/path'
import {withPermissionVisibility} from 'containers/withPermission'
import list from 'permissions/firmware/list'

const columns = [
    {
        render: ({description}) => description(),
    },
]

const rows = [
    {
        description: () => __('Patch tag'),
        onClick: ({navigate}) => navigate(path('firmware.patchTagUpdate')),
    },
]

function UpgradeIqPanelsTable({className}) {
    const navigate = useNavigate()

    const onRowClick = ({onClick}) => {
        onClick({navigate})
    }

    return (
        <Table
            rows={rows}
            columns={columns}
            className={className}
            onRowClick={onRowClick}
            title={__('Update IQ panels:')}
        />
    )
}

export default withPermissionVisibility(list)(UpgradeIqPanelsTable)
