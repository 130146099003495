import {put} from 'redux-saga/effects'

import {EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE} from 'constants/autolearn'
import {snackShow} from 'modules/snacks'

import {stopSession} from '../actions'

export default function* (session, message) {
    if (EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE !== message.event) {
        return null
    }

    const {responseCode, verboseMessage} = message

    if (0 === responseCode) {
        return null
    }

    yield put(snackShow(verboseMessage))
    yield put(stopSession(session))
}
