import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import Page from 'ipmp-react-ui/Page'

import ActivatePanelInteractiveBar from 'pages/Panel/InteractiveBar/ActivatePanelInteractiveBar'
import ConfigurationChangesInteractiveBar from 'pages/Panel/InteractiveBar/ConfigurationChangesInteractiveBar'
import MakeBasicConfigurationInteractiveBar from 'pages/Panel/InteractiveBar/MakeBasicConfigurationInteractiveBar'

import PanelPageBar from './PanelPageBar'
import PanelPageContent from './PanelPageContent'
import PanelRemarks from './PanelRemarks'
import PanelPageTop from '../PanelPageTop/PanelPageTop'

export class PanelPageBase extends Page {
    static propTypes = {
        items: PropTypes.object,
    }

    state = {
        isOpenedRemarks: false,
    }

    openRemarks = () => this.setState({isOpenedRemarks: true})

    closeRemarks = () => this.setState({isOpenedRemarks: false})

    renderTop() {
        const {panel, isContentHidden, features} = this.props
        return (
            <PanelPageTop
                openRemarks={this.openRemarks}
                panel={panel}
                isContentHidden={isContentHidden}
                emergencyFeatures={features?.emergency}
            />
        )
    }

    renderBar() {
        return <PanelPageBar menu={this.props.items} />
    }

    renderContent() {
        const {panel, items, defaultTab} = this.props

        if (Object.keys(items).length === 0) {
            return null
        }

        return (
            <Fragment>
                <PanelPageContent routes={items} defaultTab={defaultTab} />

                <PanelRemarks
                    panelId={panel?.id}
                    opened={this.state.isOpenedRemarks}
                    onClose={this.closeRemarks}
                />
            </Fragment>
        )
    }

    renderInteractiveBar() {
        const {panel} = this.props
        const id = panel?.id
        return [
            <ActivatePanelInteractiveBar id={id} key="activate-panel" />,
            <ConfigurationChangesInteractiveBar key="configuration" />,
            <MakeBasicConfigurationInteractiveBar key="make-basic-configuration" />,
        ]
    }
}
