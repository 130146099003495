import React, {useEffect} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import DateTime from 'ipmp-react-ui/DateTime'
import Definition from 'ipmp-react-ui/Definition'
import Table from 'ipmp-react-ui/Table'

import list from 'permissions/interactiveUsers/list'
import selectionPermission from 'permissions/interactiveUsers/selection'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'
import {__, __n} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import usePermission from 'hooks/usePermission'
import useInteractiveUsers from 'hooks/pages/useInteractiveUsers'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'
import User, {USER_TYPE_CUSTOMER} from 'components/Entities/User'

import InteractiveUserStatusChanger from './TableCell/InteractiveUserStatusChanger'

const columns = [
    {
        name: () => __('Email'),
        width: 340,
        render: ({email}) => <User name={email} type={USER_TYPE_CUSTOMER} withInfo />,
    },
    {
        name: () => __('Created'),
        width: 120,
        render: ({createdAt}) => <DateTime datetime={createdAt} multiLine />,
    },
    {
        name: () => __('Last login'),
        width: 120,
        render: ({lastLoginAt}) => <DateTime datetime={lastLoginAt} multiLine />,
    },
    {
        name: () => __('Panels'),
        width: 120,
        render: ({panels}) => {
            if (panels === 0) {
                return <span className="empty">{__('No panels')}</span>
            }

            return <Definition title={panels} detail={__n('panel', 'panels', panels)} />
        },
    },
    {
        name: () => __('Status'),
        tooltip: false,
        width: 120,
        component: InteractiveUserStatusChanger,
    },
]

const ConnectedTable = compose(
    withPermissionRejection(list),
    withLoader(({init}) => init())
)(Table)

export default function InteractiveUsersTable({onRowClick, active}) {
    const {isAllowed, hasSelection} = usePermission({
        hasSelection: selectionPermission,
        isAllowed: list,
    })

    const {selection, select, deselect} = useSelection()
    const {rows, isLoading, error, init, startPoll, stopPoll} = useInteractiveUsers()

    useEffect(() => {
        isAllowed ? startPoll() : stopPoll()

        return () => isAllowed && stopPoll()
    })

    return (
        <ConnectedTable
            fullHeight
            rows={rows}
            isAllowed={isAllowed}
            hasSelection={hasSelection}
            isLoading={isLoading}
            error={error}
            init={init}
            active={active}
            select={select}
            deselect={deselect}
            selection={selection}
            columns={columns}
            emptyMessage={__('No customers found')}
            onRowClick={onRowClick}
            className="accounts-page-customers-table"
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}

InteractiveUsersTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
}
