import React from 'react'

import DateTime from 'ipmp-react-ui/DateTime'
import {TableGrouped} from 'ipmp-react-ui/Table'
import {humanDate} from 'ipmp-react-ui/humanTime'

import {__} from 'utils/i18n'

export const columns = [
    {
        width: 50,
        maxWidth: 70,
        render: ({eventId}) => eventId,
    },
    {
        width: 150,
        maxWidth: 200,
        render: ({timestamp}) => timestamp && <DateTime datetime={timestamp} timeFirst />,
    },
    {
        width: 300,
        render: ({text}) => text,
    },
    {
        width: 200,
        render: ({appointment, partition}) => (
            <div>
                {appointment}
                {partition && (
                    <span className="partitions">
                        <span className="partition">{partition}</span>
                    </span>
                )}
            </div>
        ),
    },
]

const separatorCallback = (row) => humanDate(row.timestamp)

export default function LogsTable({rows}) {
    return (
        <TableGrouped
            rows={rows}
            columns={columns}
            emptyMessage={__('No entries')}
            separatorCallback={separatorCallback}
        />
    )
}
