import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'

import Checkbox from 'ipmp-react-ui/Checkbox'
import Input from 'ipmp-react-ui/Input'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

const ConfigurationFilters = ({
    makeBasic,
    isCompareWithBackup,
    isShowExportable,
    isShowChanged,
    isEditable,
    query,
    onSearch,
    setShowChanges,
    setShowExportable,
}) => {
    const handleOnClear = () => {
        onSearch('')
    }

    const handleOnChange = (e) => {
        onSearch(e.target.value)
    }

    const handleFilterChanged = (e) => {
        setShowChanges(e.target.checked)
    }

    const handleShowExportable = (e) => {
        setShowExportable(e.target.checked)
    }

    return (
        <Fragment>
            <Input
                autoFocus
                placeholder={__('Quick search')}
                value={query || ''}
                onChange={handleOnChange}
                Icon={query ? IconCross : null}
                onIconClick={handleOnClear}
            />

            {!makeBasic && !isCompareWithBackup && isEditable && (
                <Checkbox
                    onChange={handleFilterChanged}
                    checked={isShowChanged}
                    label={__('Show only changed')}
                />
            )}

            {makeBasic && (
                <Checkbox
                    onChange={handleShowExportable}
                    checked={isShowExportable}
                    label={__('Show exportable elements')}
                />
            )}
        </Fragment>
    )
}

ConfigurationFilters.propTypes = {
    query: PropTypes.string.isRequired,
    onSearch: PropTypes.func.isRequired,
    setShowChanges: PropTypes.func.isRequired,
    setShowExportable: PropTypes.func.isRequired,
    isShowExportable: PropTypes.bool,
    isShowChanged: PropTypes.bool,
    makeBasic: PropTypes.bool,
    isEditable: PropTypes.bool,
    isCompareWithBackup: PropTypes.bool,
    backup: PropTypes.object,
}

export default ConfigurationFilters
