import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import {assign} from 'permissions/interactiveUsers/actions'
import {isAllowManipulateUser} from 'constants/accountRoles'
import {showAssignUserToPanelModal} from 'modules/modals/actions'

export default function AssignButton({role, panelUserId, isPinSet}) {
    const {showAssignUserToPanelModal: showAssignUserToPanelModalAction} = useActions({
        showAssignUserToPanelModal,
    })
    const panelId = useRouterPanelId()

    const panelsByIds = useSelector((state) => state.panelInteractiveUsers.store)
    const panelUser = useSelector(
        ({panelInteractiveUsers}) =>
            panelInteractiveUsers.store.byIds[panelId][panelUserId]
    )
    const {isAllowedAssignInteractiveUsers} = usePermission({
        isAllowedAssignInteractiveUsers: assign,
    })

    if (!isAllowedAssignInteractiveUsers) {
        return null
    }

    if (!isPinSet) {
        return (
            <Tooltip tooltip={__('Unavailable for inactive panel user')}>
                <Button flat small Icon={IconPlus} disabled />
            </Tooltip>
        )
    }

    const onClick = () =>
        showAssignUserToPanelModalAction({
            panelId,
            hasPanelUser: Object.values(panelsByIds.byIds[panelId]).some(
                ({isAssign, isPinSet}) => !isAssign && isPinSet
            ),
            panelUserId,
            panelUser,
        })

    return (
        <Button
            flat
            small
            Icon={IconPlus}
            onClick={onClick}
            disabled={!isAllowManipulateUser(role)}
        />
    )
}

AssignButton.propTypes = {
    panelUserId: PropTypes.number,
}
