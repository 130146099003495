import React from 'react'
import get from 'lodash-es/get'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Autocomplete from 'ipmp-react-ui/Autocomplete'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import withOptions from 'containers/withOptions'
import {changeInstallerCode} from 'modules/forms/handlers'
import {hasIefCode, hasIefType, INSTALLER_TYPE_INSTALLER} from 'constants/installersRoles'

const InstallerRoleSelect = withOptions(
    ({
        modals: {
            args: {panelId},
        },
        features: {
            store: {byIds},
        },
    }) => {
        const {installerTypes} = get(byIds, panelId, []).installer
        return installerTypes.map(({id, role}) => ({value: id, label: role}))
    }
)(Autocomplete)

const getRules = (codeLength, hasSomeInstallerTypes) => {
    return {
        pin: {
            presence: true,
            installerPin: {
                allowedLengths: codeLength,
            },
        },
        confirmPin: {
            presence: true,
            installerPin: {
                allowedLengths: codeLength,
            },
            equality: {
                attribute: 'pin',
                message: () => __('Codes do not match'),
                comparator: (v1, v2) => v1 === v2,
            },
        },
        installerType: {
            presence: hasSomeInstallerTypes,
        },
    }
}

export default function ChangeInstallerCode({panelId, hide, featuresInstaller}) {
    const {form, handle, isLoading} = useForm(changeInstallerCode)

    const {codeLengths, editableFeatures, installerTypes} = featuresInstaller
    const hasSomeInstallerTypes = installerTypes.length > 1

    const hasCode = hasIefCode(editableFeatures)
    const hasType = hasIefType(editableFeatures)

    const onHandle = ({pin, installerType}) => {
        const type = installerType ?? installerTypes[0].id
        const payload = {type, pin, panelId}
        handle(payload)
    }
    const getDefaultInstallerType = () => {
        return hasSomeInstallerTypes ? INSTALLER_TYPE_INSTALLER : installerTypes[0].id
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            onSubmit={onHandle}
            isLoading={isLoading}
            confirmOnDismiss
            header={__('Change code')}
            rules={getRules(codeLengths, hasSomeInstallerTypes)}
        >
            {hasCode && (
                <Input
                    type="password"
                    label={__('New Code')}
                    name="pin"
                    autoComplete="off"
                />
            )}
            {hasCode && (
                <Input
                    type="password"
                    label={__('Confirm New Code')}
                    name="confirmPin"
                    autoComplete="off"
                />
            )}
            {hasType && hasSomeInstallerTypes && (
                <InstallerRoleSelect
                    label={__('Installer type')}
                    name="installerType"
                    defaultValue={getDefaultInstallerType()}
                />
            )}
        </ModalCardForm>
    )
}

ChangeInstallerCode.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.string.isRequired,
}
