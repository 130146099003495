import {DTP_POWERG, DTP_UNKNOWN} from 'constants/protocols'

import useAddSensorConfirmation from './AddSensorConfirmation'

export default function usePowerG(session) {
    const addSensorConfirmation = useAddSensorConfirmation(session)

    return [DTP_UNKNOWN, DTP_POWERG].includes(session.protocol)
        ? addSensorConfirmation
        : null
}
