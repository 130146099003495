import React, {useEffect} from 'react'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'

import {useActions} from 'modules/higherOrder/useActions'
import {clear} from 'modules/forms/actions'
import {panelTypes} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectPanelTypes'
import ConnectSmartCommunicatorFormFields from 'modals/Panel/DlsConnection/ConnectSmartCommunicatorFormFields'
import {speeds} from 'modals/Panel/DlsConnection/SmartCommunicator/SelectConnectionSpeed'
import {dlsConnectSmartCommunicator} from 'modules/forms/handlers'

const rules = {
    host: {
        presence: true,
        host: true,
    },
    port: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 80,
            lessThanOrEqualTo: 65535,
        },
    },
    password: {
        presence: true,
        format: {
            pattern: `^[0-9]{8}$`,
            flags: 'i',
            message: () => __('Must be 8 digits'),
        },
    },
    timeout: {
        presence: true,
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 3600,
        },
    },
    panelType: {
        presence: true,
        inclusion: panelTypes,
    },
    connectionSpeed: {
        presence: true,
        numericality: false,
        inclusion: speeds,
    },
}

export default function ConnectSmartCommunicator({panelId, hide, errors}) {
    const clearForm = useActions(clear)
    const submit = useActions(dlsConnectSmartCommunicator)

    useEffect(() => {
        return clearForm
    }, [])

    const handleOnSubmit = ({
        host,
        port,
        password,
        isEncrypted,
        timeout,
        panelType,
        connectionSpeed,
    }) => {
        submit({
            panelId,
            host,
            port,
            password,
            isEncrypted,
            timeout,
            panelType,
            connectionSpeed,
        })
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            onSubmit={handleOnSubmit}
            header={__('Smart communicator connect DLS')}
            rules={rules}
            hide={hide}
            submitLabel={__('Connect')}
            className="dls-smart-communicator-main-form"
        >
            <ConnectSmartCommunicatorFormFields errors={errors} />
        </ModalCardForm>
    )
}
