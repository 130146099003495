import React from 'react'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import {panels} from 'permissions/interactiveUsers/list'
import {withPermissionVisibility} from 'containers/withPermission'

const InteractiveUserEmailComponent = ({interactiveUserEmail}) => {
    return (
        <div className="recentHistory-item-info recentHistory-item-info--userEmail">
            <OverflowTextTooltip tooltip={interactiveUserEmail}>
                {interactiveUserEmail}
            </OverflowTextTooltip>
        </div>
    )
}

export const InteractiveUserEmail = withPermissionVisibility(panels)(
    InteractiveUserEmailComponent
)
