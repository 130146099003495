import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import list from 'permissions/processes/list'
import Search from 'components/Search/Search'
import processType from 'constants/processType'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectProcessesList} from 'modules/processes/list/selectors'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    setQuery,
} from 'modules/processes/list/actions'

const SearchVisibility = withVisibility()(Search)

export default function ProcessesSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectProcessesList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('serial', __('Panel Id')),
        new TranslateSuggestFilter('type', __('Type'), processType),
        new SuggestFilter('_usr_email', __('Email')),
        new SuggestFilter('user', __('User')),
        new TranslateSuggestFilter('status', __('Status'), {
            succeeded: __('Succeeded'),
            failed: __('Failed'),
            start: __('Started'),
            handled: __('Handled'),
        }),
        new DateFilter('started', __('Started at')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Processes')}
        />
    )
}
