import React from 'react'

import {useOnline} from 'hooks/pages/useDashboard'
import OfflineFaultyPanelsChartBase from 'components/Dashboard/OfflineFaultyPanelsChart'
import {withPermissionRejection} from 'containers/withPermission'
import {onlineFaulty} from 'permissions/dashboard/charts'

const OfflineFaultyPanelsChartRejection = withPermissionRejection(onlineFaulty)(
    OfflineFaultyPanelsChartBase
)

export default function OfflineFaultyPanelsChart() {
    const {faultyData, offlaneData, totalData, from, to} = useOnline()

    return (
        <OfflineFaultyPanelsChartRejection
            faultyData={faultyData}
            offlaneData={offlaneData}
            totalData={totalData}
            from={from}
            to={to}
        />
    )
}
