import React from 'react'
import {useSelector} from 'react-redux'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import {selectNotificationNameById} from 'modules/notificationTypes/store/selectors'

export default function NotificationTypeCell({row: {notificationId}}) {
    const {name} = useSelector((state) =>
        selectNotificationNameById(state, {id: notificationId})
    )

    return (
        <OverflowTextTooltip className="evenRules-elements-name">
            {name}
        </OverflowTextTooltip>
    )
}
