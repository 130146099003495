import React from 'react'

import path from 'utils/path'
import ActionLogPage from './ActionLogPage'

export default class {
    path = path('actionLog')

    element = (<ActionLogPage />)
}
