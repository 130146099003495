import React, {Fragment} from 'react'

import EventTypesTable from 'pages/Settings/EventRules/EventTypes/EventTypesTable'
import EventTypesGridBar from 'pages/Settings/EventRules/EventTypes/EventTypesGridBar'

export default function EventTypesPage() {
    return (
        <Fragment>
            <EventTypesGridBar />
            <EventTypesTable />
        </Fragment>
    )
}
