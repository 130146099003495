import React, {Component} from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {setBypass} from 'modules/devices/list/actions'
import {bypass} from 'permissions/panel/devices/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'

class BypassCheckboxBase extends Component {
    static propTypes = {
        handleChange: PropTypes.func.isRequired,
        isPermitted: PropTypes.bool,
        device: PropTypes.shape({
            id: PropTypes.number.isRequired,
            traits: PropTypes.shape({
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool,
                }),
            }),
        }).isRequired,
    }

    handleChange = (e) => this.props.handleChange(e.target.checked)

    render() {
        const data = get(this.props, ['device', 'traits', 'bypass'], {})

        if (!Object.entries(data).length) {
            return null
        }

        return (
            <Checkbox
                disabled={!this.props.isPermitted}
                onChange={this.handleChange}
                label={__('Bypass')}
                progress={data.progress}
                checked={data.enabled}
            />
        )
    }
}

const BypassCheckboxVisibility = withVisibility()(BypassCheckboxBase)

export default function BypassCheckbox({panelId, device}) {
    const {isPermitted} = usePermission(bypass)

    const {handleChange} = useActions(
        {
            handleChange: (enabled) =>
                setBypass(panelId, new Map([[device.id, enabled]])),
        },
        [panelId, device]
    )

    const isVisible = !isEmpty(get(device, ['traits', 'bypass'], {}))

    return (
        <BypassCheckboxVisibility
            device={device}
            isVisible={isVisible}
            isPermitted={isPermitted}
            handleChange={handleChange}
        />
    )
}
