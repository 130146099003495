import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'

import SetLastNameButton from './SetLastNameButton'
import Row from '../Row'
import {selectUserEditableFeature} from 'modules/features/store/selectors'
import {USER_FEATURE_EDITABLE_LAST_NAME} from 'constants/features'

export default function LastNameRow({user: {id, lastName, isPinSet}}) {
    const panelId = useRouterPanelId()
    const isFeatureEnabled = useSelector((state) =>
        selectUserEditableFeature(state, {
            panelId,
            item: USER_FEATURE_EDITABLE_LAST_NAME,
        })
    )
    if (!isFeatureEnabled) {
        return null
    }

    return (
        <Row
            value={lastName}
            title={__('Last name')}
            missingTitle={__('No last name')}
            EditButton={
                <SetLastNameButton
                    lastName={lastName}
                    panelId={panelId}
                    panelUserId={id}
                    isPinSet={isPinSet}
                />
            }
        />
    )
}

LastNameRow.propTypes = {
    user: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isPinSet: PropTypes.bool.isRequired,
        lastName: PropTypes.string,
    }).isRequired,
}
