import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/reports/bar'
import ReportsPager from './Bar/ReportsPager'
import usePermission from 'hooks/usePermission'
import StopReportsButton from './Bar/StopReportsButton'
import {withVisibility} from 'containers/withVisibility'
import CreateReportButton from './Bar/CreateReportButton'
import RemoveReportsButton from './Bar/RemoveReportsButton'
import ReportsSelectionDropDown from './Bar/ReportsSelectionDropDown'

const ReportsGridBarBase = () => (
    <Bar>
        <ReportsSelectionDropDown />
        <StopReportsButton />
        <RemoveReportsButton />
        <BarSpace />
        <ReportsPager />
        <CreateReportButton />
    </Bar>
)

const ReportsGridBarVisibility = withVisibility()(ReportsGridBarBase)

export default function ReportsGridBar() {
    const {isPermitted} = usePermission(bar)

    return <ReportsGridBarVisibility isVisible={isPermitted} />
}
