import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {selectPanel} from 'modules/panels/store/selectors'
import {showMasterActivateGUIPanel} from 'modules/modals/actions'
import {ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED} from 'constants/panelActivationStatus'

const ButtonVisibility = withVisibility()(Button)

export default function MasterActivateGUIButton({panelId}) {
    const {activationStatus} = useSelector((state) => selectPanel(state, {panelId}))
    const isVisible =
        activationStatus === ACTIVATION_STATUS_MASTER_USER_ACTIVATION_GUI_REQUIRED
    const {onClick} = useActions(
        {
            onClick: () => showMasterActivateGUIPanel(panelId),
        },
        [panelId]
    )

    return (
        <ButtonVisibility
            onClick={onClick}
            isVisible={isVisible}
            label={__('Activate')}
        />
    )
}
