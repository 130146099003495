import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router'
import PropTypes from 'prop-types'

import {CollapsibleTabs} from 'ipmp-react-ui/NavTabs/navTabs'

import {content} from 'permissions/panel/page'
import {withPermissionVisibility} from 'containers/withPermission'

import path from 'utils/path'

const PanelPageTabs = ({menu = {}}) => {
    const elementRef = useRef(null)
    const params = useParams()
    const [menuWithPath, setMenuWithPath] = useState(menu)

    useEffect(() => {
        const menuWithPathLocal = {}

        for (const [key, val] of Object.entries(menu)) {
            menuWithPathLocal[key] = {
                ...val,
                to: path(val.path, params),
            }
        }

        setMenuWithPath(menuWithPathLocal)
    }, [menu])

    return (
        <div ref={elementRef}>
            <CollapsibleTabs menu={menuWithPath} elementRef={elementRef} />
        </div>
    )
}

PanelPageTabs.propTypes = {
    menu: PropTypes.object,
    match: PropTypes.object,
}

export default withPermissionVisibility(content)(PanelPageTabs)
