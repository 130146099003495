import React from 'react'
import {useSelector} from 'react-redux'
import {matchPath, useNavigate, useLocation} from 'react-router'

import {ReactComponent as IconSettings} from 'ipmp-react-ui/icons/menu/settings.svg'

import __ from 'utils/i18n'
import path from 'utils/path'
import NavLink from 'components/Nav/NavLink'
import usePermission from 'hooks/usePermission'
import NavItemsResizeble from './NavItemsResizeble'
import * as permissions from 'permissions/navigation'
import {useActions} from 'modules/higherOrder/useActions'
import {removeSearchScope} from 'modules/settings/actions'

export default function NavMain({navRefHandler}) {
    const navigate = useNavigate()
    const location = useLocation()
    const {hideMenuLabels} = useSelector((state) => state.settings)
    const {permissionsPage} = usePermission({permissionsPage: permissions})
    const {onSearchScopeRemove} = useActions({
        onSearchScopeRemove: removeSearchScope,
    })

    const scopes = useSelector(({settings}) => settings.scopes)

    const handleSearchScopeRemove = (page, scope) => {
        const match = matchPath(
            {
                path: path(page, {scope}),
            },
            location.pathname
        )

        if (match) {
            navigate(path(page, {}))
        }

        onSearchScopeRemove(page, scope)
    }

    return (
        <nav className="nav" ref={navRefHandler}>
            <div className="menu-wrapper">
                <div className="main-links">
                    <NavItemsResizeble
                        scopes={scopes}
                        permissions={permissionsPage}
                        handleSearchScopeRemove={handleSearchScopeRemove}
                    />
                </div>
                <div className="bottom-links">
                    <NavLink
                        Icon={IconSettings}
                        name={!hideMenuLabels ? __('Settings') : ''}
                        isPermitted={permissionsPage.settings}
                        to={path('settings', {})}
                    />
                </div>
            </div>
        </nav>
    )
}
