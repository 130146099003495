import React from 'react'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import {withPermissionVisibility} from 'containers/withPermission'
import {edit} from 'permissions/basicConfiguration/actions'

import {save} from 'modules/basicConfiguration/one/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {useParams} from 'react-router'

function SaveBasicConfiguration({disabled}) {
    const {id} = useParams()

    const {onClick} = useActions({
        onClick: () => save({basicConfigId: id}),
    })

    return <Button disabled={disabled} primary label={__('Save')} onClick={onClick} />
}

export default withPermissionVisibility(edit)(SaveBasicConfiguration)
