import React from 'react'
import {useLocation} from 'react-router'

import tabs from 'permissions/panel/navigation'
import {list as panelUsersList} from 'permissions/panel/customers/actions'
import {unitContent} from 'permissions/panel/page'
import firmwareList from 'permissions/panel/firmware/page'

import usePanels from 'hooks/pages/usePanels'
import usePanelPage from 'hooks/pages/usePanelPage'
import usePermission from 'hooks/usePermission'
import usePanel from 'hooks/pages/usePanel'
import useFeatures from 'hooks/pages/useFeatures'

import {PanelPageLoader} from './PanelPageLoader'

export default function PanelPage() {
    const permissions = usePermission({
        allowedItems: {
            ...tabs,
        },
        isPanelUsersPagePermitted: panelUsersList,
        isUnitPermitted: unitContent,
        isFirmwarePermitted: firmwareList,
    })
    const panel = usePanel()
    const location = useLocation()
    const features = useFeatures(panel.panelId)
    const pageProps = {...panel, ...features, ...permissions, location}
    const panelPageProps = usePanelPage(pageProps)
    const {fetch: fetchUnits} = usePanels()

    return <PanelPageLoader {...{...pageProps, ...panelPageProps, fetchUnits}} />
}
