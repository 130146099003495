import React from 'react'

import getHocName from 'utils/getHocName'
import {__} from 'utils/i18n'
import {useSelector} from 'react-redux'
import {compose} from 'redux'
import Error from 'ipmp-react-ui/Error'
import Button from 'ipmp-react-ui/Button'
import ProcessLoader from 'ipmp-react-ui/ProcessLoader'
import processErrorMessage from 'constants/processError'
import useTrackProcess from '../hooks/useTrackProcess'
import {selectProcessesManagerByKeys} from 'modules/processes/manager/selectors'

export default function withProcessLoader(messageBuilder, onSuppress, buttonProps) {
    if (!messageBuilder) {
        messageBuilder = () => __('Synchronizing with panel')
    }

    return compose((WrappedComponent) => {
        const fn = ({process: processProp, ...props}) => {
            const byKeys = useSelector(selectProcessesManagerByKeys)

            const {process} = useTrackProcess({
                process: processProp && byKeys[processProp],
            })

            const allProps = {...props, process}
            const handleSuppress = () => onSuppress(allProps)

            if (!process || process.isSuccessful) {
                return <WrappedComponent {...allProps} />
            }

            if (process.isFailed) {
                return (
                    <Error
                        title={__('Process failed')}
                        message={processErrorMessage(process.error, process.errorMessage)}
                    >
                        {onSuppress && (
                            <Button onClick={handleSuppress} {...buttonProps}>
                                {__('Suppress')}
                            </Button>
                        )}
                    </Error>
                )
            }

            return (
                <ProcessLoader
                    details={process.details}
                    percentage={process.percentage}
                    message={messageBuilder(process)}
                />
            )
        }

        fn.displayName = getHocName('withProcessLoader', WrappedComponent)

        return fn
    })
}
