import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const Rotation = () => {
    const {maxEventAge, maxProcessAge, maxReportsAge, maxRuleEngineLogs} = useSelector(
        (state) => state.system.settings.rotation.data
    )

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Events Age [days]')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxEventAge)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Process Age [days]')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxProcessAge)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Report Age [days]')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxReportsAge)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Maximum of Rule Engine logs count per panel user')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(maxRuleEngineLogs)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default Rotation
