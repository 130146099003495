import {createSelector} from 'reselect'

export const selectRemoteInspectionsListRows = createSelector(
    (state) => state.remoteInspections.list.page,
    (state) => state.remoteInspections.store,
    (state) => state.remoteInspections.results,
    (page, store, results) =>
        page.map((id) => ({
            ...store[id],
            latest: store[id].latestId ? results[store[id].latestId] : null,
        }))
)

export const selectRemoteInspectionsList = createSelector(
    (state) => state.remoteInspections.list,
    (list) => list
)
