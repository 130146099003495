import {defaultPerPage, selectPerPage} from 'modules/settings/selectors'
import {put, all, call, select, takeEvery} from 'redux-saga/effects'

import * as api from 'api/centralStations'
import * as actions from './actions'
import {update, purge} from '../groupsStore/actions'

import createListSaga from 'modules/higherOrder/createListSaga'

import {POLL_CS_GROUPS} from 'configs/pollers.js'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import toIds from 'utils/toIds'

import get from 'lodash-es/get'
import createMatchSelector from 'modules/router'
import {CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS} from 'constants/centralStation'
import {editGroupCsLinks} from 'modules/forms/handlers'
import {check} from 'utils/path'

const batchIdMatchSelector = createMatchSelector(
    '/central-stations/:centralStationId/communication'
)

const selector = (state) => {
    const match = batchIdMatchSelector(state)
    const centralStationId = get(match, 'params.centralStationId', null)
    const centralStationPerPage = selectPerPage(state, 'centralStationCommunication')

    return {
        ...state.centralStations.groupsList,
        perPage:
            centralStationPerPage === defaultPerPage
                ? CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS[0]
                : centralStationPerPage,
        centralStationId,
        listRoute: 'communication',
    }
}

export default function* () {
    yield all([
        createListSaga({fetch: api.getGroups}, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_CS_GROUPS, fetchGroups),
        takeEvery(editGroupCsLinks.SUCCESS, watchLinksUpdated),
    ])
}

function* watchLinksUpdated() {
    const {pathname} = yield select((state) => state.router.location)

    if (check('centralStation.communication', pathname)) {
        yield call(fetchGroups)
    }
}

function* fetchGroups() {
    const state = yield select(selector)

    const {rows, count} = yield call(api.getGroups, state)
    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

fetchGroups.onError = function* (error) {
    yield put(actions.receive(error))
}
