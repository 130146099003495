import {put, call} from 'redux-saga/effects'

import generateBatch from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_PMAXSTATEGET} from 'constants/processTypes'
import * as api from 'api/panels'
import {snackShow} from 'modules/snacks'

export default function* watchRefreshState({payload: panelIds}) {
    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_PMAXSTATEGET,
        panelIds
    )

    try {
        yield call(api.refreshState, panelIds, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
