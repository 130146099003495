import React, {Component} from 'react'
import getHocName from 'utils/getHocName'

export default function withLifeCycle(lifeCycle, mergeProps) {
    return (WrappedComponent) =>
        class extends Component {
            static displayName = getHocName('WithLifeCycle', WrappedComponent)

            state = {}

            componentDidMount() {
                lifeCycle.onMount && lifeCycle.onMount(this.props)
                super.componentDidMount && super.componentDidMount()
            }

            componentWillUnmount() {
                lifeCycle.onUnmount && lifeCycle.onUnmount(this.props)
                super.componentWillUnmount && super.componentWillUnmount()
            }

            static getDerivedStateFromProps(nextProps, prevState) {
                lifeCycle.onReceiveProps && lifeCycle.onReceiveProps(nextProps, prevState)
                super.getDerivedStateFromProps &&
                    super.getDerivedStateFromProps(nextProps)
                return {...nextProps}
            }

            componentDidUpdate(prevProps) {
                lifeCycle.onUpdate && lifeCycle.onUpdate(this.props, prevProps)
                super.componentDidUpdate && super.componentDidUpdate()
            }

            render() {
                const props = mergeProps ? mergeProps(this.props) : this.props

                return <WrappedComponent {...props} />
            }
        }
}
