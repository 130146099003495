import React from 'react'
import {compose} from 'redux'

import send from 'permissions/panel/devices/plink/log/send'

import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'

import Button from 'ipmp-react-ui/Button'
import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {usePlinkDebugCommands} from 'hooks/pages/useDevices'

const ButtonVisibleConfirmation = compose(withVisibility(), withConfirmation())(Button)

export default function SendLogButton({panelId, ...props}) {
    const {isPermitted} = usePermission(send)
    const {sendLog} = usePlinkDebugCommands(panelId)

    return (
        <ButtonVisibleConfirmation
            isVisible={isPermitted}
            onClick={sendLog}
            title={__('Send log')}
            message={__('Do you want to send log?')}
            positive={__('Send')}
            {...props}
        />
    )
}
