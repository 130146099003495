import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRun} from 'ipmp-react-ui/icons/run.svg'

import usePermission from 'hooks/usePermission'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {withVisibility} from 'containers/withVisibility'
import {start, list} from 'permissions/panel/systemTest/page'
import {useActions} from 'modules/higherOrder/useActions'
import {fetchTestsHistory, runPanelTest} from 'modules/panels/one/actions'
import {
    selectBatchesInProgress,
    selectGroupTestsBatches,
} from 'modules/panels/one/selectors'
import __ from 'utils/i18n'

const ButtonVisibility = withVisibility()(Button)

export default function RunGroupSystemTestsButton({
    tests,
    panelSerial,
    groupName,
    startedGroup,
    setStartedGroup,
    primary,
    externalLabel,
}) {
    const panelId = useRouterPanelId()

    const {isAllowedRunTests, isAllowedListTests} = usePermission({
        isAllowedRunTests: start,
        isAllowedListTests: list,
    })
    const {runTest, fetchTests} = useActions({
        runTest: runPanelTest,
        fetchTests: fetchTestsHistory,
    })

    const groupTestsBatches = useSelector((state) =>
        selectGroupTestsBatches(state, {tests, panelSerial})
    )

    const batchIds = groupTestsBatches.map((batch) => batch?.id)
    const batchIdsToString = batchIds.join()
    const isCurrentBatchesInProgress = useSelector((state) =>
        selectBatchesInProgress(state, groupTestsBatches)
    )

    const [startedTests, setStartedTests] = useState(
        batchIds && isCurrentBatchesInProgress
    )

    const onButtonClick = () => {
        runTest({panelId, tests: tests})
        setStartedTests(true)
        setStartedGroup(groupName)
    }

    const groupStarted =
        startedTests ||
        (batchIds && isCurrentBatchesInProgress) ||
        (groupName && startedGroup === groupName)

    const label = groupStarted ? __('Group Tests Started') : __('Start Test Group')

    useEffect(() => {
        if (!isCurrentBatchesInProgress && startedTests) {
            isAllowedListTests && fetchTests(panelId)
            setStartedTests(false)
            setStartedGroup(null)
        }
    }, [batchIdsToString, isCurrentBatchesInProgress])

    return (
        <ButtonVisibility
            Icon={IconRun}
            onClick={onButtonClick}
            panelId={panelId}
            isVisible={isAllowedRunTests}
            label={externalLabel ? externalLabel : label}
            disabled={groupStarted}
            primary={primary}
        />
    )
}
