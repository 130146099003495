import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import SelectProtocol from 'components/Devices/SelectProtocol'
import __ from 'utils/i18n'

export default function IdleContentAutomation({onChange, protocols, currentProtocol}) {
    return (
        <>
            <div className="autolearn-idle-icon">
                <IconAutolearn />
            </div>
            <div className="autolearn-description-content-title">
                {__('Autolearn automation devices')}
            </div>
            <div className="autolearn-description-content-message">
                {__('Select protocol and press "Start" to initiate autolearn process')}
            </div>
            <div className="autolearn-idle-form">
                <div className="autolearn-device-card-wrapper">
                    <SelectProtocol
                        onChange={onChange}
                        protocols={protocols}
                        defaultValue={currentProtocol || protocols[0]}
                    />
                </div>
            </div>
        </>
    )
}

IdleContentAutomation.propTypes = {
    onChange: PropTypes.func.isRequired,
    protocols: PropTypes.array.isRequired,
}
