import {createAction} from 'redux-actions'
import toArray from 'utils/toArray'

export const fetch = createAction('GROUPS/NOTIFICATION_SETTINGS/EVENT_PROFILES/FETCH')
export const setLoading = createAction(
    'GROUPS/NOTIFICATION_SETTINGS/EVENT_PROFILES/SET_LOADING',
    (isLoading = true) => isLoading
)

export const receive = createAction('GROUPS/NOTIFICATION_SETTINGS/EVENT_PROFILES/RECEIVE')
export const update = createAction(
    'GROUPS/NOTIFICATION_SETTINGS/EVENT_PROFILES/UPDATE',
    toArray
)
