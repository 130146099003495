import {createSelector} from 'reselect'
import {
    USER_APPLICATIONS,
    INSTALLER_APPLICATIONS,
    isAvailable,
    APPLICATIONS,
    REMOTE_INSPECTION,
    EVENTS,
} from 'constants/features'
import {NO_PLAN_ID} from 'constants/billingPlan'
import {err} from 'utils/log'

export const selectPanels = (state) => state.panels.store.byIds

export const selectPanel = createSelector(
    selectPanels,
    (state, {panelId}) => panelId,
    (panels, panelId) => panels[panelId]
)

export const selectPanelIdBySerial = createSelector(
    selectPanels,
    (state, {serial}) => serial,
    (panels, serial) =>
        Object.keys(panels).reduce(
            (acc, id) => (panels[id].serial === serial ? id : acc),
            null
        )
)

const createFeatureSelector = (feature) => {
    return createSelector(selectPanel, (panel) => {
        if (!panel) {
            err('No panel in store')

            return {isAvailable: false}
        }

        return {
            isAvailable: panel.features ? isAvailable(panel.features, feature) : false,
        }
    })
}

export const selectUserAppFeature = createFeatureSelector(USER_APPLICATIONS)

export const selectInstallerAppFeature = createFeatureSelector(INSTALLER_APPLICATIONS)

export const selectApplicationFeature = createFeatureSelector(APPLICATIONS)

export const selectRRIFeature = createFeatureSelector(REMOTE_INSPECTION)

export const selectEventFeature = createFeatureSelector(EVENTS)

export const selectSelectedFeature = createSelector(
    ({selection}) => selection,
    ({
        panels: {
            store: {byIds},
        },
    }) => byIds,
    (state, {feature}) => feature,
    (selection, panelsByIds, feature) =>
        resolveSelectionFeature(selection.toJS(), panelsByIds, feature)
)

export const selectSelectedBillingFilter = createSelector(
    ({selection}) => selection,
    ({
        panels: {
            store: {byIds},
        },
    }) => byIds,
    (selection, panelsByIds) =>
        resolveSelectionBillingFilter(selection.toJS(), panelsByIds)
)

export const selectSelectedRunnersFeature = createSelector(
    ({selection}) => selection,
    ({
        panels: {
            store: {byIds},
        },
    }) => byIds,
    ({
        runners: {
            store: {byIds},
        },
    }) => byIds,
    (state, {feature}) => feature,
    (selection, panelsByIds, runnersByIds, feature) => {
        const formattedSelection = selection.toJS()
        const resolvedPanelsByIds = formattedSelection.map(
            (runnerId) => runnersByIds[runnerId].panelId
        )

        return resolveSelectionFeature(
            resolvedPanelsByIds,
            panelsByIds,
            feature,
            formattedSelection
        )
    }
)

function resolveSelectionBillingFilter(selection, panelsByIds) {
    const result = {
        isAvailable: true,
        unavailablePanels: [],
        availableIds: [],
    }

    return selection.reduce((acc, panelId, index) => {
        const panel = panelsByIds[panelId]
        const billingPlan = panel?.billing?.planId
        const isAvailable = billingPlan === NO_PLAN_ID || billingPlan === undefined

        if (!isAvailable) {
            acc.isAvailable = false

            acc.unavailablePanels.push(panel)
        } else {
            acc.availableIds.push(panelId)
        }

        return acc
    }, result)
}

function resolveSelectionFeature(selection, panelsByIds, feature, runners) {
    const result = {
        isFeatureAvailable: true,
        unavailablePanels: [],
        availableIds: [],
    }

    return selection.reduce((acc, panelId, index) => {
        const panel = panelsByIds[panelId]
        const isFeauterAvailable = isAvailable(panel.features, feature)

        if (!isFeauterAvailable) {
            if (acc.isFeatureAvailable) {
                acc.isFeatureAvailable = false
            }

            acc.unavailablePanels.push(panel)
        } else {
            if (runners) {
                acc.availableIds.push(runners[index])
            } else {
                acc.availableIds.push(panelId)
            }
        }

        return acc
    }, result)
}
