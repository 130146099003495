import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import EventTypeForm from './EventTypeForm'
import {validation} from './EventTypeFieldset'
import {addEventType} from 'modules/forms/handlers'

export default function AddEventType({hide}) {
    const {handle, isLoading, form} = useForm(addEventType)

    return (
        <EventTypeForm
            {...form}
            hide={hide}
            handle={handle}
            rules={validation}
            isLoading={isLoading}
            header={__('Add Event Type')}
        />
    )
}
