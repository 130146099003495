import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import devicesList from 'permissions/panel/devices/list'

import {fetch as fetchDevices, startPoll, stopPoll} from 'modules/devices/list/actions'
import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'
import {setBypass} from 'modules/devices/list/actions'
import withLifeCycle from 'containers/withLifeCycle'
import {withPermissionVisibility} from 'containers/withPermission'
import EventDevice from 'components/Devices/EventDevice'

function DeviceCardBase(props) {
    const {device} = props
    if (null === device) {
        return null
    }

    return <EventDevice {...props} />
}

export const DeviceCard = compose(
    withPermissionVisibility(devicesList),
    connect(
        (state, {panelId, event: {zone, deviceType}}) => {
            const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})
            return {
                isLoading: !device,
                device: device?.deviceType === deviceType ? device : null,
            }
        },
        (dispatch, {panelId}) =>
            bindActionCreators(
                {
                    fetchDevices: () => fetchDevices(panelId),
                    setBypass: (deviceId, state) =>
                        setBypass(panelId, new Map([[deviceId, state]])),
                    startPollDevice: () => startPoll(panelId),
                    stopPollDevice: () => stopPoll(),
                },
                dispatch
            )
    ),
    withLifeCycle({
        onMount: ({fetchDevices, startPollDevice}) => {
            fetchDevices()
            startPollDevice()
        },
        onUnmount: ({stopPollDevice}) => {
            stopPollDevice()
        },
    })
)(DeviceCardBase)
