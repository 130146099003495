import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import {clear} from 'modules/forms/actions'
import ModalCardForm from 'ui/ModalCardForm'
import {addPanel} from 'modules/forms/handlers'
import panelVendorType from 'constants/panelVendorType'
import {useActions} from 'modules/higherOrder/useActions'
import PanelVendorsListSelect from './PanelVendorListSelect'
import PanelFieldSet, {validation as panelValidation} from './Fieldset/Panel'
import CustomerFieldSet, {validation as customerValidation} from './Fieldset/Customer'

export default function AddPanel({hide}) {
    const {form, handle, isLoading} = useForm(addPanel)

    const googleToken = useSelector(
        ({system}) => system.settings.googleSettings.data.token
    )

    const clearForm = useActions(clear)
    useEffect(() => {
        return clearForm
    }, [])

    const [vendor, setVendor] = useState(null)

    const changePanelType = (e, value) => {
        setVendor(value)
    }

    const header = vendor ? __('Add %s Panel', panelVendorType(vendor)) : __('Add Panel')

    const rules = {
        ...customerValidation,
        ...panelValidation(vendor),
    }

    return (
        <ModalCardForm
            wide
            {...form}
            hide={hide}
            rules={rules}
            header={header}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
        >
            <div className="cols cols--stretch">
                <div className="col">
                    <h3 className="form-section-header">{__('Panel Info')}</h3>

                    <PanelVendorsListSelect changePanelParent={changePanelType} />

                    <PanelFieldSet isNew vendor={vendor} />
                </div>

                <div className="col">
                    <h3 className="form-section-header">{__('Owner Info')}</h3>
                    <CustomerFieldSet googleToken={googleToken} isNew />
                </div>
            </div>
        </ModalCardForm>
    )
}
