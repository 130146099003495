import React from 'react'
import {compose} from 'redux'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as DisableUserIcon} from 'ipmp-react-ui/icons/disable-user.svg'

import {disablePanelUser} from 'modules/forms/handlers'
import withConfirmation from 'containers/withConfirmation'
import {withVisibility} from 'containers/withVisibility'
import {__} from 'utils/i18n'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {useActions} from 'modules/higherOrder/useActions'
import useForm from 'hooks/useForm'
import {selectUserEditableFeature} from 'modules/features/store/selectors'
import {USER_FEATURE_DISABLE_USER} from 'constants/features'
import {normalizePanelUserIndex} from 'utils/panelUser'
import {isAllowDisableUser} from 'constants/accountRoles'
import usePermission from 'hooks/usePermission'
import {disableUser} from 'permissions/panel/customers/actions'

const ButtonVisibility = compose(withVisibility(), withConfirmation())(Button)

export default function DisablePanelUserButton({row, hasLabel}) {
    const {id, isPinSet, label, role} = row
    const {isPermitted} = usePermission(disableUser)
    const panelId = useRouterPanelId()
    const isAllowedDisableUser = useSelector((state) =>
        selectUserEditableFeature(state, {
            panelId,
            item: USER_FEATURE_DISABLE_USER,
        })
    )
    const {handle} = useForm(disablePanelUser)
    const {onRemove} = useActions({
        onRemove: () => handle({id: id, panelId: panelId}),
    })

    const isVisibleButton =
        isPinSet && isAllowedDisableUser && isAllowDisableUser(role) && isPermitted

    return (
        <ButtonVisibility
            isVisible={isVisibleButton}
            flat
            small={!hasLabel}
            Icon={DisableUserIcon}
            onClick={onRemove}
            message={__(
                'Do you really want to disable %s?',
                label || role + ' ' + normalizePanelUserIndex(id)
            )}
            title={__('Disable panel user')}
            children={false}
            label={hasLabel && __('Disable panel user')}
        />
    )
}
