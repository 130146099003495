import React from 'react'

import {CONFIGURATION_UPLOAD} from 'constants/features'
import {useActions} from 'modules/higherOrder/useActions'
import {withSelectionFeature} from 'containers/withFeature'
import {selectSelectedRunnersFeature} from 'modules/panels/store/selectors'
import {showPushBasicConfigurationModal} from 'modules/runners/store/actions'
import {PushBasicConfigurationItem} from 'pages/Panels/GridBar/DropDowns/Items/PushBasicConfigurationItem'

const PushBasicConfigurationSelectionFeature = withSelectionFeature(
    CONFIGURATION_UPLOAD,
    selectSelectedRunnersFeature
)(PushBasicConfigurationItem)

export default function PushBasicConfiguration() {
    const onClick = useActions(showPushBasicConfigurationModal)

    return <PushBasicConfigurationSelectionFeature onClick={onClick} />
}
