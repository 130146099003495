import React from 'react'

import path from 'utils/path'
import ReportsPage from './ReportsPage'

export default class {
    path = path('reports')

    element = (<ReportsPage />)
}
