import React from 'react'
import {useSelector} from 'react-redux'
import * as PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import get from 'lodash-es/get'
import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setPanelUserLabel} from 'modules/forms/handlers'
import {selectDevicesLabelValidationRules} from 'modules/features/store/selectors'
import {DEVICE_TYPE_USER} from 'constants/deviceType'

const getRules = (panelId) => {
    const vendor = useSelector(({panels}) => {
        return panels.store.byIds[panelId].vendor
    })

    const featureRules = useSelector(({features}) =>
        selectDevicesLabelValidationRules(features, panelId)
    )

    const userLabelValidationRules = get(featureRules, [DEVICE_TYPE_USER])

    return {
        label: {
            presence: true,
            userLabel: {
                panelId,
                vendor,
                validationRules: userLabelValidationRules,
            },
        },
    }
}

export default function SetPanelUserLabel({hide, panelId, panelUserId, userLabel}) {
    const {form, handle, isLoading} = useForm(setPanelUserLabel)

    const onHandle = ({label}) => {
        handle({panelId, panelUserId, label})
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={onHandle}
            isLoading={isLoading}
            rules={getRules(panelId)}
            header={__('Set panel user label')}
        >
            <Input label={__('Label')} name="label" defaultValue={userLabel} />
        </ModalCardForm>
    )
}

SetPanelUserLabel.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
