import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {NavLink} from 'react-router-dom'

import classes from 'classnames'

import __ from 'utils/i18n'

import {ReactComponent as FilterIcon} from 'ipmp-react-ui/icons/filter.svg'

export class NavLinkGroup extends Component {
    static propTypes = {
        Icon: PropTypes.elementType,
        to: PropTypes.string,
        hideMenuLabels: PropTypes.bool,
        name: PropTypes.string.isRequired,
        activate: PropTypes.func,
        Coin: PropTypes.elementType,
        childrenPermissions: PropTypes.array,
    }

    state = {
        active: !!this.props.match,
        open: !!this.props.match,
        scopeOpen: false,
        scopesElement: null,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside)
    }

    dropdownRef = React.createRef()

    handleClickOutside = (e) => {
        if (e.target.closest('.nav-link-coin-dropDown-container')) {
            return
        }
        if (
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(e.target) &&
            this.state.open
        ) {
            this.setState({open: false})
        }
        if (
            this.dropdownRef.current &&
            !this.dropdownRef.current.contains(e.target) &&
            this.state.scopeOpen
        ) {
            this.setState({scopeOpen: false})
        }
    }

    toggle = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({open: !this.state.open})
    }

    toggleScope = (e) => {
        e.stopPropagation()
        e.preventDefault()
        this.setState({scopeOpen: !this.state.scopeOpen})
    }

    hasChildren() {
        return React.Children.count(this.props.children) > 0
    }

    renderToggle() {
        const {match, activate} = this.props
        if (!this.hasChildren()) {
            return
        }

        const props = {
            className: classes('nav-link-filter', {
                'nav-link-filter--active': this.state.scopeOpen || activate(match),
            }),
            onClick: this.toggleScope,
        }

        return (
            <div {...props}>
                {!this.props.hideMenuLabels && <span>{__('Filters')}</span>}
                <FilterIcon />
            </div>
        )
    }

    handleScopesRef = (element) => {
        if (!element) {
            return
        }
        this.setState((state) => ({...state, scopesElement: element}))
    }

    renderChildren() {
        const {scopes} = this.props

        if (scopes || !this.hasChildren() || (!this.state.active && !this.state.open)) {
            return
        }

        return (
            <div className="nav-submenu" onClick={this.toggle}>
                {this.props.children}
            </div>
        )
    }

    renderChildrenScope() {
        const {scopes} = this.props
        const windowHeight = window.innerHeight
        let bottomPosition

        if (this.state.scopesElement) {
            const {bottom: elementBottom} =
                this.state.scopesElement.getBoundingClientRect()
            bottomPosition = windowHeight < elementBottom
        }

        if (!scopes || !this.state.scopeOpen) {
            return
        }

        return (
            <div
                className={classes('nav-submenu filter-submenu', {
                    'filter-submenu-bottom-offset': bottomPosition,
                })}
                onClick={this.toggleScope}
                ref={this.handleScopesRef}
            >
                {this.props.children}
            </div>
        )
    }

    showNavigate = () => {
        const {to, isPermitted} = this.props

        if (isPermitted) {
            return to
        } else {
            return false
        }
    }

    hasPermitedChildren = () => {
        const {childrenPermissions} = this.props

        if (childrenPermissions) {
            return childrenPermissions.every((permission) => permission)
        } else {
            return false
        }
    }

    renderContent() {
        const {Icon, name, Coin, scopes} = this.props

        return (
            <Fragment>
                {Coin && <Coin />}

                {Icon && <Icon className="nav-link-icon" />}

                <div className="nav-link-content">{name}</div>

                {scopes && this.renderToggle()}
            </Fragment>
        )
    }

    render() {
        const {to, location, isPermitted, scopes, handleClick, toStartWith} = this.props

        if (!isPermitted && !this.hasPermitedChildren()) {
            return null
        }
        const isActiveByStartWith =
            toStartWith && location.pathname.startsWith(toStartWith)

        return (
            <div
                className="nav-link-wrapper"
                ref={this.dropdownRef}
                onClick={handleClick}
            >
                {this.showNavigate() && (
                    <NavLink
                        className={({isActive}) =>
                            'nav-link' +
                            (isActiveByStartWith || isActive ? ' nav-link--active' : '')
                        }
                        to={to}
                    >
                        {this.renderContent()}
                    </NavLink>
                )}

                {!this.showNavigate() && (
                    <div className="nav-link" {...(!scopes && {onClick: this.toggle})}>
                        {this.renderContent()}
                    </div>
                )}

                {this.renderChildren()}
                {this.renderChildrenScope()}
            </div>
        )
    }
}

export default NavLinkGroup
