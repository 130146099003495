import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import path from 'utils/path'
import {__} from 'utils/i18n'
import * as actions from './actions'
import {patchTagUpdate} from 'api/firmware'
import {snackShow} from 'modules/snacks'
import {selectPerPage} from 'modules/settings/selectors'
import {purge, update} from 'modules/panels/store/actions'
import createListSaga from 'modules/higherOrder/createListSaga'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'
import generateBatch from 'modules/batches/manager/generateBatch'
import {fetchPatchTaggable as fetch, suggestPatchTaggable as suggest} from 'api/panels'
import {resolvePanelsIdsOptimistic} from 'utils/panel/resolvePanelsIdsOptimistic'

const selector = (state) => {
    return {
        ...state.firmware.patchTagUpdate,
        perPage: selectPerPage(state, 'firmwarePatchTagUpdate'),
        listRoute: path('firmware.patchTagUpdate'),
    }
}

export default function* () {
    yield all([
        createListSaga({fetch, suggest}, actions, update, selector, purge),
        takeEvery(actions.upgrade, watchPatchTagUpgrade),
    ])
}

function* watchPatchTagUpgrade({payload}) {
    const {timeout, panelIds, packageName} = payload
    const {filters, total, page} = yield select((state) => state.firmware.patchTagUpdate)

    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_NEO_UPGRADE,
        resolvePanelsIdsOptimistic(total, page, panelIds)
    )

    try {
        yield call(patchTagUpdate, {
            filters,
            packageName,
            batchId,
            timeout,
            panelIds,
        })

        yield put(snackShow(__('Patch tag will be updated')))
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
