import {createSelector} from 'reselect'

import {BILLING_STATUS_VIEW_SHORT} from 'constants/billingPlan'

const PER_PAGE_SELECTOR_KEY_CS_COMMUNICATION = 'centralStationCommunication'
const PER_PAGE_SELECTOR_KEY_GROUP_CS = 'groupCentralStations'

export const defaultPerPage = 50

const getDefaultPerPageByKey = (key) => {
    switch (key) {
        case PER_PAGE_SELECTOR_KEY_CS_COMMUNICATION:
        case PER_PAGE_SELECTOR_KEY_GROUP_CS:
            return 8
        default:
            return defaultPerPage
    }
}

export function selectPerPage(store, key) {
    return store.settings.perPage[key] || getDefaultPerPageByKey(key)
}

export const selectTemperatureScale = createSelector(
    (state) => state?.settings?.temperature,
    (temperature) => temperature
)

export const selectTheme = createSelector(
    (state) => state?.settings?.theme,
    (theme) => theme
)

export const selectBillingStatusView = createSelector(
    (state) => state.settings.billingStatusView,
    (billingStatusView) => billingStatusView || BILLING_STATUS_VIEW_SHORT
)
