import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import ToggleBox from 'ipmp-react-ui/ToggleBox'
import Definition from 'ipmp-react-ui/Definition'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {editGroupCsLinks} from 'modules/forms/handlers'
import withModalLoader from 'containers/withModalLoader'
import csProfileType, {CS_PROFILE_SMART_SENSORS} from 'constants/csProfileType'
import {fetch as fetchEventProfiles} from 'modules/centralStations/profiles/actions'

function EditLinks({hide, profiles, links, centralStation, groupId}) {
    const {form, handle, isLoading} = useForm(editGroupCsLinks)
    const [active, setActive] = useState(links || [])

    const handler = () => {
        handle(groupId, centralStation.id, active)
    }

    const handleChange = (key, value) => {
        let activeCopy = [...active]
        const includes = activeCopy.includes(key)

        if (value && !includes) {
            activeCopy = [...activeCopy, key]
        }

        if (!value && includes) {
            activeCopy = activeCopy.filter((id) => id !== key)
        }

        setActive(activeCopy)
    }

    const handleSelectAll = (e) => {
        setActive(e.target.checked ? Object.keys(profiles).map(Number) : [])
    }

    const isProfileAvailable = (key) => {
        if (profiles[key].name === CS_PROFILE_SMART_SENSORS) {
            // Smart sensors avaible only with 'VIS NAP V3' protocol
            return /VIS NAP V3/.test(centralStation.protocol)
        }

        return true
    }

    const renderList = () => {
        return Object.keys(profiles)
            .map(Number)
            .filter(isProfileAvailable)
            .map((key) => (
                <ToggleBox
                    key={key}
                    value={key}
                    onChange={(e) => {
                        handleChange(key, e.target.checked)
                    }}
                    checked={active.includes(key)}
                    defaultChecked={active.includes(key)}
                    name={`profile[${key}]`}
                    label={csProfileType(profiles[key].name)}
                />
            ))
    }

    const enabledAll = active.length >= Object.keys(profiles).length

    return (
        <ModalCardForm
            wide
            {...form}
            hide={hide}
            header={
                <Definition
                    className="central-stations-edit-links-header"
                    title={centralStation.name}
                    detail={centralStation.protocol}
                />
            }
            onSubmit={handler}
            isLoading={isLoading}
            className="central-stations-edit-links-form"
        >
            <div className={'notifications-profile-header'}>
                <ToggleBox
                    name="allProfiles"
                    label={__('All-Profiles')}
                    checked={enabledAll}
                    defaultChecked={enabledAll}
                    onChange={handleSelectAll}
                />
            </div>

            <div className="cols--stretch groupForm-grid-notifications">
                {renderList()}
            </div>
        </ModalCardForm>
    )
}

EditLinks.propTypes = {
    groupId: PropTypes.number.isRequired,
    centralStation: PropTypes.object.isRequired,
    profiles: PropTypes.object.isRequired,
    links: PropTypes.array,
    hide: PropTypes.func.isRequired,
}

export default compose(
    connect(
        ({centralStations, groups}, {groupId, centralStationId, links}) => ({
            isLoading: centralStations.profiles.isLoading, // used by withModalLoader
            error: centralStations.profiles.error, // used by withModalLoader
            profiles: centralStations.profiles.byId,
            links: links ?? groups?.links[groupId][centralStationId],
            centralStation:
                centralStations.store.byIds[centralStationId] ||
                groups.centralStations.store.byIds[centralStationId],
        }),

        (dispatch) =>
            bindActionCreators(
                {
                    fetchEventProfiles,
                },
                dispatch
            )
    ),

    withModalLoader(({fetchEventProfiles}) => fetchEventProfiles())
)(EditLinks)
