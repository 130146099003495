import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconRefresh} from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {REFRESH_STATE} from 'constants/features'
import {useActions} from 'modules/higherOrder/useActions'
import {withVisibility} from 'containers/withVisibility'
import {refreshState} from 'modules/panels/store/actions'
import {withSelectionFeature} from 'containers/withFeature'
import {withSelectionHandler} from 'containers/withSelection'
import {refreshState as permission} from 'permissions/panels/selection'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export function RefreshStateBase({onClick}) {
    const {isPermitted} = usePermission(permission)

    return (
        <ConnectedMenuItem onClick={onClick} Icon={IconRefresh} isVisible={isPermitted}>
            {__('Refresh State')}
        </ConnectedMenuItem>
    )
}

const RefreshStateSelectionFeature = withSelectionFeature(REFRESH_STATE)(RefreshStateBase)

export default function RefreshStateItem() {
    const onClick = useActions(refreshState)

    return <RefreshStateSelectionFeature onClick={onClick} />
}
