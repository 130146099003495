import {useSelector} from 'react-redux'

import {
    selectConfigurationsList,
    selectPendingChanges,
} from 'modules/panels/configuration/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch,
    markAsBackup,
    refresh,
    refreshRunner,
} from 'modules/panels/configuration/actions'
import {startPollRunnerData, stopPollRunnerData} from 'modules/runners/actions'

export function useConfigurationsList({panelId}) {
    const {rows, runner, isLoading, error, outdated} = useSelector((state) =>
        selectConfigurationsList(state, {panelId})
    )

    const {makeBasic, pendingChanges, pendingExport} = useSelector((state) =>
        selectPendingChanges(state, {panelId})
    )

    return {
        rows,
        runner,
        isLoading,
        error,
        outdated,
        makeBasic,
        pendingChanges,
        pendingExport,
        ...useActions({
            fetch: () => fetch(panelId),
            markAsBackup: (id) => markAsBackup(panelId, id),
            refresh: () => refresh(panelId),
            startPollRunnerData,
            stopPollRunnerData,
            refreshRunner,
        }),
    }
}
