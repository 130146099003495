import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

class RotationSettingsBase extends PureComponent {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        data: PropTypes.shape({
            maxEventAge: PropTypes.number,
            maxProcessAge: PropTypes.number,
            maxReportsAge: PropTypes.number,
            maxRuleEngineLogs: PropTypes.number,
        }),
    }

    handle = (data) => {
        const {handle} = this.props
        handle(data)
    }

    rules = {
        maxEventAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
        maxProcessAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
        maxReportsAge: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 367,
            },
        },
        maxRuleEngineLogs: {
            presence: {},
            numericality: {
                onlyInteger: true,
                greaterThan: 0,
                lessThan: 5001,
            },
        },
    }

    render() {
        const {data, ...props} = this.props

        return (
            <ModalCardForm
                onSubmit={this.handle}
                rules={this.rules}
                header={__('Edit Rotation Settings')}
                confirmOnDismiss
                {...props}
            >
                <Input
                    label={__('Events Age [DAYS]')}
                    name="maxEventAge"
                    defaultValue={data.maxEventAge}
                />
                <Input
                    label={__('Processes  Age [DAYS]')}
                    name="maxProcessAge"
                    defaultValue={data.maxProcessAge}
                />
                <Input
                    label={__('Reports Age [DAYS]')}
                    name="maxReportsAge"
                    defaultValue={data.maxReportsAge}
                />
                <Input
                    label={__('Maximum of Rule Engine logs count per panel user')}
                    name="maxRuleEngineLogs"
                    defaultValue={data.maxRuleEngineLogs}
                />
            </ModalCardForm>
        )
    }
}

export default function RotationSettings({data, hide}) {
    const {form, isLoading, handle} = useForm(setSystemSettings.Rotation)

    return (
        <RotationSettingsBase
            {...form}
            hide={hide}
            data={data}
            handle={handle}
            isLoading={isLoading}
        />
    )
}
