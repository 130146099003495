import {handleActions} from 'redux-actions'

import {update, clear, purge, setFirstPageStreamable} from './actions'
import {setUnhandledFirst} from '../list/actions'
import createPurgeStoreHandler from 'modules/higherOrder/createPurgeStoreHandler'

export const IS_FIRST_PAGE_STREAMABLE_NAME = 'isFirstPageStreamable'
export const UNHANDLED_EVENTS_SHOWED_FIRST = 'unhandledShowedFirst'

const defaultState = {
    byIds: {},
    [IS_FIRST_PAGE_STREAMABLE_NAME]: {
        state: true,
    },
    [UNHANDLED_EVENTS_SHOWED_FIRST]: false,
}

export default handleActions(
    {
        [setFirstPageStreamable](state, {payload}) {
            return {
                ...state,
                [IS_FIRST_PAGE_STREAMABLE_NAME]: {
                    state: payload[IS_FIRST_PAGE_STREAMABLE_NAME],
                },
            }
        },
        [setUnhandledFirst](state, {payload: {isUnhandledFirst}}) {
            return {
                ...state,
                [UNHANDLED_EVENTS_SHOWED_FIRST]: isUnhandledFirst,
            }
        },
        [update](state, {payload}) {
            const byIds = payload.reduce((acc, row) => {
                const old = acc[row.id]

                return {
                    ...acc,
                    [row.id]: {
                        ...old,
                        ...row,
                    },
                }
            }, state.byIds || {})

            return {
                ...state,
                byIds,
            }
        },

        [clear]() {
            return defaultState
        },
        ...createPurgeStoreHandler(purge),
    },
    defaultState
)
