import React from 'react'

import Empty from 'ui/Empty'

import {suspend as suspendPermission} from 'permissions/interactiveUsers/actions'

import usePermission from 'hooks/usePermission'

import {InteractiveUserStatusChanger} from 'pages/InteractiveUsers/TableCell/InteractiveUserStatusChanger'

class PanelInteractiveUserStatusChangerBase extends InteractiveUserStatusChanger {
    render() {
        return this.props.row.isAssign ? (
            <div className="c-panel-interactive-user-status-changer">
                {super.render()}
            </div>
        ) : (
            <Empty />
        )
    }
}

export default function PanelInteractiveUserStatusChanger(props) {
    const {isPermitted} = usePermission(suspendPermission)

    return (
        <PanelInteractiveUserStatusChangerBase
            {...props}
            disabled
            isPermitted={isPermitted}
        />
    )
}
