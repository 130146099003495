import React from 'react'

import path from 'utils/path'
import DashboardPage from './DashboardPage'

export default class {
    path = path('dashboard')

    element = (<DashboardPage />)
}
