import keyBy from 'lodash-es/keyBy'
import {handleActions} from 'redux-actions'

import {update, remove as removeAction} from './actions'
import {merge, remove} from 'immutable-modify'

const defaultState = {
    byIds: {},
}

export default handleActions(
    {
        [update](state, {payload: batches}) {
            return merge(state, 'byIds', keyBy(batches, 'id'))
        },
        [removeAction](state, {payload: id}) {
            return remove(state, ['byIds', id])
        },
    },
    defaultState
)
