import React from 'react'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

import bar from 'permissions/events/bar'
import EventPager from './GridBar/EventPager'
import usePermission from 'hooks/usePermission'
import EventDropDown from './GridBar/EventDropDown'
import {withVisibility} from 'containers/withVisibility'
import MarkAsViewedButton from './GridBar/MarkAsViewedButton'
import LoadNewEventsButton from './GridBar/LoadNewEventsButton'
import StreamEventsButtons from './GridBar/StreamEventsButtons'

const EventsGridBarBase = () => (
    <Bar>
        <EventDropDown />
        <MarkAsViewedButton />
        <BarSpace />
        <LoadNewEventsButton />
        <BarSpace />
        <EventPager />
        <StreamEventsButtons />
    </Bar>
)

const EventsGridBarVisibility = withVisibility()(EventsGridBarBase)

export default function EventsGridBar() {
    const {isPermitted} = usePermission(bar)

    return <EventsGridBarVisibility isVisible={isPermitted} />
}
