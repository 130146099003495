import {handleActions} from 'redux-actions'
import {show, hide, scroll} from './actions'

const defaultState = {}

export default handleActions(
    {
        [show](state, {payload}) {
            return {...state, ...payload}
        },

        [hide]() {
            return defaultState
        },
        [scroll](state, {payload}) {
            return {...state, ...payload}
        },
    },
    defaultState
)
