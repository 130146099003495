import React from 'react'

import getHocName from 'utils/getHocName'
import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'

import usePermission from '../hooks/usePermission'

export function withPermissionVisibility(permission) {
    return (WrappedComponent) => {
        const fn = ({...props}) => {
            const {isPermitted} = usePermission(permission)

            if (isPermitted) {
                return <WrappedComponent {...props} />
            }

            return null
        }

        fn.displayName = getHocName('withPermissionVisibility', WrappedComponent)

        return fn
    }
}

export function withPermissionRejection(permission) {
    return (WrappedComponent) => {
        const fn = ({isAllowed, ...props}) => {
            const {isPermitted} = usePermission(permission)

            if (isAllowed && isPermitted) {
                return <WrappedComponent {...props} />
            }

            return (
                <Error
                    title={__('Content not granted')}
                    message={__('Sorry. You are not allowed to see this content.')}
                />
            )
        }

        fn.displayName = getHocName('withPermissionRejection', WrappedComponent)

        fn.defaultProps = {
            isAllowed: true,
        }

        return fn
    }
}
