import React from 'react'
import PropTypes from 'prop-types'

import IntValue from 'components/Configuration/Row/IntValue'
import FloatInput from 'ipmp-react-ui/FloatInput'
import has from 'lodash-es/has'
import {__} from 'utils/i18n'

export default class FloatValue extends IntValue {
    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    validate(value) {
        if (value === null || value === '' || value === undefined) {
            return __('Required')
        }

        const {item} = this.props

        const floatVal = parseFloat(value)

        const regex = new RegExp(/^[+-]?\d+(\.\d+)?$/)
        const matches = regex.exec(value)
        if (null === matches) {
            return __('Not a valid float value')
        }

        if (has(item, 'max') && floatVal > item.max) {
            return __('Should be less than or equal to %s', item.max)
        }

        if (has(item, 'min') && floatVal < item.min) {
            return __('Should be greater than or equal to %s', item.min)
        }
    }

    getPrecision() {
        return this.props.item.precision
    }

    render() {
        const value = this.getValue()

        return (
            <FloatInput
                precision={this.getPrecision()}
                placeholder={__('Not set')}
                value={value}
                maxLength={this.getMaxLength()}
                onChange={this.handleChange}
                error={this.validate(value)}
            />
        )
    }
}
