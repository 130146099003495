import React from 'react'

import Page from 'ipmp-react-ui/Page'

import RunnersTable from './RunnersTable'
import RunnersGridBar from './RunnersGridBar'

export default function RunnersPage() {
    return <Page Bar={RunnersGridBar} Content={RunnersTable} />
}
