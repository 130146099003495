import React from 'react'

import {pathParent} from 'utils/path'
import SettingsPage from 'pages/Settings/SettingsPage'

export default class {
    path = pathParent('settings')

    element = (
        <div className="systemSettings">
            <SettingsPage />
        </div>
    )
}
