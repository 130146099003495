import {useSelector} from 'react-redux'

import {selectUsersListRows} from 'modules/users/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    init,
    remove,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/users/list/actions'
import {
    showAddUserModal,
    showEditUserModal,
    showRemoveUserModal,
} from 'modules/modals/actions'
import {setEnabled} from 'modules/users/store/actions'
import usePerPage from '../usePerPage'

export default function useUsers() {
    const {onPerPageChange, perPage} = usePerPage('users')
    const {isLoading, error, total, start, rows} = useSelector(selectUsersListRows)

    return {
        isLoading,
        error,
        total,
        start,
        rows,
        onPerPageChange,
        perPage,
        ...useActions({
            init,
            startPoll,
            stopPoll,
            reset,
            onPageChange: setStart,
            showAddUser: showAddUserModal,
            showEditUser: showEditUserModal,
            showRemoveUser: showRemoveUserModal,
            remove,
            enable: (ids) => setEnabled(ids, true),
            suspend: (ids) => setEnabled(ids, false),
        }),
    }
}
