import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

const rules = {
    'first.maxAttemptsCount': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 100,
            greaterThanOrEqualTo: 1,
        },
    },
    'first.timeout': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 65535,
            greaterThanOrEqualTo: 0,
        },
    },
    'first.expirationPeriod': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 65535,
            greaterThanOrEqualTo: 0,
        },
    },
    'second.maxAttemptsCount': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 100,
            greaterThanOrEqualTo: 1,
        },
    },
    'second.timeout': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 100000,
            greaterThanOrEqualTo: 1,
        },
    },
    'second.expirationPeriod': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 100000,
            greaterThanOrEqualTo: 10,
        },
    },
    'email.codeLength': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 255,
            greaterThanOrEqualTo: 5,
        },
    },
    'email.expirationPeriod': {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 65535,
            greaterThanOrEqualTo: 30,
        },
    },
    userLifetime: {
        presence: {},
        numericality: {
            onlyInteger: true,
            lessThanOrEqualTo: 30,
            greaterThanOrEqualTo: 1,
        },
    },
}

export default function AuthorizationSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.AuthorizationSettings)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Authorization Settings')}
        >
            <Input
                label={__('First factor expiration period [Seconds]')}
                name="first.expirationPeriod"
                defaultValue={data.first.expirationPeriod}
            />

            <Input
                label={__('Email code expiration period for Applications [seconds]')}
                name="email.expirationPeriod"
                defaultValue={data.email.expirationPeriod}
            />

            <Input
                label={__('Email code length for Installer App')}
                name="email.codeLength"
                defaultValue={data.email.codeLength}
            />

            <Input
                label={__('Account login attempts limit')}
                name="first.maxAttemptsCount"
                defaultValue={data.first.maxAttemptsCount}
            />

            <Input
                label={__('Account login lock timeout [seconds]')}
                name="first.timeout"
                defaultValue={data.first.timeout}
            />

            <Input
                label={__('Panel login attempts limit')}
                name="second.maxAttemptsCount"
                defaultValue={data.second.maxAttemptsCount}
            />

            <Input
                label={__('Panel login lock timeout [Seconds]')}
                name="second.timeout"
                defaultValue={data.second.timeout}
            />

            <Input
                label={__('Second factor expiration period [Seconds]')}
                name="second.expirationPeriod"
                defaultValue={data.second.expirationPeriod}
            />

            <Input
                label={__('Permanent user lifetime [Days]')}
                name="userLifetime"
                defaultValue={data.userLifetime}
            />

            <Checkbox
                label={__('Enable Allow Installer Remove Panels')}
                name="installerAllowedToRemovePanels"
                defaultChecked={data.installerAllowedToRemovePanels}
            />
        </ModalCardForm>
    )
}

AuthorizationSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        first: PropTypes.shape({
            maxAttemptsCount: PropTypes.number,
            timeout: PropTypes.number,
            expirationPeriod: PropTypes.number,
        }),
        second: PropTypes.shape({
            maxAttemptsCount: PropTypes.number,
            timeout: PropTypes.number,
            expirationPeriod: PropTypes.number,
        }),
        email: PropTypes.shape({
            codeLength: PropTypes.number,
            expirationPeriod: PropTypes.number,
        }),
        userLifetime: PropTypes.number,
        installerAllowedToRemovePanels: PropTypes.bool,
    }),
}
