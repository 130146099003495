import {MENU_TYPE} from 'constants/panelConfiguration'

const HEIGHT_ROW = 60
const HEIGHT_SECTION_MARGIN = 32
const HEIGHT_SECTION_TITLE = 34
const HEIGHT_TITLE = 88
const HEIGHT_LIST_TITLE_MARGIN = 0
const HEIGHT_FIRST_TITLE_MARGIN = 16

export const HEIGHT_OFFSET_BEFORE_CONFIGURATION_LIST = 172
export const HEIGHT_OFFSET_BEFORE_BASIC_CONFIGURATION_LIST = 60

function measureSection({val, key}, startOffset) {
    let lastRow = false
    let offset = startOffset

    const sectionReducer = (nodes, keys, acc = []) =>
        nodes.reduce((acc, node) => {
            const newKey = [...keys, node.key]

            if (node.type === MENU_TYPE) {
                if (lastRow) {
                    offset += HEIGHT_LIST_TITLE_MARGIN
                    lastRow = false
                }

                if (acc.length === 0) {
                    offset += HEIGHT_FIRST_TITLE_MARGIN
                }

                acc.push({
                    key: newKey,
                    level: newKey.length - 1,
                    offset,
                    bottom: offset + HEIGHT_TITLE,
                    node,
                })

                offset += HEIGHT_TITLE
                return sectionReducer(node.val, newKey, acc)
            }

            acc.push({
                key: newKey,
                offset,
                bottom: offset + HEIGHT_ROW,
                node,
            })

            lastRow = true
            offset += HEIGHT_ROW

            return acc
        }, acc)

    const items = sectionReducer(val, [key])

    return {
        items,
        height: offset - startOffset,
    }
}

export default function measure(sections) {
    let offset = 0

    return sections.map((node, index) => {
        if (index > 0) {
            offset += HEIGHT_SECTION_MARGIN
        }

        const sectionOffset = offset

        if (node.name) {
            offset += HEIGHT_SECTION_TITLE
        }

        const {items, height} = measureSection(node, offset)

        offset += height

        return {
            key: [node.key],
            node,
            offset: sectionOffset,
            bottom: offset + height,
            height,
            items,
        }
    })
}
