export const TOGGLE_KEYWORDS = [
    'yes',
    'no',
    'enable',
    'disable',
    'on',
    'off',
    'high',
    'low',
]
