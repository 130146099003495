import React from 'react'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import bar from 'permissions/panel/log/bar'
import {withPermissionVisibility} from 'containers/withPermission'
import NewSystemLogButton from 'components/SystemLogs/NewSystemLogButton'

export const LogBar = () => {
    return (
        <div className="configuration-header">
            <Bar orientation={RIGHT}>
                <NewSystemLogButton />
            </Bar>
        </div>
    )
}

export default withPermissionVisibility(bar)(LogBar)
