import get from 'lodash/get'

export const REFRESH_STATE = 'refreshState'
export const CONFIGURATION = 'configuration'
export const UPLOAD = 'upload'
export const DOWNLOAD = 'download'
export const CONFIGURATION_UPLOAD = `${CONFIGURATION}.${UPLOAD}`
export const CONFIGURATION_DOWNLOAD = `${CONFIGURATION}.${DOWNLOAD}`
export const REPORTS = 'reports'
export const APPLICATIONS = 'applications'
export const USER = 'user'
export const IS_LABELS_EDITABLE = 'isLabelsEditable'
export const INSTALLER = 'installer'
export const USER_APPLICATIONS = `${APPLICATIONS}.${USER}`
export const INSTALLER_APPLICATIONS = `${APPLICATIONS}.${INSTALLER}`
export const REMOTE_INSPECTION = 'remoteInspection'
export const EVENTS = 'events'

// Inside panel keys
export const VIRTUAL_KEYPAD = 'virtualKeypad'
export const FIRMWARE = 'firmware'
export const REMOTE_INSPECTIONS = 'remoteInspections'
export const LOGS = 'logs'
export const PROCESSES = 'processes'
export const LOCATIONS = 'locationsManagement'
export const CONFIGURATION_DOWNLOADABLE = 'isDownloadable'
export const CONFIGURATION_BASIC = 'isBasicAvailable'
export const CONFIGURATION_UPLOADABLE = 'isUploadable'
export const STATE = 'state'
export const STATE_CAN_GET = 'canGet'
export const STATE_CAN_SET = 'canSet'
export const STATE_CAN_REFRESH = 'canRefresh'
export const DEVICES = 'devices'
export const DIAGNOSTIC = 'diagnostic'
export const WALKTEST = 'walktest'
export const ENROLLABLE = 'isEnrollable'
export const APPS = 'apps'
export const CONFIGURATOR = 'configurator'
export const DISCOVERY = 'discovery'
export const FORCE_DISCOVERY = 'forceDiscovery'
export const RSSI = 'rssi'
export const RSSI_DEVICES = 'devices'
export const RSSI_GMS = 'gsm'
export const ACTIVATION = 'activation'
export const FAULTS = 'faults'
export const TYPE_2_ENCRYPTION = 'type_2_encryption'
export const TYPE_2_ENCRYPTION_BBA = 'isEnabledBBA'
export const TYPE_2_ENCRYPTION_GPRS = 'isEnabledGPRS'
export const VOD = 'vod'
export const ASSIGNABLE = 'assignable'
export const INSTALLER_CODE_LENGTHS = 'code_lengths'
export const USER_FEATURE_EDITABLE = 'editable'
export const USER_FEATURE_EDITABLE_CODE = 'code'
export const USER_FEATURE_EDITABLE_LABEL = 'label'
export const USER_FEATURE_EDITABLE_LAST_NAME = 'last_name'
export const USER_FEATURE_EDITABLE_EXPIRATION_DATE = 'expiration_date'
export const USER_FEATURE_PARTITIONS = 'partitions'
export const USER_FEATURE_CODE_LENGTH = 'codeLengths'
export const USER_FEATURE_DISABLE_USER = 'disable_user'
export const USER_FEATURE_VALIDATION_RULES = 'validation_rules'
export const PANEL_TEST = 'panelTest'
export const PANEL_ACTIONS = 'panel_actions'

export const isAvailable = (panelFeatures, featureKey) => {
    const feature = get(panelFeatures, featureKey, null)
    let result

    if (feature === null) {
        throw new Error(`Unknown feature key: ${featureKey}`)
    }

    if (feature instanceof Object) {
        result = Object.values(feature).some((feature) => feature === true)
    } else {
        result = feature
    }

    return result
}
