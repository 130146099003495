import {all, call, put, takeEvery} from 'redux-saga/effects'

import * as testsApi from 'api/panel/panelTest'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import watch from 'modules/higherOrder/watch'
import {POLL_PROCESSES} from 'configs/pollers'

import * as actions from './actions'
import {fetchTestsHistory} from '../one/actions'

export default function* () {
    yield all([
        takeEvery(actions.fetchSupportedTestsList, watch(fetch)),
        createPollerSaga(
            actions.startPollSupportedTestsList,
            actions.stopPollSupportedTestsList,
            POLL_PROCESSES,
            fetch,
            true
        ),
    ])
}

function* fetch({payload: {panelId}}) {
    const supportedTests = yield call(testsApi.getPanelSupportedTestsList, panelId)
    yield put(actions.updateSupportedTestsList({panelId, supportedTests}))
}

fetch.onError = function* (error) {
    yield put(fetchTestsHistory())
}
