import React from 'react'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    setQuery,
} from 'modules/basicConfiguration/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import list from 'permissions/basicConfiguration/list'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectBasicConfigurationList} from 'modules/basicConfiguration/list/selectors'

const SearchVisibility = withVisibility()(Search)

export default function RolesSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectBasicConfigurationList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new SuggestFilter('name', __('Name')),
        new SuggestFilter('vendor', __('Vendor')),
        new DateFilter('created', __('Created')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Basic configurations')}
        />
    )
}
