import React from 'react'

import {ReactComponent as IconPanels} from 'ipmp-react-ui/icons/menu/panels.svg'
import {ReactComponent as IconDashboard} from 'ipmp-react-ui/icons/menu/dashboard.svg'
import {ReactComponent as IconInspection} from 'ipmp-react-ui/icons/menu/remote-inspection.svg'
import {ReactComponent as IconEvents} from 'ipmp-react-ui/icons/menu/events.svg'
import {ReactComponent as IconReports} from 'ipmp-react-ui/icons/menu/reports.svg'
import {ReactComponent as IconAccounts} from 'ipmp-react-ui/icons/menu/accounts.svg'
import {ReactComponent as IconMyJobs} from 'ipmp-react-ui/icons/menu/my-jobs.svg'
import {ReactComponent as IconGroups} from 'ipmp-react-ui/icons/menu/groups.svg'
import {ReactComponent as IconProcesses} from 'ipmp-react-ui/icons/menu/processes.svg'
import {ReactComponent as IconRoles} from 'ipmp-react-ui/icons/menu/roles.svg'
import {ReactComponent as IconUsers} from 'ipmp-react-ui/icons/menu/users.svg'
import {ReactComponent as IconActionLog} from 'ipmp-react-ui/icons/menu/action-log.svg'
import {ReactComponent as IconCentralStations} from 'ipmp-react-ui/icons/menu/central-stations.svg'
import {ReactComponent as IconBasicConfiguration} from 'ipmp-react-ui/icons/menu/basic-configuration.svg'
import {ReactComponent as IconFirmware} from 'ipmp-react-ui/icons/menu/firmware.svg'

import NavLinkScopesGroup from 'components/Nav/NavLinkScopesGroup'
import NavLink from 'components/Nav/NavLink'
import NewEventsCounter from 'components/Nav/NewEventsCounter'
import path from 'utils/path'
import __ from 'utils/i18n'

export const MENU_ORDER_KEY = 'menuOrder'
export const MENU_ORDER_TYPE_DEFAULT = 'static'
export const MENU_ORDER_TYPE_MANUAL = 'manual'
export const MENU_ORDER_TYPE_AUTO = 'auto'
export const MENU_ORDER_UPDATE_DELAY = 600000
export const AVERAGE_MENU_HEIGHT_WITH_LABEL = 96
export const AVERAGE_MENU_HEIGHT_WITHOUT_LABEL = 81

export const countToLog = (count) => {
    return Math.round(Math.log10(count) * 1000000) / 1000000
}

export const logToCount = (log) => {
    return Math.round(Math.pow(10, log))
}

export const MENU_KEY_DASHBOARD = 'dashboard'
export const MENU_KEY_ACCOUNTS = 'accounts'
export const MENU_KEY_PANELS = 'panels'
export const MENU_KEY_BATCHES = 'batches'
export const MENU_KEY_EVENTS = 'events'
export const MENU_KEY_REMOTE_INSPECTIONS = 'remoteInspections'
export const MENU_KEY_REPORTS = 'reports'
export const MENU_KEY_FIRMWARE = 'firmware'
export const MENU_KEY_GROUPS = 'systemGroups'
export const MENU_KEY_USERS = 'systemUsers'
export const MENU_KEY_ROLES = 'systemRoles'
export const MENU_KEY_BASIC_CONFIGURATIONS = 'systemBasicConfigurations'
export const MENU_KEY_CENTRAL_STATIONS = 'systemCentralStations'
export const MENU_KEY_PROCESSES = 'systemProcesses'
export const MENU_KEY_ACTION_LOG = 'systemActionLog'

export const DEFAULT_MENU_ORDER = {
    [MENU_KEY_DASHBOARD]: 800,
    [MENU_KEY_ACCOUNTS]: 750,
    [MENU_KEY_PANELS]: 700,
    [MENU_KEY_BATCHES]: 650,
    [MENU_KEY_EVENTS]: 600,
    [MENU_KEY_REMOTE_INSPECTIONS]: 550,
    [MENU_KEY_REPORTS]: 500,
    [MENU_KEY_FIRMWARE]: 450,
    [MENU_KEY_GROUPS]: 400,
    [MENU_KEY_USERS]: 350,
    [MENU_KEY_ROLES]: 300,
    [MENU_KEY_BASIC_CONFIGURATIONS]: 250,
    [MENU_KEY_CENTRAL_STATIONS]: 200,
    [MENU_KEY_PROCESSES]: 150,
    [MENU_KEY_ACTION_LOG]: 100,
}
export const DEFAULT_MENU_ORDER_MANUAL = {
    [MENU_KEY_DASHBOARD]: 15,
    [MENU_KEY_ACCOUNTS]: 14,
    [MENU_KEY_PANELS]: 13,
    [MENU_KEY_BATCHES]: 12,
    [MENU_KEY_EVENTS]: 11,
    [MENU_KEY_REMOTE_INSPECTIONS]: 10,
    [MENU_KEY_REPORTS]: 9,
    [MENU_KEY_FIRMWARE]: 8,
    [MENU_KEY_GROUPS]: 7,
    [MENU_KEY_USERS]: 6,
    [MENU_KEY_ROLES]: 5,
    [MENU_KEY_BASIC_CONFIGURATIONS]: 4,
    [MENU_KEY_CENTRAL_STATIONS]: 3,
    [MENU_KEY_PROCESSES]: 2,
    [MENU_KEY_ACTION_LOG]: 1,
}
const menu = {
    [MENU_KEY_DASHBOARD]: {
        to: path('dashboard', {}),
        icon: IconDashboard,
        name: () => __('Dashboard'),
        isPermitted: ({dashboard}) => dashboard,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_ACCOUNTS]: {
        to: path('accounts', {}),
        icon: IconAccounts,
        name: () => __('Accounts'),
        isPermitted: ({accounts}) => accounts,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_PANELS]: {
        to: path('panels', {}),
        toStartWith: '/panel',
        icon: IconPanels,
        name: () => __('Equipment'),
        isPermitted: ({panels}) => panels,
        scope: ({panels}) => panels,
        Component: (props) => <NavLinkScopesGroup link="panels" {...props} />,
    },
    [MENU_KEY_BATCHES]: {
        to: path('batches', {}),
        icon: IconMyJobs,
        name: () => __('My Jobs'),
        isPermitted: () => true,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_EVENTS]: {
        to: path('events', {}),
        icon: IconEvents,
        name: () => __('Events'),
        isPermitted: ({events}) => events,
        scope: ({events}) => events,
        Component: (props) => (
            <NavLinkScopesGroup link="events" Coin={NewEventsCounter} {...props} />
        ),
    },
    [MENU_KEY_REMOTE_INSPECTIONS]: {
        to: path('remoteInspections', {}),
        icon: IconInspection,
        name: () => __('Remote Inspection'),
        isPermitted: ({remoteInspections}) => remoteInspections,
        scope: ({remoteInspections}) => remoteInspections,
        Component: (props) => <NavLinkScopesGroup link="remoteInspections" {...props} />,
    },
    [MENU_KEY_REPORTS]: {
        to: path('reports', {}),
        icon: IconReports,
        name: () => __('Reports'),
        isPermitted: ({reports}) => reports,
        scope: ({reports}) => reports,
        Component: (props) => <NavLinkScopesGroup link="reports" {...props} />,
    },
    [MENU_KEY_FIRMWARE]: {
        to: path('firmware', {}),
        icon: IconFirmware,
        name: () => __('Firmware'),
        isPermitted: ({firmware}) => firmware,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_GROUPS]: {
        to: path('groups', {}),
        icon: IconGroups,
        name: () => __('Groups'),
        isPermitted: ({groups}) => groups,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_USERS]: {
        to: path('users', {}),
        icon: IconUsers,
        name: () => __('Users'),
        isPermitted: ({users}) => users,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_ROLES]: {
        to: path('roles', {}),
        icon: IconRoles,
        name: () => __('Roles'),
        isPermitted: ({roles}) => roles,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_BASIC_CONFIGURATIONS]: {
        to: path('basicConfigurations', {}),
        icon: IconBasicConfiguration,
        name: () => __('Basic Configurations'),
        isPermitted: ({basicConfiguration}) => basicConfiguration,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_CENTRAL_STATIONS]: {
        to: path('centralStations', {}),
        icon: IconCentralStations,
        name: () => __('Central Stations'),
        isPermitted: ({centralStations}) => centralStations,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_PROCESSES]: {
        to: path('processes', {}),
        icon: IconProcesses,
        name: () => __('Processes'),
        isPermitted: ({processes}) => processes,
        Component: (props) => <NavLink {...props} />,
    },
    [MENU_KEY_ACTION_LOG]: {
        to: path('actionLog', {}),
        icon: IconActionLog,
        name: () => __('Action Log'),
        isPermitted: ({actionLog}) => actionLog,
        Component: (props) => <NavLink {...props} />,
    },
}

export default menu

export const getSortedMenu = (items = null) => {
    if (items) {
        return Object.values(items).reduce((sorted, item, currentIndex) => {
            sorted[item] = menu[item]
            sorted[item].order = items.length - currentIndex
            return sorted
        }, {})
    }
    return menu
}

const sortMenuItems = (menuItems) =>
    menuItems.sort((a, b) => b.props.order - a.props.order)

export const setMenuOrdered = (menuOrderType, menuItems, menuOrder, menuOrderManual) => {
    switch (menuOrderType) {
        case MENU_ORDER_TYPE_AUTO:
            return sortMenuItems(
                menuItems.map((item) =>
                    React.cloneElement(item, {
                        order: menuOrder[item.key],
                    })
                )
            )
        case MENU_ORDER_TYPE_MANUAL:
            return sortMenuItems(
                menuItems.map((item) =>
                    React.cloneElement(item, {
                        order: menuOrderManual[item.key],
                    })
                )
            )
        default:
            return sortMenuItems(menuItems)
    }
}
