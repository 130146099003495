import {call, put, select} from 'redux-saga/effects'
import * as api from 'api/had'
import * as actions from '../actions'
import {getPanelId} from 'utils/panelIdMatchSelector'

export default function* pollData() {
    const panelId = yield select(getPanelId)

    const hadList = yield call(api.getHadList, panelId)

    yield put(actions.update({hadList}))
}

pollData.onError = function* (error) {
    yield put(actions.receive(error))
}

export function* pollDataSwitchable() {
    const panelId = yield select(getPanelId)

    const {isSwitchable, timeSlot, isTimeSlotEnabled} = yield call(
        api.isPanelsHadSwitchable,
        panelId
    )

    yield put(actions.updateSwitchable({isSwitchable, timeSlot, isTimeSlotEnabled}))
}

pollDataSwitchable.onError = function* (error) {
    yield put(actions.receiveSwitchable(error))
}
