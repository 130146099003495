import React, {useEffect} from 'react'

import Page from 'ipmp-react-ui/Page'

import {useActions} from 'modules/higherOrder/useActions'
import CentralStationsTable from './CentralStationsTable'
import CentralStationsGridBar from './CentralStationsGridBar'
import {startPoll, stopPoll} from 'modules/centralStations/list/actions'

export default function CentralStationsPage() {
    const {startPoll: startPollAction, stopPoll: stopPollAction} = useActions({
        startPoll,
        stopPoll,
    })

    useEffect(() => {
        startPollAction()

        return stopPollAction
    }, [])

    return <Page Bar={CentralStationsGridBar} Content={CentralStationsTable} />
}
