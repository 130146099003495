import React from 'react'
import {useSelector} from 'react-redux'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'
import {ReactComponent as IconPrev} from 'ipmp-react-ui/icons/arrow-prev.svg'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import {LOGIN_SCREEN} from 'constants/loginScreen'
import {remindPassword} from 'modules/forms/handlers'
import {useActions} from 'modules/higherOrder/useActions'
import {setLoginScreenForm as setLoginScreenFormAction} from 'modules/auth/sign/actions'

const rules = {
    email: {
        presence: true,
        emailRFC: true,
    },
}

export default function ForgotPasswordForm() {
    const sign = useSelector(({auth: {sign}}) => ({...sign}))

    const {
        form: {errors},
        isLoading,
        handle,
    } = useForm(remindPassword, sign)

    const {setLoginScreenFormAction: setLoginScreenForm} = useActions({
        setLoginScreenFormAction,
        remindPassword,
    })

    const handleSubmit = ({email}) => {
        handle({email})
    }

    const handleBack = () => {
        setLoginScreenForm(LOGIN_SCREEN)
    }

    return (
        <Form
            className="card login-form login-form-forgot-form"
            isLoading={isLoading}
            rules={rules}
            errors={errors}
            onSubmit={handleSubmit}
        >
            <CardHeader className={'login-form-header'}>
                {__('Forgot Password?')}
            </CardHeader>

            <CardContent>
                <span className={'login-form-content-label'}>
                    {__('Enter your details to receive a reset link')}
                </span>
                <Input
                    name="email"
                    label={__('Email')}
                    autoComplete={'off'}
                    dataTestId="forgot-password-email-input"
                />
            </CardContent>

            <CardActions>
                <Button
                    primary
                    className={'login-form-button'}
                    disabled={isLoading}
                    type="submit"
                >
                    {__('Remind')}
                </Button>
                <Button borderless className={'login-form-back'} onClick={handleBack}>
                    <IconPrev />
                    {__('Back to Sign In')}
                </Button>
            </CardActions>
        </Form>
    )
}
