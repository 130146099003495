import {bindActionCreators} from 'redux'
import {useDispatch, useSelector} from 'react-redux'

import {find, isNull, reduce} from 'lodash-es'

import {ROLE_MASTER_USER} from 'constants/accountRoles'
import {LOGS} from 'constants/features'
import {isActivationOk, isContentHidden} from 'constants/panelActivationStatus'
import {filterPanelTabsWithFeature} from 'containers/withFeature'
import {forgetPanel} from 'modules/panels/recent/actions'
import {
    selectIsPanelActivated,
    selectPanelInteractiveUsers,
} from 'modules/panelInteractiveUsers/store/selectors'
import {getItemsByKeys, getMenuItems, PANEL_TAB} from 'pages/Panel/PanelPageRouting'
import {SYSTEM_LOG_TYPE} from 'constants/logTypes'

export default function usePanelPage({
    allowedItems,
    panel,
    isLoading,
    isFeatureLoading,
    panelId,
    features,
    isPanelUsersPagePermitted,
    isUnitPermitted,
    error,
}) {
    const state = useSelector((state) => state)
    const dispatch = useDispatch()
    const isActivated = selectIsPanelActivated({state, panelId})
    let panelInteractiveUsers, isPanelInteractiveUsers, isLoadingBeforeFetch
    const isPanelFeatures = Boolean(features)

    if (isPanelUsersPagePermitted) {
        panelInteractiveUsers = selectPanelInteractiveUsers(state, {panelId})
        isPanelInteractiveUsers = isNull(panelInteractiveUsers)
        isLoadingBeforeFetch = !panel || !isPanelFeatures || isPanelInteractiveUsers
    } else {
        if (isUnitPermitted) {
            isLoadingBeforeFetch = !panel || !isPanelFeatures
        }
    }

    if (isLoadingBeforeFetch) {
        return {
            isLoading: !error ? isLoadingBeforeFetch : false,
            isPanelFeatures,
        }
    }

    if (!panel?.activationStatus && isUnitPermitted) {
        allowedItems = {}
    }

    let defaultTab = null

    if (panel?.activationStatus && isContentHidden(panel?.activationStatus)) {
        const {isEnabled, types} = features[LOGS]
        const isSysLogsAvailable = isEnabled && (types || []).includes(SYSTEM_LOG_TYPE)

        allowedItems = {
            [PANEL_TAB.INFO]: allowedItems.info,
            ...(isSysLogsAvailable ? {[PANEL_TAB.LOG]: allowedItems.log} : {}),
        }

        defaultTab = PANEL_TAB.INFO
    }

    const vendorItems = getMenuItems(panel?.vendor)

    const mergedItems = reduce(
        allowedItems,
        (res, isAllowed, item) => {
            if (isAllowed && vendorItems.has(item)) {
                res.push(item)
            }
            return res
        },
        []
    )

    let items
    if (isUnitPermitted) {
        items = filterPanelTabsWithFeature(
            state,
            getItemsByKeys(mergedItems, panel?.isTimeSlotAutomationDevicesEnabled),
            panelId
        )
    } else {
        items = isActivated
            ? getItemsByKeys(mergedItems, panel?.isTimeSlotAutomationDevicesEnabled)
            : {}
    }

    const events = bindActionCreators(
        {
            forgetPanel,
        },
        dispatch
    )

    const masterUserToRemember = find(panelInteractiveUsers, {
        role: ROLE_MASTER_USER,
    })

    return {
        items,
        defaultTab,
        key: panelId,
        isLoading: isLoading || isFeatureLoading,
        isPanelFeatures,
        masterUserToRemember,
        forgetPanel: () => events.forgetPanel({panelId}),
        isContentHidden: isContentHidden(panel?.activationStatus),
        isActivationOk: isActivationOk(panel?.activationStatus),
    }
}
