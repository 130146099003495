import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import reduce from 'lodash-es/reduce'

import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import list from 'permissions/panel/devices/list'

import Diagnostic from './List/Diagnostic'
import Walktest from './List/Walktest'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {DevicesContext} from './DevicesContext'
import DevicesBlindModal from './DevicesBlindModal'

import {ZONE_TYPE_NON_ALARM, ZONE_TYPE_NULL_PGM} from 'constants/zoneType'
import {DEVICE_CATEGORY_ZONES, DEVICE_CATEGORY_OUTPUTS} from 'constants/deviceCategory'
import useDevices from 'hooks/pages/useDevices'
import {MOTION_TROUBLE} from 'constants/troubleType'

export class Devices extends Component {
    static propTypes = {
        devices: PropTypes.object.isRequired,
        showWalktest: PropTypes.bool.isRequired,
        panel: PropTypes.shape({
            id: PropTypes.number,
            isNeo: PropTypes.bool,
        }).isRequired,
        filterTroubles: PropTypes.bool,
        hideNullZones: PropTypes.bool,
    }

    state = {
        isPanelSelected: false,
        isDeviceInfoOpened: false,
        selectedDeviceId: null,
        startWithVideoOnDemandTab: false,
        startWithMeteoTab: false,
    }

    static getDerivedStateFromProps({byId}, {selectedDeviceId, isPanelSelected}) {
        if (
            !isPanelSelected &&
            (!byId || !byId[selectedDeviceId] || byId[selectedDeviceId].removing)
        ) {
            return {
                isDeviceInfoOpened: false,
                selectedDeviceId: null,
                startWithVideoOnDemandTab: false,
                startWithMeteoTab: false,
            }
        }
        return null
    }

    handleSelect = (device, startTab = {}) => {
        if (!device || device.enrolling || device.removing) {
            return this.setState({selectedDeviceId: null})
        }
        // there is no way to see Control Panel on sidebar so far
        if (device.deviceType === DEVICE_TYPE_CONTROL_PANEL) {
            return this.setState({selectedDeviceId: null})
        }

        const {startWithVideoOnDemandTab, startWithMeteoTab} = startTab

        return this.setState({
            isDeviceInfoOpened: true,
            selectedDeviceId: device.id,
            startWithVideoOnDemandTab: !!startWithVideoOnDemandTab,
            startWithMeteoTab: !!startWithMeteoTab,
            isPanelSelected: false,
        })
    }

    onSelectPanel = () => {
        return this.setState({
            isDeviceInfoOpened: true,
            selectedDeviceId: null,
            isPanelSelected: true,
        })
    }

    closeDeviceInfo = () =>
        this.setState({
            isDeviceInfoOpened: false,
            selectedDeviceId: null,
            startWithVideoOnDemandTab: false,
            startWithMeteoTab: false,
            isPanelSelected: false,
        })

    getDevices() {
        const {filterTroubles, hideNullZones, devices} = this.props
        if (!filterTroubles && !hideNullZones) {
            return devices
        }

        return reduce(
            devices,
            (acc, categoryDevices, category) => {
                if (filterTroubles) {
                    categoryDevices = categoryDevices.filter(
                        (device) =>
                            device.hasTroubles &&
                            device.warnings.find(
                                (warning) => warning.type !== MOTION_TROUBLE
                            )
                    )
                }
                if (hideNullZones) {
                    if (category == DEVICE_CATEGORY_ZONES) {
                        categoryDevices = categoryDevices.filter(
                            (device) => device.zoneType !== ZONE_TYPE_NON_ALARM
                        )
                    }
                    if (category == DEVICE_CATEGORY_OUTPUTS) {
                        categoryDevices = categoryDevices.filter(
                            (device) => device.zoneType != ZONE_TYPE_NULL_PGM
                        )
                    }
                }

                if (categoryDevices.length === 0) {
                    return acc
                }

                return {
                    ...acc,
                    [category]: categoryDevices,
                }
            },
            {}
        )
    }

    onSelectDevice = (selectedDeviceId) => {
        this.setState({
            selectedDeviceId: selectedDeviceId,
            isPanelSelected: false,
        })
    }

    render() {
        const {showWalktest} = this.props
        const {
            isPanelSelected,
            selectedDeviceId,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
            isDeviceInfoOpened,
        } = this.state
        if (showWalktest) {
            return <Walktest />
        }
        const devices = this.getDevices()
        return (
            <>
                <DevicesContext.Provider
                    value={{
                        handleSelect: this.handleSelect,
                    }}
                >
                    <Diagnostic
                        {...{
                            devices: devices,
                            selectedDevice: selectedDeviceId,
                            isPanelSelected,
                            onSelectPanel: this.onSelectPanel,
                        }}
                    />
                </DevicesContext.Provider>

                <DevicesBlindModal
                    isDeviceInfoOpened={isDeviceInfoOpened}
                    selectedDeviceId={selectedDeviceId}
                    startWithVideoOnDemandTab={startWithVideoOnDemandTab}
                    startWithMeteoTab={startWithMeteoTab}
                    onClose={this.closeDeviceInfo}
                    onSelectDevice={this.onSelectDevice}
                    isPanelSelected={isPanelSelected}
                    onSelectPanel={this.onSelectPanel}
                />
            </>
        )
    }
}

const ConnectedDevices = compose(
    withPermissionRejection(list),
    withLoader(({fetch}) => fetch())
)(Devices)

export default function DevicesContent(props) {
    const devicesProps = useDevices()

    const connectedProps = {...props, ...devicesProps}

    return <ConnectedDevices {...connectedProps} />
}
