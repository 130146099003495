import React from 'react'

import Pager from 'ipmp-react-ui/Pager'

import page from 'permissions/firmware/selection'
import usePermission from 'hooks/usePermission'
import {setStart} from 'modules/firmware/patchTagUpdate/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import usePatchTagUpdatePanels from 'hooks/pages/usePatchTagUpdatePanels'

const PagerVisibility = withVisibility()(Pager)

export default function PatchTagUpdatePager() {
    const {isPermitted} = usePermission(page)
    const {total, start, perPage} = usePatchTagUpdatePanels()

    const actions = useActions({
        setStart,
    })

    return (
        <PagerVisibility
            total={total}
            start={start}
            perPage={perPage}
            isVisible={isPermitted}
            onPageChange={actions.setStart}
            onPerPageChange={actions.setStart}
        />
    )
}
