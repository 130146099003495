import React, {Component} from 'react'
import {useSelector} from 'react-redux'
import set from 'lodash-es/set'
import mapValues from 'lodash-es/mapValues'
import cloneDeep from 'lodash-es/cloneDeep'

import Error from 'ipmp-react-ui/Error'
import Input from 'ipmp-react-ui/Input'
import ModalCardForm from 'ui/ModalCardForm'
import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import SlideDown from 'ipmp-react-ui/SlideDown'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import PermissionRow from './PermissionRow'
import usePermission from 'hooks/usePermission'
import PermissionGroupRow from './PermissionGroupRow'
import {fetch} from 'modules/roles/permissions/actions'
import withModalLoader from 'containers/withModalLoader'
import {editPermissions} from 'permissions/roles/actions'
import {useActions} from 'modules/higherOrder/useActions'
import {editRolePermissions} from 'modules/forms/handlers'
import {
    isReadonlyPermissionByRoleType,
    ROLE_PERMISSION_MMI,
} from 'constants/rolePermissions'

/**
 * permissions values
 *    true — granted
 *    false - revoked
 *    null — inherit from parent permission
 */
class EditRolePermissionsBase extends Component {
    state = {
        prefix: '',
        opened: {},
    }

    constructor(props, context) {
        super(props, context)

        this.state.tree = Object.keys(props.data).reduce((acc, key) => {
            return set(acc, key, props.data[key].title)
        }, {})

        this.state.permissions = cloneDeep(props.data)
    }

    handle = () => {
        const {roleId, handle} = this.props
        handle(roleId, this.preparePermissions())
    }

    preparePermissions = () => {
        return Object.keys(this.state.permissions).reduce((acc, key) => {
            acc[key] = this.state.permissions[key].value
            return acc
        }, {})
    }

    handleSearchChange = (e) => {
        this.setState({prefix: e.target.value})
    }

    handleToggle = (name, isActive) => {
        this.setState(({opened}) => {
            opened = {...opened}

            Object.keys(opened).forEach((key) => {
                if (key.substr(0, name.length) === name) {
                    delete opened[key]
                }
            })

            opened[name] = isActive

            return {opened}
        })
    }

    getFilteredTree(data = this.state.tree) {
        const prefix = this.state.prefix.trim().toLowerCase()

        if (!prefix) {
            return data
        }

        return Object.keys(data).reduce((acc, key) => {
            const value = data[key]

            if (key.substr(0, prefix.length).toLowerCase() === prefix) {
                acc[key] = value
            } else if (value instanceof Object) {
                const filtered = this.getFilteredTree(value)

                if (Object.keys(filtered).length > 0) {
                    acc[key] = filtered
                }
            }

            return acc
        }, {})
    }

    setPermission = (name, value) => {
        const {roleType} = this.props

        this.setState(({permissions}) => {
            permissions = mapValues(permissions, (val, key) => {
                if (
                    !isReadonlyPermissionByRoleType(key, roleType) &&
                    key.substring(0, name.length + 1) === name + '.'
                ) {
                    // drop all sub-rules because grant policy changed
                    return {...val, value: null}
                }

                return val
            })

            permissions[name].value = value

            return {permissions}
        })
    }

    hasAllowed(key, data) {
        const {value} = this.state.permissions[key] || {}

        return (
            value ||
            Object.keys(typeof data === 'object' ? data : {}).reduce(
                (acc, subKey) => acc || this.hasAllowed(`${key}.${subKey}`, data[subKey]),
                false
            )
        )
    }

    hasExceptions(key, data, startValue) {
        const {value} = this.state.permissions[key] || {}

        if (typeof startValue === 'undefined') {
            startValue = value
        }

        if (startValue !== value && value !== null) {
            return true
        }

        return Object.keys(typeof data === 'object' ? data : {}).reduce(
            (acc, subKey) =>
                acc || this.hasExceptions(`${key}.${subKey}`, data[subKey], startValue),
            false
        )
    }

    renderFields(data, keys = [], isAllowByDefault = false) {
        const fields = Object.keys(data).sort((key1, key2) => {
            if (key1 === ROLE_PERMISSION_MMI) {
                return 1
            } else if (key2 === ROLE_PERMISSION_MMI) {
                return -1
            }

            const w1 = typeof data[key1] === 'object' ? 1 : 0
            const w2 = typeof data[key2] === 'object' ? 1 : 0

            return w1 - w2
        })

        return fields.map((key) => {
            const subKeys = keys.concat([key])
            const name = subKeys.join('.')
            const {title, value, tooltip} = this.state.permissions[name]

            const isAllowed = value === true
            const isDenied = value === false

            const props = {
                title,
                name,
                tooltip,
                isAllowByDefault,
                isAllowed,
                isDenied,
                hasAllowed: this.hasAllowed(name, data[key]),
                hasExceptions: this.hasExceptions(name, data[key]),
                onChange: this.setPermission,
                isEditable: this.props.isEditable,
                roleType: this.props.roleType,
            }

            if (typeof data[key] === 'object') {
                const isActive = this.state.opened[name]

                return (
                    <div key={key} className="permissions-group">
                        <PermissionGroupRow
                            isActive={isActive}
                            onToggle={this.handleToggle}
                            {...props}
                        />

                        <SlideDown className="permissions-sublist">
                            {isActive && (
                                <div>
                                    {this.renderFields(
                                        data[key],
                                        subKeys,
                                        isAllowed || isAllowByDefault
                                    )}
                                </div>
                            )}
                        </SlideDown>
                    </div>
                )
            }

            return <PermissionRow key={key} {...props} />
        })
    }

    renderHeader() {
        return (
            <Bar>
                {__('Edit role permissions')}

                <BarSpace />

                <div>
                    <Input
                        placeholder={__('Search')}
                        onChange={this.handleSearchChange}
                    />
                </div>
            </Bar>
        )
    }

    render() {
        const {isEditable, isLoadingForm, hide, form} = this.props

        const tree = this.getFilteredTree()

        return (
            <ModalCardForm
                wide
                {...form}
                className="permissions"
                isLoading={isLoadingForm}
                editable={isEditable}
                hide={hide}
                onSubmit={this.handle}
                header={this.renderHeader()}
            >
                <div className="form-scroll">
                    {Object.keys(tree).length ? (
                        this.renderFields(tree)
                    ) : (
                        <Error title={__('Permissions not found')} />
                    )}
                </div>
            </ModalCardForm>
        )
    }
}

const EditRolePermissionsLoader = withModalLoader(({fetcher}) => fetcher())(
    EditRolePermissionsBase
)

export default function EditRolePermissions({hide, roleId}) {
    const {isPermitted} = usePermission(editPermissions)

    const {fetch: fetcher} = useActions({
        fetch: () => fetch(roleId),
    })

    const {form, handle, isLoading} = useForm(editRolePermissions)
    const data = useSelector(({roles}) => roles.permissions[roleId])
    const {type} = useSelector(({roles}) => roles.store.byIds[roleId])

    return (
        <EditRolePermissionsLoader
            form={form}
            hide={hide}
            roleId={roleId}
            handle={handle}
            data={data?.data}
            fetcher={fetcher}
            isLoading={!data}
            roleType={type}
            isLoadingForm={isLoading}
            isEditable={isPermitted}
        />
    )
}
