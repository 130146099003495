import React from 'react'
import classNames from 'classnames'

import {ReactComponent as IconNext} from 'ipmp-react-ui/icons/arrow-next.svg'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/close-circle.svg'
import {ReactComponent as IconPass} from 'ipmp-react-ui/icons/circle-check.svg'
import {ReactComponent as IconStarted} from 'ipmp-react-ui/icons/started.svg'
import Tooltip from 'ipmp-react-ui/Tooltip'

import {
    DEFAULT_ACTIVE_SYSTEM_TEST_GROUP,
    PANEL_TEST_STATUS_FAILED,
    PANEL_TEST_STATUS_RUNNING,
    PANEL_TEST_STATUS_START,
} from 'constants/panelTests'

import RunGroupSystemTestsButton from './Buttons/RunGroupSystemTestsButton'

const PanelTestGroups = ({
    activeGroup,
    handleTabClick,
    groups,
    supportedTests,
    serial,
    startedGroup,
    setStartedGroup,
    testsData,
}) => {
    return groups.map((group) => {
        let groupTests = supportedTests.filter((test) => test.groupName === group)

        if (group === DEFAULT_ACTIVE_SYSTEM_TEST_GROUP) {
            groupTests = supportedTests.filter((test) => test.groupName === null)
        }

        const currentStatuses =
            groupTests &&
            groupTests.reduce((acc, groupedTest) => {
                const testsForGroup = testsData.filter((test) => {
                    if (groupedTest.testDeviceId) {
                        return (
                            test?.testType === groupedTest?.testType &&
                            test.testDeviceId === groupedTest.testDeviceId
                        )
                    } else {
                        return test?.testType === groupedTest?.testType
                    }
                })

                return [...acc, ...testsForGroup.map((test) => test.currentStatus)]
            }, [])

        const failed = currentStatuses.filter(
            (status) => status === PANEL_TEST_STATUS_FAILED
        ).length
        const passed = currentStatuses.filter(
            (status) => status === PANEL_TEST_STATUS_START
        ).length
        const started = currentStatuses.filter(
            (status) => status === PANEL_TEST_STATUS_RUNNING
        ).length

        return (
            <div
                className={classNames('panel-system-tests-group-card', {
                    'active-group': activeGroup === group,
                })}
                onClick={() => handleTabClick(group)}
                key={group}
            >
                <div className="panel-system-tests-group-info">
                    {group}
                    <div className="panel-system-tests-group-icons">
                        {started > 0 && (
                            <div className="panel-system-tests-group-icon-wrapper panel-system-tests-group-icon-started">
                                <Tooltip tooltip="Tests started">
                                    <IconStarted />
                                </Tooltip>
                                {started}
                            </div>
                        )}
                        {started > 0 && passed > 0 && (
                            <div className="panel-system-tests-group-icon-delimiter" />
                        )}
                        {passed > 0 && (
                            <div className="panel-system-tests-group-icon-wrapper panel-system-tests-group-icon-passed">
                                <Tooltip tooltip="Tests passed">
                                    <IconPass />
                                </Tooltip>
                                {passed}
                            </div>
                        )}
                        {failed > 0 && passed > 0 && (
                            <div className="panel-system-tests-group-icon-delimiter" />
                        )}
                        {failed > 0 && (
                            <div className=" panel-system-tests-group-icon-wrapper panel-system-tests-group-icon-failed">
                                <Tooltip tooltip="Tests failed">
                                    <IconCross />
                                </Tooltip>
                                {failed}
                            </div>
                        )}
                    </div>
                </div>
                <div className="panel-system-tests-group-button">
                    <RunGroupSystemTestsButton
                        tests={groupTests}
                        panelSerial={serial}
                        groupName={group}
                        startedGroup={startedGroup}
                        setStartedGroup={setStartedGroup}
                    />
                    <div className="panel-system-tests-group-button-arrow">
                        <IconNext />
                    </div>
                </div>
            </div>
        )
    })
}

export default PanelTestGroups
