import React, {Component, useEffect} from 'react'
import {useSelector} from 'react-redux'
import {compose} from 'redux'

import Fab from 'ipmp-react-ui/Fab'
import Hotkey from 'ipmp-react-ui/Hotkey'
import Comment from 'ipmp-react-ui/Comment'
import {MenuItem} from 'ipmp-react-ui/Menu'
import Textarea from 'ipmp-react-ui/Textarea'
import {humanDate} from 'ipmp-react-ui/humanTime'
import CardBlindModal from 'ipmp-react-ui/CardBlindModal'
import {ReactComponent as IconSend} from 'ipmp-react-ui/icons/send.svg'

import {__} from 'utils/i18n'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import {useActions} from 'modules/higherOrder/useActions'
import * as actions from 'modules/panels/remarks/actions'
import {withPermissionVisibility} from 'containers/withPermission'
import remarksList, {
    add,
    remove as removePermission,
} from 'permissions/panel/info/remarks'

const REMARKS_PER_PAGE = 25

export class PanelRemarks extends Component {
    state = {value: '', size: REMARKS_PER_PAGE}

    handleRemove = (key) => {
        const {remove} = this.props
        remove && remove(key)
    }

    createRemark = () => {
        const {create} = this.props
        const value = this.state.value.trim()

        value && create && create(value)
        this.setState({value: ''})
    }

    handleChange = (e) => {
        this.setState({value: e.target.value}, this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState) {
        const {remarks} = prevProps

        if (remarks && this.props.remarks.length > remarks.length) {
            this.scrollToBottom()
        }
    }

    scrollToBottom() {
        if (this.scrollElement) {
            this.scrollElement.scrollTop = this.scrollElement.scrollHeight
        }
    }

    handleRef = (el) => {
        this.scrollElement = el
        this.scrollToBottom()
    }

    renderRemark = (remark) => (
        <Comment {...remark} isMine={remark.user.id === this.props.user.id}>
            {this.props.isRemoveAllowed && (
                <MenuItem onClick={() => this.handleRemove(remark.key)}>
                    {__('Remove')}
                </MenuItem>
            )}
        </Comment>
    )

    renderRemarks() {
        const {remarks} = this.props
        const {size} = this.state

        if (!remarks) {
            return null
        }

        const begin = Math.max(0, remarks.length - size)

        const days = remarks.slice(begin).reduce((acc, remark) => {
            const date = humanDate(remark.time)

            if (!acc[date]) {
                acc[date] = []
            }
            acc[date].push(remark)
            return acc
        }, {})

        const list = Object.keys(days).reduce((acc, day) => {
            return [
                ...acc,
                <div className="hint hint--gray panelInfo-remarks-title" key={day}>
                    {day}
                </div>,
                days[day].map(this.renderRemark),
            ]
        }, [])

        return (
            <div className="scroll" ref={this.handleRef}>
                {list}
            </div>
        )
    }

    render() {
        const {onClose, opened, isCreateAllowed} = this.props

        return (
            <CardBlindModal
                opened={opened}
                onClose={onClose}
                title={__('Panel Remarks')}
                className="panelRemarks"
            >
                {this.renderRemarks()}

                {isCreateAllowed && (
                    <div className="panelInfo-addRemark">
                        <Textarea
                            autoFocus
                            onChange={this.handleChange}
                            value={this.state.value}
                            placeholder={__('Add note')}
                            maxLength={1000}
                        />
                        <Hotkey
                            shortcut="⌘+enter"
                            action={this.createRemark}
                            scope="input"
                        />
                        <Hotkey
                            shortcut="ctrl+enter"
                            action={this.createRemark}
                            scope="input"
                        />
                        <Fab mini primary Icon={IconSend} onClick={this.createRemark} />
                    </div>
                )}
            </CardBlindModal>
        )
    }
}

const ConnectedPanelRemarks = compose(
    withPermissionVisibility(remarksList),
    withLoader(({fetch}) => fetch())
)(PanelRemarks)

export default function PanelRemarksMain({panelId, opened, onClose}) {
    const {isCreateAllowed, isRemoveAllowed, isRemarksAllowed} = usePermission({
        isCreateAllowed: add,
        isRemoveAllowed: removePermission,
        isRemarksAllowed: remarksList,
    })

    const {user, isLoading, remarks} = useSelector(
        ({
            panels,
            auth: {
                sign: {user},
            },
        }) => {
            const remarks = panels.remarks[panelId]

            return {
                user,
                isLoading: !remarks,
                remarks: remarks && remarks.page.map((id) => remarks.rows[id]),
            }
        }
    )

    const {fetch, create, remove, startPoll, stopPoll} = useActions({
        fetch: () => actions.fetch(panelId),
        create: (text) => actions.create(text, panelId),
        remove: (key) => actions.remove(key, panelId),
        startPoll: () => actions.startPoll(panelId),
        stopPoll: () => actions.stopPoll(),
    })

    useEffect(() => {
        isRemarksAllowed && startPoll()

        return stopPoll
    }, [])

    return (
        <ConnectedPanelRemarks
            user={user}
            fetch={fetch}
            create={create}
            remove={remove}
            opened={opened}
            remarks={remarks}
            panelId={panelId}
            onClose={onClose}
            isLoading={isLoading}
            isCreateAllowed={isCreateAllowed}
            isRemoveAllowed={isRemoveAllowed}
        />
    )
}
