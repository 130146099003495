import React from 'react'
import PropTypes from 'prop-types'

import Tabs, {Tab} from 'ipmp-react-ui/Tabs'

import __ from 'utils/i18n'
import {isAllowManipulateUser} from 'constants/accountRoles'
import usePermission from 'hooks/usePermission'
import {panels} from 'permissions/interactiveUsers/list'

import PanelUserTable from './PanelUserTable'
import Panels from './Panels'
import InteractiveUserNoAssigned from './InteractiveUserNoAssigned'

export const PANEL_USER_TAB_ALIAS = 'panelUser'
export const CUSTOMER_TAB_ALIAS = 'customer'

export default function _Tabs({user, setFooterContent, addPanel, closeBlind}) {
    const {role, interactiveUserId, email} = user

    const {isAllowedInteractiveUsers} = usePermission({
        isAllowedInteractiveUsers: panels,
    })

    return (
        <Tabs layoutClassName="panelInteractiveUsers-blindModal-tabs">
            <Tab name={__('Panel user info')} alias={PANEL_USER_TAB_ALIAS}>
                <PanelUserTable user={user} setFooterContent={setFooterContent} />
            </Tab>

            {isAllowedInteractiveUsers && isAllowManipulateUser(role) && (
                <Tab name={__('Customer')} alias={CUSTOMER_TAB_ALIAS}>
                    {interactiveUserId ? (
                        <Panels
                            user={{id: +interactiveUserId, email}}
                            addPanel={addPanel}
                            setFooterContent={setFooterContent}
                            closeBlind={closeBlind}
                        />
                    ) : (
                        <InteractiveUserNoAssigned
                            user={user}
                            setFooterContent={setFooterContent}
                        />
                    )}
                </Tab>
            )}
        </Tabs>
    )
}

_Tabs.propTypes = {
    user: PropTypes.shape({
        role: PropTypes.string.isRequired,
        interactiveUserId: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    setFooterContent: PropTypes.func.isRequired,
    addPanel: PropTypes.func.isRequired,
    closeBlind: PropTypes.func.isRequired,
}
