import {createAction} from 'redux-actions'

import createListActions from 'modules/higherOrder/createListActions'

export const fetch = createAction('FIRMWARE/PATCH_TAG_UPDATE/LIST/FETCH')

export const {
    init,
    reset,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
    purgeList,
} = createListActions('FIRMWARE/PATCH_TAG_UPDATE/LIST')

export const upgrade = createAction('FIRMWARE/PATCH_TAG_UPDATE/UPGRADE')
