import {all, call, put, takeEvery, select} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import generateBatch from 'modules/batches/manager/generateBatch'
import * as actions from './actions'
import * as list from 'modules/upgradeStatus/list/actions'
import * as api from 'api/runners'

export default function* () {
    yield all([takeEvery(actions.retry, watchRetry)])
}

function* watchRetry({payload: runnerIds}) {
    const runnersByIds = yield select((state) => state.runners.store.byIds)

    const panelIdsByTypes = runnerIds.reduce((acc, id) => {
        const runner = runnersByIds[id]

        if (!acc[runner.type]) {
            acc[runner.type] = []
        }

        acc[runner.type].push(runner.panelId)

        return acc
    }, {})

    for (const [type, panelIds] of Object.entries(panelIdsByTypes)) {
        const {batchId, removeBatch} = yield generateBatch(type, panelIds)

        try {
            yield call(api.retry, {runnerIds, batchId})
        } catch (error) {
            yield put(snackShow(error.message))
            yield removeBatch()

            return
        }
    }

    yield put(list.invalidate())
}
