import React, {useContext, useEffect, useMemo} from 'react'
import {connect, useSelector} from 'react-redux'

import Table from 'ipmp-react-ui/Table'
import DateTime from 'ipmp-react-ui/DateTime'
import {ReactComponent as IconCamera} from 'ipmp-react-ui/icons/camera.svg'

import {EventDeliveranceCell, EventDescriptionCell, EventRawInfoCell} from 'ui/TableCells'

import PanelCell from 'components/TableCells/PanelCell'

import withLoader from 'containers/withLoader'

import useTableColumnsPermission from 'hooks/useTableColumnsPermission'
import useSelection from 'hooks/useSelection'

import {markAsViewed as view} from 'permissions/events/actions'
import delivery from 'permissions/events/delivery'

import {EDS_VIEW_LONG} from 'constants/eventDelivery'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

import MarkAsViewed from './TableCell/MarkAsViewed'
import EventContext from '../EventContext'

import __ from 'utils/i18n'

const useEventsColumns = () => {
    const {edsView} = useSelector((state) => state.settings)

    const columns = useMemo(
        () => [
            {
                name: () => __('Date'),
                maxWidth: 120,
                render: ({datetime}) => (
                    <DateTime datetime={datetime} timeFirst multiLine />
                ),
            },
            {
                name: () => __('Panel'),
                width: 150,
                fixed: true,
                render: ({panelId, serial, account}) => (
                    <PanelCell {...{id: panelId, serial, account}} multiLine />
                ),
            },
            {
                tooltip: false,
                width: 50,
                fixed: true,
                render: ({hasVideo}) => (hasVideo ? <IconCamera /> : null),
            },
            {
                name: () => __('Event Description'),
                width: 300,
                render: ({severity, description, zone, appointment, partitions}) => {
                    return (
                        <EventDescriptionCell
                            severity={severity}
                            description={description}
                            zone={zone}
                            appointment={appointment}
                            partitions={partitions}
                        />
                    )
                },
            },
            {
                maxWidth: 200,
                name: () => edsView === EDS_VIEW_LONG && __('Delivery Status'),
                align: 'right',
                component: connect((state, {row}) => ({
                    event: row,
                    edsView: state.settings.edsView,
                }))(EventDeliveranceCell),
                permission: delivery,
            },
            {
                width: 32,
                align: 'center',
                fixed: true,
                component: EventRawInfoCell,
            },
            {
                tooltip: false,
                permission: view,
                fixed: true,
                width: 50,
                component: MarkAsViewed,
            },
        ],
        [edsView]
    )

    return useTableColumnsPermission(columns)
}

const ConnectedTable = withLoader()(Table)

export default function EventsTable(props) {
    const {selection, select, deselect} = useSelection()

    useEffect(() => {
        props.startPoll()
        return props.stopPoll
    }, [])

    const {handleEventSelect, selectedEventId} = useContext(EventContext)

    const {columns} = useEventsColumns()

    return (
        <ConnectedTable
            {...props}
            columns={columns}
            fullHeight
            emptyMessage={__('No events found')}
            onRowClick={handleEventSelect}
            active={{id: selectedEventId}}
            selection={selection}
            select={select}
            deselect={deselect}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
        />
    )
}
