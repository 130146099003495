import usePerPage from '../usePerPage'
import {selectPanelsListRows} from 'modules/panels/list/selectors'
import {useSelector} from 'react-redux'
import {
    fetch,
    init,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/panels/list/actions'
import {remove} from 'modules/panels/one/actions'
import {refresh} from 'modules/panels/store/actions'
import {
    showAddPanelModal,
    showChangePanelGroupModal,
    showMarkForServiceModal,
} from 'modules/modals/actions'
import {useActions} from 'modules/higherOrder/useActions'

export default function usePanels() {
    const perPage = usePerPage('panels')
    const {start, total, error, isLoading} = useSelector((state) => state.panels.list)

    return {
        ...perPage,
        start,
        total,
        error,
        isLoading,
        rows: useSelector(selectPanelsListRows),
        ...useActions({
            onPageChange: setStart,
            init,
            fetch,
            reset,
            remove,
            refresh,
            stopPoll,
            startPoll,
            showAddPanelModal,
            showMarkForServiceModal,
            showChangePanelGroupModal,
        }),
    }
}

export function usePanelsRefresh(panelIds) {
    const isRefreshing = useSelector((state) => state.panels.store.isRefreshing)
    const panelsByIds = useSelector((state) => state.panels.store.byIds)

    return {
        isLoading: isRefreshing,
        panels: panelIds.map((panelId) => panelsByIds[panelId]),
        ...useActions({
            refresh: () => refresh(panelIds),
        }),
    }
}
