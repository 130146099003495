import React from 'react'
import {compose} from 'redux'

import RefreshButton from 'ipmp-react-ui/RefreshButton'

import Void from 'components/Void'
import usePanel from 'hooks/pages/usePanel'
import usePermission from 'hooks/usePermission'
import {refresh} from 'permissions/panel/actions'
import useTrackProcess from 'hooks/useTrackProcess'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {withVisibility} from 'containers/withVisibility'
import {withFeatureRejection} from 'containers/withFeature'
import {selectRefreshState} from 'modules/features/store/selectors'

const RefreshButtonVisibility = compose(
    withVisibility(),
    withFeatureRejection(selectRefreshState, Void)
)(RefreshButton)

export default function RefreshPanelButton({panel}) {
    const {refreshState} = usePanel()
    const panelId = useRouterPanelId()
    const {isPermitted} = usePermission(refresh)
    const {process} = useTrackProcess({process: panel.refreshProcess})

    return (
        <RefreshButtonVisibility
            panel={panel}
            panelId={panelId}
            onClick={refreshState}
            isVisible={isPermitted}
            isRefreshing={!!process}
        />
    )
}
