import React from 'react'
import PropTypes from 'prop-types'
import {Routes, Route} from 'react-router-dom'

import {pathLastChild} from 'utils/path'

import General from './General/General'
import Stations from './Stations/Stations'
import Notifications from './Profiles/Notifications'
import Customizations from 'pages/Group/Customizations/Customizations'

const GroupContent = ({group}) => {
    return (
        <Routes>
            <Route
                path={pathLastChild('group.general')}
                element={<General group={group} />}
            />
            <Route
                path={pathLastChild('group.stations')}
                element={<Stations group={group} />}
            />
            <Route
                path={pathLastChild('group.notifications')}
                element={<Notifications group={group} />}
            />
            <Route
                path={pathLastChild('group.customizations')}
                element={<Customizations group={group} />}
            />
        </Routes>
    )
}

GroupContent.propTypes = {
    group: PropTypes.object,
}

export default React.memo(GroupContent)
