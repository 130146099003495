import React from 'react'

import bar from 'permissions/centralStations/bar'

import {withPermissionVisibility} from 'containers/withPermission'

import CentralStationsDropDown from './Bar/CentralStationsDropDown'
import RemoveCentralStations from './Bar/RemoveCentralStations'
import CentralStationsPager from './Bar/CentralStationsPager'
import CreateCentralStation from './Bar/CreateCentralStation'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'

function CentralStationsGridBar() {
    return (
        <Bar>
            <CentralStationsDropDown />
            <RemoveCentralStations />

            <BarSpace />

            <CentralStationsPager />
            <CreateCentralStation />
        </Bar>
    )
}

export default withPermissionVisibility(bar)(CentralStationsGridBar)
