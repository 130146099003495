import isObject from 'lodash-es/isObject'
import isArray from 'lodash-es/isArray'
import * as expression from './dependency/expression'
import * as set from './dependency/set'

export function lookup(dependency) {
    if (!dependency) {
        return {}
    }

    if (isArray(dependency)) {
        return set.lookup(dependency)
    }

    return expression.lookup(dependency)
}

export default function resolve(dependency, values) {
    switch (true) {
        case isArray(dependency):
            return set.resolve(dependency, values)
        case isObject(dependency):
            return expression.resolve(dependency, values)
        default:
            console.warn('Unknown dependency format', dependency)
            return false
    }
}
