import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash-es/get'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'
import MultiSelect from 'ipmp-react-ui/MultiSelect'
import Select, {Option} from 'ipmp-react-ui/Select'

import {__} from 'utils/i18n'
import ModalCardForm from 'ui/ModalCardForm'
import {DEFAULT_TYPE, roleTypesMap} from 'constants/role'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchGroups} from 'modules/groups/select/actions'
import {fetch as fetchAssignableRoles} from 'modules/roles/assignableRoles/actions'
import {selectIsDefaultRoleType} from 'modules/auth/sign/selectors'

const ParentRoleSelect = withSelectLoader(
    fetchAssignableRoles,
    ({roles}) => roles.assignableRoles.isLoading,
    ({roles}) =>
        roles.assignableRoles.ids
            .map((id) => get(roles, ['store', 'byIds', id], {}))
            .map(({id, name}) => ({value: id || '', label: name || ''}))
)(Select)

const GroupsMultiSelect = withSelectLoader(
    fetchGroups,
    ({groups}) => groups.select.isLoading,
    ({groups}) =>
        Object.values(groups.store.byIds).map(({id, name}) => ({value: id, label: name})),
    10
)(MultiSelect)

function RoleTypeSelect({type}) {
    return (
        <Select
            name="type"
            label={__('Role Type')}
            dataTestId="role-type"
            defaultValue={type || DEFAULT_TYPE}
            defaultLabel={roleTypesMap.get(type || DEFAULT_TYPE)()}
        >
            {[...roleTypesMap.keys()].map((type) => (
                <Option value={type} label={roleTypesMap.get(type)()} />
            ))}
        </Select>
    )
}

function GroupsSelect({groups}) {
    return (
        <GroupsMultiSelect
            label={__('Unit Groups')}
            name="groups"
            showTypeForMore
            defaultValues={groups?.map(({id, name}) => ({value: id, label: name}))}
        />
    )
}

const rules = {
    name: {
        presence: true,
        length: {maximum: 255},
    },
}

const getRules = (isNew, isDefaultRoleType) => {
    const currentRules = {...rules}

    if (isDefaultRoleType) {
        currentRules.groups = {
            presence: true,
        }
    }

    if (!isNew) {
        return currentRules
    }

    return {
        ...currentRules,
        parentId: {
            presence: true,
        },
    }
}

RoleForm.propTypes = {
    isNew: PropTypes.bool,
    handle: PropTypes.func,
    data: PropTypes.shape({
        name: PropTypes.string,
        groups: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
            })
        ),
    }),
}

export default function RoleForm({isNew, data, handle, ...props}) {
    const isDefaultRoleType = useSelector(selectIsDefaultRoleType)

    return (
        <ModalCardForm
            confirmOnDismiss
            rules={getRules(isNew, isDefaultRoleType)}
            onSubmit={handle}
            {...props}
        >
            <Input
                autoFocus
                label={__('Name')}
                name="name"
                defaultValue={data.name}
                dataTestId="role-name"
            />

            {isNew && isDefaultRoleType && <RoleTypeSelect type={data.type} />}

            {isNew && (
                <ParentRoleSelect
                    label={__('Parent Role')}
                    name="parentId"
                    dataTestId="parent-role"
                />
            )}

            {isDefaultRoleType && <GroupsSelect groups={data.groups} />}
        </ModalCardForm>
    )
}
