import React from 'react'
import PropTypes from 'prop-types'

import ProgressBar from 'ipmp-react-ui/ProgressBar'
import Definition from 'ipmp-react-ui/Definition'
import {ReactComponent as IconStarted} from 'ipmp-react-ui/icons/events/statuses/started.svg'
import {ReactComponent as IconNotStarted} from 'ipmp-react-ui/icons/events/statuses/not-started.svg'
import {ReactComponent as IconFailed} from 'ipmp-react-ui/icons/events/statuses/failed.svg'
import {ReactComponent as IconCompleted} from 'ipmp-react-ui/icons/events/statuses/succeeded.svg'
import {ReactComponent as IconProcessing} from 'ipmp-react-ui/icons/events/statuses/handled.svg'

import {RunnerUpgradeStatusRetryButton} from 'components/UpgradeStatus/UpgradeStatusRetryButton'
import {ProcessStopFab} from './ProcessStopButton'
import processErrorMessage, {
    PROCESS_ERROR_COMMAND_TIMED_OUT,
    PROCESS_ERROR_TIMED_OUT,
} from 'constants/processError'
import {__} from 'utils/i18n'

export default function BatchRunnerStatus({
    row: {
        isFailedToStart,
        errorMessage,
        type,
        error,
        percentage,
        id,
        status,
        isFailed,
        isSuccessful,
        isStoppable,
        isNotStarted,
        isStarted,
        isHandled,
    },
    isStopping,
    onStop,
}) {
    const stopProcess = () => onStop(id)
    const title = processErrorMessage(error, errorMessage)

    const renderNotStarted = () => {
        return (
            <>
                <Definition Icon={IconNotStarted} title={__('Not started')} />
                <ProgressBar
                    className="batch-runner--not-started"
                    max={100}
                    value={100}
                />
            </>
        )
    }

    const renderStarted = () => {
        return (
            <>
                <ProcessStopFab
                    className="batchInfo-list-item-stopProcess"
                    onClick={stopProcess}
                    disabled={!isStoppable}
                />
                <Definition
                    Icon={IconStarted}
                    title={__('Started')}
                    className="started"
                />
                <ProgressBar max={100} value={100} className="batch-runner--started" />
            </>
        )
    }

    const renderErrorMessage = (withRetry = false) => {
        return (
            <>
                <div className="processError-info">
                    <div className="processRunnerError-info-content">
                        <IconFailed />
                        <div className="content">
                            <h5>
                                {title !== errorMessage ? title : __('Failed to start')}
                            </h5>
                            <p>{errorMessage}</p>
                        </div>
                    </div>
                    <ProgressBar className="batch-runner--failed" max={100} value={100} />
                </div>
                {withRetry && <RunnerUpgradeStatusRetryButton type={type} id={id} />}
            </>
        )
    }

    const renderFailedToStart = () => {
        if (
            errorMessage &&
            ![PROCESS_ERROR_TIMED_OUT, PROCESS_ERROR_COMMAND_TIMED_OUT].includes(error)
        ) {
            return renderErrorMessage()
        }

        return (
            <>
                <Definition
                    className="danger"
                    Icon={IconFailed}
                    title={title || __('Failed to start')}
                />
                <ProgressBar className="batch-runner--failed" max={100} value={100} />
            </>
        )
    }

    const renderFailed = () => {
        if (
            errorMessage &&
            title !== errorMessage &&
            ![PROCESS_ERROR_TIMED_OUT, PROCESS_ERROR_COMMAND_TIMED_OUT].includes(error)
        ) {
            return renderErrorMessage(true)
        }

        return (
            <>
                <Definition
                    className="danger"
                    Icon={IconFailed}
                    title={processErrorMessage(error, errorMessage)}
                />
                <RunnerUpgradeStatusRetryButton type={type} id={id} />
                <ProgressBar className="batch-runner--failed" max={100} value={100} />
            </>
        )
    }

    const renderHandled = () => {
        return (
            <>
                <Definition
                    className="running"
                    Icon={IconProcessing}
                    title={__('Handled ...')}
                />
                <ProgressBar
                    max={100}
                    value={percentage || 0}
                    indeterminate={percentage === 0}
                    className="batch-runner--handled"
                />
            </>
        )
    }

    const renderSuccessful = () => {
        return (
            <>
                <Definition
                    className="success"
                    Icon={IconCompleted}
                    title={__('Succeeded')}
                />
                <ProgressBar max={100} value={100} />
            </>
        )
    }

    const renderStopping = () => {
        return (
            <Definition className="danger" Icon={IconFailed} title={__('Stopping...')} />
        )
    }

    if (isStopping) {
        return renderStopping()
    }

    if (isNotStarted) {
        return renderNotStarted()
    }

    if (isStarted) {
        return renderStarted()
    }

    if (isFailedToStart) {
        return renderFailedToStart()
    }

    if (isFailed) {
        return renderFailed()
    }

    if (isHandled) {
        return renderHandled()
    }

    if (isSuccessful) {
        return renderSuccessful()
    }

    return status()
}

BatchRunnerStatus.propTypes = {
    row: PropTypes.shape({
        isFailedToStart: PropTypes.bool,
        errorMessage: PropTypes.string,
        error: PropTypes.string,
        details: PropTypes.string,
        percentage: PropTypes.number,
        id: PropTypes.number,
        status: PropTypes.string,
        isRunning: PropTypes.bool,
        isFailed: PropTypes.bool,
        isSuccessful: PropTypes.bool,
    }),
    onStop: PropTypes.func,
    isStopPermitted: PropTypes.bool,
}
