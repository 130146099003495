import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {
    deselect,
    deselectAll,
    reverse,
    select,
    selectAll,
} from 'modules/selection/actions'
import {selectSelection} from 'modules/selection/selectors'

export default function useSelection() {
    const selection = useSelector(selectSelection)

    return {
        selection,
        ...useActions({
            select,
            selectAll,
            deselect,
            deselectAll,
            reverse,
        }),
    }
}
