import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux'
import isEmpty from 'lodash-es/isEmpty'

import Spinner from 'ipmp-react-ui/Spinner'
import {ScrollView} from 'ipmp-react-ui/Layout'

import {withPermissionRejection} from 'containers/withPermission'
import {list} from 'permissions/panel/systemTest/page'
import {
    fetchTestsHistory,
    startPollTestsStatuses,
    stopPollTestsStatuses,
} from 'modules/panels/one/actions'
import {
    startPollSupportedTestsList,
    stopPollSupportedTestsList,
} from 'modules/panels/systemTests/actions'
import {useActions} from 'modules/higherOrder/useActions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import usePermission from 'hooks/usePermission'
import {selectSystemTests} from 'modules/panels/systemTests/selectors'
import systemTests from 'permissions/panel/systemTest/page'
import {selectSystemTestsInProgress} from 'modules/panels/one/selectors'

import PanelTestGroups from './PanelTestGroups'
import {DEFAULT_ACTIVE_SYSTEM_TEST_GROUP} from 'constants/panelTests'
import PanelTestGroup from './PanelTestGroup'
import RunAllSystemTestsButton from './Buttons/RunAllSystemTestsButton'

const PanelTest = () => {
    const [activeGroup, setActiveGroup] = useState(DEFAULT_ACTIVE_SYSTEM_TEST_GROUP)
    const [startedGroup, setStartedGroup] = useState()

    const {isPermitted} = usePermission(list)
    const panelId = useRouterPanelId()
    const {
        fetchHistory,
        startPollStatuses,
        stopPollStatuses,
        startPollSupportedTests,
        stopPollSupportedTests,
    } = useActions({
        fetchHistory: fetchTestsHistory,
        startPollStatuses: startPollTestsStatuses,
        stopPollStatuses: stopPollTestsStatuses,
        startPollSupportedTests: startPollSupportedTestsList,
        stopPollSupportedTests: stopPollSupportedTestsList,
    })

    const supportedTests = useSelector((state) => selectSystemTests(state, {panelId}))

    const groupedTests = supportedTests.filter((test) => test.groupName !== null)
    const singleTests = supportedTests.filter((test) => test.groupName === null)

    const testsGroups = groupedTests.reduce((acc, test) => {
        const groupName = test.groupName

        if (!acc.includes(groupName)) {
            acc.push(groupName)
        }

        return acc
    }, [])

    if (singleTests && !isEmpty(singleTests)) {
        testsGroups.unshift(DEFAULT_ACTIVE_SYSTEM_TEST_GROUP)
    }

    const {panel, isLoading} = useSelector(({panels}) => {
        return {
            panel: panels.store.byIds[panelId],
            isLoading: panels.one.isLoading,
        }
    })

    const testsData = panel?.testsData || []
    const testsInProgress = useSelector((state) =>
        selectSystemTestsInProgress({state, panelId})
    )

    const tokens = useMemo(
        () =>
            testsData
                .map((test) => {
                    const inProgress = testsInProgress.filter(
                        (testInProgress) => testInProgress?.testType === test?.testType
                    )

                    return inProgress.length > 0 ? test.processToken : null
                })
                .filter((token) => token !== null),
        [testsInProgress]
    )

    useEffect(() => {
        isPermitted && startPollSupportedTests(panelId)
        isPermitted && fetchHistory(panelId)

        return stopPollSupportedTests
    }, [])

    useEffect(() => {
        isPermitted && !isEmpty(testsInProgress) && startPollStatuses(panelId, tokens)

        return stopPollStatuses
    }, [tokens.length])

    if (isEmpty(panel.testsData)) {
        if (isLoading && isPermitted) {
            return <Spinner />
        }
    }

    return (
        <div className="panel-system-tests-layout">
            <div className="panel-system-tests-group-wrapper">
                <ScrollView className="panel-system-tests-group-cards">
                    <RunAllSystemTestsButton
                        tests={supportedTests}
                        panelSerial={panel.serial}
                        startedGroup={startedGroup}
                        setStartedGroup={setStartedGroup}
                    />
                    <PanelTestGroups
                        activeGroup={activeGroup}
                        handleTabClick={setActiveGroup}
                        groups={testsGroups}
                        supportedTests={supportedTests}
                        serial={panel.serial}
                        startedGroup={startedGroup}
                        setStartedGroup={setStartedGroup}
                        testsData={testsData}
                    />
                </ScrollView>
                <PanelTestGroup
                    activeGroup={activeGroup}
                    panelId={panelId}
                    supportedTests={supportedTests}
                    testsData={testsData}
                    serial={panel.serial}
                    startedGroup={startedGroup}
                />
            </div>
        </div>
    )
}

export default withPermissionRejection(systemTests)(PanelTest)
