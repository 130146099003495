import React from 'react'

import Fab from 'ipmp-react-ui/Fab'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconStop} from 'ipmp-react-ui/icons/close-circle.svg'
import {ReactComponent as IconCross} from 'ipmp-react-ui/icons/cross.svg'

import {__} from 'utils/i18n'
import withConfirmation from 'containers/withConfirmation'

const ButtonWithConfirmation = withConfirmation()(Button)

export default function ProcessStopButton({disabled, onClick}) {
    return (
        <ButtonWithConfirmation
            small
            flat
            Icon={IconCross}
            onClick={onClick}
            primary={!disabled}
            disabled={disabled}
            title={__('Stop process')}
            message={__('Do you want to stop process?')}
        />
    )
}

const FabWithConfirmation = withConfirmation()(Fab)

export function ProcessStopFab({className, onClick, disabled}) {
    return (
        <FabWithConfirmation
            mini
            primary
            Icon={IconStop}
            onClick={onClick}
            disabled={disabled}
            className={className}
            title={__('Stop process')}
            message={__('Do you want to stop process?')}
        />
    )
}
