import {useDispatch} from 'react-redux'
import {showConfirmationModal} from '../modules/modals/actions'

export default function useConfirmationModal(actionCreator, messagesCreator, props = {}) {
    const dispatch = useDispatch()

    return {
        onClick(...args) {
            const action = actionCreator(props, ...args)
            const messages = messagesCreator(props, ...args)

            if (!action) {
                return
            }

            dispatch(showConfirmationModal(action, messages))
        },
    }
}
