import {DEALER_TYPE, INSTALLER_TYPE} from './role'

export const ROLE_PERMISSION_MMI = 'mmi'

const DISALLOW_PERMISSIONS_BY_TYPE_START_WITH = {
    [DEALER_TYPE]: [
        'mmi',
        'system.installers',
        'system.centralStations',
        'system.customers',
    ],
    [INSTALLER_TYPE]: [
        'mmi',
        'groups',
        'system.users',
        'system.roles',
        'system.installers',
        'system.centralStations',
    ],
}

export const isReadonlyPermissionByRoleType = (name, roleType) => {
    for (const startWith of DISALLOW_PERMISSIONS_BY_TYPE_START_WITH[roleType] ?? []) {
        if (name.startsWith(startWith)) {
            return true
        }
    }

    return false
}
