import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ZoneTypeForm from './ZoneTypeForm'
import {validation} from './ZoneTypeFieldset'
import {editZoneType} from 'modules/forms/handlers'

export default function EditZoneType({hide, zoneTypeId}) {
    const {form, handle, isLoading} = useForm(editZoneType)
    const data = useSelector(({zoneTypes}) => zoneTypes.store.byIds[zoneTypeId])

    const handler = (data) => handle({id: zoneTypeId, ...data})

    return (
        <ZoneTypeForm
            {...form}
            hide={hide}
            data={data}
            handle={handler}
            rules={validation}
            isLoading={isLoading}
            header={__('Edit Zone Type')}
        />
    )
}
