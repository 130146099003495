import React from 'react'
import {useSelector} from 'react-redux'

import {__} from 'utils/i18n'
import UserForm from './UserForm'
import useForm from 'hooks/useForm'
import {EditValidation} from './Fieldset/User'
import {editUser} from 'modules/forms/handlers'

export default function EditUser({hide, userId}) {
    const {handle, isLoading, form} = useForm(editUser)
    const data = useSelector(({users}) => users.store.byIds[userId])
    const handler = (newData) => {
        const isChanged =
            data.name !== newData.name ||
            data.email !== newData.email ||
            data.phone !== newData.phone ||
            data.countryId != newData.countryId ||
            data.roleId != newData.roleId ||
            null !== newData.password

        isChanged ? handle(userId, newData) : hide()
    }

    return (
        <UserForm
            {...form}
            data={data}
            hide={hide}
            handle={handler}
            isLoading={isLoading}
            rules={EditValidation}
            header={__('Edit User')}
        />
    )
}
