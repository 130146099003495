import {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'

import {selectAddSensorConfirmationRequest} from 'modules/devices/autolearn/selectors'

import useConfirmWithoutPinCode from './ConfirmWithoutPinCode'
import useConfirmWithPinCode from './ConfirmWithPinCode'

export default function useAddSensorConfirmation(session) {
    const request = useSelector((state) =>
        selectAddSensorConfirmationRequest(state, {session})
    )
    const [hasPin, setHasPin] = useState(false)
    useEffect(() => {
        setHasPin(false)
    }, [session, request])

    const screenWithoutPin = useConfirmWithoutPinCode(hasPin, setHasPin, session, request)
    const screenWithPin = useConfirmWithPinCode(hasPin, setHasPin, session, request)

    return !session.token || !request ? null : screenWithoutPin || screenWithPin
}
