import {compose} from 'redux'

import withLoader from 'containers/withLoader'
import {withPermissionRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import {PanelPageBase} from './PanelPageBase'
import {content} from 'permissions/panel/page'

export const PanelPageLoader = compose(
    withPermissionRejection(content),
    withLoader(
        ({
            fetch,
            fetchFeatures,
            fetchFirmware,
            fetchPanelInteractiveUsers,
            panelId,
            isPanelFeatures,
            isInteractiveUsers,
            isPanelUsersPagePermitted,
            isUnitPermitted,
            isFirmwarePermitted,
            fetchUnits,
        }) => {
            fetch()
            !isPanelFeatures && isUnitPermitted && fetchFeatures(panelId)
            if (isPanelUsersPagePermitted) {
                !isInteractiveUsers && fetchPanelInteractiveUsers()
            }
            isFirmwarePermitted && fetchFirmware()
            !isUnitPermitted && fetchUnits()
        }
    ),
    withLifeCycle({
        onMount({
            startPollPanelData,
            location,
            remember,
            masterUserToRemember,
            isUnitPermitted,
        }) {
            isUnitPermitted && startPollPanelData()
            remember({path: location.pathname, masterUserToRemember})
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },

        onUpdate({error, panelId, dispatch, forgetPanel}, {error: prevError}) {
            if (error && error !== prevError) {
                forgetPanel()
            }
        },
    })
)(PanelPageBase)
