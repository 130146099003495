import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'
import {unassign} from 'permissions/interactiveUsers/actions'
import {disconnectPanel} from 'modules/interactiveUserPanels/list/actions'

const ConnectedButton = compose(withVisibility(), withConfirmation())(Button)

export default function UnassignPanelCell({row: {id, serial}, interactiveUserId}) {
    const {isPermitted} = usePermission(unassign)
    const {onClick} = useActions({
        onClick: () => disconnectPanel({panelId: id, interactiveUserId}),
    })

    return (
        <ConnectedButton
            small
            flat
            Icon={IconMinus}
            onClick={onClick}
            isVisible={isPermitted}
            title={__('Unassign panel')}
            message={__('Do you really want to unassign %s?', serial)}
        />
    )
}
