import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconUpload} from 'ipmp-react-ui/icons/upload.svg'

import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import withConfirmation from 'containers/withConfirmation'
import {withSelectionHandler} from 'containers/withSelection'
import {pushBasicConfiguration} from 'modules/panels/store/actions'

const ConnectedButton = compose(withSelectionHandler(), withConfirmation())(Button)

export default function PushBasicConfigurationButton({basicConfigId}) {
    const {onClick} = useActions(
        {
            onClick: (...args) => pushBasicConfiguration(basicConfigId, ...args),
        },
        [basicConfigId]
    )

    return (
        <ConnectedButton
            message={__(
                'Do you really want to push basic configurations for selected panels?'
            )}
            onClick={onClick}
            Icon={IconUpload}
            title={__('Push basic configurations')}
        >
            {__('Push Basic Configuration')}
        </ConnectedButton>
    )
}
