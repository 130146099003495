import __ from 'utils/i18n'

export const DEFAULT_TYPE = 'default'
export const DEALER_TYPE = 'dealer'
export const INSTALLER_TYPE = 'installer'

export const roleTypesMap = new Map([
    [DEFAULT_TYPE, () => __('Default')],
    [DEALER_TYPE, () => __('Dealer')],
    [INSTALLER_TYPE, () => __('Installer')],
])

export const getRoleTypeLabel = (role, fallback = null) => {
    if (roleTypesMap.has(role)) {
        return roleTypesMap.get(role)()
    }

    return fallback
}
