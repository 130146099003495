import React from 'react'

import Page from 'ipmp-react-ui/Page'

import TableWithBlind from 'components/Table/TableWithBlind'

import InteractiveUsersBar from './InteractiveUsersBar'
import InteractiveUsersBlind from './InteractiveUsersBlind'
import InteractiveUsersTable from './InteractiveUsersTable'

const InteractiveUsersContent = () => {
    return (
        <TableWithBlind>
            {(props) => <InteractiveUsersBlind {...props} />}
            {(props) => <InteractiveUsersTable {...props} />}
        </TableWithBlind>
    )
}

export default function InteractiveUsersPage() {
    return <Page Bar={InteractiveUsersBar} Content={InteractiveUsersContent} />
}
