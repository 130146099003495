import React from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import withModalLoader from 'containers/withModalLoader'
import {editCentralStation} from 'modules/forms/handlers'
import CentralStationFormFields, {rules} from './CentralStationFormFields'
import {selectEditCentralStation} from 'modules/centralStations/store/selectors'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'
import {fetch as fetchSerialPorts} from 'modules/centralStations/serialPorts/actions'

function EditCentralStation({data: oldData, hide, protocols, serialPorts}) {
    const {handle, form, isLoading} = useForm(editCentralStation)

    const handler = (data) => {
        data = {
            ...oldData,
            ...data,
        }

        if (!data.isHeartBeat) {
            data.heartBeat = 0
        }

        handle(data)
    }

    const getSerialPortsWithCurrent = () => {
        if (oldData.connectionType !== 'serial') {
            return serialPorts
        }

        return [
            {
                id: oldData.serialPortId,
                name: oldData.device ?? oldData.serialPortName,
            },
            ...serialPorts,
        ]
    }

    return (
        <ModalCardForm
            confirmOnDismiss
            rules={rules}
            header={__('Edit Central Station')}
            isLoading={isLoading}
            hide={hide}
            onSubmit={handler}
            {...form}
        >
            <CentralStationFormFields
                data={oldData}
                serialPorts={getSerialPortsWithCurrent()}
                protocols={protocols}
            />
        </ModalCardForm>
    )
}

EditCentralStation.propTypes = {
    centralStationId: PropTypes.number,
    hide: PropTypes.func.isRequired,
    protocols: PropTypes.any,
    serialPorts: PropTypes.array,
    data: PropTypes.object,
}

export default compose(
    connect(selectEditCentralStation, (dispatch) =>
        bindActionCreators(
            {
                fetchSerialPorts,
                fetchProtocols,
            },
            dispatch
        )
    ),
    withModalLoader(({fetchSerialPorts, fetchProtocols}) => {
        fetchSerialPorts()
        fetchProtocols()
    })
)(EditCentralStation)
