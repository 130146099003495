export function resolvePanelsIdsOptimistic(total, page, panelIds) {
    const panelsToUpgrade = total === panelIds.length ? total : total - panelIds.length

    // For optimistic UI
    if (panelsToUpgrade === 1) {
        // We can try to find it id for show serial in My Processes widget

        // Not garanted to find id
        if (total - page.length > 0) {
            return new Array(panelsToUpgrade)
        }

        const panelId = page.filter((id) => !panelIds.includes(id))

        return [panelId]
    }

    // fill fakes
    return new Array(Math.min(panelsToUpgrade, 100000))
}
