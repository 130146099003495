import {combineReducers, compose} from 'redux'

import list from './list/reducer'
import recent from './recent/reducer'
import store from './store/reducer'
import remarks from './remarks/reducer'
import remoteInspections from './remoteInspections/reducer'
import state from './state/reducer'
import keypad from './keypad/reducer'
import locations from './locations/reducer'
import logs from './logs/reducer'
import one from './one/reducer'
import plink from './plink/reducer'
import processes from './processes/reducer'
import firmware from './firmware/reducer'
import reports from './reports/reducer'
import events from './events/reducer'
import configuration from './configuration/reducer'
import configurationExport from './configuration/export/reducer'
import panelUsers from './panelUsers/reducer'
import had from './had/reducer'
import systemLogs from './systemLogs/reducer'
import systemTests from './systemTests/reducer'
import billingPlan from './billingPlan/reducer'

import createPurgeReducer from 'modules/higherOrder/createPurgeReducer'
import {purgeStore} from './one/actions'

const panelsReducer = combineReducers({
    list,
    recent,
    store,
    remarks,
    remoteInspections,
    state,
    keypad,
    locations,
    logs,
    one,
    plink,
    processes,
    reports,
    events,
    firmware,
    configuration,
    configurationExport,
    panelUsers,
    had,
    systemLogs,
    systemTests,
    billingPlan,
})

const purgePanelsStoreReducer = (reducer) =>
    createPurgeReducer(reducer, purgeStore, (state) => ({
        store: state.store,
        recent: state.recent,
        list: state.list,
    }))

export default compose(purgePanelsStoreReducer)(panelsReducer)
