import {createSelector} from 'reselect'

export const selectReportsListRows = createSelector(
    (state) => state.reports.list.page,
    (state) => state.reports.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectReportsList = createSelector(
    (state) => state.reports.list,
    (list) => list
)
