import React, {useMemo} from 'react'
import {connect, useSelector} from 'react-redux'

import DateTime from 'ipmp-react-ui/DateTime'
import Table from 'ipmp-react-ui/Table'
import {ReactComponent as IconCamera} from 'ipmp-react-ui/icons/camera.svg'

import {EventDeliveranceCell, EventDescriptionCell, EventRawInfoCell} from 'ui/TableCells'

import {markAsViewed as view} from 'permissions/events/actions'
import delivery from 'permissions/events/delivery'

import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

import MarkAsViewed from 'pages/Events/List/TableCell/MarkAsViewed'

import {EDS_VIEW_LONG} from 'constants/eventDelivery'
import {DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT} from 'constants/themes'

import __ from 'utils/i18n'

const useEventsColumns = () => {
    const {edsView} = useSelector((state) => state.settings)

    const columns = useMemo(
        () => [
            {
                name: () => __('Date'),
                maxWidth: 120,
                render: ({datetime}) => (
                    <DateTime datetime={datetime} timeFirst multiLine />
                ),
            },
            {
                tooltip: false,
                width: 50,
                fixed: true,
                render: ({hasVideo}) => (hasVideo ? <IconCamera /> : null),
            },
            {
                name: () => __('Event Description'),
                width: 300,
                render: ({severity, description, zone, appointment, partitions}) => {
                    return (
                        <EventDescriptionCell
                            severity={severity}
                            description={description}
                            zone={zone}
                            appointment={appointment}
                            partitions={partitions}
                        />
                    )
                },
            },
            {
                maxWidth: 200,
                name: () => edsView === EDS_VIEW_LONG && __('Delivery Status'),
                align: 'right',
                component: connect((state, {row}) => ({
                    event: row,
                    edsView: state.settings.edsView,
                }))(EventDeliveranceCell),
                permission: delivery,
            },
            {
                width: 32,
                align: 'center',
                fixed: true,
                component: EventRawInfoCell,
            },
            {
                tooltip: false,
                permission: view,
                fixed: true,
                width: 50,
                component: MarkAsViewed,
            },
        ],
        [edsView]
    )

    return useTableColumnsPermission(columns)
}

export default function EventsTable(props) {
    const {columns} = useEventsColumns()

    return (
        <Table
            {...props}
            columns={columns}
            rowHeight={DEFAULT_MULTI_LINE_ROW_MIN_HEIGHT}
            emptyMessage={__('No events found')}
        />
    )
}
