import React, {useMemo} from 'react'

import {DTP_UNKNOWN} from 'constants/protocols'

import SessionContentSecurity from './SessionContentSecurity'
import SessionButtons from './SessionButtons'
import SessionContentAutomation from './SessionContentAutomation'

export default function useSessionScreen(session) {
    return useMemo(
        () =>
            !session.token
                ? null
                : {
                      Content: () =>
                          session?.protocol === DTP_UNKNOWN ? (
                              <SessionContentSecurity session={session} />
                          ) : (
                              <SessionContentAutomation session={session} />
                          ),
                      Actions: () => <SessionButtons session={session} />,
                  },
        [session.token]
    )
}
