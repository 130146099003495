import React from 'react'
import {compose} from 'redux'

import Menu, {MenuDelimiter} from 'ipmp-react-ui/Menu'
import DropDownButton from 'ipmp-react-ui/DropDownButton'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {service} from 'permissions/panels/selection'
import {ifSelection} from 'containers/withSelection'
import ChangeGroupItem from './Items/ChangeGroupItem'
import RefreshStateItem from './Items/RefreshStateItem'
import RemovePanelsItem from './Items/RemovePanelsItem'
import {withVisibility} from 'containers/withVisibility'
import TriggerDiscoveryItem from './Items/TriggerDiscoveryItem'
import DownloadConfigurationItem from './Items/DownloadConfigurationItem'
import PushBasicConfigurationItem from './Items/PushBasicConfigurationItem'

function ServiceDropDownBase() {
    return (
        <DropDownButton label={__('Service')} dataTestId="panels-service-drop-down">
            <Menu>
                <ChangeGroupItem />

                <MenuDelimiter />

                <RefreshStateItem />
                <DownloadConfigurationItem />
                <PushBasicConfigurationItem />

                <MenuDelimiter />

                <TriggerDiscoveryItem />

                <MenuDelimiter />

                <RemovePanelsItem />
            </Menu>
        </DropDownButton>
    )
}

const ServiceDropDownVisibility = compose(
    withVisibility(),
    ifSelection()
)(ServiceDropDownBase)

export default function ServiceDropDown() {
    const {isPermitted} = usePermission(service)

    return <ServiceDropDownVisibility isVisible={isPermitted} />
}
