import dataMapper from 'utils/dataMapper'
import parseDate from 'api/base/parseDate'
import {isNeoSerial} from 'utils/panelType'
import isNil from 'lodash-es/isNil'

export const mapInteractiveUser = dataMapper((user) => ({
    id: parseInt(user.pru_id),
    email: user.pru_email,
    status: user.pru_status,
    createdAt: parseDate(user.pru_created_timestamp),
    lastLoginAt: parseDate(user.pru_last_login_timestamp),
    panels: parseInt(user.panels),
}))

export const keysMap = {
    id: 'id',
    email: 'pru_email',
    status: 'pru_status',
    createdAt: 'pru_created_timestamp',
    lastLoginAt: 'pru_last_login_timestamp',
    panelIds: 'unt_id',
}

export const mapInteractiveUserPanels = dataMapper((interactiveUserPanel) => ({
    id: Number(interactiveUserPanel.unt_id),
    groupId: Number(interactiveUserPanel.utg_id),
    serial: interactiveUserPanel.unt_serial,
    isNeo: isNeoSerial(interactiveUserPanel.unt_serial),
    account: interactiveUserPanel.unt_account,
    group: interactiveUserPanel._utg_name,
    role: interactiveUserPanel.role,
    label: interactiveUserPanel.label,
}))

export const mapOnePanelInteractiveUsers = dataMapper((user) => ({
    id: parseInt(user.id),
    role: user.role,
    label: user.label || null,
    email: user.email,
    isPinSet: user.has_code,
    partitions: user.partitions,
    createdAt: parseDate(user.created_timestamp),
    isAssign: !isNil(user.email),
    status: user.status,
    interactiveUserId: user.pru_id,
    lastName: user.last_name || null,
    expirationDate: user.expiration_date || null,
}))

export const assignUserKeyMap = {
    email: 'email',
    panelId: 'unt_id',
    panelUserId: 'pxu_panel_id',
}

export const mapPanelsInteractiveUsers = dataMapper((panelsUser) => ({
    panelId: Number(panelsUser.unt_id),
    interactiveUserId: Number(panelsUser.pru_id),
    panelUserId: Number(panelsUser.puu_power_id),
    role: panelsUser.pxu_role,
    email: panelsUser.pru_email,
    status: panelsUser.pru_status,
    createdAt: panelsUser.pru_created_timestamp,
    lastLoginAt: panelsUser.pru_last_login_timestamp,
}))
