import React from 'react'

import Page from 'ipmp-react-ui/Page'

import BasicConfigurationGridBar from './BasicConfigurationGridBar'
import BasicConfigurationContent from './BasicConfigurationContent'

export default function BasicConfigurationPage() {
    return <Page Bar={BasicConfigurationGridBar} Content={BasicConfigurationContent} />
}
