import PropTypes from 'prop-types'

export default function ReadOnlyListValue({item: {items: originalItems}, value}) {
    return originalItems.map((originalItem) => {
        return originalItem.find((item) => {
            return item.trim() === value.trim()
        })
    })
}

ReadOnlyListValue.propTypes = {
    item: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
}
