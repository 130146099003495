import React from 'react'

import Input from 'ipmp-react-ui/Input'

import __ from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {registerInstaller} from 'modules/forms/handlers'

const rules = {
    name: {
        presence: true,
        length: {maximum: 255},
    },
    phone: {
        presence: true,
        phone: true,
    },
    email: {
        presence: true,
        emailRFC: true,
    },
}

export default function RegisterInstaller({hide}) {
    const {handle, isLoading, form} = useForm(registerInstaller)

    return (
        <ModalCardForm
            hide={hide}
            rules={rules}
            onSubmit={handle}
            isLoading={isLoading}
            header={__('Register New Installer')}
            {...form}
        >
            <Input label={__('Name')} name="name" />
            <Input label={__('Email')} name="email" />
            <Input label={__('Phone')} name="phone" />
        </ModalCardForm>
    )
}
