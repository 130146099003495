import React from 'react'
import {compose} from 'redux'

import {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as IconDiscovery} from 'ipmp-react-ui/icons/discovery.svg'

import {__} from 'utils/i18n'
import {DISCOVERY} from 'constants/features'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {withSelectionFeature} from 'containers/withFeature'
import {withSelectionHandler} from 'containers/withSelection'
import {showTriggerDiscoveryModal} from 'modules/modals/actions'
import {discovery as permission} from 'permissions/panels/selection'

const ConnectedMenuItem = compose(withVisibility(), withSelectionHandler())(MenuItem)

export function TriggerDiscoveryBase({onClick}) {
    const {isPermitted} = usePermission(permission)

    return (
        <ConnectedMenuItem
            onClick={onClick}
            Icon={IconDiscovery}
            title={__('Trigger panel discovery')}
            isVisible={isPermitted}
        >
            {__('Trigger Discovery')}
        </ConnectedMenuItem>
    )
}

const ConnectedMenuSelectionFeature =
    withSelectionFeature(DISCOVERY)(TriggerDiscoveryBase)

export default function TriggerDiscoveryItem() {
    const onClick = useActions(showTriggerDiscoveryModal)

    return <ConnectedMenuSelectionFeature onClick={onClick} />
}
