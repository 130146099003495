import {warn} from 'utils/log'
import {__} from 'utils/i18n'

export const DEVICE_TYPE_UNKNOWN = 'UNKNOWN'

export const DEVICE_TYPE_CONTROL_PANEL = 'CONTROL_PANEL'
export const DEVICE_TYPE_GSM = 'GSM'
export const DEVICE_TYPE_ZONE = 'ZONE'
export const DEVICE_TYPE_CAMERA = 'CAMERA'
export const DEVICE_TYPE_KEYFOB = 'KEYFOB'
export const DEVICE_TYPE_GUARD_KEY = 'GUARD_KEY'
export const DEVICE_TYPE_WIRELESS_COMMANDER = 'WIRELESS_COMMANDER'
export const DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD = '2_WAY_WIRELESS_KEYPAD'
export const DEVICE_TYPE_PENDANT = 'PENDANT'
export const DEVICE_TYPE_PROXY_TAG = 'PROXY_TAG'
export const DEVICE_TYPE_X10 = 'X10'
export const DEVICE_TYPE_REPEATER = 'REPEATER'
export const DEVICE_TYPE_EXPANDER_33 = 'EXPANDER_33'
export const DEVICE_TYPE_WL_SIREN = 'WL_SIREN'
export const DEVICE_TYPE_PGM = 'PGM'
export const DEVICE_TYPE_POWER_LINK = 'POWER_LINK'

export const DEVICE_TYPE_WIRED_KEYPAD = 'WIRED_KEYPAD'
export const DEVICE_TYPE_ZONE_EXPANDER = 'ZONE_EXPANDER_HSM2108'
export const DEVICE_TYPE_OUTPUT_EXPANDER = 'OUTPUT_EXPANDER_HSM2208'
export const DEVICE_TYPE_POWER_SUPPLY = 'POWER_SUPPLY_MODULE_HSM2300'
export const DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS = 'POWER_SUPPLY_WITH_OUTPUTS_HSM2204'
export const DEVICE_TYPE_PGH = 'PGH'
export const DEVICE_TYPE_COMMUNICATOR = 'COMMUNICATOR'
export const DEVICE_TYPE_AUDIO_VERIFICATION = 'AUDIO_VERIFICATION_HSM2955'

export const DEVICE_TYPE_POWER_SUPPLY_3A = 'POWER_SUPPLY_3A'
export const DEVICE_TYPE_IO_EXPANDER_HSM3408 = 'IO_EXPANDER_HSM3408'
export const DEVICE_TYPE_BUS_REPEATER_HSM3204CX = 'BUS_REPEATER_HSM3204CX'
export const DEVICE_TYPE_DUALPATH_INPUT = 'DUAL_PATH_INPUT'
export const DEVICE_TYPE_DUALPATH_OUTPUT = 'DUAL_PATH_OUTPUT'
export const DEVICE_TYPE_LTE_MODULE = 'LTE_MODULE'
export const DEVICE_TYPE_IQ_REMOTE = 'IQ_REMOTE'
export const DEVICE_TYPE_DT_PG_BOX_DEVICE = 'DT_PG_BOX_DEVICE'
export const DEVICE_TYPE_DAUGHTER_CARD = 'DAUGHTER_CARD'
export const IQ_HAD = 'IQ_HAD'
export const DEVICE_TYPE_BT = 'BT'
export const DEVICE_TYPE_VIRTUAL_KEYPAD = 'VIRTUAL_KEYPAD'
export const DEVICE_TYPE_OS = 'OS'
export const DEVICE_TYPE_IO_TERMINAL = 'IO_TERMINAL'
export const DEVICE_TYPE_USER = 'USER'
export const DEVICE_TYPE_PARTITION = 'PARTITION'
export const DEVICE_TYPE_WIRED_TO_WIRELESS = 'WIRED_TO_WIRELESS'
export const DEVICE_TYPE_IQ_PENDANT = 'IQ_PENDANT'
export const DEVICE_TYPE_IQ_REPEATER = 'IQ_REPEATER'

const deviceTypeMap = new Map([
    [DEVICE_TYPE_UNKNOWN, () => __('Unknown')],
    [DEVICE_TYPE_CONTROL_PANEL, () => __('Control Panel')],
    [DEVICE_TYPE_ZONE, () => __('Zone')],
    [DEVICE_TYPE_CAMERA, () => __('Camera')],
    [DEVICE_TYPE_KEYFOB, () => __('Keyfob')],
    [DEVICE_TYPE_GUARD_KEY, () => __('Guard key')],
    [DEVICE_TYPE_WIRELESS_COMMANDER, () => __('Wireless commander')],
    [DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD, () => __('Wireless keypad')],
    [DEVICE_TYPE_PENDANT, () => __('Pendant')],
    [DEVICE_TYPE_PROXY_TAG, () => __('Proxy tag')],
    [DEVICE_TYPE_X10, () => __('X10')],
    [DEVICE_TYPE_REPEATER, () => __('Repeater')],
    [DEVICE_TYPE_EXPANDER_33, () => __('Expander 33')],
    [DEVICE_TYPE_WL_SIREN, () => __('Wireless siren')],
    [DEVICE_TYPE_PGM, () => __('Pgm')],
    [DEVICE_TYPE_WIRED_KEYPAD, () => __('Wired Keypad')],
    [DEVICE_TYPE_ZONE_EXPANDER, () => __('Zone Expander')],
    [DEVICE_TYPE_OUTPUT_EXPANDER, () => __('Output Expander')],
    [DEVICE_TYPE_POWER_SUPPLY, () => __('Power Supply HSM2300')],
    [DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS, () => __('Power Supply With Outputs')],
    [DEVICE_TYPE_POWER_SUPPLY_3A, () => __('Power Supply 3A HSM3350')],
    [DEVICE_TYPE_PGH, () => __('PGH')],
    [DEVICE_TYPE_COMMUNICATOR, () => __('Communicator')],
    [DEVICE_TYPE_AUDIO_VERIFICATION, () => __('Audio Verification')],
    [DEVICE_TYPE_POWER_LINK, () => __('Power Link')],
    [DEVICE_TYPE_GSM, () => __('GSM Modem')],
    [DEVICE_TYPE_LTE_MODULE, () => __('OTA Modem')],
    [DEVICE_TYPE_IO_EXPANDER_HSM3408, () => __('8 I/O Module HSM3408')],
    [DEVICE_TYPE_BUS_REPEATER_HSM3204CX, () => __('Bus Repeater HSM3204CX')],
    [DEVICE_TYPE_DUALPATH_INPUT, () => __('Input')],
    [DEVICE_TYPE_DUALPATH_OUTPUT, () => __('Output')],
    [DEVICE_TYPE_IQ_REMOTE, () => __('IQRemote')],
    [DEVICE_TYPE_DT_PG_BOX_DEVICE, () => __('PowerG Automation Box')],
    [DEVICE_TYPE_DAUGHTER_CARD, () => __('Daughter Card')],
    [IQ_HAD, () => __('IQ HAD')],
    [DEVICE_TYPE_BT, () => __('Bluetooth')],
    [DEVICE_TYPE_VIRTUAL_KEYPAD, () => __('Virtual keypad')],
    [DEVICE_TYPE_OS, () => __('Operating System')],
    [DEVICE_TYPE_IO_TERMINAL, () => __('USC Output')],
    [DEVICE_TYPE_USER, () => __('User')],
    [DEVICE_TYPE_PARTITION, () => __('Partition')],
    [DEVICE_TYPE_WIRED_TO_WIRELESS, () => __('Wired To Wireless')],
    [DEVICE_TYPE_IQ_PENDANT, () => __('IQ Pendant')],
    [DEVICE_TYPE_IQ_REPEATER, () => __('IQ Repeater')],
])

export default function deviceType(type) {
    if (deviceTypeMap.has(type)) {
        return deviceTypeMap.get(type)()
    }

    warn(`Unknown device type ${type}`)

    return type
}
