import React from 'react'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'

export default function InitButtons({onClose}) {
    return (
        <>
            <Button onClick={onClose} borderless>
                {__('Dismiss')}
            </Button>
        </>
    )
}
