import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconFile} from 'ipmp-react-ui/icons/file.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {createReport} from 'permissions/panels/selection'

const ButtonVisibility = withVisibility()(Button)

export default function CreateReportButton({onClick}) {
    const {isPermitted} = usePermission(createReport)

    return (
        <ButtonVisibility Icon={IconFile} isVisible={isPermitted} onClick={onClick}>
            {__('Create Report')}
        </ButtonVisibility>
    )
}
