import React from 'react'
import {useSelector} from 'react-redux'

import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import list from 'permissions/actionLog/list'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import {selectActionLogList} from 'modules/actionLog/list/selectors'
import {MapSuggestFilter} from 'components/Search/Filters/MapSuggestFilter'
import {
    fetch,
    setQuery,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
} from 'modules/actionLog/list/actions'

const SearchVisibility = withVisibility()(Search)

export default function ActionLogSearch({className}) {
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectActionLogList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
        onSuggest: fetchSuggests,
    })

    const filters = [
        new MapSuggestFilter('users', __('User'), {
            value: 'userId',
            title: 'userName',
        }),
        new ValuesFilter('statuses', __('Status'), {
            success: __('Success'),
            error: __('Error'),
        }),

        new MapSuggestFilter('activities', __('Activity'), {
            value: 'key',
            title: 'value',
        }),

        new DateFilter('datetime', __('Time')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Action logs')}
        />
    )
}
