import {all, takeEvery, call, put, select} from 'redux-saga/effects'

import * as api from 'api/panel/autolearn'
import {snackShow} from 'modules/snacks'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {
    AUTOLEARN_SESSION_TIMEOUT,
    EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE,
    EVENT_START_ZONE_DISCOVERY,
} from 'constants/autolearn'
import {POLL_AUTOLEARN} from 'configs/pollers'

import * as actions from './actions'
import handleMessage from './handler'
import {selectSession} from './selectors'

export default function* () {
    yield all([
        takeEvery(actions.startSession, watchStart),
        takeEvery(actions.stopSession, watchStop),
        createPollerSaga(
            actions.startPoll,
            actions.stopPoll,
            POLL_AUTOLEARN,
            receive,
            true
        ),
        takeEvery(actions.sendAddSensorConfirmation, watchAddSensorConfirmation),
        takeEvery(actions.sendZWaveKeysReport, watchZWaveKeysReport),
        takeEvery(actions.sendZWaveDskReport, watchZWaveDskReport),
    ])
}

function* watchStart({payload: session}) {
    try {
        const token = yield call(api.start, session, AUTOLEARN_SESSION_TIMEOUT)

        yield put(actions.setToken(session, token))
        yield put(actions.startPoll({...session, token}))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchStop({payload: session}) {
    const {token} = yield select((state) => selectSession(state, {session}))
    if (!token) {
        return
    }

    yield put(actions.clearSession(session))

    try {
        yield call(api.stop, session)
    } catch (error) {
        yield put(snackShow(error.message))
    }

    yield put(actions.stopPoll())
}

function* receive({payload: session}) {
    const {token} = yield select((state) => selectSession(state, {session}))
    if (!token) {
        return
    }

    const messages = yield call(api.fetch, session)

    for (const message of messages) {
        if (
            [
                EVENT_START_ZONE_DISCOVERY,
                EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE,
            ].includes(message.event) &&
            message.responseCode !== 0
        ) {
            yield handleMessage(session, message)
            return
        }
    }

    for (const message of messages) {
        yield handleMessage(session, message)
    }
}

receive.onError = function* (error, {payload: session}) {
    yield put(actions.stopSession(session))
}

export function* watchAddSensorConfirmation({payload: {session, data}}) {
    try {
        yield call(api.confirmation, session, data)
        yield put(actions.completeAddSensorConfirmation(session, data.sensor))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchZWaveKeysReport({payload: {session, data}}) {
    try {
        yield call(api.keys, session, data)
        yield put(actions.completeZWaveKeysReport(session, data.node_id))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

export function* watchZWaveDskReport({payload: {session, data}}) {
    try {
        yield call(api.dsk, session, data)
        yield put(actions.completeZWaveDskReport(session, data))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
