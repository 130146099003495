import React, {useEffect} from 'react'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import withLoader from 'containers/withLoader'
import RemoveNotificationType from './TableCell/RemoveNotificationType'
import EditNotificationType from './TableCell/EditNotificationType'
import useNotificationTypes from 'hooks/pages/useNotificationTypes'
import {edit, remove} from 'permissions/settings/virtualPanelRules'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        name: () => __('Notification Type'),
        render: ({name}) => name,
    },
    {
        tooltip: false,
        width: 40,
        fixed: true,
        component: EditNotificationType,
        permission: edit,
    },
    {
        tooltip: false,
        width: 50,
        fixed: true,
        component: RemoveNotificationType,
        permission: remove,
    },
]

const TableWithLoader = withLoader()(Table)

export default function NotificationTypesTable() {
    const {columns} = useTableColumnsPermission(allColumns)
    const {init, startPoll, stopPoll, rows, isLoading, error} = useNotificationTypes()

    useEffect(() => {
        init()
        startPoll()

        return stopPoll
    }, [])

    return (
        <TableWithLoader
            fullHeight
            rows={rows}
            error={error}
            columns={columns}
            isLoading={isLoading}
            emptyMessage={__('No notification types found')}
        />
    )
}
