import {put, all, call, race, select, take, delay} from 'redux-saga/effects'
import {LOCATION_CHANGE} from 'redux-first-history'
import get from 'lodash-es/get'
import isEmpty from 'lodash-es/isEmpty'

import {isOnline} from 'api/panel/info'
import * as infoApi from 'api/panel/info'
import {
    POLL_DEVICES,
    POLL_DEVICES_BACKGROUND,
    POLL_PANEL_IS_ONLINE,
    POLL_PROCESSES,
    POLL_STATE,
    POLL_STATE_BACKGROUND,
} from 'configs/pollers'
import {
    PROCESS_TYPE_PMAXCANCELSOAKZONE,
    PROCESS_TYPE_PMAXCLEARBYPASSZONE,
    PROCESS_TYPE_PMAXSETBYPASSZONE,
    PROCESS_TYPE_PMAXSETSOAKZONE,
    PROCESS_TYPE_PMAXZONEADD,
    PROCESS_TYPE_PMAXZONEREMOVE,
    PROCESS_TYPE_PMAXZONERSSI,
} from 'constants/processTypes'

import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {fetch as fetchDevices} from 'modules/devices/list/saga'
import {fetch as fetchState} from 'modules/panels/state/saga'
import {
    takeProcessComplete,
    takeProcessCompleteSuccessful,
} from 'modules/processes/manager/takeProcess'
import {check} from 'utils/path'
import * as testsApi from 'api/panel/panelTest'

import watchPanelDiscoveryComplete from './watchers/watchPanelDiscoveryComplete'
import {update} from '../store/actions'
import * as actions from './actions'
import {stopPollTestsStatuses} from './actions'

export default function* () {
    yield all([
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            devicesDelay,
            fetchDevices,
            true
        ),
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            statesDelay,
            fetchState,
            true
        ),
        createPollerSaga(
            actions.startPollPanelData,
            actions.stopPollPanelData,
            POLL_PANEL_IS_ONLINE,
            fetchIsOnline,
            true
        ),
        createPollerSaga(
            actions.startPollTestsStatuses,
            actions.stopPollTestsStatuses,
            POLL_PROCESSES,
            watchPollingStatus,
            true
        ),
    ])
}

function* fetchIsOnline({payload: {panelId}}) {
    const data = yield call(isOnline, panelId)
    const activatingStarted = yield select(({panels}) => panels.store.byIds[panelId])
        .activatingStarted

    if (!data.isActivated) {
        const {isActivating} = yield call(infoApi.isPanelActivating, panelId)
        data.isActivating = activatingStarted ? activatingStarted : isActivating
    }

    if (data.isActivated) {
        data.activatingStarted = false
    }

    yield call(checkFeatures, data)

    yield put(update(data))
}

fetchIsOnline.onError = function* (error) {
    yield put(actions.setError(error))
}

function* devicesDelay({payload: {panelId}}) {
    const {pathname} = yield select((state) => state.router.location || {})
    const timeout =
        check('panel.devices', pathname) || check('panel.state', pathname)
            ? POLL_DEVICES
            : POLL_DEVICES_BACKGROUND

    yield race({
        delay: delay(timeout),
        zoneChanged: takeProcessComplete(
            [
                PROCESS_TYPE_PMAXZONEADD,
                PROCESS_TYPE_PMAXZONEREMOVE,
                PROCESS_TYPE_PMAXSETSOAKZONE,
                PROCESS_TYPE_PMAXCANCELSOAKZONE,
                PROCESS_TYPE_PMAXSETBYPASSZONE,
                PROCESS_TYPE_PMAXCLEARBYPASSZONE,
            ],
            panelId
        ),
        rssiRefreshed: takeProcessCompleteSuccessful(PROCESS_TYPE_PMAXZONERSSI, panelId),
        location: take(
            ({type, payload}) =>
                type === LOCATION_CHANGE &&
                check('panel.devices', payload && payload.pathname)
        ),
    })
}

function* statesDelay() {
    const {pathname} = yield select((state) => state.router.location || {})
    const timeout = check('panel.state', pathname) ? POLL_STATE : POLL_STATE_BACKGROUND

    yield race({
        _delay: delay(timeout),
        _break: take(({type, payload}) => {
            return (
                type === actions.skipDelayPollPanelData.toString() ||
                (type === LOCATION_CHANGE &&
                    check('panel.state', payload?.location?.pathname))
            )
        }),
    })
}

function* checkFeatures(data) {
    const panel = yield select((state) => state.panels.store.byIds[data.id])

    const isDiscoveryRuns = get(panel, 'discoveryStatus.completed', true) === false
    const isDiscoveryComplete = get(data, 'discoveryStatus.completed', true) === true

    if (isDiscoveryRuns && isDiscoveryComplete) {
        yield call(watchPanelDiscoveryComplete, {panelId: data.id})
    }
}

function* watchPollingStatus({payload: {panelId, tokens}}) {
    if (!isEmpty(tokens)) {
        const testsData = yield call(testsApi.getPanelTestsStatus, panelId, tokens)
        yield put(actions.updateTestsStatuses({panelId, testsData}))
    }
}

watchPollingStatus.onError = function* (error) {
    yield put(stopPollTestsStatuses())
}
