import React from 'react'
import classnames from 'classnames'

import Button from 'ipmp-react-ui/Button'

import __ from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import PanelBillingPlanBadge from './PanelBillingPlanBadge'
import {withPermissionVisibility} from 'containers/withPermission'
import {showPanelBillingActivateModal} from 'modules/modals/actions'
import {activate as activatePermission} from 'permissions/panel/billing/actions'

const ButtonActivate = withPermissionVisibility(activatePermission)(Button)

export default function PanelBillingPlan({
    id,
    panelId,
    name,
    description,
    active,
    requested,
}) {
    const {onClick} = useActions(
        {
            onClick: () =>
                showPanelBillingActivateModal({
                    planId: id,
                    panelId,
                }),
        },
        [id, panelId]
    )

    const buttonName = () => {
        switch (true) {
            case active:
                return __('Current plan')
            default:
                return __('Choose plan')
        }
    }

    return (
        <div
            className={classnames('panelBillingPlan', {
                'panelBillingPlan--active': active,
                'panelBillingPlan--requested': requested,
            })}
        >
            <div className="panelBillingPlan__info">
                <PanelBillingPlanBadge name={name} id={id} />
                <div className="panelBillingPlan__choose">
                    <ButtonActivate
                        primary
                        onClick={onClick}
                        disabled={active}
                        className="panelBillingPlan__choose-button"
                    >
                        {buttonName()}
                    </ButtonActivate>
                </div>
            </div>
            <p className="panelBillingPlan__description">{description}</p>
        </div>
    )
}
