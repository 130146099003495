import React from 'react'
import classNames from 'classnames'
import has from 'lodash-es/has'
import {get} from 'lodash-es'

import {MENU_TYPE} from 'constants/panelConfiguration'
import ConfigurationRow from './ConfigurationRow'

function isItemVisible({bottom, offset}, from, to) {
    return to > offset && from < bottom
}

function calculateChangedAndBackupStatus(changes, backup, itemKey) {
    return {
        hasChangedValue: changes && has(changes, itemKey),
        hasBackupValue: backup && has(backup, itemKey),
    }
}

function ConfigurationRowsList({
    items,
    from,
    to,
    changes,
    backup,
    exporting,
    featuresetValidationRules,
    isEditable,
    isShowExportCheckbox,
    isChanged,
    isChecked,
    hasUndo,
    onChange,
    onSetExport,
    onUndo,
}) {
    const visibleItems = items.filter((item) => isItemVisible(item, from, to))

    if (visibleItems.length === 0) {
        return null
    }

    const rows = visibleItems.map((item) => {
        const {key, level, node} = item
        if (node.type === MENU_TYPE) {
            return (
                <h3
                    key={key}
                    className={classNames(
                        'configuration-title',
                        `configuration-title--${level}`
                    )}
                >
                    {node.name}
                </h3>
            )
        }

        const {hasChangedValue, hasBackupValue} = calculateChangedAndBackupStatus(
            changes,
            backup,
            node.key
        )
        const validationRules = get(
            featuresetValidationRules,
            node?.validation_rule,
            null
        )
        const isExported = exporting && has(exporting, node.key)

        return (
            <ConfigurationRow
                key={node.key}
                item={{...node, validationRules}}
                isEditable={isEditable}
                isExported={isExported}
                isShowExportCheckbox={isShowExportCheckbox}
                isChanged={isChanged ? isChanged(node) : hasChangedValue}
                isChecked={isChecked ? isChecked(node) : false}
                hasUndo={hasUndo}
                changed={hasChangedValue ? changes[node.key] : undefined}
                backup={hasBackupValue ? backup[node.key] : undefined}
                onChange={onChange}
                onSetExport={onSetExport}
                onUndo={onUndo}
            />
        )
    })

    if (visibleItems[0] !== items[0]) {
        const firstVisibleOffset =
            visibleItems[0].offset -
            items[0].offset +
            (items[0].node.type === MENU_TYPE ? 16 : 0)

        rows.unshift(
            <div
                key="stub"
                className="configuration-stub"
                style={{height: `${firstVisibleOffset}px`}}
            />
        )
    }

    return <>{rows}</>
}

export default ConfigurationRowsList
