import {put, select} from 'redux-saga/effects'

import {selectMessageIsHandled} from '../selectors'
import * as actions from '../actions'

import EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION from './EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION'
import EVENT_ADD_SENSOR_CONFIRMATION from './EVENT_ADD_SENSOR_CONFIRMATION'
import EVENT_ZWAVE_DSK_DEVICE_ENROLMENT from './EVENT_ZWAVE_DSK_DEVICE_ENROLMENT'
import EVENT_PANEL_EVENT from './EVENT_PANEL_EVENT'
import EVENT_NEW_SECURITY_DEVICE_ADDED from './EVENT_NEW_SECURITY_DEVICE_ADDED'
import EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE from './EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE'
import EVENT_START_ZONE_DISCOVERY from './EVENT_START_ZONE_DISCOVERY'
import EVENT_NOTIFICATION_MESSAGE from './EVENT_NOTIFICATION_MESSAGE'

const handlers = [
    EVENT_AUTOMATION_DEVICE_ADDED_NOTIFICATION,
    EVENT_ADD_SENSOR_CONFIRMATION,
    EVENT_ZWAVE_DSK_DEVICE_ENROLMENT,
    EVENT_PANEL_EVENT,
    EVENT_NEW_SECURITY_DEVICE_ADDED,
    EVENT_AUTOMATION_DEVICE_AUTOLEARN_RESPONSE,
    EVENT_START_ZONE_DISCOVERY,
    EVENT_NOTIFICATION_MESSAGE,
]

export default function* handleMessage(session, message) {
    if (!('requestId' in message)) {
        message.requestId = btoa(JSON.stringify(message))
    }

    const isHandled = yield select((state) =>
        selectMessageIsHandled(state, {session, id: message.requestId})
    )
    if (isHandled) {
        return
    }

    for (const handler of handlers) {
        yield handler(session, message)
    }

    yield put(actions.addMessage(session, message))
}
