import {takeEvery, call, put, all} from 'redux-saga/effects'

import * as api from 'api/panel/billing'
import {POLL_BILLING_STATUS} from 'configs/pollers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import watch from 'modules/higherOrder/watch'

import * as actions from './actions'
import {ACTION_NOT_SUPPORTED} from 'constants/errorType'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watch(fetch)),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_BILLING_STATUS, fetch),
    ])
}

function* fetch({payload: panelId}) {
    try {
        const status = yield call(api.status, panelId)
        yield put(actions.receive(status, panelId))
    } catch (e) {
        if (e.reason !== ACTION_NOT_SUPPORTED) {
            throw e
        }

        yield put(actions.stopPoll())
    }
}

fetch.onError = function* (error, {payload: panelId}) {
    yield put(actions.receive(error, panelId))
}
