import {Children} from 'react'
import PropTypes from 'prop-types'

import map from 'lodash-es/map'

const Each = ({render, of}) => {
    if (of.length === 0) {
        return null
    }

    return Children.toArray(
        map(of, (item, index) => {
            return render(item, index)
        })
    )
}

Each.propTypes = {
    render: PropTypes.func,
    of: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Each
