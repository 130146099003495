import React from 'react'
import {useSelector} from 'react-redux'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

const AuthorizationSettings = () => {
    const {first, second, email, userLifetime, installerAllowedToRemovePanels} =
        useSelector((state) => state.system.settings.authorizationSettings.data)

    return (
        <>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('First factor expiration period [Seconds]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(first.expirationPeriod)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Email code expiration period for Applications [seconds]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(email.expirationPeriod)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Email code length for Installer App')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(email.codeLength)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Account login attempts limit')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(first.maxAttemptsCount)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Account login lock timeout [seconds]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(first.timeout)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Panel login attempts limit')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(second.maxAttemptsCount)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Panel login lock timeout [Seconds]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(second.timeout)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Second factor expiration period [Seconds]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(second.expirationPeriod)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Permanent user lifetime [Days]')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(userLifetime)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Allow Installer Remove Panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(installerAllowedToRemovePanels)}
                </InfoCardTileContent>
            </InfoCardTile>
        </>
    )
}

export default AuthorizationSettings
