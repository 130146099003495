import React from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import centralStationsList from 'permissions/centralStations/list'

import {reset as resetCentralStations} from 'modules/groups/centralStations/list/actions'
import {startPoll, stopPoll} from 'modules/groups/centralStations/list/actions'

import {fetch as fetchEventProfiles} from 'modules/centralStations/profiles/actions'

import {showEditCentralStationLinksModal} from 'modules/modals/actions'

import CentralStationCard from 'components/CentralStations/Card'

import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import Error from 'ipmp-react-ui/Error'

import {__} from 'utils/i18n'
import InfoCards from 'ipmp-react-ui/InfoCards'

const Stations = ({stations, links, profiles, edit, group}) => {
    const renderList = () => {
        return Object.values(stations).map((station) => (
            <CentralStationCard
                {...{
                    key: station.id,
                    edit,
                    station,
                    profiles,
                    links: links[station.id] || [],
                    group,
                }}
            />
        ))
    }

    if (!Object.values(stations).length) {
        return <Error title={__('No central stations')} />
    }

    return (
        <InfoCards cols={Object.values(stations).length > 1 ? 2 : 1}>
            {renderList()}
        </InfoCards>
    )
}

Stations.propTypes = {
    group: PropTypes.object.isRequired,
    links: PropTypes.object.isRequired,
    profiles: PropTypes.object.isRequired,
}

export default compose(
    withPermissionRejection(centralStationsList),
    connect(
        ({centralStations, groups}, {group}) => ({
            isLoading:
                groups.centralStations.list.isLoading ||
                centralStations.profiles.isLoading,
            error: groups.centralStations.list.error || centralStations.profiles.error,

            stations: groups.centralStations.store.byIds,
            links: groups.links[group.id],
            profiles: centralStations.profiles.byId,
        }),

        (dispatch, {group}) =>
            bindActionCreators(
                {
                    edit: (station) =>
                        showEditCentralStationLinksModal(group.id, station.id),
                    resetCentralStations,
                    fetchEventProfiles,
                    startPoll,
                    stopPoll,
                },
                dispatch
            )
    ),
    withLoader(
        ({resetCentralStations, fetchEventProfiles, startPoll}) => {
            resetCentralStations()
            fetchEventProfiles()
            startPoll()
        },
        ({stopPoll}) => {
            stopPoll()
        }
    )
)(Stations)
