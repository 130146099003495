import React from 'react'

import path from 'utils/path'
import GroupsPage from './GroupsPage'

export default class {
    path = path('groups')

    element = (<GroupsPage />)
}
