import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import classNames from 'classnames'

import Spinner from 'ipmp-react-ui/Spinner'
import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import __ from 'utils/i18n'
import {selectAddedDevices} from 'modules/devices/autolearn/selectors'

import AddedDevicesTable from './AddedDevicesTable'

export default function SessionContentSecurity({session}) {
    const devices = useSelector((state) => selectAddedDevices(state, {session}))
    const hasTable = devices && devices.length > 0

    return (
        <>
            <div
                className={classNames({
                    'autolearn-with-table': hasTable,
                    'autolearn-in-process': !hasTable,
                })}
            >
                <div className="autolearn-description-content">
                    <IconAutolearn />
                    <div className="autolearn-description-content-wrapper">
                        <div className="autolearn-description-content-title">
                            {__('Enroll security devices')}
                        </div>
                        <div className="autolearn-description-content-message">
                            <p>
                                {__(
                                    'Press and hold Enroll button for 5 sec for PowerG devices.'
                                )}
                            </p>
                            <p>{__('Open/Close or Tamper for SRF devices.')}</p>
                            <p>
                                {__(
                                    'It can take up to 5 min to detect and enroll MX devices.'
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <Spinner />
            </div>
            {devices && devices.length > 0 && (
                <div className="autolearned-devices-table">
                    <div className="autolearned-devices-table-title">
                        {__('Autolearn status')}
                    </div>
                    <AddedDevicesTable devices={devices} />
                </div>
            )}
        </>
    )
}

SessionContentSecurity.propTypes = {
    session: PropTypes.shape({
        protocol: PropTypes.number.isRequired,
    }).isRequired,
}
