import __ from 'utils/i18n'
import list from 'permissions/centralStations/list'
import groupsList from 'permissions/groups/list'

export const CENTRAL_STATION_TAB_GENERAL = 'general'
export const CENTRAL_STATION_TAB_COMMUNICATION = 'communication'

const tabs = new Map([
    [
        CENTRAL_STATION_TAB_GENERAL,
        {
            label: () => __('General'),
            path: 'centralStation.general',
            end: true,
            isVisiblePermission: list,
        },
    ],
    [
        CENTRAL_STATION_TAB_COMMUNICATION,
        {
            label: () => __('Communication'),
            path: 'centralStation.communication',
            isVisiblePermission: groupsList,
        },
    ],
])

export default tabs
