import React from 'react'

import Table from 'ipmp-react-ui/Table'
import Definition from 'ipmp-react-ui/Definition'

import Empty from 'ui/Empty'

const columns = [
    {
        render: ({serial, account}) => <Definition title={serial} detail={account} />,
    },
    {
        render: ({group}) => group || <Empty />,
    },
    {
        render: ({model}) => model || <Empty />,
    },
]

export default function PanelsTable({fullHeight, className, rows}) {
    return (
        <Table
            rows={rows}
            columns={columns}
            className={className}
            fullHeight={fullHeight}
        />
    )
}
