import React from 'react'

import {ReactComponent as DefaultHadIcon} from 'assets/devices/z-wave/light.svg'
import {ReactComponent as SwitcherIcon} from 'assets/had/Switcher.svg'
import {ReactComponent as WaterValveIcon} from 'assets/had/Water_valve.svg'
import {ReactComponent as GarageDoorIcon} from 'assets/had/Garage_Door.svg'
import {ReactComponent as LightIcon} from 'assets/had/Light.svg'
import {ReactComponent as GenericIcon} from 'assets/had/Generic.svg'
import {ReactComponent as DisarmIcon} from 'assets/had/Disarm.svg'
import {ReactComponent as ArmModeIcon} from 'assets/had/Arm_Mode.svg'
import {ReactComponent as ArmToggleIcon} from 'assets/had/Arm_Toggle.svg'

import {ReactComponent as DisabledWaterValveIcon} from 'assets/had/disabled/Water-valve_disabled.svg'
import {ReactComponent as DisabledGarageDoorIcon} from 'assets/had/disabled/Garage_Door_disabled.svg'
import {ReactComponent as DisabledLightIcon} from 'assets/had/disabled/Light_disabled.svg'
import {ReactComponent as DisabledGenericIcon} from 'assets/had/disabled/Generic_disabled.svg'
import {ReactComponent as DisabledDisarmIcon} from 'assets/had/disabled/Disarm_disabled.svg'
import {ReactComponent as DisabledArmModeIcon} from 'assets/had/disabled/Arm-mode_disabled.svg'
import {ReactComponent as DisabledArmToggleIcon} from 'assets/had/disabled/Arm-toggle_disabled.svg'
import {ReactComponent as DisabledSwitcherIcon} from 'assets/had/disabled/Switcher_disabled.svg'

import {activeImages, deviceSubtypeMap, images} from './deviceSubtype'
import thermostatTypeStateTitle, {
    HAD_DEVICE_THERMOSTAT_MODE_OFF,
} from 'constants/had/thermostat'
import doorlockTypeStateTitle from 'constants/had/doorlock'
import switcherTypeStateTitle from 'constants/had/switcher'
import {warn} from 'utils/log'
import __, {__n} from 'utils/i18n'

import resolveMapTranslation from 'constants/utils/resolveMapTranslation'
import {
    HAD_DOORLOCK_JAMMED,
    HAD_DOORLOCK_LOCKED,
    HAD_STATE_OFF,
    HAD_STATE_ON,
    HAD_WATER_VALVE_OPENED,
} from './had/hadState'
import waterValveTypeStateTitle from './had/waterValve'

export const HAD_OPTION_TYPE_BOOLEAN = 'boolean'
export const HAD_OPTION_TYPE_NUMBER = 'number'
export const HAD_OPTION_TYPE_UNSIGNED_8 = 'unsigned [8]'
export const HAD_OPTION_TYPE_NUMBER_ARRAY = 'number []'
export const HAD_OPTION_TYPE_BOOLEAN_8 = 'boolean [8]'

export const HAD_CATEGORY_PGM = 'PGM'
export const HAD_CATEGORY_OUTPUT = 'OUTPUT'
export const HAD_CATEGORY_COMMAND_OUTPUT = 'COMMAND_OUTPUT'
export const HAD_CATEGORY_DUAL_PATH_OUTPUT = 'DUAL_PATH_OUTPUT'
export const HAD_CATEGORY_DUAL_PATH_INPUT = 'DUAL_PATH_INPUT'
export const HAD_CATEGORY_INPUT = 'INPUT'
export const HAD_CATEGORY_Z_WAVE = 'IQ_HAD'
// synthetic device type that does not exist on server. Included to provide grouping on automation device screen
export const HAD_CATEGORY_PG_AUTOMATION = 'PG_AUTOMATION'

export const HAD_TYPE_PGM = 'PGM'
export const HAD_TYPE_PGM_ON_PANEL = 'PGM_ON_PANEL'
export const HAD_TYPE_PGM_ON_ZONE = 'PGM_ON_ZONE'
export const HAD_TYPE_SWITCHER = 0
export const HAD_TYPE_COMMAND_OUTPUT = 'COMMAND_OUTPUT'
export const HAD_TYPE_DUALPATH_AUTOMATION_BUTTON = 'DUALPATH_AUTOMATION_BUTTON'
export const HAD_TYPE_AUTOMATION_INPUT = 'AUTOMATION_INPUT'
export const HAD_TYPE_LEEDARSON_LIGHT_SWITCH = 'LEEDARSON_LIGHT_SWITCH_PG_PLUS'
export const HAD_TYPE_STATELESS_SWITCHER = 1
export const HAD_TYPE_DIMMER = 'ZWAVE_DIMMER'
export const HAD_TYPE_LEEDARSON_DIMMER = 'LEEDARSON_DIMMER_SWITCH_PG_PLUS'
export const HAD_TYPE_DOORLOCK = 'ZWAVE_DOOR_LOCK'
export const HAD_TYPE_YALE_DOORLOCK_L2 = 'YALE_DOORLOCK_L2_PG_PLUS'
export const HAD_TYPE_YALE_DOORLOCK_L3 = 'YALE_DOORLOCK_L3_PG_PLUS'
export const HAD_TYPE_IQ_SMART_DOORLOCK = 'IQ_SMART_DOORLOCK_PG_PLUS'
export const HAD_TYPE_PG_PLUS_DOORLOCK = 'PG_PLUS_DOOR_LOCK'
export const HAD_TYPE_THERMOSTAT = 'ZWAVE_THERMOSTAT'
export const HAD_TYPE_SOCKET = 5
export const HAD_TYPE_ZWAVE_SMART_SOCKET = 'ZWAVE_SMART_SOCKET'
export const HAD_TYPE_LEEDARSON_SOCKET = 'LEEDARSON_SOCKET_PG_PLUS'
export const HAD_TYPE_ZWAVE_LIGHT = 'ZWAVE_LIGHT'
export const HAD_TYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS =
    'LEEDARSON_INDOOR_AC_PLUG_PG_PLUS'
export const HAD_TYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS =
    'LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS'
export const HAD_TYPE_ZWAVE_WATER_VALVE = 'ZWAVE_WATER_VALVE'
export const HAD_TYPE_PG_PLUS_WATER_VALVE = 'PG_PLUS_WATER_VALVE'

export const HAD_STATE_PARAMETER_NEVER_TURN = 'NEVER_TURN'
export const HAD_STATE_PARAMETER_CUSTOM = 'CUSTOM'
export const HAD_STATE_PARAMETER_CUSTOM_MAXIMUM_TIMEOUT = 1092
export const HAD_STATE_PARAMETER_ON_1 = 1
export const HAD_STATE_PARAMETER_ON_5 = 5
export const HAD_STATE_PARAMETER_ON_10 = 10
export const HAD_STATE_PARAMETER_ON_30 = 30
export const HAD_STATE_PARAMETER_ON_60 = 60

export const SUPPORTED_COMMAND_HAD_OUTPUT = 0

export const HAD_CONFIGURABLE_ICON_LIGHT = 'light'
export const HAD_CONFIGURABLE_ICON_SWITCHER = 'switch'
export const HAD_CONFIGURABLE_ICON_WATER_VALVE = 'water'
export const HAD_CONFIGURABLE_ICON_GARAGE_DOOR = 'gate'
export const HAD_CONFIGURABLE_ICON_GENERIC = 'pgm'
export const HAD_CONFIGURABLE_ICON_ARM_MODE = 'arm_mode'
export const HAD_CONFIGURABLE_ICON_ARM_TOGGLE = 'arm_toggle'
export const HAD_CONFIGURABLE_ICON_DISARM = 'disarm'

export const hadTypes = [
    HAD_TYPE_PGM,
    HAD_TYPE_SWITCHER,
    HAD_TYPE_STATELESS_SWITCHER,
    HAD_TYPE_DIMMER,
    HAD_TYPE_LEEDARSON_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_YALE_DOORLOCK_L2,
    HAD_TYPE_YALE_DOORLOCK_L3,
    HAD_TYPE_IQ_SMART_DOORLOCK,
    HAD_TYPE_PG_PLUS_DOORLOCK,
    HAD_TYPE_THERMOSTAT,
    HAD_TYPE_SOCKET,
    HAD_TYPE_ZWAVE_WATER_VALVE,
    HAD_TYPE_PG_PLUS_WATER_VALVE,
]

export const categories = {
    [HAD_CATEGORY_PGM]: {deviceType: HAD_CATEGORY_PGM},
    [HAD_CATEGORY_OUTPUT]: {deviceType: HAD_CATEGORY_OUTPUT},
    [HAD_CATEGORY_COMMAND_OUTPUT]: {deviceType: HAD_CATEGORY_COMMAND_OUTPUT},
    [HAD_CATEGORY_DUAL_PATH_OUTPUT]: {deviceType: HAD_CATEGORY_DUAL_PATH_OUTPUT},
    [HAD_CATEGORY_INPUT]: {deviceType: HAD_CATEGORY_INPUT},
    [HAD_CATEGORY_DUAL_PATH_INPUT]: {deviceType: HAD_CATEGORY_DUAL_PATH_INPUT},
    // Be careful with the order as the device list reduces in order to this list
    // So the list will first extract PG Automation devices, then it assumes that all the rest are Z-wave devices
    [HAD_CATEGORY_PG_AUTOMATION]: {
        deviceType: HAD_CATEGORY_Z_WAVE,
        deviceSubtypes: [
            HAD_TYPE_LEEDARSON_LIGHT_SWITCH,
            HAD_TYPE_YALE_DOORLOCK_L2,
            HAD_TYPE_YALE_DOORLOCK_L3,
            HAD_TYPE_IQ_SMART_DOORLOCK,
            HAD_TYPE_PG_PLUS_DOORLOCK,
            HAD_TYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS,
            HAD_TYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS,
            HAD_TYPE_PG_PLUS_WATER_VALVE,
            HAD_TYPE_LEEDARSON_SOCKET,
            HAD_TYPE_LEEDARSON_DIMMER,
        ],
    },
    [HAD_CATEGORY_Z_WAVE]: {deviceType: HAD_CATEGORY_Z_WAVE},
}

export const canDisableSwitchCategories = [
    HAD_CATEGORY_PGM,
    HAD_CATEGORY_OUTPUT,
    HAD_CATEGORY_COMMAND_OUTPUT,
    HAD_CATEGORY_DUAL_PATH_OUTPUT,
    HAD_CATEGORY_INPUT,
    HAD_CATEGORY_DUAL_PATH_INPUT,
    HAD_CATEGORY_Z_WAVE,
    HAD_CATEGORY_PG_AUTOMATION,
]

export const configurableCategories = [
    HAD_CATEGORY_PGM,
    HAD_CATEGORY_OUTPUT,
    HAD_CATEGORY_COMMAND_OUTPUT,
    HAD_CATEGORY_DUAL_PATH_OUTPUT,
    HAD_CATEGORY_INPUT,
    HAD_CATEGORY_DUAL_PATH_INPUT,
    HAD_CATEGORY_Z_WAVE,
]

const hadCategoryMap = new Map([
    [HAD_CATEGORY_PGM, () => __('Pgm')],
    [HAD_CATEGORY_OUTPUT, () => __('Output')],
    [HAD_CATEGORY_COMMAND_OUTPUT, () => __('Output')],
    [HAD_CATEGORY_DUAL_PATH_OUTPUT, () => __('Output')],
    [HAD_CATEGORY_INPUT, () => __('Input')],
    [HAD_CATEGORY_DUAL_PATH_INPUT, () => __('Input')],
    [HAD_CATEGORY_Z_WAVE, () => __('Z-Wave')],
    [HAD_CATEGORY_PG_AUTOMATION, () => __('PowerG Automation')],
])

export const hadCategory = (category) => {
    let result = hadCategoryMap.get(category)

    if (!result) {
        warn(`No home automation category title for: ${category}`)
        result = () => category
    }

    return result()
}

const hadTitleMap = new Map([
    [HAD_TYPE_PGM, () => __('Generic')],
    [HAD_TYPE_SWITCHER, () => __('Switcher')],
    [HAD_TYPE_STATELESS_SWITCHER, () => __('Stateless Switcher')],
    [HAD_TYPE_DIMMER, () => __('Light')],
    [HAD_TYPE_DOORLOCK, () => __('Doorlock')],
    [HAD_TYPE_THERMOSTAT, () => __('Thermostat')],
    [HAD_TYPE_SOCKET, () => __('Socket')],
])

export const hadTitle = (hadType) => {
    let result = deviceSubtypeMap.get(hadType)

    if (!result) {
        warn(`No title for home automation device type: ${hadType}`)
        result = hadTitleMap.get(HAD_TYPE_PGM)
    }

    return result()
}

export const hadConfigurableIcons = {
    [HAD_CONFIGURABLE_ICON_LIGHT]: <LightIcon />,
    [HAD_CONFIGURABLE_ICON_SWITCHER]: <SwitcherIcon />,
    [HAD_CONFIGURABLE_ICON_WATER_VALVE]: <WaterValveIcon />,
    [HAD_CONFIGURABLE_ICON_GARAGE_DOOR]: <GarageDoorIcon />,
    [HAD_CONFIGURABLE_ICON_GENERIC]: <GenericIcon />,
    [HAD_CONFIGURABLE_ICON_ARM_MODE]: <ArmModeIcon />,
    [HAD_CONFIGURABLE_ICON_ARM_TOGGLE]: <ArmToggleIcon />,
    [HAD_CONFIGURABLE_ICON_DISARM]: <DisarmIcon />,
}

export const hadDisabledConfigurableIcons = {
    [HAD_CONFIGURABLE_ICON_LIGHT]: <DisabledLightIcon />,
    [HAD_CONFIGURABLE_ICON_SWITCHER]: <DisabledSwitcherIcon />,
    [HAD_CONFIGURABLE_ICON_WATER_VALVE]: <DisabledWaterValveIcon />,
    [HAD_CONFIGURABLE_ICON_GARAGE_DOOR]: <DisabledGarageDoorIcon />,
    [HAD_CONFIGURABLE_ICON_GENERIC]: <DisabledGenericIcon />,
    [HAD_CONFIGURABLE_ICON_ARM_MODE]: <DisabledArmModeIcon />,
    [HAD_CONFIGURABLE_ICON_ARM_TOGGLE]: <DisabledArmToggleIcon />,
    [HAD_CONFIGURABLE_ICON_DISARM]: <DisabledDisarmIcon />,
}

export const hadConfigurableIconValues = [
    HAD_CONFIGURABLE_ICON_LIGHT,
    HAD_CONFIGURABLE_ICON_SWITCHER,
    HAD_CONFIGURABLE_ICON_WATER_VALVE,
    HAD_CONFIGURABLE_ICON_GARAGE_DOOR,
    HAD_CONFIGURABLE_ICON_GENERIC,
    HAD_CONFIGURABLE_ICON_ARM_MODE,
    HAD_CONFIGURABLE_ICON_ARM_TOGGLE,
    HAD_CONFIGURABLE_ICON_DISARM,
]

export const hadConfigurableIconTitles = {
    [HAD_CONFIGURABLE_ICON_LIGHT]: () => __('Light'),
    [HAD_CONFIGURABLE_ICON_SWITCHER]: () => __('Switcher'),
    [HAD_CONFIGURABLE_ICON_WATER_VALVE]: () => __('Water Valve'),
    [HAD_CONFIGURABLE_ICON_GARAGE_DOOR]: () => __('Garage Door'),
    [HAD_CONFIGURABLE_ICON_GENERIC]: () => __('Generic'),
    [HAD_CONFIGURABLE_ICON_ARM_MODE]: () => __('Arm Mode'),
    [HAD_CONFIGURABLE_ICON_ARM_TOGGLE]: () => __('Arm Toggle'),
    [HAD_CONFIGURABLE_ICON_DISARM]: () => __('Disarm'),
}

export const isHadConfigurable = (had) => configurableCategories.includes(had?.deviceType)

export const isHadIconConfigurable = (type) => type !== HAD_CATEGORY_Z_WAVE

export const hadConfigurableIcon = (key, state) => {
    const disabled = state?.pgm === HAD_STATE_OFF
    let result = disabled ? hadDisabledConfigurableIcons[key] : hadConfigurableIcons[key]

    if (!result) {
        warn(`No icon for home automation device: ${key}`)
        result = hadConfigurableIcons[HAD_CONFIGURABLE_ICON_GENERIC]
    }

    return result
}

export const validConfigurableIconValue = (value) =>
    hadConfigurableIconValues.includes(value) ? value : HAD_CONFIGURABLE_ICON_GENERIC

export const hadConfigurableIconTitle = (key) => {
    let result = hadConfigurableIconTitles[key]

    if (!result) {
        warn(`No title for home automation device user type: ${key}`)
        result = hadConfigurableIconTitles[HAD_CONFIGURABLE_ICON_GENERIC]
    }

    return result()
}

export const hadStateChangeParams = [
    HAD_STATE_PARAMETER_NEVER_TURN,
    HAD_STATE_PARAMETER_ON_1,
    HAD_STATE_PARAMETER_ON_5,
    HAD_STATE_PARAMETER_ON_10,
    HAD_STATE_PARAMETER_ON_30,
    HAD_STATE_PARAMETER_ON_60,
    HAD_STATE_PARAMETER_CUSTOM,
]

export const hadIcon = (subtype, type, state) => {
    let result

    if (hadStateIsActive(subtype || type, state)) {
        result = activeImages.get(subtype)
    }

    if (!result) {
        result = images.get(subtype)
    }

    if (!result) {
        warn(`No icon for home automation device type: ${subtype}`)
        result = <DefaultHadIcon />
    }

    return result
}

export const isParameterCustom = (parameter) => HAD_STATE_PARAMETER_CUSTOM === parameter

const forNMinutes = (n) => __n('On for %d minute', 'On for %d minutes', n)

export const pgmStateParamsTitleMap = new Map([
    [HAD_STATE_PARAMETER_NEVER_TURN, () => __('Never Turn Off')],
    [HAD_STATE_PARAMETER_ON_1, () => forNMinutes(HAD_STATE_PARAMETER_ON_1)],
    [HAD_STATE_PARAMETER_ON_5, () => forNMinutes(HAD_STATE_PARAMETER_ON_5)],
    [HAD_STATE_PARAMETER_ON_10, () => forNMinutes(HAD_STATE_PARAMETER_ON_10)],
    [HAD_STATE_PARAMETER_ON_30, () => forNMinutes(HAD_STATE_PARAMETER_ON_30)],
    [HAD_STATE_PARAMETER_ON_60, () => forNMinutes(HAD_STATE_PARAMETER_ON_60)],
    [HAD_STATE_PARAMETER_CUSTOM, () => __('Custom')],
])

export const pgmStateParamsTitle = resolveMapTranslation(
    pgmStateParamsTitleMap,
    (key) => {
        warn(`No had PGM state params title for key: ${key}`)
    }
)

const convertMinutesToSeconds = (minutes) => Number(minutes) * 60

export const getPgmStateParamTimestamp = ({parameters, customTime}) => {
    switch (parameters) {
        case HAD_STATE_PARAMETER_CUSTOM:
            return convertMinutesToSeconds(customTime)
        case HAD_STATE_PARAMETER_ON_1:
        case HAD_STATE_PARAMETER_ON_5:
        case HAD_STATE_PARAMETER_ON_10:
        case HAD_STATE_PARAMETER_ON_30:
        case HAD_STATE_PARAMETER_ON_60:
            return convertMinutesToSeconds(parameters)
        case HAD_STATE_PARAMETER_NEVER_TURN:
        default:
            return null
    }
}

const hadStateTitleByTypeMap = new Map([
    [HAD_TYPE_PGM, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_PGM_ON_ZONE, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_PGM_ON_PANEL, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_SWITCHER, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_COMMAND_OUTPUT, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_LEEDARSON_LIGHT_SWITCH, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_DIMMER, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_LEEDARSON_DIMMER, (state) => switcherTypeStateTitle(state)],
    [HAD_TYPE_DOORLOCK, (state) => doorlockTypeStateTitle(state?.lock?.mode)],
    [HAD_TYPE_YALE_DOORLOCK_L2, (state) => doorlockTypeStateTitle(state?.lock?.mode)],
    [HAD_TYPE_YALE_DOORLOCK_L3, (state) => doorlockTypeStateTitle(state?.lock?.mode)],
    [HAD_TYPE_IQ_SMART_DOORLOCK, (state) => doorlockTypeStateTitle(state?.lock?.mode)],
    [HAD_TYPE_PG_PLUS_DOORLOCK, (state) => doorlockTypeStateTitle(state?.lock?.mode)],
    [HAD_TYPE_THERMOSTAT, (state) => thermostatTypeStateTitle(state?.thermostat?.mode)],
    [
        HAD_TYPE_ZWAVE_WATER_VALVE,
        (state) => waterValveTypeStateTitle(state?.water_valve?.mode),
    ],
    [
        HAD_TYPE_PG_PLUS_WATER_VALVE,
        (state) => waterValveTypeStateTitle(state?.water_valve?.mode),
    ],
])

export const hadStateTitleByType = ({hadType, state}) => {
    let result = hadStateTitleByTypeMap.get(hadType)

    if (!result) {
        warn(`No had state title for home automation device type: ${hadType}`)
        result = (state) => switcherTypeStateTitle(state)
    }

    return result(state)
}

export const hadStateIsActive = (subtype, state) => {
    switch (subtype) {
        case HAD_TYPE_PGM:
        case HAD_TYPE_PGM_ON_PANEL:
        case HAD_TYPE_PGM_ON_ZONE:
        case HAD_TYPE_SWITCHER:
        case HAD_TYPE_COMMAND_OUTPUT:
        case HAD_TYPE_DUALPATH_AUTOMATION_BUTTON:
        case HAD_TYPE_LEEDARSON_LIGHT_SWITCH:
        case HAD_TYPE_DIMMER:
        case HAD_TYPE_ZWAVE_LIGHT:
        case HAD_TYPE_LEEDARSON_INDOOR_AC_PLUG_PG_PLUS:
        case HAD_TYPE_LEEDARSON_OUTDOOR_AC_PLUG_PG_PLUS:
        case HAD_TYPE_LEEDARSON_DIMMER:
        case HAD_TYPE_ZWAVE_SMART_SOCKET:
        case HAD_TYPE_LEEDARSON_SOCKET:
        case HAD_TYPE_SOCKET:
        case HAD_TYPE_AUTOMATION_INPUT:
            return state?.pgm === HAD_STATE_ON
        case HAD_TYPE_DOORLOCK:
        case HAD_TYPE_YALE_DOORLOCK_L2:
        case HAD_TYPE_YALE_DOORLOCK_L3:
        case HAD_TYPE_IQ_SMART_DOORLOCK:
        case HAD_TYPE_PG_PLUS_DOORLOCK:
            return state?.lock?.mode === HAD_DOORLOCK_LOCKED
        case HAD_TYPE_THERMOSTAT:
            return state?.thermostat?.mode !== HAD_DEVICE_THERMOSTAT_MODE_OFF
        case HAD_TYPE_ZWAVE_WATER_VALVE:
        case HAD_TYPE_PG_PLUS_WATER_VALVE:
            return state?.water_valve?.mode === HAD_WATER_VALVE_OPENED
        default:
            return false
    }
}

export const hadStateIsJammed = (subtype, state) => {
    switch (subtype) {
        case HAD_TYPE_DOORLOCK:
        case HAD_TYPE_YALE_DOORLOCK_L2:
        case HAD_TYPE_YALE_DOORLOCK_L3:
        case HAD_TYPE_IQ_SMART_DOORLOCK:
        case HAD_TYPE_PG_PLUS_DOORLOCK:
            return state?.lock?.mode === HAD_DOORLOCK_JAMMED
        default:
            return false
    }
}

export const isSwitchableCategories = (categories) =>
    categories.some((category) => canDisableSwitchCategories.includes(category))

export const isHadDisabled = ({isSwitchable, had}) => {
    return canDisableSwitchCategories.includes(had?.deviceType) && !isSwitchable
}
