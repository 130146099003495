import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch,
    refreshState,
    remove,
    startPollPanelData,
    stopPollPanelData,
} from 'modules/panels/one/actions'
import {dismissChanges, upload} from 'modules/panels/configuration/actions'
import {update} from 'modules/panels/store/actions'
import {
    showEditPanelCustomerInfoModal,
    showEditPanelInfoModal,
    showMakeBasicConfigurationModal,
} from 'modules/modals/actions'
import {remember} from 'modules/panels/recent/actions'
import {fetch as fetchFirmware} from 'modules/panels/firmware/actions'
import {fetch as fetchPanelInteractiveUsers} from 'modules/panelInteractiveUsers/list/actions'
import {selectPendingChanges} from 'modules/panels/configuration/selectors'
import {selectPanel} from 'modules/panels/store/selectors'
import {info} from 'permissions/panel/info/page'

import useRouterPanelId from '../useRouterPanelId'
import usePermission from '../usePermission'

export default function usePanel() {
    const {isPermitted: isFetchAllowed} = usePermission(info)
    const panelId = useRouterPanelId()
    const actions = useActions({
        remove,
        fetch,
        upload,
        update,
        refreshState,
        showEditPanelInfoModal,
        showEditPanelCustomerInfoModal,
        showMakeBasicConfigurationModal,
        commitChanges: upload,
        dismissChanges,
        remember,
        startPollPanelData,
        stopPollPanelData,
        fetchFirmware,
        fetchPanelInteractiveUsers,
    })

    const panel = useSelector((state) => selectPanel(state, {panelId}))
    const one = useSelector((state) => state.panels.one)
    const pending = useSelector((state) => selectPendingChanges(state, {panelId}))

    return {
        ...actions,
        panel,
        panelId,
        isLoading: !panel && !one.error,
        error: one.error,
        ...pending,
        isFetchAllowed,
        remove: () => actions.remove([panel]),
        fetch: () =>
            isFetchAllowed ? actions.fetch(panelId) : actions.update({id: panelId}),
        fetchFirmware: () => actions.fetchFirmware(panelId),
        refreshState: () => actions.refreshState(panelId),
        showEditPanelInfo: () => actions.showEditPanelInfoModal(panelId),
        showEditPanelCustomerInfo: () => actions.showEditPanelCustomerInfoModal(panelId),
        commitBasic: () => actions.showMakeBasicConfigurationModal(panelId),
        commitChanges: () => actions.upload(panelId),
        resetChanges: () => actions.dismissChanges(panelId),
        remember: ({path, masterUserToRemember}) =>
            actions.remember({panel, path, masterUserToRemember}),
        startPollPanelData: () => actions.startPollPanelData(panelId),
        stopPollPanelData: () => actions.stopPollPanelData(panelId),
        fetchPanelInteractiveUsers: () => actions.fetchPanelInteractiveUsers(),
    }
}
