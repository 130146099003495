import React from 'react'
import classes from 'classnames'
import _get from 'lodash-es/get'

import {FormRow, FormSection} from 'ipmp-react-ui/Form'
import Input from 'ipmp-react-ui/Input'

import {IQ4} from 'constants/groups'
import {__} from 'utils/i18n'

import {TabContentWrapper} from './TabContentWrapper'
import Togglebox from 'ipmp-react-ui/ToggleBox'

export default function IQ4Tab({activeTab, handleFieldChanged, settingsFibro, errors}) {
    return (
        <TabContentWrapper
            activeTab={activeTab}
            tabName={IQ4}
            styleName="communications-content"
        >
            <FormSection
                className={classes('col', {
                    'group-form-section-with-error':
                        errors?.iq4FibroGprsSupervision || errors?.iq4FibroGprsTimeout,
                })}
                title={__('Cellular')}
            >
                <Togglebox
                    name="iq4FibroGprsIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="iq4_fibro-gprs-is-enabled"
                    defaultChecked={_get(settingsFibro, 'gprs.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.iq4_fibro.gprs.isEnabled',
                            e.target.checked
                        )
                    }}
                />

                <FormRow>
                    <Input
                        name="iq4FibroGprsSupervision"
                        label={__('Supervision Period')}
                        dataTestId="iq4_fibro-gprs-supervision"
                        type="number"
                        defaultValue={_get(settingsFibro, 'gprs.supervision', false)}
                        disabled={!_get(settingsFibro, 'gprs.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.iq4_fibro.gprs.supervision',
                                Number(e.target.value)
                            )
                        }}
                    />
                    <Input
                        name="iq4FibroGprsTimeout"
                        label={__('Offline Timer')}
                        dataTestId="iq4_fibro-gprs-timeout"
                        type="number"
                        defaultValue={_get(settingsFibro, 'gprs.timeout', false)}
                        disabled={!_get(settingsFibro, 'gprs.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.iq4_fibro.gprs.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
            <FormSection className="col" title={__('Broadband')}>
                <Togglebox
                    name="iq4FibroBbaIsEnabled"
                    label={__('Supervision enabled')}
                    labelOff={__('Supervision disabled')}
                    dataTestId="iq4_fibro-bba-is-enabled"
                    defaultChecked={_get(settingsFibro, 'bba.isEnabled', false)}
                    onChange={(e) => {
                        handleFieldChanged(
                            'communications.iq4_fibro.bba.isEnabled',
                            e.target.checked
                        )
                    }}
                />
                <FormRow>
                    <Input
                        name="iq4FibroBbaSupervision"
                        label={__('Supervision Period')}
                        dataTestId="iq4_fibro-bba-supervision"
                        type="number"
                        defaultValue={_get(settingsFibro, 'bba.supervision', false)}
                        disabled={!_get(settingsFibro, 'bba.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.iq4_fibro.bba.supervision',
                                Number(e.target.value)
                            )
                        }}
                    />

                    <Input
                        name="iq4FibroBbaTimeout"
                        label={__('Offline Timer')}
                        dataTestId="iq4_fibro-bba-timeout"
                        type="number"
                        defaultValue={_get(settingsFibro, 'bba.timeout', false)}
                        disabled={!_get(settingsFibro, 'bba.isEnabled', false)}
                        onChange={(e) => {
                            handleFieldChanged(
                                'communications.iq4_fibro.bba.timeout',
                                Number(e.target.value)
                            )
                        }}
                    />
                </FormRow>
            </FormSection>
        </TabContentWrapper>
    )
}
