import React from 'react'

import {ReactComponent as AddIcon} from 'ipmp-react-ui/icons/plus.svg'
import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {add} from 'permissions/settings/virtualPanelRules'
import {showAddStateTypeModal} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function AddEventRuleButton() {
    const {isPermitted} = usePermission(add)
    const onClick = useActions(showAddStateTypeModal)

    return (
        <ButtonVisibility
            Icon={AddIcon}
            onClick={onClick}
            isVisible={isPermitted}
            label={__('Add State Type')}
        />
    )
}
