import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'

import {selectPanelIdBySerial} from 'modules/panels/store/selectors'
import {deviceImage, deviceSubtype} from 'constants/deviceSubtype'
import {DELIM} from 'constants/device'

export const AutolearnDeviceCard = ({serial, sensor}) => {
    const panelId = useSelector((state) => selectPanelIdBySerial(state, {serial}))
    const {devices} = useSelector(({features}) => features.store.byIds[panelId].devices)
    const device = Object.values(devices).find(
        (device) =>
            device.enrollmentPrefix === sensor?.slice(0, 3) &&
            [7, 8].includes(sensor.length)
    )

    const deviceType = deviceSubtype(device?.name)
    const backgroundImage = `url(${deviceImage(device?.name)})`

    const digits = sensor.replace(/\D+/g, '')
    const enrollId =
        digits.length === 7
            ? [digits.slice(0, 3), DELIM, digits.slice(3)].join('')
            : sensor

    return (
        <div className="autolearn-device-card">
            <div className="autolearn-device-image" style={{backgroundImage}} />
            <div className="autolearn-device-content">
                <div className="autolearn-device-type-field">{deviceType}</div>
                <div className="autolearn-device-enroll-id-field">{enrollId}</div>
            </div>
        </div>
    )
}

AutolearnDeviceCard.propTypes = {
    serial: PropTypes.string.isRequired,
    sensor: PropTypes.string.isRequired,
}
