import React from 'react'
import PropTypes from 'prop-types'

import {ErrorWithIcon} from 'ipmp-react-ui/Error'
import VisibilityLoader from 'ipmp-react-ui/VisibilityLoader'
import {ReactComponent as IconShieldDoorLock} from 'ipmp-react-ui/icons/shield-door-lock.svg'
import {ScrollView} from 'ipmp-react-ui/Layout'

import StateDevice from 'components/Devices/StateDevice'

const PanelStateBypassDevices = ({
    devices,
    deviceBypassStates,
    isBypassAllowed,
    onBypassChange,
    ByPassAll,
    errorTitle,
    partitionId,
}) => {
    if (devices.length === 0) {
        return <ErrorWithIcon errorTitle={errorTitle} Icon={IconShieldDoorLock} />
    }

    return (
        <ScrollView>
            {ByPassAll || null}
            <div className="tabs-layout-container">
                {devices.map((device) => (
                    <VisibilityLoader
                        key={`key${partitionId}-${device.id}`}
                        containerSelector={`.tab-content${partitionId}-${device.id}`}
                    >
                        <StateDevice
                            device={device}
                            bypassEnabled={deviceBypassStates.get(device.id)}
                            isChanged={deviceBypassStates.has(device.id)}
                            onBypassChange={onBypassChange}
                            isBypassAllowed={isBypassAllowed}
                        />
                    </VisibilityLoader>
                ))}
            </div>
        </ScrollView>
    )
}

PanelStateBypassDevices.propTypes = {
    devices: PropTypes.array.isRequired,
    deviceBypassStates: PropTypes.instanceOf(Map),
    isBypassAllowed: PropTypes.bool.isRequired,
    onBypassChange: PropTypes.func.isRequired,
    ByPassAll: PropTypes.node,
}

export default PanelStateBypassDevices
