import React from 'react'
import {useSelector} from 'react-redux'
import * as PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setPanelUserPartitions} from 'modules/forms/handlers'
import PartitionsMultiSelect from 'utils/panel/PartitionsMultiSelect'
import {selectPanelFeatures} from 'modules/features/store/selectors'
import {DEFAULT_PARTITION_VALUE, preparePartitionName} from 'constants/partitions'

const rules = {
    partitions: {
        presence: {
            message: () => __('You should define partitions'),
        },
    },
}

export default function SetUserPartitions({hide, panelId, panelUserId, partitions}) {
    const {form, handle, isLoading} = useForm(setPanelUserPartitions)

    const {features} = useSelector((state) => selectPanelFeatures(state, {panelId}))
    const maxPartitions = features?.partitions?.maxPartitions || 1

    const onHandle = ({partitions}) => {
        handle({panelId, panelUserId, partitions})
    }

    const panelPartitions = useSelector(
        ({panels: {state}}) => state.byIds[panelId].partitions
    )

    let defaultPartitions = Object.keys(panelPartitions)
        .filter((panelPartition) => {
            return partitions.includes(panelPartitions[panelPartition].id.toString())
        })
        .map((partition) => preparePartitionName(panelPartitions[partition]?.id))
    if (defaultPartitions.length === 0) {
        defaultPartitions = [preparePartitionName(DEFAULT_PARTITION_VALUE)]
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            confirmOnDismiss
            onSubmit={onHandle}
            isLoading={isLoading}
            header={__('Set user partitions')}
        >
            <PartitionsMultiSelect
                label={__('Partitions')}
                name="partitions"
                panelId={panelId}
                hasSelectAll
                maxSelectOptions={maxPartitions}
                defaultValues={defaultPartitions}
            />
        </ModalCardForm>
    )
}

SetUserPartitions.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
