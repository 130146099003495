import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import * as api from 'api/had'
import * as actions from './actions'
import {getPanelId} from 'utils/panelIdMatchSelector'
import {snackShow} from 'modules/snacks'
import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_HAD_COMMAND} from 'constants/processTypes'
import generateProcess from 'modules/processes/manager/generateProcess'
import watchSubmitHadDeviceControlForm from 'modules/panels/had/watchers/watchSubmitHadDeviceControlForm'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {POLL_HAD} from 'configs/pollers'
import pollData, {pollDataSwitchable} from 'modules/panels/had/watchers/pollData'
import watchOnWithTimer from 'modules/panels/had/watchers/watchOnWithTimer'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.fetchSwitchable, watchFetchSwitchable),
        takeEvery(actions.on, watchOn),
        takeEvery(actions.lock, watchLock),
        takeEvery(actions.unlock, watchUnlock),
        takeEvery(actions.toggle, watchToggle),
        takeEvery(actions.onWithTimer, watchOnWithTimer),
        takeEvery(actions.off, watchOff),
        takeEvery(actions.openWaterValve, watchOpenWaterValve),
        takeEvery(actions.closeWaterValve, watchCloseWaterValve),
        takeEvery(submitHadDeviceControlForm.SUCCESS, watchSubmitHadDeviceControlForm),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_HAD, pollData),
        createPollerSaga(
            actions.startPollSwitchable,
            actions.stopPollSwitchable,
            POLL_HAD,
            pollDataSwitchable
        ),
        takeEveryProcessComplete(PROCESS_TYPE_HAD_COMMAND, watchHadCommandComplete),
    ])
}

function* watchFetch() {
    try {
        const panelId = yield select(getPanelId)
        const hadList = yield call(api.getHadList, panelId)
        yield put(actions.receive({hadList}))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchHadCommandComplete({panelId}) {
    try {
        const currentPanelId = yield select(getPanelId)

        if (parseInt(currentPanelId) !== panelId) {
            return
        }

        const hadList = yield call(api.getHadList, panelId)
        yield put(actions.receive({hadList}))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

function* watchFetchSwitchable() {
    try {
        const panelId = yield select(getPanelId)

        const {isSwitchable, timeSlot, isTimeSlotEnabled} = yield call(
            api.isPanelsHadSwitchable,
            panelId
        )
        yield put(actions.receiveSwitchable({isSwitchable, timeSlot, isTimeSlotEnabled}))
    } catch (error) {
        yield put(actions.receiveSwitchable(error))
    }
}

function* watchOn({payload: {id, deviceType}}) {
    const {panelId, timestamp} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.setHadOn, {id, panelId, timestamp, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchLock({payload: {id, deviceType}}) {
    const {panelId} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.setHadLock, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchUnlock({payload: {id, deviceType}}) {
    const {panelId} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.setHadUnlock, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchToggle({payload: {id, deviceType}}) {
    const {panelId} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.setHadToggle, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchOff({payload: {id, deviceType}}) {
    const panelId = yield select((state) => getPanelId(state))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)

        yield execute(api.setHadOff, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchOpenWaterValve({payload: {id, deviceType}}) {
    const {panelId} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.openWaterValve, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}

function* watchCloseWaterValve({payload: {id, deviceType}}) {
    const {panelId} = yield select((state) => ({
        panelId: getPanelId(state),
    }))
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_HAD_COMMAND,
        panelId
    )

    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_HAD_COMMAND, panelId)
        yield execute(api.closeWaterValve, {id, panelId, batchId, deviceType})
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
