import React from 'react'

import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconDownloadOutline} from 'ipmp-react-ui/icons/download.svg'

import {showExportConfigurationModal} from 'modules/modals/actions'
import {__} from 'utils/i18n'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'
import {makeExport} from 'permissions/panel/configuration/actions'

const ExportCell = ({row, panelId}) => {
    const {showModal} = useActions({
        showModal: () => showExportConfigurationModal(row.id, panelId),
    })

    const {isPermitted} = usePermission(makeExport)

    const handleClick = (e) => {
        e.stopPropagation()
        showModal()
    }

    if (!isPermitted) {
        return null
    }

    return (
        <Tooltip tooltip={__('Export')} onClick={handleClick}>
            <IconDownloadOutline className="configurations-icon" />
        </Tooltip>
    )
}

export default ExportCell
