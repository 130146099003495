import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ipmp-react-ui/Button'

import {__} from 'utils/i18n'

const SaveButton = ({label, className, isDisabled = false}) => {
    const onClick = () => {
        document
            .querySelector('.blindModal form')
            .dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
    }

    return (
        <Button
            className={className}
            primary
            label={label || __('Save')}
            onClick={onClick}
            disabled={isDisabled}
        />
    )
}

SaveButton.propTypes = {
    label: PropTypes.string,
    className: PropTypes.string,
}

export default SaveButton
