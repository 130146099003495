import {all} from 'redux-saga/effects'

import list from './list/saga'
import walktest from './walktest/saga'
import vod from './vod/saga'
import meteo from './meteo/saga'
import autolearn from './autolearn/saga'

export default function* () {
    yield all([list(), walktest(), vod(), meteo(), autolearn()])
}
