import isNil from 'lodash-es/isNil'
import {
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
} from 'constants/had/thermostat'
import {DimmerLevelCommand, DimmerModeCommand} from 'constants/had/command/dimmer'
import {
    ThermostatFanModeCommand,
    ThermostatModeCommand,
    ThermostatTargetCommand,
} from 'constants/had/command/thermostat'
import {PgmCommand} from 'constants/had/command/pgm'
import {DoorlockCommand} from './command/doorlock'
import {WaterValveCommand} from './command/waterValve'

export const HAD_COMMAND_OUTPUT = 0
export const HAD_COMMAND_SET_THERMOSTAT_TARGET = 1
export const HAD_COMMAND_SET_THERMOSTAT_MODE = 2
export const HAD_COMMAND_SET_DIM = 3
export const HAD_COMMAND_SET_DOORLOCK = 4
export const HAD_COMMAND_SET_THERMOSTAT_FAN_MODE = 5
export const HAD_COMMAND_SET_WATER_VALVE_MODE = 6

export const isThermostatModeChangeSupported = (supportedCommands) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_THERMOSTAT_MODE)
}

export const isThermostatFanModeChangeSupported = (supportedCommands) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_THERMOSTAT_FAN_MODE)
}

export const isThermostatTargetChangeSupported = (supportedCommands) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_THERMOSTAT_TARGET)
}

export const isThermostatFanModeSupported = (supportedCommands) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_THERMOSTAT_FAN_MODE)
}

export const isWaterValveModeSupported = (supportedCommands) => {
    return (supportedCommands || []).includes(HAD_COMMAND_SET_WATER_VALVE_MODE)
}

export const resolvePgmCommands = ({supportedCommands, mode, timestamp}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(HAD_COMMAND_OUTPUT)

    if (isCommandChangeModeSupported) {
        result.push(
            new PgmCommand({
                command: HAD_COMMAND_OUTPUT,
                mode,
                timestamp,
            })
        )
    }

    return result
}

export const resolveDoorlockCommands = ({supportedCommands, mode}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_DOORLOCK
    )

    if (isCommandChangeModeSupported) {
        result.push(
            new DoorlockCommand({
                command: HAD_COMMAND_SET_DOORLOCK,
                mode,
            })
        )
    }

    return result
}

export const resolveThermostatCommands = ({
    thermostatMode,
    thermostatFanMode,
    targetHeat,
    targetCool,
    supportedCommands,
}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_THERMOSTAT_MODE
    )
    const isCommandChangeFanModeSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_THERMOSTAT_FAN_MODE
    )
    const isCommandChangeTargetSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_THERMOSTAT_TARGET
    )

    if (!isNil(thermostatMode) && isCommandChangeModeSupported) {
        result.push(
            new ThermostatModeCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_MODE,
                mode: thermostatMode,
            })
        )
    }
    if (!isNil(thermostatFanMode) && isCommandChangeFanModeSupported) {
        result.push(
            new ThermostatFanModeCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_FAN_MODE,
                mode: thermostatFanMode,
            })
        )
    }
    if (!isNil(targetHeat) && !isNaN(targetHeat) && isCommandChangeTargetSupported) {
        result.push(
            new ThermostatTargetCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_TARGET,
                target: targetHeat,
                mode: HAD_DEVICE_THERMOSTAT_MODE_HEAT,
            })
        )
    }
    if (!isNil(targetCool) && !isNaN(targetCool) && isCommandChangeTargetSupported) {
        result.push(
            new ThermostatTargetCommand({
                command: HAD_COMMAND_SET_THERMOSTAT_TARGET,
                target: targetCool,
                mode: HAD_DEVICE_THERMOSTAT_MODE_COOL,
            })
        )
    }

    return result
}

export const resolveDimmerCommands = ({dimmerMode, dimmerLevel, supportedCommands}) => {
    const isCommandLevelSupported = supportedCommands?.includes(HAD_COMMAND_SET_DIM)
    const isCommandChangeModeSupported = supportedCommands?.includes(HAD_COMMAND_OUTPUT)
    const isChangedLevel = !isNil(dimmerLevel)
    const isChangedMode = !isNil(dimmerMode)
    const result = []

    if (isChangedLevel && isCommandLevelSupported) {
        result.push(
            new DimmerLevelCommand({command: HAD_COMMAND_SET_DIM, level: dimmerLevel})
        )
    }
    if (isChangedMode && isCommandChangeModeSupported) {
        result.push(
            new DimmerModeCommand({command: HAD_COMMAND_OUTPUT, mode: dimmerMode})
        )
    }

    return result
}

export const resolveWaterValveCommands = ({supportedCommands, mode}) => {
    const result = []
    const isCommandChangeModeSupported = supportedCommands?.includes(
        HAD_COMMAND_SET_WATER_VALVE_MODE
    )

    if (isCommandChangeModeSupported) {
        result.push(
            new WaterValveCommand({
                command: HAD_COMMAND_SET_WATER_VALVE_MODE,
                mode,
            })
        )
    }

    return result
}
