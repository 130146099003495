import React, {Component} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Input from 'ipmp-react-ui/Input'
import __ from 'utils/i18n'
import {CardActions, CardContent, CardHeader} from 'ipmp-react-ui/Card'

import {
    authEmailPassword,
    setLoginScreenForm,
    login,
    clearErrors,
} from 'modules/auth/sign/actions'
import {FORGOT_PASSWORD_SCREEN} from 'constants/loginScreen'

const rules = {
    email: {
        presence: true,
        emailRFC: true,
    },
    password: {
        presence: true,
    },
}

class LoginForm extends Component {
    static propTypes = {
        authEmailPassword: PropTypes.func.isRequired,
        setLoginScreenForm: PropTypes.func.isRequired,
        login: PropTypes.func.isRequired,
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    state = {
        isLoading: false,
        isSubmitted: false,
        errors: false,
    }

    handleSubmit = () => {
        const {email, password, isSubmitted} = this.state
        if (!this.props.error && !isSubmitted) {
            this.props.authEmailPassword(email, password)
            this.setState({isLoading: true, isSubmitted: true})
        }
    }

    handleChangeInput = (e) => {
        const {name, value} = e.target
        this.setState(() => ({[name]: value, isSubmitted: false, errors: false}))
        this.props.clearErrors()
    }

    static getDerivedStateFromProps(props) {
        const {user, login, error} = props

        if (user) {
            login(user.email, user.password)
        }

        if (error) {
            return {isLoading: false, errors: true}
        }

        return null
    }

    handleForgot = (e) => {
        e.preventDefault()
        this.props.setLoginScreenForm(FORGOT_PASSWORD_SCREEN)
    }

    render() {
        const {error} = this.props
        const {isLoading} = this.state

        return (
            <Form
                className="card login-form"
                isLoading={isLoading}
                onSubmit={this.handleSubmit}
                errors={error && error.errors}
                rules={rules}
            >
                <CardHeader className={'login-form-header'}>
                    {__('Welcome back!')}
                </CardHeader>

                <CardContent>
                    <span className={'login-form-content-label'}>
                        {__('Please login to access your account')}
                    </span>
                    <Input
                        name="email"
                        label={__('Email')}
                        autoComplete={'off'}
                        onChange={this.handleChangeInput}
                        dataTestId="login-email-input"
                    />

                    <Input
                        name="password"
                        type="password"
                        label={__('Password')}
                        autoComplete={'off'}
                        onChange={this.handleChangeInput}
                        dataTestId="login-password-input"
                    />
                </CardContent>
                <a href="#" className="link" onClick={this.handleForgot}>
                    {__('Forgot password?')}
                </a>
                <CardActions justify>
                    <Button
                        className={'login-form-button'}
                        primary
                        disabled={isLoading ?? error}
                        type="submit"
                    >
                        {__('Log in')}
                    </Button>
                </CardActions>
            </Form>
        )
    }
}

export default connect(
    ({auth: {sign}}) => ({...sign}),
    (dispatch) =>
        bindActionCreators(
            {
                authEmailPassword,
                setLoginScreenForm,
                login,
                clearErrors,
            },
            dispatch
        )
)(LoginForm)
