import React, {useState} from 'react'
import PropTypes from 'prop-types'

import ToggleBox from 'ipmp-react-ui/ToggleBox'
import Definition from 'ipmp-react-ui/Definition'
import {ReactComponent as IconFan} from 'ipmp-react-ui/icons/fan.svg'

import {isThermostatFanModeChangeSupported} from 'constants/had/command'
import usePermission from 'hooks/usePermission'
import {thermostatFanMode} from 'permissions/panel/output/actions'
import {
    getThermostatFanToggleLabel,
    HAD_DEVICE_THERMOSTAT_FAN_MODE_AUTO,
    HAD_DEVICE_THERMOSTAT_FAN_MODE_ON,
} from 'constants/had/thermostat'
import __ from 'utils/i18n'

export default function HadThermostatFanMode({had, name = 'fan'}) {
    const isThermostatFanModeChoose = isThermostatFanModeChangeSupported(
        had?.supportedCommands
    )
    const {isAllowedFanMode} = usePermission({
        isAllowedFanMode: thermostatFanMode,
    })
    const [isAuto, setAuto] = useState(
        had?.state?.thermostat?.fanmode === HAD_DEVICE_THERMOSTAT_FAN_MODE_AUTO
    )

    const onToggleChange = (e) => {
        setAuto(e.target.checked)
    }

    const currentLabel = getThermostatFanToggleLabel(isAuto)

    return (
        isAllowedFanMode &&
        isThermostatFanModeChoose && (
            <section className="form-field thermostatFanModeChoose">
                <IconFan />
                <Definition multiLine title={__('Fan auto mode')} />
                <ToggleBox
                    checked={isAuto}
                    onChange={onToggleChange}
                    label={currentLabel()}
                />
                <input
                    name={name}
                    type="hidden"
                    value={
                        isAuto
                            ? HAD_DEVICE_THERMOSTAT_FAN_MODE_AUTO
                            : HAD_DEVICE_THERMOSTAT_FAN_MODE_ON
                    }
                />
            </section>
        )
    )
}

HadThermostatFanMode.propTypes = {
    had: PropTypes.shape({
        state: PropTypes.shape({
            thermostat: PropTypes.shape({
                limits: PropTypes.object,
            }),
        }).isRequired,
        supportedCommands: PropTypes.arrayOf(PropTypes.number),
    }),
    name: PropTypes.string,
}
