import React from 'react'
import PropTypes from 'prop-types'

import {ReactComponent as IconMinus} from 'ipmp-react-ui/icons/minus.svg'

import {useActions} from 'modules/higherOrder/useActions'
import {disconnectInteractiveUser} from 'modules/panelInteractiveUsers/store/actions'
import useRouterPanelId from 'hooks/useRouterPanelId'
import {withPermissionVisibility} from 'containers/withPermission'
import {unassign} from 'permissions/interactiveUsers/actions'
import WithConfirmationButton from 'components/Button/ButtonWithConfirmation'
import __ from 'utils/i18n'

function DisconnectButton({panelUserId, label}) {
    const {disconnectInteractiveUser: onDisconnect} = useActions({
        disconnectInteractiveUser,
    })
    const panelId = useRouterPanelId()

    return (
        <WithConfirmationButton
            Icon={IconMinus}
            flat
            small
            onClick={() => onDisconnect(panelUserId, panelId)}
            message={__('Do you really want to unassign %s?', label)}
            title={__('Unassign Customer')}
        />
    )
}

DisconnectButton.propTypes = {
    panelUserId: PropTypes.number,
    label: PropTypes.string,
}

export default withPermissionVisibility(unassign)(DisconnectButton)
