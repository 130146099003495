import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'
import classnames from 'classnames'

import {
    getThermostatTemperature,
    HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_CURRENT,
    resolveTemperatureKeyAccordingToMode,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
} from 'constants/had/thermostat'
import {selectTemperatureScale} from 'modules/settings/selectors'
import {temperatureKeyToLabel} from 'utils/temperature'
import Empty from 'ui/Empty'

const HadDeviceThermostatValuesTarget = ({mode, state}) => {
    const userTemperatureScale = useSelector(selectTemperatureScale)
    const label = temperatureKeyToLabel(useSelector(selectTemperatureScale))
    const modes = state?.thermostat?.modes[mode]

    return modes?.length
        ? modes.map((mode) => {
              const targetTemperature = getThermostatTemperature({
                  state: state,
                  userTemperatureScale,
                  tempKey: resolveTemperatureKeyAccordingToMode(mode),
              })

              return (
                  <span
                      key={mode}
                      className={classnames(
                          'device-had-controls-thermostat-temperatureContainer-target',
                          {
                              'device-had-controls-thermostat-temperatureContainer-target--heat':
                                  mode === HAD_DEVICE_THERMOSTAT_MODE_HEAT,
                              'device-had-controls-thermostat-temperatureContainer-target--cool':
                                  mode === HAD_DEVICE_THERMOSTAT_MODE_COOL,
                          }
                      )}
                  >
                      {Math.round(Number(targetTemperature))}
                      <span className="device-had-controls-thermostat-temperatureContainer-target-label">
                          {label}
                      </span>
                  </span>
              )
          })
        : null
}

const HadDeviceThermostatValues = ({had, disabled}) => {
    const userTemperatureScale = useSelector(selectTemperatureScale)
    const currentTemperature = getThermostatTemperature({
        state: had.state,
        userTemperatureScale,
        tempKey: HAD_DEVICE_THERMOSTAT_TEMPERATURE_KEY_CURRENT,
    })
    const label = temperatureKeyToLabel(userTemperatureScale)
    const mode = had?.state?.thermostat?.mode

    return (
        <div
            className={classnames('device-had-controls-thermostat', {
                'device-had-controls-thermostat--disabled': Boolean(disabled),
            })}
        >
            <span className="device-had-controls-thermostat-temperatureContainer-current">
                {!!currentTemperature && (
                    <>
                        <strong>{Math.round(Number(currentTemperature))}</strong>
                        <span className="device-had-controls-thermostat-temperatureContainer-current-label">
                            {label}
                        </span>
                    </>
                )}
                {!currentTemperature && <Empty />}
            </span>
            <span className="device-had-controls-thermostat-temperatureContainer-targetContainer">
                <HadDeviceThermostatValuesTarget mode={mode} state={had?.state} />
            </span>
        </div>
    )
}

HadDeviceThermostatValues.propTypes = {
    had: PropTypes.object,
    disabled: PropTypes.bool,
}

export default HadDeviceThermostatValues
