import React, {useEffect} from 'react'
import {useSelector} from 'react-redux'

import {BarSpace} from 'ipmp-react-ui/Bar'

import {groupsPagerSearchSelect} from 'modules/centralStations/groupsList/selectors'
import {setStart} from 'modules/centralStations/groupsList/actions'
import {useActions} from 'modules/higherOrder/useActions'
import usePerPage from 'hooks/usePerPage'
import {
    CENTRAL_STATION_COMMUNICATION_PER_PAGE_DEFAULT,
    CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS,
} from 'constants/centralStation'

import CommunicationPager from './Bar/CommunicationPager'

const CommunicationBar = () => {
    const {total, start} = useSelector(groupsPagerSearchSelect)
    const {setStart: onPageChange} = useActions({setStart})

    const {onPerPageChange, perPage} = usePerPage('centralStationCommunication')

    useEffect(() => {
        if (!perPage) {
            onPerPageChange(CENTRAL_STATION_COMMUNICATION_PER_PAGE_DEFAULT)
        }
    }, [])

    return (
        <>
            <BarSpace />
            <CommunicationPager
                total={total}
                start={start}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
                perPage={perPage}
                className="centralStation-communication-pager"
                customPerPageRows={CENTRAL_STATION_COMMUNICATION_PER_PAGE_ROWS}
            />
        </>
    )
}

export default CommunicationBar
