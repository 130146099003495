import {compose} from 'redux'

import Select from 'ipmp-react-ui/Select'

import withSelectLoader from 'containers/withSelectLoader'
import {fetchUsers} from 'modules/panels/one/actions'
import __ from 'utils/i18n'
import {deviceSubtype as deviceSubtypeName} from 'constants/deviceSubtype'

export const UserSelect = compose(
    withSelectLoader(
        (prefix, maxOptionsToShow, {panelId}) => fetchUsers(panelId),
        ({panels: {store}}, {panelId}) =>
            !(
                store &&
                store.byIds &&
                store.byIds[panelId] &&
                store.byIds[panelId].userNames
            ),
        (
            {panels: {store}, devices: {list: panels}},
            {isLoading, panelId, deviceSubtype}
        ) => {
            if (isLoading) {
                return []
            }

            const usersWithKeyfobs = Object.values(panels[panelId].byIds).reduce(
                (acc, device) => {
                    if (
                        device.subtype === deviceSubtype &&
                        device.traits &&
                        device.traits.owner
                    ) {
                        acc.push(device.traits.owner.id)
                    }

                    return acc
                },
                []
            )

            const options = Object.entries(store.byIds[panelId].userNames)
                .filter(([id]) => !usersWithKeyfobs.includes(parseInt(id)))
                .map(([id, name]) => ({value: id, label: name || __('User %s', id)}))

            return options.length
                ? options
                : [
                      {
                          label: __(
                              'All users already have %s',
                              deviceSubtypeName(deviceSubtype)
                          ),
                          disabled: true,
                      },
                  ]
        }
    )
)(Select)
