import createPermission, {any} from 'permissions/createPermission'

export const suspend = createPermission('system.customers.suspend')
export const assign = createPermission('system.customers.assign')
export const unassign = createPermission('system.customers.unassign')
export const reset = createPermission('system.customers.reset')
export const register = createPermission('system.customers.register')
export const remove = createPermission('system.customers.remove')

export default createPermission(any(suspend, remove))
