import {createSelector} from 'reselect'

import remove from 'lodash-es/remove'
import find from 'lodash-es/find'
import reduce from 'lodash/reduce'
import keys from 'lodash-es/keys'

import {categories} from 'constants/had'

import getPrevNext from 'utils/getPrevNext'

const prepareHadCategorizedList = (hadList) => {
    hadList = [...hadList]

    return reduce(
        categories,
        (acc, category, name) => {
            const filtered = remove(hadList, ({deviceType, subtype}) => {
                if (!category.deviceSubtypes) {
                    return deviceType === category.deviceType
                }

                return (
                    deviceType === category.deviceType &&
                    category.deviceSubtypes.includes(subtype)
                )
            })

            if (filtered.length > 0) {
                acc.set(name, filtered)
            }

            return acc
        },
        new Map()
    )
}

const selectHadList = createSelector(
    (state) => state.panels.had.hadList,
    (hadList) => hadList
)

export const selectHadIsSwitchable = createSelector(
    (state) => state.panels.had,
    ({isSwitchable, timeSlot, isTimeSlotEnabled}) => ({
        isSwitchable,
        timeSlot,
        isTimeSlotEnabled,
    })
)

export const selectCategorizedList = createSelector(selectHadList, (hadList) =>
    prepareHadCategorizedList(hadList || [])
)

export const selectPanelHadList = createSelector(
    (state) => state.panels.had.isLoading,
    selectCategorizedList,
    (isLoading, categorizedList) => ({
        isLoading,
        categorizedList,
    })
)

export const selectHadByIdAndDeviceType = createSelector(
    selectHadList,
    (state, had) => had,
    (hadList, idWithDeviceType) => find(hadList, {idWithDeviceType})
)

export const selectHadBlindModal = createSelector(
    selectCategorizedList,
    selectHadByIdAndDeviceType,
    (state, hadId) => hadId,
    (categorizedList, had, hadId) => ({
        hadBlindModalPrevNext: getPrevNext(
            hadId,
            categorizedList,
            keys(categories),
            'idWithDeviceType'
        ),
        had,
    })
)
