import __ from 'utils/i18n'

export const STATUS_PENDING = 'pending'
export const STATUS_REJECTED = 'rejected'
export const STATUS_ACCEPTED = 'accepted'

export default function getInstallerStatusTitle(status) {
    switch (status) {
        case STATUS_PENDING:
            return __('Pending')
        case STATUS_REJECTED:
            return __('Rejected')
        case STATUS_ACCEPTED:
            return __('Accepted')
        default:
            return __('Unknown')
    }
}
