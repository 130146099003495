import React from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {activateMasterGUIPanel} from 'modules/forms/handlers'

const rules = {
    masterUserCode: {
        presence: true,
        format: {
            pattern: /^(\d{4}|\d{6}|\d{8})$/,
            flags: 'i',
            message: () => __('The activation code should be 4/6/8 digits long'),
        },
    },
}

export default function ActivateMasterPanelGUI({unitId, hide}) {
    const {form, handle, isLoading} = useForm(activateMasterGUIPanel)

    const handler = ({masterUserCode}) => {
        handle(unitId, masterUserCode)
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            rules={rules}
            onSubmit={handler}
            isLoading={isLoading}
            submitLabel={__('Activate')}
            header={__('Master User Activation')}
        >
            <Input
                autoFocus
                name="masterUserCode"
                label={__('Master User Code')}
                maxLength="8"
                minLength="4"
                dataTestId="masterUserCode"
                type="password"
                autoComplete="off"
            />
        </ModalCardForm>
    )
}

ActivateMasterPanelGUI.propTypes = {
    unitId: PropTypes.number.isRequired,
    hide: PropTypes.func.isRequired,
}
