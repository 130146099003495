import has from 'lodash-es/has'

import BoardDateFilter from 'components/Search/Board/BoardDateFilter'
import Filter from 'components/Search/Filters/Filter'

import parse, {stringify} from 'utils/dateParser'

import __ from 'utils/i18n'
import {
    TODAY,
    YESTERDAY,
    WEEK_AGO,
    TOMORROW,
    NEXT_WEEK,
    LAST_MONTH,
    NEXT_MONTH,
    PAST_PERIOD,
} from 'constants/dateFilter'

export default class DateFilter extends Filter {
    Component = BoardDateFilter

    range = true

    uniqId = null

    constructor(name, title, period = PAST_PERIOD, range = true) {
        super(name, title)

        this.range = range
        this.period = period

        if (period === PAST_PERIOD) {
            this.items = {
                [TODAY]: __('Today'),
                [YESTERDAY]: __('Yesterday'),
                [WEEK_AGO]: __('Last Week'),
                [LAST_MONTH]: __('Last Month'),
            }
        } else {
            this.items = {
                [TODAY]: __('Today'),
                [TOMORROW]: __('Tomorrow'),
                [NEXT_WEEK]: __('Next Week'),
                [NEXT_MONTH]: __('Next Month'),
            }
        }
    }

    // For prevent duplicate filter values
    // we assume that there can be a maximum of two date filters,
    // past and future
    digest(value) {
        if (value instanceof Object) {
            return this.period + this.name + '$custom'
        }

        return this.period + this.name + value
    }

    getItems() {
        return Object.keys(this.items).map(this.createItem.bind(this))
    }

    getItemLabel(value) {
        if (has(this.items, value)) {
            return this.items[value]
        }

        return stringify(value)
    }

    createItemByPrefix(prefix) {
        const parsed = parse(prefix)

        if (parsed && parsed.from) {
            return this.createItem(parsed)
        }

        return null
    }
}
