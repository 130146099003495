import parseDate from 'api/base/parseDate'
import {post} from 'api/http'
import {get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import configurationMutate from 'utils/configuration/configurationMutate'
import dataMapper from 'utils/dataMapper'
import {mapRunners} from 'api/runners.map'

const mapConfigurationRow = dataMapper(
    ({id, date, backup, current, verified_at: verified}) => ({
        id: parseInt(id),
        date: parseDate(date),
        verified: parseDate(verified),
        backup: !!backup,
        current: !!current,
    })
)

export function one(unitId, id) {
    return get('pmaxconfig/one', {unt_id: unitId, id}).then((config) => {
        configurationMutate(config)
        return config
    })
}

export function list(panelId) {
    return get('pmaxconfig/list', {unt_id: panelId}).then(({rows, current, runner}) => ({
        runner: runner ? mapRunners(runner) : null,
        rows: rows.map(mapConfigurationRow),
    }))
}

export function refresh(panelId, batchId) {
    return post('pmaxconfig/refresh', {
        unt_id: panelId,
        batch_id: batchId,
    }).then(mapRunners)
}

export function refreshDevice({panelId, deviceType, zone, batchId}) {
    return post('pmaxconfig/refreshdevice', {
        unt_id: panelId,
        device_type: deviceType,
        zone_number: zone,
        batch_id: batchId,
    }).then(mapRunners)
}

export function refreshAll(panelIds, batchId) {
    return post('pmaxconfig/refreshall', {
        unt_id: panelIds,
        batch_id: batchId,
    })
}

export function backup(panelId, id) {
    return post('pmaxconfig/backup', {unt_id: panelId, id})
}

export function upload(panelId, changes, version, batchId) {
    const diff = Object.entries(changes)

    return post('pmaxconfig/upload', {
        unt_id: panelId,
        diff,
        version,
        batch_id: batchId,
    }).then(mapRunners)
}

export function makeBasic(panelId, name, version, values, currentConfigId) {
    const diff = Object.entries(values)
    return post('pmaxconfig/addbasic', {
        unt_id: panelId,
        diff,
        version,
        name,
        configuration_id: currentConfigId,
    }).catch(mapValidationErrors)
}
