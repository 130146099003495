import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconAdd} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {add} from 'permissions/settings/virtualPanelRules'
import {showAddEventRuleModal} from 'modules/modals/actions'

const ButtonVisibility = withVisibility()(Button)

export default function AddEventRuleButton() {
    const {isPermitted} = usePermission(add)
    const onClick = useActions(showAddEventRuleModal)

    return (
        <ButtonVisibility
            onClick={onClick}
            Icon={IconAdd}
            isVisible={isPermitted}
            label={__('Add Event Rules')}
        />
    )
}
