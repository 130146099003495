import React from 'react'

import useSelection from 'hooks/useSelection'
import SelectionDropDown from 'components/SelectionDropDown'
import useInteractiveUsers from 'hooks/pages/useInteractiveUsers'
import {withVisibility} from 'containers/withVisibility'
import usePermission from 'hooks/usePermission'
import selectionPermission from 'permissions/interactiveUsers/selection.js'

const DropDownVisibility = withVisibility()(SelectionDropDown)
export default function InteractiveUsersSelectionDropDown() {
    const {rows, total} = useInteractiveUsers()
    const {isPermitted} = usePermission(selectionPermission)
    const {selection, select, deselect, deselectAll, selectAll} = useSelection()

    return (
        <DropDownVisibility
            rows={rows}
            total={total}
            selection={selection}
            select={select}
            deselect={deselect}
            deselectAll={deselectAll}
            selectAll={selectAll}
            isVisible={isPermitted}
        />
    )
}
