import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import __ from 'utils/i18n'
import {remove} from 'modules/zoneTypes/list/actions'
import withConfirmation from 'containers/withConfirmation'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonConfirmation = withConfirmation()(Button)

export default function RemoveZoneType({row: {id, name}}) {
    const {onRemove} = useActions(
        {
            onRemove: () => remove([id]),
        },
        [id]
    )

    return (
        <ButtonConfirmation
            small
            flat
            Icon={IconRemove}
            onClick={onRemove}
            message={__(
                'If you delete this zone type, then the event rule in which it is used will also be deleted, if it exists. Do you really want to remove %s zone type?',
                name
            )}
            title={__('Remove Zone Type')}
        />
    )
}
