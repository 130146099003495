import {selectPerPage} from 'modules/settings/selectors'
import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import * as api from 'api/users'
import toIds from 'utils/toIds'
import * as actions from './actions'

import {POLL_USERS} from 'configs/pollers'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import createListSaga from 'modules/higherOrder/createListSaga'
import {update, purge} from 'modules/users/store/actions'
import {addUser} from 'modules/forms/handlers'
import {snackShow} from 'modules/snacks'

import {__} from 'utils/i18n'

const selector = (state) => ({
    ...state.users.list,
    perPage: selectPerPage(state, 'users'),
    listRoute: 'users',
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector, purge),
        createListPollerSaga(actions, POLL_USERS, watchPoll),
        takeEvery(addUser.SUCCESS, watchAddUser),
    ])
}

function* watchAddUser() {
    yield put(actions.fetch())
    yield put(snackShow(__('User was created successfully')))
}

function* watchPoll() {
    const state = yield select(selector)

    if (state.start !== 0) {
        return
    }

    const {rows, count} = yield call(api.fetch, state)

    yield put(update(rows))
    yield put(actions.receive(toIds(rows), count))
}

watchPoll.onError = function* (error) {
    yield put(actions.receive(error))
}
