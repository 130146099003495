import React from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import InfoCards from 'ipmp-react-ui/InfoCards'

import {notificationsList} from 'permissions/group/navigation'
import {withPermissionRejection} from 'containers/withPermission'

import NotificationProfile from './NotificationProfile'
import EventProfile from './EventProfile'

export function NotificationsBase({groupId}) {
    return (
        <InfoCards cols={1}>
            <EventProfile groupId={groupId} />
            <NotificationProfile groupId={groupId} />
        </InfoCards>
    )
}

NotificationsBase.propTypes = {
    groupId: PropTypes.number.isRequired,
}

const ConnectedNotifications = compose(withPermissionRejection(notificationsList))(
    NotificationsBase
)

export default function Notifications({group: {id: groupId}}) {
    return <ConnectedNotifications groupId={groupId} />
}
