import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {CONFIGURATION_SEPARATOR, MENU_TYPE} from 'constants/panelConfiguration'
import {MARGIN} from 'constants/themes'
import ConfigurationTableOfContents from './ConfigurationTableOfContents'
import {ReactComponent as Icon} from 'ipmp-react-ui/icons/drop-down.svg'
import Button from 'ipmp-react-ui/Button'

const activeLinkSelector = '.sidebar > .scroll .configuration-link-row--highlight'
const scrollableContainerSelector = '.sidebar > .scroll'
const isActiveChecker = (key, highlightKey) =>
    !!(highlightKey && highlightKey.substr(0, key.length) === key)

const ConfigurationLink = React.memo(({node, onSelect, highlightKey}) => {
    const [isActive, setIsActive] = useState(isActiveChecker(node.key, highlightKey))
    const [isOpened, setIsOpened] = useState(false)
    const linkRef = useRef(null)

    useEffect(() => {
        setIsActive(isActiveChecker(node.key, highlightKey))
    }, [highlightKey, node.key])

    useEffect(() => {
        if (isActive && linkRef.current) {
            const element = document.querySelector(activeLinkSelector)
            const elementRect = element?.getBoundingClientRect()
            const scrollContainer = document.querySelector(scrollableContainerSelector)
            const containerRect = scrollContainer?.getBoundingClientRect()

            if (
                elementRect?.top < containerRect.top + MARGIN ||
                elementRect?.bottom > containerRect?.bottom
            ) {
                scrollContainer.scrollTop += elementRect.top - containerRect.top
            }
        }
    }, [isActive])

    const nestedHighlightKey = useMemo(() => {
        return highlightKey && highlightKey.startsWith(node.key)
            ? highlightKey.substr(node.key.length + CONFIGURATION_SEPARATOR.length)
            : null
    }, [highlightKey, node.key])

    const toggle = useCallback(() => {
        setIsOpened((opened) => !opened)
    }, [])

    const onClick = useCallback(
        (e) => {
            if (linkRef.current === e.target) {
                onSelect(node.key)
            }
        },
        [node.key, onSelect]
    )

    const handleSelect = useCallback(
        (key) => {
            onSelect(`${node.key}${CONFIGURATION_SEPARATOR}${key}`)
        },
        [node.key, onSelect]
    )

    const nested = useMemo(
        () => node.val.filter(({type}) => type === MENU_TYPE),
        [node.val]
    )

    return (
        <div className="configuration-link">
            <div
                onClick={onClick}
                className={classnames('configuration-link-row', {
                    'configuration-link-row--highlight': highlightKey === node.key,
                })}
                ref={linkRef}
            >
                {nested.length > 0 && (
                    <Button
                        borderless
                        onClick={toggle}
                        className={classnames('configuration-link-button', {
                            'configuration-link-button--active': isActive || isOpened,
                        })}
                    >
                        <Icon />
                    </Button>
                )}
                {node.name}
            </div>

            {nested.length > 0 && (isActive || isOpened) && (
                <ConfigurationTableOfContents
                    nodes={nested}
                    onSelect={handleSelect}
                    highlightKey={nestedHighlightKey}
                />
            )}
        </div>
    )
})

ConfigurationLink.propTypes = {
    node: PropTypes.shape({
        key: PropTypes.string.isRequired,
    }).isRequired,
    onSelect: PropTypes.func.isRequired,
    highlightKey: PropTypes.string,
}

export default ConfigurationLink
