import React from 'react'
import {createRoot} from 'react-dom/client'
import 'setimmediate'

import createAppStore from 'modules/createAppStore'

import App from 'pages/App'

import 'css-reset-and-normalize-sass'
import 'styles/index.sass'
import 'configs/init'

const {store, history} = createAppStore()

const container = document.getElementById('root')
const root = createRoot(container)

root.render(<App store={store} history={history} />)
