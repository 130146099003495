import React from 'react'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'

import AddNotificationTypeButton from './GridBar/AddNotificationTypeButton'

export const NotificationTypesGridBar = () => (
    <Bar orientation={RIGHT}>
        <AddNotificationTypeButton />
    </Bar>
)

export default NotificationTypesGridBar
