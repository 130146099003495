import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRemove} from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'
import useGroups from 'hooks/pages/useGroups'
import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import {remove as removePermission} from 'permissions/groups/actions'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedButton = compose(
    withVisibility(),
    withSelectionHandler(),
    withConfirmation()
)(Button)

export default function RemoveGroupsButton() {
    const {remove} = useGroups()
    const {isPermitted} = usePermission(removePermission)

    return (
        <ConnectedButton
            isVisible={isPermitted}
            onClick={remove}
            Icon={IconRemove}
            title={__('Removing groups')}
            message={__('Do you really want to remove selected groups?')}
            children={__('Remove')}
        />
    )
}
