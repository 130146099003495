import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as AddIcon} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {create} from 'permissions/users/actions'
import {showAddUserModal} from 'modules/modals/actions'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'

const ButtonVisibility = withVisibility()(Button)

export default function CreateUserButton() {
    const {isPermitted} = usePermission(create)
    const onClick = useActions(showAddUserModal)

    return (
        <ButtonVisibility
            primary
            Icon={AddIcon}
            onClick={onClick}
            isVisible={isPermitted}
            label={__('Add user')}
        />
    )
}
