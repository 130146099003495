import {takeEvery, all, put, call, select} from 'redux-saga/effects'
import keyBy from 'lodash-es/keyBy'

import {scheduleInspectionByCsvFile, scheduleRri} from 'modules/forms/handlers'
import * as actions from './actions'
import * as api from 'api/remoteInspection'
import generateBatch from 'modules/batches/manager/generateBatch'
import {PROCESS_TYPE_REMOTEINSPECTION} from 'constants/processTypes'
import {snackShow} from 'modules/snacks'
import {takeEveryProcessProgress} from 'modules/processes/manager/takeProcess'
import getFailedProcesses from 'modules/processes/manager/helpers/getFailedProcesses'
import {makeProcessStubs} from 'modules/processes/manager/generateProcess'
import {ensureProcesses} from 'modules/processes/manager/ensureProcess'

export default function* () {
    yield all([
        takeEvery(actions.initiateNow, watchInitiateNow),
        takeEvery(actions.initiateNowBatch, watchInitiateNowBatch),
        takeEvery(scheduleRri.SUCCESS, watchScheduleRri),
        takeEvery(actions.cancel, watchCancel),
        takeEvery(scheduleInspectionByCsvFile.SUCCESS, watchScheduleByCsvFile),
        takeEveryProcessProgress(PROCESS_TYPE_REMOTEINSPECTION, watchProcessProgress),
    ])
}

function* watchScheduleRri({meta: {ids, date: next, repetition}}) {
    yield put(actions.update(ids.map((id) => ({id, repetition, next}))))
}

function* watchInitiateNow({payload: panelIds}) {
    const store = yield select((state) =>
        panelIds.map((id) => state.remoteInspections.store[id])
    )

    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_REMOTEINSPECTION,
        panelIds
    )
    const panelByIds = yield select((state) => state.panels.store.byIds)
    const processStubs = makeProcessStubs(
        PROCESS_TYPE_REMOTEINSPECTION,
        panelIds,
        panelByIds
    )

    yield put(
        actions.update(
            panelIds.map((id) => ({
                id,
                result: 'progress',
                progress: 0,
                process: processStubs.find((stub) => stub.panelId === id),
            }))
        )
    )

    try {
        const {processes} = yield call(api.initiateInspection, panelIds, batchId)
        const failedProcesses = getFailedProcesses(processStubs, processes)

        if (failedProcesses.length > 0) {
            yield put(
                actions.update(store.filter(({id}) => failedProcesses.includes(id)))
            )
        }

        yield ensureProcesses(
            processes.filter((process) => !failedProcesses.includes(process.id))
        )
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(store))
        yield removeBatch()
    }
}

function* watchCancel({payload: panelIds}) {
    if (!panelIds.length) {
        return
    }

    const store = yield select((state) => state.remoteInspections.store)

    yield put(
        actions.update(
            panelIds.map((id) => ({
                id,
                next: null,
                repetition: null,
            }))
        )
    )

    try {
        yield call(api.cancelInspection, panelIds)
    } catch (error) {
        yield put(
            actions.update(
                panelIds.map((id) => ({
                    id,
                    repetition: store[id].repetition,
                    next: store[id].next,
                }))
            )
        )
        yield put(snackShow(error.message))
    }
}

function* watchScheduleByCsvFile({meta}) {
    const content = keyBy(meta, 'serial')
    const store = yield select((state) => state.remoteInspections.store)

    const data = Object.values(store)
        .filter(({serial}) => !!content[serial])
        .map(({id, serial}) => {
            const {next: timestamp, repetition} = content[serial]
            const next = new Date()

            next.setTime(timestamp)

            return {id, repetition, next}
        })

    yield put(actions.update(data))
}

function* watchProcessProgress({panelId, percentage}) {
    yield put(
        actions.update({
            id: panelId,
            result: 'progress',
            progress: percentage,
        })
    )
}

// Workaround for use runners
function* watchInitiateNowBatch({payload: panelIds}) {
    const store = yield select((state) =>
        panelIds.map((id) => state.remoteInspections.store[id])
    )
    const {batchId, removeBatch} = yield generateBatch(
        PROCESS_TYPE_REMOTEINSPECTION,
        panelIds
    )

    try {
        yield call(api.initiateInspectionBatch, {panelIds, batchId})
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(store.filter((item) => !!item)))
        yield removeBatch()
    }
}
