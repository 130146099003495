import React from 'react'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import {withPermissionVisibility} from 'containers/withPermission'
import {useActions} from 'modules/higherOrder/useActions'
import {setExpirationDate} from 'permissions/panel/customers/actions'
import {showSetUserExpirationDateModal} from 'modules/modals/actions'

const ButtonVisibility = withPermissionVisibility(setExpirationDate)(Button)

export default function SetExpirationDateButton({
    panelId,
    panelUserId,
    isPinSet,
    expirationDate,
}) {
    const {onClick} = useActions(
        {
            onClick: () =>
                showSetUserExpirationDateModal({panelId, panelUserId, expirationDate}),
        },
        [expirationDate]
    )

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconEdit}
            onClick={onClick}
            disabled={!isPinSet}
        />
    )
}
