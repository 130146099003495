import {createSelector} from 'reselect'

export const selectUsersListRows = createSelector(
    (state) => state.users.list,
    (state) => state.users.store.byIds,
    (list, storeByIds) => ({
        ...list,
        rows: list.page.map((id) => storeByIds[id] || {}),
    })
)

export const selectUsersByIds = createSelector(
    (state) => state.users.store.byIds,
    (storeByIds) => storeByIds
)

export const selectUsersList = createSelector(
    (state) => state.users.list,
    (list) => list
)
