import merge from 'lodash-es/merge'

import {MENU_TYPE} from 'constants/panelConfiguration'
import resolve, {lookup} from './dependency'

export function getDependencyKeys(item, acc = {}) {
    if (!item || !item.val) {
        return {}
    }

    item.val.forEach((node) => {
        if (node.type === MENU_TYPE) {
            merge(acc, getDependencyKeys(node, acc))
        }
        merge(acc, lookup(node.dependency))
    })

    return acc
}

function _finder(item, dependencyValues, result = []) {
    if (!item || !item.val) {
        return result
    }

    item.val.forEach((node) => {
        if (node.type === MENU_TYPE) {
            _finder(node, dependencyValues, result)
        }

        if (!node.dependency) {
            return
        }

        const isResolved = resolve(node.dependency, dependencyValues)

        if (!isResolved) {
            result.push(node.key)
        }
    })

    return result
}

export default function findNotResolvedKeys(configuration, dependencyValues) {
    return _finder(configuration, dependencyValues)
}
