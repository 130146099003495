import React from 'react'
import * as PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {editHadLabel} from 'modules/forms/handlers'
import {useSelector} from 'react-redux'
import {selectDevicesLabelValidationRules} from 'modules/features/store/selectors'
import get from 'lodash-es/get'

export default function EditLabel({panelId, hadId, deviceType, defaultValue, hide}) {
    const {handle, form, isLoading} = useForm(editHadLabel)

    const onSubmit = ({label}) => {
        const payload = {panelId, hadId, deviceType, label}
        handle(payload)
    }

    const featureRules = useSelector(({features}) =>
        selectDevicesLabelValidationRules(features, panelId)
    )

    const validationRules = get(featureRules, [deviceType])

    const rules = {
        label: {
            labelByFeatureset: {
                panelId,
                maxLength: 20,
                validationRules,
            },
        },
    }

    return (
        <ModalCardForm
            onSubmit={onSubmit}
            confirmOnDismiss
            rules={rules}
            hide={hide}
            isLoading={isLoading}
            header={__('Edit label')}
            {...form}
        >
            <Input label={__('Label')} name="label" defaultValue={defaultValue} />
        </ModalCardForm>
    )
}

EditLabel.propTypes = {
    panelId: PropTypes.number.isRequired,
    hadId: PropTypes.number.isRequired,
    deviceType: PropTypes.string.isRequired,
    defaultValue: PropTypes.string,
}
