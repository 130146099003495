import {createAction} from 'redux-actions'

export const receive = createAction('PANELS/EVENTS/RECEIVE', (ids) => ({ids}))
export const receiveFiltered = createAction('PANELS/EVENTS/RECEIVE_FILTERED', (ids) => ({
    ids,
}))
export const reset = createAction('PANELS/EVENTS/RESET')
export const fetch = createAction('PANELS/EVENTS/FETCH', (panelId) => ({panelId}))
export const fetchSuggests = createAction(
    'PANELS/EVENTS/FETCH_SUGGESTS',
    (fields, prefix) => ({
        fields,
        prefix,
    })
)
export const fetchPanelEvents = createAction('PANELS/EVENTS/FETCH_PANEL_EVENTS')
export const fetchOlder = createAction('PANELS/EVENTS/FETCH_OLDER')
export const fetchNewer = createAction('PANELS/EVENTS/FETCH_NEWER')
export const checkNewer = createAction('PANELS/EVENTS/CHECK_NEWER')
export const startPoll = createAction('PANELS/EVENTS/START_POLL')
export const stopPoll = createAction('PANELS/EVENTS/STOP_POLL')
export const setPanelEventsFiltered = createAction(
    'PANELS/EVENTS/SET_PANEL_EVENTS_FILTERED'
)
export const setNewerCount = createAction(
    'PANELS/EVENTS/SET_NEWER_COUNT',
    (newerCount) => ({newerCount})
)
export const setHasOlder = createAction('PANELS/EVENTS/SET_HAS_OLDER', (hasOlder) => ({
    hasOlder,
}))
