import {createSelector} from 'reselect'

export const selectInstallerList = createSelector(
    (state) => state.installers.list,
    ({isLoading, page, error, total, start}) => ({isLoading, page, error, total, start})
)

export const selectInstallerListSearch = createSelector(
    (state) => state.installers.list,
    (list) => list
)
