import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ZoneTypeForm from './ZoneTypeForm'
import {validation} from './ZoneTypeFieldset'
import {addZoneType} from 'modules/forms/handlers'

export default function AddZoneType({hide}) {
    const {form, handle, isLoading} = useForm(addZoneType)

    return (
        <ZoneTypeForm
            {...form}
            hide={hide}
            handle={handle}
            rules={validation}
            isLoading={isLoading}
            header={__('Add Zone Type')}
        />
    )
}
