import {all, call, put} from 'redux-saga/effects'

import list from 'modules/runners/list/saga'
import store from 'modules/runners/store/saga'
import * as actions from './actions'

import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {POLL_RUNNER_STATUS} from 'configs/pollers'

import {status as fetchStatus} from 'api/runners'

export default function* () {
    yield all([
        list(),
        store(),
        createPollerSaga(
            actions.startPollRunnerData,
            actions.stopPollRunnerData,
            POLL_RUNNER_STATUS,
            watchRunnerRefresh,
            true
        ),
    ])
}

export function* watchRunnerRefresh({payload: {runnerId, callback}}) {
    try {
        if (!runnerId) {
            return false
        }
        const runners = yield fetchStatus([runnerId])
        const runner = runners.find((runner) => (runner.id = runnerId))

        yield call(callback, {runner})
    } catch (error) {
        if (callback) {
            yield call(callback, {error})
        }
    }

    yield put(actions.stopPollRunnerData())
}
