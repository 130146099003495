import {createSelector} from 'reselect'
import get from 'lodash-es/get'

export const selectSession = createSelector(
    (state, {session: {serial, protocol}}) =>
        get(state.devices.autolearn, [serial, protocol], {}),
    (session) => session
)

export const selectActiveSession = createSelector(
    (state, serial) => serial,
    (state, serial) => get(state.devices.autolearn, [serial], {}),
    (serial, sessions) =>
        Object.keys(sessions).reduce(
            (acc, protocol) =>
                sessions[protocol]?.token
                    ? {serial, protocol: +protocol, token: sessions[protocol]?.token}
                    : acc,
            null
        )
)

export const selectToken = createSelector(selectSession, ({token}) => token)

export const selectMessageIsHandled = createSelector(
    selectSession,
    (state, {id}) => id,
    ({messages}, id) => (messages || []).includes(id)
)

export const selectAddedDevices = createSelector(selectSession, ({devices}) => [
    ...(devices || []),
])

export const selectAddSensorConfirmationRequest = createSelector(
    selectSession,
    ({addSensorConfirmationRequests}) =>
        [...(addSensorConfirmationRequests || [])].shift()
)

export const selectZWaveKeysReportRequest = createSelector(
    selectSession,
    ({zWaveKeysReportRequests}) => [...(zWaveKeysReportRequests || [])].shift()
)

export const selectZWaveDskReportRequest = createSelector(
    selectSession,
    ({zWaveDskReportRequests}) => [...(zWaveDskReportRequests || [])].shift()
)
