import React from 'react'
import PropTypes from 'prop-types'

import {DTP_SRF} from 'constants/protocols'

import SFRBase from './SRFBase'

export default function SFR({
    panelId,
    onClose,
    protocol,
    setProtocol,
    startAuto,
    isAutoLearnAvailable,
}) {
    return protocol !== DTP_SRF ? null : (
        <SFRBase
            panelId={panelId}
            onClose={onClose}
            setProtocol={setProtocol}
            startAuto={startAuto}
            isAutoLearnAvailable={isAutoLearnAvailable}
        />
    )
}

SFR.propTypes = {
    panelId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    protocol: PropTypes.number.isRequired,
    setProtocol: PropTypes.func.isRequired,
    startAuto: PropTypes.func.isRequired,
    isAutoLearnAvailable: PropTypes.bool.isRequired,
}
