import React, {Fragment} from 'react'

import StateTypesTable from './StateTypesTable'
import StateTypesGridBar from './StateTypesGridBar'

export default function StateTypesPage() {
    return (
        <Fragment>
            <StateTypesGridBar />
            <StateTypesTable />
        </Fragment>
    )
}
