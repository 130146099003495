import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import PatchTag from 'components/PatchTag'

import {useActions} from 'modules/higherOrder/useActions'
import withRunnerLoader from 'containers/withRunnerLoader'
import {fetch, startPoll, stopPoll} from 'modules/panels/firmware/actions'
import {showFirmwarePatchTagUpdateModal} from 'modules/modals/actions'
import __ from 'utils/i18n'
import combine from './extendedInfo'

const ConnectedPatchTag = withRunnerLoader(
    () => __('Upgrading device software…'),
    ({fetch, panelId}) => fetch(panelId)
)(PatchTag)

const PatchTagTab = ({panelId, info, version}) => {
    const actions = useActions({
        fetch,
        startPoll,
        stopPoll,
        handler: () => showFirmwarePatchTagUpdateModal({panelId}),
    })

    const {runner} = useSelector(({panels: {firmware}}) => firmware[panelId] || {})

    useEffect(() => {
        actions.startPoll(panelId)

        return () => actions.stopPoll(panelId)
    }, [])

    return (
        <ConnectedPatchTag
            panelId={panelId}
            runner={runner}
            fetch={actions.fetch}
            handler={actions.handler}
            info={combine(info, version)}
        />
    )
}

PatchTagTab.propTypes = {
    panelId: PropTypes.number.isRequired,
    info: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            name: PropTypes.string,
            values: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string,
                    name: PropTypes.string,
                    value: PropTypes.string,
                })
            ),
        })
    ),
    version: PropTypes.string,
}

export default PatchTagTab
