import React from 'react'

import Page from 'ipmp-react-ui/Page'

import page from 'permissions/remoteInspections/page'
import {withPermissionRejection} from 'containers/withPermission'
import RemoteInspectionBar from './RemoteInspectionBar'
import RemoteInspectionContent from './RemoteInspectionContent'

const PageRejection = withPermissionRejection(page)(Page)

export default function RemoteInspectionPage() {
    return <PageRejection Bar={RemoteInspectionBar} Content={RemoteInspectionContent} />
}
