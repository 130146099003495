import __ from 'utils/i18n'

export const STATUS_REGISTERED = 'registered'
export const STATUS_VERIFIED = 'verified'
export const STATUS_SUSPENDED = 'suspended'

export const canSuspend = (status) => status === STATUS_VERIFIED

export default function getInteractiveUserStatusTitle(status) {
    switch (status) {
        case STATUS_REGISTERED:
            return __('Not verified')
        case STATUS_VERIFIED:
            return __('Active')
        case STATUS_SUSPENDED:
            return __('Suspended')
        default:
            return __('Unknown')
    }
}
