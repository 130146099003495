import React from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ipmp-react-ui/Bar'
import Buttons from 'ipmp-react-ui/Buttons'
import Button from 'ipmp-react-ui/Button'
import Tooltip from 'ipmp-react-ui/Tooltip'
import {ReactComponent as IconChat} from 'ipmp-react-ui/icons/baseline-chat.svg'

import usePermission from 'hooks/usePermission'
import PanelStatus from 'components/PanelStatus'
import PanelCell from 'components/TableCells/PanelCell'
import __ from 'utils/i18n'
import list from 'permissions/panel/info/remarks'

import PanelStatusLine from './PanelStatusLine'
import RefreshPanelButton from './Buttons/RefreshPanelButton'
import FaultsDropDown from './DropDowns/FaultsDropDown'
import InitEmergenciesButton from './Buttons/InitEmergenciesButton'
import ActionsDropDown from './DropDowns/ActionsDropDown'
import {PanelStatusLineStubber} from './PanelStatusLineStubber'
import BillingPlanButton from './Buttons/BillingPlanButton'

function PanelPageTop({panel, openRemarks, emergencyFeatures, isContentHidden}) {
    const {serial, account, connected} = panel
    const {isPermitted: remarksPermitted} = usePermission(list)
    return (
        <Bar className="panel__page-top">
            <PanelStatus panel={panel} />
            <PanelCell
                multiLine
                className="title title--large"
                serial={serial}
                account={account}
            />
            <PanelStatusLine panel={panel} />
            <PanelStatusLineStubber panel={panel} />

            <BarSpace />

            <BillingPlanButton />
            {!isContentHidden && emergencyFeatures?.isEnabled && (
                <InitEmergenciesButton />
            )}
            <FaultsDropDown />
            <ActionsDropDown isContentHidden={isContentHidden} connected={connected} />
            {!isContentHidden && (
                <Buttons>
                    <RefreshPanelButton panel={panel} />
                </Buttons>
            )}
            {remarksPermitted && (
                <Buttons dataTestId="open-panel-remarks">
                    <Tooltip tooltip={__('Open Panel Remarks')}>
                        <Button onClick={openRemarks} Icon={IconChat} />
                    </Tooltip>
                </Buttons>
            )}
        </Bar>
    )
}

PanelPageTop.propTypes = {
    panel: PropTypes.shape({
        id: PropTypes.number,
        serial: PropTypes.string,
        account: PropTypes.string,
    }).isRequired,
    openRemarks: PropTypes.func.isRequired,
    emergencyFeatures: PropTypes.shape({
        isEnabled: PropTypes.bool,
    }),
}

export default PanelPageTop
