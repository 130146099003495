import React from 'react'
import PropTypes from 'prop-types'

import Bar from 'ipmp-react-ui/Bar'

const PartitionStateButtons = ({
    state,
    disabled,
    ButtonComponent,
    sendState,
    partition,
}) => {
    const isActive = ({name}) => {
        return state ? state.name === name : false
    }

    const handleSendState = (...args) => {
        sendState(partition.id, ...args)
    }

    const isTransitionAllowed = ({name}) => {
        const {transitions} = state
        return transitions && transitions.includes(name)
    }

    if (!state.stateSet) {
        return null
    }

    return (
        <Bar>
            {Object.values(state.stateSet)
                .filter(({visible}) => visible)
                .map((state) => (
                    <ButtonComponent
                        key={state.name}
                        name={state.name}
                        modifiers={state.modifiers}
                        active={isActive(state)}
                        disabled={disabled || !isTransitionAllowed(state)}
                        sendStateAction={handleSendState}
                        partitionId={partition.id}
                    />
                ))}
        </Bar>
    )
}

PartitionStateButtons.propTypes = {
    state: PropTypes.shape({
        name: PropTypes.string,
        stateSet: PropTypes.objectOf(
            PropTypes.shape({
                name: PropTypes.string,
                visible: PropTypes.bool,
            })
        ).isRequired,
    }).isRequired,

    partition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
    }).isRequired,

    sendState: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    ButtonComponent: PropTypes.elementType.isRequired,
}

export default PartitionStateButtons
