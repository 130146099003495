import {createAction} from 'redux-actions'

export const update = createAction('USER_SETTINGS/UPDATE')

export const updateOSTheme = createAction('USER_SETTINGS/STOP_DETECTING_THEME_CHANGE')
export const updateFromServiceWorker = createAction('USER_SETTINGS/UPDATE/SERVICE_WORKER')
export const updateFromServiceWorkerWithoutSend = createAction(
    'USER_SETTINGS/UPDATE/SERVICE_WORKER_WITHOUT_SEND'
)

export const revert = createAction('USER_SETTINGS/REVERT')
export const changePerPage = createAction('USER_SETTINGS/CHANGE_PER_PAGE')
export const saveSearchScope = createAction(
    'USER_SETTINGS/SAVE_SEARCH',
    (page, scope, name, filters) => ({page, scope, name, filters})
)
export const removeSearchScope = createAction(
    'USER_SETTINGS/REMOVE_SEARCH_SCOPE',
    (page, scope) => ({page, scope})
)
export const setLanguage = createAction('USER_SETTINGS/SET_LANGUAGE')
export const setAutologout = createAction('USER_SETTINGS/SET_AUTOLOGOUT')

export const setAlarmsEnabled = createAction(
    'USER_SETTINGS/SET_ALARMS_ENABLED',
    (isEnabled = true) => ({isEnabled})
)
export const setAlarmsNotificationsEnabled = createAction(
    'USER_SETTINGS/SET_ALARMS_NOTIFICATIONS_ENABLED',
    (isEnabled = true) => ({isEnabled})
)
export const setAlarmsSoundEnabled = createAction(
    'USER_SETTINGS/SET_ALARMS_SOUND_ENABLED',
    (isSoundEnabled = true) => ({isSoundEnabled})
)

export const showSoundPermissionError = createAction(
    'USER_SETTINGS/SHOW_SOUND_PERMISSION_ERROR'
)

export const enableSounds = createAction('USER_SETTINGS/ENABLE_SOUNDS')

export const setTheme = createAction('USER_SETTINGS/SET_THEME')

export const setEDSView = createAction('USER_SETTINGS/SET_EDS_VIEW')
export const setBillingStatusView = createAction('USER_SETTINGS/SET_BILLING_STATUS_VIEW')
export const setKeypadSoundEnabled = createAction(
    'USER_SETTINGS/SET_KEYPAD_SOUND_ENABLED',
    (isEnabled = true) => ({isEnabled})
)
export const setTemperature = createAction('USER_SETTINGS/SET_TEMPERATURE')
export const setAlarmSound = createAction('USER_SETTINGS/SET_ALARM_SOUND')
export const updateMenuOrder = createAction(
    'USER_SETTINGS/MENU_ORDER',
    (menuOrderKey) => menuOrderKey
)
export const resetMenuOrderUpdateFlag = createAction(
    'USER_SETTINGS/RESET_MENU_ORDER_UPDATE_FLAG'
)

export const setMainMenuSettings = createAction(
    'USER_SETTINGS/SET_MAIN_MENU_SETTINGS',
    (settings) => settings
)
