import {matchPath} from 'react-router'

const getLocation = (state) => state.router.location

export default function createMatchSelector(path) {
    let lastMatch = null
    let lastPathname = null

    return function (state) {
        const {pathname} = getLocation(state) || {}

        if (!pathname) {
            return lastMatch
        }

        if (pathname === lastPathname) {
            return lastMatch
        }

        lastPathname = pathname

        let match = matchPath(path, pathname)

        if (!match || !lastMatch || match.pathname !== lastMatch.pathname) {
            lastMatch = match
        }
        return lastMatch
    }
}
