import React from 'react'
import {useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import {ReactComponent as IconOpen} from 'ipmp-react-ui/icons/disarm.svg'
import {ReactComponent as IconClose} from 'ipmp-react-ui/icons/away.svg'

import {HAD_WATER_VALVE_CLOSED, HAD_WATER_VALVE_OPENED} from 'constants/had/hadState'
import {waterValveControl} from 'permissions/panel/output/actions'
import {selectHadIsSwitchable} from 'modules/panels/had/selector'
import __ from 'utils/i18n'
import usePermission from 'hooks/usePermission'

import OpenButton from './SetWaterValveOpenButton'
import CloseButton from './SetWaterValveCloseButton'

const HadWaterValveControl = ({had: {id, state, deviceType}, disabled}) => {
    const mode = state?.water_valve?.mode
    const {isPermitted} = usePermission(waterValveControl)
    const {isSwitchable} = useSelector(selectHadIsSwitchable)

    return (
        <div
            className={classnames(
                'device-had-controls-stateButtons',
                'device-had-controls-stateButtons--doorlock',
                {
                    'device-had-controls-stateButtons--disabled': Boolean(disabled),
                }
            )}
        >
            {mode === HAD_WATER_VALVE_CLOSED && isPermitted && (
                <OpenButton
                    Icon={IconClose}
                    id={id}
                    label={__('Open')}
                    deviceType={deviceType}
                    disabled={!isSwitchable}
                />
            )}
            {mode === HAD_WATER_VALVE_OPENED && isPermitted && (
                <CloseButton
                    Icon={IconOpen}
                    id={id}
                    label={__('Close')}
                    deviceType={deviceType}
                    disabled={!isSwitchable}
                />
            )}
        </div>
    )
}

HadWaterValveControl.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.shape({
            water_valve: PropTypes.shape({
                mode: PropTypes.number,
            }),
        }),
    }).isRequired,
    disabled: PropTypes.bool,
}

export default HadWaterValveControl
