import React from 'react'

import {ReactComponent as IconAutolearn} from 'ipmp-react-ui/icons/autolearn/device-autolearn.svg'

import __ from 'utils/i18n'

export default function IdleContentSecurity() {
    return (
        <>
            <div className="autolearn-idle-icon">
                <IconAutolearn />
            </div>
            <div className="autolearn-description-content-title">
                {__('Autolearn security devices')}
            </div>
            <div className="autolearn-description-content-message">
                {__('Press "Start" to initiate autolearn process')}
            </div>
        </>
    )
}
