import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import page from 'permissions/firmware/page'

import {fetch} from 'modules/firmware/applianceSelection/actions'
import {fetch as fetchUpgradableIqPanels} from 'modules/firmware/patchTagUpdate/actions'
import {withPermissionRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import SearchBar from 'components/Search/SearchBar'
import Bar from 'ipmp-react-ui/Bar'
import Page from 'ipmp-react-ui/Page'
import Table from 'ipmp-react-ui/Table'
import Layout, {ScrollView} from 'ipmp-react-ui/Layout'
import Button from 'ipmp-react-ui/Button'

import path from 'utils/path'
import {__} from 'utils/i18n'
import deviceType from 'constants/deviceType'
import IconBack from 'ui/IconBack'
import {useNavigate} from 'react-router'
import UpgradeIqPanelsTable from './UpgradeIqPanelsTable'
import isEmpty from 'lodash-es/isEmpty'

const appliancesColumns = [
    {
        render: ({type}) => deviceType(type),
    },
]

const packagesColumns = [
    {
        width: 150,
        render: ({name}) => name,
    },
    {
        width: 50,
        render: ({version}) => version,
    },
]

const contains = (value, query) =>
    value && (value + '').toLowerCase().indexOf(query) !== -1

export class FirmwarePage extends Page {
    static propTypes = {
        appliances: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                type: PropTypes.string.isRequired,
                name: PropTypes.string,
                groupAbbr: PropTypes.string,
                upgradePackages: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        version: PropTypes.string.isRequired,
                        description: PropTypes.string,
                    })
                ),
            })
        ),
    }

    state = {
        value: '',
    }

    handleSearchChange = (value) =>
        this.setState({
            value,
            query: value.trim().toLowerCase(),
        })

    handleGoBack = () => this.setState({appliance: null, query: '', value: ''})

    renderTop() {
        const {appliance, value} = this.state

        return (
            <Bar>
                {appliance && (
                    <Button
                        shortcut="backspace"
                        onClick={this.handleGoBack}
                        className="btn--goBack"
                    >
                        <IconBack />
                    </Button>
                )}

                <SearchBar
                    value={value}
                    onChange={this.handleSearchChange}
                    placeholder={__('Quick search')}
                />
            </Bar>
        )
    }

    selectAppliance = (appliance) => this.setState({appliance, query: '', value: ''})

    selectPackage = ({id}) => {
        const {appliance} = this.state

        this.props.navigate(
            path('firmware.upgrade', {
                upgradePackageId: id,
                applianceId: appliance.id,
            })
        )
    }

    getVisiblePackages() {
        const packages = this.state.appliance.upgradePackages
        const {query} = this.state

        if (!query) {
            return packages
        }

        return packages.filter(
            ({version, name}) => contains(version, query) || contains(name, query)
        )
    }

    getVisibleAppliances() {
        const {appliances} = this.props
        const {query} = this.state

        if (!query) {
            return appliances
        }

        return appliances.filter(({name}) => contains(name, query))
    }

    renderContent() {
        const {upgradableIqPanelsPage} = this.props
        if (this.state.appliance) {
            return (
                <Layout vertical className="firmware">
                    <Table
                        fullHeight
                        emptyMessage={__('No packages found')}
                        title={__(
                            'Upgrade %s to version:',
                            deviceType(this.state.appliance.type)
                        )}
                        rows={this.getVisiblePackages()}
                        columns={packagesColumns}
                        onRowClick={this.selectPackage}
                    />
                </Layout>
            )
        }

        const visibleAppliances = this.getVisibleAppliances()
        const isVisibleAppliances =
            !isEmpty(visibleAppliances) || isEmpty(upgradableIqPanelsPage)

        return (
            <ScrollView className="firmware">
                {isVisibleAppliances && (
                    <Table
                        emptyMessage={__('No upgradable devices found')}
                        title={__('Choose device for mass upgrade:')}
                        rows={visibleAppliances}
                        columns={appliancesColumns}
                        onRowClick={this.selectAppliance}
                    />
                )}
                {upgradableIqPanelsPage && !isEmpty(upgradableIqPanelsPage) && (
                    <UpgradeIqPanelsTable className="firmware__upgrade-iq-panels-table" />
                )}
            </ScrollView>
        )
    }
}

function FirmwarePageBase(props) {
    const navigate = useNavigate()

    return <FirmwarePage {...props} navigate={navigate} />
}

export default compose(
    withPermissionRejection(page),
    connect(
        ({
            firmware: {
                applianceSelection: {isLoading, error, appliances},
                store,
                patchTagUpdate,
            },
        }) => {
            return {
                isLoading,
                error,
                upgradableIqPanelsPage: patchTagUpdate.page,
                appliances: appliances.map((id) => {
                    const appliance = store.appliances.byIds[id]

                    return {
                        ...appliance,
                        upgradePackages: appliance.upgradePackages.map(
                            (upgradePackageId) =>
                                store.upgradePackages.byIds[upgradePackageId]
                        ),
                    }
                }),
            }
        },
        (dispatch) => bindActionCreators({fetch, fetchUpgradableIqPanels}, dispatch)
    ),
    withLoader(({fetch, fetchUpgradableIqPanels}) => {
        fetch()
        fetchUpgradableIqPanels()
    })
)(FirmwarePageBase)
