import React from 'react'

import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {initiate} from 'permissions/remoteInspections/actions'
import useRemoteInspections from 'hooks/pages/useRemoteInspections'
import InitRRIBase from 'components/Cell/RemoteInspections/Actions/InitRRI'

const InitRRIVisibility = withVisibility()(InitRRIBase)

export default function Initiate({panelId}) {
    const {isPermitted} = usePermission(initiate)
    const {initiateNow} = useRemoteInspections()

    return (
        <InitRRIVisibility
            initiateNow={initiateNow}
            panelId={panelId}
            isVisible={isPermitted}
        />
    )
}
