import React from 'react'

import path from 'utils/path'
import RolesPage from './RolesPage'

export default class RolesRoute {
    path = path('roles')

    element = (<RolesPage />)
}
