export default function isBitOn(number, index) {
    return Boolean(number & (1 << index))
}

export function getActiveBitsFromBitmask(bitmask, maxValue) {
    if (bitmask == null) {
        return null
    }

    const result = []
    for (let i = 0; i < maxValue; ++i) {
        if (isBitOn(bitmask, i)) {
            result.push(i)
        }
    }

    return result
}
