import React from 'react'

import path from 'utils/path'
import FirmwarePatchTagUpdatePage from './FirmwarePatchTagUpdatePage'

export default class {
    path = path('firmware.patchTagUpdate')

    element = (<FirmwarePatchTagUpdatePage />)
}
