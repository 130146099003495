import {DTP_PREFIX} from 'constants/protocols'

export const DELIM = ' — '
export const POWERG_ENROL_ID_PREFIX_LENGTH = 3 // 101-1231
export const UNKNOWN_DEVICE = Object.freeze({})
export const WIFI_CAMERA_ENROLMENT_PREFIX = '140'

export const openedBypassableDeviceFilter = (device) => {
    const isBypassable = Boolean(device?.traits?.bypass)

    return isBypassable && Boolean(device.open)
}

export const isClearAvailable = (subtype, protocols) =>
    Object.keys(protocols).filter(
        (protocol) => subtype && subtype.includes(protocol.replace(DTP_PREFIX, ''))
    ).length > 0
