import React, {useState} from 'react'
import {useSelector} from 'react-redux'

import Modal from 'ipmp-react-ui/Modal'
import Card from 'ipmp-react-ui/Card'
import Tabs, {VERTICAL_TABS_ALIGN} from 'ipmp-react-ui/Tabs'
import {ReactComponent as IconProtected} from 'ipmp-react-ui/icons/autolearn/Protected.svg'
import {ReactComponent as IconZWave} from 'ipmp-react-ui/icons/autolearn/autolearn-icon.svg'

import {clear} from 'modules/forms/actions'
import {useActions} from 'modules/higherOrder/useActions'
import usePermission from 'hooks/usePermission'
import {autolearn} from 'permissions/panel/devices/autolearn/actions'
import {selectActiveSession} from 'modules/devices/autolearn/selectors'
import __ from 'utils/i18n'
import {VENDOR_QOLSYS} from 'constants/panelVendorType'

import Security from './Security'
import Automation from './Automation'
import {DismissConfirmation} from 'components/Modal'
import {selectAvailableAutolearn} from 'modules/features/store/selectors'
import {getAutomationFeaturesAutolearnProtocols} from 'constants/autolearn'

export default function AddDevice({panelId, hide}) {
    const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
    const panel = useSelector(({panels}) => panels.store.byIds[panelId])
    const {errorClear} = useActions({errorClear: clear})
    const {isPermitted: isAutolearnPermitted} = usePermission(autolearn)
    const {token} =
        useSelector((state) => selectActiveSession(state, panel?.serial)) || {}
    const isAutoLearnAvailable = [VENDOR_QOLSYS].includes(panel?.vendor)

    const handleClose = () => {
        hide()
        errorClear()
    }

    const cancelDismiss = () => setShowDismissConfirmation(false)

    const dismiss = (e) => {
        e && e.preventDefault()

        if (token) {
            setShowDismissConfirmation(true)
        } else {
            handleClose()
        }
    }

    const automationProtocols = getAutomationFeaturesAutolearnProtocols(
        useSelector((state) => selectAvailableAutolearn(state, {panelId}))
    )

    return (
        <Modal onClose={dismiss}>
            <Card className="add-device-form">
                <Tabs
                    isTabLabel={false}
                    align={VERTICAL_TABS_ALIGN}
                    onChangeTab={errorClear}
                    withConfirmation={!!token}
                    confirmationMessage={__(
                        'Do you really want to interrupt autolearn session?'
                    )}
                    confirmationTitle={__('Stop autolearn session')}
                >
                    <Security
                        Icon={IconProtected}
                        panelId={panelId}
                        serial={panel?.serial}
                        onClose={handleClose}
                        isAutoLearnAvailable={isAutoLearnAvailable}
                    />
                    {!!automationProtocols.length &&
                        isAutoLearnAvailable &&
                        isAutolearnPermitted && (
                            <Automation
                                automationProtocols={automationProtocols}
                                Icon={IconZWave}
                                serial={panel?.serial}
                                vendor={panel?.vendor}
                                onClose={handleClose}
                            />
                        )}
                </Tabs>
                {showDismissConfirmation && (
                    <DismissConfirmation
                        dismiss={dismiss}
                        cancelDismiss={cancelDismiss}
                        agreeDismiss={handleClose}
                        message={__(
                            'Current autolearn process will be stopped. Do you want to proceed?'
                        )}
                    />
                )}
            </Card>
        </Modal>
    )
}
