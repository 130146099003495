import {useSelector} from 'react-redux'

import useRouterPanelId from '../useRouterPanelId'
import {selectFotaAotaVersions} from 'modules/devices/list/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    showEditPanelCustomerInfoModal,
    showEditPanelInfoModal,
} from 'modules/modals/actions'

export default function usePanelInfo({googleApiError} = {}) {
    const id = useRouterPanelId()
    const {panel, isLoading} = useSelector(({panels}) => ({
        panel: panels.store.byIds[id],
        isLoading: panels.one.isLoading,
    }))

    const fotaAotaVersions = useSelector((state) => selectFotaAotaVersions(state, id))

    return {
        panel,
        isLoading,
        fotaAotaVersions,
        ...useActions({
            editPanelInfo: () => showEditPanelInfoModal(id),
            editCustomerInfo: () => showEditPanelCustomerInfoModal(id, googleApiError),
        }),
    }
}
