import React from 'react'
import {compose} from 'redux'

import DropDownButton from 'ipmp-react-ui/DropDownButton'
import Menu, {MenuItem} from 'ipmp-react-ui/Menu'
import {ReactComponent as PlusIcon} from 'ipmp-react-ui/icons/plus.svg'

import Void from 'components/Void'
import {withVisibility} from 'containers/withVisibility'
import {selectAddDeviceFeature} from 'modules/features/store/selectors'
import {withFeatureRejection} from 'containers/withFeature'
import useDevices from 'hooks/pages/useDevices'
import useEnrollPermissions from 'hooks/panel/devices/useEnrollPermissions'
import __ from 'utils/i18n'

const AddCameraMenuItem = () => {
    const devicesProps = useDevices()

    return (
        <MenuItem Icon={PlusIcon} onClick={devicesProps.showAddCamera}>
            {__('Add camera')}
        </MenuItem>
    )
}

const DropDownButtonVisibleWithFeature = compose(
    withVisibility(),
    withFeatureRejection(selectAddDeviceFeature, Void)
)(DropDownButton)

export default function AddDevicesMenu() {
    const devicesProps = useDevices()
    const {camerasPermitted} = useEnrollPermissions()

    return (
        <DropDownButtonVisibleWithFeature
            {...devicesProps}
            isVisible={camerasPermitted}
            className="devices-add-camera-button"
            left
        >
            <Menu>{camerasPermitted && <AddCameraMenuItem />}</Menu>
        </DropDownButtonVisibleWithFeature>
    )
}
