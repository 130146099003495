import React from 'react'
import PropTypes from 'prop-types'

import {
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import {__, __n} from 'utils/i18n'
import {convertConfigValue} from 'utils/gettext'

export default function Quazar53({settings: {bba, gprs}}) {
    return (
        <>
            {!gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Cellular Supervision')}</InfoCardTileTitle>
                    <InfoCardTileContent>{convertConfigValue(false)}</InfoCardTileContent>
                </InfoCardTile>
            )}
            {gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>
                        {__('Cellular Supervision Period')}
                    </InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(
                            __n('%d second', '%d seconds', gprs.supervision)
                        )}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
            {gprs.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Cellular Offline Timer')}</InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(__n('%d second', '%d seconds', gprs.timeout))}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
            {!bba.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Broadband Supervision')}</InfoCardTileTitle>
                    <InfoCardTileContent>{convertConfigValue(false)}</InfoCardTileContent>
                </InfoCardTile>
            )}

            {bba.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>
                        {__('Broadband Supervision Period')}
                    </InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(
                            __n('%d second', '%d seconds', bba.supervision)
                        )}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}

            {bba.isEnabled && (
                <InfoCardTile>
                    <InfoCardTileTitle>{__('Broadband Offline Timer')}</InfoCardTileTitle>
                    <InfoCardTileContent>
                        {convertConfigValue(__n('%d second', '%d seconds', bba.timeout))}
                    </InfoCardTileContent>
                </InfoCardTile>
            )}
        </>
    )
}

Quazar53.propTypes = {
    settings: PropTypes.objectOf(
        PropTypes.shape({
            isEnabled: PropTypes.bool,
            supervision: PropTypes.number,
            timeout: PropTypes.number,
        })
    ),
}
