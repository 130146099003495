import React from 'react'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import StateTypeForm from './StateTypeForm'
import {validation} from './StateTypeFieldset'
import {addStateType} from 'modules/forms/handlers'

export default function AddStateType({hide}) {
    const {handle, form, isLoading} = useForm(addStateType)

    return (
        <StateTypeForm
            {...form}
            hide={hide}
            handle={handle}
            rules={validation}
            isLoading={isLoading}
            header={__('Add State Type')}
        />
    )
}
