import {useSelector} from 'react-redux'

import usePerPage from '../usePerPage'
import {selectInstallersByPage} from 'modules/installers/store/selectors'
import {useActions} from 'modules/higherOrder/useActions'
import {
    fetch,
    init,
    remove,
    reset,
    setStart,
    startPoll,
    stopPoll,
} from 'modules/installers/list/actions'
import {accept, reject, resetPassword} from 'modules/installers/store/actions'

export default function useInstallers() {
    const {onPerPageChange, perPage} = usePerPage('installers')
    const {isLoading, error, total, start, rows} = useSelector(selectInstallersByPage)

    return {
        perPage,
        onPerPageChange,
        isLoading,
        error,
        total,
        start,
        rows,
        ...useActions({
            init,
            fetch,
            reset,
            accept,
            reject,
            resetPassword,
            onPageChange: setStart,
            startPoll,
            stopPoll,
            remove,
        }),
    }
}
