import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {hadStateIsActive, hadStateIsJammed, hadStateTitleByType} from 'constants/had'
import {getThermostatModeIcon} from 'constants/had/thermostat'
import {DEVICE_SUBTYPE_ZWAVE_THERMOSTAT} from 'constants/deviceSubtype'

const HadDeviceState = ({subtype, type, state}) => {
    if (!state) {
        return null
    }

    return (
        <div
            className={classNames('deviceHad-state', {
                active: hadStateIsActive(subtype || type, state),
                jammed: hadStateIsJammed(subtype || type, state),
            })}
        >
            {subtype === DEVICE_SUBTYPE_ZWAVE_THERMOSTAT
                ? getThermostatModeIcon(state?.thermostat?.mode)
                : null}
            {hadStateTitleByType({hadType: subtype || type, state})}
        </div>
    )
}

HadDeviceState.propTypes = {
    type: PropTypes.string,
    state: PropTypes.object,
}

export default HadDeviceState
