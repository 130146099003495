import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import Form from 'ipmp-react-ui/Form'
import ToggleBox from 'ipmp-react-ui/ToggleBox'

import __ from 'utils/i18n'
import {
    HAD_DOORLOCK_JAMMED,
    HAD_DOORLOCK_LOCKED,
    HAD_DOORLOCK_UNLOCKED,
    isLocked,
} from 'constants/had/hadState'
import doorlockTypeStateTitle from 'constants/had/doorlock'
import {resolveDoorlockCommands} from 'constants/had/command'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {withPermissionRejection} from 'containers/withPermission'
import {doorlockControl} from 'permissions/panel/output/actions'
import Empty from 'ui/Empty'
import useForm from 'hooks/useForm'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'

const DoorlockControlFormContent = ({
    had,
    lockState,
    handleChange,
    isDisabled,
    setIsActiveSaveButton,
}) => {
    if ((had?.supportedCommands || []).length === 0) {
        return null
    }

    const isJammed = had?.state?.lock?.mode === HAD_DOORLOCK_JAMMED
    useEffect(() => {
        setIsActiveSaveButton(!isJammed)
    }, [isJammed])

    const onChange = (checked) => {
        handleChange(checked && !isJammed ? HAD_DOORLOCK_LOCKED : HAD_DOORLOCK_UNLOCKED)
        setIsActiveSaveButton(true)
    }

    return (
        <ToggleBox
            onChange={(e) => {
                onChange(e.target.checked)
            }}
            checked={isLocked(lockState) || lockState === HAD_DOORLOCK_JAMMED}
            defaultChecked={isLocked(lockState) || isJammed}
            name="state"
            label={
                <>
                    {__('Device control')}{' '}
                    <Empty>({doorlockTypeStateTitle(lockState)})</Empty>
                </>
            }
            disabled={isDisabled}
        />
    )
}

const DoorlockControlFormBase = ({
    errors,
    isLoading,
    handle,
    had,
    panelId,
    setIsActiveSaveButton,
}) => {
    const [lockState, setLockState] = useState(had?.state?.lock?.mode)
    const onSubmitHandler = () => {
        handle &&
            handle(
                new HadControlForm({
                    commands: resolveDoorlockCommands({
                        mode: Number(lockState),
                        supportedCommands: had?.supportedCommands,
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }

    return (
        <Form
            className="pgmControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
        >
            <DoorlockControlFormContent
                had={had}
                lockState={lockState}
                handleChange={setLockState}
                setIsActiveSaveButton={setIsActiveSaveButton}
            />
        </Form>
    )
}

const DoorLockControlFormRejection = withPermissionRejection(doorlockControl)(
    DoorlockControlFormBase
)

export default function DoorlockControlForm({had, panelId, setIsActiveSaveButton}) {
    const {handle, isLoading, form} = useForm(submitHadDeviceControlForm)

    return (
        <DoorLockControlFormRejection
            {...form}
            had={had}
            panelId={panelId}
            handle={handle}
            isLoading={isLoading}
            setIsActiveSaveButton={setIsActiveSaveButton}
        />
    )
}

DoorlockControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
    panelId: PropTypes.number,
    setIsActiveSaveButton: PropTypes.func.isRequired,
}
