import React from 'react'

import DownloadCell from 'components/Cell/Reports/DownloadCell'

export default function DownloadReportCell({row: {id, exportTypes, status}}) {
    return (
        <DownloadCell
            id={id}
            isGroupButtons
            exportTypes={exportTypes}
            disabled={status !== 'succeeded'}
        />
    )
}
