import React from 'react'
import PropTypes from 'prop-types'
import {useParams} from 'react-router'

import Error from 'ipmp-react-ui/Error'
import InfoCards, {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import __ from 'utils/i18n'

import csProfileType, {getCsProfileStatusTitle} from 'constants/csProfileType'
import EditCommunicationButton from './EditCommunicationButton'

export const GroupCardWrapper = ({children}) => {
    if (children.length === 0) {
        return <Error title={__('No groups')} />
    }

    return <InfoCards cols={children.length > 1 ? 2 : 1}>{children}</InfoCards>
}

const GroupCard = ({id, links, profiles, title}) => {
    const {id: centralStationId} = useParams()

    return (
        <InfoCard
            cols={4}
            title={title}
            headerButton={
                <EditCommunicationButton
                    stationId={Number(centralStationId)}
                    groupId={id}
                    links={links}
                />
            }
        >
            {Object.values(profiles).map((profile) => {
                const isEnabled = links.includes(profile.id)
                const tileContentClass = isEnabled ? 'enabled' : 'disabled'

                return (
                    <InfoCardTile key={profile.name}>
                        <InfoCardTileTitle>
                            {csProfileType(profile.name)}
                        </InfoCardTileTitle>
                        <InfoCardTileContent className={tileContentClass}>
                            {getCsProfileStatusTitle(isEnabled)}
                        </InfoCardTileContent>
                    </InfoCardTile>
                )
            })}
        </InfoCard>
    )
}

GroupCard.propTypes = {
    id: PropTypes.number.isRequired,
    links: PropTypes.array,
    profiles: PropTypes.object,
    title: PropTypes.string,
}

export default GroupCard
