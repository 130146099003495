import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import {
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
    fetch,
    fetchSuggests,
} from 'modules/remoteInspections/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import usePermission from 'hooks/usePermission'
import list from 'permissions/remoteInspections/list'
import {withVisibility} from 'containers/withVisibility'
import {useActions} from 'modules/higherOrder/useActions'
import {showSaveSearchModal} from 'modules/modals/actions'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import {selectRemoteInspectionsList} from 'modules/remoteInspections/list/selectors'

const SearchVisibility = withVisibility()(Search)

export default function RemoteInspectionSearch({className}) {
    const {scope} = useParams()
    const {isPermitted} = usePermission(list)
    const {filters: selected, query, suggests} = useSelector(selectRemoteInspectionsList)

    const {apply, onSelect, onDeselect, onClear, onQuery, onSuggest, onSaveSearch} =
        useActions({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: (filters, onClose) =>
                showSaveSearchModal('remoteInspections', filters, onClose),
        })

    const filters = [
        new SuggestFilter('serial', __('Panel Id')),
        new SuggestFilter('account', __('Account')),
        new SuggestFilter('group', __('Group')),

        new DateFilter('last', __('Last RRI')),
        new DateFilter('next', __('Next RRI'), 'future'),

        new ValuesFilter('isEmailSent', __('Email Sent'), {
            true: __('Yes'),
            false: __('No'),
        }),

        new ValuesFilter('isReviewed', __('Reviewed'), {
            true: __('Yes'),
            false: __('No'),
        }),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <SearchVisibility
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            onSuggest={onSuggest}
            onSaveSearch={onSaveSearch}
            key={scope}
            query={query}
            filters={filters}
            suggests={suggests}
            selected={selected}
            isVisible={isPermitted}
            className={className}
            placeholder={__('Search Remote Inspections')}
        />
    )
}
