import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {showAddPanelModal} from 'modules/modals/actions'

import {withPermissionVisibility} from 'containers/withPermission'

import {add} from 'permissions/panels/bar'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconPlus} from 'ipmp-react-ui/icons/plus.svg'

import {__} from 'utils/i18n'

class AddPanelButton extends Component {
    static propTypes = {
        showAddPanelModal: PropTypes.func.isRequired,
    }

    render() {
        const {showAddPanelModal} = this.props

        return (
            <Button onClick={showAddPanelModal} Icon={IconPlus}>
                {__('Add Panel')}
            </Button>
        )
    }
}

export default compose(
    withPermissionVisibility(add),
    connect(null, (dispatch) =>
        bindActionCreators(
            {
                showAddPanelModal,
            },
            dispatch
        )
    )
)(AddPanelButton)
