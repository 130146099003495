import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ipmp-react-ui/Checkbox'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setSystemSettings} from 'modules/forms/handlers'

export default function BroadbandConnectedSettings({hide, data}) {
    const {form, handle, isLoading} = useForm(setSystemSettings.BroadbandConnected)

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            onSubmit={handle}
            confirmOnDismiss
            isLoading={isLoading}
            header={__('Edit Broadband Connected Settings')}
        >
            <Checkbox
                label={__('Enable Auto-Enroll')}
                name="isAutoEnrollmentEnabled"
                defaultChecked={data.isAutoEnrollmentEnabled}
            />
        </ModalCardForm>
    )
}

BroadbandConnectedSettings.propTypes = {
    hide: PropTypes.func.isRequired,
    data: PropTypes.shape({
        isAutoEnrollmentEnabled: PropTypes.bool,
    }),
}
