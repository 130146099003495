import React from 'react'

import Page from 'ipmp-react-ui/Page'

import {tabsMap} from './AccountsRouting'
import page from 'permissions/accounts/page'
import AccountsContent from './AccountsContent'
import tabs from 'permissions/accounts/navigation'
import AccountsBar from 'pages/Accounts/AccountsBar'
import usePermission from '../../hooks/usePermission'
import {withPermissionRejection} from 'containers/withPermission'
import resolveTabsPermissions from 'permissions/resolveTabsPermissions'

const PageRejection = withPermissionRejection(page)(Page)

export default function AccountsPage() {
    const {isAllowed, allowedTabs} = usePermission({allowedTabs: tabs})
    const availableTabs = resolveTabsPermissions(allowedTabs, tabsMap)

    return (
        <PageRejection
            tabs={availableTabs}
            isAllowed={isAllowed}
            Bar={AccountsBar}
            Content={AccountsContent}
            className="accounts-page"
        />
    )
}
