import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {humanTime} from 'ipmp-react-ui/humanTime'
import Definition from 'ipmp-react-ui/Definition'
import List, {ListHintItem} from 'ipmp-react-ui/List'

import {__} from 'utils/i18n'
import PlinkDebug from './PlinkDebug'
import usePermission from 'hooks/usePermission'
import plink from 'permissions/panel/devices/plink'
import {deviceSignals} from 'constants/signalLevelType'
import GeneralInfoTabBlock from './GeneralInfoTabBlock'
import {signalLevelTitle} from 'constants/signalLevelType'
import {DEVICE_TYPE_POWER_LINK} from 'constants/deviceType'
import DiagnosticInfo from 'components/Diagnostic/DiagnosticInfo'
import diagnosticPermission from 'permissions/panel/devices/diagnostic'
import {clear as clearDevicePermission} from 'permissions/panel/devices/actions'
import RefreshRssiButton from 'pages/Panel/Devices/Bars/Buttons/RefreshRssiButton'
import ClearDeviceWidget from 'pages/Panel/Devices/Sidebar/Tabs/ClearDeviceWidget'
import {isClearAvailable} from 'constants/device'
import {selectClearingProtocols} from 'modules/features/store/selectors'
import {DEVICE_SUBTYPE_ZWAVE_RADIO} from 'constants/deviceSubtype'

export default function GeneralInfoTab({panelId, device}) {
    const {isPlinkAllowed, isDiagnosticAllowed, isClearPermitted} = usePermission({
        isPlinkAllowed: plink,
        isDiagnosticAllowed: diagnosticPermission,
        isClearPermitted: clearDevicePermission,
    })
    const protocols = useSelector((state) => selectClearingProtocols(state, {panelId}))

    const renderRssi = ({average, current, channel, repeater, last_updated: updated}) => {
        const empty = <span className="empty">{__('not set')}</span>

        const info = (
            <List twoCols>
                <ListHintItem
                    name={__('Current')}
                    text={current ? signalLevelTitle(current) : empty}
                />
                <ListHintItem
                    name={__('Average')}
                    text={average ? signalLevelTitle(average) : empty}
                />
                <ListHintItem name={__('Channels')} text={channel || empty} />
                <ListHintItem
                    name={__('Repeater')}
                    text={repeater !== null ? repeater : empty}
                />
            </List>
        )

        const noInfo = (
            <div className="rssi-noMeasurements">
                <h6 className="title title--medium">{__('No measurements')}</h6>
                <p className="rssi-noMeasurements-content">
                    {__('Click Refresh RSSI button to reveal device rssi info')}
                </p>
                <RefreshRssiButton flat />
            </div>
        )

        return (
            <div className="block rssi">
                <Definition
                    className="title"
                    multiLine
                    title={__('RSSI')}
                    detail={updated && humanTime(updated)}
                />

                {updated ? info : noInfo}
            </div>
        )
    }

    const renderDiagnostic = (diagnostic) => {
        return (
            <div className="block">
                <h4 className="title">{__('Diagnostic')}</h4>

                <DiagnosticInfo {...diagnostic} />
            </div>
        )
    }

    const {traits, deviceType, subtype} = device || {}
    const {rssi, diagnostic} = traits || {}

    const isClearDeviceWidgetShown =
        isClearAvailable(subtype, protocols) &&
        isClearPermitted &&
        subtype === DEVICE_SUBTYPE_ZWAVE_RADIO

    return (
        <div className="blocks">
            <GeneralInfoTabBlock device={device} panelId={panelId} />
            {rssi !== undefined && renderRssi(rssi || {})}
            {isDiagnosticAllowed && diagnostic && renderDiagnostic(diagnostic)}
            {isPlinkAllowed && deviceType === DEVICE_TYPE_POWER_LINK && (
                <PlinkDebug panelId={panelId} />
            )}
            {isClearDeviceWidgetShown && <ClearDeviceWidget subtype={subtype} />}
        </div>
    )
}

GeneralInfoTab.propTypes = {
    device: PropTypes.shape({
        traits: PropTypes.shape({
            rarely_used: PropTypes.shape({
                progress: PropTypes.bool,
                enabled: PropTypes.bool.isRequired,
            }),
            bypass: PropTypes.shape({
                progress: PropTypes.bool,
                enabled: PropTypes.bool.isRequired,
            }),
            soak: PropTypes.shape({
                progress: PropTypes.bool,
                enabled: PropTypes.bool.isRequired,
            }),
            rssi: PropTypes.shape({
                average: PropTypes.oneOf(deviceSignals),
                current: PropTypes.oneOf(deviceSignals),
                channel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                repeater: PropTypes.number,
                triggeredAt: PropTypes.string,
            }),
        }),
    }).isRequired,
}
