import React from 'react'
import {__} from 'utils/i18n'

import {
    MAIN_INFORMATION,
    POWER_MAX,
    QUAZAR,
    QUAZAR_5_3,
    SMART_COMMUNICATOR,
    IQ4,
    GENERAL,
} from 'constants/groups'

const NavTabsForm = ({activeTab, handleTabClick}) => {
    const isActiveTab = (tabName) => {
        return tabName === activeTab
            ? 'group-form-tab-wrapper groups-tab-wrapper--active'
            : 'group-form-tab-wrapper'
    }

    return (
        <div className={'group-form-tabs-wrapper'}>
            <div
                className={isActiveTab(MAIN_INFORMATION)}
                onClick={() => handleTabClick(MAIN_INFORMATION)}
            >
                {__('Main information')}
            </div>
            <div className={isActiveTab(GENERAL)} onClick={() => handleTabClick(GENERAL)}>
                {__('General information')}
            </div>
            <div
                className={isActiveTab(POWER_MAX)}
                onClick={() => handleTabClick(POWER_MAX)}
            >
                {__('PowerMaster / PowerMax')}
            </div>
            <div className={isActiveTab(QUAZAR)} onClick={() => handleTabClick(QUAZAR)}>
                {__('PowerSeries Neo/Pro, Quazar <5.3 (Fibro)')}
            </div>
            <div
                className={isActiveTab(QUAZAR_5_3)}
                onClick={() => handleTabClick(QUAZAR_5_3)}
            >
                {__('PowerSeries Neo, Quazar 5.3 and higher (Fibro)')}
            </div>
            <div
                className={isActiveTab(SMART_COMMUNICATOR)}
                onClick={() => handleTabClick(SMART_COMMUNICATOR)}
            >
                {__('Smart Communicator')}
            </div>
            <div className={isActiveTab(IQ4)} onClick={() => handleTabClick(IQ4)}>
                {__('IQ Panel (Fibro)')}
            </div>
        </div>
    )
}

export default NavTabsForm
