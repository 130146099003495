import __ from 'utils/i18n'

export const PANEL_TEST_STATUS_START = 'pass'
export const PANEL_TEST_STATUS_FAILED = 'fail'
export const PANEL_TEST_STATUS_RUNNING = 'running'
export const CURRENT_STATUS_FIELD_NAME = 'test result'
export const LAST_STARTED_TIME_FIELD_NAME = 'last started time'
export const DEFAULT_ACTIVE_SYSTEM_TEST_GROUP = 'General'
export const SYSTEM_TEST_GROUP_ALL = 'All'

export const TEST_PANEL_TEST = 2
export const TEST_SIREN_TEST = 20

export const getTestDisclaimer = (group) => {
    switch (group) {
        case TEST_PANEL_TEST:
            return __('This test will disarm the panel if the panel is in arm mode')
        case TEST_SIREN_TEST:
            return __('This test will activate sirens')
        default:
            return null
    }
}

export const getClass = (status, key) => {
    if (key !== CURRENT_STATUS_FIELD_NAME) {
        return null
    }

    switch (status) {
        case PANEL_TEST_STATUS_START:
            return 'testPassed'
        case PANEL_TEST_STATUS_FAILED:
            return 'testFailed'
        default:
            return 'testInProgress'
    }
}
