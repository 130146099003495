import {__} from 'utils/i18n'
import {general, receiver, resolve, interactive} from 'permissions/settings/actions'
import virtualPanelRules from 'permissions/settings/virtualPanelRules'

export const VIRTUAL_PANEL_SETTINGS = 'virtualPanel'

export const tabs = {
    general: {
        label: () => __('General'),
        path: 'settings.general',
        end: true,
        permission: general,
    },
    receiver: {
        label: () => __('Receiver'),
        path: 'settings.receiver',
        permission: receiver,
    },
    resolve: {
        label: () => __('Resolve'),
        path: 'settings.resolve',
        permission: resolve,
    },
    interactive: {
        label: () => __('Interactive'),
        path: 'settings.interactive',
        permission: interactive,
    },
    eventRules: {
        label: () => __('Event Rules'),
        path: 'settings.eventRules',
        parent: VIRTUAL_PANEL_SETTINGS,
        permission: virtualPanelRules,
    },
    zoneTypes: {
        label: () => __('Zone Types'),
        path: 'settings.zoneTypes',
        parent: VIRTUAL_PANEL_SETTINGS,
        permission: virtualPanelRules,
    },
    notificationTypes: {
        label: () => __('Notification'),
        path: 'settings.notificationTypes',
        parent: VIRTUAL_PANEL_SETTINGS,
        permission: virtualPanelRules,
    },
    stateTypes: {
        label: () => __('State'),
        path: 'settings.stateTypes',
        parent: VIRTUAL_PANEL_SETTINGS,
        permission: virtualPanelRules,
    },
    eventTypes: {
        label: () => __('Events'),
        path: 'settings.eventTypes',
        parent: VIRTUAL_PANEL_SETTINGS,
        permission: virtualPanelRules,
    },
}
