import React from 'react'

import {pathParent} from 'utils/path'
import CentralStationPage from './CentralStationPage'

export default class {
    path = pathParent('centralStation')

    element = (<CentralStationPage />)
}
