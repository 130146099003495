import React, {useState, useEffect, useCallback} from 'react'
import PropTypes from 'prop-types'
import _sortBy from 'lodash-es/sortBy'

import Select, {Option} from 'ipmp-react-ui/Select'
import Togglebox from 'ipmp-react-ui/ToggleBox'

import {TOGGLE_KEYWORDS} from 'constants/configuration'

const ListValue = ({item, value, onChange}) => {
    const convertItems = useCallback((items) => {
        if (items.length === 2) {
            return _sortBy(items, [0])
        }
        return items
    }, [])

    const [items, setItems] = useState(convertItems(item.items))

    useEffect(() => {
        setItems(convertItems(item.items))
    }, [item.items, convertItems])

    const handleToggleChange = useCallback(
        (e) => {
            const newValue = e.target.checked ? items[1][0] : items[0][0]
            onChange(newValue)
        },
        [items, onChange]
    )

    const handleChange = useCallback(
        (e, newValue) => {
            onChange(newValue)
        },
        [onChange]
    )

    if (items.length < 2) {
        return items[value] ? items[value][1] : value
    }

    const isToggle = () => {
        const flattenedItems = items.flat().map((item) => item.toString().toLowerCase())

        return TOGGLE_KEYWORDS.some((keyword) => flattenedItems.includes(keyword))
    }

    if (items.length === 2 && isToggle()) {
        return (
            <Togglebox
                onChange={handleToggleChange}
                label={items[1][1]}
                checked={value === items[1][0]}
                labelOff={items[0][1]}
            />
        )
    }

    return (
        <Select value={value} onChange={handleChange}>
            {items.map(([key, val]) => (
                <Option key={key} label={val.trim()} value={key} />
            ))}
        </Select>
    )
}

ListValue.propTypes = {
    item: PropTypes.object.isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default ListValue
