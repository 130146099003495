import {useSelector} from 'react-redux'

import {selectForms} from 'modules/forms/selectors'
import {useActions} from 'modules/higherOrder/useActions'

export default function useForm(handler, {isLoading} = {}) {
    const forms = useSelector(selectForms)
    const {handle} = useActions({handle: handler})

    return {
        form: forms[handler] || {},
        handle,
        isLoading: isLoading || forms[handler]?.isLoading,
    }
}
