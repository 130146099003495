import {put, all, takeEvery, call} from 'redux-saga/effects'

import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import {eventInitiate} from 'modules/panels/one/actions'
import {POLL_EVENTS} from 'configs/pollers'
import {getVideo, initiateEvent} from 'api/events'
import {snackShow} from 'modules/snacks'
import {PROCESS_TYPE_EVENT_INITIATE} from 'constants/processTypes'

import * as actions from './actions'
import {update} from '../store/actions'
import {generateBatchForOneProcess} from '../../batches/manager/generateBatch'

export default function* () {
    yield all([
        takeEvery(actions.fetchVideo, watchFetchVideo),
        createListPollerSaga(actions, POLL_EVENTS, watchFetchVideo),
        takeEvery(eventInitiate, watchEventInitiate),
    ])
}

export function* watchFetchVideo({payload: {id}}) {
    const video = yield getVideo(id)
    yield put(update({id, video}))
}

function* watchEventInitiate({payload: {panelId, eventType, partitions}}) {
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_EVENT_INITIATE,
        panelId
    )

    try {
        yield call(initiateEvent, panelId, eventType, partitions, batchId)
    } catch (error) {
        yield put(snackShow(error.message))
        yield removeBatch()
    }
}
