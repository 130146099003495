import React from 'react'

import {__} from 'utils/i18n'
import Button from 'ipmp-react-ui/Button'
import {useActions} from 'modules/higherOrder/useActions'
import {stopClearDevice} from 'modules/devices/list/actions'
import {getProtocolBySubtype} from 'constants/protocols'

export default function StopClearDeviceButton({batch, flat, panelId, subtype, disabled}) {
    const {handleStopClear} = useActions({
        handleStopClear: () => stopClearDevice(panelId, getProtocolBySubtype(subtype)),
    })

    return (
        <Button
            disabled={disabled}
            flat={flat}
            primary
            panelId={panelId}
            onClick={handleStopClear}
            isRefreshing={!!batch}
            label={__('Stop Clear')}
        />
    )
}
