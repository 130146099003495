import React from 'react'
import {useSelector} from 'react-redux'

import {
    fetch,
    addFilters,
    removeFilters,
    clearFilters,
    setQuery,
} from 'modules/installers/list/actions'
import __ from 'utils/i18n'
import Search from 'components/Search/Search'
import {useActions} from 'modules/higherOrder/useActions'
import DateFilter from 'components/Search/Filters/DateFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import {selectInstallerListSearch} from 'modules/installers/list/selectors'

export default function InstallersSearch({className}) {
    const {filters: selected, query} = useSelector(selectInstallerListSearch)

    const {apply, onSelect, onDeselect, onClear, onQuery} = useActions({
        apply: fetch,
        onSelect: addFilters,
        onDeselect: removeFilters,
        onClear: clearFilters,
        onQuery: setQuery,
    })

    const filters = [
        new ValuesFilter('status', __('Status'), {
            pending: __('Pending'),
            accepted: __('Accepted'),
            rejected: __('Rejected'),
        }),
        new DateFilter('createdAt', __('Created')),
        new DateFilter('lastLogin', __('Last Login')),
    ].sort((item, next) => item.title.localeCompare(next.title))

    return (
        <Search
            apply={apply}
            onSelect={onSelect}
            onDeselect={onDeselect}
            onClear={onClear}
            onQuery={onQuery}
            query={query}
            filters={filters}
            selected={selected}
            className={className}
            placeholder={__('Search Installers')}
        />
    )
}
