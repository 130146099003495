import React from 'react'

import Spinner from 'ipmp-react-ui/Spinner'
import {TableGrouped} from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import {CrossServerSearchContext} from 'pages/Panels/CrossServerSearch/CrossServerSearch'

const columns = [
    {
        render: ({account}) => account,
    },
    {
        render: ({serial}) => serial,
    },
    {
        render: ({model}) => model,
    },
]

const separatorCallback = ({domain}) => domain
const onRowClick = ({url, id}) => window.open(url + '/panel/' + id, '_blank')

export default function CrossServerSearchResultsTable() {
    return (
        <CrossServerSearchContext.Consumer>
            {({results, isLoading}) => {
                if (isLoading) {
                    return <Spinner />
                }

                if (!results) {
                    return null
                }

                return (
                    <TableGrouped
                        rows={results}
                        columns={columns}
                        onRowClick={onRowClick}
                        className="crossServerBlind-table"
                        separatorCallback={separatorCallback}
                        emptyMessage={__('No equipment')}
                    />
                )
            }}
        </CrossServerSearchContext.Consumer>
    )
}
