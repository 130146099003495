import React from 'react'
import PropTypes from 'prop-types'
import {useSelector} from 'react-redux'

import {OverflowTextTooltip} from 'ipmp-react-ui/Tooltip'

import __ from 'utils/i18n'
import {selectEventDescriptionById} from 'modules/eventTypes/store/selectors'

export default function EventTypeCell({row: {eventId, isRestore}}) {
    const {description} = useSelector((state) =>
        selectEventDescriptionById(state, {id: eventId})
    )

    const tooltip = isRestore ? __('restore') + ' ' + description : description

    return (
        <OverflowTextTooltip className="evenRules-elements-name">
            {tooltip}
        </OverflowTextTooltip>
    )
}

EventTypeCell.propTypes = {
    row: PropTypes.shape({
        eventId: PropTypes.number.isRequired,
        isRestore: PropTypes.bool,
    }),
}
