import React from 'react'

import Page from 'ipmp-react-ui/Page'

import FirmwareUpgradeTable from './FirmwareUpgradeTable'
import FirmwareUpgradeGridBar from './FirmwareUpgradeGridBar'

export default function FirmwareUpgradePage() {
    return <Page Bar={FirmwareUpgradeGridBar} Content={FirmwareUpgradeTable} />
}
