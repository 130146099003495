import React, {Fragment} from 'react'
import PropTypes from 'prop-types'

import Input from 'ipmp-react-ui/Input'
import Checkbox from 'ipmp-react-ui/Checkbox'
import {ReactComponent as IconNumber} from 'ipmp-react-ui/icons/icons_number.svg'
import {ReactComponent as IconUser} from 'ipmp-react-ui/icons/icons_user.svg'

import {PartitionSelect} from 'components/Panel'
import {UserSelect} from 'components/Panel/PanelUser'
import SelectProtocol from 'components/Devices/SelectProtocol'
import {useActions} from 'modules/higherOrder/useActions'
import {DTP_POWERG, DTP_SRF} from 'constants/protocols'
import {clear} from 'modules/forms/actions'
import __ from 'utils/i18n'

const PowerGFields = ({
    panelId,
    deviceId,
    handleDeviceIdChange,
    handleDeviceIdKeyDown,
    showPartitions,
    showUser,
    isUserNumber,
    deviceSubtype,
    isKeyfob,
    setUserWithoutPin,
    setProtocol,
    isSRFEnabled,
    isZoneApplicable,
}) => {
    const {clearForm} = useActions({clearForm: clear})

    return (
        <Fragment>
            <SelectProtocol
                onChange={setProtocol}
                defaultValue={DTP_POWERG}
                protocols={[DTP_POWERG, DTP_SRF]}
                isDisabled={!isSRFEnabled}
            />
            <Input
                label={__('Enrollment ID')}
                name="deviceId"
                type="text"
                maxLength={10}
                value={deviceId}
                onChange={(e) => {
                    clearForm()
                    handleDeviceIdChange(e)
                }}
                onKeyDown={handleDeviceIdKeyDown}
                Icon={IconNumber}
                iconLeft
            />

            {isZoneApplicable && (
                <Input
                    label={__('Zone Number')}
                    name="zoneId"
                    type="number"
                    min="1"
                    onChange={clearForm}
                    Icon={IconNumber}
                    iconLeft
                />
            )}

            {!!showPartitions && (
                <PartitionSelect
                    enrollmentPrefix={deviceId?.match(/\d*/)?.[0]}
                    showPartitions={showPartitions}
                    panelId={panelId}
                />
            )}

            {!!showUser && (
                <Fragment>
                    {isKeyfob(deviceSubtype) && (
                        <Checkbox
                            onChange={(e) => {
                                clearForm()
                                setUserWithoutPin(e)
                            }}
                            label={__('User without pin-code')}
                        />
                    )}
                    {isUserNumber ? (
                        <Input
                            label={__('User Number')}
                            name="userId"
                            onChange={clearForm}
                            Icon={IconUser}
                            iconLeft
                        />
                    ) : (
                        <UserSelect
                            label={__('User')}
                            name="userId"
                            deviceSubtype={deviceSubtype}
                            panelId={panelId}
                            IconLeft={IconUser}
                        />
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

PowerGFields.propTypes = {
    setProtocol: PropTypes.func.isRequired,
    isSRFEnabled: PropTypes.bool.isRequired,
    isZoneApplicable: PropTypes.bool.isRequired,
}

export default PowerGFields
