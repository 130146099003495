import React, {useState, forwardRef} from 'react'

import {useDispatch} from 'react-redux'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {CardActions, CardContent, CardHeader, CardClose} from 'ipmp-react-ui/Card'
import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Modal from 'ipmp-react-ui/Modal'
import Portal from 'ipmp-react-ui/Portal'
import CardMessage from 'ipmp-react-ui/CardMessage'

import {__} from 'utils/i18n'
import stopPropagation from 'utils/stopPropagation'
import {clear as errorClear} from 'modules/forms/actions'

const commonPropTypes = {
    wide: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf([2, 3, 4])]),
    className: PropTypes.string,
    header: PropTypes.node,
    isLoading: PropTypes.bool,
    isSubmitDisable: PropTypes.bool,
    editable: PropTypes.bool,
    showDismiss: PropTypes.bool,
    submitLabel: PropTypes.string,
    dissmissLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    confirmOnDismiss: PropTypes.bool,
    confirmOnSubmit: PropTypes.bool,
    hide: PropTypes.func,
    onRef: PropTypes.func,
}

const ModalCardForm = forwardRef(
    (
        {
            wide,
            className,
            header,
            isLoading,
            isSubmitDisable,
            editable = true,
            showDismiss = true,
            confirmOnDismiss = false,
            submitLabel,
            dissmissLabel,
            onSubmit,
            confirmOnSubmit,
            hide,
            ...props
        },
        ref
    ) => {
        const [changed, setChanged] = useState(false)
        const [showDismissConfirmation, setShowDismissConfirmation] = useState(false)
        const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false)
        const [submitData, setSubmitData] = useState(null)
        const dispatch = useDispatch()

        const dismiss = (e) => {
            e && e.preventDefault()

            if (confirmOnDismiss && changed) {
                setShowDismissConfirmation(true)
            } else {
                hide && hide(e)
                dispatch(errorClear())
            }
        }

        const onChange = (...args) => {
            setChanged(true)
            props.onChange && props.onChange(...args)
        }

        const agreeDismiss = () => {
            hide && hide()
            dispatch(errorClear())
        }
        const cancelDismiss = () => setShowDismissConfirmation(false)

        const submit = (data) => {
            if (confirmOnSubmit) {
                setShowSubmitConfirmation(true)
                setSubmitData(data)
            } else {
                onSubmit && onSubmit(data)
            }
        }

        const agreeSubmit = () => {
            setShowSubmitConfirmation(false)
            onSubmit && onSubmit(submitData)
        }

        const cancelSubmit = () => setShowSubmitConfirmation(false)

        const renderCardActions = () => {
            return (
                <CardActions>
                    {hide && showDismiss && (
                        <Button borderless shortcut="esc" onClick={dismiss}>
                            {dissmissLabel || __('Dismiss')}
                        </Button>
                    )}

                    {editable && (
                        <Button
                            primary
                            disabled={isLoading || isSubmitDisable}
                            type="submit"
                        >
                            {submitLabel || __('Save')}
                        </Button>
                    )}
                </CardActions>
            )
        }

        delete props.confirmOnDismiss

        return (
            <Modal
                onClose={dismiss}
                preventCloseOnEsc={false}
                preventCloseOnOutsideClick={false}
            >
                <Form
                    {...props}
                    ref={ref}
                    onSubmit={submit}
                    onChange={onChange}
                    className={classes('card', className, {
                        'card--wide': wide === true,
                        'card--wide-1': wide === 1,
                        'card--wide-2': wide === 2,
                        'card--wide-3': wide === 3,
                        'card--wide-4': wide === 4,
                    })}
                    isLoading={isLoading}
                >
                    {hide && <CardClose onClick={dismiss} />}
                    {header && <CardHeader>{header}</CardHeader>}
                    <CardContent>{props.children}</CardContent>
                    {props.renderCustomActions
                        ? props.renderCustomActions()
                        : renderCardActions()}
                </Form>

                {showDismissConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={dismiss}>
                            <CardMessage
                                title={__('Closing form')}
                                message={__(
                                    'All data will be lost. Do you want to proceed?'
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={agreeDismiss}
                                onNegative={cancelDismiss}
                                onClose={cancelDismiss}
                            />
                        </Modal>
                    </Portal>
                )}

                {showSubmitConfirmation && (
                    <Portal onClick={stopPropagation} key="portal">
                        <Modal onClose={dismiss}>
                            <CardMessage
                                title={__('Submit form')}
                                message={__(
                                    'All data will be submitted. Do you want to proceed?'
                                )}
                                positive={__('Confirm')}
                                negative={__('Cancel')}
                                onPositive={agreeSubmit}
                                onNegative={cancelSubmit}
                                onClose={cancelSubmit}
                            />
                        </Modal>
                    </Portal>
                )}
            </Modal>
        )
    }
)

export default ModalCardForm

export const ModalCardFormWithCustomActions = forwardRef((props, ref) => {
    const {CustomActions, editable, isLoading} = props

    const renderCustomActions = () => (
        <CustomActions
            onDismiss={props.dismiss}
            isLoading={isLoading}
            editable={editable}
        />
    )

    return (
        <ModalCardForm {...props} ref={ref} renderCustomActions={renderCustomActions} />
    )
})

ModalCardFormWithCustomActions.propTypes = {
    ...commonPropTypes,
    CustomActions: PropTypes.func,
}
