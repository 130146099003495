import React from 'react'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconEdit} from 'ipmp-react-ui/icons/edit.svg'

import usePermission from 'hooks/usePermission'
import {withVisibility} from 'containers/withVisibility'
import {PANEL_MASTER_USER_ID} from 'constants/panelUsers'
import {useActions} from 'modules/higherOrder/useActions'
import {isAllowManipulateUser} from 'constants/accountRoles'
import {setPartitions} from 'permissions/panel/customers/actions'
import {showSetUserPartitionsModal} from 'modules/modals/actions'
import {USER_FEATURE_PARTITIONS} from 'constants/features'
import {selectUserEditableFeature} from 'modules/features/store/selectors'

const ButtonVisibility = withVisibility()(Button)

export default function SetPartitionsButton({
    panelId,
    panelUserId,
    isPinSet,
    role,
    partitions,
}) {
    const {isPermitted} = usePermission(setPartitions)
    const isFeatureEnabled = useSelector((state) =>
        selectUserEditableFeature(state, {
            panelId,
            item: USER_FEATURE_PARTITIONS,
        })
    )

    const {onClick} = useActions({
        onClick: () => showSetUserPartitionsModal({panelId, panelUserId, partitions}),
    })

    const disabled =
        !(isFeatureEnabled && isPinSet) ||
        panelUserId === PANEL_MASTER_USER_ID ||
        !isAllowManipulateUser(role)

    return (
        <ButtonVisibility
            flat
            small
            Icon={IconEdit}
            onClick={onClick}
            disabled={disabled}
            isVisible={isPermitted}
        />
    )
}
