import {all, takeEvery, call, put} from 'redux-saga/effects'

import {snackShow} from 'modules/snacks'

import * as api from 'api/group'
import * as actions from './actions'
import {editEventsProfileEventProfiles} from 'modules/forms/handlers'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(editEventsProfileEventProfiles.SUCCESS, watchEditProfiles),
    ])
}

function* watchFetch({payload: id}) {
    try {
        const eventProfilesNotifications = yield call(
            api.receiveGroupNotificationsEventProfiles,
            id
        )

        yield put(
            actions.receive({
                notifications: {
                    ...eventProfilesNotifications,
                    availableProfiles: {
                        ...eventProfilesNotifications.availableProfiles,
                    },
                },
                id,
            })
        )
    } catch (error) {
        yield put(actions.receive(error))
        yield put(snackShow(error.message))
    }
}

function* watchEditProfiles({meta}) {
    try {
        yield put(actions.update({...meta}))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
