import React from 'react'
import {connect} from 'react-redux'

import getHocName from 'utils/getHocName'

/**
 * @deprecated
 * Use filter in `withSelectionHandler`. It will filter onClick array as well
 */
export function ifSelection(filter) {
    return (WrappedComponent) => {
        const fn = ({isHidden, ...props}) =>
            isHidden ? null : <WrappedComponent {...props} />

        fn.displayName = getHocName('ifSelection', WrappedComponent)

        return connect(
            (state) => {
                const visible = filter
                    ? state.selection.some((id) => filter(id, state))
                    : !state.selection.isEmpty()

                return {
                    isHidden: !visible,
                }
            },
            () => ({})
        )(fn)
    }
}

export function withSelectionHandler(filter) {
    return (WrappedComponent) =>
        connect((state) => {
            const selection = filter
                ? state.selection
                      .toJS()
                      .filter((item, index, selectionArray) =>
                          filter(item, state, index, selectionArray)
                      )
                : state.selection.toJS()

            return {
                selection,
            }
        })(
            class extends React.Component {
                static displayName = getHocName('WithSelectionHandler', WrappedComponent)

                onClick = () => {
                    this.props.onClick && this.props.onClick(this.props.selection)
                }

                shouldComponentUpdate(nextProps, nextState, nextContext) {
                    const {selection} = this.props

                    const wasVisible = !!selection.length
                    const shouldVisible = !!nextProps.selection.length

                    return wasVisible !== shouldVisible
                }

                render() {
                    const {selection, ...props} = this.props

                    if (!selection.length) {
                        return null
                    }

                    return <WrappedComponent {...props} onClick={this.onClick} />
                }
            }
        )
}
