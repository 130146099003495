import {all, call, put, takeEvery} from 'redux-saga/effects'
import uniqBy from 'lodash-es/uniqBy'

import * as api from 'api/panel/firmware'
import {patchTagUpdate} from 'api/firmware'

import * as actions from './actions'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'

import {generateBatchForOneProcess} from 'modules/batches/manager/generateBatch'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'
import {hide} from 'modules/modals/actions'
import createListPollerSaga from 'modules/higherOrder/createListPollerSaga'
import watch from 'modules/higherOrder/watch'
import {POLL_UPGRADE_RUNNERS_STATUS} from 'configs/pollers'

export default function* () {
    yield all([
        createListPollerSaga(actions, POLL_UPGRADE_RUNNERS_STATUS, fetch),
        takeEvery(actions.fetch, watch(fetch)),
        takeEvery(actions.upgrade, watchUpgrade),
        takeEvery(actions.patchTagUpgrade, watchPatchTag),
        takeEveryProcessComplete(PROCESS_TYPE_NEO_UPGRADE, watchProcessComplete),
    ])
}

function* fetch({payload: {panelId}}) {
    const {appliances, runner} = yield call(api.fetchList, panelId)
    yield put(
        actions.receive(
            {
                appliances,
                runner,
            },
            panelId
        )
    )
}

fetch.onError = function* (error, {payload: {panelId}}) {
    yield put(actions.receive(error, panelId))
}

function* watchPatchTag({payload}) {
    const {panelId, timeout, packageName} = payload

    yield runUpgrade(panelId, function* (batchId) {
        return yield call(patchTagUpdate, {
            packageName,
            batchId,
            timeout,
            panelIds: [panelId],
            filters: {},
        })
    })
}

function* watchUpgrade({payload}) {
    const {panelId, packages, timeout, failOnArmedState} = payload

    yield runUpgrade(panelId, function* (batchId) {
        return yield call(
            api.upgrade,
            panelId,
            uniqBy(packages, 'packageName'),
            timeout,
            failOnArmedState,
            batchId
        )
    })
}

function* runUpgrade(panelId, runnerCallback) {
    const {batchId, removeBatch} = yield generateBatchForOneProcess(
        PROCESS_TYPE_NEO_UPGRADE,
        panelId
    )

    try {
        const runner = yield runnerCallback(batchId)

        yield put(hide())
        yield put(
            actions.receive(
                {
                    runner,
                },
                panelId
            )
        )
    } catch ({message}) {
        yield put(actions.receive({runner: null}, panelId))
        yield put(snackShow(message))
        yield removeBatch()
    }
}

function* watchProcessComplete({panelId}) {
    yield put(fetch(panelId))
}
