import React, {useState} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Form from 'ipmp-react-ui/Form'
import Button from 'ipmp-react-ui/Button'
import Range from 'ipmp-react-ui/Range'
import formatError from 'ipmp-react-ui/_utils/formatError'
import {ReactComponent as IconPower} from 'ipmp-react-ui/icons/power.svg'

import __ from 'utils/i18n'
import {resolveDimmerButtonLabel} from 'constants/had/dimmer'
import useForm from 'hooks/useForm'
import {isOff, isOn, reverseHadStateMap} from 'constants/had/hadState'
import HadControlForm from 'modules/panels/had/utils/hadControlForm'
import {resolveDimmerCommands} from 'constants/had/command'
import {withPermissionRejection} from 'containers/withPermission'
import {dimmerLevel} from 'permissions/panel/output/actions'
import {submitHadDeviceControlForm} from 'modules/forms/handlers'

const rules = {
    dimmerLevel: {
        format: {
            pattern: /^-?\d+$/,
            message: () => __('Invalid value. Must be digits only'),
        },
        presence: (_, val) => val,
        numericality: {
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 100,
        },
    },
}
const DimmerControlFormBase = ({errors, isLoading, handle, had, panelId}) => {
    const [dimmerMode, setDimmerMode] = useState(had?.state?.pgm)
    const onSubmitHandler = (formData) => {
        handle &&
            handle(
                new HadControlForm({
                    commands: resolveDimmerCommands({
                        dimmerMode:
                            dimmerMode === had?.state?.pgm ? undefined : dimmerMode,
                        dimmerLevel:
                            formData?.dimmerLevel && Number(formData?.dimmerLevel),
                        supportedCommands: had?.supportedCommands,
                    }),
                    params: {hadId: had?.id, panelId, deviceType: had?.deviceType},
                })
            )
    }
    const onClickButtonHandler = () => {
        setDimmerMode(reverseHadStateMap.get(dimmerMode))
    }

    const label = resolveDimmerButtonLabel(dimmerMode)

    return (
        <Form
            className="dimmerControlForm"
            errors={errors}
            isLoading={isLoading}
            onSubmit={onSubmitHandler}
            rules={rules}
        >
            <div
                className={classnames('form-field', {
                    'form-field--error': errors?.mode,
                })}
            >
                <h3 className="form-field-label">{__('Modes')}</h3>
                <Button
                    className={classnames('dimmerControlForm-stateButton', {
                        'dimmerControlForm-stateButton--stateOn': isOff(dimmerMode),
                        'dimmerControlForm-stateButton--stateOff': isOn(dimmerMode),
                    })}
                    onClick={onClickButtonHandler}
                    Icon={IconPower}
                    label={label}
                />
                {errors?.mode && (
                    <span className="form-field-error">{formatError(errors?.mode)}</span>
                )}
            </div>
            {isOn(dimmerMode) && (
                <Range
                    className="dimmerControlForm-range"
                    label={__('Dimmer Control')}
                    name="dimmerLevel"
                    min={0}
                    max={100}
                    minmaxSuffix="%"
                    defaultValue={had?.state?.dimmer?.level}
                />
            )}
        </Form>
    )
}

const DimmerControlFormRejection =
    withPermissionRejection(dimmerLevel)(DimmerControlFormBase)

export default function DimmerControlForm({had, panelId}) {
    const {handle, isLoading, form} = useForm(submitHadDeviceControlForm)

    return (
        <DimmerControlFormRejection
            {...form}
            had={had}
            handle={handle}
            panelId={panelId}
            isLoading={isLoading}
        />
    )
}

DimmerControlForm.propTypes = {
    had: PropTypes.shape({
        id: PropTypes.number,
        state: PropTypes.object,
        type: PropTypes.number,
    }),
    panelId: PropTypes.number,
}
