import React from 'react'

import {ReactComponent as IconNotification} from 'ipmp-react-ui/icons/alarm.svg'
import {ReactComponent as IconStateType} from 'ipmp-react-ui/icons/cloud.svg'
import {ReactComponent as IconEventType} from 'ipmp-react-ui/icons/calendar.svg'

import {ReactComponent as IconZoneType} from 'icons/zone-type.svg'
import {ReactComponent as IconSave} from 'icons/save.svg'
import {warn} from 'utils/log'
import __ from 'utils/i18n'

export const EVENT_RULE_MASTER_STEP_ZONE_TYPE = 'EVENT_RULE_MASTER_STEP_ZONE_TYPE'
export const EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE =
    'EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE'
export const EVENT_RULE_MASTER_STEP_STATE_TYPE = 'EVENT_RULE_MASTER_STEP_STATE_TYPE'
export const EVENT_RULE_MASTER_STEP_EVENT_TYPE = 'EVENT_RULE_MASTER_STEP_EVENT_TYPE'
export const EVENT_RULE_MASTER_STEP_SAVE = 'EVENT_RULE_MASTER_STEP_SAVE'

// hack to provide null(wildcard) value
export const EVENT_RULE_ZONE_TYPE_WILD_CARD = Infinity
export const EVENT_RULE_STATE_TYPE_WILD_CARD = Infinity

export const EVENT_RULE_ZONE_TYPE_FEATURE_NULL = 0
export const EVENT_RULE_ZONE_TYPE_FEATURE_DELAY = 1

export const eventRuleWildCardTitle = () => __('Wild Card')

export const getZoneTypeName = (store, id) => {
    return id === EVENT_RULE_ZONE_TYPE_WILD_CARD
        ? eventRuleWildCardTitle()
        : store[id]?.name
}

export const getStateTypeName = (store, id) => getZoneTypeName(store, id)

export const steps = [
    EVENT_RULE_MASTER_STEP_ZONE_TYPE,
    EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE,
    EVENT_RULE_MASTER_STEP_STATE_TYPE,
    EVENT_RULE_MASTER_STEP_EVENT_TYPE,
    EVENT_RULE_MASTER_STEP_SAVE,
]

export class EventRuleStep {
    constructor(step) {
        this.currentStep = step
    }

    get currentIndex() {
        return steps.indexOf(this.currentStep)
    }

    get isLastStep() {
        return this.currentIndex === steps.length - 1
    }

    get isFirstStep() {
        return this.currentIndex === 0
    }

    get nextStep() {
        if (this.isLastStep) {
            return false
        }

        return steps[this.currentIndex + 1]
    }

    get prevStep() {
        if (this.isFirstStep) {
            return false
        }

        return steps[this.currentIndex - 1]
    }
}

export function getEventRuleStepsClassNameEnd(step) {
    switch (step) {
        case EVENT_RULE_MASTER_STEP_ZONE_TYPE:
            return 'zone'
        case EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE:
            return 'notification'
        case EVENT_RULE_MASTER_STEP_STATE_TYPE:
            return 'state'
        case EVENT_RULE_MASTER_STEP_EVENT_TYPE:
            return 'event'
        case EVENT_RULE_MASTER_STEP_SAVE:
            return 'save'
    }
}

export function getEventRuleStepsClassNames(step) {
    return {
        'eventRules-master-steps__zone': step === EVENT_RULE_MASTER_STEP_ZONE_TYPE,
        'eventRules-master-steps__notification':
            step === EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE,
        'eventRules-master-steps__state': step === EVENT_RULE_MASTER_STEP_STATE_TYPE,
        'eventRules-master-steps__event': step === EVENT_RULE_MASTER_STEP_EVENT_TYPE,
        'eventRules-master-steps__save': step === EVENT_RULE_MASTER_STEP_SAVE,
    }
}

export function getEventRuleStepIcon(step) {
    switch (step) {
        case EVENT_RULE_MASTER_STEP_ZONE_TYPE:
            return <IconZoneType />
        case EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE:
            return <IconNotification />
        case EVENT_RULE_MASTER_STEP_STATE_TYPE:
            return <IconStateType />
        case EVENT_RULE_MASTER_STEP_EVENT_TYPE:
            return <IconEventType />
        case EVENT_RULE_MASTER_STEP_SAVE:
            return <IconSave />
    }

    warn(`Unknown step ${step}`)

    return null
}

export function getEventRuleStepTitle(step) {
    switch (step) {
        case EVENT_RULE_MASTER_STEP_ZONE_TYPE:
            return __('IoT Device')
        case EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE:
            return __('Notification')
        case EVENT_RULE_MASTER_STEP_STATE_TYPE:
            return __('Virtual Panel')
        case EVENT_RULE_MASTER_STEP_EVENT_TYPE:
            return __('Event')
        case EVENT_RULE_MASTER_STEP_SAVE:
            return __('Receiver')
    }

    warn(`Unknown step ${step}`)

    return null
}

export const ZONE_TYPE_NAME = 'zoneTypeId'
export const NOTIFICATION_TYPE_NAME = 'notificationId'
export const STATE_TYPE_NAME = 'stateId'
export const EVENT_TYPE_NAME = 'eventId'

export const EVENT_TYPE_NAME_RESTORE = 'isRestore'

export function getEvenRuleFormValidationRules(step) {
    switch (step) {
        case EVENT_RULE_MASTER_STEP_ZONE_TYPE:
            return {
                [ZONE_TYPE_NAME]: {
                    presence: true,
                },
            }
        case EVENT_RULE_MASTER_STEP_NOTIFICATION_TYPE:
            return {
                [NOTIFICATION_TYPE_NAME]: {
                    presence: true,
                },
            }
        case EVENT_RULE_MASTER_STEP_STATE_TYPE:
            return {
                [STATE_TYPE_NAME]: {
                    presence: true,
                },
            }
        case EVENT_RULE_MASTER_STEP_EVENT_TYPE:
            return {
                [EVENT_TYPE_NAME]: {
                    presence: true,
                },
            }
        case EVENT_RULE_MASTER_STEP_SAVE:
            return {
                [ZONE_TYPE_NAME]: {
                    presence: true,
                },
                [NOTIFICATION_TYPE_NAME]: {
                    presence: true,
                },
                [STATE_TYPE_NAME]: {
                    presence: true,
                },
                [EVENT_TYPE_NAME]: {
                    presence: true,
                },
            }
    }
}

export const isZoneTypeDelayEnabled = (zoneTypeFeatures) =>
    zoneTypeFeatures[EVENT_RULE_ZONE_TYPE_FEATURE_DELAY]
