import React from 'react'

import Page from 'ipmp-react-ui/Page'

import {tabs} from './SettingsPageRouting'
import usePermission from 'hooks/usePermission'
import SettingsPageTabs from './SettingsPageTabs'
import permission from 'permissions/settings/page'
import {withPermissionRejection} from 'containers/withPermission'
import SettingsPageContent from './SettingsPageContent'

const PageWithRejection = withPermissionRejection(permission)(Page)

function SettingsPage() {
    const permissionToCheck = Object.entries(tabs).reduce((items, [key, tab]) => {
        items[key] = tab.permission

        return items
    }, {})

    const {menuPermission} = usePermission({
        menuPermission: permissionToCheck,
    })

    const filteredMenu = Object.keys(tabs).reduce((items, key) => {
        if (menuPermission[key]) {
            items[key] = tabs[key]
        }

        return items
    }, {})

    return (
        <PageWithRejection
            menu={filteredMenu}
            Top={SettingsPageTabs}
            Content={SettingsPageContent}
        />
    )
}

export default React.memo(SettingsPage)
