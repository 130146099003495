import {createAction} from 'redux-actions'

import * as blinds from './constants'

export const hide = createAction('NAV_BLIND/HIDE', () => null)
export const show = createAction('NAV_BLIND/SHOW', (type, args) => ({type, args}))
export const scroll = createAction('NAV_BLIND/SCROLL', (top) => ({top}))

export function showBatchInfoNavBlind(batchId) {
    return show(blinds.NAV_BLIND_BATCH_INFO, {batchId})
}

export function showRecentHistoryNavBlind() {
    return show(blinds.NAV_BLIND_RECENT_HISTORY)
}
