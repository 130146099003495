import React, {useMemo} from 'react'

import {DTP_UNKNOWN} from 'constants/protocols'

import IdleContentAutomation from './IdleContentAutomation'
import IdleContentSecurity from './IdleContentSecurity'
import IdleButtons from './IdleButtons'

export default function useIdleScreen(
    session,
    setProtocol,
    onDismiss,
    automationProtocols
) {
    return useMemo(
        () =>
            session?.token
                ? null
                : {
                      Content: () =>
                          DTP_UNKNOWN === session?.protocol ? (
                              <IdleContentSecurity
                                  session={session}
                                  onChange={setProtocol}
                              />
                          ) : (
                              <IdleContentAutomation
                                  session={session}
                                  onChange={setProtocol}
                                  protocols={automationProtocols}
                                  currentProtocol={session?.protocol}
                              />
                          ),
                      Actions: () => (
                          <IdleButtons session={session} onDismiss={onDismiss} />
                      ),
                  },
        [session.token, session.protocol]
    )
}
