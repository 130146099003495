import {createAction} from 'redux-actions'

export const fetch = createAction('PANELS/BILLING_PLAN/FETCH', (panelId) => panelId)
export const receive = createAction(
    'PANELS/BILLING_PLAN/RECEIVE',
    (data) => data,
    (_, panelId) => ({panelId})
)
export const startPoll = createAction(
    'PANELS/BILLING_PLAN/START_POLL',
    (panelId) => panelId
)
export const stopPoll = createAction('PANELS/BILLING_PLAN/STOP_POLL')
