import React from 'react'
import PropTypes from 'prop-types'
import {Route, Routes, Navigate} from 'react-router-dom'
import {ACCOUNT_TAB} from 'pages/Accounts/AccountsRouting'

import path, {pathLastChild} from 'utils/path'
import InteractiveUsersPage from 'pages/InteractiveUsers/InteractiveUsersPage'
import InstallersPage from 'pages/Installers/InstallersPage'

const componentsMap = new Map([
    [ACCOUNT_TAB.INTERACTIVE_USERS, <InteractiveUsersPage />],
    [ACCOUNT_TAB.INSTALLERS, <InstallersPage />],
])

const AccountsContent = ({tabs}) => {
    const firstPathAvailablePath = tabs.entries().next()?.value[1]?.path

    return (
        <section className="accountsPage-content">
            <Routes>
                {[...tabs].map(([key, {path: pathName}]) => (
                    <Route
                        path={pathLastChild(pathName)}
                        element={componentsMap.get(key)}
                        key={key}
                    />
                ))}
                <Route
                    path="*"
                    element={<Navigate to={path(firstPathAvailablePath)} />}
                />
            </Routes>
        </section>
    )
}

AccountsContent.propTypes = {
    tabs: PropTypes.instanceOf(Map).isRequired,
}

export default AccountsContent
