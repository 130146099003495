import React from 'react'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'

import Button from 'ipmp-react-ui/Button'

import __, {__n} from 'utils/i18n'
import usePermission from 'hooks/usePermission'
import {upgradeList} from 'permissions/firmware/upgrade'
import {withVisibility} from 'containers/withVisibility'
import {IdSetSubstractive} from 'modules/selection/store'
import {useActions} from 'modules/higherOrder/useActions'
import {showFirmwareUpgradeModal} from 'modules/modals/actions'
import {selectFirmwareListTotal} from 'modules/firmware/list/selectors'

const ButtonVisibility = withVisibility()(Button)

export default function UpgradeByFilters({applianceType, selection}) {
    const {isPermitted} = usePermission(upgradeList)
    const {applianceId, upgradePackageId} = useParams()
    const {total: totalList} = useSelector(selectFirmwareListTotal)

    const {onClick} = useActions(
        {
            onClick: () =>
                showFirmwareUpgradeModal({
                    selection,
                    applianceId: parseInt(applianceId),
                    upgradePackageId: parseInt(upgradePackageId),
                    type: applianceType,
                    upgradeAll:
                        selection.isEmpty() || selection instanceof IdSetSubstractive,
                }),
        },
        [applianceId, upgradePackageId, applianceType, selection]
    )

    const count = selection.count()
    const total = selection.isEmpty() ? totalList : count

    const label =
        total === null || total === Infinity
            ? __('Upgrade all panels')
            : __n('Upgrade %d panel', 'Upgrade all %d panels', total)

    return (
        <ButtonVisibility isVisible={isPermitted} onClick={onClick}>
            {label}
        </ButtonVisibility>
    )
}
