import {createSelector} from 'reselect'

export const selectBasicConfigurationsListRows = createSelector(
    (state) => state.basicConfiguration.list.page,
    (state) => state.basicConfiguration.store.byIds,
    (page, store) => page.map((id) => store[id] || {})
)

export const selectBasicConfigurationList = createSelector(
    (state) => state.basicConfiguration.list,
    (list) => list
)
