import React from 'react'
import PropTypes from 'prop-types'

import InfoCards, {
    InfoCard,
    InfoCardTile,
    InfoCardTileContent,
    InfoCardTileTitle,
} from 'ipmp-react-ui/InfoCards'

import PowerMaster from './Communications/PowerMaster'
import Quazar from './Communications/Quazar'
import Quazar53 from './Communications/Quazar53'
import SmartCommunicator from './Communications/SmartCommunicator'
import IQ4FibroPanels from './Communications/IQ4Fibro'
import EditGroupButton from 'pages/Group/GroupBar/EditGroupButton'

import {__} from 'utils/i18n'
import {general} from 'permissions/group/navigation'
import {withPermissionRejection} from 'containers/withPermission'
import {convertConfigValue} from 'utils/gettext'
import {
    GENERAL,
    IQ4,
    MAIN_INFORMATION,
    POWER_MAX,
    QUAZAR,
    QUAZAR_5_3,
    SMART_COMMUNICATOR,
} from 'constants/groups'

function PowerMasterCard({group}) {
    if (!group.communications.pmaster) {
        return null
    }

    return (
        <InfoCard
            cols={2}
            title={__('PowerMaster/PowerMax')}
            headerButton={<EditGroupButton group={group} activeTab={POWER_MAX} />}
        >
            <PowerMaster
                settings={group.communications.pmaster}
                isEncryptionEnabled={group.BBAEncryption}
                pscConnection={group.pscConnection}
                enableTemperatureAndLight={group.enableTemperatureAndLight}
                upgradeMethod={group.upgradeMethod}
            />
        </InfoCard>
    )
}

function QuazarCard({group}) {
    if (!group.communications.quazar) {
        return null
    }

    return (
        <InfoCard
            cols={2}
            title={__('PowerSeries NEO/Pro, Quazar <5.3 (Fibro)')}
            headerButton={<EditGroupButton group={group} activeTab={QUAZAR} />}
        >
            <Quazar settings={group.communications.quazar} />
        </InfoCard>
    )
}

function Quazar53Card({group}) {
    if (!group.communications.quazar53) {
        return null
    }

    return (
        <InfoCard
            cols={2}
            title={__('PowerSeries NEO, Quazar 5.3 and higher (Fibro)')}
            headerButton={<EditGroupButton group={group} activeTab={QUAZAR_5_3} />}
        >
            <Quazar53 settings={group.communications.quazar53} />
        </InfoCard>
    )
}

function SmartCommunicatorCard({group}) {
    if (!group.communications.smart_comm) {
        return null
    }

    return (
        <InfoCard
            cols={2}
            title={__('Smart Communicator')}
            headerButton={
                <EditGroupButton group={group} activeTab={SMART_COMMUNICATOR} />
            }
        >
            <SmartCommunicator settings={group.communications.smart_comm.any} />
        </InfoCard>
    )
}

function IQ4FibroCard({group}) {
    if (!group.communications.iq4_fibro) {
        return null
    }

    return (
        <InfoCard
            cols={2}
            title={__('IQ Panel (Fibro)')}
            headerButton={<EditGroupButton group={group} activeTab={IQ4} />}
        >
            <IQ4FibroPanels settings={group.communications.iq4_fibro} />
        </InfoCard>
    )
}

const General = ({group}) => (
    <InfoCards cols={1}>
        <InfoCard
            title={__('Main information')}
            cols={2}
            headerButton={<EditGroupButton group={group} activeTab={MAIN_INFORMATION} />}
        >
            <InfoCardTile>
                <InfoCardTileTitle>{__('Group Name')}</InfoCardTileTitle>
                <InfoCardTileContent>{group.name}</InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Description')}</InfoCardTileTitle>
                <InfoCardTileContent className="groupInfo-description-field">
                    {convertConfigValue(group.description)}
                </InfoCardTileContent>
            </InfoCardTile>
        </InfoCard>
        <InfoCard
            title={__('General information')}
            cols={2}
            headerButton={<EditGroupButton group={group} activeTab={GENERAL} />}
        >
            <InfoCardTile>
                <InfoCardTileTitle>{__('Local Wake up')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.localWakeUp)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Server Messaging Language')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.serverMessagingLanguage)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('VOD history log size')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.vodHistorySize)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Time Synchronization')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.timeSynchronization)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>
                    {__('Allow upgrade over Cellular for DSC panels')}
                </InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.allowUpgradeOverGprsDSCPanels)}
                </InfoCardTileContent>
            </InfoCardTile>
            <InfoCardTile>
                <InfoCardTileTitle>{__('Temperature/Light statistic')}</InfoCardTileTitle>
                <InfoCardTileContent>
                    {convertConfigValue(group.enableTemperatureAndLight)}
                </InfoCardTileContent>
            </InfoCardTile>
        </InfoCard>
        <PowerMasterCard group={group} />
        <QuazarCard group={group} />
        <Quazar53Card group={group} />
        <SmartCommunicatorCard group={group} />
        <IQ4FibroCard group={group} />
    </InfoCards>
)

General.propTypes = {
    group: PropTypes.object.isRequired,
    enableTemperatureAndLight: PropTypes.bool,
}

export default withPermissionRejection(general)(General)
