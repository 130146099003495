import React from 'react'

import bar, {
    refresh as refreshPermission,
    download as downloadPermission,
} from 'permissions/panel/log/bar'

import {withPermissionVisibility} from 'containers/withPermission'

import Bar, {RIGHT} from 'ipmp-react-ui/Bar'
import Button from 'ipmp-react-ui/Button'
import {ReactComponent as IconRefresh} from 'ipmp-react-ui/icons/refresh.svg'

import {__} from 'utils/i18n'
import usePermission from 'hooks/usePermission'

export const LogBar = ({refresh, download}) => {
    const {hasRefresh, hasDownload} = usePermission({
        hasRefresh: refreshPermission,
        hasDownload: downloadPermission,
    })

    return (
        <div className="configuration-header">
            <Bar orientation={RIGHT}>
                {hasRefresh && (
                    <Button onClick={refresh} label={__('Refresh')} Icon={IconRefresh} />
                )}
                {hasDownload && <Button onClick={download} label={__('Download CSV')} />}
            </Bar>
        </div>
    )
}

export default withPermissionVisibility(bar)(LogBar)
