import dataMapper from 'utils/dataMapper'
import _forEach from 'lodash-es/forEach'

export const mapCellularConnected = dataMapper((data) => ({
    isAutoEnrollmentEnabled: !!data.isAutoEnrollmentEnabled,
    messageBroker: parseInt(data.messageBroker),
    retriesMax: parseInt(data.retriesMax),
    retryInterval: parseInt(data.retryInterval),
    wakeupOnUserDiscovery: !!data.wakeupOnUserDiscovery,
}))

export const mapBroadbandConnected = dataMapper((data) => ({
    isAutoEnrollmentEnabled: !!data.isAutoEnrollmentEnabled,
}))

export const mapCommonConnected = dataMapper((data) => ({
    systemIdRegex: data.systemIdRegex,
    isPanelRemoveForever: !!data.isPanelRemoveForever,
}))

export const mapSupervision = dataMapper((data) => ({
    doSendEmailOnOnlineOffline: !!data.doSendEmailOnOnlineOffline,
    doSendSmsOnOnlineOffline: !!data.doSendSmsOnOnlineOffline,
    doSendOneChannelPanelOnlineOffline: !!data.doSendOneChannelPanelOnlineOffline,
    doSendTwoChannelPanelOnlineOffline: !!data.doSendTwoChannelPanelOnlineOffline,
}))

export const mapRemoteInspection = dataMapper((data) => ({
    doSendEmailOnSuccess: !!data.doSendEmailOnSuccess,
    doGenerateResultEvent: !!data.doGenerateResultEvent,
}))

export const mapInteractiveSession = dataMapper((data) => ({
    doAutoEnableUserApp: !!data.doAutoEnableUserApp,
    doAutoEnableConfiguratorApp: !!data.doAutoEnableConfiguratorApp,
    isDlsServiceEnabled: !!data.isDlsServiceEnabled,
    globalServerApptype: data.globalServerApptype,
    maxInteractiveConnections: parseInt(data.maxInteractiveConnections),
    maxInteractiveConnectionsPerPanel: parseInt(data.maxInteractiveConnectionsPerPanel),
    doRequireUserInstallerApproval: !!data.doRequireUserInstallerApproval,
}))

export const mapUserNotification = dataMapper((data) => ({
    isEmailWithVideoEnabled: !!data.isEmailWithVideoEnabled,
    isEmailWithoutVideoEnabled: !!data.isEmailWithoutVideoEnabled,
    messageBroker: parseInt(data.messageBroker),
}))

export const mapAdvertisement = dataMapper((data) => ({
    url: data.url,
}))

export const mapMessageBroker = dataMapper((data) => ({
    id: parseInt(data.id),
    name: data.name,
    description: data.description,
    sender: data.sender,
    login: data.login,
    password: data.password,
    host: data.host,
    port: parseInt(data.port),
    useTLS: !!data.useTLS,
    isUsedByMfa: data.isUsedByMfa,
    messageTemplate: data.messageTemplate,
    serial: parseInt(data.serial) !== -1 ? parseInt(data.serial) : null,
}))

export const mapSerialPort = dataMapper((data) => ({
    id: parseInt(data.id),
    name: data.name,
    speed: parseInt(data.speed),
    databits: parseInt(data.databits),
    parity: parseInt(data.parity),
    stopbits: parseInt(data.stopbits),
    application: data.application,
}))

export const mapPgmActivationTimeSlot = dataMapper((data) => ({
    timeWindow: parseInt(data.time_window),
    enabled: data.enabled,
    automationDevicesEnabled: data.automation_devices_enabled,
}))

export const mapValidatorInteractiveAuthorizationSettingsError = (errors) => {
    if (errors.details) {
        let details = {}
        _forEach(errors.details, (val, key) => {
            if (key != 'userLifetime') {
                _forEach(errors.details[key], (deepval, deepkey) => {
                    details[key + '.' + deepkey] = deepval
                })
            } else {
                details[key] = val
            }
        })
        errors.details = details
    }
    return errors
}

export const mfaKeysMap = {
    mfaEnabled: 'enabled',
    smsBroker: 'messageBroker',
    otpTtl: 'one_time_password_ttl',
    otpLength: 'one_time_password_length',
    resendTimeout: 'resend_timeout',
    resendCount: 'resend_count',
    verificationSessionTimes: 'session_times',
    maxAttempts: 'failed_attempts_count',
    blockTimeout: 'blocking_timeout',
    overallBlockTimeout: 'overall_blocking_timeout',
}

/* eslint-disable camelcase */
export const mapMfa = dataMapper((data) => ({
    mfaEnabled: Boolean(data?.enabled),
    smsBroker: parseInt(data.messageBroker) || undefined,
    otpTtl: Number(data?.one_time_password_ttl),
    otpLength: Number(data?.one_time_password_length),
    resendTimeout: Number(data?.resend_timeout),
    resendCount: Number(data?.resend_count),
    verificationSessionTimes: Number(data?.session_times),
    maxAttempts: Number(data?.failed_attempts_count),
    blockTimeout: Number(data?.blocking_timeout),
    overallBlockTimeout: Number(data?.overall_blocking_timeout),
}))
/* eslint-enable camelcase */
