import React from 'react'

import Charts from 'components/Dashboard/Charts'

import EventsRateChart from './Charts/EventsRateChart'
import OfflineFaultyPanelsChart from './Charts/OfflineFaultyPanelsChart'
import AllProcesses from './Charts/AllProcesses'
import ConnectedPanels from './Charts/ConnectedPanels'

export default function ChartsComponent() {
    return (
        <Charts>
            <EventsRateChart />
            <OfflineFaultyPanelsChart />
            <AllProcesses />
            <ConnectedPanels />
        </Charts>
    )
}
