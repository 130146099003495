import React from 'react'

import EventsPage from './EventsPage'
import path from 'utils/path'

export default class Events {
    path = path('events')

    element = (<EventsPage />)
}
