import React from 'react'
import {compose} from 'redux'

import Button from 'ipmp-react-ui/Button'
import {ReactComponent as RemoveIcon} from 'ipmp-react-ui/icons/remove.svg'

import {__} from 'utils/i18n'
import useUsers from 'hooks/pages/useUsers'
import usePermission from 'hooks/usePermission'
import {remove} from 'permissions/users/selection'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

const ConnectedButton = compose(withVisibility(), withSelectionHandler())(Button)

export default function RemoveUsersButton() {
    const {showRemoveUser} = useUsers()
    const {isPermitted} = usePermission(remove)

    return (
        <ConnectedButton
            isVisible={isPermitted}
            onClick={showRemoveUser}
            label={__('Remove')}
            Icon={RemoveIcon}
        />
    )
}
