import {combineReducers, compose} from 'redux'

import createPurgeReducer from 'modules/higherOrder/createPurgeReducer'
import {purgeStore} from 'modules/panels/one/actions'

import list from './list/reducer'
import walktest from './walktest/reducer'
import meteo from './meteo/reducer'
import vod from './vod/reducer'
import autolearn from './autolearn/reducer'

const purgeDevicesStoreReducer = (reducer) => createPurgeReducer(reducer, purgeStore)

const devicesReducer = combineReducers({
    list,
    walktest,
    meteo,
    vod,
    autolearn,
})

export default compose(purgeDevicesStoreReducer)(devicesReducer)
