import React, {useEffect} from 'react'
import {compose} from 'redux'

import list from 'permissions/reports/list'
import selectionPermission from 'permissions/reports/selection'
import {markAsViewed, download} from 'permissions/reports/actions'

import {withPermissionRejection} from 'containers/withPermission'

import ReportDownloadCell from './Cells/DownloadReportCell'
import ScheduleReportCell from './Cells/ScheduleReportCell'
import ReviewReportCell from './Cells/ReviewReportCell'
import StatusReportCell from './Cells/StatusReportCell'
import StateReportCell from './Cells/StateReportCell'

import Table from 'ipmp-react-ui/Table'

import {__} from 'utils/i18n'
import useSelection from 'hooks/useSelection'
import withLoader from 'containers/withLoader'
import usePermission from 'hooks/usePermission'
import useReports from 'hooks/pages/useReports'
import {useSearchScope} from 'hooks/useAutoFilters'
import useTableColumnsPermission from 'hooks/useTableColumnsPermission'

const allColumns = [
    {
        name: () => __('Name'),
        width: 50,
        render: ({name}) => name,
    },
    {
        name: () => __('Repetition'),
        width: 50,
        component: ScheduleReportCell,
    },
    {
        name: () => __('Finished'),
        component: StatusReportCell,
    },
    {
        name: () => __('Next'),
        component: StateReportCell,
    },
    {
        tooltip: false,
        permission: download,
        width: 56,
        fixed: true,
        component: ReportDownloadCell,
        className: 'reports-table-download-cell',
    },
    {
        tooltip: false,
        permission: markAsViewed,
        fixed: true,
        width: 39,
        component: ReviewReportCell,
        className: 'reports-table-review-cell',
    },
]

const ConnectedTable = compose(withPermissionRejection(list), withLoader())(Table)

export default function ReportsTable(props) {
    const reportProps = useReports()
    const {hasSelection} = usePermission({
        hasSelection: selectionPermission,
    })

    useSearchScope(reportProps.init)

    useEffect(() => {
        reportProps.startPoll()

        return reportProps.stopPoll
    }, [])

    const tableProps = {...props, ...reportProps}
    const {selection, select, deselect} = useSelection()
    const {columns} = useTableColumnsPermission(allColumns)

    return (
        <ConnectedTable
            fullHeight
            hasSelection={hasSelection}
            selection={selection}
            select={select}
            deselect={deselect}
            columns={columns}
            emptyMessage={__('No reports found')}
            className="reports-page-table"
            {...tableProps}
        />
    )
}
