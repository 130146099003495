import React from 'react'

import {__} from 'utils/i18n'
import RoleForm from './RoleForm'
import useForm from 'hooks/useForm'
import {addRole} from 'modules/forms/handlers'

export default function AddRole({hide}) {
    const {handle, isLoading, form} = useForm(addRole)

    return (
        <RoleForm
            data={{}}
            hide={hide}
            handle={handle}
            isLoading={isLoading}
            {...form}
            isNew={true}
            header={__('Add role')}
        />
    )
}
