import {handleActions} from 'redux-actions'

import {
    fetch,
    receive,
    receiveSwitchable,
    revertUpdateHad,
    update,
    updateHad,
    updateSwitchable,
} from './actions'
import {
    HAD_TYPE_DIMMER,
    HAD_TYPE_DOORLOCK,
    HAD_TYPE_IQ_SMART_DOORLOCK,
    HAD_TYPE_LEEDARSON_DIMMER,
    HAD_TYPE_PG_PLUS_DOORLOCK,
    HAD_TYPE_PG_PLUS_WATER_VALVE,
    HAD_TYPE_PGM,
    HAD_TYPE_THERMOSTAT,
    HAD_TYPE_YALE_DOORLOCK_L2,
    HAD_TYPE_YALE_DOORLOCK_L3,
    HAD_TYPE_ZWAVE_WATER_VALVE,
} from 'constants/had'
import {
    HAD_COMMAND_OUTPUT,
    HAD_COMMAND_SET_DIM,
    HAD_COMMAND_SET_THERMOSTAT_MODE,
    HAD_COMMAND_SET_THERMOSTAT_TARGET,
    HAD_COMMAND_SET_DOORLOCK,
    HAD_COMMAND_SET_WATER_VALVE_MODE,
} from 'constants/had/command'
import {
    HAD_DEVICE_THERMOSTAT_MODE_COOL,
    HAD_DEVICE_THERMOSTAT_MODE_HEAT,
} from 'constants/had/thermostat'

const defaultState = {
    isLoading: false,
    error: null,
    hadList: null,
    isSwitchable: false,
    timeSlot: null,
    isTimeSlotEnabled: true,
    temporaryHad: null,
}

export default handleActions(
    {
        [fetch](state) {
            return {
                ...state,
                ...defaultState,
                isLoading: true,
            }
        },
        [receive](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            return {
                ...state,
                isLoading: false,
                error: null,
                hadList: payload.hadList,
            }
        },
        [receiveSwitchable](state, {payload, error}) {
            if (error) {
                return {
                    ...state,
                    error: payload,
                    isLoading: false,
                }
            }

            return {
                ...state,
                isLoading: false,
                error: null,
                isSwitchable: payload.isSwitchable,
                timeSlot: payload.timeSlot,
                isTimeSlotEnabled: payload.isTimeSlotEnabled,
            }
        },
        [updateHad](state, {payload: {id, deviceType, commands}}) {
            const oldHadIndex = state.hadList.findIndex(
                (had) => had.id === id && had.deviceType === deviceType
            )
            const oldHad = state.hadList[oldHadIndex]

            return {
                ...state,
                hadList: state.hadList.map((had) => {
                    if (had.id === id && had.deviceType === deviceType) {
                        return resolveNewHad(oldHad, commands)
                    }
                    return had
                }),
                temporaryHad: oldHad,
            }
        },
        [revertUpdateHad](state, {payload: {id, deviceType}}) {
            const oldHad = state.temporaryHad

            if (!oldHad) {
                return {...state}
            }

            const updatedHadIndex = state.hadList.findIndex(
                (had) => had.id === id && had.deviceType === deviceType
            )
            const newHadList = state.hadList.slice()
            newHadList.splice(updatedHadIndex, 1, oldHad)

            return {
                ...state,
                hadList: newHadList,
                temporaryHad: null,
            }
        },
        [update](state, {payload: {hadList}}) {
            return {
                ...state,
                hadList,
            }
        },
        [updateSwitchable](
            state,
            {payload: {isSwitchable, timeSlot, isTimeSlotEnabled}}
        ) {
            return {
                ...state,
                isSwitchable,
                timeSlot,
                isTimeSlotEnabled,
            }
        },
    },
    defaultState
)

const resolvePgmNewHad = (had, commands) => {
    const newHad = {...had}
    const stateCommand = commands.find(
        (command) => command.command === HAD_COMMAND_OUTPUT
    )

    newHad.state = {...newHad.state, pgm: stateCommand.mode}
    return newHad
}

const resolveThermostatHad = (had, commands) => {
    const newHad = {...had}
    const modeCommand = commands.find(
        (command) => command.command === HAD_COMMAND_SET_THERMOSTAT_MODE
    )
    const targetHeatCommand = commands
        .filter((command) => command.mode === HAD_DEVICE_THERMOSTAT_MODE_HEAT)
        .find((command) => command.command === HAD_COMMAND_SET_THERMOSTAT_TARGET)
    const targetCoolCommand = commands
        .filter((command) => command.mode === HAD_DEVICE_THERMOSTAT_MODE_COOL)
        .find((command) => command.command === HAD_COMMAND_SET_THERMOSTAT_TARGET)

    newHad.state = {
        ...newHad.state,
        thermostat: {
            ...newHad.state.thermostat,
            mode: modeCommand?.mode ?? newHad.state.thermostat.mode,
            target_cool_temp: targetCoolCommand
                ? Number(targetCoolCommand.target)
                : newHad.state.thermostat.target_cool_temp,
            target_heat_temp: targetHeatCommand
                ? Number(targetHeatCommand.target)
                : newHad.state.thermostat.target_heat_temp,
        },
    }
    return newHad
}

const resolveDimmerHad = (had, commands) => {
    const newHad = {...had}
    const modeCommand = commands.find((command) => command.command === HAD_COMMAND_OUTPUT)
    const levelCommand = commands.find(
        (command) => command.command === HAD_COMMAND_SET_DIM
    )

    newHad.state = {
        ...newHad.state,
        pgm: modeCommand?.mode ?? newHad.state.pgm,
        dimmer: {
            level: levelCommand
                ? Number(levelCommand?.level)
                : newHad.state.dimmer?.level,
        },
    }

    return newHad
}

const resolveDoorlockHad = (had, commands) => {
    const newHad = {...had}

    const modeCommand = commands.find(
        (command) => command.command === HAD_COMMAND_SET_DOORLOCK
    )
    newHad.state = {
        ...newHad.state,
        lock: {mode: modeCommand?.mode},
    }
    return newHad
}

const resolveWaterValveHad = (had, commands) => {
    const newHad = {...had}

    const modeCommand = commands.find(
        (command) => command.command === HAD_COMMAND_SET_WATER_VALVE_MODE
    )
    newHad.state = {
        ...newHad.state,
        water_valve: {mode: modeCommand?.mode},
    }
    return newHad
}

const resolveNewHad = (had, commands) => {
    switch (had.subtype) {
        case HAD_TYPE_PGM:
        default:
            return resolvePgmNewHad(had, commands)

        case HAD_TYPE_THERMOSTAT:
            return resolveThermostatHad(had, commands)

        case HAD_TYPE_DIMMER:
        case HAD_TYPE_LEEDARSON_DIMMER:
            return resolveDimmerHad(had, commands)

        case HAD_TYPE_DOORLOCK:
        case HAD_TYPE_IQ_SMART_DOORLOCK:
        case HAD_TYPE_YALE_DOORLOCK_L2:
        case HAD_TYPE_YALE_DOORLOCK_L3:
        case HAD_TYPE_PG_PLUS_DOORLOCK:
            return resolveDoorlockHad(had, commands)
        case HAD_TYPE_ZWAVE_WATER_VALVE:
        case HAD_TYPE_PG_PLUS_WATER_VALVE:
            return resolveWaterValveHad(had, commands)
    }
}
