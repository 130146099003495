import axios from 'axios'
import has from 'lodash-es/has'

import reject from 'api/base/reject'
import {BAD_REQUEST, CUSTOM_ERROR} from 'constants/errorType'

const cancelError = () => {
    return reject({
        isCancel: true,
    })
}

const serverError = ({data, status}) => {
    if (BAD_REQUEST === data.reason) {
        data.message = CUSTOM_ERROR

        let message = data.details?.message
        if (!message) {
            const firstDetail = Object.values(data?.details || {}).shift()
            message = firstDetail?.message
            if (!message) {
                const firstSubDetail = Object.values(firstDetail || {}).shift()
                message = firstSubDetail?.message
            }
        }

        data.details = {
            ...(data.details || {}),
            message,
        }
    }

    return reject({
        status,
        isServerError: true,
        data: data.data,
        details: data.details,
        type: data.message,
        reason: data.reason,
    })
}

const communicationError = (response, message) => {
    return reject({
        isCommunicationError: true,
        status: response.status,
        message,
    })
}

const wrongFormatError = (response) => {
    return reject({
        isWrongFormatError: true,
        status: response.status,
    })
}

const requestError = (request, message) => {
    return reject({
        isRequestError: true,
        message,
    })
}

const unknownError = (message) => {
    return reject({message})
}

export default function apiFormat(instance) {
    return instance.interceptors.response.use(
        (response) => {
            if (!response.data || typeof response.data !== 'object') {
                return wrongFormatError(response)
            }

            if (!['success', 'partial_success'].includes(response.data.status)) {
                return serverError(response)
            }

            return response.data.data
        },
        (error) => {
            if (axios.isCancel(error)) {
                return cancelError(error.response)
            }

            if (error.response) {
                if (has(error.response.data, 'status')) {
                    return serverError(error.response)
                }

                return communicationError(error.response, error.message)
            }

            if (error.request) {
                return requestError(error.request, error.message)
            }

            return unknownError(error.message)
        }
    )
}
