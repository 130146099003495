import React from 'react'
import * as PropTypes from 'prop-types'
import get from 'lodash-es/get'
import {useSelector} from 'react-redux'

import Input from 'ipmp-react-ui/Input'

import {__} from 'utils/i18n'
import useForm from 'hooks/useForm'
import ModalCardForm from 'ui/ModalCardForm'
import {setPanelUserLastName} from 'modules/forms/handlers'
import {selectDevicesLabelValidationRules} from 'modules/features/store/selectors'
import {DEVICE_TYPE_USER} from 'constants/deviceType'
import {VENDOR_QOLSYS} from 'constants/panelVendorType'

const getRules = (panelId) => {
    const vendor = useSelector(({panels}) => panels.store.byIds[panelId].vendor)

    const featureRules = useSelector(({features}) =>
        selectDevicesLabelValidationRules(features, panelId)
    )

    const userLabelValidationRules = get(featureRules, [DEVICE_TYPE_USER])

    return {
        lastName: {
            presence: vendor !== VENDOR_QOLSYS,
            userLabel: {
                panelId,
                vendor,
                validationRules: userLabelValidationRules,
            },
        },
    }
}

export default function SetPanelUserLastName({hide, panelId, panelUserId, lastName}) {
    const {form, handle, isLoading} = useForm(setPanelUserLastName)

    const onHandle = ({lastName}) => {
        handle({panelId, panelUserId, lastName})
    }

    return (
        <ModalCardForm
            {...form}
            hide={hide}
            confirmOnDismiss
            onSubmit={onHandle}
            isLoading={isLoading}
            rules={getRules(panelId)}
            header={__('Set panel user last name')}
        >
            <Input label={__('Last Name')} name="lastName" defaultValue={lastName} />
        </ModalCardForm>
    )
}

SetPanelUserLastName.propTypes = {
    hide: PropTypes.func.isRequired,
    panelId: PropTypes.number.isRequired,
}
