import {useSelector} from 'react-redux'

import {useActions} from 'modules/higherOrder/useActions'
import {showPanelBillingActivateModal} from 'modules/modals/actions'
import {selectPlan} from 'modules/panels/billingPlan/selectors'

const useBilling = (panelId) => {
    const {showModal} = useActions(
        {
            showModal: (planId) =>
                showPanelBillingActivateModal({
                    planId,
                    panelId,
                }),
        },
        [panelId]
    )

    return {
        showModal,
        selectPlan: (planId) =>
            useSelector((state) => selectPlan(state, panelId, planId)),
    }
}

export default useBilling
